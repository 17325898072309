import { ServiceRecipient, ServiceRecipientDTO } from '../_commonTypes/ServiceRecipient';

const parseServiceRecipient = (serviceRecipient: ServiceRecipientDTO): ServiceRecipient => ({
  apartmentNumber: serviceRecipient.apartment_number,
  buildingNumber: serviceRecipient.building_number,
  city: serviceRecipient.city,
  createdAt: new Date(serviceRecipient.created_at),
  dateOfBirth: new Date(serviceRecipient.date_of_birth),
  firstName: serviceRecipient.first_name,
  guardianFirstName: serviceRecipient.guardian_first_name,
  guardianLastName: serviceRecipient.guardian_last_name,
  guardianPhoneNumber: serviceRecipient.guardian_phone_number,
  id: serviceRecipient.id,
  keyHolders:
    serviceRecipient?.key_holders?.map(keyHolder => ({
      firstName: keyHolder.first_name,
      lastName: keyHolder.last_name,
      phoneNumber: keyHolder.phone_number,
    })) || null,
  lastName: serviceRecipient.last_name,
  patient: serviceRecipient.patient,
  pesel: serviceRecipient.pesel,
  phoneNumber: serviceRecipient.phone_number,
  postalCode: serviceRecipient.postal_code,
  street: serviceRecipient.street,
  terytCity: serviceRecipient.teryt_city,
  terytCommuneType: serviceRecipient.teryt_commune_type,
  terytCounty: serviceRecipient.teryt_county,
  terytDistrict: serviceRecipient.teryt_district,
  terytLocationType: serviceRecipient.teryt_location_type,
  terytProvince: serviceRecipient.teryt_province,
  terytStreet: serviceRecipient.teryt_street,
  updatedAt: new Date(serviceRecipient.updated_at),
  projectUserNumber: serviceRecipient.project_user_number,
});

export default parseServiceRecipient;
