import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'end',
    width: '100%',
    justifyContent: 'end',
    gap: theme.spacing(3),
  },
}));

export default useStyles;
