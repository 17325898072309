import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';

import type { TableRowProps } from '@mui/material';
import { TableCell, TableRow as TableRowMaterial, TableBody as TableBodyMaterial } from '@mui/material';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import type { TableContent } from 'constants/_types/TableContent';

import useStyles from './TableBody.styles';

export type Props = TableContent;

const TableBody: FC<Props> = ({ headers, data, rowLink, actionsRenderer, onClick }) => {
  const { classes } = useStyles();

  const rowProps = useCallback(
    rowData => {
      const props: TableRowProps & { component?: ReactNode; to?: string } = {};
      props.component = Link;
      props.to = rowLink(rowData);
      if (onClick) {
        props.onClick = onClick;
      }

      return props;
    },
    [rowLink],
  );

  return (
    <TableBodyMaterial>
      {data.length > 0 &&
        data.map(rowData => (
          <TableRowMaterial className={classes.row} key={rowData.meta.id} {...rowProps(rowData)}>
            {headers.map(header => (
              <TableCell className={classes.cell} key={header.id}>
                {get(rowData, header.id, null)}
              </TableCell>
            ))}
            {actionsRenderer && <div className={classes.actionsBox}>{actionsRenderer(rowData, false)}</div>}
          </TableRowMaterial>
        ))}
    </TableBodyMaterial>
  );
};

export default TableBody;
