import type { FC, ReactNode } from 'react';

import { Typography, Card } from '@mui/material';

import { ReactComponent as DnaIcon } from 'assets/icons/decorative/dna.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/decorative/heart.svg';

import useStyles from './FunctionalityPlaceholder.styles';

export type Props = {
  header: string;
  description?: string;
  action?: ReactNode;
};

const FunctionalityPlaceholder: FC<Props> = ({ header, description, action }) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.root}>
      <Typography align='center' className={classes.header} component='p' variant='h1'>
        {header}
      </Typography>
      {description && (
        <Typography
          align='center'
          className={classes.subheader}
          color='secondary'
          component='p'
          data-testid='FunctionalityPlaceholder_description'
          variant='subtitle1'
        >
          {description}
        </Typography>
      )}
      {action && (
        <div className={classes.actionContainer} data-testid='FunctionalityPlaceholder_action'>
          {action}
        </div>
      )}
      <HeartIcon className={classes.heartIcon} />
      <DnaIcon className={classes.dnaIcon} />
    </Card>
  );
};

export default FunctionalityPlaceholder;
