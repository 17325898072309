import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import type { DropdownOption } from 'constants/_types/DropdownOptions';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

import { useServicesFromSubtypesQuery } from '../useServicesFromSubtypesQuery/useServicesFromSubtypesQuery';
import { useServiceSubtypesQuery } from '../useServiceSubtypesQuery/useServiceSubtypesQuery';
import { useServiceTypesQuery } from '../useServiceTypesQuery/useServiceTypesQuery';
import { prepareSelectedServiceSubtypesIds } from './_services/prepareSelectedServiceSubtypesIds/prepareSelectedServiceSubtypesIds';

interface HookParams {
  selectedPayerIds: string[];
  serviceTypeValue: string;
  serviceSubtypeValues: string[];
  preselectedSubtypeId: string | undefined;
  setFormSubtypePreselectedValue: (serviceSubtypes: DropdownOption[]) => void;
  setFormServicePreselectedValue: (servicesFromSubtypes: DropdownOption[]) => void;
}

export const useServicesQuery = ({
  selectedPayerIds,
  serviceTypeValue,
  serviceSubtypeValues,
  preselectedSubtypeId,
  setFormSubtypePreselectedValue,
  setFormServicePreselectedValue,
}: HookParams) => {
  const [searchParams] = useSearchParams();

  const paramOrigin = useMemo(() => searchParams.get('origin') as OrderServiceOrigins, [searchParams]);

  const { serviceTypes, serviceTypesLoading } = useServiceTypesQuery({ paramOrigin, selectedPayerIds });
  const { serviceSubtypes, refetchSubtypes } = useServiceSubtypesQuery({
    paramOrigin,
    selectedPayerIds,
    serviceTypeValue,
    setFormSubtypePreselectedValue,
  });

  const selectedServiceSubtypesIds = useMemo(
    () =>
      prepareSelectedServiceSubtypesIds({
        subtypesData: serviceSubtypes,
        preselectedSubtypeId,
        selectedSubtypesNames: serviceSubtypeValues,
      }),
    [serviceSubtypes, preselectedSubtypeId, serviceSubtypeValues],
  );
  const { servicesFromSubtypes, servicesFromSubtypesFetchedData, servicesFromSubtypesLoading, refetchServices } =
    useServicesFromSubtypesQuery({
      selectedPayerIds,
      selectedServiceSubtypesIds,
      setFormServicePreselectedValue,
    });

  return {
    serviceTypes,
    serviceTypesLoading,
    serviceSubtypes,
    servicesFromSubtypes,
    servicesFromSubtypesFetchedData,
    servicesFromSubtypesLoading,
    refetchSubtypes,
    refetchServices,
  };
};
