import type { FC } from 'react';

import { Typography } from '@mui/material';

import normalizePath from 'helpers/normalizePath/normalizePath';

import useStyles from './AvailablePayerLabel.styles';

export interface Props {
  alt?: string;
  logoSrc?: string | null;
}

const AvailablePayerLabel: FC<Props> = ({ alt, logoSrc }) => {
  const { classes } = useStyles();

  return logoSrc ? (
    <img alt={alt} className={classes.checkboxImage} src={normalizePath(logoSrc)} />
  ) : (
    <Typography className={classes.checkboxTextLabel} component='p' variant='subtitle3'>
      {alt}
    </Typography>
  );
};

export default AvailablePayerLabel;
