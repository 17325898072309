import { getServiceTypeDetails } from './getServiceTypeDetails/getServiceTypeDetails';
import { getServiceTypes } from './getServiceTypes/getServiceTypes';

// mastertype = type
export const endpoint = '/api/v1/core-service-mastertypes/';

const coreServiceTypes = {
  getServiceTypes,
  getServiceTypeDetails,
};

export default coreServiceTypes;
