const symptomCheckerReportsMessages = {
  header: [ 'symptomCheckerReports>>header', 'Raport z wywiadu medycznego' ],
  subheaders: {
    recommendation: [ 'symptomCheckerReports>>subheaders>>recommendation', 'Rekomendacja' ],
    patient: [ 'symptomCheckerReports>>subheaders>>patient', 'Pacjent' ],
    results: [ 'symptomCheckerReports>>subheaders>>results', 'Wyniki' ],
    interview: [ 'symptomCheckerReports>>subheaders>>interview', 'Wywiad' ],
    gender: [ 'symptomCheckerReports>>subheaders>>gender', 'Płeć' ],
    age: [ 'symptomCheckerReports>>subheaders>>age', 'Wiek' ],
    riskFactors: [ 'symptomCheckerReports>>subheaders>>riskFactors', 'Czynniki ryzyka' ],
    visitedPlaces: [ 'symptomCheckerReports>>subheaders>>visitedPlaces', 'Odwiedzone miejsca' ],
    reportedSymptoms: [ 'symptomCheckerReports>>subheaders>>reportedSymptoms', 'Zgłoszone objawy' ],
    present: [ 'symptomCheckerReports>>subheaders>>present', 'Występuje' ],
    absent: [ 'symptomCheckerReports>>subheaders>>absent', 'Nie występuje' ],
    unknown: [ 'symptomCheckerReports>>subheaders>>unknown', 'Nieznany' ],
    name: [ 'symptomCheckerReports>>subheaders>>name', 'Nazwa' ],
    probability: [ 'symptomCheckerReports>>subheaders>>probability', 'Prawdopodobieństwo' ],
  },
  probabilities: {
    high: 'Wysokie',
    medium: 'Średnie',
    low: 'Niskie',
  },
  dateLabel: [ 'symptomCheckerReports>>dateLabel', 'Wygenerowany' ],
  warning: [ 'symptomCheckerReports>>warning', `Pamiętaj, że przedstawione informacje mają wyłącznie informacyjno-edukacyjny charakter i nie mogą być w żadnym wypadku interpretowane lub traktowane jak porada, konsultacja lub diagnoza lekarza. Nie są one również podstawą do przeprowadzenia samodiagnozy oraz indywidualnego leczenia opartego na informacjach uzyskanych dzięki Serwisowi. Jeśli uważasz, że znajdujesz się w sytuacji zagrożenia życia, bezzwłocznie wezwij pogotowie ratunkowe.` ],
  created: [ 'symptomCheckerReports>>created', 'Raport został zapisany!' ],
  updated: [ 'symptomCheckerReports>>updated', 'Raport został zaktualizowany!' ],
  triageLevelsDescriptions: {
    self_care: {
      header: [ 'symptomCheckerReports>>triageLevelsDescriptions>>self_care>>header', 'Leczenie w domu' ],
      content: [ 'symptomCheckerReports>>triageLevelsDescriptions>>self_care>>content', `Twoje objawy zwykle nie wymagają wizyty u lekarza i mogą po pewnym czasie same ustąpić. Spróbuj złagodzić swoje objawy domowymi sposobami. Jeśli Twój stan zdrowia się pogorszy, skonsultuj się z lekarzem.` ]
    },
    consultation: {
      header: [ 'symptomCheckerReports>>triageLevelsDescriptions>>consultation>>header', 'Skonsultuj się z lekarzem' ],
      content: [ 'symptomCheckerReports>>triageLevelsDescriptions>>consultation>>content', `Twoje objawy mogą wymagać dalszej diagnostyki. Umów wizytę u lekarza. Jeśli objawy się nasilą, niezwłocznie skonsultuj się z lekarzem.` ]
    },
    consultation_24: {
      header: [ 'symptomCheckerReports>>triageLevelsDescriptions>>consultation_24>>header', 'Skonsultuj się z lekarzem w ciągu 24 godzin' ],
      content: [ 'symptomCheckerReports>>triageLevelsDescriptions>>consultation_24>>content', `Twoje objawy mogą wymagać pilnej konsultacji lekarskiej. Jeśli Twoje objawy nagle się nasilą, udaj się na pogotowie.` ]
    },
    emergency: {
      header: [ 'symptomCheckerReports>>triageLevelsDescriptions>>emergency>>header', 'Udaj się na szpitalny oddział ratunkowy (SOR)' ],
      content: [ 'symptomCheckerReports>>triageLevelsDescriptions>>emergency>>content', `Masz niepokojące objawy, które zwykle wymagają pilnej pomocy medycznej. Jeśli nie możesz udać się na SOR, wezwij karetkę.` ]
    },
    emergency_ambulance: {
      header: [ 'symptomCheckerReports>>triageLevelsDescriptions>>emergency_ambulance>>header', 'Wezwij karetkę' ],
      content: [ 'symptomCheckerReports>>triageLevelsDescriptions>>emergency_ambulance>>content', `Masz bardzo poważne objawy, które zwykle wymagają natychmiastowej pomocy medycznej. Nie zwlekaj, natychmiast zadzwoń na pogotowie.` ]
    }
  },
  reportsList: {
    header: ['symptomCheckerReports>>reportsList>>header', 'Raporty Wirtualnego Lekarza'],
    buttons: {
      diagnose: ['symptomCheckerReports>>reportsList>>buttons>diagnose', 'Zdiagnozuj'],
    },
    tableHeaders: {
      reportName: ['symptomCheckerReports>>reportsList>>tableHeaders>header1', 'Nazwa raportu'],
      createdAt: ['symptomCheckerReports>>reportsList>>tableHeaders>header2', 'Czas wygenerowania'],
      recommendation: ['symptomCheckerReports>>reportsList>>tableHeaders>header3', 'Rekomendacja'],
    },
  },
  patientProfileRequired: {
    header: ['symptomCheckerReports>>patientProfileRequired>>header', 'Profil pacjenta jest wymagany dla tej funkcjonalności'],
    description: ['symptomCheckerReports>>patientProfileRequired>>description', 'Uzupełnij profil pacjenta, aby móc korzystać z wirtualnego lekarza'],
    buttonLabel: ['symptomCheckerReports>>patientProfileRequired>>buttonLabel', 'Uzupełnij profil'],
  },
}

export default symptomCheckerReportsMessages
