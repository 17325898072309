import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  row: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  cell: {
    color: theme.palette.grey[600],
    fontSize: '0.7858rem',
    lineHeight: '0.9375rem',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      wordBreak: 'break-word',
    },
  },
}));

export default useStyles;
