import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
}));

export default useStyles;
