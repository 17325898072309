import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ICON_SIZES_STYLES from 'constants/styles/ICON_SIZES_STYLES';
import theme from 'styles/theme';

const useStyles = makeStyles<{ isBoxShadow: boolean }>()((_, { isBoxShadow }) => ({
  root: {
    width: `${ICON_SIZES_STYLES.large.width} !important`,
    height: `${ICON_SIZES_STYLES.large.height} !important`,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    boxShadow: isBoxShadow ? `0 8px 16px ${alpha(theme.palette.secondary.main, 0.3)} !important` : 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
