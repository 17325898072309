import { getPayers } from './getPayers/getPayers';
import { getPayersByService } from './getPayersByService/getPayersByService';

export const endpoint = {
  payers: '/api/v1/payers/',
  payersByService: '/api/v1/payers/by-service/',
};

const payers = {
  getPayers,
  getPayersByService,
};

export default payers;
