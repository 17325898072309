import { useCallback, useEffect } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import { ORDER_SERVICE_FORM_INPUT_KEYS, type OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

import { usePreselectedData } from '../usePreselectedData/usePreselectedData';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  paramOrigin: OrderServiceOrigins;
}

export const useOrderServiceFormPreselectDropdownsValues = ({ form, paramOrigin }: HookParams) => {
  const { preselectedTypeId, preselectedSubtypeId, preselectedServiceId, forgetPreselection } = usePreselectedData();

  const setFormTypePreselectedValue = useCallback(() => {
    if (!preselectedTypeId) return;
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceType as 'serviceType', preselectedTypeId);
  }, [preselectedTypeId, paramOrigin]);

  useEffect(() => {
    setFormTypePreselectedValue();
  }, [preselectedTypeId]);

  const setFormSubtypePreselectedValue = useCallback(
    (serviceSubtypes: DropdownOption[]) => {
      if (!preselectedSubtypeId) return;
      const subtypePreselectedLabel = serviceSubtypes.find(serviceSubtype => serviceSubtype.meta?.id === preselectedSubtypeId)?.label || '';
      form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceSubtype as 'serviceSubtype', [subtypePreselectedLabel]);
    },
    [preselectedSubtypeId, paramOrigin],
  );

  const setFormServicePreselectedValue = useCallback(
    (servicesFromSubtypes: DropdownOption[]) => {
      if (!preselectedServiceId) return;
      const servicePreselectedLabel = servicesFromSubtypes.find(service => service.meta?.id === preselectedServiceId)?.label || '';
      form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype as 'serviceFromSubtype', servicePreselectedLabel);
      forgetPreselection();
    },
    [preselectedServiceId, paramOrigin],
  );

  return {
    preselectedSubtypeId,
    preselectedServiceId,
    setFormSubtypePreselectedValue,
    setFormServicePreselectedValue,
  };
};
