import { getServicesForGroup } from './getServicesForGroup/getServicesForGroup';
import { getServicesGroup } from './getServicesGroup/getServicesGroup';

export const endpoint = '/api/v1/core-service-groups/';

const coreServiceGroups = {
  getServicesGroup,
  getServicesForGroup,
};

export default coreServiceGroups;
