import { FC } from 'react';

import { ECareSosInternalNote } from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes.types';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';

import ECareBitrixNotes from '../ECareBitrixNotes/ECareBitrixNotes';

type Props = {
  data: ECareSosInternalNote[];
  disabled?: boolean;
};

const BitrixList: FC<Props> = ({ data, disabled }) => {
  const lastInternalNote = data && data.length > 0 ? data[0] : undefined;

  const bitrixNotesList = [
    {
      label: '',
      value: lastInternalNote && <ECareBitrixNotes disabled={disabled} note={lastInternalNote} />,
    },
  ];

  return <DefinitionList columns={1} definitions={bitrixNotesList} />;
};

export default BitrixList;
