import type { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ServicesProfile } from 'api/servicesProfile/servicesProfile.types';
import InputWithDebouncedAction from 'components/UI/atoms/InputWithDebouncedAction/InputWithDebouncedAction';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import servicesMessages from 'translations/specific/services.mjs';

import ServicesReviewProfileTableRow from './components/ServicesReviewProfileTableRow/ServicesReviewProfileTableRow';
import { useServicesProfilesRemoveFromBasket, useServicesProfilesSearch } from './ServicesReviewProfilesTable.logic';
import useStyles from './ServicesReviewProfilesTable.styles';

export type Props = {
  content?: ServicesProfile[];
};

export const testId = createTestIdObject('ServicesReviewProfilesTable', {
  noItemsInfo: 'noItemsInfo',
  contentItem: 'contentItem',
  removeButton: 'removeButton',
});

const ServicesReviewProfilesTable: FC<Props> = ({ content }) => {
  const { t } = useTranslation();

  const { onRemove } = useServicesProfilesRemoveFromBasket();

  const { onSearch, localContent } = useServicesProfilesSearch(content);

  const { classes } = useStyles();

  if (!localContent) return null;

  return (
    <div className={classes.root}>
      <InputWithDebouncedAction
        action={onSearch}
        formControlProps={{ size: 'small', className: classes.search }}
        inputProps={{ size: 'small' }}
        startAdornment={<SearchIcon />}
      />
      {localContent.length ? (
        localContent.map(servicesProfile => (
          <ServicesReviewProfileTableRow
            data-testid={testId.contentItem}
            key={servicesProfile.id}
            servicesProfile={servicesProfile}
            onRemove={onRemove}
          />
        ))
      ) : (
        <Typography align='center' data-testid={testId.noItemsInfo} variant='caption'>
          {t(servicesMessages.noResultsForProfileSearch)}
        </Typography>
      )}
    </div>
  );
};

export default ServicesReviewProfilesTable;
