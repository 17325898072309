import { useEffect } from 'react';

import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';

import { clearSearchAroundDistance } from '../../_services/clearSearchAroundDistance/clearSearchAroundDistance';

export const useSearchAddressInputsRelation = () => {
  const { form } = useOrderServiceFormContext();

  const address = form.watch('address');
  const searchAroundDistanceValue = address[ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as 'searchAroundDistance'];
  const searchNearestValue = address[ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as 'searchNearest'];

  const shouldDisableSearchAroundDistance = !!searchNearestValue;

  useEffect(() => {
    if (!searchNearestValue || !searchAroundDistanceValue) return;
    clearSearchAroundDistance({ form, currentValues: address });
  }, [searchNearestValue]);

  return { shouldDisableSearchAroundDistance };
};
