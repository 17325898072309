import create from 'zustand';
import { devtools } from 'zustand/middleware';

import type { Breadcrumb, NestedRoute } from 'constants/router/routes';

type Config = NestedRoute | null;

type CurrentPathConfigStorage = {
  overrideConfig: any;
  baseConfig: Config;
  getConfig: () => Config;
  pruneOverrides: () => void;
  setBasePathConfig: (newConfig: NestedRoute) => void;
  setBreadcrumbsOverride: (newBreadCrumbs: Breadcrumb[]) => void;
};

// To avoid race condition override logic is implemented for dynamic data
const useCurrentPathConfigStorage = create<CurrentPathConfigStorage>(
  devtools(
    (set, get) => ({
      baseConfig: null,
      getConfig: () => {
        const { baseConfig, overrideConfig } = get();
        if (!overrideConfig) return baseConfig;
        if (!baseConfig) return {};
        return {
          ...baseConfig,
          ...overrideConfig,
          breadcrumbs: [...(baseConfig?.breadcrumbs || []), ...(overrideConfig.breadcrumbs || [])],
        };
      },

      // overrides
      overrideConfig: null,
      setBreadcrumbsOverride: breadcrumbs => {
        set({ overrideConfig: { breadcrumbs } });
      },
      pruneOverrides: () => set({ overrideConfig: null }),
      setBasePathConfig: newConfig => set({ baseConfig: newConfig }),
    }),
    { name: 'CurrentPathConfigStorage' },
  ),
);

export default useCurrentPathConfigStorage;
