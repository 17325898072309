import type { FC } from 'react';
import { useMemo } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AvatarFemale } from 'assets/icons/avatar_female.svg';
import { ReactComponent as AvatarMale } from 'assets/icons/avatar_male.svg';
import AvatarDialog from 'components/UI/organisms/_dialogs/AvatarDialog/AvatarDialog';
import { GENDER } from 'constants/_types/Gender';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './EditableUserAvatar.styles';

export type Props = {
  imageUrl?: string;
  gender?: string;
  onAvatarChange: (config: { action: 'create' | 'update' }) => void;
  disabled?: {
    value: boolean;
    reason: string;
  };
};

const EditableUserAvatar: FC<Props> = ({ gender, imageUrl, onAvatarChange, disabled }) => {
  const { t } = useTranslation();
  const [isEditDialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const { classes } = useStyles({ disabled: !!disabled?.value });

  const fallback = useMemo(
    () =>
      gender === GENDER.k ? (
        <AvatarFemale aria-label='female avatar' className={classes.avatar} />
      ) : (
        <AvatarMale aria-label='male avatar' className={classes.avatar} />
      ),
    [gender],
  );

  return (
    <>
      <div className={classes.root}>
        {imageUrl ? <img alt='avatar' className={classes.avatar} src={imageUrl} /> : fallback}
        {disabled?.value ? (
          <Typography align='center' className={classes.elementOnHover} variant='caption'>
            {disabled?.reason}
          </Typography>
        ) : (
          <Button className={classes.elementOnHover} size='small' variant='outlined' onClick={openDialog}>
            {t(patientMessages.changePicture)}
          </Button>
        )}
      </div>
      {isEditDialogOpen && <AvatarDialog close={closeDialog} isOpen={isEditDialogOpen} onAvatarChange={onAvatarChange} />}
    </>
  );
};

export default EditableUserAvatar;
