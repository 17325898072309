import type { FC } from 'react';
import { useMemo } from 'react';

import { InputBaseComponentProps } from '@mui/material';
import type { ValidationRule } from 'react-hook-form';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputText from 'components/UI/molecules/InputText/InputText';
import validationMessages from 'translations/common/validation.mjs';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  helperText?: string;
  required?: boolean;
  defaultValue?: number | '';
  rules?: ValidationRule<any>;
  inputProps?: InputBaseComponentProps;
};

const FormInputNumber: FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = '',
  inputProps = {},
  rules = {},
  required = false,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_field) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <InputText
          error={fieldState.error?.message}
          helperText={helperText}
          label={label}
          {...{
            type: 'number',
            ...inputProps,
          }}
          {...field}
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputNumber;
