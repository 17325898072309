import { useQuery } from 'react-query';

import coreService from 'api/coreService/coreService';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useGlobalSearchStorage, { DataState } from 'storages/globalSearchStorage';
import useGlobalSearchPayerStorage from 'storages/payerStorage/globalSearchPayerStorage';

import { sortAndGroupResults } from '../../_services/sortAndGroupResults/sortAndGroupResults';

const disabledDataStates: DataState[] = ['TOO_SHORT_PHRASE', 'PRISTINE'];

export const useGlobalSearch = () => {
  const { patient } = usePatientContext();
  const { globalSearchIsOpen, searchPhrase, dataState, setSearchResults } = useGlobalSearchStorage();
  const selectedPayersIds = useGlobalSearchPayerStorage(state => state.getSelectedPayersList());

  useQuery(
    [QUERY_KEYS.GLOBAL_SEARCH, searchPhrase, selectedPayersIds],
    coreService.getServicesSearchResults({
      patientId: patient?.id,
      searchPhrase,
      payers: selectedPayersIds,
    }),
    {
      enabled: !!patient?.id && globalSearchIsOpen && !disabledDataStates.includes(dataState),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: data => setSearchResults(sortAndGroupResults(data)),
    },
  );
};
