import { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './AsyncDisplay.styles';

export type Props = {
  isLoading: boolean;
  isError: boolean;
  hasData: boolean;
  children: JSX.Element;

  ignoreError?: boolean;
  customNoDataView?: JSX.Element | null;
  customErrorView?: JSX.Element | null;
  customIsLoadingView?: JSX.Element | null;
};

export const testId = createTestIdObject('AsyncDisplay', {
  loader: 'loader',
  error: 'error',
  noData: 'noData',
});

const AsyncDisplay: FC<Props> = ({
  customErrorView,
  customIsLoadingView,
  customNoDataView,
  hasData,
  isError,
  ignoreError,
  children,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (isLoading) {
    return customIsLoadingView || <LoaderOverlay data-testid={testId.loader} inner minHeight='100px' />;
  }

  if (isError && !ignoreError) {
    return (
      customErrorView || (
        <Typography className={classes.root} data-testid={testId.error}>
          {t(generalMessages.generalError)}
        </Typography>
      )
    );
  }

  if (!hasData) {
    return (
      customNoDataView || (
        <Typography className={classes.root} data-testid={testId.noData}>
          {t(generalMessages.noData)}
        </Typography>
      )
    );
  }

  return children;
};

export default AsyncDisplay;
