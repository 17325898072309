import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    maxWidth: '150px',
  },

  removeButton: {
    padding: 0,
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
}));

export default useStyles;
