import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import servicesProfile from 'api/servicesProfile/servicesProfile';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

import parseProfileServicesCatalogData from '../../_services/parseProfileServicesCatalogData/parseProfileServicesCatalogData';

const useGetServicesForProfile = () => {
  const { t } = useTranslation();
  const { profileId } = useParams() as unknown as { profileId: string };
  const { patient } = usePatientContext();

  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.GET_SINGLE_SERVICES_PROFILE, createQueryKeyFromObject({ patient: patient?.id, profileId, selectedPayersList })],
    servicesProfile.getServicesProfile({ patientId: patient?.id, profileId, payers: selectedPayersList }),
    { enabled: !!patient?.id, retry: false },
  );

  const { profileServices, profileBaseInformation } = useMemo(() => parseProfileServicesCatalogData(t, data), [data, t]);

  return { profileServices, profileBaseInformation, profileServicesLoading: isLoading };
};

export default useGetServicesForProfile;
