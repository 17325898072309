import { ECareDictionaryDTO } from 'api/eCareDictionary/eCareDictionary.types';

import { PatientFE, PatientDTO } from '../patient/patient.types';

/* --- Alarm Type --- */
const alarmTypes = ['INTERVENTION_REQUIRED', 'INTERVENTION_NOT_REQUIRED', 'MISTAKE', 'TEST'] as const;
export type AlarmTypeT = typeof alarmTypes[number];

export const isAlarmType = (status: AlarmTypeT): status is AlarmTypeT => alarmTypes.includes(status);

/* --- Alarm Status --- */
export const alarmStatuses = ['RECEIVED', 'MISSED', 'COMPLETE_DOCUMENTATION', 'CLOSED'] as const;
export type AlarmStatusT = typeof alarmStatuses[number];

export const isAlarmStatus = (status: AlarmStatusT): status is AlarmStatusT => alarmStatuses.includes(status);

/* --- Alarm API types --- */
type Operator = {
  first_name: string;
  last_name: string;
};

export type InternalNotesDTO = {
  id: string;
  content_type: number;
  created_at: string;
  created_by: {
    phone_number: string;
    email: string;
  };
  creation_time: string;
  object_id: string;
  text: string;
  update_time: string;
  updated_at: string;
  updated_by: string | null;
};

export type ECareAlarmSource = Record<string, string | Date>;

export type ECareAlarmDTO = {
  id: string;
  internal_id: string;
  created_at: string;
  description: string;
  source: ECareAlarmSource | null;
  device: string | null;
  event: string | null;
  updated_at: string;
  connection_date: string;
  serial_number: string | null;
  sos_type: AlarmTypeT;
  sos_status: AlarmStatusT;
  intervention_date: string | null;
  internal_notes: InternalNotesDTO[];
  object_id: number;
  order: string;
  patient: PatientDTO | null;
  service_recipient: string;
  sos_reason: ECareDictionaryDTO | null;
  intervention_type: ECareDictionaryDTO | null;
  intervention_effect: ECareDictionaryDTO | null;
  content_type: number;
  subscription: string;
  entity: string;
  payer: string;
  operator: Operator;
  last_sync: string | null;
};

export type ECareAlertUnassigned = {
  sos_type?: string;
  sos_reason_id?: number;
  sos_status?: string;
  intervention_type_id?: number;
  intervention_effect_id?: number;
  description?: string;
  intervention_date?: string | null;
};

export type ECareAlarm = {
  id: string;
  internalId: string;
  createdAt: Date;
  updatedAt: Date;
  device: string | null;
  source: ECareAlarmSource | null;
  event: string | null;
  description: string;
  connectionDate: Date;
  serialNumber: string | null;
  sosType: AlarmTypeT;
  sosStatus: AlarmStatusT;
  interventionDate: Date | null;
  objectId: number;
  order: string;
  internalNotes: InternalNotesDTO[];
  serviceRecipient: string;
  patient: PatientFE | null;
  sosReason: ECareDictionaryDTO | null;
  interventionType: ECareDictionaryDTO | null;
  interventionEffect: ECareDictionaryDTO | null;
  contentType: number;
  subscription: string;
  payer: string;
  entity: string;
  operator: Operator;
  lastSync: Date | null;
};

export type ECareAlarmListFilters =
  | 'internal_id'
  | 'subscription'
  | 'event'
  | 'intervention_date'
  | 'connection_date'
  | 'service_recipient__patient__personal_data__first_name'
  | 'service_recipient__patient__personal_data__last_name'
  | 'service_recipient__patient__pesel'
  | 'service_recipient__patient__personal_data__main_address__street_name'
  | 'service_recipient__patient__personal_data__main_address__city'
  | 'service_recipient__patient__personal_data__main_address__postcode'
  | 'sos_status'
  | 'patient__operator__last_name'
  | 'sos_type'
  | 'payer'
  | 'guardian'
  | 'service_recipient__patient__project_user_number'
  | 'operator__last_name'
  | 'service_recipient__patient_id'
  | 'exclude_event';
