import type { FC, ReactElement } from 'react';
import { useMemo } from 'react';

import { Paper } from '@mui/material';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import AppLogoTopBar from 'components/UI/molecules/AppLogoTopBar/AppLogoTopBar';

import useStyles from './LayoutUnauthorized.styles';

type Props = {
  children: ReactElement;
};

const LayoutUnauthorized: FC<Props> = ({ children }) => {
  const { brandInfo } = useBrandContext() || { brandInfo: null };

  const { leftImageSrc, rightImageSrc } = useMemo(
    () => ({
      leftImageSrc: brandInfo?.brand.logo_left,
      rightImageSrc: brandInfo?.brand.logo_right,
    }),
    [brandInfo],
  );

  const hasNoImage = useMemo(() => !leftImageSrc && !rightImageSrc, [brandInfo]);

  const { classes } = useStyles({ hasNoImage });

  return (
    <>
      <AppLogoTopBar hasNoImage={hasNoImage} leftImageSrc={leftImageSrc} rightImageSrc={rightImageSrc} />
      <div className={classes.root}>
        <Paper className={classes.tile}>{children}</Paper>
      </div>
    </>
  );
};

export default LayoutUnauthorized;
