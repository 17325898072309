import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttons: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  buttonWrapper: {
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(3),
    alignItems: 'flex-end',
    marginBottom: theme.spacing(0.5),
  },
  tableWrapper: {
    marginBottom: 20,
  },
  section: {
    gap: 0,
  },
  boxList: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
