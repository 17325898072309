import { DIRECTIONS, type Directions } from 'constants/_types/Direction';

export type DirectionConfig = {
  base: Directions;
  alternate: Directions;
};

export const resolveTransform = (direction: DirectionConfig, isAlternated?: boolean) => {
  const currentDirection = isAlternated ? direction.alternate : direction.base;
  switch (currentDirection) {
    case DIRECTIONS.top:
      return 'rotate(180deg)';
    case DIRECTIONS.right:
      return 'rotate(-90deg)';
    case DIRECTIONS.bottom:
      return 'rotate(0deg)';
    case DIRECTIONS.left:
      return 'rotate(90deg)';
    default:
      return 'rotate(0)';
  }
};
