import { Dispatch } from 'react';

import { TFunction } from 'i18next';

import { CoreServiceDTO } from 'api/coreService/coreService.types';
import { ResultsRow } from 'components/UI/organisms/ServicesTypeView/ServicesTypeView';
import { normalizeString } from 'helpers/normalizeString/normalizeString';
import parseCoreService from 'services/parseCoreService/parseCoreService';

type Helpers = {
  setCompletedColumns: Dispatch<string[]>;
  t: TFunction;
};

const parseServicesData = (
  rawData: { dataForType: CoreServiceDTO[] | null; type: string; isCompleted: boolean }[],
  { setCompletedColumns, t }: Helpers,
): ResultsRow[] => {
  const rows: ResultsRow[] = [];

  setCompletedColumns(
    rawData.reduce<string[]>((accumulator, current) => {
      if (current.isCompleted) accumulator.push(current.type);
      return accumulator;
    }, []),
  );
  const iterationsNumber = Math.max(...rawData.map(({ dataForType }) => dataForType?.length || 0));
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < iterationsNumber; i++) {
    const row: ResultsRow = {};
    rawData.forEach(({ type, dataForType }) => {
      const cell = dataForType ? dataForType[+i] : null;
      row[type] = cell ? { ...parseCoreService(cell), searchableName: normalizeString(t(cell.name.key, cell.name.default_value)) } : null;
    });
    rows.push(row);
  }
  return rows;
};

export default parseServicesData;
