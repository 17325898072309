import axios from 'axios';

import { PartialRecord } from 'constants/_types/PartialRecord';
import { PatientStatus } from 'constants/_types/PatientStatusesArray';
import { TableDataBaseParamsForQuery } from 'constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import {
  DefaultApiResponse,
  PaginatedApiResponse,
  PatientLegacy,
  PatientAvatar,
  PatientAvatarInput,
  PatientsFilters,
  PersonalDataUnassigned,
} from '../_types';
import { parsePatientForFE } from './patient.parsers';
import { PatientDTO, PatientFE } from './patient.types';

const endpoints = {
  patients: 'api/v1/user-patients/',
  avatar: 'api/v1/patient-profile-picture/',
};

type GetPatientQueryLegacy = () => Promise<DefaultApiResponse<PatientLegacy>>;
type GetPatientQuery = () => Promise<PatientFE>;

type GetMyPatientsQuery = () => Promise<PaginatedApiResponse<PatientLegacy>>;

type GetAllPatientsQuery = (
  params: TableDataQueryFunctionParams<keyof PatientLegacy>,
) => Promise<TableDataQueryResult<keyof PatientLegacy>>;

export type CreatePatientsInput = { personal_data: PersonalDataUnassigned; pesel: string };

type CreatePatientsQuery = (data: CreatePatientsInput) => Promise<DefaultApiResponse<PatientLegacy>>;

export type UploadFilesResponse = { attachment_ids: number[] };
export type UploadFilesQuery = (data: FormData) => Promise<DefaultApiResponse<UploadFilesResponse>>;

type GetPatientPoliciesQuery = () => Promise<DefaultApiResponse<PatientLegacy>>;

type GetAvatarQuery = () => Promise<DefaultApiResponse<PatientAvatar>>;

type CreateAvatarQuery = (data: PatientAvatarInput) => Promise<DefaultApiResponse<PatientAvatar>>;

type UpdateAvatarQuery = (data: PatientAvatarInput) => Promise<DefaultApiResponse<PatientAvatar>>;

type UpdatePatientStatusQuery = (data: PatientStatus) => Promise<DefaultApiResponse<{ status: PatientStatus }>>;

type UpdatePatientBaseDataMutation = (data: Partial<PatientLegacy>) => Promise<DefaultApiResponse<PatientLegacy>>;

type DeleteAvatarQuery = () => Promise<DefaultApiResponse<null>>;

type CreateOrUpdateNoteMutationParams = { patientId: number; content: string; noteId?: number };
export type CreateOrUpdateNoteMutation = (params: CreateOrUpdateNoteMutationParams) => Promise<DefaultApiResponse<null>>;

const createOrUpdateNote = (
  ep: 'health-condition' | 'social-situation',
  { patientId, content, noteId }: CreateOrUpdateNoteMutationParams,
) => {
  const body = { content, patient: patientId };

  if (!noteId) return axios.post<CreateOrUpdateNoteMutation, DefaultApiResponse<null>>(`api/v1/${ep}/`, body);
  return axios.put<CreateOrUpdateNoteMutation, DefaultApiResponse<null>>(`api/v1/${ep}/${noteId}/`, body);
};

const patients = {
  getPatientLegacy:
    (id: number | string, asPro?: boolean): GetPatientQueryLegacy =>
    () =>
      axios.get<GetPatientQueryLegacy, DefaultApiResponse<PatientLegacy>>(
        createUrlWithParams({ url: `${endpoints.patients}${id}/`, params: { pro: asPro }, removeEmptyParams: true }),
      ),
  getPatient:
    (id: number | string, asPro?: boolean): GetPatientQuery =>
    async () => {
      const { data } = await axios.get<GetPatientQuery, DefaultApiResponse<PatientDTO>>(
        createUrlWithParams({ url: `${endpoints.patients}${id}/`, params: { pro: asPro }, removeEmptyParams: true }),
      );

      return parsePatientForFE(data);
    },
  getMyPatients: (): GetMyPatientsQuery => () => axios.get<GetMyPatientsQuery, PaginatedApiResponse<PatientLegacy>>(endpoints.patients),
  getAllPatients: (): GetAllPatientsQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery & PartialRecord<PatientsFilters, string> & { pro: boolean } = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      pro: true,
      ...parseFiltersForBE<PatientsFilters>(params.filters),
    };
    const response = await axios.get<GetMyPatientsQuery, PaginatedApiResponse<PatientLegacy>>(
      createUrlWithParams({ url: endpoints.patients, params: paramsForBE }),
    );

    return {
      count: response.data.count,
      data: response.data.results,
    };
  },
  createPatient: (): CreatePatientsQuery => data =>
    axios.post<CreatePatientsQuery, DefaultApiResponse<PatientLegacy>>(endpoints.patients, data),
  updatePatientStatus:
    (id: number | string): UpdatePatientStatusQuery =>
    data =>
      axios.put<UpdatePatientStatusQuery, DefaultApiResponse<{ status: PatientStatus }>>(
        createUrlWithParams({ url: `${endpoints.patients}${id}/`, params: { pro: true } }),
        { status: data },
      ),
  uploadFiles:
    (id: number): UploadFilesQuery =>
    data =>
      axios.patch<UploadFilesQuery, DefaultApiResponse<UploadFilesResponse>>(`${endpoints.patients}${id}/attachments/`, data),
  getPatientPolicies:
    (id: number): GetPatientPoliciesQuery =>
    () =>
      axios.get<GetPatientPoliciesQuery, DefaultApiResponse<PatientLegacy>>(`${endpoints.patients}${id}/active-policies/`),
  partialUpdatePatientBaseData:
    (id: number, asPro?: boolean): UpdatePatientBaseDataMutation =>
    data =>
      axios.patch<UpdatePatientBaseDataMutation, DefaultApiResponse<PatientLegacy>>(
        createUrlWithParams({ url: `${endpoints.patients}${id}/`, params: { pro: asPro } }),
        data,
      ),
  getPatientAvatar:
    (id: number): GetAvatarQuery =>
    () =>
      axios.get<GetAvatarQuery, DefaultApiResponse<PatientAvatar>>(`${endpoints.avatar}${id}/`),
  createPatientAvatar: (): CreateAvatarQuery => data =>
    axios.post<CreateAvatarQuery, DefaultApiResponse<PatientAvatar>>(endpoints.avatar, data),
  updatePatientAvatar:
    (id: number): UpdateAvatarQuery =>
    data =>
      axios.put<UpdateAvatarQuery, DefaultApiResponse<PatientAvatar>>(`${endpoints.avatar}${id}/`, data),
  deletePatientAvatar:
    (id: number): DeleteAvatarQuery =>
    () =>
      axios.delete<DeleteAvatarQuery, DefaultApiResponse<null>>(`${endpoints.avatar}${id}/`),
  createOrUpdateHealthConditions: (): CreateOrUpdateNoteMutation => params => createOrUpdateNote('health-condition', params),
  createOrUpdateSocialSituation: (): CreateOrUpdateNoteMutation => params => createOrUpdateNote('social-situation', params),
};

export const getPatientKeyGeneratorLegacy = (patientId: number | string, isPro?: boolean) => [
  `${QUERY_KEYS.PATIENT_INFO}_LEGACY`,
  patientId,
  isPro ? 'pro' : '',
];

export const getPatientKeyGenerator = (patientId: number | string, isPro?: boolean) => [
  QUERY_KEYS.PATIENT_INFO,
  patientId,
  isPro ? 'pro' : '',
];

export default patients;
