import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ECareContactNote } from 'api/eCareContactNotes/eCareContactNotes.types';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

const usePhoneCallNotesTable = (data: ECareContactNote[] | undefined) => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ECareContactNote>();
  const columns = [
    columnHelper.accessor('createdAt', {
      header: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.creationTime) as string,
      id: 'createdAt',
      cell: info => unknownDateToString(new Date(info.getValue()), DATE_FORMATS.DISPLAY_LONG_WITH_TIME),
    }),
    columnHelper.accessor('content', {
      header: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.text) as string,
      id: 'content',
      cell: info => info.getValue(),
    }),
  ];

  return {
    columns,
    data: data || null,
  };
};

export default usePhoneCallNotesTable;
