import { FORM_BAR_BUTTONS } from 'constants/styles/_types/FormBarButtons';

export const stepOneMobileGridTemplateAreas = `
  "${FORM_BAR_BUTTONS.savedProfilesButtonArea} ${FORM_BAR_BUTTONS.saveAsProfileButtonArea}"
  "${FORM_BAR_BUTTONS.nextButtonArea} ${FORM_BAR_BUTTONS.nextButtonArea}"
`;

export const stepTwoMobileGridTemplateAreas = `
  "${FORM_BAR_BUTTONS.previousButtonArea} ${FORM_BAR_BUTTONS.saveAsProfileButtonArea}"
  "${FORM_BAR_BUTTONS.nextButtonArea} ${FORM_BAR_BUTTONS.nextButtonArea}"
`;
