import axios from 'axios';

import parseSortForBE from 'api/_commonParsers/parseSortForBE';
import { PaginatedApiResponse } from 'api/_types';
import { TableDataBaseParamsForQuery } from 'constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parseActionLogForFE } from './actionLogs.parsers';
import { ActionLog, ActionLogDTO } from './actionLogs.types';

const endpoint = '/api/v1/log-action/';

type GetActionLogsBaseParams = { contentType: number; objectId: string | number };
type GetActionLogsBaseParamsDTO = { content_type: number; object_id: string | number };
type GetActionLogsQuery = (params: TableDataQueryFunctionParams<keyof ActionLog>) => Promise<TableDataQueryResult<keyof ActionLog>>;

const actionLogs = {
  getActionLogs:
    ({ contentType, objectId }: GetActionLogsBaseParams): GetActionLogsQuery =>
    async params => {
      const paramsForBE: TableDataBaseParamsForQuery & GetActionLogsBaseParamsDTO = {
        limit: params.limit,
        offset: params.offset,
        ordering: parseSortForBE(params.sort),
        content_type: contentType,
        object_id: objectId,
      };

      const { data } = await axios.get<GetActionLogsQuery, PaginatedApiResponse<ActionLogDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: paramsForBE,
          removeEmptyParams: true,
        }),
      );

      return { data: data.results.map(parseActionLogForFE), count: data.count };
    },
};
export default actionLogs;
