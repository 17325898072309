import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  titleLink: {
    color: theme.palette.secondary.main,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
