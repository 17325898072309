import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled }) => ({
  root: {
    pointerEvents: disabled ? 'none' : 'auto',
    position: disabled ? 'relative' : 'static',
    opacity: disabled ? 0.4 : 1,
  },
}));

export default useStyles;
