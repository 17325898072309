import { FunctionComponent, useMemo } from 'react';

import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ReactComponent as AlarmsListIcon } from 'assets/icons/hub/active/bell.svg';
import { ReactComponent as ECareOrderListIcon } from 'assets/icons/hub/active/cashier.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/hub/active/document.svg';
import { ReactComponent as ServicesIcon } from 'assets/icons/hub/active/files.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/hub/active/gearwheel.svg';
import { ReactComponent as ECareTechnicalIssuesListIcon } from 'assets/icons/hub/active/issues.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/hub/active/lifebuoy.svg';
import { ReactComponent as PhoneCallIcon } from 'assets/icons/hub/active/phone_call.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/hub/active/screen.svg';
import { ReactComponent as PatientProfileIcon } from 'assets/icons/hub/active/user_plus.svg';
import { ReactComponent as PatientProfilesListIcon } from 'assets/icons/hub/active/users.svg';
import PATHS from 'constants/router/PATHS';
import menuMessages from 'translations/specific/menu.mjs';

import useIsProfessionalUser from '../useIsProfessionalUser/useIsProfessionalUser';
import usePermissionCheck from '../usePermissionCheck/usePermissionCheck';
import { useResolvedActivePaths } from './_hooks/useResolvedActivePaths/useResolvedActivePaths';

export type MenuEntity = {
  label: string;
  icon: FunctionComponent;
  action?: () => void;
  to: string;
  isActiveFor: string[];
  testId?: string;
  hidden?: boolean;
};

const useMenuContent = (): MenuEntity[] => {
  const isProfessionalUser = useIsProfessionalUser();
  const [canViewPatient, canViewECareOrder, canViewECareAlarm, canViewECareTechnicalIssues, canViewPhoneCalls] = usePermissionCheck([
    'view_patient',
    'view_ecareserviceorder',
    'view_ecaresos',
    'view_ecaretechnicalproblem',
    'view_phonecall',
  ]);
  const { t } = useTranslation();

  const { dashboardIsActiveForPaths, servicesIsActiveForPaths } = useResolvedActivePaths();

  const baseMenuElements = useMemo(
    () => [
      { label: t(menuMessages.elements.settings), to: generatePath(PATHS.SETTINGS), icon: SettingsIcon, isActiveFor: [PATHS.SETTINGS] },
    ],
    [t],
  );

  const menuElements = useMemo(() => {
    if (isNil(isProfessionalUser)) {
      return baseMenuElements;
    }
    if (isProfessionalUser) {
      const availablePaths: MenuEntity[] = [];

      if (canViewECareAlarm) {
        availablePaths.push({
          label: t(menuMessages.pro_elements.eCareSos),
          to: generatePath(PATHS.PROFESSIONAL_ECARE_ALARMS_LIST),
          icon: AlarmsListIcon,
          isActiveFor: [PATHS.PROFESSIONAL_ECARE_ALARMS_LIST, PATHS.PROFESSIONAL_ECARE_ALARM_SINGLE],
        });
      }

      if (canViewPhoneCalls) {
        availablePaths.push({
          label: t(menuMessages.pro_elements.eCarePhoneCalls),
          to: generatePath(PATHS.PROFESSIONAL_ECARE_PHONE_CALLS_LIST),
          icon: PhoneCallIcon,
          isActiveFor: [PATHS.PROFESSIONAL_ECARE_PHONE_CALLS_LIST, PATHS.PROFESSIONAL_ECARE_PHONE_CALL_SINGLE],
        });
      }

      if (canViewECareOrder) {
        availablePaths.push({
          label: t(menuMessages.pro_elements.eCareOrder),
          to: generatePath(PATHS.PROFESSIONAL_ECARE_ORDER_LIST),
          icon: ECareOrderListIcon,
          isActiveFor: [PATHS.PROFESSIONAL_ECARE_ORDER_LIST, PATHS.PROFESSIONAL_ECARE_ORDER_SINGLE],
        });
      }

      if (canViewECareTechnicalIssues) {
        availablePaths.push({
          label: t(menuMessages.pro_elements.technicalIssues),
          to: generatePath(PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUES_LIST),
          icon: ECareTechnicalIssuesListIcon,
          isActiveFor: [PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUES_LIST, PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUE_SINGLE],
        });
      }

      if (canViewPatient) {
        availablePaths.push({
          label: t(menuMessages.pro_elements.patients),
          to: generatePath(PATHS.PROFESSIONAL_PATIENTS),
          icon: PatientProfilesListIcon,
          isActiveFor: [PATHS.PROFESSIONAL_PATIENTS, PATHS.PROFESSIONAL_PATIENT_SINGLE],
        });
      }

      return [...availablePaths, ...baseMenuElements];
    }
    return [
      {
        label: t(menuMessages.elements.start),
        to: PATHS.ROOT,
        icon: DashboardIcon,
        isActiveFor: dashboardIsActiveForPaths,
      },
      {
        label: t(menuMessages.elements.services),
        to: generatePath(PATHS.SERVICES),
        icon: ServicesIcon,
        isActiveFor: servicesIsActiveForPaths,
      },
      {
        label: t(menuMessages.elements.my_profile),
        to: generatePath(PATHS.MY_PROFILE),
        icon: PatientProfileIcon,
        isActiveFor: [PATHS.MY_PROFILE],
      },
      {
        label: t(menuMessages.elements.reports),
        to: generatePath(PATHS.REPORTS),
        icon: ReportsIcon,
        isActiveFor: [PATHS.REPORTS, PATHS.REPORT_SINGLE],
      },
      ...baseMenuElements,
      { label: t(menuMessages.elements.help), to: generatePath(PATHS.HELP), icon: HelpIcon, isActiveFor: [PATHS.HELP] },
    ];
  }, [isProfessionalUser, t, canViewPatient]);

  return menuElements;
};

export default useMenuContent;
