import { mapValues } from 'lodash';
import type { StoreApi, UseBoundStore } from 'zustand';
import type { PersistOptions } from 'zustand/esm/middleware/persist';
import type { State } from 'zustand/esm/vanilla';
import { persist } from 'zustand/middleware';

import payersApi from 'api/payers/payers';
import type { Payer } from 'api/payers/types';
import queryClient from 'config/queryClient';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import compareIdArrays from 'services/compareIdArrays/compareIdArrays';

export type Payers = {
  [key: number | string]: boolean;
};

type PayerChoiceStorage = {
  payers: Payers;
  rawPayers: Payer[];
  selfPayerId: number | null;
  setInitPayers: (newPatient: number) => void;
  toggle: (id: number) => void;
  setOnlyOnePayerActive: (id: number) => void;
  getSelectedPayersList: () => string[];
};

declare type PersistListener<S> = (state: S) => void;

interface StorePersist<S extends State, Ps> {
  persist: {
    setOptions: (options: Partial<PersistOptions<S, Ps>>) => void;
    clearStorage: () => void;
    rehydrate: () => Promise<void>;
    hasHydrated: () => boolean;
    onHydrate: (fn: PersistListener<S>) => () => void;
    onFinishHydration: (fn: PersistListener<S>) => () => void;
  };
}

export type PayerChoiceStorageType = UseBoundStore<
  PayerChoiceStorage,
  StoreApi<PayerChoiceStorage> & StorePersist<PayerChoiceStorage, Partial<PayerChoiceStorage>>
>;

const payerChoiceStorage = persist<PayerChoiceStorage>(
  (set, get) => ({
    payers: {},
    rawPayers: [],
    selfPayerId: null,
    setInitPayers: async newPatient => {
      const rawPayers = await queryClient.fetchQuery([QUERY_KEYS.PAYER, newPatient], payersApi.getPayers(newPatient));
      set({ rawPayers });
      const ids: number[] = rawPayers.map(({ id }) => id);
      const abortUpdate = compareIdArrays(ids, Object.keys(get().payers));
      if (abortUpdate) return;
      const payers = ids.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {} as Payers);
      set({ payers, selfPayerId: rawPayers.find(({ isSelfPayer }) => isSelfPayer)?.id || null });
    },
    toggle: id => {
      const { payers } = get();
      const isLastChecked = Object.values(payers).filter(value => !!value).length === 1;

      const newValue = !payers[id];
      if (isLastChecked && !newValue) return;
      set({
        payers: { ...payers, [id]: newValue },
      });
    },
    setOnlyOnePayerActive: id => {
      const { payers } = get();
      set({ payers: mapValues(payers, (_, key) => key === String(id)) });
    },
    getSelectedPayersList: () => {
      const { payers } = get();

      return Object.entries(payers)
        .filter(([_, value]) => !!value)
        .map(([key]) => key);
    },
  }),
  { name: 'userPayersStorage', getStorage: () => sessionStorage },
);

export default payerChoiceStorage;
