import type { TranslatableDropdownOption } from 'constants/_types/DropdownOptions';
import { GENDER } from 'constants/_types/Gender';
import generalMessages from 'translations/common/general.mjs';

const genderOptions: TranslatableDropdownOption[] = [
  { value: GENDER.m, labelKey: generalMessages.gender.m },
  { value: GENDER.k, labelKey: generalMessages.gender.f },
];

export default genderOptions;
