export type Dialogs =
  | 'clauses'
  | 'passwordChange'
  | 'patient'
  | 'prescription'
  | 'referral'
  | 'stationaryVisit'
  | 'teleconsultation'
  | 'eCareOrderForm'
  | 'eCareStatusDialog'
  | 'professionalECareOrderSinglePage';

export const DIALOGS: { [key in Dialogs]: Dialogs } = {
  clauses: 'clauses',
  passwordChange: 'passwordChange',
  patient: 'patient',
  prescription: 'prescription',
  referral: 'referral',
  stationaryVisit: 'stationaryVisit',
  teleconsultation: 'teleconsultation',
  eCareOrderForm: 'eCareOrderForm',
  eCareStatusDialog: 'eCareStatusDialog',
  professionalECareOrderSinglePage: 'professionalECareOrderSinglePage',
};
