import { makeStyles } from 'tss-react/mui';

import { PSEUDOELEMENT_ACTIVE_BORDER_BASE } from 'constants/styles/PSEUDOELEMENT_ACTIVE_BORDER_BASE';
import SCROLLBAR_SIZE from 'constants/styles/SCROLLBAR_SIZE';
import { SCROLLBAR_STYLING } from 'constants/styles/SCROLLBAR_STYLING';

interface StylesProps {
  tableHeight: number;
  isFirefox: boolean;
}

const MAX_TABLE_HEIGHT = 480;

const useStyles = makeStyles<StylesProps>()((theme, { tableHeight, isFirefox }) => {
  const BORDER = `thin solid ${theme.palette.grey[200]}`;
  const BORDER_ACTIVE = `2px solid ${theme.palette.primary.main}`;

  const cellSinglePadding = theme.spacing(0.5);

  return {
    root: {
      maxWidth: '100%',
      justifySelf: 'stretch',
      overflowY: 'auto',
      position: 'relative',
      minHeight: tableHeight < MAX_TABLE_HEIGHT ? `${tableHeight + SCROLLBAR_SIZE.width}px` : '50vh',
      height: tableHeight,
      padding: '0 1px',
      ...SCROLLBAR_STYLING(theme),

      [theme.breakpoints.down('sm')]: {
        minHeight: '40vh',
      },
    },

    table: {
      position: 'absolute',
      tableLayout: 'fixed',
      borderSpacing: 0,
      borderCollapse: 'separate',
      borderBottom: BORDER,
    },

    thead: {
      margin: 0,
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: theme.palette.background.paper,

      '&:after': {
        content: '""',
        bottom: 0,
        position: 'absolute',
        height: '1px',
        width: '100%',
        background: theme.palette.grey[200],
      },

      '&:before': {
        content: '""',
        top: 0,
        position: 'absolute',
        height: '1px',
        width: '100%',
        background: theme.palette.grey[200],
      },
    },

    th: {
      borderRight: BORDER,
      textAlign: 'center',
      padding: cellSinglePadding,
      margin: 0,

      '&:first-of-type': {
        borderLeft: BORDER,
      },

      '&:after': isFirefox
        ? {
            content: '""',
            top: 0,
            right: 0,
            position: 'absolute',
            height: '100%',
            width: '1px',
            background: theme.palette.grey[200],
          }
        : {},
    },

    headerCaption: {
      display: 'inline-block',
      width: 120,
    },

    thActive: {
      position: 'relative',

      '&:after': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: '-1px',
        left: '-1px',
        width: 'calc(100% + 3px)',
        height: 'calc(100% + 2px)',
        background: 'none',
        borderRight: BORDER_ACTIVE,
        borderLeft: BORDER_ACTIVE,
      },

      '&:before': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: 0,
        left: 0,
        height: '2px',
        width: '100%',
      },

      '&:first-of-type': {
        borderLeft: 'none',
      },
    },

    activeBorderNode: {
      position: 'relative',
      '&:before': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: 0,
        left: '-1px',
        // to avoid 1px gray space
        height: 'calc(100% + 1px)',
        width: '2px',
      },
      '&:after': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: 0,
        right: '-1px',
        // to avoid 1px gray space
        height: 'calc(100% + 1px)',
        width: '2px',
      },
    },
    lastActiveTd: {
      borderBottom: BORDER_ACTIVE,
    },
    loaderWrapper: {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      zIndex: 1,
      transform: `translateX(-1px) scale(1.01)`, // scale is necessary to cover active row border
      background: `linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%)`,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
  };
});

export default useStyles;
