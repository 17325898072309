import { useEffect } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import { ORDER_SERVICE_FORM_INPUT_KEYS, type OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  serviceTypes: DropdownOption[];
  selectedPayersList: string[];
}

export const usePreselectType = ({ form, serviceTypes, selectedPayersList }: HookParams) => {
  useEffect(() => {
    const shouldPreselectType = serviceTypes.length === 1;
    if (!shouldPreselectType) return;

    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceType as 'serviceType', serviceTypes[0].meta?.id || '');
  }, [serviceTypes, ...selectedPayersList]);
};
