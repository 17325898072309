import type { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { QueryKey } from 'react-query';

import { useReportRowActions } from 'hooks/_reports/useReportRowActions/useReportRowActions';
import dashboardMessages from 'translations/specific/dashboard.mjs';

import useStyles from './ReportsTableRowActions.styles';

interface Props {
  id: number;
  createdAtTime: string;
  isHidden: boolean;
  isMedicalRecord: boolean;
  queryKey?: QueryKey;
  reportFileId: number;
}

const ReportsTableRowActions: FC<Props> = ({
  id,
  createdAtTime,
  isHidden: isHiddenProp,
  isMedicalRecord: isMedicalRecordProp,
  queryKey = '',
  reportFileId,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    buttonsState: { isHidden, isMedicalRecord },
    onMedicalRecordToggle,
    onDownload,
    onHide,
  } = useReportRowActions({ id, createdAtTime, queryKey, isHiddenProp, isMedicalRecordProp, reportFileId });

  return (
    <>
      <Tooltip
        enterDelay={750}
        title={
          isMedicalRecord
            ? (t(dashboardMessages.reportsCard.actions.private) as string)
            : (t(dashboardMessages.reportsCard.actions.public) as string)
        }
      >
        <Button className={classes.button} size='small' onClick={onMedicalRecordToggle}>
          {isMedicalRecord ? (
            <LockOpenOutlinedIcon color='secondary' data-testid='isPublic' fontSize='small' />
          ) : (
            <LockOutlinedIcon color='secondary' data-testid='isPrivate' fontSize='small' />
          )}
        </Button>
      </Tooltip>
      <Tooltip enterDelay={750} title={t(dashboardMessages.reportsCard.actions.download) as string}>
        <Button className={classes.button} size='small' onClick={onDownload}>
          <FileDownloadOutlinedIcon color='secondary' fontSize='small' />
        </Button>
      </Tooltip>
      <Tooltip
        enterDelay={750}
        title={
          isHidden ? (t(dashboardMessages.reportsCard.actions.show) as string) : (t(dashboardMessages.reportsCard.actions.hide) as string)
        }
      >
        <Button className={classes.button} size='small' onClick={onHide}>
          <CloseIcon color='secondary' fontSize='small' />
        </Button>
      </Tooltip>
    </>
  );
};

export default ReportsTableRowActions;
