import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';

import users from 'api/users/users';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import snackbarMessages from 'translations/common/snackbar.mjs';

import type { TwoFAFormInput } from '../../_constants/inputKeys';

export interface HookParams {
  form: UseFormReturn<TwoFAFormInput, any>;
  shouldBlockRequest: boolean;
  restorePreviousSwitchState: () => void;
  setIsModalShown: Dispatch<SetStateAction<boolean>>;
}

export const useTwoFAQuery = ({ form, shouldBlockRequest, restorePreviousSwitchState, setIsModalShown }: HookParams) => {
  const { refreshUserInfo } = useAuthContext();
  const { showSnackbar } = useShowSnackbar();

  const handleOnSuccess = useCallback(async () => {
    await refreshUserInfo();
  }, []);

  const handleOnError = useCallback(() => {
    showSnackbar({
      variant: 'error',
      translationArray: snackbarMessages.failure,
    });
    restorePreviousSwitchState();
  }, [restorePreviousSwitchState, showSnackbar]);

  const updateTwoFAMutation = useMutation(QUERY_KEYS.TWO_FA, users.updateTwoFA(), {
    onSuccess: handleOnSuccess,
    onError: handleOnError,
    retry: false,
  });

  const handleSubmit = useCallback(async (data: TwoFAFormInput) => {
    if (shouldBlockRequest) return;
    await updateTwoFAMutation.mutateAsync(data);
  }, []);

  const handleConfirmSubmission = useCallback(() => {
    form.handleSubmit(data => handleSubmit(data))();
    setIsModalShown(false);
  }, [setIsModalShown, form.handleSubmit]);

  const handleAbortSubmission = useCallback(() => {
    restorePreviousSwitchState();
    setIsModalShown(false);
  }, [setIsModalShown, restorePreviousSwitchState]);

  return { isLoading: updateTwoFAMutation.isLoading, handleConfirmSubmission, handleAbortSubmission };
};
