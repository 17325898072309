import type { FC } from 'react';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { IconAlertProps } from 'constants/_types/IconAlertProps';

import useStyles from './IconWarningAlert.styles';

const IconWarningAlert: FC<IconAlertProps> = ({ isBoxShadow = false }) => {
  const { classes } = useStyles({ isBoxShadow });

  return (
    <div className={classes.root}>
      <WarningIcon />
    </div>
  );
};

export default IconWarningAlert;
