import parseCoreService from 'services/parseCoreService/parseCoreService';
import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

import type { CoreServiceForGroup, CoreServiceForGroupDTO } from '../coreServiceGroups.types';

export const parseServicesForGroupForFE = (raw: CoreServiceForGroupDTO): CoreServiceForGroup => ({
  code: raw.code,
  name: parseDynamicTranslatableName(raw.name),
  preselectedServices: raw.preselected_services,
  id: raw.id,
  coreServices: raw.core_services.map(parseCoreService),
});
