import { FC, useMemo } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { DefaultApiResponse, PatientLegacy } from 'api/_types';
import patients, { getPatientKeyGeneratorLegacy } from 'api/patient/patients';
import InputDropdown from 'components/UI/molecules/InputDropdown/InputDropdown';
import { PatientStatus } from 'constants/_types/PatientStatusesArray';
import patientStatusOptions from 'constants/dropdownOptions/patientStatus';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import translateOptions from 'services/translateOptions/translateOptions';

import useStyles from './PatientStatusDropdown.styles';

export type Props = {
  patientId: number;
};

const PatientStatusDropdown: FC<Props> = ({ patientId }) => {
  const [canChangePatient] = usePermissionCheck(['change_patient']);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => getPatientKeyGeneratorLegacy(patientId, true), [patientId]);

  const statusMutation = useMutation(patients.updatePatientStatus(patientId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      const currentData: DefaultApiResponse<PatientLegacy> | undefined = queryClient.getQueryData(queryKey);
      if (currentData) queryClient.setQueryData(queryKey, { data: { ...currentData.data, status: newStatus } });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { isLoading, data } = useQuery(queryKey, patients.getPatientLegacy(patientId, true), {
    refetchOnWindowFocus: false,
  });

  const onStatusChange = ({ target }: SelectChangeEvent<unknown>) => {
    statusMutation.mutate(target.value as PatientStatus);
  };

  const { classes } = useStyles();

  return (
    <div className={classes.twoRows}>
      <InputDropdown
        disabled={isLoading || !canChangePatient}
        options={translateOptions(t, patientStatusOptions)}
        value={data?.data?.status || ''}
        onChange={onStatusChange}
      />
    </div>
  );
};

export default PatientStatusDropdown;
