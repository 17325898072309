import type { Payer } from 'api/payers/types';

export const reorderSelfPayerFirst = (data: Payer[] | undefined) => {
  if (!data || !data.length) return null;
  if (data.length === 1) return data;

  const selfPayerIndex = data?.findIndex(({ isSelfPayer }) => isSelfPayer);
  const dataCopy = [...data];
  [dataCopy[0], dataCopy[selfPayerIndex]] = [dataCopy[selfPayerIndex], dataCopy[0]];

  return dataCopy;
};
