import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 0,
    gap: theme.spacing(2),
  },
  message: {
    color: theme.palette.text.secondary,
  },
  titleLink: {
    color: theme.palette.secondary.main,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  received: {
    color: theme.palette.table.warning.main,
    borderColor: theme.palette.table.warning.main,
  },
  missed: {
    color: theme.palette.table.danger.main,
    borderColor: theme.palette.table.danger.main,
  },
  completeDocumentation: {
    color: theme.palette.table.success.main,
    borderColor: theme.palette.table.success.main,
  },
  closed: {
    color: theme.palette.table.closed.main,
    borderColor: theme.palette.table.closed.main,
  },
  listItem: {
    color: theme.palette.error.main,
    fontWeight: 800,
  },
}));

export default useStyles;
