import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ gridSpacing: number }>()((theme, { gridSpacing }) => ({
  root: {
    padding: theme.spacing(gridSpacing),
    '& > div': {
      maxWidth: `calc(100% + ${theme.spacing(gridSpacing)})`,
    },
  },
}));

export default useStyles;
