import type { FC } from 'react';

import { cloneDeep } from 'lodash';

import ActivateAccountPage from 'components/pages/_auth/ActivateAccountPage/ActivateAccountPage';
import LoginPage from 'components/pages/_auth/LoginPage/LoginPage';
import RegisterPage from 'components/pages/_auth/RegisterPage/RegisterPage';
import RegisterSuccessPage from 'components/pages/_auth/RegisterSuccessPage/RegisterSuccessPage';
import ResetPasswordFormPage from 'components/pages/_auth/ResetPasswordFormPage/ResetPasswordFormPage';
import ResetPasswordFormSuccessPage from 'components/pages/_auth/ResetPasswordFormSuccessPage/ResetPasswordFormSuccessPage';
import ResetPasswordRequestPage from 'components/pages/_auth/ResetPasswordRequestPage/ResetPasswordRequestPage';
import ResetPasswordSuccessPage from 'components/pages/_auth/ResetPasswordSuccessPage/ResetPasswordSuccessPage';
import ProfessionalAlarmSinglePage from 'components/pages/_professional/ProfessionalAlarmSinglePage/ProfessionalAlarmSinglePage';
import ProfessionalAlarmsListPage from 'components/pages/_professional/ProfessionalAlarmsListPage/ProfessionalAlarmsListPage';
import ProfessionalDashboard from 'components/pages/_professional/ProfessionalDashboard/ProfessionalDashboard';
import ProfessionalEcarePhoneCallSinglePage from 'components/pages/_professional/ProfessionalEcarePhoneCallSinglePage/ProfessionalEcarePhoneCallSinglePage';
import ProfessionalEcarePhoneCallsListPage from 'components/pages/_professional/ProfessionalEcarePhoneCallsListPage/ProfessionalEcarePhoneCallsListPage';
import ProfessionalECareOrderSinglePage from 'components/pages/_professional/ProfessionalEOrderSinglePage/ProfessionalECareOrderSinglePage';
import ProfessionalEOrdersListPage from 'components/pages/_professional/ProfessionalEOrdersListPage/ProfessionalEOrdersListPage';
import ProfessionalLayout from 'components/pages/_professional/ProfessionalLayout/ProfessionalLayout';
import ProfessionalPatientSinglePage from 'components/pages/_professional/ProfessionalPatientSinglePage/ProfessionalPatientSinglePage';
import ProfessionalPatientsListPage from 'components/pages/_professional/ProfessionalPatientsListPage/ProfessionalPatientsListPage';
import ProfessionalTechnicalIssuesListPage from 'components/pages/_professional/ProfessionalTechnicalIssuesListPage/ProfessionalTechnicalIssuesListPage';
import ProfessionalTechnicalIssuesSinglePage from 'components/pages/_professional/ProfessionalTechnicalIssuesSinglePage/ProfessionalTechnicalIssuesSinglePage';
import ServicesGroupPage from 'components/pages/_userServices/ServicesGroupPage/ServicesGroupPage';
import ServicesLayout from 'components/pages/_userServices/ServicesLayout/ServicesLayout';
import ServicesPage from 'components/pages/_userServices/ServicesPage/ServicesPage';
import ServicesTypesPage from 'components/pages/_userServices/ServicesTypesPage/ServicesTypesPage';
import ServicesUserProfilePage from 'components/pages/_userServices/ServicesUserProfilePage/ServicesUserProfilePage';
import DashboardPage from 'components/pages/DashboardPage/DashboardPage';
import HelpPage from 'components/pages/HelpPage/HelpPage';
import MyProfilePage from 'components/pages/MyProfilePage/MyProfilePage';
import OrderServicePage from 'components/pages/OrderServicePage/OrderServicePage';
import ReportPage from 'components/pages/ReportPage/ReportPage';
import ReportSinglePage from 'components/pages/ReportSinglePage/ReportSinglePage';
import SettingsPage from 'components/pages/SettingsPage/SettingsPage';
import SymptomCheckerPage from 'components/pages/SymptomCheckerPage/SymptomCheckerPage';
import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';
import breadcrumbsMessages from 'translations/common/breadcrumbs.mjs';
import symptomCheckerReports from 'translations/specific/symptomCheckerReports.mjs';

import PATHS, { AUTH_PATHS, INTERNAL_PATHS, PROFESSIONAL_PATHS, PROFESSIONAL_PREFIX, SERVICES_PATHS, SERVICES_PREFIX } from './PATHS';

export type Breadcrumb = {
  translationArray: string[];
  translationParams?: Record<string, string | number>;
  path: string; // should be an empty string for the last array item
  isCurrent: boolean; // should be true only for the last array item
};

export type Route = {
  path: string;
  element: FC<{
    routeConfig?: Route & {
      subRoutes?: Route[];
    };
  }>;
  index?: boolean;
  scrollRestoration?: boolean;
  breadcrumbs?: Breadcrumb[];
  showAlerts?: boolean;
  formMaxStep?: number;
};

export type NestedRoute = Route & {
  subRoutes?: Route[] | NestedRoute[];
};

export type Routes = {
  allRoutes: Route[];
  authRoutes: Route[];
  internalRoutes: Route[];
};

const authRoutes: NestedRoute[] = [
  { path: AUTH_PATHS.AUTH_LOGIN, element: LoginPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_REGISTER, element: RegisterPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_REGISTER_SUCCESS, element: RegisterSuccessPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_REQUEST, element: ResetPasswordRequestPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_FORM, element: ResetPasswordFormPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_SUCCESS, element: ResetPasswordSuccessPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_RESET_PASSWORD_FORM_SUCCESS, element: ResetPasswordFormSuccessPage, scrollRestoration: false },
  { path: AUTH_PATHS.AUTH_ACTIVATE_ACCOUNT, element: ActivateAccountPage, scrollRestoration: false },
];

const internalRoutes: NestedRoute[] = [
  { path: PATHS.ROOT, element: DashboardPage, scrollRestoration: false, showAlerts: true },
  { path: PATHS.ORDER_SERVICE, element: OrderServicePage, scrollRestoration: false, showAlerts: true, formMaxStep: 2 },
  { path: PATHS.SYMPTOM_CHECKER, element: SymptomCheckerPage, scrollRestoration: false, showAlerts: true },
  {
    path: INTERNAL_PATHS.MY_PROFILE,
    element: MyProfilePage,
    scrollRestoration: false,
    index: true,
    breadcrumbs: [{ translationArray: breadcrumbsMessages.myProfile, path: '', isCurrent: true }],
    showAlerts: true,
  },
  {
    path: INTERNAL_PATHS.REPORTS,
    element: ReportPage,
    scrollRestoration: false,
    breadcrumbs: [{ translationArray: symptomCheckerReports.reportsList.header, path: '', isCurrent: true }],
    showAlerts: true,
  },
  { path: INTERNAL_PATHS.REPORT_SINGLE, element: ReportSinglePage, scrollRestoration: false },
  {
    path: INTERNAL_PATHS.HELP,
    element: HelpPage,
    scrollRestoration: false,
    breadcrumbs: [{ translationArray: breadcrumbsMessages.help, path: '', isCurrent: true }],
    showAlerts: true,
  },
  {
    path: INTERNAL_PATHS.SETTINGS,
    element: SettingsPage,
    scrollRestoration: false,
    breadcrumbs: [{ translationArray: breadcrumbsMessages.settings, path: '', isCurrent: true }],
    showAlerts: true,
  },
  {
    path: PROFESSIONAL_PREFIX,
    element: ProfessionalLayout,
    scrollRestoration: false,
    showAlerts: true,
    subRoutes: [
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_DASHBOARD,
        element: ProfessionalDashboard,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_ORDER_LIST,
        element: ProfessionalEOrdersListPage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_order_list,
            path: '',
            isCurrent: false,
          },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_ORDER_SINGLE,
        element: ProfessionalECareOrderSinglePage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_order_list,
            path: `/${PATHS.PROFESSIONAL_ECARE_ORDER_LIST}`,
            isCurrent: false,
          },
          { translationArray: breadcrumbsMessages.pro_ecare_order_single, path: '', isCurrent: true },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUES_LIST,
        element: ProfessionalTechnicalIssuesListPage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_technical_issues_list,
            path: '',
            isCurrent: false,
          },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUE_SINGLE,
        element: ProfessionalTechnicalIssuesSinglePage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_technical_issues_list,
            path: `/${PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUES_LIST}`,
            isCurrent: false,
          },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_ALARMS_LIST,
        element: ProfessionalAlarmsListPage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_alarms_list,
            path: '',
            isCurrent: false,
          },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_ALARM_SINGLE,
        element: ProfessionalAlarmSinglePage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_alarms_list,
            path: `/${PATHS.PROFESSIONAL_ECARE_ALARMS_LIST}`,
            isCurrent: false,
          },
          { translationArray: breadcrumbsMessages.pro_ecare_alarm_single, path: '', isCurrent: true },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_PATIENTS,
        element: ProfessionalPatientsListPage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_patients_list,
            path: '',
            isCurrent: false,
          },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_PATIENT_SINGLE,
        element: ProfessionalPatientSinglePage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_patients_list,
            path: `/${PATHS.PROFESSIONAL_PATIENTS}`,
            isCurrent: false,
          },
          { translationArray: breadcrumbsMessages.pro_patient_single, path: '', isCurrent: true },
        ],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_PHONE_CALLS_LIST,
        element: ProfessionalEcarePhoneCallsListPage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [{ translationArray: breadcrumbsMessages.pro_ecare_phone_calls_list, path: '', isCurrent: true }],
      },
      {
        path: PROFESSIONAL_PATHS.PROFESSIONAL_ECARE_PHONE_CALL_SINGLE,
        element: ProfessionalEcarePhoneCallSinglePage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
        breadcrumbs: [
          {
            translationArray: breadcrumbsMessages.pro_ecare_phone_calls_list,
            path: `/${PATHS.PROFESSIONAL_ECARE_PHONE_CALLS_LIST}`,
            isCurrent: false,
          },
          { translationArray: breadcrumbsMessages.pro_ecare_phone_call_single, path: '', isCurrent: true },
        ],
      },
    ],
  },
  {
    path: SERVICES_PREFIX,
    element: ServicesLayout,
    scrollRestoration: false,
    showAlerts: true,
    subRoutes: [
      {
        path: SERVICES_PATHS.SERVICES,
        element: ServicesPage,
        scrollRestoration: false,
        showAlerts: true,
        index: true,
      },
      {
        path: SERVICES_PATHS.SERVICES_SUBTYPE,
        element: ServicesTypesPage,
        scrollRestoration: false,
        showAlerts: true,
      },
      {
        path: SERVICES_PATHS.SERVICES_USER_PROFILE,
        element: ServicesUserProfilePage,
        scrollRestoration: false,
        showAlerts: true,
      },
      {
        path: SERVICES_PATHS.SERVICES_GROUP,
        element: ServicesGroupPage,
        scrollRestoration: false,
        showAlerts: true,
      },
    ],
  },
];

const allRoutes: Route[] = [];
const collectAllRoutes = (routesArr: NestedRoute[], prefix = ''): void => {
  routesArr.forEach(route => {
    if (route.subRoutes) {
      allRoutes.push(route);
      collectAllRoutes(route.subRoutes, route.path);
      return;
    }
    // eslint-disable-next-line no-param-reassign
    route.path = `${prefix}/${removeTrailingSlash(route.path)}`;
    allRoutes.push(route);
  });
};
collectAllRoutes(cloneDeep([...authRoutes, ...internalRoutes]));

const routes: Routes = { allRoutes, authRoutes, internalRoutes };

export default routes;
