import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'block',
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    textAlign: 'right',
  },
  value: {
    marginTop: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  noPaddingX: {
    padding: '5px 0',
  },
  standardColumnWrapper: {
    display: 'grid',
    alignContent: 'flex-start',
  },
  container: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(3, 4),
    alignContent: 'flex-start',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  patientButton: {
    justifySelf: 'flex-start',
  },
  multiDefinitionList: {
    ul: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      ':last-of-type': {
        borderBottom: 0,
      },
    },
  },
  icon: {
    cursor: 'pointer',
    width: 15,
  },
  uneven: {
    gridTemplateColumns: '66% 33%',
  },
  borderRight: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(3),
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default useStyles;
