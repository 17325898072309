import type { FC, ReactNode } from 'react';

import useStyles from './DisabledWrapper.styles';

export type Props = {
  disabled: boolean;
  children: ReactNode;
};

const DisabledWrapper: FC<Props> = ({ children, disabled }) => {
  const { classes } = useStyles({ disabled });
  return <div className={classes.root}>{children}</div>;
};

export default DisabledWrapper;
