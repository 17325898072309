import type { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useGlobalSearchStorage from 'storages/globalSearchStorage';

import useStyles from './GlobalSearchOpenMobileButton.styles';

export const testId = createTestIdObject('GlobalSearchOpenMobileButton', {
  button: 'button',
});

type Props = {
  disabled: boolean;
};

const GlobalSearchOpenMobileButton: FC<Props> = ({ disabled }) => {
  const { showGlobalSearch } = useGlobalSearchStorage();

  const { classes } = useStyles();

  return (
    <IconButton className={classes.searchButton} data-testid={testId.button} disabled={disabled} onClick={showGlobalSearch}>
      <SearchIcon className={classes.searchIcon} />
    </IconButton>
  );
};

export default GlobalSearchOpenMobileButton;
