import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreServiceSubtypes from 'api/coreServiceSubtypes/coreServiceSubtypes';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import BaseColumnCellRenderer from 'components/UI/molecules/BaseColumnCellRenderer/BaseColumnCellRenderer';
import { ResultsRow, ResultsRowValue } from 'components/UI/organisms/ServicesTypeView/ServicesTypeView';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

type UseColumnsParams = {
  typeId: string;
  toggleSelected: (id: number | string) => void;
  isSearchActive: boolean;
};

const useColumns = ({ typeId, toggleSelected, isSearchActive }: UseColumnsParams) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const { data: headersData, isLoading: isSubtypesLoading } = useQuery(
    [QUERY_KEYS.CORE_SERVICE_SUBTYPES, createQueryKeyFromObject({ patient: patient?.id, typeId, selectedPayersList })],
    coreServiceSubtypes.getServiceSubtypes({
      patientId: patient?.id,
      masterType: typeId,
      payers: selectedPayersList,
    }),
    {
      enabled: !!patient?.id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const columns: ColumnDef<ResultsRow | null>[] | null = useMemo(() => {
    if (!headersData) return null;
    return headersData.sort().map(({ id, name }) => ({
      accessorKey: `${id}`,
      cell: info => {
        const value = info.getValue() as unknown as ResultsRowValue | null;
        if (!value) return '';
        return (
          <BaseColumnCellRenderer
            config={{ cellSize: 120 }}
            entity={{ id: value.id, name: value.name, searchableName: t(value.name.key, value.name.defaultValue) }}
            isMatched={value.isMatched}
            isSearchActive={isSearchActive}
            isSelected={!!value.isSelected}
            toggleSelected={toggleSelected}
            truncateLinesNumber={3}
          />
        );
      },
      header: t(name.key, name.defaultValue) || name.defaultValue,
    }));
  }, [headersData, t, isSearchActive]);

  return { columns, isSubtypesLoading };
};

export default useColumns;
