import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';

import ColumnsDisplayFallback from 'components/UI/molecules/ColumnsDisplayFallback/ColumnsDisplayFallback';
import { ResultsRow } from 'components/UI/organisms/ServicesTypeView/ServicesTypeView';
import { getBrowserType } from 'services/getBrowserType/getBrowserType';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import servicesMessages from 'translations/specific/services.mjs';

import useStyles from './ServicesTable.styles';

type Props = {
  flatData: ResultsRow[];
  isFetching: boolean;
  activeColumnId: string;
  fetchNextPage: () => void;
  columns: ColumnDef<ResultsRow | null>[] | null;
  completedColumns: string[];
  readyToFetch: boolean;
};

const ServicesTable: FC<Props> = ({ flatData, isFetching, activeColumnId, fetchNextPage, columns, completedColumns, readyToFetch }) => {
  const { t } = useTranslation();
  const isFirefox = useMemo(() => getBrowserType() === 'firefox', []);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const table = useReactTable({
    data: flatData,
    columns: columns || [],
    getCoreRowModel: getCoreRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (scrollHeight - scrollTop - clientHeight < 300 && readyToFetch && !isFetching && columns?.length !== completedColumns.length) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, columns, completedColumns],
  );

  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached, selectedPayersList]);

  const { classes, cx } = useStyles({ tableHeight: 450, isFirefox });

  return (
    <div className={classes.root} ref={tableContainerRef} onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}>
      {!columns?.length || !flatData.length ? (
        <ColumnsDisplayFallback fallbackMessage={t(servicesMessages.noResultsForPaymentType)} />
      ) : (
        <table className={classes.table}>
          <thead className={classes.thead}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const isActive = header.id.toString() === activeColumnId;
                  return (
                    <th
                      className={cx(classes.th, isActive && classes.thActive)}
                      colSpan={header.colSpan}
                      key={header.id}
                      style={{ width: 120 }}
                    >
                      <Typography className={classes.headerCaption} color={isActive ? 'primary' : undefined} variant='caption'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow, rowIndex) => {
              const row = rows[virtualRow.index] as Row<ResultsRow>;
              const visibleCells = row.getVisibleCells();
              const isLastRow = rowIndex + 1 === virtualRows.length;
              return (
                <tr key={row.id}>
                  {visibleCells.map(cell => {
                    const isActive = cell.column.id.toString() === activeColumnId;
                    return (
                      <td
                        className={cx(isActive && classes.activeBorderNode, !isFetching && isLastRow && isActive && classes.lastActiveTd)}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {isFetching && (
              <tr className={classes.loaderWrapper}>
                <td>
                  <CircularProgress />
                </td>
              </tr>
            )}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

const ServicesTableMemoized = memo(ServicesTable);

export { ServicesTableMemoized };

export default ServicesTable;
