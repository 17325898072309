import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import servicesMessages from 'translations/specific/services.mjs';

export const testId = createTestIdObject('ColumnsDisplaySet', {
  fallback: 'fallback',
});

export type Props = {
  fallbackMessage: string | undefined;
};

const ColumnsDisplayFallback: FC<Props> = ({ fallbackMessage }) => {
  const { t } = useTranslation();

  return (
    <Typography data-testid={testId.fallback} textAlign='center' variant='body1'>
      {fallbackMessage || t(servicesMessages.noResultsForPaymentType)}
    </Typography>
  );
};

export default ColumnsDisplayFallback;
