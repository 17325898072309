import type { ChangeEvent, FC, RefObject } from 'react';

export interface Props {
  accept: string;
  inputRef: RefObject<HTMLInputElement>;
  multiple?: boolean;
  name?: string;
  value?: any;
  onChange: ({ target }: ChangeEvent<HTMLInputElement>) => void;
}

const InputFileHiddenNative: FC<Props> = ({ inputRef, multiple = false, ...rest }) => (
  <input hidden multiple={multiple} ref={inputRef} type='file' {...rest} />
);

export default InputFileHiddenNative;
