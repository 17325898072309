import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  twoRows: {
    display: 'grid',
    gridGap: theme.spacing(1),
    justifyContent: 'flex-start',
  },

  label: {
    color: theme.palette.grey[500],
    fontSize: '0.7rem',
  },
}));

export default useStyles;
