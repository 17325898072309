import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  twoRows: {
    display: 'grid',
    gridGap: theme.spacing(1),
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
