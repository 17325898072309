import type { FC } from 'react';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as HelpIcon } from 'assets/icons/hub/active/lifebuoy.svg';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import ContactCard from 'components/UI/organisms/ContactCard/ContactCard';
import FaqCard from 'components/UI/organisms/FaqCard/FaqCard';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import helpMessages from 'translations/specific/help.mjs';

import TitleExtraContent from './_components/TitleExtraContent/TitleExtraContent';

const HelpPage: FC = () => {
  const { t } = useTranslation();
  const { brandInfo } = useBrandContext();

  const contactData = useMemo(() => {
    const brandContactInfo = {
      logoSrc: brandInfo?.brand.logo_left || '',
      logoAlt: brandInfo?.brand.full_company_name || '',
      phoneNumber: brandInfo?.brand.inquiry_phone || '',
      address: brandInfo?.brand.inquiry_address || '',
      email: brandInfo?.brand.inquiry_email || '',
    };
    const generalContactInfo = {
      logoSrc: brandInfo?.brand.logo_right || '',
      logoAlt: brandInfo?.name || '',
      phoneNumber: brandInfo?.it_helpline_phone || '',
      address: '',
      email: brandInfo?.it_helpline_email || '',
    };

    if (brandInfo?.brand) {
      return {
        brandContactInfo,
        generalContactInfo,
      };
    }
    return {
      generalContactInfo,
    };
  }, [brandInfo]);

  const phoneNumber = useMemo(() => contactData.brandContactInfo?.phoneNumber ?? contactData.generalContactInfo.phoneNumber, [contactData]);
  const hasInternalBranding = useMemo(() => !!contactData.brandContactInfo, [contactData]);

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitle
        extraContentComponent={<TitleExtraContent phoneNumber={phoneNumber} />}
        headerLabel={t(helpMessages.header)}
        icon={<HelpIcon />}
      />
      <FaqCard />
      <ContactCard data={contactData} hasInternalBranding={hasInternalBranding} />
    </MainContentWrapper>
  );
};

export default HelpPage;
