import type { FC } from 'react';

import { Collapse, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Avatar from 'components/UI/atoms/Avatar/Avatar';
import useLayoutStorage from 'storages/layoutStorage';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './PatientCardSmall.styles';

type Props = {
  firstName: string;
  lastName: string;
  age: number | null;
  imageUrl?: string;
};

const PatientCardSmall: FC<Props> = ({ firstName, lastName, age, imageUrl }) => {
  const { t } = useTranslation();
  const { isOpen } = useLayoutStorage();
  const { classes } = useStyles({ isOpen });

  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <Avatar imageUrl={imageUrl} />
      </div>
      <Collapse in={isOpen}>
        <Typography align='center' className={classes.name} component='p' variant='subtitle3'>{`${firstName} ${lastName}`}</Typography>
        {age && (
          <Typography align='center' component='p' variant='subtitle3'>
            {t(patientMessages.age, { age })}
          </Typography>
        )}
      </Collapse>
    </div>
  );
};

export default PatientCardSmall;
