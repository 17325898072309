import { FC } from 'react';

import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ECareSosInternalNote } from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes.types';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

type Props = {
  note: ECareSosInternalNote;
};

const CreatedByTooltip: FC<Props> = ({ note }) => {
  const { t } = useTranslation();

  const creatorData = [
    {
      label: t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.creationTime),
      value: format(note.creationTime, DATE_FORMATS.DISPLAY_TIME),
    },
    {
      label: t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.user),
      value: note.createdBy?.email || t(generalMessages.noData),
    },
  ];

  return (
    <Box sx={{ mr: 1 }}>
      <InfoIconWithTooltip content={<DefinitionList columns={1} definitions={creatorData} fontSize='small' />} />
    </Box>
  );
};

export default CreatedByTooltip;
