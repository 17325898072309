import { TranslationKey } from 'constants/_types/TranslationKey';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

export const availableECareStatuses = ['NEW', 'IN_PROGRESS', 'CLARIFICATION_REQUIRED', 'ACTIVE', 'SUSPENDED', 'ENDED'] as const;

export type ECareStatusT = typeof availableECareStatuses[number];

export const eCareStatusDictionary: Record<ECareStatusT, TranslationKey> = {
  NEW: proECareOrderMessages.status.new,
  CLARIFICATION_REQUIRED: proECareOrderMessages.status.clarificationRequired,
  IN_PROGRESS: proECareOrderMessages.status.inProgress,
  ACTIVE: proECareOrderMessages.status.active,
  SUSPENDED: proECareOrderMessages.status.suspended,
  ENDED: proECareOrderMessages.status.ended,
};

const eCareImplementationStatus = ['PENDING', 'IN_PROGRESS', 'FINISHED'];
export type ECareImplementationStatusT = typeof eCareImplementationStatus[number];
export const eCareImplementationStatusDictionary: Record<ECareImplementationStatusT, TranslationKey> = {
  PENDING: proECareOrderMessages.implementationStatus.pending,
  IN_PROGRESS: proECareOrderMessages.implementationStatus.inProgress,
  FINISHED: proECareOrderMessages.implementationStatus.finished,
};

export const isECareStatusT = (status: ECareStatusT): status is ECareStatusT => availableECareStatuses.includes(status);
