import type { FC } from 'react';
import { useMemo } from 'react';

import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputRadioGroup from 'components/UI/molecules/InputRadioGroup/InputRadioGroup';
import type { RadioOption } from 'constants/_types/RadioOptions';
import type { DateSoonOptions } from 'constants/_types/SelectedServices';
import validationMessages from 'translations/common/validation.mjs';

interface Props {
  name: string;
  control: Control<any>;
  radioOptions: RadioOption<DateSoonOptions>[];
  title?: string;
  defaultValue?: string;
  tiny?: boolean;
  inputProps?: {
    disabled?: boolean;
    required?: boolean;
  };
  rules?: ValidationRule<any>;
}

const FormInputRadioGroup: FC<Props> = ({
  name,
  control,
  radioOptions,
  title,
  defaultValue = '',
  tiny = false,
  inputProps = {},
  rules = {},
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: inputProps?.required, message: t(validationMessages.required_consent) }, ...rules }),
    [rules, inputProps?.required, i18n.language],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <InputRadioGroup
          defaultValue={defaultValue}
          error={fieldState.error?.message}
          radioOptions={radioOptions}
          tiny={tiny}
          title={title}
          {...inputProps}
          {...field}
          value={field.value || ''} // necessary to enable to clear radio to init value
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputRadioGroup;
