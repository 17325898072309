import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface Params {
  subtypesData: DropdownOption[];
  preselectedSubtypeId: string | undefined;
  selectedSubtypesNames: string[];
}

export const prepareSelectedServiceSubtypesIds = ({ subtypesData, preselectedSubtypeId, selectedSubtypesNames }: Params) => {
  if (!subtypesData?.length || !selectedSubtypesNames?.length) return [];

  if (preselectedSubtypeId) return [preselectedSubtypeId];

  return selectedSubtypesNames.map(selectedSubtypeName => {
    const foundSelectedSubtype = subtypesData.find(({ value }) => `${value}` === selectedSubtypeName);
    return foundSelectedSubtype?.meta?.id ?? '';
  });
};
