import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ headersCount: number }>()((theme, { headersCount }) => ({
  rowContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(${headersCount}, ${100 / headersCount}%)`,
    gridGap: theme.spacing(1),
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    minHeight: '40px',
  },
}));

export default useStyles;
