import type { FC } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import ResetPasswordForm from 'components/UI/organisms/ResetPasswordForm/ResetPasswordForm';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ResetPasswordFormPage: FC = () => {
  usePageConfig();

  const { uid, token } = useParams();
  if (!uid || !token) return <Navigate to={`/${PATHS.AUTH_LOGIN}`} />;
  return <ResetPasswordForm token={token} uid={uid} />;
};

export default ResetPasswordFormPage;
