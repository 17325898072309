import { makeStyles } from 'tss-react/mui';

import { PSEUDOELEMENT_ACTIVE_BORDER_BASE } from 'constants/styles/PSEUDOELEMENT_ACTIVE_BORDER_BASE';

interface StylesProps {
  isLoading: boolean;
  isTableActive: boolean;
}

const useStyles = makeStyles<StylesProps>()((theme, { isLoading, isTableActive }) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridGap: theme.spacing(2),
    position: 'relative',
  },

  rootBorder: {
    '&:before': {
      ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
      top: '-2px',
      left: '-2px',
      height: '2px',
      width: 'calc(100% + 4px)',
    },
    '&:after': {
      ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
      bottom: '-2px',
      right: '-2px',
      height: '2px',
      width: 'calc(100% + 4px)',
    },
  },

  rootBorderNode: {
    '&:before': {
      ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
      top: 0,
      left: '-2px',
      height: 'calc(100% + 2px)',
      width: '2px',
    },
    '&:after': {
      ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
      top: 0,
      right: '-2px',
      height: 'calc(100% + 2px)',
      width: '2px',
    },
  },

  table: {
    minHeight: isLoading ? '100px' : 'unset',
  },
}));

export default useStyles;
