import { makeStyles } from 'tss-react/mui';

import { InputSizes } from 'constants/_types/InputSizes';
import { INPUT_HEIGHT } from 'constants/styles/INPUT_HEIGHT';

interface StyleProps {
  clearable?: boolean;
  dropdownMaxWidth: number | undefined;
  dropdownMinWidth: number | undefined;
  dropdownMobileMinWidth: number | undefined;
  hasFullHeight: boolean;
  size?: InputSizes;
  shouldIncreaseDropdownHeight: boolean;
}

const useStyles = makeStyles<StyleProps>()(
  (theme, { clearable, dropdownMaxWidth, dropdownMinWidth, dropdownMobileMinWidth, hasFullHeight, size, shouldIncreaseDropdownHeight }) => {
    const fontSize = size === 'small' ? '0.8125rem' : 'inherit';

    return {
      label: {
        fontSize,
        maxWidth: `calc(100% - ${theme.spacing(6)})`,
      },

      formControl: {
        height: hasFullHeight ? '100%' : 'inherit',
        minWidth: dropdownMinWidth ?? 'unset',

        [theme.breakpoints.down('md')]: {
          minWidth: dropdownMobileMinWidth ?? 'unset',
        },
      },

      selectWrapper: {
        display: 'grid',
        gridTemplateColumns: clearable ? '1fr 43px' : '1fr',
        gridGap: theme.spacing(2),
        height: hasFullHeight ? '100%' : 'inherit',
        minHeight: INPUT_HEIGHT,

        '& > div > div[role="button"]': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: dropdownMaxWidth ?? 'unset',
        },
      },

      select: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'pre',

        '& > div': {
          fontSize: size === 'small' ? '0.8125rem' : 'inherit',
          padding: theme.spacing(size === 'small' ? 1.75 : 2),
          minHeight: shouldIncreaseDropdownHeight ? `${theme.spacing(7.5)} !important` : 'inherit',
          display: 'flex',
          alignItems: 'center',

          [theme.breakpoints.down('md')]: {
            minHeight: shouldIncreaseDropdownHeight ? `${theme.spacing(5)} !important` : 'inherit',
          },
        },
      },
    };
  },
);

export default useStyles;
