import type { FC } from 'react';

import { IconButton } from '@mui/material';

import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';

import useStyles from './RemoveItemButton.styles';

export interface Props {
  onClick?: () => void;
  size?: 'medium' | 'small' | 'large';
  className?: string;
  testId?: string;
}

const RemoveItemButton: FC<Props> = ({ onClick, className, size = 'medium', testId }) => {
  const { classes, cx } = useStyles({ size });

  return (
    <IconButton className={cx(classes.root, className)} data-testid={testId} size={size} onClick={onClick}>
      <MinusIcon />
    </IconButton>
  );
};

export default RemoveItemButton;
