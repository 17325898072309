import type { ChangeEvent, FC, RefObject } from 'react';
import { useCallback } from 'react';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import InputFileHiddenNative from 'components/UI/molecules/InputFileHiddenNative/InputFileHiddenNative';

import useStyles from './InputFileHidden.styles';

export interface Props {
  acceptedFormats: string;
  error?: string;
  inputRef: RefObject<HTMLInputElement>;
  multiple?: boolean;
  name?: string;
  value?: any;
  onChange: ({ target }: ChangeEvent<HTMLInputElement>) => void;
  onChangeExtended?: ({ target }: ChangeEvent<HTMLInputElement>) => void;
}

const InputFileHidden: FC<Props> = ({ acceptedFormats, error, inputRef, name, multiple, value, onChange, onChangeExtended }) => {
  const { classes } = useStyles({ error: !!error });

  const handleOnChange = useCallback(e => {
    onChangeExtended?.(e);
    onChange(e.target.files);
  }, []);

  return (
    <>
      <InputFileHiddenNative
        accept={acceptedFormats}
        inputRef={inputRef}
        multiple={multiple}
        name={name}
        value={value}
        onChange={handleOnChange}
      />
      <InputFooter className={classes.footer} error={error} />
    </>
  );
};

export default InputFileHidden;
