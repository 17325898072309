import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import type { TwoFAFormInput } from 'components/UI/molecules/TwoFASwitch/_constants/inputKeys';

import { parseTwoFAForBE } from '../parseTwoFAForBE/parseTwoFAForBE';
import type { TwoFADto } from '../types';
import { endpoints } from '../users';

type UpdateTwoFAResponse = TwoFADto;
type UpdateTwoFA = (twoFA: TwoFAFormInput) => Promise<DefaultApiResponse<UpdateTwoFAResponse>>;
type UpdateTwoFAQuery = (parsedData: TwoFADto) => Promise<DefaultApiResponse<UpdateTwoFAResponse>>;

export const updateTwoFA = (): UpdateTwoFA => twoFA => {
  const parsedData = parseTwoFAForBE(twoFA);

  return axios.post<UpdateTwoFAQuery, DefaultApiResponse<UpdateTwoFAResponse>>(endpoints.updateTwoFA, parsedData);
};
