const proECareOrderMessages = {
  title: ['proECareOrder>>title', 'Zlecenie teleopieki'],
  buttons: {
    addOrder: ['proECareOrder>>buttons>>addOrder', 'Dodaj zlecenie'],
    availableDevices: ['proECareOrder>>buttons>>availableDevices', 'Pokaż rodzaje urządzeń'],
    showDevices: ['proECareOrder>>buttons>>showDevices', 'Pokaż urządzenia pacjenta'],
  },
  orderStatus: ['proECareOrder>>orderStatus', 'Status zlecenia'],
  healthDetails: {
    orderDate: ['proECareOrder>>healthDetails>>orderDate', 'Data zlecenia'],
    emergencyContactPhone: ['proECareOrder>>healthDetails>>emergencyContactPhone', 'Telefon kontaktowy do osoby bliskiej'],
    phoneNumber: ['proECareOrder>>healthDetails>>phoneNumber', 'Telefon kontaktowy do odbiorcy usługi'],
    phoneNumberInfo: [
      'proECareOrder>>healthDetails>>phoneNumberInfo',
      'Uwaga: Rzeczywisty telefon pacjenta może być różny od tel. odbiorcy usługi. Ten telefon jest używany w systemie do powiązania urządzeń z pacjentem.',
    ],
    dwellingType: ['proECareOrder>>healthDetails>>dwellingType', 'Rodzaj mieszkania'],
    otherDwellingType: ['proECareOrder>>healthDetails>>otherDwellingType', 'Inny rodzaj mieszkania'],
    healthConditions: ['proECareOrder>>healthDetails>>healthConditions', 'Warunki zdrowotne'],
    healthProblems: ['proECareOrder>>healthDetails>>healthProblems', 'Problemy zdrowotne'],
    dysfunctions: ['proECareOrder>>healthDetails>>dysfunctions', 'Dysfunkcje'],
    lifestyle: ['proECareOrder>>healthDetails>>lifestyle', 'Styl życia'],
    lifemode: ['proECareOrder>>healthDetails>>lifemode', 'Tryb życia'],
    mobilityDisabilityDegree: ['proECareOrder>>healthDetails>>mobilityDisabilityDegree', 'Stopień niepełnoprawności ruchowej'],
    cognitiveDisabilityDegree: ['proECareOrder>>healthDetails>>cognitiveDisabilityDegree', 'Stopień niepełnoprawności poznawczej'],
    deviceTypes: ['proECareOrder>>healthDetails>>deviceTypes', 'Rodzaje urządzeń'],
    serviceStartDate: ['proECareOrder>>healthDetails>>serviceStartDate', 'Data Rozpoczęcia Usługi'],
    serviceEndDate: ['proECareOrder>>healthDetails>>serviceEndDate', 'Data Zakończenia Usługi'],
    serviceTerminationReason: ['proECareOrder>>healthDetails>>serviceTerminationReason', 'Przyczyna Zakończenia Usługi'],
    gotToPatientProfile: ['proECareOrder>>healthDetails>>gotToPatientProfile', 'Profil pacjenta'],
    typeOfDisease: ['proECareOrder>>healthDetails>>typeOfDisease', 'Rodzaj schorzeń'],
    diseaseCounter: ['proECareOrder>>healthDetails>>diseaseCounter', 'Schorzeń'],
  },
  eOrderTableHeaders: {
    id: ['proECareOrder>>eCareOrderTableHeaders>>id', 'Numer zlecenia'],
    orderDate: ['proECareOrder>>eCareOrderTableHeaders>>orderDate', 'Data utworzenia zlecenia'],
    firstName: ['proECareOrder>>eCareOrderTableHeaders>>firstName', 'Imię'],
    lastName: ['proECareOrder>>eCareOrderTableHeaders>>lastName', 'Nazwisko'],
    pesel: ['proECareOrder>>eCareOrderTableHeaders>>pesel', 'PESEL / Wiek'],
    address: ['proECareOrder>>eCareOrderTableHeaders>>address', 'Adres'],
    terytProvince: ['proECareOrder>>eCareOrderTableHeaders>>gmina', 'Gmina'],
    status: ['proECareOrder>>eCareOrderTableHeaders>>status', 'Status zlecenia'],
    callFrequency: ['proECareOrder>>eCareOrderTableHeaders>>callFrequency', 'Częstotliwość połączeń'],
    payer: ['proECareOrder>>eCareOrderTableHeaders>>payer', 'Gmina / Płatnik'],
    subscription: ['proECareOrder>>eCareOrderTableHeaders>>subscription', 'Abonament'],
    emergencyContactPhone: ['proECareOrder>>eCareOrderTableHeaders>>emergencyContactPhone', 'Telefon'],
    serviceStartDate: ['proECareOrder>>eCareOrderTableHeaders>>serviceStartDate', 'Start zlecenia'],
    serviceEndDate: ['proECareOrder>>eCareOrderTableHeaders>>serviceEndDate', 'Koniec zlecenia'],
    entity: ['proECareOrder>>eOrderTableHeaders>>entity', 'Gmina'],
    serviceTerminationReason: ['proECareOrder>>eOrderTableHeaders>>serviceTerminationReason', 'Przyczyna zakończenia usługi'],
    alertSystemContract: ['proECareOrder>>eOrderTableHeaders>>alertSystemContract', 'Numer kontraktu'],
    implementationStatus: ['proECareOrder>>eOrderTableHeaders>>implementationStatus', 'Status implementacji zlecenia'],
    projectUserNumber: ['proECareOrder>>eOrderTableHeaders>>projectUserNumber', 'Kod Pacjenta\xa0(Bitrix)'],
    postalCode: ['proECareOrder>>eOrderTableHeaders>>postalCode', 'Kod pocztowy'],
    city: ['proECareOrder>>eOrderTableHeaders>>city', 'Miasto'],
    street: ['proECareOrder>>eOrderTableHeaders>>street', 'Ulica i numer domu'],
  },
  deviceTableHeaders: {
    name: ['proECareOrder>>deviceTableHeaders>>name', 'Nazwa urządzenia'],
    code: ['proECareOrder>>deviceTableHeaders>>code', 'Kod urządzenia'],
    dateFrom: ['proECareOrder>>deviceTableHeaders>>dateFrom', 'Data\xa0od'],
    dateTo: ['proECareOrder>>deviceTableHeaders>>dateTo', 'Data\xa0do'],
    description: ['proECareOrder>>deviceTableHeaders>>description', 'Opis'],
  },
  filters: {
    id: ['proECareOrder>>filters>>id', 'Numer zlecenia'],
    orderDate: ['proECareOrder>>filters>>orderDate', 'Data utworzenia zlecenia'],
    firstName: ['proECareOrder>>filters>>firstName', 'Imię'],
    lastName: ['proECareOrder>>filters>>lastName', 'Nazwisko'],
    pesel: ['proECareOrder>>filters>>pesel', 'PESEL'],
    address: ['proECareOrder>>filters>>address', 'Adres'],
    terytProvince: ['proECareOrder>>filters>>gmina', 'Gmina'],
    status: ['proECareOrder>>filters>>status', 'Status zlecenia'],
    callFrequency: ['proECareOrder>>filters>>callFrequency', 'Częstotliwość połączeń'],
    payer: ['proECareOrder>>filters>>payer', 'Gmina / Płatnik'],
    subscription: ['proECareOrder>>filters>>subscription', 'Abonament'],
    emergencyContactPhone: ['proECareOrder>>filters>>emergencyContactPhone', 'Telefon'],
    serviceStartDate: ['proECareOrder>>filters>>serviceStartDate', 'Data rozpoczęcia usługi'],
    serviceEndDate: ['proECareOrder>>filters>>serviceEndDate', 'Data zakończenia usługi'],
    entity: ['proECareOrder>>filters>>entity', 'Gmina'],
    serviceTerminationReason: ['proECareOrder>>filters>>serviceTerminationReason', 'Przyczyna zakończenia usługi'],
    alertSystemContract: ['proECareOrder>>filters>>alertSystemContract', 'Numer kontraktu'],
    implementationStatus: ['proECareOrder>>filters>>implementationStatus', 'Status implementacji zlecenia'],
    projectUserNumber: ['proECareOrder>>filters>>projectUserNumber', 'Kod Pacjenta\xa0(Bitrix)'],
    postalCode: ['proECareOrder>>filters>>postalCode', 'Kod pocztowy'],
    city: ['proECareOrder>>filters>>city', 'Miasto'],
    street: ['proECareOrder>>filters>>street', 'Ulica i numer domu'],
    patientId: ['proECareOrder>>filters>>patientId', 'ID odbiorcy usługi'],
  },
  status: {
    generalConfirmation: ['proECareOrder>>status>>generalConfirmation', 'Czy potwierdzasz zmianę statusu'],
    new: ['proECareOrder>>status>>new', 'Nowy'],
    clarificationRequired: ['proECareOrder>>status>>clarificationRequired', 'Wymaga wyjaśnień'],
    inProgress: ['proECareOrder>>status>>inProgress', 'W trakcie realizacji'],
    active: ['proECareOrder>>status>>active', 'Aktywne'],
    accept: ['proECareOrder>>status>>accept', 'Zatwierdź'],
    suspended: ['proECareOrder>>status>>suspended', 'Zawieszone'],
    ended: ['proECareOrder>>status>>ended', 'Zakończone'],
  },
  implementationStatus: {
    pending: ['proECareOrder>>implementationStatus>>pending', 'Oczekujące na realizację'],
    inProgress: ['proECareOrder>>implementationStatus>>inProgress', 'W trakcie realizacji'],
    finished: ['proECareOrder>>implementationStatus>>finished', 'Zrealizowane'],
  },
  statusButton: {
    accept: ['proECareOrder>>statusButton>>accept', 'Zatwierdź'],
    clarificationRequired: ['proECareOrder>>statusButton>>clarificationRequired', 'Wymaga wyjaśnień'],
    active: ['proECareOrder>>statusButton>>active', 'Aktywuj'],
  },
  newEOrderDialog: {
    header: ['proECareOrder>>newECareOrderDialog>>header', 'Dodaj zlecenie'],
  },
  confirmationDialog: {
    confirmationDialogTitle: ['proECareOrder>>confirmationDialog>>confirmationDialogTitle', 'Przyjmij zlecenie'],
    confirmationDialogBody: ['proECareOrder>>confirmationDialog>>confirmationDialogBody', 'Czy na pewno chesz przyjąć zlecenie?'],
  },
  patientsTable: {
    id: ['proECareOrder>>patientsTable>>id', 'ID odbiorcy usługi'],
    firstName: ['proECareOrder>>patientsTable>>firstName', 'Imię'],
    lastName: ['proECareOrder>>patientsTable>>lastName', 'Nazwisko'],
    email: ['proECareOrder>>patientsTable>>email', 'Email'],
    pesel: ['proECareOrder>>patientsTable>>pesel', 'Pesel'],
    status: ['proECareOrder>>patientsTable>>status', 'Status'],
    address: ['proECareOrder>>patientsTable>>address', 'Ulica'],
    entity: ['proECareOrder>>patientsTable>>entity', 'Gmina'],
    phoneNumber: ['proECareOrder>>patientsTable>>phoneNumber', 'Nr telefon'],
    dateOfBirth: ['proECareOrder>>patientsTable>>dateOfBirth', 'Data urodzenia'],
    keyHolders: ['proECareOrder>>patientsTable>>keyHolders', 'Posiadacze klucza'],
    guardian: ['proECareOrder>>patientsTable>>guardian', 'Opiekun prawny'],
    guardianFirstName: ['proECareOrder>>patientsTable>>guardianFirstName', 'Imię opiekuna prawnego'],
    guardianLastName: ['proECareOrder>>patientsTable>>guardianLastName', 'Nazwisko opiekuna prawnego'],
    guardianPhoneNumber: ['proECareOrder>>patientsTable>>guardianPhoneNumber', 'Numer opiekuna prawnego'],
    projectUserNumber: ['proECareOrder>>patientsTable>>projectUserNumber', 'Kod Pacjenta (Bitrix)'],
  },
  keyHolders: {
    firstName: ['proECareOrder>>keyHolders>>firstName', 'Imię'],
    lastName: ['proECareOrder>>keyHolders>>lastName', 'Nazwisko'],
    phoneNumber: ['proECareOrder>>keyHolders>>phoneNumber', 'Telefon'],
  },
  suspensions: {
    dateFrom: ['proECareOrder>>suspensions>>dateFrom', 'Od'],
    dateTo: ['proECareOrder>>suspensions>>dateTo', 'Do'],
    reason: ['proECareOrder>>suspensions>>reason', 'Powód'],
  },
  dialog: {
    suspensionHistory: ['proECareOrder>>suspensionDialog>>dialogTitle', 'Historia zawieszeń'],
    orderEditTitle: ['proECareOrder>>suspensionDialog>>orderEditTitle', 'Edytuj dane zlecenia'],
    trainingEditTitle: ['proECareOrder>>suspensionDialog>>trainingEditTitle', 'Edytuj informacje o szkoleniu'],
    suspensionEditTitle: ['proECareOrder>>suspensionDialog>>suspensionEditTitle', 'Edytuj zawieszenie'],
    addSuspension: ['proECareOrder>>suspensionDialog>>addSuspension', 'Dodaj zawieszenie'],
  },
  suspensionTable: {
    dateFrom: ['proECareOrder>>suspensionTable>>dateFrom', 'Od'],
    dateTo: ['proECareOrder>>suspensionTable>>dateTo', 'Do'],
    reason: ['proECareOrder>>suspensionTable>>reason', 'Powód'],
  },
  columns: {
    innerPersonalDataTooltip: [
      'proECareOrder>>columns>>innerPersonalDataTooltip',
      'Dane pacjenta mogą być edytowane w profilu pacjenta. Kliknij w imię i nazwisko w nagłówku strony, aby przejść do profilu pacjenta.',
    ],
    eCareData: ['proECareOrder>>columns>>eCareData', 'Dane Zlecenia'],
    healthData: ['proECareOrder>>columns>>healthData', 'Dane stanu zdrowia'],
    infoCard: ['proECareOrder>>columns>>infoCard', 'Karta informacyjna'],
    deviceData: ['proECareOrder>>columns>>deviceData', 'Urządzenia'],
    suspensions: ['proECareOrder>>columns>>suspensions', 'Zawieszenia'],
    training: ['proECareOrder>>columns>>training', 'Informacje o szkoleniu'],
    cusPersonalData: ['proECareOrder>>columns>>cusPersonalData', 'Dane odbiorcy usługi ze zlecenia CUS'],
    internalPersonalData: ['proECareOrder>>columns>>internalPersonalData', 'Dane pacjenta z systemu MDT'],
  },
  devices: {
    trainingDate: ['proECareOrder>>devices>>trainingDate', 'Data przeprowadzenia szkolenia'],
    trainingInfo: ['proECareOrder>>devices>>trainingInfo', 'Informacje przeprowadzonego szkolenia'],
    callFrequency: ['proECareOrder>>devices>>callFrequency', 'Częstotliwość połączeń'],
  },
  orderForm: {
    serviceTerminationReason: ['proECareOrder>>devices>>serviceTerminationReason', 'Przyczyna Zakończenia Usługi'],
  },
  internalNotes: {
    title: ['proECareOrder>>internalNotes>>title', 'Notatka wewnętrzne'],
    historyTitle: ['proECareOrder>>internalNotes>>historyTitle', 'Notatki wewnętrznych'],
    addNote: ['proECareOrder>>internalNotes>>addNote', 'Dodaj notatkę wewnętrzną'],
    content: ['proECareOrder>>internalNotes>>content', 'Opis'],
    updatedAt: ['proECareOrder>>internalNotes>>updatedAt', 'Data aktualizacji'],
  },
  cusComments: {
    title: ['proECareOrder>>cusComments>>title', 'Uwagi do CUS'],
    titleField: ['proECareOrder>>cusComments>>titleField', 'Uwaga'],
    addComment: ['proECareOrder>>cusComments>>addComment', 'Dodaj uwagę do CUS'],
    lastSync: ['proECareOrder>>cusComments>>lastSync', 'Ostatnia synchronizacja'],
    author: ['proECareOrder>>cusComments>>author', 'Sporządził'],
    editComment: ['proECareOrder>>cusComments>>editComment', 'Edytuj uwagę do CUS'],
    moreComment: ['proECareOrder>>cusComments>>moreComment', 'Zobacz więcej uwag'],
    createdDate: ['proECareOrder>>cusComments>>createdDate', 'Data utworzenia'],
    updateDate: ['proECareOrder>>cusComments>>updateDate', 'Data aktualizacji'],
    noLastSyncData: ['proECareOrder>>noLastSyncData', 'Synchronizacja z CUS jeszcze nie została wykonana'],
    syncWithCusInfo: ['proECareOrder>>alarmCard>>buttons>>syncWithCusInfo', 'Ostatnia synchronizacja:'],
    edit: ['proECareOrder>>cusComments>>edit', 'Edytuj'],
    receiverTitle: ['proECareOrder>>cusComments>>receiverTitle', 'Odbiorcy'],
    receiverHelperText: ['proECareOrder>>cusComments>>receiverHelperText', 'Przynajmniej jedno pole odbiorcy musi być zaznaczone'],
  },
  tooltip: {
    suspensions: [
      'proECareOrder>>tooltip>>suspensions',
      'Zmiana daty DO powoduje zamknięcie zawieszenia. Opisz powód odwieszenia w notatce dodając słowa: “Powód odwieszenia”',
    ],
    callFrequency: [
      'proECareOrder>>tooltip>>callFrequency',
      'Tygodniowa średnia wszystkich połączeń z pacjentem wyliczana na podstawie całej historii kontaktu',
    ],
    alertSystemContract: [
      'proECareOrder>>tooltip>>alertSystemContract',
      'Numer ustalany przez admina. Służy do powiązania niektórych urządzeń z Pacjentem.',
    ],
    receiver: [
      'proECareOrder>>tooltip>>receiver',
      'proECareOrder>>tooltip>>receiver',
      'Ta informacja przekazywana jest TYLKO do systemu e-opieka CUS. Zgodnie z wymogiem CUS,  przynajmniej jeden odbiorca musi być zaznaczony.',
    ],
  },
  callFrequencyWithUnit: ['proECareOrder>>callFrequencyWithUnit', '{{frequency}}/tydzień'],
  validationForm: {
    dateFromIsLargerThenDateTo: ['proECareOrder>>validationForm>>dateFromIsLargerThenDateTo', 'Data od nie może być większa niż data do'],
  },
};

export default proECareOrderMessages;
