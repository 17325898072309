import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../payers';
import type { PayersByService, PayersByServiceDTO } from '../types';

interface Params {
  patientId: number;
  serviceItemId: number;
}

type GetPayersByServiceQuery = () => Promise<PayersByService[]>;

export const getPayersByService =
  ({ patientId, serviceItemId }: Params): GetPayersByServiceQuery =>
  async () => {
    const { data } = await axios.get<GetPayersByServiceQuery, DefaultApiResponse<PayersByServiceDTO[]>>(
      createUrlWithParams({
        url: endpoint.payersByService,
        params: {
          patient_id: patientId,
          core_service_id: serviceItemId,
        },
      }),
    );

    return data as PayersByService[];
  };
