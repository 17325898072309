import { getCountryJSON } from 'components/UI/organisms/PatientPersonalInfo/_services/getCountryJSON/getCountryJSON';
import type { SupportedLanguages } from 'constants/translations/_types';

const fallbackName = 'Polska';
const defaultCode = 'PL';

const getDefaultCountryName = (languageCode: SupportedLanguages): string => {
  const countryJSON = getCountryJSON(languageCode);
  const defaultCountry = countryJSON.find(({ code }) => code === defaultCode);
  return defaultCountry ? defaultCountry.name : fallbackName;
};

export default getDefaultCountryName;
