import type { FC } from 'react';

import { Table as TableMaterial } from '@mui/material';

import TableBody from 'components/UI/organisms/_tables/TableBody/TableBody';
import TableHeader from 'components/UI/organisms/_tables/TableHeader/TableHeader';
import type { TableContent } from 'constants/_types/TableContent';

type Props = TableContent;

const TableDesktop: FC<Props> = ({ headers, data, rowLink, actionsRenderer }) => (
  <TableMaterial>
    <TableHeader headers={headers} />
    <TableBody actionsRenderer={actionsRenderer} data={data} headers={headers} rowLink={rowLink} />
  </TableMaterial>
);

export default TableDesktop;
