import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import type { ContactInfoData } from 'constants/_types/ContactInfo';
import helpMessages from 'translations/specific/help.mjs';

import MobileContactTile from '../MobileContactTile/MobileContactTile';
import useStyles from './MobileContactTiles.styles';

export interface Props {
  data: ContactInfoData;
  hasInternalBranding: boolean;
}

const MobileContactTiles: FC<Props> = ({ data, hasInternalBranding }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {hasInternalBranding && data.brandContactInfo && (
        <MobileContactTile header={t(helpMessages.brandContact.header)} info={data.brandContactInfo} />
      )}
      <MobileContactTile header={t(helpMessages.generalContact.header)} info={data.generalContactInfo} />
    </div>
  );
};

export default MobileContactTiles;
