import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    position: 'relative',
    zIndex: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  body: {
    display: 'grid',
    gap: theme.spacing(3),
  },
  disabledLink: {
    pointerEvents: 'none',
    color: theme.palette.grey[400],
  },
  decIcon: {
    position: 'absolute',
    zIndex: '-1',
    height: '80%',
    left: '100%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fill: theme.palette.grey[600],
  },
}));

export default useStyles;
