import type { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import servicesProfile from 'api/servicesProfile/servicesProfile';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import ServicesReviewProfilesTable from 'components/UI/organisms/ServicesReviewProfilesTable/ServicesReviewProfilesTable';
import ServicesReviewTableWrapper from 'components/UI/organisms/ServicesReviewTable/_components/ServicesReviewTableWrapper/ServicesReviewTableWrapper';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import servicesMessages from 'translations/specific/services.mjs';

const ServicesReviewProfiles: FC = () => {
  const { patient } = usePatientContext();
  const { t } = useTranslation();
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.GET_SERVICES_PROFILE, createQueryKeyFromObject({ patientId: patient?.id, selectedPayersList })],
    servicesProfile.getServicesProfilesList({ patientId: patient?.id, payers: selectedPayersList }),
    {
      enabled: !!patient?.id && mainFunctionalitiesAllowed,
    },
  );

  return (
    <ServicesReviewTableWrapper isLoading={isLoading} title={t(servicesMessages.tableTitles.profiles)}>
      <ServicesReviewProfilesTable content={data} />
    </ServicesReviewTableWrapper>
  );
};

export default ServicesReviewProfiles;
