import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';

import type { TwoFAFormInput } from '../../_constants/inputKeys';
import { TWO_FA_INPUT_KEYS } from '../../_constants/inputKeys';
import { performToggle } from '../../_services/performToggle/performToggle';
import { useTwoFAQuery } from '../useTwoFAQuery/useTwoFAQuery';

interface HookParams {
  fetchedInitialValue: boolean;
  isEnforcedByBranding: boolean;
  setIsModalShown: Dispatch<SetStateAction<boolean>>;
}

export const useTwoFAForm = ({ fetchedInitialValue, isEnforcedByBranding, setIsModalShown }: HookParams) => {
  const { userInfo } = useAuthContext();
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();

  const [isOnInit, setIsOnInit] = useState(true);
  const [initialSwitchState, setInitialSwitchState] = useState(fetchedInitialValue);

  const form = useForm<TwoFAFormInput>();

  const switchState = form.watch(TWO_FA_INPUT_KEYS.twoFA);

  const restorePreviousSwitchState = useCallback(() => {
    form.setValue(TWO_FA_INPUT_KEYS.twoFA, initialSwitchState);
  }, [initialSwitchState, form]);

  const { isLoading, handleConfirmSubmission, handleAbortSubmission } = useTwoFAQuery({
    form,
    shouldBlockRequest: isEnforcedByBranding && initialSwitchState,
    restorePreviousSwitchState,
    setIsModalShown,
  });

  const checkPhoneNumberVerification = useCallback(() => {
    if (userInfo.hasPhoneVerified) handleConfirmSubmission();
    else addToGlobalDialogQueue({ type: GlobalDialogs.verifyMobileNumber });
  }, [userInfo, handleConfirmSubmission, addToGlobalDialogQueue]);

  useEffect(() => {
    // initialization ending
    setIsOnInit(false);
    setInitialSwitchState(fetchedInitialValue);
    form.setValue(TWO_FA_INPUT_KEYS.twoFA, fetchedInitialValue);
  }, [fetchedInitialValue]);

  useEffect(() => {
    performToggle({ isOnInit, initialSwitchState, switchState, isEnforcedByBranding, setIsModalShown, checkPhoneNumberVerification });
  }, [switchState]);

  return { form, switchState, isLoading, handleAbortSubmission, handleConfirmSubmission };
};
