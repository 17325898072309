import { useMemo, type FC } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton } from '@mui/material';

import { isInvalid } from 'services/_formInput/isInvalid/isInvalid';

export interface Props {
  disabled: boolean | undefined;
  multiple: boolean | undefined;
  value: string | string[] | undefined;
  onClear: () => void;
}

const DropdownClearButton: FC<Props> = ({ disabled, multiple, value, onClear }) => {
  const isDisabled = useMemo(() => {
    if (disabled) return true;

    if (multiple) return !(value as string[]).length;
    return isInvalid(value as string);
  }, [disabled, multiple, value]);

  return (
    <IconButton disabled={isDisabled} size='small' onClick={onClear}>
      <DeleteOutlinedIcon />
    </IconButton>
  );
};

export default DropdownClearButton;
