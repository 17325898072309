import { useEffect, type Dispatch, type SetStateAction } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import type { DropdownOption } from 'constants/_types/DropdownOptions';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

import { determineDefaultPayerId } from './_services/determineDefaultPayerId/determineDefaultPayerId';

interface HookParams {
  form: UseFormReturn<any, any>;
  payersByService: DropdownOption[];
  payersFormInputKey: string;
  setCurrentPayerId: Dispatch<SetStateAction<string>>;
}

export const useDefaultPayerId = ({ form, payersByService, payersFormInputKey, setCurrentPayerId }: HookParams) => {
  const payersSelected = useUserPayersStorage(state => state.getSelectedPayersList());

  useEffect(() => {
    if (!payersByService.length) return;

    const defaultPayerId = determineDefaultPayerId({ payersSelected, payersByService });

    setCurrentPayerId(defaultPayerId);
    if (defaultPayerId) form.setValue(payersFormInputKey as any, +defaultPayerId);
  }, [payersByService]);
};
