import type { FC } from 'react';

import { Button, Typography, useTheme } from '@mui/material';

import Chevron from 'components/UI/atoms/Chevron/Chevron';
import { DIRECTIONS } from 'constants/_types/Direction';

import useStyles from './MobileBasketHead.styles';

export interface Props {
  buttonLabel: string;
  headerLabel: string;
  isOpened: boolean;
  handleToggleOpen: () => void;
}

const MobileBasketHead: FC<Props> = ({ buttonLabel, headerLabel, isOpened, handleToggleOpen }) => {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <>
      <Button
        className={classes.toggleButton}
        endIcon={
          <Chevron
            color={theme.palette.primary.main}
            direction={{ base: DIRECTIONS.top, alternate: DIRECTIONS.bottom }}
            isAlternated={isOpened}
          />
        }
        variant='text'
        onClick={handleToggleOpen}
      >
        <Typography variant='caption'>{buttonLabel}</Typography>
      </Button>
      <Typography component='h4' variant='subtitle3'>
        {headerLabel}
      </Typography>
    </>
  );
};

export default MobileBasketHead;
