import type { FC } from 'react';

import { AppBar } from '@mui/material';

import useStyles from './AppLogoTopBar.styles';

export interface Props {
  leftImageSrc: string | undefined;
  rightImageSrc: string | undefined;
  hasNoImage: boolean;
}

const AppLogoTopBar: FC<Props> = ({ leftImageSrc, rightImageSrc, hasNoImage }) => {
  const { classes } = useStyles();

  if (hasNoImage) return null;

  return (
    <AppBar className={classes.root} position='fixed'>
      <div>{leftImageSrc && <img alt='left-logo' className={classes.image} src={leftImageSrc} />}</div>
      <div>{rightImageSrc && <img alt='right-logo' className={classes.image} src={rightImageSrc} />}</div>
    </AppBar>
  );
};

export default AppLogoTopBar;
