import { useCallback, useEffect, useRef, useState } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import { FILE_DEFAULT_VALUE } from 'components/UI/molecules/DetailsKindSection/_components/ReferralAttachment/_constants/fileDefaultValue';
import { FileUploadState } from 'constants/_types/fileUploadState';

export interface HookParams {
  form: UseFormReturn<any, any>;
  formFileName: string;
  setUploadState: (newState: FileUploadState) => void;
}

export const useFileHandlers = ({ form, formFileName, setUploadState }: HookParams) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState('');

  const handleAdd = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  const handleFileProceed = useCallback((fileUnknownFormat: File | FileList) => {
    let file: File;
    if (fileUnknownFormat instanceof FileList) {
      // eslint-disable-next-line prefer-destructuring
      file = fileUnknownFormat[0];
    } else {
      file = fileUnknownFormat;
    }
    setFilename(file.name);
    form.setValue(formFileName as any, file);
    setUploadState('loaded');
  }, []);

  const handleRemove = useCallback(async () => {
    form.setValue(formFileName as any, FILE_DEFAULT_VALUE);
    await form.trigger(formFileName as any);
    setUploadState('initial');
  }, []);

  useEffect(() => {
    const initialFile = form.getValues(formFileName as any);
    if (initialFile?.length) {
      handleFileProceed(initialFile);
    } else {
      handleRemove();
    }
  }, []);

  return { filename, inputRef, handleAdd, handleFileProceed, handleRemove };
};
