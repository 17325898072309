import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ProfessionalECareCusCommentsEntity } from 'api/eCareCusComments/eCareCusComments.types';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import CusCommentsDialog from 'components/UI/organisms/_dialogs/CusCommentsDialog/CusCommentsDialog';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import { receiverDictionary, Receivers } from 'constants/receiverDictionary/receiverDictionary';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from './ECareCusCommentsHistory.styles';

type Props = {
  comment: ProfessionalECareCusCommentsEntity;
};

const ECareCusCommentsHistory: FC<Props> = ({ comment }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isCusCommentsDialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const header = [
    {
      label: t(proECareOrderMessages.cusComments.updateDate),
      value: comment.updatedAt ? unknownDateToString(comment.updatedAt, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.cusComments.lastSync),
      value: comment.lastSync ? unknownDateToString(comment.lastSync, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noLastSyncNote),
    },
    {
      label: t(proECareOrderMessages.cusComments.author),
      value: comment.updatedBy ? comment.updatedBy : comment.createdBy,
    },
    {
      label: t(proECareOrderMessages.cusComments.receiverTitle),
      value: comment.receivers.map(receiver => receiver).join(', '),
    },
  ];

  const isReceiver = (receiver: Receivers) => comment.receivers.includes(receiver);

  const defaultValues = useMemo(
    () => ({
      content: comment.content,
      CUS: isReceiver(receiverDictionary.CUS),
      OPS: isReceiver(receiverDictionary.OPS),
      WYK: isReceiver(receiverDictionary.WYK),
      CT24: isReceiver(receiverDictionary.CT24),
    }),
    [comment],
  );

  const headerElement = comment.lastSync ? undefined : (
    <Button className={classes.button} size='small' variant='outlined' onClick={openDialog}>
      {t(proECareOrderMessages.cusComments.edit)}
    </Button>
  );

  return (
    <>
      <BoxWithHeader description={comment.content} header={header} headerElement={headerElement} />
      <CusCommentsDialog
        close={closeDialog}
        commentId={comment.id}
        defaultValue={defaultValues}
        isOpen={isCusCommentsDialogOpen}
        orderId={comment.order}
        serviceRecipientId={comment.serviceRecipient}
      />
    </>
  );
};

export default ECareCusCommentsHistory;
