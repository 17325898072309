import { useCallback, type FC, type MouseEvent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { QueryKey } from 'react-query';

import type { MenuData } from 'components/UI/organisms/_tables/_reports/ReportsTableCardActions/ReportsTableCardActions';
import { useReportRowActions } from 'hooks/_reports/useReportRowActions/useReportRowActions';
import dashboardMessages from 'translations/specific/dashboard.mjs';

import useStyles from './ReportsTableCardActionButtons.styles';

export interface Props {
  menuData: MenuData;
  queryKey?: QueryKey;
  isMenuOpened: boolean;
  anchorElement: HTMLElement | null;
  handleMenuClose: (e?: MouseEvent<HTMLElement>) => void;
}

const ReportsTableCardActionButtons: FC<Props> = ({
  menuData: { id, createdAtTime, isHidden: isHiddenProp, isMedicalRecord: isMedicalRecordProp, reportFileId },
  queryKey = '',
  isMenuOpened,
  anchorElement,
  handleMenuClose,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const {
    buttonsState: { isMedicalRecord },
    onMedicalRecordToggle,
    onDownload,
    onHide,
  } = useReportRowActions({ id, createdAtTime, queryKey, isHiddenProp, isMedicalRecordProp, handleMenuClose, reportFileId });

  const onClose = useCallback((e: MouseEvent<HTMLElement>) => {
    handleMenuClose(e);
  }, []);

  return (
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      elevation={2}
      open={isMenuOpened}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
    >
      <MenuItem className={classes.menuItem} onClick={onMedicalRecordToggle}>
        {isMedicalRecord ? (
          <>
            <LockOpenOutlinedIcon color='secondary' fontSize='small' />
            <Typography className={classes.menuItemText}>{t(dashboardMessages.reportsCard.actions.private)}</Typography>
          </>
        ) : (
          <>
            <LockOutlinedIcon color='secondary' fontSize='small' />
            <Typography className={classes.menuItemText}>{t(dashboardMessages.reportsCard.actions.public)}</Typography>
          </>
        )}
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={onDownload}>
        <FileDownloadOutlinedIcon color='secondary' fontSize='small' />
        <Typography className={classes.menuItemText}>{t(dashboardMessages.reportsCard.actions.download)}</Typography>
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={onHide}>
        <CloseIcon color='secondary' fontSize='small' />
        <Typography className={classes.menuItemText}>{t(dashboardMessages.reportsCard.actions.hide)}</Typography>
      </MenuItem>
    </Menu>
  );
};

export default ReportsTableCardActionButtons;
