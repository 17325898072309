import { FC, useMemo } from 'react';

import { Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import eCarePhoneCall from 'api/eCarePhoneCall/eCarePhoneCall';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './ProfessionalEcarePhoneCallSummary.styles';

type FormInput = {
  call_summary: string;
};

type Props = {
  summary?: string;
};

const ProfessionalEcarePhoneCallSummary: FC<Props> = ({ summary }) => {
  const { t } = useTranslation();
  const { phoneCallId } = useParams() as unknown as { phoneCallId: string };
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_PHONE_CALL, phoneCallId], [phoneCallId]);
  const statusMutation = useMutation(eCarePhoneCall.patchEcarePhoneCall(phoneCallId), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues } = useForm<FormInput>();

  const addNewSummary = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionPhoneCallNoteAdd),
      body: <FormInputText control={control} label='' multiline name='call_summary' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed)
      statusMutation.mutate({
        call_summary: getValues('call_summary'),
      });
  };

  return (
    <SectionWithTitle
      title={t(ecarePhoneCallMessages.detail.summary)}
      titleIcon={
        <Button variant='contained' onClick={addNewSummary}>
          {summary ? t(ecarePhoneCallMessages.detail.editSummary) : t(ecarePhoneCallMessages.detail.addSummary)}
        </Button>
      }
    >
      <Typography className={classes.summeryDescription} component='p' variant='body1'>
        {summary || t(generalMessages.noData)}
      </Typography>
    </SectionWithTitle>
  );
};

export default ProfessionalEcarePhoneCallSummary;
