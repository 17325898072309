import Flags, { FlagComponent } from 'country-flag-icons/react/3x2';

import languageMessages from 'translations/specific/languageMessages.mjs';

import type { SupportedLanguages } from './_types';
import { SUPPORTED_LANGUAGES } from './_types';

export type Language = {
  code: SupportedLanguages;
  label: [string, string];
  icon: FlagComponent;
};

const languages: Language[] = [
  { label: languageMessages.simple_names.en as [string, string], code: SUPPORTED_LANGUAGES.EN, icon: Flags.GB },
  { label: languageMessages.simple_names.pl as [string, string], code: SUPPORTED_LANGUAGES.PL, icon: Flags.PL },
  { label: languageMessages.simple_names.es as [string, string], code: SUPPORTED_LANGUAGES.ES, icon: Flags.ES },
];

export default languages;
