import axios from 'axios';

import { DefaultApiResponse } from 'api/_types';

import createUrlWithParams from '../../helpers/createUrlWithParams/createUrlWithParams';
import { parsePatientKeylockCodeForFE } from './patientKeylockCode.parsers';
import { PatientKeylockCode, PatientKeylockCodeDTO } from './patientKeylockCode.types';

const endpoint = '/api/v1/ecare-device/get-keylock-code/';

type GetPatientKeylockCodeQuery = () => Promise<PatientKeylockCode>;

const patientKeylockCode = {
  getPatientKeylockCode:
    (patientId: number): GetPatientKeylockCodeQuery =>
    async () => {
      const { data } = await axios.get<GetPatientKeylockCodeQuery, DefaultApiResponse<PatientKeylockCodeDTO>>(
        createUrlWithParams({ url: endpoint, params: { patient: patientId, keylock__isnull: false } }),
      );
      return parsePatientKeylockCodeForFE(data);
    },
};
export default patientKeylockCode;
