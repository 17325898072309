import { ReactComponent as ServiceItemIcon } from 'assets/icons/services/service_item.svg';
import { ReactComponent as ServicePaymentIcon } from 'assets/icons/services/service_payment.svg';
import { ReactComponent as ServiceTimeIcon } from 'assets/icons/services/service_time.svg';
import orderServiceMessages from 'translations/specific/orderService.mjs';

export const headers = [
  {
    id: 'serviceName',
    translationArray: orderServiceMessages.details.confirmation.serviceItemHeader,
    icon: <ServiceItemIcon />,
  },
  {
    id: 'serviceTime',
    translationArray: orderServiceMessages.details.confirmation.serviceTimeHeader,
    icon: <ServiceTimeIcon />,
  },
  {
    id: 'servicePayer',
    translationArray: orderServiceMessages.details.confirmation.servicePaymentHeader,
    icon: <ServicePaymentIcon />,
  },
];
