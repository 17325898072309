import axios from 'axios';

import { PaginatedApiResponse } from 'api/_types';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parseECareDictionaryForFE } from './eCareDictionary.parsers';
import { ECareDictionary, ECareDictionaryDTO, EntryType } from './eCareDictionary.types';

const endpoint = '/api/v1/ecare-dictionary/';

type GetEcareDictionaryListQuery = () => Promise<TableDataQueryResult<keyof ECareDictionary>>;

const eCareDictionary = {
  getEcareDictionaryList:
    (entryType: EntryType): GetEcareDictionaryListQuery =>
    async () => {
      const { data } = await axios.get<GetEcareDictionaryListQuery, PaginatedApiResponse<ECareDictionaryDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: { entry_type: entryType },
          removeEmptyParams: true,
        }),
      );
      return { data: parseECareDictionaryForFE(data.results), count: data.count };
    },
};
export default eCareDictionary;
