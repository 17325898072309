import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: theme.spacing(2),
  },
  message: {
    color: theme.palette.text.secondary,
  },
  syncStatus: {
    fontWeight: 'bold',
    fontSize: 10,
    marginTop: 5,
  },
}));

export default useStyles;
