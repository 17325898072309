import axios from 'axios';

import type { SymptomCheckerTriageLevelValues } from 'constants/_types/SymptomCheckerTriageLevels';
import type { SupportedLanguages } from 'constants/translations/_types';

import { DefaultApiResponse } from './_types';

const endpoints = {
  getInfo: 'api/v1/companies/',
};

export type Brand = {
  id: number;
  brand: {
    id: number;
    logo_left: string;
    logo_right: string;
    favicon: string;
    full_company_name: string;
    footer_phone_number: string;
    footer_email: string;
    inquiry_address: string;
    inquiry_email: string;
    inquiry_phone: string;
    symptom_checker_triage: {
      [k1 in SymptomCheckerTriageLevelValues]: {
        [k2 in SupportedLanguages]: string;
      };
    };
  };
  config: {
    id: number;
    display_referral_form: boolean;
    force_2fa: boolean;
    registration_required_phone_number: boolean;
    required_patient_fields: string[];
  };
  name: string;
  subdomain: string;
  it_helpline_email: string;
  it_helpline_phone: string;
};
type GetInfoQuery = () => Promise<DefaultApiResponse<Brand>>;

const branding = {
  getInfo:
    (brand: string): GetInfoQuery =>
    () =>
      axios.get<GetInfoQuery, DefaultApiResponse<Brand>>(`${endpoints.getInfo}${brand}/`),
};

export default branding;
