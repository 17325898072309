import type { FC } from 'react';

import { Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import GlobalSearchBar from 'components/UI/organisms/GlobalSearchBar/GlobalSearchBar';
import UserMenuDropdown from 'components/UI/organisms/UserMenuDropdown/UserMenuDropdown';
import useLayoutStorage from 'storages/layoutStorage';
import layoutMessages from 'translations/specific/layout.mjs';

import useIsProfessionalUser from '../../../../hooks/useIsProfessionalUser/useIsProfessionalUser';
import useStyles from './AppBarContentDesktop.styles';

const AppBarContentDesktop: FC = () => {
  const { t } = useTranslation();
  const isProfessionalUser = useIsProfessionalUser();
  const { brandInfo } = useBrandContext();
  const { isOpen } = useLayoutStorage();

  const { classes } = useStyles({ isDrawerOpened: isOpen });

  return (
    <Toolbar classes={{ root: classes.toolbarRoot }}>
      <div className={classes.search}>{!isProfessionalUser && <GlobalSearchBar />}</div>
      <div>
        <div className={classes.serviceProvider}>
          {brandInfo?.brand.logo_right && (
            <>
              <Typography className={classes.logoDescription} variant='caption'>
                {t(layoutMessages.topNavigation.serviceProvider)}
              </Typography>
              <img alt='MDT' className={classes.logo} src={brandInfo.brand.logo_right} />
            </>
          )}
        </div>
      </div>
      <div>
        <UserMenuDropdown />
      </div>
    </Toolbar>
  );
};

export default AppBarContentDesktop;
