import { FC } from 'react';

import AlarmsList from 'components/UI/organisms/AlarmsList/AlarmsList';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ProfessionalAlarmsListPage: FC = () => {
  usePageConfig();

  return (
    <MainContentWrapper extraContainer noPadding>
      <AlarmsList />
    </MainContentWrapper>
  );
};

export default ProfessionalAlarmsListPage;
