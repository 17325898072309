import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isFilters: boolean }>()((theme, { isFilters }) => ({
  filterChips: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    maxWidth: '100%',
    flexWrap: 'wrap',
    marginBottom: isFilters ? theme.spacing(2) : 0,
    justifyContent: 'space-between',
  },
  clearBtn: {
    marginRight: theme.spacing(2),
    height: '36px',
  },
  chip: {
    margin: theme.spacing(2, 0, 2, 2),
  },
}));

export default useStyles;
