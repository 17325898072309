import { isNil } from 'lodash';

import { PartialRecord } from 'constants/_types/PartialRecord';
import { TableDataFilter } from 'constants/_types/TableDataFilter';

const parseFiltersForBE = <FILTERS extends string>(filters: TableDataFilter[] | null): PartialRecord<FILTERS, string> =>
  filters
    ? filters.reduce<PartialRecord<FILTERS, string>>((acc, param) => {
        if (!isNil(param.value)) {
          acc[param.key as FILTERS] = param.value.toString();
        }
        return acc;
      }, {})
    : {};

export default parseFiltersForBE;
