import { GENDER } from 'constants/_types/Gender';
import generalMessages from 'translations/common/general.mjs';

import { SimpleResolver } from '../_types';

const resolveGender: SimpleResolver = (t, gender) => {
  switch (gender) {
    case GENDER.m:
      return t(generalMessages.gender.m);
    case GENDER.k:
      return t(generalMessages.gender.f);
    default:
      throw Error('missing gender code');
  }
};

export default resolveGender;
