import type { FC } from 'react';
import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { PatientPolicy } from 'api/patientPolicies/patientPolicies.types';
import { useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import PatientCardSectionTitle from 'components/UI/atoms/PatientCardSectionTitle/PatientCardSectionTitle';
import DefaultPayerCard from 'components/UI/molecules/DefaultPayerCard/DefaultPayerCard';
import PolicyCard from 'components/UI/molecules/PolicyCard/PolicyCard';
import SubscriptionCard from 'components/UI/molecules/SubscriptionCard/SubscriptionCard';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import PATHS from 'constants/router/PATHS';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

import useStyles from './PatientPolicyInfo.styles';
import { usePatientPolicyQuery } from './usePatientPolicyQuery';

type Props = {
  showSelfPayment: boolean;
  title: string;
  patientId: number;
  // TODO pro-patient-page: it's possible that will be a standard way to dispaly services
  showServicesInDialog?: boolean;
  isPro?: boolean;
};

const PatientPolicyInfo: FC<Props> = ({ showSelfPayment, title, patientId, showServicesInDialog, isPro }) => {
  const [canViewServices] = usePermissionCheck(['view_mdtcoreservice']);
  const { data, isLoading } = usePatientPolicyQuery(patientId, isPro);
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();

  const { setOnlyOnePayerActive, selfPayerId } = useUserPayersStorage();
  const navigate = useNavigate();

  const onScopeClick = useCallback((cardData?: PatientPolicy) => {
    if (showServicesInDialog) {
      addToGlobalDialogQueue({
        type: 'displayServicesInPolicy',
        props: { patientId, payerId: cardData?.payerId, policyId: cardData?.policyId, policyType: cardData?.type },
      });
      return;
    }

    if (!cardData && !selfPayerId) return;

    if (cardData?.payerId) {
      setOnlyOnePayerActive(cardData.payerId);
      navigate(`/${PATHS.SERVICES}`);
      return;
    }

    if (selfPayerId) {
      setOnlyOnePayerActive(selfPayerId);
      navigate(`/${PATHS.SERVICES}`);
    }
  }, []);

  const { classes } = useStyles();
  return (
    <CardContentContainer isLoading={isLoading}>
      <PatientCardSectionTitle marginBottom={3} titleText={title} />
      <div className={classes.cardContainer}>
        {showSelfPayment && <DefaultPayerCard onScopeClick={onScopeClick} />}
        {data?.map(policyData => {
          switch (policyData.type) {
            case 'PACKAGE':
            case 'POLICY':
              return (
                <PolicyCard
                  cardData={policyData}
                  key={policyData.policyNumber}
                  onScopeClick={isPro && canViewServices ? onScopeClick : undefined}
                />
              );
            case 'SUBSCRIPTION':
              return (
                <SubscriptionCard
                  cardData={policyData}
                  key={policyData.policyNumber}
                  onScopeClick={isPro && canViewServices ? onScopeClick : undefined}
                />
              );
            default:
              return exhaustiveGuard(policyData.type);
          }
        })}
      </div>
    </CardContentContainer>
  );
};

export default PatientPolicyInfo;
