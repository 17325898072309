import { makeStyles } from 'tss-react/mui';

import { APP_LOGO_TOP_BAR_HEIGHT } from 'constants/styles/APP_LOGO_TOP_BAR_HEIGHT';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    maxHeight: APP_LOGO_TOP_BAR_HEIGHT,
  },
  image: {
    maxHeight: '32px',
  },
}));

export default useStyles;
