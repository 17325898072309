import { makeStyles } from 'tss-react/mui';

import tableRowStylesFactory from 'styles/helpers/tableRowStylesFactory';

const useStyles = makeStyles()(theme => ({
  root: {},
  filters: {
    padding: theme.spacing(2, 0),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableLink: { display: 'block', textAlign: 'right' },

  dropColor: tableRowStylesFactory(theme, theme.palette.table.danger),
  suspendedColor: tableRowStylesFactory(theme, theme.palette.table.closed),
  completeColor: tableRowStylesFactory(theme, theme.palette.table.default),
  missedColor: tableRowStylesFactory(theme, theme.palette.table.warning), // W trakcie realizacji
  activeColor: tableRowStylesFactory(theme, theme.palette.table.success),
  receivedColor: tableRowStylesFactory(theme, theme.palette.table.clarify),

  textWrapWithTooltip: {
    display: 'flex',
    alignItems: 'center',

    svg: {
      color: theme.palette.primary.contrastText,
    },
  },

  textEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
}));

export default useStyles;
