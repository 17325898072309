import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isOpened: boolean }>()((theme, { isOpened }) => ({
  head: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    transition: theme.transitions.create('marginBottom', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginBottom: isOpened ? theme.spacing(3) : 0,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    height: isOpened ? 'calc(100vh - 370px)' : 0,
    overflow: 'scroll',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default useStyles;
