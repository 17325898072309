import { makeStyles } from 'tss-react/mui';

const SUMMARY_WIDTH = 200;

const useStyles = makeStyles<{ hideBasket: boolean }>()((theme, { hideBasket }) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridGap: theme.spacing(3),
    },
  },
  card: {
    padding: theme.spacing(2, 2),
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: hideBasket ? `auto 0px` : `auto ${SUMMARY_WIDTH}px`,
    gridGap: theme.spacing(2),
    transition: theme.transitions.create('grid-template-columns', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  payersBar: {
    borderBottom: `thin solid ${theme.palette.grey[200]}`,

    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      padding: theme.spacing(0),
    },
  },
  summary: {
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    maxHeight: '80vh',
    opacity: hideBasket ? 0 : 1,

    transition: theme.transitions.create('opacity', {
      delay: hideBasket ? 0 : theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}));

export default useStyles;
