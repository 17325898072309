import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import helpMessages from 'translations/specific/help.mjs';

import useStyles from './TitleExtraContent.styles';

export interface Props {
  phoneNumber: string;
}

const TitleExtraContent: FC<Props> = ({ phoneNumber }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography className={classes.info} variant='caption'>
        {t(helpMessages.headerExtraContentInfo)}
      </Typography>
      <Typography className={classes.value} variant='h2'>
        {phoneNumber}
      </Typography>
    </div>
  );
};

export default TitleExtraContent;
