import { useMemo } from 'react';

import type { MenuEntity } from 'hooks/useMenuContent/useMenuContent';
import useMenuContent from 'hooks/useMenuContent/useMenuContent';

export const useGroupedMenuItems = () => {
  const menuContent = useMenuContent();

  const { menuItemsToDisplayOnBar, menuItemsToDisplayOnMoreList, shouldDisplayMoreButton } = useMemo(() => {
    const menuItemsReadyToRender = menuContent.filter(menuItem => !menuItem?.hidden);

    const _menuItemsToDisplayOnBar: MenuEntity[] = [];
    const _menuItemsToDisplayOnMoreList: MenuEntity[] = [];
    menuItemsReadyToRender.forEach((menuItem, index) => {
      if (index > 2) {
        _menuItemsToDisplayOnMoreList.push(menuItem);
        return;
      }
      _menuItemsToDisplayOnBar.push(menuItem);
    });

    return {
      menuItemsToDisplayOnBar: _menuItemsToDisplayOnBar,
      menuItemsToDisplayOnMoreList: _menuItemsToDisplayOnMoreList,
      shouldDisplayMoreButton: _menuItemsToDisplayOnMoreList.length > 0,
    };
  }, []);

  return { menuItemsToDisplayOnBar, menuItemsToDisplayOnMoreList, shouldDisplayMoreButton };
};
