import type { FC } from 'react';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DotIcon from '@mui/icons-material/FiberManualRecord';
import {
  Card,
  Container,
  Divider,
  Grid,
  PaletteColor,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'react-query';

import ProbabilityBar from 'components/UI/atoms/ProbabilityBar/ProbabilityBar';
import ReportsServiceButtons, { ButtonsReportProp } from 'components/UI/molecules/ReportsServiceButtons/ReportsServiceButtons';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import locale from 'constants/dates/locale';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { resolveProbabilityName } from 'services/_dictionaryResolvers/resolveProbability/resolveProbability';
import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

import useStyles from './SymptomCheckerReportWeb.styles';

const subtitleProps: TypographyProps = {
  variant: 'h3',
  marginBottom: 1.5,
};

const valueProps: TypographyProps = {
  variant: 'body1',
  display: 'flex',
  alignItems: 'center',
  mb: 1,
};

type Props = {
  createdAt: string;
  gender: string;
  age: number;
  symptoms: {
    present: { name: string; id: string }[];
    absent: { name: string; id: string }[];
    unknown: { name: string; id: string }[];
  };
  riskFactors: { name: string; id: string }[];
  visitedPlaces: { name: string; id: string }[];
  reportedSymptoms: { name: string; id: string }[];
  conditions: { common_name: string; id: string; probability: number }[];
  id: number;
  isHidden: boolean;
  isMedicalRecord: boolean;
  triageBaseText: {
    header: string;
    content: string;
  };
  triageBrandText: string;
  triageStyling: {
    color: PaletteColor;
    icon: FC;
  };
  queryKey: QueryKey;
  reportFileId: number;
};

const SymptomCheckerReportWeb: FC<Props> = ({
  createdAt,
  gender,
  age,
  symptoms,
  riskFactors,
  visitedPlaces,
  reportedSymptoms,
  conditions,
  triageBaseText,
  triageBrandText,
  id: reportId,
  isHidden,
  isMedicalRecord,
  triageStyling,
  queryKey,
  reportFileId,
}) => {
  const { t } = useTranslation();
  const langCode = useLanguageCode();

  const serviceButtonsReportProp: ButtonsReportProp = {
    id: reportId,
    isHidden,
    createdAt,
    isMedicalRecord,
    reportFileId,
  };

  const { classes, cx } = useStyles({ triageColor: triageStyling.color });
  const TriageIcon = triageStyling.icon;
  return (
    <Container maxWidth='md'>
      <div className={classes.root}>
        <div>
          <Grid container direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}>
            <Grid item sm xs={12}>
              <Typography component='h1' variant='h1'>
                {t(symptomCheckerReportsMessages.header)}
              </Typography>
            </Grid>
            <Grid item paddingBottom={{ xs: 2, sm: 2 }} sm='auto' xs={12}>
              <ReportsServiceButtons queryKey={queryKey} report={serviceButtonsReportProp} />
            </Grid>
          </Grid>
          <Typography variant='subtitle1'>
            {t(symptomCheckerReportsMessages.dateLabel)}
            {': '}
            {format(new Date(createdAt), DATE_FORMATS.DISPLAY_LONG_WITH_TIME, { locale: locale[langCode] })}
          </Typography>
        </div>
        <Card className={classes.triageContainer}>
          <div className={classes.triageIndicator}>
            <TriageIcon />
          </div>
          <div className={classes.triageMain}>
            <Typography fontSize='1rem' variant='h2'>
              {t(symptomCheckerReportsMessages.subheaders.recommendation)}
            </Typography>
            <Typography variant='h6'>{triageBaseText.header}</Typography>
            <Typography variant='body1'>{triageBaseText.content}</Typography>
          </div>
        </Card>
        <Card className={classes.triageBrandText}>
          <Typography variant='subtitle1'>{triageBrandText}</Typography>
        </Card>
        <Typography component='h2' variant='h2'>
          {t(symptomCheckerReportsMessages.subheaders.patient)}
        </Typography>
        <Card className={classes.card}>
          <Grid container spacing={6}>
            <Grid item>
              <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.gender)}</Typography>
              <Typography {...valueProps}>{gender}</Typography>
            </Grid>
            <Grid item>
              <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.age)}</Typography>
              <Typography {...valueProps}>{age}</Typography>
            </Grid>
          </Grid>
          <Divider />
          <div>
            <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.visitedPlaces)}</Typography>
            {visitedPlaces.map(({ name, id }) => (
              <Typography {...valueProps} key={id}>
                <DotIcon className={cx(classes.icon, classes.dotIcon)} />
                {name}
              </Typography>
            ))}
          </div>
          <div>
            <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.riskFactors)}</Typography>
            {riskFactors.map(({ name, id }) => (
              <Typography {...valueProps} key={id}>
                <DotIcon className={cx(classes.icon, classes.dotIcon)} />
                {name}
              </Typography>
            ))}
          </div>
        </Card>
        <Typography component='h2' variant='h2'>
          {t(symptomCheckerReportsMessages.subheaders.interview)}
        </Typography>
        <Card className={classes.card}>
          <div>
            <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.reportedSymptoms)}</Typography>
            {reportedSymptoms.map(({ name, id }) => (
              <Typography {...valueProps} key={id}>
                <DotIcon className={cx(classes.icon, classes.dotIcon)} />
                {name}
              </Typography>
            ))}
          </div>
          <div>
            <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.present)}</Typography>
            {symptoms.present.map(({ name, id }) => (
              <Typography {...valueProps} key={id}>
                <CheckOutlinedIcon className={cx(classes.icon, classes.checkIcon)} />
                {name}
              </Typography>
            ))}
          </div>
          <div>
            <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.absent)}</Typography>
            {symptoms.absent.map(({ name, id }) => (
              <Typography {...valueProps} key={id}>
                <CloseOutlinedIcon className={cx(classes.icon, classes.closeIcon)} />
                {name}
              </Typography>
            ))}
          </div>
          <div>
            <Typography {...subtitleProps}>{t(symptomCheckerReportsMessages.subheaders.unknown)}</Typography>
            {symptoms.unknown.map(({ name, id }) => (
              <Typography {...valueProps} key={id}>
                <ArrowForwardOutlinedIcon className={cx(classes.icon, classes.arrowIcon)} />
                {name}
              </Typography>
            ))}
          </div>
        </Card>
        <Typography component='h2' variant='h2'>
          {t(symptomCheckerReportsMessages.subheaders.results)}
        </Typography>
        <TableContainer className={classes.table} component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t(symptomCheckerReportsMessages.subheaders.name)}</TableCell>
                <TableCell>{t(symptomCheckerReportsMessages.subheaders.probability)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conditions.map(({ common_name, probability, id }) => (
                <TableRow key={id}>
                  <TableCell>{common_name}</TableCell>
                  <TableCell>
                    <Grid alignItems='center' container spacing={2}>
                      <Grid item>
                        <ProbabilityBar probability={probability} />
                      </Grid>
                      <Grid item>{resolveProbabilityName(t, probability)}</Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant='caption'>{t(symptomCheckerReportsMessages.warning)}</Typography>
      </div>
    </Container>
  );
};

export default SymptomCheckerReportWeb;
