import type { FC } from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DollarIcon } from 'assets/icons/decorative/dollar.svg';
import FixedSizeCard from 'components/UI/atoms/FixedSizeCard/FixedSizeCard';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './DefaultPayerCard.styles';

type Props = {
  onScopeClick?: () => void;
};

export const testId = createTestIdObject('DefaultPayerCard', {
  policyScope: 'policyScope',
});

const DefaultPayerCard: FC<Props> = ({ onScopeClick }) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { selfPayerId } = useUserPayersStorage();

  return (
    <FixedSizeCard maxWidth={364}>
      <div className={classes.root}>
        <header className={classes.header}>
          <Typography variant='subtitle1'>{t(patientMessages.policiesAndPaymentsSection.selfPaid.title)}</Typography>
          {onScopeClick && (
            <Typography
              className={cx(!selfPayerId && classes.disabledLink)}
              color='primary'
              component={ButtonBase}
              data-testid={testId.policyScope}
              disabled={!selfPayerId}
              disableRipple
              variant='captionBold'
              onClick={() => onScopeClick()}
            >
              {t(patientMessages.policiesAndPaymentsSection.policyScopeLink)}
            </Typography>
          )}
        </header>
        <div className={classes.body}>
          <Typography variant='body1'>{t(patientMessages.policiesAndPaymentsSection.selfPaid.description)}</Typography>
          <div>
            <Typography variant='caption'>{t(patientMessages.policiesAndPaymentsSection.selfPaid.optionsLabel)}</Typography>
            {': '}
            <Typography variant='subtitle3'>{t(patientMessages.policiesAndPaymentsSection.selfPaid.options.atPoint)}</Typography>
          </div>
        </div>
        <DollarIcon className={classes.decIcon} />
      </div>
    </FixedSizeCard>
  );
};

export default DefaultPayerCard;
