import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ gridArea?: string }>()((theme, { gridArea }) => ({
  notEditableVersionWrapper: {
    wordBreak: 'break-word',
    display: 'grid',
    gridGap: theme.spacing(1),
    gridArea: gridArea || 'unset',
  },
  editButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    svg: {
      height: '1.125rem',
      width: '1.125rem',
    },
  },
}));

export default useStyles;
