import { submitServiceRequestsForm } from './submitServiceRequestsForm/submitServiceRequestsForm';

export const endpoints = {
  postServiceRequests: 'api/v1/service-requests/',
};

const serviceRequests = {
  submitServiceRequestsForm,
};

export default serviceRequests;
