import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

export default useStyles;
