import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    border: `thin solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  listItem: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 1.5),
    },
  },
  listItemText: {
    fontSize: '.775rem',
    letterSpacing: '-0.0075em',
    wordSpacing: '-0.005em',
    color: theme.palette.grey[500],
  },
  listItemText_valid: {
    color: theme.palette.primary.main,
  },
  checkbox: {
    position: 'absolute',
    top: theme.spacing(0.75),
    left: 0,
  },
}));

export default useStyles;
