import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import isProduction from 'helpers/isProduction/isProduction';

type LayoutStorage = {
  isOpen: boolean;
  toggleOpen: () => void;
  hideMenu: () => void;
  showMenu: () => void;
};

const storage = persist<LayoutStorage>(
  set => ({
    isOpen: true,
    toggleOpen: () => set(state => ({ isOpen: !state.isOpen })),
    hideMenu: () => set({ isOpen: false }),
    showMenu: () => set({ isOpen: true }),
  }),
  { name: 'layoutStorage', getStorage: () => sessionStorage },
);

const useLayoutStorage = isProduction() ? create(storage) : create(devtools(storage));

export default useLayoutStorage;
