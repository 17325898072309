import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import type { Basket } from 'constants/_types/Basket';
import { ORDER_SERVICE_ORIGINS } from 'constants/_types/OrderServiceOrigins';
import PATHS from 'constants/router/PATHS';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

export const useOnServicesOrder = () => {
  const navigate = useNavigate();

  const onServicesOrder = useCallback(() => {
    navigate(
      createUrlWithParams({
        url: PATHS.ORDER_SERVICE,
        params: { origin: ORDER_SERVICE_ORIGINS.SERVICES, step: '2' },
        shouldReturnFullUrl: false,
      }),
    );
  }, []);

  return { onServicesOrder };
};

export const useProfileSave = () => {
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();

  const profileSave = useCallback(
    (dataToSave: Basket | null) => {
      addToGlobalDialogQueue({ type: GlobalDialogs.saveServicesProfile, props: { dataToSave } });
    },
    [addToGlobalDialogQueue],
  );

  return { profileSave };
};
