import axios from 'axios';

import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { DefaultApiResponse, PaginatedApiResponse } from '../_types';
import { parseECareInternalNotesListForFE } from './eCareInternalNotes.parsers';
import {
  CreateECareInternalNotesInput,
  ECareInternalNotes,
  ECareInternalNotesDTO,
  UpdateECareInternalNotesInput,
} from './eCareInternalNotes.types';

type GetECareAllInternalNotesQuery = () => Promise<TableDataQueryResult<keyof ECareInternalNotes>>;
type CreateNoteQuery = (data: CreateECareInternalNotesInput) => Promise<DefaultApiResponse<ECareInternalNotes>>;
type UpdateECareInternalNotesQuery = (data: UpdateECareInternalNotesInput) => Promise<DefaultApiResponse<ECareInternalNotes>>;

const endpoint = '/api/v1/ecare-order-internal-note/';

const eCareInternalNotes = {
  getAllNotes:
    (order_id: string): GetECareAllInternalNotesQuery =>
    async () => {
      const { data } = await axios.get<GetECareAllInternalNotesQuery, PaginatedApiResponse<ECareInternalNotesDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: { order: order_id || '' },
          removeEmptyParams: true,
        }),
      );
      return { data: parseECareInternalNotesListForFE(data.results), count: data.count };
    },

  createNote: (): CreateNoteQuery => data => axios.post<CreateNoteQuery, DefaultApiResponse<ECareInternalNotes>>(endpoint, data),

  patchNoteData:
    (id: string): UpdateECareInternalNotesQuery =>
    data =>
      axios.patch<UpdateECareInternalNotesQuery, DefaultApiResponse<ECareInternalNotes>>(`${endpoint}${id}/`, data),
};
export default eCareInternalNotes;
