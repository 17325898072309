import { useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import InputDropdown from 'components/UI/molecules/InputDropdown/InputDropdown';
import type { Props as InputDropdownProps } from 'components/UI/molecules/InputDropdown/InputDropdown';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import { reorderSelfPayerFirst } from 'services/reorderSelfPayerFirst/reorderSelfPayerFirst';
import { PayerChoiceStorageType } from 'storages/payerStorage/_payerChoiceStorageBase';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { populateDropdownSelectedValuesWithPayers } from './_services/populateDropdownSelectedValuesWithPayers/populateDropdownSelectedValuesWithPayers';
import { resolvePayerIdForToggle } from './_services/resolvePayerIdForToggle/resolvePayerIdForToggle';

export interface Props {
  dropdownMaxWidth?: number;
  dropdownMinWidth?: number;
  dropdownMobileMinWidth?: number;
  multiple: boolean;
  options: DropdownOption[];
  placeholder?: string;
  inputDropdownProps?: Omit<InputDropdownProps, 'value'>;
  dataSource?: PayerChoiceStorageType;
}

const PayerSelectorWithStorage: FC<Props> = ({
  dropdownMaxWidth,
  dropdownMinWidth,
  dropdownMobileMinWidth,
  multiple,
  options,
  placeholder,
  inputDropdownProps,
  dataSource,
}) => {
  const { t } = useTranslation();
  const dataSourceHook = dataSource || useUserPayersStorage;
  const { payers, rawPayers, toggle } = dataSourceHook();

  const reorderedData = useMemo(() => reorderSelfPayerFirst(rawPayers), [rawPayers]);
  const selfPaymentLabel = t(orderServiceMessages.payers.selfPayer);

  const value = useMemo(
    () =>
      populateDropdownSelectedValuesWithPayers({
        data: reorderedData,
        selectedPayers: payers,
        selfPaymentLabel,
      }),
    [payers, reorderedData],
  );

  const onValueUpdate = (_value: string[]) => {
    const payerIdToToggle = resolvePayerIdForToggle({
      oldPayersState: value,
      currentPayersState: _value,
      data: rawPayers,
      selfPaymentLabel,
    });
    if (payerIdToToggle) toggle(payerIdToToggle);
  };

  return (
    <InputDropdown
      dropdownMaxWidth={dropdownMaxWidth}
      dropdownMinWidth={dropdownMinWidth}
      dropdownMobileMinWidth={dropdownMobileMinWidth}
      multiple={multiple}
      options={options}
      placeholder={placeholder}
      value={value}
      onValueUpdate={onValueUpdate}
      {...inputDropdownProps}
    />
  );
};

export default PayerSelectorWithStorage;
