import type { FC, ReactNode } from 'react';
import { createContext, useState, useContext, useMemo, useEffect } from 'react';

import { PatientLegacy } from 'api/_types';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import useGlobalSearchPayerStorage from 'storages/payerStorage/globalSearchPayerStorage';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

type Props = {
  children: ReactNode;
};

type PatientContextType = {
  patient: PatientLegacy;
  changePatient: (newPatient: number) => void;
  patientInfoFetched: boolean;
};

const PatientContext = createContext({} as PatientContextType);

const PatientContextProvider: FC<Props> = ({ children }) => {
  const { userInfo } = useAuthContext();
  const [patientNumber, setPatientNumber] = useState<number>(0);

  const changePatient = (number: number) => setPatientNumber(number);

  const value = useMemo(
    () => ({ patient: userInfo.patients[patientNumber], patientInfoFetched: !!userInfo.id, changePatient }),
    [userInfo, patientNumber, changePatient],
  );

  const { setInitPayers: setInitPayersForGlobalSearch } = useGlobalSearchPayerStorage();
  const { setInitPayers: setInitPayersForServices } = useUserPayersStorage();
  useEffect(() => {
    if (value.patient?.id) {
      setInitPayersForGlobalSearch(value.patient.id);
      setInitPayersForServices(value.patient.id);
    }
  }, [value.patient?.id]);

  return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
};

export const usePatientContext = () => useContext(PatientContext);

export default PatientContextProvider;
