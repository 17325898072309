import type { FC, ReactNode } from 'react';

import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';

import useStyles from './ElementWithLoader.styles';

export type Props = {
  isLoading: boolean;
  children: ReactNode;
  hasFullHeight?: boolean;
  minHeight?: number;
  className?: string;
  overlayClassName?: string;
  inheritDisplayType?: boolean;
};

export const testId = createTestIdObject('ElementWithLoader', {
  loader: 'loader',
});

const ElementWithLoader: FC<Props> = ({
  isLoading,
  children,
  hasFullHeight = false,
  className,
  minHeight,
  overlayClassName,
  inheritDisplayType,
}) => {
  const { classes, cx } = useStyles({ isLoading, hasFullHeight, minHeight, inheritDisplayType });

  return (
    <div className={cx(classes.root, hasFullHeight && classes.fullHeight, className)}>
      {children}
      <div className={cx(classes.overlay, overlayClassName)} data-testid={testId.loader}>
        <LoaderOverlay inner />
      </div>
    </div>
  );
};

export default ElementWithLoader;
