import { FC, useMemo } from 'react';

import { Button, Card, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, Link, useParams } from 'react-router-dom';

import eCareOrder from 'api/eCareOrder/eCareOrder';
import { ECareSyncCusEndpoint } from 'api/eCareSyncWithCus/eCareSyncWithCus';
import { ReactComponent as ECareOrderListIcon } from 'assets/icons/hub/active/cashier.svg';
import ECareOrderStatusDropdown from 'components/UI/atoms/ECareOrderStatusDropdown/ECareOrderStatusDropdown';
import ECareSyncWithCusButton from 'components/UI/molecules/ECareSyncWithCusButton/ECareSyncWithCusButton';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import PatientDevicesDialog from 'components/UI/organisms/_dialogs/PatientDevicesDialog/PatientDevicesDialog';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import ProfessionalECareCommentsAndNotes from 'components/UI/organisms/ProfessionalECareCommentsAndNotes/ProfessionalECareCommentsAndNotes';
import ProfessionalECareOrderInfo from 'components/UI/organisms/ProfessionalECareOrderInfo/ProfessionalECareOrderInfo';
import ProfessionalECareOrderInternalPatientData from 'components/UI/organisms/ProfessionalECareOrderInternalPatientData/ProfessionalECareOrderInternalPatientData';
import ProfessionalECareOrderSuspensions from 'components/UI/organisms/ProfessionalECareOrderSuspensions/ProfessionalECareOrderSuspensions';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from './ProfessionalECareOrderSinglePage.styles';

const ProfessionalECareOrderSinglePage: FC = () => {
  usePageConfig();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { orderId } = useParams() as { orderId: string };

  const endpointsToSync: ECareSyncCusEndpoint[] = ['order'];

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ORDER, orderId], [orderId]);
  const { data: orderData } = useQuery(queryKey, eCareOrder.getECareOrder(orderId), {
    refetchOnWindowFocus: false,
  });

  const [isDevicesDialogOpen, openDevicesDialog, closeDevicesDialog] = useBooleanState(false);

  const subLabel = useMemo(() => {
    if (!orderData) return <Skeleton width={120} />;

    const age = orderData.patient ? getAgeFromDOB(orderData.patient.personalData.dateOfBirth) : '';
    return orderData?.patient ? (
      <>
        <Link
          className={classes.titleLink}
          to={`/${generatePath(PATHS.PROFESSIONAL_PATIENT_SINGLE, { patientId: orderData.patient.id.toString() })}`}
        >
          {orderData.patient.personalData.firstName} {orderData.patient.personalData.lastName}
        </Link>
        , {age} {t(generalMessages.person.age)}
      </>
    ) : (
      t(generalMessages.noData)
    );
  }, [orderData]);

  const patientId = useMemo(() => orderData?.patient?.id, [orderData]);

  const pageTitle = useMemo(
    () => ({
      headerLabel: t(proECareOrderMessages.title),
      subLabel,
      extraContentComponent: patientId ? (
        <Button className={classes.button} variant='contained' onClick={openDevicesDialog}>
          {t(proECareOrderMessages.buttons.showDevices)}
        </Button>
      ) : (
        <span />
      ),
      icon: <ECareOrderListIcon />,
      extraAfterIconComponent: <ECareOrderStatusDropdown orderId={orderId} queryKey={queryKey} value={orderData?.status || null} />,
    }),
    [orderData, t],
  );

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitleWithFiltersForTables pageTitle={pageTitle} />
      <Card>
        {orderData && (
          <>
            <ProfessionalECareOrderInfo data={orderData} />
            <ProfessionalECareOrderInternalPatientData data={orderData} />
            <ProfessionalECareOrderSuspensions data={orderData} />
            <ProfessionalECareCommentsAndNotes orderId={orderData.id} recipientId={orderData.serviceRecipient.id} />
          </>
        )}
        <CardContentContainer isLastSection>
          <ECareSyncWithCusButton
            endpoints={endpointsToSync}
            id={orderId}
            message={
              orderData?.lastSync
                ? `${t(proECareOrderMessages.cusComments.syncWithCusInfo)} ${unknownDateToString(
                    orderData.lastSync,
                    DATE_FORMATS.DISPLAY_TIME,
                  )}`
                : t(proECareOrderMessages.cusComments.noLastSyncData)
            }
          />
        </CardContentContainer>
      </Card>
      {patientId && orderId && (
        <PatientDevicesDialog close={closeDevicesDialog} isOpen={isDevicesDialogOpen} orderId={orderId} patientId={patientId} />
      )}
    </MainContentWrapper>
  );
};

export default ProfessionalECareOrderSinglePage;
