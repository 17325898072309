import { FC, useState } from 'react';

import { Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareTechnicalIssues from 'api/eCareTechnicalIssues/eCareTechnicalIssues';
import { ECareTechnicalIssuesUpdateInput } from 'api/eCareTechnicalIssues/eCareTechnicalIssues.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

export type Props = {
  close: () => void;
  isOpen: boolean;
  id: string;
  values: {
    description: string;
    fixDescription: string | null;
    fixDate: Date | null;
  };
};

type FormInput = {
  description: string;
  fixDescription: string | null;
  fixDate: Date | null;
};

const formId = 'TechnicalIssueUpdateDialog_form';

const TechnicalIssueEditDialog: FC<Props> = ({ isOpen, close, id, values }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useShowSnackbar();
  const queryClient = useQueryClient();
  const { control, handleSubmit, formState, setError } = useForm<FormInput>({
    defaultValues: {
      description: values.description,
      fixDate: values.fixDate,
      fixDescription: values.fixDescription,
    },
  });

  const [generalErrors, setGeneralErrors] = useState<string[]>();

  const onError = useOnBackendFormError<FormInput, ECareTechnicalIssuesUpdateInput>({ setError, setGeneralErrors });

  const createMutation = useMutation('Update technical issue', eCareTechnicalIssues.patchECareTechnicalIssue(id), {
    onError,
  });

  const onSubmit: SubmitHandler<FormInput> = async formInput => {
    await createMutation.mutateAsync({ ...formInput });
    queryClient.invalidateQueries(QUERY_KEYS.GET_ECARE_TECHNICAL_ISSUES);
    showSnackbar({ variant: 'success', translationArray: snackbarMessages.success });
    close();
  };

  return (
    <DialogWrapper
      close={close}
      defaultActionsConfig={{
        isLoading: createMutation.isLoading,
        okLabel: t(generalMessages.save),
        cancelLabel: t(generalMessages.cancel),
        formId,
        onClose: close,
      }}
      dialogProps={{
        maxWidth: 'xs',
      }}
      header={t(proECareTechnicalIssuesMessages.editDialogTitle)}
      isOpen={isOpen}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <SimpleFormWrapper formState={formState} globalErrors={generalErrors}>
          <FormInputText
            control={control}
            inputProps={{ multiline: true }}
            label={t(proECareTechnicalIssuesMessages.issueDetailLabel.description)}
            multiline
            name='description'
            required
            rows={3}
          />
          <FormInputDate control={control} label={t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDate)} name='fixDate' />
          <FormInputText
            control={control}
            label={t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDescription)}
            multiline
            name='fixDescription'
            rows={3}
          />
          <Typography variant='body1'>{t(proECareTechnicalIssuesMessages.form.description)}</Typography>
        </SimpleFormWrapper>
      </form>
    </DialogWrapper>
  );
};

export default TechnicalIssueEditDialog;
