import { createTheme } from '@mui/material/styles';

import defaultTheme from 'styles/theme';

const interriskTheme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    branding: {
      highlightedText: '#DA3832',
      shadow: '#1A788815',
      primary: '#EAF9FA',
      mainBackground: '#FFFFFF',
    },
  },
});

export default interriskTheme;
