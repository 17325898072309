import { makeStyles } from 'tss-react/mui';

import { DATE_SOON_AREA } from '../../_constants/gridAreas';

const useStyles = makeStyles()(theme => ({
  dateSoon: {
    gridArea: DATE_SOON_AREA,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > span': {
      paddingBottom: theme.spacing(0.5),
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'center',

      '& > span': {
        marginRight: theme.spacing(2),
      },
    },
  },

  checkboxesContainer: {
    display: 'flex',
    flexWrap: 'nowrap',

    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}));

export default useStyles;
