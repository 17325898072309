import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ECareInternalNotes } from 'api/eCareInternalNotes/eCareInternalNotes.types';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

const useInternalNoteTable = (data: ECareInternalNotes[] | undefined) => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ECareInternalNotes>();

  const columns = [
    columnHelper.accessor('content', {
      id: 'content',
      header: t(proECareOrderMessages.internalNotes.content) as string,
      enableSorting: false,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('updatedAt', {
      id: 'updatedAt',
      header: t(proECareOrderMessages.internalNotes.updatedAt) as string,
      enableSorting: false,
      cell: info => unknownDateToString(info.getValue()),
    }),
  ];

  return {
    columns,
    data: data || null,
  };
};

export default useInternalNoteTable;
