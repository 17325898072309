import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const baseStyles: CSSObject = {
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '1rem',
};

const useStyles = makeStyles()(theme => ({
  select: {
    height: '50px',
  },
  selectedMenuItem: {
    ...baseStyles,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      border: '1px solid ',
      zIndex: 1,
      borderRadius: theme.shape.borderRadius,
    },
  },
  menuItem: {
    ...baseStyles,

    '&::before': {
      content: "'•'",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      fontSize: '2rem',
      lineHeight: '1rem',
    },
  },
}));

export default useStyles;
