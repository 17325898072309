import { PatientDTO, PatientFE, PatientNote, PatientNoteDTO, PersonalData, PersonalDataDTO } from './patient.types';

const parsePersonalDataForFE = (personalData: PersonalDataDTO): PersonalData => ({
  id: personalData.id,
  firstName: personalData.first_name,
  lastName: personalData.last_name,
  gender: personalData.gender,
  email: personalData.email,
  phoneNumber: personalData.phone_number,
  mainAddress: {
    id: personalData.main_address.id,
    streetName: personalData.main_address.street_name,
    buildingNumber: personalData.main_address.building_number,
    apartmentNumber: personalData.main_address.apartment_number,
    postcode: personalData.main_address.postcode,
    city: personalData.main_address.city,
    country: personalData.main_address.country,
  },
  title: personalData.title,
  dateOfBirth: new Date(personalData.date_of_birth),
});

const parsePatentNoteForFE = (patientNote: PatientNoteDTO): PatientNote => ({
  id: patientNote.id,
  patient: patientNote.patient,
  createdAt: new Date(patientNote.created_at),
  updatedAt: new Date(patientNote.updated_at),
  createdBy: patientNote.created_by,
  updatedBy: patientNote.updated_by,
  content: patientNote.content,
  logs: patientNote.logs.map(log => ({
    id: log.id,
    createdAt: new Date(log.created_at),
    body: log.body,
    additionalData: log.additional_data,
    email: log.email,
  })),
});

export const parsePatientForFE = (patient: PatientDTO): PatientFE => ({
  id: patient.id,
  pesel: patient.pesel,
  personalData: parsePersonalDataForFE(patient.personal_data),
  foreignDocument: patient.foreign_document,
  foreignDocumentType: patient.foreign_document_type,
  foreignDocumentNumber: patient.foreign_document_number,
  foreignDocumentCountry: patient.foreign_document_country,
  croppedImage: patient.cropped_image,
  status: patient.status,
  payers: patient.payers,
  ppaCount: patient.ppa_count,
  addressDirections: patient.address_directions,
  contactPerson: patient.contact_person,
  healthCondition: patient.health_condition ? parsePatentNoteForFE(patient.health_condition) : null,
  socialSituation: patient.social_situation ? parsePatentNoteForFE(patient.social_situation) : null,
  projectUserNumber: patient.project_user_number || null,
});
