import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SELF_PAYER_IDENTIFIER } from 'api/payers/selfPayerIdentifier';
import type { Payer } from 'api/payers/types';
import generatePathName from 'services/generatePathName/generatePathName';
import orderServiceMessages from 'translations/specific/orderService.mjs';

interface HookParams {
  currentPayer: Payer | undefined;
  payerId: number;
}

export const usePayerAltLabel = ({ currentPayer, payerId }: HookParams) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const isSelfPayer = currentPayer?.code === SELF_PAYER_IDENTIFIER;
    const selfPayerLabel = t(orderServiceMessages.payers.selfPayer);
    const nameLabel = generatePathName(['payer', payerId]);

    return isSelfPayer ? selfPayerLabel : nameLabel;
  }, [currentPayer, t, payerId]);
};
