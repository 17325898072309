import axios from 'axios';

export const downloadFile = async <T extends Blob | MediaSource | string>(source: T, fileName: string) => {
  const link = document.createElement('a');

  if (typeof source === 'string') {
    const response = await axios.get(source, { headers: { Accept: '*/*' }, responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    link.href = URL.createObjectURL(blob);
  } else {
    link.href = URL.createObjectURL(source);
  }

  link.target = '_blank';
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();

  if (typeof source !== 'string') setTimeout(() => URL.revokeObjectURL(link.href), 7000); // in case the Blob uses a lot of memory
};
