const cusCommentsMessages = {
  title: ['cusCommentsMessages>>cusComments>>title', 'Uwagi do CUS'],
  titleField: ['cusCommentsMessages>>cusComments>>titleField', 'Uwaga'],
  addComment: ['cusCommentsMessages>>cusComments>>addComment', 'Dodaj uwagę do CUS'],
  lastSync: ['cusCommentsMessages>>cusComments>>lastSync', 'Ostatnia synchronizacja'],
  author: ['cusCommentsMessages>>cusComments>>author', 'Sporządził'],
  editComment: ['cusCommentsMessages>>cusComments>>editComment', 'Edytuj uwagę do CUS'],
  moreComment: ['cusCommentsMessages>>cusComments>>moreComment', 'Zobacz więcej uwag'],
  createdDate: ['cusCommentsMessages>>cusComments>>createdDate', 'Data utworzenia'],
  updateDate: ['cusCommentsMessages>>cusComments>>updateDate', 'Data aktualizacji'],
  noLastSyncData: ['cusCommentsMessages>>noLastSyncData', 'Synchronizacja z CUS jeszcze nie została wykonana'],
  syncWithCusInfo: ['cusCommentsMessages>>alarmCard>>buttons>>syncWithCusInfo', 'Ostatnia synchronizacja:'],
  edit: ['cusCommentsMessages>>cusComments>>edit', 'Edytuj'],
  receiverTitle: ['cusCommentsMessages>>cusComments>>receiverTitle', 'Odbiorca usługi'],
  form: {
    note: ['cusCommentsMessages>>form>>note', 'Notatka'],
    receiverTitle: ['cusCommentsMessages>>form>>receiverTitle', 'Odbiorca usługi'],
    receiverHelperText: ['cusCommentsMessages>>form>>receiverHelperText', 'Przynajmniej jedno pole odbiorcy musi być zaznaczone'],
    receiverError: ['cusCommentsMessages>>form>>receiverError', 'Pole wymagane'],
  },
  tooltip: {
    receiver: [
      'cusCommentsMessages>>tooltip>>receiver',
      'Ta informacja przekazywana jest TYLKO do systemu e-opieka CUS. Zgodnie z wymogiem CUS,  przynajmniej jeden odbiorca musi być zaznaczony.',
    ],
  },
};

export default cusCommentsMessages;
