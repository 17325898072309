import { useCallback, useState } from 'react';

import { uniq } from 'lodash';

import type { CoreService } from 'api/coreService/coreService.types';
import type { ColumnsDisplaySetEntities } from 'constants/_types/ColumnsDisplaySetData';
import { useServicesBasketStorageWrapper } from 'storageWrappers/useServicesBasketStorageWrapper/useServicesBasketStorageWrapper';

const useSelectLogic = () => {
  const [selected, setSelected] = useState<number[]>([]);

  const { addToBasket } = useServicesBasketStorageWrapper();

  const toggleSelected = useCallback(idToToggle => {
    setSelected(prev => (prev.includes(idToToggle) ? prev.filter(id => id !== idToToggle) : [...prev, idToToggle]));
  }, []);

  const batchSelect = useCallback((newValues: number[]) => {
    setSelected(prevState => uniq([...prevState, ...newValues]));
  }, []);

  const clearSelection = useCallback(() => {
    setSelected([]);
  }, []);

  const checkIsSelected = useCallback(
    id => {
      if (!id) return false;
      return selected.includes(id);
    },
    [selected],
  );

  const handleAddToBasket = useCallback(
    (entities?: ColumnsDisplaySetEntities<CoreService>) => {
      if (!entities) return;
      const reduceInitial: CoreService[] = [];

      const matchedValues = Object.values(entities)
        .flat()
        .reduce((acc, curr) => {
          if (selected.includes(curr.id)) {
            const metaData = curr.metaData!;
            acc.push(metaData);
          }
          return acc;
        }, reduceInitial);

      addToBasket(matchedValues);
      clearSelection();
    },
    [selected, addToBasket],
  );

  return { toggleSelected, checkIsSelected, clearSelection, selected, handleAddToBasket, batchSelect };
};

export default useSelectLogic;
