import { useTranslation } from 'react-i18next';

import { PatientFE } from 'api/patient/patient.types';
import generalMessages from 'translations/common/general.mjs';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

const usePersonalData = (person: PatientFE | undefined | null) => {
  const { t } = useTranslation();

  return [
    {
      label: t(ecarePhoneCallMessages.personal.firstName),
      value: person?.personalData.firstName || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.personal.lastName),
      value: person?.personalData.lastName || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.personal.phoneNumber),
      value: person?.personalData.phoneNumber || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.personal.projectUserNumber),
      value: person?.projectUserNumber || t(generalMessages.noData),
    },
  ];
};

export default usePersonalData;
