import { FC, ReactNode, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ECareSuspension } from 'api/eCareSuspension/eCareSuspension.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import generalMessages from 'translations/common/general.mjs';

import DeviceForm from './_forms/DeviceForm';
import OrderForm from './_forms/OrderForm';
import SuspensionsForm from './_forms/SuspensionsForm';
import TrainingForm from './_forms/TrainingForm';

export type OrderFormName = 'orderForm' | 'deviceForm' | 'trainingForm' | 'suspensionsForm';

export type Props = {
  close: () => void;
  isOpen: boolean;
  formName: OrderFormName;
  header: string;
  orderId: string;
  deviceId?: string | null;
  suspension?: ECareSuspension | null;
  isNew?: boolean;
};

const ProfessionalEOrderFormDialog: FC<Props> = ({
  close,
  isOpen,
  formName,
  header,
  deviceId = 'orderForm',
  orderId,
  suspension,
  isNew = false,
}) => {
  const { t } = useTranslation();
  const [formId, setFormId] = useState<OrderFormName>('orderForm');

  const currentForm = useMemo((): ReactNode => {
    switch (formName) {
      case 'orderForm':
        setFormId('orderForm');
        return orderId ? <OrderForm close={close} orderId={orderId} /> : '';
      case 'deviceForm':
        setFormId('deviceForm');
        return deviceId ? <DeviceForm close={close} deviceId={deviceId} /> : '';
      case 'trainingForm':
        setFormId('trainingForm');
        return orderId ? <TrainingForm close={close} orderId={orderId} /> : '';
      case 'suspensionsForm':
        setFormId('suspensionsForm');
        return <SuspensionsForm close={close} isNew={isNew} orderId={orderId} suspension={suspension || undefined} />;
      default:
        return exhaustiveGuard(formName);
    }
  }, [formName, isNew]);

  return (
    <DialogWrapper
      close={close}
      defaultActionsConfig={{
        isLoading: false,
        okLabel: t(generalMessages.save),
        cancelLabel: t(generalMessages.cancel),
        formId,
        onClose: close,
      }}
      dialogProps={{
        maxWidth: 'sm',
      }}
      header={header}
      isOpen={isOpen}
    >
      {currentForm}
    </DialogWrapper>
  );
};

export default ProfessionalEOrderFormDialog;
