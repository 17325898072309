import { FC } from 'react';

import {
  AddressDirectionModal,
  ContactPersonButtonModal,
  KeyBoxButtonModal,
  MedicalFormButtonModal,
} from 'components/UI/organisms/ProfessionalECareAlarmInfo/_modals';

import useStyles from './HeaderButtons.styles';

type Props = {
  patientId?: number | null;
};

const HeaderButtons: FC<Props> = ({ patientId }) => {
  const { classes } = useStyles();
  const medicalFormButton = MedicalFormButtonModal(patientId);
  const contactPersonButton = ContactPersonButtonModal(patientId);
  const keyBoxButton = KeyBoxButtonModal(patientId);
  const addressAndTripTips = AddressDirectionModal(patientId);

  return (
    <div className={classes.container}>
      {addressAndTripTips}
      {keyBoxButton}
      {contactPersonButton}
      {medicalFormButton}
    </div>
  );
};

export default HeaderButtons;
