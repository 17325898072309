import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  info: {
    marginRight: theme.spacing(3),
  },
  value: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      lineHeight: '1.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2.0625rem',
    },
  },
}));

export default useStyles;
