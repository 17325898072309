import { useQuery } from 'react-query';

import userPermissions from 'api/userPermissions/userPermissions';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { UserPermission } from 'constants/_types/UserPermission';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

type ComplexCheck = { comparisonMode: 'any' | 'all'; permissions: UserPermission[] };

type AvailableParam = UserPermission | ComplexCheck;

const isComplexCheck = (param: AvailableParam): param is ComplexCheck => typeof param !== 'string';

/**
 *
 * @return boolean[] or empty[]
 */
const usePermissionCheck = (permissionsToCheck: AvailableParam[], enabled = true): boolean[] => {
  const { userInfo } = useAuthContext();

  const { data } = useQuery(QUERY_KEYS.USER_PERMISSIONS, userPermissions.getUserPermissions(), {
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
    enabled: enabled && !!userInfo?.isAuthorized,
  });

  if (!data) return [];
  return permissionsToCheck.map(permission => {
    if (isComplexCheck(permission)) {
      if (permission.comparisonMode === 'all') return permission.permissions.every(permissionAsKey => data[permissionAsKey]);
      if (permission.comparisonMode === 'any') return permission.permissions.some(permissionAsKey => data[permissionAsKey]);
      throw new Error('Invalid comparison mode');
    }
    return data[permission];
  });
};

export default usePermissionCheck;
