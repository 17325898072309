import type { FC, ReactNode } from 'react';

import { Link, LinkProps, useLocation } from 'react-router-dom';

export type Props = {
  children: ReactNode;
  to: string;
} & LinkProps;

const LinkWithQuery: FC<Props> = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};

export default LinkWithQuery;
