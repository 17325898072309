type TrimStringConfig = {
  showSuffix?: boolean;
  fallback?: string;
  suffix?: string;
  keepExtension?: boolean;
};
type TrimString = (count: number, string?: string, config?: TrimStringConfig) => string;

const baseConfig: TrimStringConfig = {
  showSuffix: true,
};

const extractExtension = (string: string): string | undefined => {
  const parts = string.split('.');
  return parts.length > 1 ? parts.pop() : undefined;
};

const trimString: TrimString = (count, string, config = baseConfig) => {
  const { showSuffix, suffix = '...', fallback, keepExtension } = { ...baseConfig, ...config };
  if (!string) return fallback || '';

  const extension = keepExtension ? extractExtension(string) : undefined;
  const { length } = string;

  // Text is shorter than count = return text
  if (count >= length) return string;

  // Suffix is disabled = return standard substring
  if (!showSuffix) return string.substring(0, count);

  // Text is shorter than count and suffix = return text
  if (length < count + suffix.length) return string;

  // Text is longer than count and suffix and extension is required = return trimmed text with suffix and extension
  if (extension) return `${string.substring(0, count)}(${suffix}).${extension}`;

  // Text is longer than count and suffix = return trimmed text with suffix
  return `${string.substring(0, count)}${suffix}`;
};

export default trimString;
