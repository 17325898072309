import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import type { PreselectingOrderServiceFormMetaData } from 'constants/_types/PreselectingOrderServiceFormMetaData';
import PATHS from 'constants/router/PATHS';
import useGlobalSearchStorage from 'storages/globalSearchStorage';

export const usePreselectOrderServiceForm = () => {
  const { hideGlobalSearch } = useGlobalSearchStorage();
  const navigate = useNavigate();

  const preselectOrderServiceFormUsingItemDetails = useCallback(
    ({ serviceOrigin, serviceTypeId, serviceSubtypeId, serviceId }: PreselectingOrderServiceFormMetaData) => {
      navigate(`${PATHS.ORDER_SERVICE}?origin=${serviceOrigin}&step=1`, { state: { serviceTypeId, serviceSubtypeId, serviceId } });
      hideGlobalSearch();
    },
    [],
  );

  return { preselectOrderServiceFormUsingItemDetails };
};
