const DATE_FORMATS = {
  DISPLAY: 'dd.MM.yyyy',
  DISPLAY_TIME: "dd.MM.yyyy 'g.'HH:mm",
  DISPLAY_LONG: 'd MMMM yyyy',
  DISPLAY_LONG_WITH_TIME: 'd MMMM yyyy HH:mm',
  INPUT: 'dd/MM/yyyy',
  INPUT_TIME: 'dd/MM/yyyy HH:mm',
  API_DATE: 'yyyy-MM-dd',
  API_DATE_TIME: 'yyyy-MM-dd HH:mm:ss',
  TIME: 'HH:mm:ss',
};

export default DATE_FORMATS;
