import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(2, 3),
  },
  header: {
    fontSize: '0.8125rem',
  },
}));

export default useStyles;
