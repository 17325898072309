import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ProfessionalAlarmKeyBoxDialog from 'components/UI/organisms/_dialogs/ProfessionalAlarmKeyBoxDialog/ProfessionalAlarmKeyBoxDialog';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './KeyBoxButtonModal.styles';

const KeyBoxButtonModal = (patientId: number | null | undefined) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isOpenDialog, openDialog, closeDialog] = useBooleanState(false);
  const [canViewKeylockCode] = usePermissionCheck(['can_view_keylock_code']);

  return (
    <>
      <Tooltip title={canViewKeylockCode ? '' : (t(proECareAlarmMessages.alarmCard.buttons.keyBoxMissingPermissionInfo) as string)}>
        <span>
          <Button className={classes.patientButton} disabled={!patientId || !canViewKeylockCode} variant='outlined' onClick={openDialog}>
            {t(proECareAlarmMessages.alarmCard.buttons.keyBox)}
          </Button>
        </span>
      </Tooltip>
      {patientId && <ProfessionalAlarmKeyBoxDialog close={closeDialog} isOpen={isOpenDialog} patientId={patientId} />}
    </>
  );
};

export default KeyBoxButtonModal;
