import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { useQuery } from 'react-query';

import type { ClauseLocation } from 'api/_types';
import clauses from 'api/clauses';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

interface Props {
  children: ReactNode;
}

const ClauseLocationsContext = createContext<ClauseLocation[] | null>(null);

const ClauseLocationsContextProvider: FC<Props> = ({ children }) => {
  const { data } = useQuery(QUERY_KEYS.LOCATIONS, clauses.getClauseLocations(), {
    refetchOnWindowFocus: false,
  });

  const value = useMemo(() => {
    if (!data) return null;
    return data.data.results;
  }, [data]);

  return <ClauseLocationsContext.Provider value={value}>{children}</ClauseLocationsContext.Provider>;
};

export const useClauseLocationsContext = () => useContext(ClauseLocationsContext);

export default ClauseLocationsContextProvider;
