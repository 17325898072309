import {
  PatientRelatedFile,
  PatientRelatedFileDTO,
  PatientRelatedFileInput,
  PatientRelatedFilesInputDTO,
} from './patientRelatedFiles.types';

export const parsePatientRelatedFilesForFE = (patientRelatedFilesDTO: PatientRelatedFileDTO): PatientRelatedFile => ({
  id: patientRelatedFilesDTO.id,
  createdAt: new Date(patientRelatedFilesDTO.created_at),
  updatedAt: new Date(patientRelatedFilesDTO.updated_at),
  patient: patientRelatedFilesDTO.patient,
  fileName: patientRelatedFilesDTO.file_name,
  fileUrl: patientRelatedFilesDTO.file_url,
  type: patientRelatedFilesDTO.type,
  uploadedBy: patientRelatedFilesDTO.uploaded_by,
  description: patientRelatedFilesDTO.description,
});

export const parsePatientRelatedFilesForBE = (patientRelatedFiles: Partial<PatientRelatedFileInput>): PatientRelatedFilesInputDTO => {
  const formData = new FormData();

  Object.entries(patientRelatedFiles).forEach(([key, value]) => {
    let safeValue: string | File;

    if (typeof value === 'number') safeValue = value.toString();
    else safeValue = value;
    if (safeValue instanceof File && safeValue.size === 0) return;
    if (safeValue) formData.append(key, safeValue);
  });

  return formData;
};
