import type { FC } from 'react';
import { useMemo } from 'react';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';

import PickServicesStep from './_components/PickServicesStep/PickServicesStep';
import SummaryStep from './_components/SummaryStep/SummaryStep';
import useStyles from './OrderServiceForm.styles';

const OrderServiceForm: FC = () => {
  const { formStep } = useOrderServiceFormContext();
  const { classes } = useStyles({ formStep });

  const currentStepComponents = useMemo(() => {
    switch (formStep) {
      case 2:
        return <SummaryStep />;
      case 1:
      default:
        return <PickServicesStep />;
    }
  }, [formStep]);

  return <main className={classes.root}>{currentStepComponents}</main>;
};

export default OrderServiceForm;
