import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
  },
  link: {
    // prevent jumping on type change
    marginTop: '1px',
    '&:hover': {
      '& > span': {
        textDecoration: 'underline',
      },
    },
  },
}));

export default useStyles;
