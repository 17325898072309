import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    display: 'grid',
    gridGap: theme.spacing(4),
  },

  confirmButton: {
    justifySelf: 'end',
    [theme.breakpoints.down('sm')]: {
      justifySelf: 'unset',
    },
  },
}));

export default useStyles;
