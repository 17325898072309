import type { FC, Ref } from 'react';
import { useEffect } from 'react';

import { Slider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import { ImageReducerState } from 'components/UI/organisms/_dialogs/AvatarDialog/AvatarDialog.logic';
import AVATAR_SIZE from 'constants/styles/AVATAR_SIZE';
import patientMessages from 'translations/specific/patient.mjs';

import { useScale } from './ImageEditor.logic';
import useStyles from './ImageEditor.styles';

export type ImageEditorVariant = 'preview' | 'editor' | 'empty';

export type Props = {
  variant: ImageEditorVariant;
  editorRef: Ref<AvatarEditor>;
  image?: ImageReducerState;
};

const AVATAR_SIDE = 2 * AVATAR_SIZE.width;

const ImageEditor: FC<Props> = ({ variant, editorRef, image }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { scale, resetScale, onScaleSliderChange, scaleSlider } = useScale();

  useEffect(() => {
    resetScale();
  }, [image?.src]);

  const { classes } = useStyles();
  switch (variant) {
    case 'editor': {
      return (
        <div className={classes.editorWrapper}>
          <AvatarEditor
            border={32}
            borderRadius={theme.shape.borderRadius as number}
            color={[255, 255, 255, 0.6]}
            height={AVATAR_SIDE}
            image={image?.src as string}
            ref={editorRef}
            scale={scale}
            width={AVATAR_SIDE}
          />
          <Slider value={scaleSlider} onChange={onScaleSliderChange} />
        </div>
      );
    }
    case 'preview':
      return (
        <div className={classes.previewWrapper}>
          <img alt='avatar' src={image?.src || ''} />
        </div>
      );
    case 'empty':
    default:
      return (
        <Typography align='center' component='p' variant='subtitle3'>
          {t(patientMessages.avatar.missingFile)}
        </Typography>
      );
  }
};

export default ImageEditor;
