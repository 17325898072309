import type { FC } from 'react';

import EcarePhoneCallsList from 'components/UI/organisms/EcarePhoneCallsList/EcarePhoneCallsList';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ProfessionalEcarePhoneCallsListPage: FC = () => {
  usePageConfig();

  return (
    <MainContentWrapper extraContainer noPadding>
      <EcarePhoneCallsList />
    </MainContentWrapper>
  );
};

export default ProfessionalEcarePhoneCallsListPage;
