const partnerNotesMessages = {
  title: ['partnerNotesMessages>>title', 'Dodaj notatkę (idzie do partnera)'],
  description: ['partnerNotesMessages>>description', 'Notatka (idzie do partnera)'],
  more: ['partnerNotesMessages>>more', 'Zobacz więcej notatek'],
  eCarePartnerNotesTable: {
    id: ['partnerNotesMessages>>eCarePartnerNotesTable>>id', 'Sporządził'],
    creationTime: ['partnerNotesMessages>>eCarePartnerNotesTable>>creationTime', 'Data utworzenia'],
    updatedTime: ['partnerNotesMessages>>eCarePartnerNotesTable>>updatedTime', 'Data aktualizacji'],
    text: ['partnerNotesMessages>>eCarePartnerNotesTable>>text', 'Treść'],
    lastSync: ['partnerNotesMessages>>eCarePartnerNotesTable>>lastSync', 'Ostatnia synchronizacja'],
    receivers: ['partnerNotesMessages>>eCarePartnerNotesTable>>receiver', 'Odbiorcy'],
  },
  buttons: {
    sendToCus: ['partnerNotesMessages>>buttons>>sendToCus', 'Wyślij do CUS'],
    medicalForm: ['partnerNotesMessages>>buttons>>medicalForm', 'Otwórz ankietę medyczną'],
    contactPerson: ['partnerNotesMessages>>buttons>>contactPerson', 'Osoby do kontaktu'],
    keyBox: ['partnerNotesMessages>>buttons>>keyBox', 'Skrzynka na klucze'],
    keyBoxMissingPermissionInfo: [
      'partnerNotesMessages>>buttons>>keyBoxMissingPermissionInfo',
      'Nie masz uprawnień do wyświetlenia kodu do skrzynki na klucze',
    ],
    addressDirection: ['partnerNotesMessages>>>alarmCard>buttons>>addressDirection', 'Wskazówki dojazdu'],
    endIntervention: ['partnerNotesMessages>>buttons>>endIntervention', 'Zamknij interwencję'],
    endedIntervention: ['partnerNotesMessages>>buttons>>endedIntervention', 'Interwencja zamknięta'],
    syncWithCus: ['partnerNotesMessages>>buttons>>syncWithCus', 'Synchronizuj z CUS'],
    syncWithCusInfo: ['partnerNotesMessages>>buttons>>syncWithCusInfo', 'Ostatnia synchronizacja:'],
    alarmDescription: ['partnerNotesMessages>>buttons>>alarmDescription', 'Dodaj opis alarmu'],
    addNote: ['partnerNotesMessages>>buttons>>addNote', 'Dodaj notatkę'],
    showAllIllnesses: ['partnerNotesMessages>>buttons>>showAllIllnesses', 'Pokaż wszystkie schorzenia'],
  },
  form: {
    note: ['partnerNotesMessages>>form>>note', 'Notatka'],
    receiverTitle: ['partnerNotesMessages>>form>>receiverTitle', 'Odbiorca usługi'],
    receiverHelperText: ['partnerNotesMessages>>form>>receiverHelperText', 'Przynajmniej jedno pole odbiorcy musi być zaznaczone'],
    receiverError: ['partnerNotesMessages>>form>>receiverError', 'Pole wymagane'],
  },
  tooltip: {
    addNote: [
      'partnerNotesMessages>>tooltip>>addNote',
      'Ta uwaga zostanie przesłana do partnera jako informacja połączona ze zleceniem i sposobem jego obsługi.',
    ],
    receiver: [
      'partnerNotesMessages>>tooltip>>receiver',
      'Ta informacja przekazywana jest TYLKO do systemu e-opieka CUS. Zgodnie z wymogiem CUS,  przynajmniej jeden odbiorca musi być zaznaczony.',
    ],
  },
};

export default partnerNotesMessages;
