import { useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import type { Props as InputDropdownProps } from 'components/UI/molecules/InputDropdown/InputDropdown';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import { reorderSelfPayerFirst } from 'services/reorderSelfPayerFirst/reorderSelfPayerFirst';
import useGlobalSearchPayerStorage from 'storages/payerStorage/globalSearchPayerStorage';
import globalSearchMessages from 'translations/specific/globalSearch.mjs';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import PayerSelectorWithStorage from '../PayerSelectorWithStorage/PayerSelectorWithStorage';
import { parsePayersToDropdownOptions } from './_services/parsePayersToDropdownOptions/parsePayersToDropdownOptions';
import useStyles from './GlobalSearchPayerSelector.styles';

export interface Props {
  dropdownMaxWidth?: number;
  dropdownMinWidth?: number;
  dropdownMobileMinWidth?: number;
  inputDropdownProps?: Omit<InputDropdownProps, 'value'>;
  disabled?: boolean;
}

const GlobalSearchPayerSelector: FC<Props> = ({ dropdownMinWidth = 170, dropdownMobileMinWidth = 120, inputDropdownProps, disabled }) => {
  const { t } = useTranslation();
  const { rawPayers } = useGlobalSearchPayerStorage();

  const reorderedParsedDropdownOptions = useMemo(() => {
    const reorderedData = reorderSelfPayerFirst(rawPayers);
    return parsePayersToDropdownOptions(reorderedData, t(orderServiceMessages.payers.selfPayer));
  }, [rawPayers]);

  const { classes, cx } = useStyles();

  return (
    <ElementWithLoader hasFullHeight isLoading={!disabled && (!rawPayers || !rawPayers.length)}>
      <PayerSelectorWithStorage
        dataSource={useGlobalSearchPayerStorage}
        dropdownMaxWidth={155}
        dropdownMinWidth={dropdownMinWidth}
        dropdownMobileMinWidth={dropdownMobileMinWidth}
        inputDropdownProps={{
          hasFullHeight: true,
          className: cx(classes.select, inputDropdownProps?.className),
          disabled,
          ...(inputDropdownProps || {}),
        }}
        multiple
        options={reorderedParsedDropdownOptions}
        placeholder={t(globalSearchMessages.payers.placeholder)}
      />
    </ElementWithLoader>
  );
};

export default GlobalSearchPayerSelector;
