import { SELF_PAYER_IDENTIFIER } from '../selfPayerIdentifier';
import type { Payer, PayerDTO } from '../types';

export const parsePayersForFE = (payersDTO: PayerDTO[]): Payer[] =>
  payersDTO.map(entity => ({
    id: entity.id,
    code: entity.code,
    payerImg: {
      logoLeft: entity.payer_img?.logo_left || '',
      logoRight: entity.payer_img?.logo_right || '',
    },
    isSelfPayer: entity.code === SELF_PAYER_IDENTIFIER,
    company: entity.company
      ? {
          name: entity.company.name,
        }
      : null,
  }));
