import { memo, useMemo } from 'react';

import { Search as DetailIcon } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation } from 'react-router-dom';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import { AlarmStatusT, AlarmTypeT, ECareAlarm, ECareAlarmListFilters, isAlarmStatus, isAlarmType } from 'api/eCareAlarms/eCareAlarms.types';
import { ReactComponent as ECareAlarmListIcon } from 'assets/icons/hub/active/bell.svg';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import type { AvailableFilter } from 'constants/_types/AvailableFilter';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import generatePolishPostCode from 'helpers/generatePolishPostCode/generatePolishPostCode';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useDeviceEventDictionary from 'hooks/useDeviceEventDictionary/useDeviceEventDictionary';
import useTableData, { Config as TableConfig } from 'hooks/useTableData/useTableData';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import { alarmStatusesDictionary } from './_dictionaries/alarmStatusesDictionary';
import { alarmsTypesDictionary } from './_dictionaries/alarmsTypesDictionary';
import useStyles from './AlarmsList.styles';

const AlarmsList = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const config: Partial<TableConfig> = {
    initialSort: {
      key: 'connection_date',
      direction: 'desc',
    },
  };
  const { data: eventDevice, dictionary: eventDict, resolveValue } = useDeviceEventDictionary();
  const { query, sort, onSort, page, onFilter, onExactPage, filters, pagesCount } = useTableData<keyof ECareAlarm>({
    queryKeyBase: QUERY_KEYS.GET_ECARE_ALARMS,
    queryFunction: eCareAlarms.getAllAlarms(),
    queryOptions: { enabled: !!eventDevice },
    config,
  });
  const { data } = query;

  const alarmTypeDDL = Object.entries(alarmsTypesDictionary).map(([value, label]) => ({
    value,
    label: t(label as string[]),
  }));
  const alarmStatusDDL = Object.entries(alarmStatusesDictionary).map(([value, label]) => ({
    value,
    label: t(label as string[]),
  }));
  const eventDDL = Object.entries(eventDict).map(([value, label]) => ({ value, label }));

  const getAlarmsTypes = (status: AlarmTypeT) => (isAlarmType(status) ? t(alarmsTypesDictionary[status]) : t(generalMessages.noData));

  const getAlarmsStatus = (status: AlarmStatusT) =>
    isAlarmStatus(status) ? t(alarmStatusesDictionary[status]) : t(generalMessages.noData);

  const columnHelper = createColumnHelper<ECareAlarm>();
  const columns = [
    columnHelper.accessor('internalId', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.id) as string,
      id: 'internal_id',
      cell: info => info.getValue(),
      meta: {
        sx: { maxWidth: '8ch' },
      },
    }),
    columnHelper.accessor('sosStatus', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.alarmStatus) as string,
      id: 'sos_status',
      cell: info => getAlarmsStatus(info.getValue()),
      meta: {
        sx: { maxWidth: '22ch' },
      },
    }),
    columnHelper.accessor('device', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.device) as string,
      id: 'device',
      cell: memo((info: any) => {
        const device = info.getValue();
        return device ? <Box sx={{ overflowWrap: 'anywhere' }}>{device}</Box> : t(generalMessages.noData);
      }),
      meta: {
        sx: { maxWidth: '22ch' },
      },
    }),
    columnHelper.accessor('event', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.event) as string,
      id: 'event',
      cell: info => {
        const event = info.getValue();
        return resolveValue(event);
      },
      meta: {
        sx: { maxWidth: '22ch' },
      },
    }),
    columnHelper.accessor('subscription', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.subscription) as string,
      id: 'subscription',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('connectionDate', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.alarmDate) as string,
      id: 'connection_date',
      cell: info => unknownDateToString(info.getValue(), DATE_FORMATS.DISPLAY_TIME) || t(generalMessages.noData),
      meta: {
        sx: { width: '10ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.firstName', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.firstName) as string,
      id: 'service_recipient__patient__personal_data__first_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.personalData.lastName', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.lastName) as string,
      id: 'service_recipient__patient__personal_data__last_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.pesel', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.pesel) as string,
      id: 'service_recipient__patient__pesel',
      cell: info =>
        info.row.original?.patient
          ? `${info.getValue()},\xa0${getAgeFromDOB(info.row.original?.patient.personalData.dateOfBirth)}l`
          : t(generalMessages.noData),
      meta: {
        sx: { width: '20ch' },
      },
    }),
    columnHelper.accessor('patient.projectUserNumber', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.projectUserNumber) as string,
      id: 'service_recipient__patient__project_user_number',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '4ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.mainAddress.city', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.city) as string,
      id: 'service_recipient__patient__personal_data__main_address__city',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.personalData.mainAddress.postcode', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.postalCode) as string,
      id: 'service_recipient__patient__personal_data__main_address__postcode',
      cell: info => (info.getValue() ? generatePolishPostCode(info.getValue()) : t(generalMessages.noData)),
      meta: {
        sx: { width: '4ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.mainAddress.streetName', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.address) as string,
      id: 'service_recipient__patient__personal_data__main_address__street_name',
      cell: info =>
        info.row.original.patient
          ? `${info.getValue()} ${info.row.original.patient.personalData.mainAddress.buildingNumber}   ${
              info.row.original.patient.personalData.mainAddress.apartmentNumber
            }`
          : t(generalMessages.noData),
    }),
    columnHelper.accessor('sosType', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.sosType) as string,
      id: 'sos_type',
      cell: info => getAlarmsTypes(info.getValue()),
      meta: {
        sx: { maxWidth: '23ch' },
      },
    }),
    columnHelper.accessor('payer', {
      header: t(proECareAlarmMessages.eCareAlertTableHeaders.payer) as string,
      id: 'payer',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '15ch' },
      },
    }),
    columnHelper.accessor('operator', {
      header: memo(() => (
        <>
          {t(proECareAlarmMessages.eCareAlertTableHeaders.carer)}
          <Box sx={{ ml: 1 }}>
            <InfoIconWithTooltip content={t(proECareAlarmMessages.bitrix.operator)} />
          </Box>
        </>
      )),
      id: 'operator__last_name',
      cell: info => {
        if (info.getValue()) {
          const { first_name, last_name } = info.getValue();
          return `${first_name} ${last_name}`;
        }
        return t(generalMessages.noData);
      },
    }),
    columnHelper.display({
      id: 'detailsView',
      enableSorting: false,
      cell: memo((info: any) => (
        <Link to={`/${generatePath(PATHS.PROFESSIONAL_ECARE_ALARM_SINGLE, { alarmId: `${info.row.original.id}` })}`}>
          <DetailIcon />
        </Link>
      )),
      meta: {
        sx: { maxWidth: '5ch' },
      },
    }),
  ];

  const location = useLocation() as { state: { filterBy?: { key: string; value: string } } };

  const availableFilters: AvailableFilter<ECareAlarmListFilters>[] = useMemo(
    () => [
      { key: 'internal_id', type: 'string', label: t(proECareAlarmMessages.eCareAlertFilters.id) },
      {
        key: 'sos_status',
        type: 'dropdown',
        label: t(proECareAlarmMessages.eCareAlertFilters.alarmStatus),
        options: alarmStatusDDL,
      },
      {
        key: 'event',
        type: 'dropdown',
        label: t(proECareAlarmMessages.eCareAlertFilters.event),
        options: eventDDL,
      },
      { key: 'subscription', type: 'string', label: t(proECareAlarmMessages.eCareAlertFilters.subscription) },
      {
        key: 'connection_date',
        type: 'dateRange',
        label: t(proECareAlarmMessages.eCareAlertFilters.alarmDate),
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(proECareAlarmMessages.eCareAlertFilters.alarmDate),
      },
      {
        key: 'service_recipient__patient__personal_data__first_name',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.firstName),
      },
      {
        key: 'service_recipient__patient__personal_data__last_name',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.lastName),
      },
      {
        key: 'service_recipient__patient__pesel',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.pesel),
      },
      {
        key: 'service_recipient__patient__project_user_number',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.projectUserNumber),
      },
      {
        key: 'service_recipient__patient__personal_data__main_address__city',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.city),
      },
      {
        key: 'service_recipient__patient__personal_data__main_address__postcode',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.postalCode),
      },
      {
        key: 'service_recipient__patient__personal_data__main_address__street_name',
        type: 'string',
        label: t(proECareAlarmMessages.eCareAlertFilters.address),
      },
      {
        key: 'sos_type',
        type: 'dropdown',
        label: t(proECareAlarmMessages.eCareAlertFilters.sosType),
        options: alarmTypeDDL,
      },
      { key: 'payer', type: 'string', label: t(proECareAlarmMessages.eCareAlertFilters.payer) },
      { key: 'operator__last_name', type: 'string', label: t(proECareAlarmMessages.eCareAlertFilters.guardian) },
      {
        key: 'service_recipient__patient_id',
        type: 'hidden',
        label: t(proECareAlarmMessages.eCareAlertFilters.patientId),
        initialValue: location.state?.filterBy?.value,
      },
    ],
    [eventDDL.length, alarmStatusDDL.length, alarmTypeDDL.length],
  );

  const pageTitle = {
    headerLabel: t(proECareAlarmMessages.title),
    icon: <ECareAlarmListIcon />,
    itemsCount: data?.count || 0,
  };

  const customFilter = {
    availableFilters,
    filters,
    onFilter,
  };

  const rowClassResolver = (status: AlarmStatusT) => {
    switch (status) {
      case 'CLOSED':
        return classes.closedColor;
      case 'COMPLETE_DOCUMENTATION':
        return classes.completeDocumentationColor;
      case 'RECEIVED':
        return classes.receivedColor;
      case 'MISSED':
        return classes.missedColor;
      default:
        return exhaustiveGuard(status);
    }
  };

  return (
    <div>
      <PageTitleWithFiltersForTables customFilters={customFilter} pageTitle={pageTitle} />
      <Card className={classes.container}>
        {/* Generic Component throws error, but idk why, it should work */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CustomTable<ECareAlarm>
          columns={columns}
          config={config}
          data={data?.data}
          page={page}
          pagesCount={pagesCount}
          rowClassResolver={(rowValues: AlarmStatusT) => rowClassResolver(rowValues.sosStatus)}
          sort={sort}
          onExactPage={onExactPage}
          onSort={onSort}
        />
      </Card>
    </div>
  );
};

export default AlarmsList;
