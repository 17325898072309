import { makeStyles } from 'tss-react/mui';

import breakpoints from 'styles/themeParts/breakpoints';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled }) => ({
  wrapper: {
    [theme.breakpoints.down(breakpoints.values.searchBar + 1)]: {
      display: 'grid',
      justifyContent: 'end',
    },
  },
  searchBar: {
    display: 'grid',
    gridTemplateColumns: 'auto 200px',
  },
  inputRoot: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,

    '&:hover > fieldset': {
      borderRight: disabled ? 'none' : `thin solid ${theme.palette.grey[900]}`,
    },
  },
  inputOutline: {
    borderRight: 'none',
  },
  searchIcon: {
    '& path': {
      fill: theme.palette.grey[400],
    },
  },
}));

export default useStyles;
