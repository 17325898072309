import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ProfessionalAlarmAddressDirectionDialog from 'components/UI/organisms/_dialogs/ProfessionalAlarmAddressDirection/ProfessionalAlarmAddressDirection';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './AddressDirectionModal.styles';

const AddressDirectionModal = (patientId: number | null | undefined) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isOpenDialog, openDialog, closeDialog] = useBooleanState(false);

  return (
    <>
      <Button className={classes.patientButton} disabled={!patientId} variant='outlined' onClick={openDialog}>
        {t(proECareAlarmMessages.alarmCard.buttons.addressDirection)}
      </Button>
      {patientId && <ProfessionalAlarmAddressDirectionDialog close={closeDialog} isOpen={isOpenDialog} patientId={patientId} />}
    </>
  );
};

export default AddressDirectionModal;
