import { useCallback } from 'react';

import { useTheme } from '@mui/material/styles';
import { pdf } from '@react-pdf/renderer';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import reports from 'api/reports';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import SymptomCheckerReportPdf from 'components/UI/organisms/SymptomCheckerReportPdf/SymptomCheckerReportPdf';
import { SymptomCheckerInterviewData } from 'constants/_types/SymptomCheckerInterviewData';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { resolveTriageColor } from 'services/_dictionaryResolvers/resolveTriage/resolveTriage';
import symptomCheckerReportDataParser, {
  ReportCommonDataResult,
} from 'services/_symptomChecker/symptomCheckerReportDataParser/symptomCheckerReportDataParser';
import validateReportData from 'services/validateReportData/validateReportData';

const useGetReportBlob = () => {
  const { t } = useTranslation();
  const { brandInfo } = useBrandContext();
  const langCode = useLanguageCode();
  const theme = useTheme();

  const preparePdfProps = useCallback(
    (pdfData, triageLevel) => ({
      ...pdfData,
      triageBrandText: get(brandInfo?.brand.symptom_checker_triage, `${triageLevel}.${langCode}`, ''),
      triageStyling: {
        color: resolveTriageColor(theme, triageLevel),
      },
      triageLevel,
    }),
    [theme, brandInfo, langCode],
  );

  const getReportBlob = async (id: number): Promise<{ blob: Blob; reportData: ReportCommonDataResult }> => {
    const { data: singleReportData } = await reports.getReport(id)();
    if (!validateReportData(singleReportData.data)) throw Error('Incorrect report data');
    const pdfData = symptomCheckerReportDataParser(t, singleReportData);

    return new Promise(resolve => {
      pdf(<SymptomCheckerReportPdf {...preparePdfProps(pdfData, singleReportData.data.triageLevel)} />)
        .toBlob()
        .then(blob => resolve({ blob, reportData: pdfData }));
    });
  };

  const createReportBlobFromData = (data: SymptomCheckerInterviewData): Promise<Blob> => {
    if (!validateReportData(data)) throw Error('Incorrect report data');
    const pdfData = symptomCheckerReportDataParser(t, { data, created_at: new Date().toDateString() });

    return new Promise(resolve => {
      pdf(<SymptomCheckerReportPdf {...preparePdfProps(pdfData, data.triageLevel)} />)
        .toBlob()
        .then(blob => resolve(blob));
    });
  };

  return { getReportBlob, createReportBlobFromData };
};

export default useGetReportBlob;
