import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

interface Parameters {
  form: UseFormReturn<OrderServiceFormInput, any>;
  listeningOnValues: {
    valueOne: string | boolean;
    valueTwo?: string | boolean;
  };
  inputKeysToClear: {
    inputKeyOneToClear: string;
    inputKeyTwoToClear?: string;
  };
  defaultValue: string | null;
}

export const clearOppositeGroupOfDateInputs = ({
  form,
  listeningOnValues: { valueOne, valueTwo },
  inputKeysToClear: { inputKeyOneToClear, inputKeyTwoToClear },
  defaultValue,
}: Parameters) => {
  if (valueOne || valueTwo) {
    form.setValue(inputKeyOneToClear as any, defaultValue);
    if (inputKeyTwoToClear) form.setValue(inputKeyTwoToClear as any, defaultValue);
  }
};
