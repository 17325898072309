import type { FC } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ContactInfoData } from 'constants/_types/ContactInfo';
import helpMessages from 'translations/specific/help.mjs';

import ContactTile from '../ContactTile/ContactTile';

export interface Props {
  data: ContactInfoData;
  hasInternalBranding: boolean;
}

const ContactTiles: FC<Props> = ({ data, hasInternalBranding }) => {
  const { t } = useTranslation();

  return (
    <Grid container item md={hasInternalBranding ? 6 : 3} spacing={2} xs={12}>
      {hasInternalBranding && data.brandContactInfo && (
        <ContactTile hasInternalBranding={hasInternalBranding} header={t(helpMessages.brandContact.header)} info={data.brandContactInfo} />
      )}
      <ContactTile
        hasInternalBranding={hasInternalBranding}
        header={t(helpMessages.generalContact.header)}
        info={data.generalContactInfo}
      />
    </Grid>
  );
};

export default ContactTiles;
