import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IconErrorAlert from 'components/UI/atoms/IconErrorAlert/IconErrorAlert';
import IconInfoAlert from 'components/UI/atoms/IconInfoAlert/IconInfoAlert';
import IconSuccessAlert from 'components/UI/atoms/IconSuccessAlert/IconSuccessAlert';
import IconWarningAlert from 'components/UI/atoms/IconWarningAlert/IconWarningAlert';
import type { SnackbarVariant } from 'constants/_types/SnackbarVariant';
import snackbarMessages from 'translations/common/snackbar.mjs';

import useStyles from './SnackbarContent.styles';

type Props = {
  iconVariant: SnackbarVariant;
  text: string;
  accentColorText: string;
  hasRefreshButton: boolean;
};

const SnackbarContent: FC<Props> = ({ iconVariant, text, accentColorText, hasRefreshButton }) => {
  const { classes } = useStyles({ iconVariant });
  const { t } = useTranslation();

  const icon = useMemo(() => {
    switch (iconVariant) {
      case 'info':
        return <IconInfoAlert isBoxShadow />;
      case 'success':
        return <IconSuccessAlert isBoxShadow />;
      case 'warning':
        return <IconWarningAlert isBoxShadow />;
      case 'error':
      default:
        return <IconErrorAlert isBoxShadow />;
    }
  }, [iconVariant]);

  const colorizeText = useCallback(
    textToColorize =>
      textToColorize
        .split(' ')
        .map((word: string) => (
          <span className={word === accentColorText ? classes.accentColorText : undefined} key={word}>{` ${word} `}</span>
        )),
    [text],
  );

  const preparedText = useMemo(() => (!accentColorText ? text : colorizeText(text)), [accentColorText]);

  return (
    <>
      {icon}
      <span className={classes.text}>{preparedText}</span>
      {hasRefreshButton && (
        <Button className={classes.refreshButton} variant='text'>
          {t(snackbarMessages.buttons.refreshPage)}
        </Button>
      )}
    </>
  );
};

export default SnackbarContent;
