import type { FC } from 'react';
import { useMemo } from 'react';

import type { ColumnsDisplaySetEntities } from 'constants/_types/ColumnsDisplaySetData';
import { ColumnsDisplaySetEntity } from 'constants/_types/ColumnsDisplaySetData';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';

import useStyles from './ColumnsDisplaySetRows.styles';

export type PathToGenerator = (data: { entityId: number | string; headerId: number | string }) => string;

export type CellRendererProps = {
  entity: ColumnsDisplaySetEntity;
  config: {
    index: number;
    rowIndex: number;
    headerId: number | string;
    cellSize: number;
    columnsNumber?: number;
    pathToGenerator?: PathToGenerator;
  };
};

export type CellRendererType = (props: CellRendererProps) => JSX.Element;

export type Props = {
  entities: ColumnsDisplaySetEntities;
  headerIds: (string | number)[];
  cellSize: number;
  columnsNumber?: number;
  cellRenderer: CellRendererType;
  activeColumnId?: number | string;
  showGrid?: boolean;
  pathToGenerator?: PathToGenerator;
};

export const testId = createTestIdObject('ColumnsDisplaySetRows', {
  cell: 'cell',
  activeBorder: 'activeBorder',
});

const ColumnsDisplaySetRows: FC<Props> = ({
  entities,
  headerIds,
  cellSize,
  columnsNumber,
  cellRenderer,
  activeColumnId,
  showGrid,
  pathToGenerator,
}) => {
  const [longestTypeGroup] = useMemo(() => Object.values(entities).sort((a, b) => b.length - a.length), [entities]);

  const { classes, cx } = useStyles({ showGrid });

  const rowsCount = useMemo(() => longestTypeGroup?.length, [longestTypeGroup]);
  if (!rowsCount) return null;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Array.from(Array(rowsCount)).map((_, rowIndex) => {
        const isLastRow = rowIndex === rowsCount - 1;
        return (
          <tr className={classes.tr} key={rowIndex.toString()}>
            {headerIds.map((headerId, index) => {
              const entity = entities?.[headerId]?.[rowIndex];
              const isActive = headerId.toString() === activeColumnId;

              return (
                <td
                  className={cx(classes.td, isActive && classes.tdActive)}
                  data-testid={testId.cell}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${headerId}-${rowIndex}-${entity?.id || index}`}
                >
                  {cellRenderer({ entity, config: { index, rowIndex, headerId, cellSize, columnsNumber, pathToGenerator } })}
                  {isActive && isLastRow && <span className={classes.activeBorderNode} data-testid={testId.activeBorder} />}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export default ColumnsDisplaySetRows;
