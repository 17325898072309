import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

import { getDropdownsValues } from '../../_services/getDropdownsValues/getDropdownsValues';
import { useOrderServiceFormPreselectDropdownsValues } from '../useOrderServiceFormPreselectDropdownsValues/useOrderServiceFormPreselectDropdownsValues';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  paramOrigin: OrderServiceOrigins;
}

export const useOrderServiceFormDropdownsValues = ({ form, paramOrigin }: HookParams) => {
  const { preselectedSubtypeId, preselectedServiceId, setFormSubtypePreselectedValue, setFormServicePreselectedValue } =
    useOrderServiceFormPreselectDropdownsValues({ form, paramOrigin });

  const { serviceTypeValue, serviceSubtypeValues, servicesFromSubtypesValue } = getDropdownsValues(form);

  return {
    serviceTypeValue,
    serviceSubtypeValues: preselectedSubtypeId ? [preselectedSubtypeId] : serviceSubtypeValues,
    servicesFromSubtypesValue: preselectedServiceId || servicesFromSubtypesValue,
    preselectedSubtypeId,
    preselectedServiceId,
    setFormSubtypePreselectedValue,
    setFormServicePreselectedValue,
  };
};
