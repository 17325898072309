import type { FC } from 'react';
import { useCallback } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import MobileFixedActionIconButton from 'components/UI/atoms/MobileFixedActionIconButton/MobileFixedActionIconButton';
import { useIsMobile } from 'hooks/_breakpoints/useIsMobile/useIsMobile';

import useStyles from './PageTitleButton.styles';

export type Props = {
  buttonDisabled?: boolean;
  buttonLabel?: string;
  redirectPath?: string;
};

const PageTitleButton: FC<Props> = ({ buttonDisabled, buttonLabel, redirectPath }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { classes } = useStyles();

  const handleClick = useCallback(() => {
    if (redirectPath) navigate(redirectPath);
  }, [navigate]);

  return isMobile ? (
    <MobileFixedActionIconButton buttonProps={{ disabled: buttonDisabled }} handleClick={handleClick} icon={AddCircleOutlineIcon} />
  ) : (
    <Button
      className={classes.button}
      data-testid='titleActionButton'
      disabled={buttonDisabled}
      size='small'
      variant='contained'
      onClick={handleClick}
    >
      {buttonLabel}
    </Button>
  );
};

export default PageTitleButton;
