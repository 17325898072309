import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useMutation } from 'react-query';

import passwordChangeReminder from 'api/passwordChangeReminder';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import PasswordChangeReminderActionButtons from 'components/UI/molecules/PasswordChangeReminderActionButtons/PasswordChangeReminderActionButtons';
import PasswordChangeDialog from 'components/UI/organisms/_dialogs/PasswordChangeDialog/PasswordChangeDialog';
import { DIALOGS } from 'constants/_types/Dialogs';
import { useDialogs } from 'hooks/_dialogs/useDialogs/useDialogs';

type Props = {
  close: () => void;
};

const PasswordChangeReminderContainer: FC<Props> = ({ close }) => {
  const { userInfo } = useAuthContext();
  const { dialog, closeDialog, openPasswordChangeDialog } = useDialogs();

  const mutation = useMutation(passwordChangeReminder.postPostponeRemind());

  const handleCloseAllAndPostpone = useCallback(() => {
    mutation.mutate({ userId: userInfo.id as number });
    closeDialog();
    close();
  }, [mutation.mutate, closeDialog, close]);

  const isPasswordChangeDialogOpened = useMemo(() => dialog === DIALOGS.passwordChange, [dialog]);

  return (
    <>
      <PasswordChangeReminderActionButtons
        handleCloseAllAndPostpone={handleCloseAllAndPostpone}
        handleOpenChangePassword={openPasswordChangeDialog}
      />
      {isPasswordChangeDialogOpened && (
        <PasswordChangeDialog close={closeDialog} closeAndPostpone={handleCloseAllAndPostpone} isOpen={isPasswordChangeDialogOpened} />
      )}
    </>
  );
};

export default PasswordChangeReminderContainer;
