import type { Payer } from 'api/payers/types';

interface Params {
  oldPayersState: string[];
  currentPayersState: string[];
  data: Payer[];
  selfPaymentLabel: string;
}

export const resolvePayerIdForToggle = ({ oldPayersState, currentPayersState, data, selfPaymentLabel }: Params) => {
  const shorterPayersArray = oldPayersState.length > currentPayersState.length ? currentPayersState : oldPayersState;
  const longerPayersArray = shorterPayersArray.length === currentPayersState.length ? oldPayersState : currentPayersState;
  const resolvedPayerNameToToggle = longerPayersArray.find(payerName => !shorterPayersArray.includes(payerName));

  const isSelfPayer = resolvedPayerNameToToggle === selfPaymentLabel;

  const foundPayerToToggle = data.find(payerDataItem => {
    if (isSelfPayer) return payerDataItem.isSelfPayer;
    return payerDataItem.company?.name === resolvedPayerNameToToggle;
  });
  const payerIdToToggle = foundPayerToToggle?.id;

  return payerIdToToggle ? +payerIdToToggle : undefined;
};
