import { useCallback } from 'react';

import { useMutation } from 'react-query';

import { DefaultApiResponse } from 'api/_types';
import consents, { CreateConsentInput, CreateConsentOutput, DeclineConsentInput, DeclineConsentOutput } from 'api/consents';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import { CLAUSE_ACCEPTANCE } from 'constants/_types/ClauseAcceptance';
import type { ClauseLocationsDTO } from 'constants/_types/ClauseLocations';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useClausesConsentsQuery from 'hooks/_clauses/useClausesConsentsQuery/useClausesConsentsQuery';

type Options = {
  commonMutationOptions?: object;
};

const defaultOptions = {
  commonMutationOptions: {},
};

type MutationConfig = {
  virtualLocation?: ClauseLocationsDTO;
  updateOnlyChecked?: boolean;
  initialState?: Record<string, boolean>;
};

type UseConsentsUpdateReturn = {
  mutationStatuses: {
    update: { isLoading: boolean };
    create: { isLoading: boolean };
    all: { isLoading: boolean };
  };
  updateClausesConsents: (
    clausesToUpdate: Record<string, boolean>,
    config?: MutationConfig,
  ) => Promise<DefaultApiResponse<DeclineConsentOutput | CreateConsentOutput>[]>;
};

const useConsentUpdate = (locations: ClauseLocationsDTO[], options: Options = defaultOptions): UseConsentsUpdateReturn => {
  const { clausesWithConsents, locationsMapping } = useClausesConsentsQuery(locations);
  const { patient } = usePatientContext();
  const { userInfo } = useAuthContext();

  const createClausesConsentMutation = useMutation(QUERY_KEYS.CONSENTS_CREATE, consents.createConsent(), {
    ...options.commonMutationOptions,
  });

  const declineClausesConsentsMutation = useMutation(QUERY_KEYS.CONSENTS_DECLINE, consents.declineConsent(), {
    ...options.commonMutationOptions,
  });

  const parseClauses = useCallback(
    (
      clausesToUpdate: Record<string, boolean>,
      config: MutationConfig = {},
    ): { toCreate: CreateConsentInput[]; toDecline: DeclineConsentInput[] } => {
      const result: { toCreate: CreateConsentInput[]; toDecline: DeclineConsentInput[] } = {
        toCreate: [],
        toDecline: [],
      };
      const { virtualLocation } = config;

      Object.entries(clausesToUpdate).forEach(([clauseToUpdateId, value]) => {
        if (!clausesWithConsents) return;
        const matchedClauseWithConsent = clausesWithConsents.find(({ clauseId }) => clauseId === Number(clauseToUpdateId));
        if (matchedClauseWithConsent) {
          if (config.updateOnlyChecked && !value) return;
          if (config.initialState && config.initialState[clauseToUpdateId] === value) return;
          // BE logic: Update is possible only for declining consent
          const shouldBeDeclined =
            !value &&
            matchedClauseWithConsent.consentId &&
            !matchedClauseWithConsent.isObligatory &&
            matchedClauseWithConsent.acceptanceType === CLAUSE_ACCEPTANCE.singleAcceptance;

          if (shouldBeDeclined) {
            result.toDecline.push({ id: Number(matchedClauseWithConsent.consentId), declined: !value });
          } else {
            const clauseInput: CreateConsentInput = {
              clause: Number(clauseToUpdateId),
              declined: !value,
              location: virtualLocation ? locationsMapping[virtualLocation] : matchedClauseWithConsent.locationId,
              ordering: virtualLocation ? 99999 : matchedClauseWithConsent.slot, // pick ordering which not exist
              patient: patient?.id,
              user_account: userInfo.id,
            };
            result.toCreate.push(clauseInput);
          }
        }
      });

      return result;
    },
    [patient, clausesWithConsents, locationsMapping],
  );

  const updateClausesConsents = async (clausesToUpdate: Record<string, boolean>, config: MutationConfig = {}) => {
    const { toDecline, toCreate } = parseClauses(clausesToUpdate, config);
    const mutations = [];
    if (toCreate.length) mutations.push(createClausesConsentMutation.mutateAsync(toCreate));
    if (toDecline.length) mutations.push(...toDecline.map(data => declineClausesConsentsMutation.mutateAsync(data)));
    return Promise.all(mutations);
  };

  return {
    updateClausesConsents,
    mutationStatuses: {
      update: { isLoading: declineClausesConsentsMutation.isLoading },
      create: { isLoading: createClausesConsentMutation.isLoading },
      all: { isLoading: [declineClausesConsentsMutation, createClausesConsentMutation].some(({ isLoading }) => isLoading) },
    },
  };
};

export default useConsentUpdate;
