import type { Theme } from '@mui/material';
import type { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const getIconCss: (theme: Theme) => CSSObject = theme => ({
  position: 'absolute',
  zIndex: 0,
  '& path': {
    stroke: theme.palette.grey[300],
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
});

const antiOverlapCss: CSSObject = {
  position: 'relative',
  zIndex: 1,
};

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(12, 4),
    display: 'grid',
    gridGap: theme.spacing(4),
    justifyItems: 'center',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 1.5),
    },
  },
  header: {
    ...antiOverlapCss,
  },
  subheader: {
    ...antiOverlapCss,
    paddingTop: theme.spacing(4),
    maxWidth: theme.spacing(50),
  },
  actionContainer: {
    ...antiOverlapCss,
    display: 'flex',
    justifyContent: 'center',
  },
  heartIcon: {
    ...getIconCss(theme),
    bottom: 0,
    left: '12%',
    transform: 'translateY(24%)',
  },
  dnaIcon: {
    ...getIconCss(theme),
    top: 0,
    right: 0,
    height: '140px',
    transform: 'translate(40%, 10%) rotate(90deg)',
  },
}));

export default useStyles;
