interface Params {
  serviceTypeValue: string;
  patientId: number | undefined;
}

export const checkIsSubtypesQueryEnabled = ({ serviceTypeValue, patientId }: Params) => {
  const hasTypeValue = !!serviceTypeValue;
  const hasPatientId = !!patientId;

  return hasTypeValue && hasPatientId;
};
