import PubSub from 'pubsub-js';
import { QueryClient, setLogger } from 'react-query';

import { APP_EVENTS } from 'constants/_types/AppEvents';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: process.env.NODE_ENV === 'test' ? false : 3,
      retryDelay: attemptIndex => 250 + attemptIndex * 500,
      onError: error => {
        PubSub.publish(APP_EVENTS.apiMutationError, { error });
      },
    },
    queries: {
      retry: process.env.NODE_ENV === 'test' ? false : 3,
      retryDelay: attemptIndex => 250 + attemptIndex * 500,
      onError: error => {
        PubSub.publish(APP_EVENTS.apiFetchError, { error });
      },
    },
  },
});

if (process.env.NODE_ENV === 'test') {
  setLogger({
    // eslint-disable-next-line no-console
    log: console.log,
    // eslint-disable-next-line no-console
    warn: console.warn,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    error: () => {},
  });
}

export default queryClient;
