import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useTelemedicalMonitoringHighlight = () => {
  const location = useLocation() as { state?: { isTelemedicalMonitoringHighlighted: boolean } };

  const isHighlighted = useMemo(() => !!location.state?.isTelemedicalMonitoringHighlighted, [location.state]);

  return { isHighlighted };
};
