import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(5),
  },
}));

export default useStyles;
