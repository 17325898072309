import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  select: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

export default useStyles;
