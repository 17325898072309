import type { FC } from 'react';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';

import { usePrepareValidationRules } from '../ReferralAttachment/_hooks/usePrepareValidationRules/usePrepareValidationRules';
import ReferralAttachment from '../ReferralAttachment/ReferralAttachment';
import ReferralOrder from '../ReferralOrder/ReferralOrder';

export interface Props {
  kind: OrderServiceCoreServicesKinds;
  serviceItemId: number;
}

const ReferralSection: FC<Props> = ({ kind, serviceItemId }) => {
  const { form } = useOrderServiceFormContext();

  const formFileName = `details.${kind}.serviceItems._${serviceItemId}.file`;
  const formOrderReferralName = `details.${kind}.serviceItems._${serviceItemId}.orderReferral`;

  const validationRules = usePrepareValidationRules({ form, formFileName, formOrderReferralName });

  return (
    <>
      <ReferralAttachment form={form} formInputName={formFileName} validationRules={validationRules} />
      <ReferralOrder form={form} formInputName={formOrderReferralName} validationRules={validationRules} />
    </>
  );
};

export default ReferralSection;
