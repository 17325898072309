import { format } from 'date-fns';

import type { ServiceRequestsFormDataDto } from 'api/serviceRequests/types';
import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import type { RegularServiceDetails } from 'constants/_types/SelectedServices';
import { SERVICE_HOURS_RANGE_PLACEHOLDER } from 'constants/coreServices/serviceHoursRangePlaceholder';
import { SERVICE_ORIGINS_REQUIRING_HOURS_RANGE } from 'constants/coreServices/serviceOriginsRequiringHoursRange';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';

import { parseAddressData } from '../parseAddressData/parseAddressData';

interface Params {
  details: RegularServiceDetails;
  addressData: OrderServiceFormInput['address'];
  patientId: number;
  paramOrigin: OrderServiceOrigins;
}

export const parseRegularServices = ({ details, addressData, patientId, paramOrigin }: Params): ServiceRequestsFormDataDto[] =>
  Object.values(details.serviceItems).map(serviceItem => ({
    patient: patientId,
    payer: serviceItem.payer,
    service: serviceItem.id,
    asap: serviceItem.dateSoon === 'asap',
    first_week: serviceItem.dateSoon === 'nextSevenDays',
    completion_date_from: serviceItem.dateFrom ? format(new Date(serviceItem.dateFrom), DATE_FORMATS.API_DATE) : null,
    completion_date_to: serviceItem.dateUntil ? format(new Date(serviceItem.dateUntil), DATE_FORMATS.API_DATE) : null,
    attachments: serviceItem.attachmentId,
    referral_request: serviceItem.orderReferral,
    hours_range: SERVICE_ORIGINS_REQUIRING_HOURS_RANGE.includes(paramOrigin) ? SERVICE_HOURS_RANGE_PLACEHOLDER : '',
    medical_issue: '',
    additional_information: '',
    ...parseAddressData(addressData),
  }));
