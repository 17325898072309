import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(3, 4),
    alignContent: 'flex-start',
    gridTemplateColumns: '66% 33%',
  },
  borderRight: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(3),
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default useStyles;
