// TODO: replace with a generic component once it has been developed
// https://mdtnet.atlassian.net/jira/software/projects/MDT/boards/1?selectedIssue=MDT-1054

import { FC, useMemo } from 'react';

import { SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import eCareDictionary from 'api/eCareDictionary/eCareDictionary';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import InputDropdown from 'components/UI/molecules/InputDropdown/InputDropdown';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import sortDictionary from 'helpers/sortDictionary/sortDictionary';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './ECareAlarmIntervetionReasonStatus.styles';

export type Props = {
  alarmId: string;
  sosReasonId: string | undefined;
  isValid: boolean;
  isClosed: boolean;
};

const ECareAlarmIntervetionReasonStatus: FC<Props> = ({ alarmId, sosReasonId, isValid, isClosed }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ALERT_INTERVENTION_REASON_STATUS_FOR_PRO, alarmId], [alarmId]);

  const { data, isLoading } = useQuery(queryKey, eCareDictionary.getEcareDictionaryList('SOS_REASON'), {
    refetchOnWindowFocus: false,
  });

  const statusMutation = useMutation(eCareAlarms.patchECareAlarm(alarmId), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.GET_ECARE_ALARMS, alarmId]);
    },
  });

  const onStatusChange = async ({ target }: SelectChangeEvent<unknown>) => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.confirmationDialog.interventionReason.confirmationDialogTitle),
      body: t(proECareAlarmMessages.confirmationDialog.confirmationDialogBody),
    });
    if (confirmed) statusMutation.mutate({ sos_reason_id: target.value as number });
  };

  const inputData = useMemo(() => {
    const options = data?.data.map(({ id, name }) => ({
      value: id as number | string,
      label: name as string,
    }));

    return options && sortDictionary(options, 'label');
  }, [data?.data]);

  return (
    <div>
      <Typography className={classes.label} component='label' variant='body2'>
        {t(proECareAlarmMessages.interventionReasonStatus)}
      </Typography>
      {inputData && (
        <InputDropdown
          disabled={isLoading || isClosed}
          error={isValid && !sosReasonId}
          fullWidth
          options={inputData || undefined}
          required
          value={sosReasonId}
          onChange={onStatusChange}
        />
      )}
    </div>
  );
};

export default ECareAlarmIntervetionReasonStatus;
