import { TFunction } from 'i18next';

import type { ServicesProfile } from 'api/servicesProfile/servicesProfile.types';
import type { ServicesForColumnsDisplaySet } from 'constants/_types/ServicesForColumnsDisplaySet';

type ParseProfileServicesCatalogData = (
  t: TFunction,
  servicesData?: ServicesProfile | null,
) => {
  profileServices: ServicesForColumnsDisplaySet | null;
  profileBaseInformation: { name: string } | null;
};

const parseProfileServicesCatalogData: ParseProfileServicesCatalogData = (t, servicesData) => {
  if (!servicesData) {
    return { profileServices: null, profileBaseInformation: null };
  }

  const { name: profileName, coreServices } = servicesData;

  const reduceInitial: ServicesForColumnsDisplaySet = { headers: [], entities: {} };
  const profileServices = coreServices.reduce((accumulator, current) => {
    const { headers, entities } = accumulator;
    const { subtype } = current;
    const headerKey = subtype.id;

    // prepare headers:
    if (!headers.some(({ id }) => headerKey === id)) headers.push({ id: headerKey, name: subtype.name });

    // prepare entities:
    if (!entities[headerKey]) entities[headerKey] = [];
    entities[headerKey].push({
      id: current.id,
      name: current.name,
      metaData: current,
      searchableName: t(current.name.key, current.name.defaultValue),
    });

    return accumulator;
  }, reduceInitial);

  return { profileServices, profileBaseInformation: { name: profileName } };
};

export default parseProfileServicesCatalogData;
