const joinPath = (...args: string[]) =>
  args
    .map((part, i) => {
      const securePart = part || '';
      if (i === 0) {
        return securePart.trim().replace(/[/]*$/g, '');
      }
      return securePart.trim().replace(/(^[/]*|[/]*$)/g, '');
    })
    .filter(x => x.length)
    .join('/');

export default joinPath;
