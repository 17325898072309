import { updateTwoFA } from './updateTwoFA/updateTwoFA';
import { updateUserLanguage } from './updateUserLanguage/updateUserLanguage';

export const endpoints = {
  updateTwoFA: 'api/v1/users/update-2fa/',
  updateUserLanguage: 'api/v1/users/update-language/',
};

const users = {
  updateTwoFA,
  updateUserLanguage,
};

export default users;
