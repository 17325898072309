import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';

import { useTheme } from '@mui/material/styles';

import IconWithBackground from 'components/UI/atoms/IconWithBackground/IconWithBackground';
import type { Message } from 'components/UI/organisms/MainContentWrapper/_types/types';
import { MESSAGE_ORIGINS } from 'components/UI/organisms/MainContentWrapper/_types/types';

interface HookParams {
  text: JSX.Element | null;
  headerText: string;
  icon: JSX.Element | null;
  setMessages: Dispatch<SetStateAction<Message[]>>;
}

export const useMessageHandlers = ({ text, headerText, icon, setMessages }: HookParams) => {
  const theme = useTheme();

  const setShowSubmissionSuccessNotification = useCallback(
    (tempMessages: Message[]) => {
      if (!text || !icon) return;

      tempMessages.push({
        origin: MESSAGE_ORIGINS.submissionSuccess,
        content: text,
        severity: 'success',
        icon: <IconWithBackground backgroundColor={theme.palette.background.default} icon={icon} />,
        header: <span>{headerText}</span>,
      });
    },
    [icon, text],
  );

  const removeSubmissionSuccessNotification = useCallback(() => {
    setMessages(prevMessages => prevMessages.filter(({ origin }) => origin !== MESSAGE_ORIGINS.submissionSuccess));
  }, []);

  return { setShowSubmissionSuccessNotification, removeSubmissionSuccessNotification };
};
