import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';

import isDevelopment from 'helpers/isDevelopment/isDevelopment';
import isProduction from 'helpers/isProduction/isProduction';

import GeneralErrorContent from './_components/GeneralErrorContent';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

class GeneralError extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (isProduction()) {
      // logErrors;
    }
    if (isDevelopment()) {
      /* eslint-disable no-console */
      console.error({ error });
      console.error({ errorInfo });
      /* eslint-enable no-console */
    }
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return <GeneralErrorContent />;
    }

    return props.children;
  }
}

export default GeneralError;
