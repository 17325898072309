import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Card, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import validationMessages from 'translations/common/validation.mjs';

import useStyles from './PasswordValidation.styles';

type Props = {
  password?: string;
  rePassword?: string;
  setIsValid: (param: boolean) => void;
};

const PASSWORD_MIN_LENGTH = Number(process.env.REACT_APP_PASSWORD_MIN_LENGTH) || 12;

const rules = [
  {
    message: validationMessages.password.min_length,
    messageConfig: { minLength: PASSWORD_MIN_LENGTH },
    test: (password?: string, rePassword?: string): boolean => (password ? password.length >= PASSWORD_MIN_LENGTH : false),
  },
  {
    message: validationMessages.password.lowercase,
    test: (password?: string, rePassword?: string): boolean => (password ? /[a-z]/.test(password) : false),
  },
  {
    message: validationMessages.password.uppercase,
    test: (password?: string, rePassword?: string): boolean => (password ? /[A-Z]/.test(password) : false),
  },
  {
    message: validationMessages.password.number,
    test: (password?: string, rePassword?: string): boolean => (password ? /[0-9]/.test(password) : false),
  },
  {
    message: validationMessages.password.special_char,
    test: (password?: string, rePassword?: string): boolean => (password ? /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password) : false),
  },
  {
    message: validationMessages.password.match,
    test: (password?: string, rePassword?: string): boolean => (password ? rePassword === password : false),
  },
];

const PasswordValidation: FC<Props> = ({ password, rePassword, setIsValid }) => {
  const { t } = useTranslation();

  const generateValidators = () =>
    rules.map(({ message, test, messageConfig }) => ({
      message: t(message, messageConfig),
      isValid: test(password, rePassword),
    }));
  const [validators, setValidators] = useState(generateValidators());

  useEffect(() => {
    const newValidators = generateValidators();
    const allValid = newValidators.every(({ isValid }) => isValid);
    setIsValid(allValid);
    setValidators(newValidators);
  }, [password, rePassword]);

  const { classes, cx } = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <List dense>
        {validators.map(({ message, isValid }) => (
          <ListItem className={classes.listItem} key={message}>
            <ListItemText>
              <Typography className={cx(classes.listItemText, isValid && classes.listItemText_valid)}>{t(message)}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default PasswordValidation;
