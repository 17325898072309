import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  iframe: {
    height: '100%',
    width: '100%',
    border: 'none',
  },
}));

export default useStyles;
