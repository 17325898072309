import type { DashboardButtonOrderServiceType } from 'constants/_types/DashboardButtons';
import { ORDER_SERVICE_ORIGINS, type OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

export const getResolvedTitleIcon = (sickCardButtons: DashboardButtonOrderServiceType[], origin: OrderServiceOrigins | undefined) => {
  if (!origin) return null;

  const foundItem = sickCardButtons.find(item => {
    // use OTHERS icon for SERVICES
    if (origin === ORDER_SERVICE_ORIGINS.SERVICES) return item.orderServiceProps?.origin === ORDER_SERVICE_ORIGINS.OTHERS;
    //
    return item.orderServiceProps?.origin === origin;
  });

  return foundItem?.orderServiceProps.icon ?? sickCardButtons[1].orderServiceProps.icon;
};
