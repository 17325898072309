import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import ECareAlarmInterventionEffect from 'components/UI/molecules/ECareAlarmForms/ECareAlarmInterventionEffect/ECareAlarmInterventionEffect';
import ECareAlarmInterventionType from 'components/UI/molecules/ECareAlarmForms/ECareAlarmInterventionType/ECareAlarmInterventionType';
import ECareAlarmIntervetionReasonStatus from 'components/UI/molecules/ECareAlarmForms/ECareAlarmIntervetionReasonStatus/ECareAlarmIntervetionReasonStatus';
import ECareAlarmType from 'components/UI/molecules/ECareAlarmForms/ECareAlarmType/ECareAlarmType';

type Props = {
  alarmId: string;
  eCareAlarmsData: ECareAlarm;
  isValid: boolean;
  isClosed: boolean;
};

const useDropdownList = ({ alarmId, eCareAlarmsData, isValid, isClosed }: Props) => [
  {
    label: '',
    value: eCareAlarmsData ? (
      <ECareAlarmType alarmId={alarmId} currentOption={eCareAlarmsData.sosType} isClosed={isClosed} isValid={isValid} />
    ) : (
      ''
    ),
  },
  {
    label: '',
    value: (
      <ECareAlarmIntervetionReasonStatus
        alarmId={alarmId}
        isClosed={isClosed}
        isValid={isValid}
        sosReasonId={eCareAlarmsData?.sosReason?.id || undefined}
      />
    ),
  },
  {
    label: '',
    value: (
      <ECareAlarmInterventionType
        alarmId={alarmId}
        interventionTypeId={eCareAlarmsData?.interventionType?.id || undefined}
        isClosed={isClosed}
        isValid={isValid}
      />
    ),
  },
  {
    label: '',
    value: (
      <ECareAlarmInterventionEffect
        alarmId={alarmId}
        interventionEffectId={eCareAlarmsData?.interventionEffect?.id || undefined}
        isClosed={isClosed}
        isValid={isValid}
      />
    ),
  },
];

export default useDropdownList;
