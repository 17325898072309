import type { FC } from 'react';

import { Card, Grid } from '@mui/material';

import SettingSection from 'components/UI/molecules/SettingSection/SettingSection';

import { useSettingsData } from './_hooks/useSettingsData/useSettingsData';
import useStyles from './SettingsCard.styles';

const GRID_SPACING = 5;

const SettingsCard: FC = () => {
  const { classes } = useStyles({ gridSpacing: GRID_SPACING });
  const { settingsData } = useSettingsData();

  return (
    <Card className={classes.root}>
      <Grid container spacing={GRID_SPACING}>
        {settingsData?.map(({ content, headerText, id }) => (
          <SettingSection content={content} headerText={headerText} key={id} />
        ))}
      </Grid>
    </Card>
  );
};

export default SettingsCard;
