import type { TFunction } from 'react-i18next';

import type { CoreService } from 'api/coreService/coreService.types';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface Params {
  data: CoreService[] | undefined;
  t: TFunction;
}

export const parseServices = ({ data, t }: Params): DropdownOption[] => {
  if (!data?.length) return [];

  return data.map(({ id, name, coreServiceKind }) => ({
    value: `${id}`,
    label: t([name.key, name.defaultValue]) || name.defaultValue,
    meta: { id: `${id}`, coreServiceKind },
  }));
};
