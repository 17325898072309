import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import passwordChangeReminder from 'api/passwordChangeReminder';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import IconWarningAlert from 'components/UI/atoms/IconWarningAlert/IconWarningAlert';
import type { Message } from 'components/UI/organisms/MainContentWrapper/_types/types';
import { MESSAGE_ORIGINS } from 'components/UI/organisms/MainContentWrapper/_types/types';
import PasswordChangeReminderContainer from 'components/UI/organisms/PasswordChangeReminderContainer/PasswordChangeReminderContainer';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import passwordChangeReminderMessages from 'translations/specific/passwordChangeReminder.mjs';

export const usePasswordChangeReminder = (setMessages: Dispatch<SetStateAction<Message[]>>) => {
  const { t } = useTranslation();
  const { userInfo } = useAuthContext();
  const languageCode = useLanguageCode();

  const { data } = useQuery(QUERY_KEYS.SHOULD_SHOW_PASSWORD_CHANGE_REMINDER, passwordChangeReminder.getShouldRemind(), {
    refetchOnWindowFocus: false,
    enabled: !!userInfo?.isAuthorized,
  });

  const shouldRemindPasswordChange = useMemo(() => data?.data?.should_change_password, [data, languageCode]);

  const removePasswordChangeReminderAlert = useCallback(() => {
    setMessages(prevMessages => prevMessages.filter(({ origin }) => origin !== MESSAGE_ORIGINS.passwordChangeReminder));
  }, [setMessages]);

  const setPasswordChangeReminder = (tempMessages: Message[]) => {
    tempMessages.push({
      origin: MESSAGE_ORIGINS.passwordChangeReminder,
      content: (
        <>
          {t(passwordChangeReminderMessages.alert.content)} <strong>{t(passwordChangeReminderMessages.alert.strongContent)}</strong>
        </>
      ),
      severity: 'warning',
      icon: <IconWarningAlert isBoxShadow />,
      action: <PasswordChangeReminderContainer close={removePasswordChangeReminderAlert} />,
    });

    return tempMessages;
  };

  return { shouldRemindPasswordChange, setPasswordChangeReminder };
};
