import { FC, useMemo } from 'react';

import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import BitrixNotes from 'components/UI/molecules/BitrixNotes/BitrixNotes';

import CardContentContainer from '../CardContentContainer/CardContentContainer';
import { InterventionData, PartnerNotes } from './_components';
import useStyles from './ProfessionalECareAlarmInfo.styles';

type Props = {
  eCareAlarmsData: ECareAlarm;
  alarmId: string;
  isValid: boolean;
};

const ProfessionalECareAlarmInfo: FC<Props> = ({ eCareAlarmsData, alarmId, isValid }) => {
  const { classes } = useStyles();

  const isClosed = useMemo(() => eCareAlarmsData.sosStatus === 'CLOSED', [eCareAlarmsData.sosStatus]);

  return (
    <>
      <InterventionData alarmId={alarmId} eCareAlarmsData={eCareAlarmsData} isValid={isValid} />
      <CardContentContainer className={classes.cardContainer} isLastSection>
        <div className={classes.notesWrapper}>
          <BitrixNotes contentType='ecaresos' disabled={isClosed} objectId={alarmId} />
          <PartnerNotes disabled={isClosed} eCareAlarmsData={eCareAlarmsData} />
        </div>
      </CardContentContainer>
    </>
  );
};
export default ProfessionalECareAlarmInfo;
