import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';

import { endpoints } from '../contactForm';
import { parseContactFormCategoriesForFE } from '../parseContactFormCategoriesForFE/parseContactFormCategoriesForFE';
import type { ContactFormCategory, ContactFormCategoryDto } from '../types';

type GetContactFormCategoriesQuery = () => Promise<PaginatedApiResponse<ContactFormCategoryDto>>;
type GetContactFormCategories = () => Promise<ContactFormCategory[]>;

export const getContactFormCategories = (): GetContactFormCategories => async () => {
  const data = await axios.get<GetContactFormCategoriesQuery, PaginatedApiResponse<ContactFormCategoryDto>>(endpoints.getCategories);

  return parseContactFormCategoriesForFE(data.data.results);
};
