import type { UseFormReturn } from 'react-hook-form';

import type { Basket } from 'constants/_types/Basket';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type {
  CumulativeExtendedServiceItem,
  CumulativeServiceDetails,
  CumulativeServiceItem,
  ServiceItem,
} from 'constants/_types/SelectedServices';
import { CUMULATIVE_CORE_SERVICE_KINDS } from 'constants/coreServices/cumulativeCoreServiceKinds';

import type { OrderServiceFormInput } from '../../_constants/inputKeys';

export interface Params {
  form: UseFormReturn<OrderServiceFormInput, any>;
  basketState: Basket | null;
}

export const decreaseFormServicesNumberWithBasketState = ({ form, basketState }: Params) => {
  // Prepares basic info about services from the basket (kind and id)
  const basketStateServicesKindsAndIds = basketState
    ? Object.values(basketState).reduce(
        (acc, { items }) => [...acc, ...Object.values(items).map(({ id, coreServiceKind }) => ({ id: `${id}`, coreServiceKind }))],
        [] as { id: string; coreServiceKind: OrderServiceCoreServicesKinds }[],
      )
    : [];

  // Updates services from the form
  const updatedFormDetails = Object.entries(form.getValues().details).reduce((acc, [coreServiceKind, content]) => {
    const updatedFormCurrentKindServiceItems = { ...content.serviceItems };

    // Services from the basket of the current kind
    const basketStateCurrentKindServicesKindsAndIds = basketStateServicesKindsAndIds.filter(
      ({ coreServiceKind: basketStateCoreServiceKind }) => basketStateCoreServiceKind === coreServiceKind,
    );

    // Removes service from the form if this service is not present in the basket (compares ids)
    Object.keys(updatedFormCurrentKindServiceItems).forEach(formServiceUnderscoreId => {
      const formServiceId = formServiceUnderscoreId.replace('_', '');
      const isBasketStateContainingCurrentFormService =
        basketStateCurrentKindServicesKindsAndIds.filter(({ id }) => id === formServiceId).length > 0;

      if (!isBasketStateContainingCurrentFormService) delete updatedFormCurrentKindServiceItems[formServiceUnderscoreId];
    });

    // Adjusts output shape basing on cumulative character of the current kind
    const isCurrentKindCumulative = CUMULATIVE_CORE_SERVICE_KINDS.includes(coreServiceKind as OrderServiceCoreServicesKinds);
    if (isCurrentKindCumulative)
      return {
        ...acc,
        [coreServiceKind]: {
          ...content,
          serviceItems: updatedFormCurrentKindServiceItems,
        } as CumulativeServiceDetails<Record<string, CumulativeServiceItem | Record<string, CumulativeExtendedServiceItem>>>,
      };

    return {
      ...acc,
      [coreServiceKind]: {
        serviceItems: updatedFormCurrentKindServiceItems as Record<string, ServiceItem>,
      },
    };
  }, {});

  form.setValue('details', updatedFormDetails);
};
