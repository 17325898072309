import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from 'assets/icons/hub/active/gearwheel.svg';
import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import SettingsCard from 'components/UI/organisms/SettingsCard/SettingsCard';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

const SettingsPage: FC = () => {
  const { t } = useTranslation();
  usePageConfig();

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitle headerLabel={t(userSettingsMessages.header)} icon={<SettingsIcon />} />
      <SettingsCard />
    </MainContentWrapper>
  );
};

export default SettingsPage;
