import type { FC } from 'react';
import { useMemo } from 'react';

import type { ParsedListData } from 'api/_parsedTypes';
import type { MobileCardHeader } from 'constants/_types/MobileCardHeader';
import type { MobileCardRow, MobileCardRowCustomStyles } from 'constants/_types/MobileCardRow';

import TableMobileCardRow from './_components/TableMobileCardRow/TableMobileCardRow';
import useStyles from './TableMobileCard.styles';

export interface Props {
  headers: MobileCardHeader[];
  rows: MobileCardRow[];
  rowCustomStyles: MobileCardRowCustomStyles;
  rowLink?: ({ meta }: ParsedListData) => string;
  actionsRenderer?: ({ meta, created_at }: ParsedListData, shouldShowVerticalVersion?: boolean) => JSX.Element;
}

const TableMobileCard: FC<Props> = ({ headers, rows, actionsRenderer, ...propsRest }) => {
  const { classes } = useStyles();

  const { mainHeader, bodyHeaders } = useMemo(
    () => (actionsRenderer ? { mainHeader: headers[0], bodyHeaders: headers.slice(1) } : { mainHeader: null, bodyHeaders: headers }),
    [headers, actionsRenderer],
  );

  const props = {
    mainHeader,
    bodyHeaders,
    actionsRenderer,
    ...propsRest,
  };

  return (
    <div className={classes.cardsContainer}>
      {rows.map(row => (
        <TableMobileCardRow key={row.id} row={row} {...props} />
      ))}
    </div>
  );
};

export default TableMobileCard;
