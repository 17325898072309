import { useEffect, useState } from 'react';

import { intervalToDuration } from 'date-fns';

const INTERVAL_TIME = 1000;

type UseDifferenceBetweenDatesParams = {
  date: Date;
  stopDate?: Date | null;
  isInterval?: boolean;
};

const useDifferenceBetweenDates = ({ date, stopDate, isInterval }: UseDifferenceBetweenDatesParams) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    if (!isInterval) return () => null;
    const interval = setInterval(() => setNow(new Date()), INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isInterval) return intervalToDuration({ start: now, end: date });

  return intervalToDuration({ start: stopDate || now, end: date });
};

export default useDifferenceBetweenDates;
