import type { FC } from 'react';
import { useMemo } from 'react';

import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputSwitch from 'components/UI/molecules/InputSwitch/InputSwitch';
import validationMessages from 'translations/common/validation.mjs';

interface Props {
  label: string | JSX.Element;
  name: string;
  control: Control<any>;
  required?: boolean;
  defaultValue?: boolean;
  rules?: ValidationRule<any>;
  inputProps?: {
    disabled?: boolean;
  };
}

const FormInputSwitch: FC<Props> = ({ label, name, control, required = false, defaultValue = false, rules = {}, inputProps = {} }) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_consent) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => <InputSwitch label={label} required={required} {...inputProps} {...field} />}
      rules={rulesProp}
    />
  );
};

export default FormInputSwitch;
