import { useTranslation } from 'react-i18next';

import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import { eCareImplementationStatusDictionary } from 'components/UI/organisms/ECareList/ECareStatus';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

const useGenerateOrders = (data: ECareOrder | undefined) => {
  const { t } = useTranslation();

  return {
    section1: [
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.id),
        value: data?.id || t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.implementationStatus),
        value: (data && t(eCareImplementationStatusDictionary[data.implementationStatus])) || t(generalMessages.noData),
      },
    ],
    section2: [
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.orderDate),
        value: data ? unknownDateToString(data.orderDate, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.serviceStartDate),
        value: data ? unknownDateToString(data.serviceStartDate, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.serviceEndDate),
        value: data && data.serviceEndDate ? unknownDateToString(data.serviceEndDate, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
      },
    ],
    section3: [
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.serviceTerminationReason),
        value: data?.serviceTerminationReason || t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.eOrderTableHeaders.payer),
        value: data?.payers?.filter(Boolean).join(', ') || t(generalMessages.noData),
      },
    ],
  };
};

export default useGenerateOrders;
