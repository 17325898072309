import { useEffect, useMemo, useRef, type FC } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import languages from 'constants/translations/languages';
import { setLanguage } from 'services/_language/setLanguage/setLanguage';

import LanguageItem from './_components/LanguageItem/LanguageItem';
import { useLanguageChangeControl } from './_hooks/useLanguageChangeControl/useLanguageChangeControl';
import { renderFlag } from './_services/renderFlag/renderFlag';
import useStyles from './LanguageChanger.styles';

const LanguageChanger: FC = () => {
  const { i18n, t } = useTranslation();
  const { userInfo } = useAuthContext();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { open, isLoading, changeLanguage, handleToggle, handleClose } = useLanguageChangeControl();

  const { isInitializing, preselectedLanguage } = useMemo(() => {
    const _isInitializing = !userInfo.language;
    const _preselectedLanguage = languages.find(({ code }) => code === userInfo.language);

    return { isInitializing: _isInitializing, preselectedLanguage: _preselectedLanguage ?? languages[0] };
  }, [userInfo.language]);

  useEffect(() => {
    (async () => {
      await setLanguage(preselectedLanguage.code, i18n);
    })();
  }, [preselectedLanguage]);

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <ElementWithLoader isLoading={isInitializing}>
        <Button className={classes.expandButton} ref={anchorRef} size='small' variant='outlined' onClick={handleToggle}>
          <div className={classes.flagContainer}>
            {renderFlag(preselectedLanguage.icon, classes.flag)}
            {t(preselectedLanguage.label)}
          </div>
          <ArrowDropDownIcon className={classes.chevron} />
          <Popper anchorEl={anchorRef.current} className={classes.popper} disablePortal open={open} placement='bottom' transition>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu'>
                      {languages.map(({ label, code, icon }) => (
                        <LanguageItem
                          changeLanguage={changeLanguage}
                          className={classes.flag}
                          code={code}
                          disabled={isLoading}
                          icon={icon}
                          key={code}
                          label={label}
                        />
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Button>
      </ElementWithLoader>
    </div>
  );
};

export default LanguageChanger;
