import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreServiceSubtypes from 'api/coreServiceSubtypes/coreServiceSubtypes';
import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';

import { checkIsSubtypesQueryEnabled } from './_services/checkIsSubtypesQueryEnabled/checkIsSubtypesQueryEnabled';
import { parseServiceSubtypes } from './_services/parseServiceSubtypes/parseServiceSubtypes';

interface HookParams {
  paramOrigin: OrderServiceOrigins;
  selectedPayerIds: string[];
  serviceTypeValue: string;
  setFormSubtypePreselectedValue: (serviceSubtypes: DropdownOption[]) => void;
}

export const useServiceSubtypesQuery = ({
  paramOrigin,
  selectedPayerIds,
  serviceTypeValue,
  setFormSubtypePreselectedValue,
}: HookParams) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const { form } = useOrderServiceFormContext();

  const onClearThisAndLowerDropdowns = useCallback(() => {
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceSubtype as 'serviceSubtype', []);
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype as 'serviceFromSubtype', '');
  }, []);

  const { data: serviceSubtypesData, refetch: refetchSubtypes } = useQuery(
    [QUERY_KEYS.CORE_SERVICE_SUBTYPES, createQueryKeyFromObject({ serviceTypeValue })],
    coreServiceSubtypes.getServiceSubtypes({
      patientId: patient?.id,
      serviceOrigin: paramOrigin,
      masterType: serviceTypeValue,
      masterTypeIsNull: false,
      payers: selectedPayerIds,
    }),
    {
      enabled: checkIsSubtypesQueryEnabled({ serviceTypeValue, patientId: patient?.id }),
      refetchOnWindowFocus: false,
      retry: false,
      onSettled: data => {
        onClearThisAndLowerDropdowns();
        const parsedData = parseServiceSubtypes({ data, t });
        setFormSubtypePreselectedValue(parsedData);
      },
    },
  );

  const serviceSubtypes = useMemo(() => parseServiceSubtypes({ data: serviceSubtypesData, t }), [serviceSubtypesData, t]);

  return { serviceSubtypes, refetchSubtypes };
};
