import { useEffect, useMemo, type FC } from 'react';

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import OrderServiceFormContextProvider from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import DisabledWrapper from 'components/UI/atoms/DisabledWrapper/DisabledWrapper';
import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import OrderServiceForm from 'components/UI/organisms/OrderServiceForm/OrderServiceForm';
import type { DashboardButtonOrderServiceType } from 'constants/_types/DashboardButtons';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import { useGetSickCardButtons } from 'hooks/_dashboardButtons/useGetSickCardButtons/useGetSickCardButtons';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';
import useCurrentPathConfigStorage from 'storages/currentPathConfigStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { getResolvedTitleIcon } from './_services/getResolvedTitleIcon/getResolvedTitleIcon';
import useStyles from './OrderServicePage.styles';

const STEPS_WITH_BASKET = [1];

const OrderServicePage: FC = () => {
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();
  const { getConfig, baseConfig, overrideConfig } = useCurrentPathConfigStorage();
  const config = useMemo(getConfig, [baseConfig, overrideConfig]);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { classes } = useStyles();

  const { sickCardButtons } = useGetSickCardButtons() as { sickCardButtons: DashboardButtonOrderServiceType[] };

  const { setShouldShowMobileBasket } = useBottomFixedContainerStorage();

  const { paramOrigin, paramStep } = useMemo(
    () => ({ paramOrigin: searchParams.get('origin') as OrderServiceOrigins, paramStep: +(searchParams.get('step') || 1) }),
    [searchParams],
  );

  useEffect(() => {
    setShouldShowMobileBasket(STEPS_WITH_BASKET.includes(paramStep));
    return () => setShouldShowMobileBasket(false);
  }, [paramStep]);

  const icon = useMemo(() => getResolvedTitleIcon(sickCardButtons, paramOrigin), [sickCardButtons, searchParams]);

  return (
    <OrderServiceFormContextProvider formMaxStep={config?.formMaxStep as number} formStep={paramStep}>
      <MainContentWrapper>
        <PageTitle
          formMaxStep={config?.formMaxStep}
          formStep={paramStep}
          hasCloseButton
          headerLabel={t(orderServiceMessages[paramOrigin].title)}
          icon={icon}
          paramOrigin={paramOrigin}
        />
        <Divider className={classes.divider} variant='fullWidth' />
        <DisabledWrapper disabled={!mainFunctionalitiesAllowed}>
          <OrderServiceForm />
        </DisabledWrapper>
      </MainContentWrapper>
    </OrderServiceFormContextProvider>
  );
};

export default OrderServicePage;
