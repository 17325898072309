import { ECareSosInternalNote, ECareSosInternalNoteDTO } from './eCareSosPcInternalNotes.types';

export const parseSingleSosInternalNoteForFE = (eCareSosInternalNoteDTO: ECareSosInternalNoteDTO): ECareSosInternalNote => ({
  id: eCareSosInternalNoteDTO.id,
  createdBy: {
    firstName: eCareSosInternalNoteDTO.created_by?.first_name || null,
    lastName: eCareSosInternalNoteDTO.created_by?.last_name || null,
    phoneNumber: eCareSosInternalNoteDTO.created_by.phone_number,
    email: eCareSosInternalNoteDTO.created_by.email,
  },
  updatedBy: {
    firstName: eCareSosInternalNoteDTO.updated_by?.first_name || null,
    lastName: eCareSosInternalNoteDTO.updated_by?.last_name || null,
    phoneNumber: eCareSosInternalNoteDTO.updated_by?.phone_number || null,
    email: eCareSosInternalNoteDTO.updated_by?.email || null,
  },
  text: eCareSosInternalNoteDTO.text,
  objectId: eCareSosInternalNoteDTO.object_id || null,
  contentType: eCareSosInternalNoteDTO.content_type || null,
  creationTime: new Date(eCareSosInternalNoteDTO.creation_time),
  updateTime: new Date(eCareSosInternalNoteDTO.update_time),
  updatedAt: new Date(eCareSosInternalNoteDTO.updated_at),
  createdAt: new Date(eCareSosInternalNoteDTO.created_at),
});

export const parseECareSosInternalNoteForFE = (eCareContactNoteDTO: ECareSosInternalNoteDTO[]): ECareSosInternalNote[] =>
  eCareContactNoteDTO.map(parseSingleSosInternalNoteForFE);
