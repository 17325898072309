import { isAfter, isBefore } from 'date-fns';
import type { TFunction } from 'i18next';
import type { Control, ValidationRule } from 'react-hook-form';

import validationMessages from 'translations/common/validation.mjs';

interface Params {
  value: Date;
  t: TFunction;
  rules: ValidationRule<any>;
  control: Control<any>;
  disablePast: boolean;
  disableFuture: boolean;
}

export const resolveDateValidation = ({ value, t, rules, control, disablePast, disableFuture }: Params) => {
  const isInvalidDate = value && value.toString() === 'Invalid Date' ? t(validationMessages.invalid_dateformat) : false;

  if (isInvalidDate) return isInvalidDate;

  const additionalValidation = [];
  const today = new Date().setHours(0, 0, 0, 0);
  if (rules.validate) additionalValidation.push(rules.validate(value, control));
  if (disablePast && isBefore(value, today)) additionalValidation.push(t(validationMessages.invalid_date_past));
  if (disableFuture && isAfter(value, today)) additionalValidation.push(t(validationMessages.invalid_date_future));

  return additionalValidation.find(e => typeof e === 'string');
};
