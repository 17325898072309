import type { FC } from 'react';
import { useMemo } from 'react';

import type { Payer } from 'api/payers/types';
import AvailablePayerLabel from 'components/UI/molecules/ServicePayersBar/_components/AvailablePayerLabel/AvailablePayerLabel';

import { usePayerAltLabel } from '../../_hooks/usePayerAltLabel/usePayerAltLabel';

export interface Props {
  payerId: number;
  payersData: Payer[] | undefined;
}

const PayerCell: FC<Props> = ({ payerId, payersData }) => {
  const currentPayer = useMemo(() => payersData?.find(payerData => payerData.id === payerId), [payersData]);

  const alt = usePayerAltLabel({ currentPayer, payerId });

  return <AvailablePayerLabel alt={alt} logoSrc={currentPayer?.payerImg?.logoLeft} />;
};

export default PayerCell;
