const menuMessages = {
  patientCardCaption: ['menu>>patientCardCaption', 'Pacjent'],
  loggedUser: ['menu>>loggedUser', 'Zalogowany'],
  logout: [ 'menu>>elements>>logout', 'Wyloguj' ],
  change_role: [ 'menu>>elements>>change_role', 'Zmień rolę' ],
  elements: {
    start: [ 'menu>>elements>>start', 'Dashboard' ],
    policies: [ 'menu>>elements>>policies', 'Polisy' ],
    my_profile: [ 'menu>>elements>>my_profile', 'Profil pacjenta' ],
    reports: [ 'menu>>elements>>reports', 'Raporty WL' ],
    settings: [ 'menu>>elements>>settings', 'Ustawienia' ],
    help: [ 'menu>>elements>>help', 'Pomoc' ],
    services: [ 'menu>>elements>>services', 'Usługi' ]
  },
  pro_elements: {
    eCareOrder: ['menu>pro_elements>ECareTechnicalIssues', 'Zlecenia teleopieki'],
    eCareSos: ['menu>pro_elements>eCareSos', 'Alarmy SOS'],
    patients: ['menu>pro_elements>patients', 'Pacjenci'],
    technicalIssues: ['menu>pro_elements>technicalIssues', 'Problemy techniczne'],
    eCarePhoneCalls: ['menu>pro_elements>eCarePhoneCalls', 'Połączenia teleopieki'],
  }
}

export default menuMessages
