import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  moreButton: {
    borderRadius: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    background: '#fff',
    padding: theme.spacing(1, 1.5),
    '& > div': {
      display: 'flex',
      margin: theme.spacing(0.5, 0),
    },
    '&:focus > p': {
      color: '#fff',
    },
  },
}));

export default useStyles;
