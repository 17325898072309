import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import eCareCusComments from 'api/eCareCusComments/eCareCusComments';
import { ProfessionalECareCusCommentsEntity } from 'api/eCareCusComments/eCareCusComments.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import CusCommentsDialog from 'components/UI/organisms/_dialogs/CusCommentsDialog/CusCommentsDialog';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { Receivers } from 'constants/receiverDictionary/receiverDictionary';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import CusCommentList from './_components/CusCommentList/CusCommentList';
import useStyles from './ECareCusComments.styles';

type Props = {
  recipientId: string;
};

const ECareCusComments: FC<Props> = ({ recipientId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { orderId } = useParams();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const [isCusCommentsDialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_CUS_COMMENTS, orderId], [orderId]);
  const { data: commentData } = useQuery(queryKey, eCareCusComments.getAllComments(orderId as string), {
    refetchOnWindowFocus: false,
  });

  const showCommentHistory = () => {
    showConfirmationDialog({
      title: t(proECareOrderMessages.cusComments.title),
      body:
        commentData?.data &&
        commentData.data.map(note => (
          <BoxWithHeader
            className={classes.boxList}
            description={note.content as string}
            header={[
              {
                label: t(proECareOrderMessages.cusComments.updateDate),
                value: note?.updatedAt ? unknownDateToString(note.updatedAt as Date, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noData),
              },
              {
                label: t(proECareOrderMessages.cusComments.lastSync),
                value: note?.lastSync
                  ? unknownDateToString(note.lastSync as Date, DATE_FORMATS.DISPLAY_TIME)
                  : t(generalMessages.noLastSyncNote),
              },
              {
                label: t(proECareOrderMessages.cusComments.author),
                value: note.updatedBy ? note.updatedBy : note.createdBy,
              },
              {
                label: t(proECareOrderMessages.cusComments.receiverTitle),
                value: (note.receivers as Receivers[]).map(receiver => receiver).join(', '),
              },
            ]}
            key={note.id as string}
          />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  return (
    <>
      <SectionWithTitle
        className={classes.section}
        title={t(proECareOrderMessages.cusComments.title)}
        titleIcon={
          <Button variant='contained' onClick={openDialog}>
            {t(proECareOrderMessages.cusComments.addComment)}
          </Button>
        }
        titleRightSideComponent={
          commentData &&
          commentData.data.length > 0 && (
            <Button variant='outlined' onClick={showCommentHistory}>
              {t(proECareOrderMessages.cusComments.moreComment)}
            </Button>
          )
        }
      >
        {commentData && <CusCommentList data={commentData.data as ProfessionalECareCusCommentsEntity[]} />}
      </SectionWithTitle>
      {orderId && (
        <CusCommentsDialog close={closeDialog} isNew isOpen={isCusCommentsDialogOpen} orderId={orderId} serviceRecipientId={recipientId} />
      )}
    </>
  );
};

export default ECareCusComments;
