import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreServiceTypes from 'api/coreServiceTypes/coreServiceTypes';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';

interface HookParams {
  paramOrigin: OrderServiceOrigins;
  selectedPayerIds: string[];
}

export const useServiceTypesQuery = ({ paramOrigin, selectedPayerIds }: HookParams) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();

  const { data: serviceTypesData, isLoading: serviceTypesLoading } = useQuery(
    [QUERY_KEYS.CORE_SERVICE_TYPES, createQueryKeyFromObject({ paramOrigin, selectedPayerIds })],
    coreServiceTypes.getServiceTypes({
      patientId: patient?.id,
      serviceOrigin: paramOrigin,
      payers: selectedPayerIds,
    }),
    {
      enabled: !!patient?.id,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const serviceTypes = useMemo(() => {
    if (!serviceTypesData?.length) return [];

    return serviceTypesData.map(item => {
      const name = t([item.name.key, item.name.defaultValue]) as string;
      return {
        value: `${item.id}`,
        label: name || item.name.defaultValue,
        meta: { id: `${item.id}` },
      };
    }) as DropdownOption[];
  }, [serviceTypesData, t]);

  return { serviceTypes, serviceTypesLoading };
};
