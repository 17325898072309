import { useMemo } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { ORDER_SERVICE_ORIGINS, type OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import PATHS from 'constants/router/PATHS';

export const DEFAULT_DASHBOARD_IS_ACTIVE_FOR_PATHS = [PATHS.ROOT, PATHS.SYMPTOM_CHECKER, PATHS.ORDER_SERVICE];
export const DEFAULT_SERVICES_IS_ACTIVE_FOR_PATHS = [PATHS.SERVICES, PATHS.SERVICES_SUBTYPE];

export const useResolvedActivePaths = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const paramOrigin = useMemo(() => searchParams.get('origin') as OrderServiceOrigins, [searchParams]);

  const shouldActivateServicesRoute = useMemo(() => {
    const isPathnameMatching = pathname === PATHS.ORDER_SERVICE;
    const isOriginParamMatching = paramOrigin === ORDER_SERVICE_ORIGINS.SERVICES;

    return isPathnameMatching && isOriginParamMatching;
  }, [pathname, paramOrigin]);

  const { dashboardIsActiveForPaths, servicesIsActiveForPaths } = useMemo(
    () => ({
      dashboardIsActiveForPaths: shouldActivateServicesRoute
        ? DEFAULT_DASHBOARD_IS_ACTIVE_FOR_PATHS.filter(path => path !== PATHS.ORDER_SERVICE)
        : DEFAULT_DASHBOARD_IS_ACTIVE_FOR_PATHS,
      servicesIsActiveForPaths: shouldActivateServicesRoute
        ? [...DEFAULT_SERVICES_IS_ACTIVE_FOR_PATHS, PATHS.ORDER_SERVICE]
        : DEFAULT_SERVICES_IS_ACTIVE_FOR_PATHS,
    }),
    [shouldActivateServicesRoute],
  );

  return {
    dashboardIsActiveForPaths,
    servicesIsActiveForPaths,
  };
};
