import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateRows: 'auto 1fr',
  },

  title: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    fontSize: '1.1rem',
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
