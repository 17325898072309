import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  filesWrapper: {
    display: 'flex',
    width: '100%',
    gap: '20px',

    '> div': {
      flex: 1,
    },
  },
}));

export default useStyles;
