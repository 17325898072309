import { makeStyles } from 'tss-react/mui';

const ALERT_SECTION = 'alertSection';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'block',
    padding: theme.spacing(2),
  },
  value: {
    marginTop: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  standardColumnWrapper: {
    display: 'grid',
    alignContent: 'flex-start',
  },
  container3Columns: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(3, 4),
    alignContent: 'flex-start',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  container: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(3, 2),
    alignContent: 'flex-start',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  section: {
    gap: 0,
  },
  alertSection: {
    gridArea: ALERT_SECTION,
    gridGap: 10,
  },

  patientButton: {
    justifySelf: 'flex-start',
  },
  ddlWrapper: {
    display: 'flex',
    gap: 20,
  },
  notesWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: 20,

    '> section': {
      flex: 1,
    },
  },
  cardContainer: {
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: '0 !important',
  },
}));

export default useStyles;
