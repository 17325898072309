import axios from 'axios';

import { PaginatedApiResponse } from 'api/_types';
import { PartialRecord } from 'constants/_types/PartialRecord';
import { TableDataBaseParamsForQuery } from 'constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import { ECareTechnicalIssuesListFilters } from '../eCareTechnicalIssues/eCareTechnicalIssues.types';
import { parseECareDevicesListForFE } from './eCareDevicesType.parsers';
import { ECareDeviceType, ECareDeviceTypeDTO, ECareDeviceTypeFilters } from './eCareDevicesType.types';

const endpoint = '/api/v1/ecare-device-type/';

type GetECareAllDevicesQuery = (
  params: TableDataQueryFunctionParams<keyof ECareDeviceType>,
) => Promise<TableDataQueryResult<keyof ECareDeviceType>>;
const eCareDevicesType = {
  getAllDevices: (): GetECareAllDevicesQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery & PartialRecord<ECareDeviceTypeFilters, string> = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      ...parseFiltersForBE<ECareTechnicalIssuesListFilters>(params.filters),
    };

    const { data } = await axios.get<GetECareAllDevicesQuery, PaginatedApiResponse<ECareDeviceTypeDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: paramsForBE,
        removeEmptyParams: true,
      }),
    );
    return { data: parseECareDevicesListForFE(data.results), count: data.count };
  },
};
export default eCareDevicesType;
