const dashboardMessages = {
  sickCard: {
    header: ['dashboard>>sickCard>>header', 'Zachorowałem'],
    virtual_doctor: {
      header: ['dashboard>>sickCard>>virtual_doctor>>header', 'Wirtualny lekarz'],
      description: ['dashboard>>sickCard>>virtual_doctor>>description', 'Sprawdź swoje objawy, otrzymaj pomoc, podejmij dalsze krok'],
    },
    stationary_visit: {
      header: ['dashboard>>sickCard>>stationary_visit>>header', 'Wizyty stacjonarne'],
      description: [
        'dashboard>>sickCard>>stationary_visit>>description',
        'Do lekarza pierwszego kontaktu lub specjalisty\nBadania laboratoryjne i obrazowe\nInne badania diagnostyczne',
      ],
    },
    teleconsultation: {
      header: ['dashboard>>sickCard>>teleconsultation>>header', 'Telekonsultacje'],
      description: ['dashboard>>sickCard>>teleconsultation>>description', 'Jak najszybciej lub na określoną godzinę'],
    },
    examinations: {
      header: ['dashboard>>sickCard>>examinations>>header', 'Badania'],
    },
    otherServices: {
      header: ['dashboard>>otherServices>>header', 'Inne usługi'],
    },
    homeVisits: {
      header: ['dashboard>>homeVisits>>header', 'Wizyty domowe'],
    },
  },
  treatmentCard: {
    header: ['dashboard>>treatmentCard>>header', 'Leczę się'],
    prescription: {
      header: ['dashboard>>treatmentCard>>prescription>>header', 'Zamów receptę'],
      description: [
        'dashboard>>treatmentCard>>prescription>>description',
        'W ramach kontynuacji leczenia na podstawie dokumentacji medycznej',
      ],
    },
    referral: {
      header: ['dashboard>>treatmentCard>>referral>>header', 'Zamów skierowanie'],
      description: ['dashboard>>treatmentCard>>referral>>description', ''],
    },
    telemedicalMonitoring: {
      header: ['dashboard>>treatmentCard>>telemedicalMonitoring>>header', 'Monitorowanie telemedyczne'],
      description: ['dashboard>>treatmentCard>>telemedicalMonitoring>>description', ''],
    },
  },
  reportsCard: {
    header: ['dashboard>>reportsCard>>header', 'Raporty wirtualnego lekarza'],
    deleteConfirmation: {
      title: ['dashboard>>reportsCard>>deleteConfirmationTitle', 'Ukryj raport'],
      body: ['dashboard>>reportsCard>>deleteConfirmationBody', 'Czy jesteś pewien, że chcesz ukryć raport z tablicy?'],
    },
    actions: {
      hide: ['dashboard>>reportsCard>>actions>>hide', 'Usuń z tablicy'],
      show: ['dashboard>>reportsCard>>actions>>show', 'Pokaż na tablicy'],
      download: ['dashboard>>reportsCard>>actions>>download', 'Pobierz'],
      private: ['dashboard>>reportsCard>>actions>>private', 'Zmień na prywatny'],
      public: ['dashboard>>reportsCard>>actions>>public', 'Zmień na publiczny'],
    },
  },
  alerts: {
    fillYourData: {
      content: [
        'dashboard>>alerts>>fillYourData>>content',
        'Uzupełnij swoje dane profilowe ({{missingFields}}) niezbędne do sprawdzenia aktywnej polisy.',
      ],
      description: [
        'dashboard>>alerts>>fillYourData>>description',
        'Uzupełnij swoje dane profilowe niezbędne do sprawdzenia aktywnej polisy.',
      ],
      actionButton: ['dashboard>>alerts>>fillYourData>>actionButton', 'Uzupełnij dane'],
    },
    missingPolicy: {
      content: ['dashboard>>alerts>>missingPolicy>>content', 'Brak aktywnej polisy.'],
      description: ['dashboard>>alerts>>missingPolicy>>description', 'Upewnij się czy twoja polisa jest aktywna i sprawdź ponownie.'],
    },
  },
  suggestedAction: ['dashboard>>suggestedAction', 'Sugerowane działania:'],
};

export default dashboardMessages;
