import { hexToRgb, Theme } from '@mui/material';

export const SCROLLBAR_STYLING = (theme: Theme) => ({
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: hexToRgb(`${theme.palette.secondary.light}77`),
    cursor: 'pointer',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: hexToRgb(`${theme.palette.secondary.light}ff`),
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[200],
  },
});
