import { FC, useMemo } from 'react';

import { Card, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, Link, useParams } from 'react-router-dom';

import eCarePhoneCall from 'api/eCarePhoneCall/eCarePhoneCall';
import { ECareSyncCusEndpoint } from 'api/eCareSyncWithCus/eCareSyncWithCus';
import { ReactComponent as PhoneCallIcon } from 'assets/icons/hub/active/phone_call.svg';
import ECareSyncWithCusButton from 'components/UI/molecules/ECareSyncWithCusButton/ECareSyncWithCusButton';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import ProfessionalEcarePhoneCallInfo from 'components/UI/organisms/ProfessionalEcarePhoneCallInfo/ProfessionalEcarePhoneCallInfo';
import ProfessionalEcarePhoneCallNotes from 'components/UI/organisms/ProfessionalEcarePhoneCallNotes/ProfessionalEcarePhoneCallNotes';
import ProfessionalEcarePhoneCallSummary from 'components/UI/organisms/ProfessionalEcarePhoneCallSummary/ProfessionalEcarePhoneCallSummary';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import generalMessages from 'translations/common/general.mjs';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

import useStyles from './ProfessionalEcarePhoneCallSinglePage.styles';

const ProfessionalEcarePhoneCallSinglePage: FC = () => {
  usePageConfig();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { phoneCallId } = useParams();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_PHONE_CALL, phoneCallId], [phoneCallId]);
  const { data: phoneCallData } = useQuery(queryKey, eCarePhoneCall.getEcarePhoneCall(phoneCallId as string), {
    enabled: !!phoneCallId,
    refetchOnWindowFocus: false,
  });

  const subLabel = useMemo(() => {
    if (!phoneCallData) return <Skeleton width={120} />;

    const age = phoneCallData?.patient ? getAgeFromDOB(phoneCallData.patient.personalData.dateOfBirth) : '';
    return phoneCallData?.patient ? (
      <>
        <Link
          className={classes.titleLink}
          to={`/${generatePath(PATHS.PROFESSIONAL_PATIENT_SINGLE, { patientId: phoneCallData.patient.id.toString() })}`}
        >
          {phoneCallData.patient.personalData.firstName} {phoneCallData.patient.personalData.lastName}
        </Link>
        , {age} {t(generalMessages.person.age)}
      </>
    ) : (
      t(generalMessages.noData)
    );
  }, [phoneCallData]);

  const pageTitle = {
    headerLabel: t(ecarePhoneCallMessages.title),
    subLabel,
    extraContentComponent: <span />,
    icon: <PhoneCallIcon />,
  };

  const endpointsToSync: ECareSyncCusEndpoint[] = ['phoneCall'];

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitleWithFiltersForTables pageTitle={pageTitle} />
      <Card>
        {phoneCallData && phoneCallId && (
          <>
            <CardContentContainer>
              <ProfessionalEcarePhoneCallInfo />
              <ProfessionalEcarePhoneCallSummary summary={phoneCallData.callSummary} />
            </CardContentContainer>
            {/*---*/}
            <CardContentContainer isLastSection>
              <ProfessionalEcarePhoneCallNotes data={phoneCallData} />
              <ECareSyncWithCusButton
                endpoints={endpointsToSync}
                id={phoneCallId}
                message={
                  phoneCallData.lastSync
                    ? `${t(ecarePhoneCallMessages.syncWithCusInfo)} ${unknownDateToString(
                        phoneCallData.lastSync,
                        DATE_FORMATS.DISPLAY_TIME,
                      )}`
                    : t(ecarePhoneCallMessages.noLastSyncData)
                }
              />
            </CardContentContainer>
          </>
        )}
      </Card>
    </MainContentWrapper>
  );
};

export default ProfessionalEcarePhoneCallSinglePage;
