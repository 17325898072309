import { ACCEPTED_MAX_FILE_SIZE } from 'constants/_files/acceptedMaxFileSize';
import sizeUnitsCalc from 'helpers/sizeUnitsCalc/sizeUnitsCalc';

import type { CheckFileSizeCoreParams } from '../_types/params';

type Parameters = CheckFileSizeCoreParams & {
  file: File;
};

export const checkFileSize = ({ file, acceptedMaxSize = ACCEPTED_MAX_FILE_SIZE, unit = 'MB' }: Parameters) => {
  const maxFileSize = sizeUnitsCalc(acceptedMaxSize, unit);
  if (file.size <= maxFileSize.B) return true;

  return false;
};
