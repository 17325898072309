import type { TFunction } from 'i18next';

import validatePhoneNumber from './_services/validatePhoneNumber/validatePhoneNumber';

interface Params {
  currentValue: string;
  t: TFunction;
}

export const resolvePhoneNumberValidation = ({ currentValue, t }: Params) => {
  const validationResult = validatePhoneNumber(currentValue);

  return Array.isArray(validationResult) ? t(validationResult) : true;
};
