import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  button: {
    minWidth: 'unset',
    padding: theme.spacing(1.5),
  },
}));

export default useStyles;
