const globalSearchMessages = {
  searchInput: {
    initialPhrase: ['globalSearch>>searchInput>>initialPhrase', 'Wpisz nazwę usługi, aby rozpocząć wyszukiwanie'],
    tooShortPhrase: ['globalSearch>>searchInput>>tooShortPhrase', 'Wprowadź minimum {{ length }} znaki, aby rozpocząć wyszukiwanie'],
    noSearchResults: ['globalSearch>>searchInput>>noSearchResults', 'Brak wyników wyszukiwania'],
    placeholder: ['globalSearch>>searchInput>>placeholder', 'Wyszukaj wizyty lub badania...'],
  },
  searchResults: {
    clarificationText: ['globalSearch>>searchResults>>clarificationText', 'Doprecyzuj, aby zobaczyć to, czego szukasz'],
  },
  payers: {
    placeholder: ['globalSearch>>payers>>placeholder', 'Wybierz typ płatności'],
  },
  globalSearchDisabled: ['globalSearch>>globalSearchDisabled', 'Uzupełnij dane profilowe, aby korzystać z wyszukiwarki'],
};

export default globalSearchMessages;
