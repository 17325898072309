import { useCallback, useState } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { useDefaultPayerId } from 'components/UI/molecules/DetailsKindSection/_components/DetailsItem/_hooks/useDefaultPayerId/useDefaultPayerId';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  payersByService: DropdownOption[];
  payersFormInputKey: string;
}

export const useCurrentPayer = ({ form, payersByService, payersFormInputKey }: HookParams) => {
  const [currentPayerId, setCurrentPayerId] = useState('');

  useDefaultPayerId({ form, payersByService, payersFormInputKey, setCurrentPayerId });

  const interceptCurrentPayer = useCallback((payerId: string) => {
    if (!payerId) return;
    setCurrentPayerId(`${payerId}`);
  }, []);

  return { currentPayerId, interceptCurrentPayer };
};
