import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  menuItem: {
    display: 'grid',
    gridTemplateColumns: `16px ${theme.spacing(1)} 1fr`,
  },
  menuItemText: {
    gridColumnStart: 3,
    color: theme.palette.grey[600],
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
  },
}));

export default useStyles;
