import { useCallback, useState, type MouseEvent } from 'react';

import { useTranslation } from 'react-i18next';
import type { QueryKey } from 'react-query';

import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import { TempButtonsReportState } from 'components/UI/molecules/ReportsServiceButtons/ReportsServiceButtons';
import { downloadFile } from 'helpers/downloadFile/downloadFile';
import { downloadFileFromUrl } from 'helpers/downloadFileFromUrl/downloadFileFromUrl';
import useGetReportBlob from 'hooks/useGetReportBlob/useGetReportBlob';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import snackbarMessages from 'translations/common/snackbar.mjs';
import dashboardMessages from 'translations/specific/dashboard.mjs';

import { useReportRowMutation } from './_hooks/useReportRowMutation/useReportRowMutation';

type ButtonState = Extract<TempButtonsReportState, { isHidden: boolean; isMedicalRecord: boolean }>;

type UseReportRowActions = (args: {
  id: number;
  createdAtTime: string;
  queryKey: QueryKey;
  isHiddenProp: boolean;
  isMedicalRecordProp: boolean;
  handleMenuClose?: (e?: MouseEvent<HTMLElement>) => void;
  reportFileId: number;
}) => {
  buttonsState: ButtonState;
  onMedicalRecordToggle: (e: MouseEvent<HTMLElement>) => Promise<void>;
  onDownload: (e: MouseEvent<HTMLElement>) => Promise<void>;
  onHide: (e: MouseEvent<HTMLElement>) => Promise<void>;
};

export const useReportRowActions: UseReportRowActions = ({
  id,
  createdAtTime,
  queryKey,
  isHiddenProp,
  isMedicalRecordProp,
  reportFileId,
  handleMenuClose,
}) => {
  const { t } = useTranslation();

  const { patient } = usePatientContext();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { getReportBlob } = useGetReportBlob();
  const { showSnackbar } = useShowSnackbar();

  const [buttonsState, setButtonsState] = useState<ButtonState>({
    isHidden: isHiddenProp,
    isMedicalRecord: isMedicalRecordProp,
  });

  const { mutate, isLoading } = useReportRowMutation({
    id,
    queryKey,
    handleMenuClose,
    setButtonsState,
  });

  const onMedicalRecordToggle = useCallback(
    async e => {
      e.stopPropagation();
      e.preventDefault();
      if (isLoading) return;
      mutate({
        is_hidden: buttonsState.isHidden,
        is_medical_record: !buttonsState.isMedicalRecord,
        patient: patient.id,
      });
    },
    [mutate, isLoading, buttonsState, patient],
  );

  const onDownload = useCallback(
    async e => {
      e.stopPropagation();
      e.preventDefault();
      if (isLoading) return;
      const fileName = `${createdAtTime}-raport.pdf`;
      if (reportFileId) {
        await downloadFileFromUrl({ attachmentId: reportFileId, patientId: patient.id, fileName });
      } else {
        try {
          const { blob } = await getReportBlob(id);
          downloadFile(blob, fileName);
        } catch (error) {
          showSnackbar({ variant: 'error', translationArray: snackbarMessages.failure });
        }
      }
      handleMenuClose?.();
    },
    [createdAtTime, isLoading, reportFileId, patient.id],
  );

  const onHide = useCallback(
    async e => {
      e.stopPropagation();
      e.preventDefault();
      if (isLoading) return;
      const confirm = buttonsState.isHidden
        ? true
        : await showConfirmationDialog({
            title: t(dashboardMessages.reportsCard.deleteConfirmation.title),
            body: t(dashboardMessages.reportsCard.deleteConfirmation.body),
          });
      if (confirm) {
        mutate({
          is_hidden: !buttonsState.isHidden,
          is_medical_record: buttonsState.isMedicalRecord,
          patient: patient.id,
        });
      }
    },
    [mutate, isLoading, buttonsState, patient],
  );

  return {
    buttonsState,
    onMedicalRecordToggle,
    onDownload,
    onHide,
  };
};
