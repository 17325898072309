import { useEffect } from 'react';

import type { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';

import type { CoreService } from 'api/coreService/coreService.types';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface HookParams {
  services: DropdownOption[];
  serviceValue: string;
  preselectedServiceId: string | undefined;
  refetchServices: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined,
  ) => Promise<QueryObserverResult<CoreService[], unknown>>;
}

export const useRefetchingServicesForPreselection = ({ services, serviceValue, preselectedServiceId, refetchServices }: HookParams) => {
  useEffect(() => {
    const preselectedServiceValue = services.find(({ value }) => value === preselectedServiceId)?.label;

    const isServiceValueValidated = !!serviceValue;
    const isPreselectedServiceValueValidated = !!preselectedServiceValue;
    if (!isServiceValueValidated || !isPreselectedServiceValueValidated) return;

    const areValuesEqual = serviceValue === preselectedServiceValue;
    if (areValuesEqual) return;

    refetchServices();
  }, [serviceValue, preselectedServiceId]);
};
