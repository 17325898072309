import { FC, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { CreateOrUpdateNoteMutation, getPatientKeyGenerator } from 'api/patient/patients';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

export type Props = {
  header: string;
  close: () => void;
  isOpen: boolean;
  initialValue?: string;
  mutationFn: () => CreateOrUpdateNoteMutation;
  patientId: number;
  noteId?: number;
};

type FormInput = {
  content: string;
};

const FORM_ID = 'PatientHealthDetailsNoteEditDialog';

const PatientHealthDetailsNoteEditDialog: FC<Props> = ({ close, isOpen, header, mutationFn, initialValue, patientId, noteId }) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useShowSnackbar();
  const { t } = useTranslation();

  const { control, handleSubmit, formState, setValue } = useForm<FormInput>({
    defaultValues: {
      content: initialValue || '',
    },
  });

  useEffect(() => {
    if (initialValue) setValue('content', initialValue);
  }, [initialValue]);

  const mutation = useMutation(mutationFn());

  const onSubmit: SubmitHandler<FormInput> = async ({ content }) => {
    await mutation.mutateAsync(
      { patientId, content, noteId },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(getPatientKeyGenerator(patientId, true));
        },
      },
    );

    showSnackbar({ variant: 'success', translationArray: snackbarMessages.savedSuccessfully });
    close();
  };

  return (
    <DialogWrapper
      close={close}
      defaultActionsConfig={{
        cancelLabel: t(generalMessages.cancel),
        okLabel: t(generalMessages.save),
        isLoading: mutation.isLoading,
        formId: FORM_ID,
        onClose: close,
      }}
      header={`${t(proPatientMessages.editNote)} - ${header}`}
      isOpen={isOpen}
    >
      <DialogFormWrapper formState={formState} id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <FormInputText
          control={control}
          inputProps={{ multiline: true }}
          label={t(proPatientMessages.noteInputLabel)}
          multiline
          name='content'
          required
          rows={6}
        />
      </DialogFormWrapper>
    </DialogWrapper>
  );
};

export default PatientHealthDetailsNoteEditDialog;
