import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  bottomActionBar: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    justifyContent: 'flex-end',
  },

  titleLink: {
    color: theme.palette.secondary.main,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  button: {
    fontSize: '0.9rem',
    height: '3.55rem',
    flexShrink: 0,
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
