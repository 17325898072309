import { useCallback } from 'react';

import { useQueryClient } from 'react-query';

import QUERY_KEYS from 'constants/queryKeys/queryKeys';

export const useRefreshUserInfo = () => {
  const queryClient = useQueryClient();

  const refreshUserInfo = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries(QUERY_KEYS.USER_DATA_REFRESH),
      queryClient.invalidateQueries(QUERY_KEYS.PATIENTS_REFRESH),
    ]);
  }, [queryClient.invalidateQueries]);

  return { refreshUserInfo };
};
