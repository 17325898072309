import { useEffect, useMemo } from 'react';

import BreadcrumbHomeIcon from 'components/UI/atoms/BreadcrumbHomeIcon/BreadcrumbHomeIcon';
import BreadcrumbItem from 'components/UI/atoms/BreadcrumbItem/BreadcrumbItem';
import { usePageAlertMessages } from 'hooks/usePageAlertMessages/usePageAlertMessages';
import useCurrentPathConfigStorage from 'storages/currentPathConfigStorage';

const usePageConfig = () => {
  const { getConfig, baseConfig, overrideConfig } = useCurrentPathConfigStorage();
  const config = useMemo(getConfig, [baseConfig, overrideConfig]);
  const { alertMessages } = usePageAlertMessages();

  const breadcrumbs = useMemo(() => {
    if (!config?.breadcrumbs) return null;
    return [
      <BreadcrumbHomeIcon key={0} />,
      ...(config?.breadcrumbs ?? []).map(({ isCurrent, path, translationArray, translationParams }, index) => (
        <BreadcrumbItem
          isCurrent={isCurrent}
          // eslint-disable-next-line
          key={index + 1}
          path={path}
          translationArray={translationArray}
          translationParams={translationParams}
        />
      )),
    ];
  }, [config?.breadcrumbs]);

  useEffect(() => {
    if (!config?.scrollRestoration) {
      window.scrollTo(0, 0);
    }
  }, [config]);

  return { breadcrumbs, alertMessages: config?.showAlerts ? alertMessages : undefined, formMaxStep: config?.formMaxStep };
};

export default usePageConfig;
