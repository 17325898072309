import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ hasInternalBranding: boolean }>()((theme, { hasInternalBranding }) => ({
  root: {
    paddingRight: theme.spacing(5),
    marginBottom: 0,
    alignItems: 'flex-start',
    flexGrow: 0,
    width: hasInternalBranding ? '50%' : '75%',
    flexBasis: hasInternalBranding ? '50%' : '75%',
    '& > div': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      marginBottom: theme.spacing(3),
      width: '100%',
      flexBasis: '100%',
    },
  },
  header: {
    marginBottom: theme.spacing(5),
  },
  inputWrapper: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
  button: {
    width: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
