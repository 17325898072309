import type { FC } from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import DialogWrapper, { ActionsConfig } from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import ClausesForm from 'components/UI/organisms/ClausesForm/ClausesForm';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import { CLAUSE_LOCATIONS } from 'constants/_types/ClauseLocations';
import { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { getClauseLocationsValues } from 'helpers/getClauseLocationsValues/getClauseLocationsValues';
import useClausesConsentsQuery from 'hooks/_clauses/useClausesConsentsQuery/useClausesConsentsQuery';
import useConsentUpdate from 'hooks/_clauses/useConsentUpdate/useConsentUpdate';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import groupClausesWithConsents from 'services/_clauses/groupClausesWithConsents/groupClausesWithConsents';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import clausesMessages from 'translations/specific/clauses.mjs';

import useStyles from './ClausesDialog.styles';

type ClausesDialogVersion = 'manual' | 'forcedRequired' | 'forcedOptional';

export const CLAUSES_DIALOG_VERSIONS: { [key in ClausesDialogVersion]: ClausesDialogVersion } = {
  manual: 'manual',
  forcedRequired: 'forcedRequired',
  forcedOptional: 'forcedOptional',
};

type Props = {
  flushGlobalDialogQueue?: () => void;
  close: () => void;
  isOpen: boolean;
  dialogVersion: ClausesDialogVersion;
};

type ClausesFormInput = {
  [key: string]: boolean;
};

const FORM_ID = 'ClausesDialog_form';

const ClausesDialog: FC<Props> = ({ isOpen, flushGlobalDialogQueue, close, dialogVersion }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useShowSnackbar();
  const { userInfo, refreshUserInfo, logout } = useAuthContext();
  const queryClient = useQueryClient();

  const virtualLocationToGetClauses = useMemo(
    () => (dialogVersion === CLAUSES_DIALOG_VERSIONS.manual ? [] : getClauseLocationsValues()),
    [dialogVersion],
  );

  const virtualLocationToSubmitClauses = useMemo(
    () => (dialogVersion === CLAUSES_DIALOG_VERSIONS.manual ? CLAUSE_LOCATIONS.userSettings : CLAUSE_LOCATIONS.forcedDialog),
    [],
  );

  const { control, handleSubmit, setValue, reset, formState, getValues } = useForm<ClausesFormInput>({ defaultValues: {} });
  const initialFormStateRef = useRef<ClausesFormInput>();

  const { updateClausesConsents, mutationStatuses } = useConsentUpdate([]);
  const { clausesWithConsents, queryStatuses } = useClausesConsentsQuery([...virtualLocationToGetClauses], { onlySingle: true });

  const { requiredClauses, optionalClauses } = useMemo(
    () =>
      groupClausesWithConsents(clausesWithConsents, {
        requiredClauses: ({ isObligatory }) => isObligatory,
        optionalClauses: ({ isObligatory }) => !isObligatory,
      }),
    [clausesWithConsents],
  );

  const clausesToRender = useMemo(() => {
    const missing = userInfo.hasObligatoryConsents?.missing;
    let _clausesToRender: ClauseWithConsent[];
    switch (dialogVersion) {
      case CLAUSES_DIALOG_VERSIONS.manual: {
        _clausesToRender = optionalClauses;
        break;
      }
      case CLAUSES_DIALOG_VERSIONS.forcedRequired: {
        _clausesToRender = requiredClauses.filter(({ clauseId }) => missing?.includes(clauseId));
        break;
      }
      case CLAUSES_DIALOG_VERSIONS.forcedOptional: {
        _clausesToRender = optionalClauses.filter(({ hasUserConsent }) => hasUserConsent === null);
        break;
      }
      default:
        _clausesToRender = [];
    }

    if (!formState.isDirty) {
      _clausesToRender.forEach(({ clauseId, hasUserConsent }) => setValue(`${clauseId}`, !!hasUserConsent));
      initialFormStateRef.current = getValues();
    }
    return _clausesToRender;
  }, [requiredClauses, optionalClauses, dialogVersion, queryStatuses.all.isLoading]);

  const onSubmit: SubmitHandler<ClausesFormInput> = async formData => {
    const responses = await updateClausesConsents(formData, {
      virtualLocation: virtualLocationToSubmitClauses,
      initialState: dialogVersion === CLAUSES_DIALOG_VERSIONS.manual ? initialFormStateRef.current : undefined,
    });

    if (responses.every(({ status }) => status < 400)) {
      if (dialogVersion === CLAUSES_DIALOG_VERSIONS.forcedRequired) await refreshUserInfo();
      else {
        await Promise.all([queryClient.invalidateQueries(QUERY_KEYS.CONSENTS), queryClient.invalidateQueries(QUERY_KEYS.CLAUSES)]);
      }
      showSnackbar({ variant: 'success', translationArray: snackbarMessages.success });
      close();
      reset();
    } else {
      showSnackbar({ variant: 'error', translationArray: snackbarMessages.failure });
    }
  };

  const onLogout = useCallback(() => {
    if (flushGlobalDialogQueue) flushGlobalDialogQueue();
    logout();
  }, [flushGlobalDialogQueue, logout]);

  const actionsConfig = useMemo(() => {
    const commonCancelButtonProps = {
      variant: 'outlined' as const,
      disabled: queryStatuses.all.isLoading || mutationStatuses.all.isLoading,
    };
    const commonOkButtonProps = {
      label: t(generalMessages.accept),
      type: 'submit',
      variant: 'contained',
      isLoading: mutationStatuses.all.isLoading,
      isLoadingType: true,
      form: FORM_ID,
    };
    switch (dialogVersion) {
      case CLAUSES_DIALOG_VERSIONS.forcedOptional: {
        return [commonOkButtonProps];
      }
      case CLAUSES_DIALOG_VERSIONS.forcedRequired: {
        return [
          { ...commonCancelButtonProps, label: t(clausesMessages.notAcceptAndLogout), onClick: onLogout },
          { ...commonOkButtonProps },
        ];
      }
      case CLAUSES_DIALOG_VERSIONS.manual:
      default: {
        return [
          { ...commonCancelButtonProps, label: t(generalMessages.cancel), onClick: close },
          { ...commonOkButtonProps, label: t(generalMessages.save) },
        ];
      }
    }
  }, [dialogVersion, queryStatuses, mutationStatuses]);

  const headerText = useMemo(() => {
    switch (dialogVersion) {
      case CLAUSES_DIALOG_VERSIONS.forcedOptional: {
        return t(clausesMessages.notRequiredClauseUpdate);
      }
      case CLAUSES_DIALOG_VERSIONS.forcedRequired: {
        return t(clausesMessages.requiredClauseUpdate);
      }
      case CLAUSES_DIALOG_VERSIONS.manual:
      default: {
        return t(clausesMessages.clausesChange);
      }
    }
  }, [dialogVersion]);

  return (
    <DialogWrapper
      actionsConfig={actionsConfig as ActionsConfig}
      close={close}
      dialogProps={{ maxWidth: 'sm' }}
      header={headerText}
      isOpen={isOpen}
      noMarginTop
      subheader={dialogVersion === CLAUSES_DIALOG_VERSIONS.manual ? `${t(clausesMessages.manageFollowingClauses)}:` : undefined}
    >
      <ElementWithLoader isLoading={queryStatuses.all.isLoading}>
        <div className={classes.clausesContainer}>
          <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
            <ClausesForm
              clausesToRender={clausesToRender}
              control={control}
              showAcceptDate={dialogVersion === CLAUSES_DIALOG_VERSIONS.manual}
            />
          </form>
        </div>
      </ElementWithLoader>
    </DialogWrapper>
  );
};

export default ClausesDialog;
