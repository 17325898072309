import type { FC } from 'react';

import PageTitleButton from '../PageTitleButton/PageTitleButton';

export interface Props {
  buttonLabel?: string;
  buttonDisabled?: boolean;
  dividerComponent: JSX.Element;
  extraContentComponent?: JSX.Element;
  redirectPath?: string;
}

const PageTitleContent: FC<Props> = ({ buttonDisabled, buttonLabel, dividerComponent, extraContentComponent, redirectPath }) => (
  <>
    {buttonLabel && redirectPath && (
      <PageTitleButton buttonDisabled={buttonDisabled} buttonLabel={buttonLabel} redirectPath={redirectPath} />
    )}
    {extraContentComponent && (
      <>
        {dividerComponent}
        {extraContentComponent}
      </>
    )}
  </>
);

export default PageTitleContent;
