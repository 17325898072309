import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isSearchResult: boolean }>()((theme, { isSearchResult }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(isSearchResult ? 1 : 3),
  },

  header: {
    position: isSearchResult ? 'unset' : 'sticky',
    top: 0,
    zIndex: 1,
    background: isSearchResult ? 'unset' : theme.palette.background.default,
    padding: isSearchResult ? theme.spacing(0, 6.25) : 0,
  },

  sectionItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(isSearchResult ? 0.3 : 2),
    padding: isSearchResult ? theme.spacing(0, 2.45) : 0,
  },
}));

export default useStyles;
