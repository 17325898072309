import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LinkWithQuery from 'components/UI/atoms/LinkWithQuery/LinkWithQuery';
import AuthTileFooter from 'components/UI/organisms/AuthTileFooter/AuthTileFooter';
import RegisterForm from 'components/UI/organisms/RegisterForm/RegisterForm';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import registerMessages from 'translations/specific/register.mjs';

const RegisterPage: FC = () => {
  usePageConfig();

  const { t } = useTranslation();
  return (
    <>
      <RegisterForm />
      <AuthTileFooter>
        <Typography component='span'>{t(registerMessages.footer.login_link_label)}</Typography>{' '}
        <LinkWithQuery to={`/${PATHS.AUTH_LOGIN}`}>{t(registerMessages.footer.login_link)}</LinkWithQuery>
      </AuthTileFooter>
    </>
  );
};

export default RegisterPage;
