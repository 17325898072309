const userSettingsMessages = {
  header: ['userSettings>>header', 'Ustawienia aplikacji'],
  cardTitle: ['userSettings>>cardTitle', 'Ustawienia konta'],
  twoFA: {
    title: ['userSettings>>twoFA>>title', '2FA'],
    labelOn: ['userSettings>>twoFA>>labelOn', 'Włącz 2FA'],
    labelOff: ['userSettings>>twoFA>>labelOff', 'Wyłącz 2FA'],
    forcedInfo: ['userSettings>>twoFA>>forcedInfo', 'Weryfikacja dwuetapowa jest wymagana'],
    modal: {
      header: ['userSettings>>twoFA>>modal>>header', 'Potwierdzenie'],
      content: ['userSettings>>twoFA>>modal>>content', 'Czy na pewno chcesz wyłączyć 2FA'],
    },
  },
  passwordChange: ['userSettings>>passwordChange', 'Zmień hasło'],
  chooseLanguage: ['userSettings>>chooseLanguage', 'Wybierz język aplikacji'],

};

export default userSettingsMessages;
