import joinPath from 'helpers/joinPath/joinPath';

const isUrl = (path: string): boolean => {
  try {
    const url = new URL(path);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    return false;
  }
};

const normalizePath = (path?: string | null): string => {
  if (!path) return '';
  return isUrl(path) ? path : joinPath(process.env.REACT_APP_API_BASE_URL as string, path);
};

export default normalizePath;
