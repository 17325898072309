import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import FormInputRadioGroup from 'components/UI/organisms/_formInputs/FormInputRadioGroup/FormInputRadioGroup';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { getDateSoonRadioOptions } from './_services/getDateSoonRadioOptions/getDateSoonRadioOptions';
import useStyles from './DateSoon.styles';

export interface Props {
  dateSoonName: string;
  validationRules: { validate: () => string | true };
}

const DateSoon: FC<Props> = ({ dateSoonName, validationRules }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { form } = useOrderServiceFormContext();

  const dateSoonRadioOptions = getDateSoonRadioOptions(t);

  return (
    <div className={classes.dateSoon}>
      <Typography variant='caption'>{`${t(orderServiceMessages.details.deadline)}:`}</Typography>
      <div className={classes.checkboxesContainer}>
        <FormInputRadioGroup control={form.control} name={dateSoonName} radioOptions={dateSoonRadioOptions} rules={validationRules} tiny />
      </div>
    </div>
  );
};

export default DateSoon;
