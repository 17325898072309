import { FC } from 'react';

import { useQuery } from 'react-query';
import { matchPath, Navigate, Outlet, useLocation } from 'react-router-dom';

import userPermissions from 'api/userPermissions/userPermissions';
import { UserPermissions } from 'api/userPermissions/userPermissions.types';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import { UserPermission } from 'constants/_types/UserPermission';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import useIsProfessionalUser from 'hooks/useIsProfessionalUser/useIsProfessionalUser';

// dictionary to store required permissions for each path
const pathProtectionDictionary: Record<string, UserPermission> = {
  [PATHS.PROFESSIONAL_PATIENTS]: 'view_patient',
  [PATHS.PROFESSIONAL_ECARE_ORDER_LIST]: 'view_ecareserviceorder',
  [PATHS.PROFESSIONAL_ECARE_ALARMS_LIST]: 'view_ecaresos',
  [PATHS.PROFESSIONAL_ECARE_PHONE_CALLS_LIST]: 'view_phonecall',
};

const hasAccessBasedOnPermission = (permissions: UserPermissions, path: string): boolean =>
  Object.entries(pathProtectionDictionary).every(([protectedPath, requiredPermission]) => {
    const match = !!matchPath(protectedPath, path);
    if (match) return permissions[requiredPermission];
    return true;
  });

const ProfessionalLayout: FC = () => {
  const location = useLocation();
  const isProfessionalUser = useIsProfessionalUser();
  const { data: userPermissionsData, isLoading: isUserPermissionsDataLoading } = useQuery(
    QUERY_KEYS.USER_PERMISSIONS,
    userPermissions.getUserPermissions(),
    {
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 10,
    },
  );

  if (isProfessionalUser === false) {
    return <Navigate replace state={{ from: location }} to={PATHS.ROOT} />;
  }

  if (isProfessionalUser === null || isUserPermissionsDataLoading) {
    return <LoaderOverlay />;
  }

  if (location.pathname !== '/' && !hasAccessBasedOnPermission(userPermissionsData as UserPermissions, location.pathname)) {
    return <Navigate replace state={{ from: location }} to='/' />;
  }

  return (
    <MainContentWrapper hideBreadcrumbs hideMessages noPadding>
      <Outlet />
    </MainContentWrapper>
  );
};

export default ProfessionalLayout;
