import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ standAlone: boolean }>()((theme, { standAlone }) => ({
  root: {
    height: standAlone ? '100%' : 'calc(100vh - 16px)',
    width: standAlone ? '100%' : 'calc(100vw - 16px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  card: {
    width: '100%',
    padding: theme.spacing(12.5, 4, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  iconsContainer: {
    position: 'relative',
    marginBottom: theme.spacing(10),
    zIndex: 2,
  },
  title: {
    width: '100%',
    fontSize: '10rem',
    lineHeight: '13.6875rem',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    position: 'absolute',
    top: '22px',
    left: '-15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '5rem',
      top: '-42px',
      left: '-8px',
    },
  },
  errorBackground: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
  },
  firstAid: {
    position: 'absolute',
    bottom: '-90px',
    right: '120px',
    [theme.breakpoints.down('md')]: {
      width: '175px',
      bottom: '-93px',
      right: '58px',
    },
  },
  header: {
    fontSize: '1.75rem',
    lineHeight: '2.375rem',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    position: 'relative',
    zIndex: 2,
  },
  subheader: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    position: 'relative',
    zIndex: 2,
  },
  buttonWrapper: {
    margin: theme.spacing(5, 0, 10),
  },
  button: {
    minWidth: '200px',
    position: 'relative',
    zIndex: 2,
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(17.5),
  },
  contactDescription: {
    color: theme.palette.grey[500],
    fontSize: '0.8125rem',
    lineHeight: '2rem',
  },
  contactPhoneNumber: {
    color: theme.palette.primary.main,
    fontSize: '1.125rem',
    lineHeight: '2rem',
  },
  dnaIcon: {
    position: 'absolute',
    zIndex: 0,
    top: '60px',
    left: 0,
    transform: 'translateX(-40%)',
    '& path': {
      stroke: theme.palette.grey[300],
    },
    [theme.breakpoints.down('sm')]: {
      top: '43px',
    },
  },
  disabledIcon: {
    position: 'absolute',
    zIndex: 0,
    bottom: '270px',
    right: 0,
    transform: 'translateX(15%)',
    '& path': {
      stroke: theme.palette.grey[300],
    },
  },
  heartIcon: {
    position: 'absolute',
    zIndex: 0,
    bottom: 0,
    left: '290px',
    transform: 'translateY(55px)',
    '& path': {
      stroke: theme.palette.grey[300],
    },
    [theme.breakpoints.down('lg')]: {
      left: '150px',
    },
    [theme.breakpoints.down('md')]: {
      left: '40px',
    },
  },
}));

export default useStyles;
