import { getPatientAttachment } from './getPatientAttachment/getPatientAttachment';

export const endpoints = {
  patientAttachment: 'api/v1/patient-attachment/get/',
};

const patientAttachment = {
  getPatientAttachment,
};

export default patientAttachment;
