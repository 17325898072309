interface Params {
  selectedServiceSubtypesIds: string[];
  patientId: number | undefined;
}

export const checkIsServicesQueryEnabled = ({ selectedServiceSubtypesIds, patientId }: Params) => {
  const hasSubtypeValue = selectedServiceSubtypesIds.length > 0;
  const isNotEmpty = selectedServiceSubtypesIds[0] !== '';
  const hasPatientId = !!patientId;

  return hasSubtypeValue && isNotEmpty && hasPatientId;
};
