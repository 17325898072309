import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  tooltip: {
    maxWidth: '480px',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '16px',
    width: '16px',
    borderRadius: '100%',
  },
  icon: {
    color: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    minWidth: '16px',
    minHeight: '16px',
  },
}));

export default useStyles;
