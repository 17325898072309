import axios from 'axios';

import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { DefaultApiResponse, PaginatedApiResponse } from '../_types';
import { parseECareContactNotesListForFE } from './eCareContactNotes.parsers';
import {
  CreateECareContactNotesInput,
  ECareContactNote,
  ECareContactNoteDTO,
  UpdateECareContactNotesInput,
} from './eCareContactNotes.types';

type GetECareAllContactNotesQuery = () => Promise<TableDataQueryResult<keyof ECareContactNote>>;
type CreateContactNoteQuery = (data: CreateECareContactNotesInput) => Promise<DefaultApiResponse<ECareContactNote>>;
type UpdateECareContactNotesQuery = (data: UpdateECareContactNotesInput) => Promise<DefaultApiResponse<ECareContactNote>>;

const endpoint = '/api/v1/ecare-contact-note/';

const eCareContactNotes = {
  getAllNotes:
    (orderId: string): GetECareAllContactNotesQuery =>
    async () => {
      const { data } = await axios.get<GetECareAllContactNotesQuery, PaginatedApiResponse<ECareContactNoteDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: { order: orderId || '' },
          removeEmptyParams: true,
        }),
      );
      return { data: parseECareContactNotesListForFE(data.results), count: data.count };
    },

  createNote: (): CreateContactNoteQuery => data =>
    axios.post<CreateContactNoteQuery, DefaultApiResponse<ECareContactNote>>(endpoint, data),

  patchNoteData:
    (id: string): UpdateECareContactNotesQuery =>
    data =>
      axios.patch<UpdateECareContactNotesQuery, DefaultApiResponse<ECareContactNote>>(`${endpoint}${id}/`, data),
};
export default eCareContactNotes;
