import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../coreServiceTypes';
import type { CoreServiceType, CoreServiceTypeDTO } from '../types';
import { parseServiceTypesForFE } from './_services/parseServiceTypesForFE/parseServiceTypesForFE';
import { prepareParamsForGetServiceTypes } from './_services/prepareParamsForGetServiceTypes/prepareParamsForGetServiceTypes';

export interface Params {
  patientId: number;
  serviceOrigin?: OrderServiceOrigins;
  payers: string[] | undefined;
}

type GetServiceTypesQuery = () => Promise<CoreServiceType[]>;

export const getServiceTypes =
  ({ patientId, serviceOrigin, payers }: Params) =>
  async () => {
    const { data } = await axios.get<GetServiceTypesQuery, PaginatedApiResponse<CoreServiceTypeDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: prepareParamsForGetServiceTypes({ patientId, serviceOrigin, payers }),
      }),
    );

    return parseServiceTypesForFE(data.results);
  };
