import parseServiceRecipient from 'api/_commonParsers/parseServiceRecipient';

import { parseECareSuspencionListForFE } from '../eCareSuspension/eCareSuspension.parsers';
import { parsePatientForFE } from '../patient/patient.parsers';
import { ECareOrder, ECareOrderDTO } from './eCareOrder.types';

export const parseSingleECareOrderForFE = (eCareOrderDTO: ECareOrderDTO): ECareOrder => ({
  cognitiveDisabilityDegree: eCareOrderDTO.cognitive_disability_degree,
  createdAt: new Date(eCareOrderDTO.created_at),
  dwellingType: eCareOrderDTO.dwelling_type,
  dysfunctions: eCareOrderDTO.dysfunctions,
  emergencyContactPhone: eCareOrderDTO.emergency_contact_phone,
  healthConditions: eCareOrderDTO.health_conditions,
  healthProblems: eCareOrderDTO.health_problems,
  id: eCareOrderDTO.id,
  lifestyle: eCareOrderDTO.lifestyle,
  mobilityDisabilityDegree: eCareOrderDTO.mobility_disability_degree,
  orderDate: new Date(eCareOrderDTO.order_date),
  otherDwellingType: eCareOrderDTO.other_dwelling_type,
  serviceEndDate: eCareOrderDTO.service_end_date ? new Date(eCareOrderDTO.service_end_date) : null,
  serviceRecipient: parseServiceRecipient(eCareOrderDTO.service_recipient),
  patient: eCareOrderDTO.patient ? parsePatientForFE(eCareOrderDTO.patient) : null,
  serviceStartDate: new Date(eCareOrderDTO.service_start_date),
  serviceTerminationReason: eCareOrderDTO.service_termination_reason,
  updatedAt: new Date(eCareOrderDTO.updated_at),
  status: eCareOrderDTO.status,
  suspensions: eCareOrderDTO?.suspensions ? parseECareSuspencionListForFE(eCareOrderDTO.suspensions) : null,
  entity: eCareOrderDTO.entity,
  trainingDate: eCareOrderDTO.training_date ? new Date(eCareOrderDTO.training_date) : null,
  trainingInfo: eCareOrderDTO.training_info,
  callFrequency: eCareOrderDTO.call_frequency,
  payers: eCareOrderDTO.payers,
  subscriptions: eCareOrderDTO.subscriptions,
  implementationStatus: eCareOrderDTO.implementation_status,
  lastSync: eCareOrderDTO.last_sync ? new Date(eCareOrderDTO.last_sync) : null,
  companyContractId: eCareOrderDTO.company_contract_id,
});

export const parseECareOrderListForFE = (eCareOrderDTO: ECareOrderDTO[]): ECareOrder[] => eCareOrderDTO.map(parseSingleECareOrderForFE);
