import type { FC } from 'react';
import { useCallback } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useStyles from './CloseButton.styles';

export interface Props {
  customAction?: () => void;
}

const CloseButton: FC<Props> = ({ customAction }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleClick = useCallback(() => {
    if (customAction) {
      customAction();
    } else {
      handleGoBack();
    }
  }, []);

  return (
    <IconButton className={classes.root} onClick={handleClick}>
      <CloseOutlinedIcon />
    </IconButton>
  );
};

export default CloseButton;
