import { useQuery } from 'react-query';

import coreServiceTypes from 'api/coreServiceTypes/coreServiceTypes';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

const useGetTypeDetails = (typeId: string) => {
  const { data, isLoading } = useQuery(
    QUERY_KEYS.CORE_SERVICE_TYPE_DETAILS,
    coreServiceTypes.getServiceTypeDetails({ serviceTypeId: typeId }),
  );

  return { data, isLoading };
};

export default useGetTypeDetails;
