import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { type DefaultApiResponse } from '../_types';
import { parseECareAlarmsListForFE } from './eCareDeviceEvents.parsers';
import { ECareDeviceEvents, ECareDeviceEventsDTO } from './eCareDeviceEvents.types';

type GetECareDeviceEventQuery = () => Promise<ECareDeviceEvents[]>;

const endpoint = '/api/v1/ecare-sos/events/';

const eCareDeviceEvents = {
  getAll: (): GetECareDeviceEventQuery => async () => {
    const { data } = await axios.get<GetECareDeviceEventQuery, DefaultApiResponse<ECareDeviceEventsDTO[]>>(
      createUrlWithParams({
        url: endpoint,
        removeEmptyParams: true,
      }),
    );

    return parseECareAlarmsListForFE(data);
  },
};
export default eCareDeviceEvents;
