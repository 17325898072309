import type { DropdownOption } from 'constants/_types/DropdownOptions';
import en from 'constants/dropdownOptions/countries/en';
import es from 'constants/dropdownOptions/countries/es';
import pl from 'constants/dropdownOptions/countries/pl';
import type { SupportedLanguages } from 'constants/translations/_types';
import { SUPPORTED_LANGUAGES } from 'constants/translations/_types';

const parseDictionary = (dictionary: { code: string; name: string }[]): DropdownOption[] =>
  dictionary.map(({ code, name }) => ({ value: name, label: name, metaData: { code, name } }));

const getCountryDropdown = (languageCode: SupportedLanguages) => {
  switch (languageCode) {
    case SUPPORTED_LANGUAGES.EN: {
      return parseDictionary(en);
    }
    case SUPPORTED_LANGUAGES.ES: {
      return parseDictionary(es);
    }
    case SUPPORTED_LANGUAGES.PL:
    default: {
      return parseDictionary(pl);
    }
  }
};

export default getCountryDropdown;
