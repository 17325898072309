import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  twoColumnsContainer: {
    marginTop: theme.spacing(2),
    display: 'grid',
    alignContent: 'flex-start',
    gridTemplateColumns: '1fr 1fr',
  },
  definitionList: {
    display: 'block',
  },
  icon: {
    width: '15px',
  },
}));

export default useStyles;
