import { useEffect } from 'react';

import type { FormState } from 'react-hook-form';

import scrollToError from 'services/scrollToError/scrollToError';

type UseFormScrollToError = (formState?: FormState<any>) => void;

const useFormScrollToError: UseFormScrollToError = formState => {
  useEffect(() => {
    if (!formState) return;
    const errorKeys = Object.keys(formState.errors);
    if (formState.isSubmitting && errorKeys.length) {
      scrollToError(errorKeys[0]);
    }
  }, [formState?.isSubmitting]);
};

export default useFormScrollToError;
