import { FC, useMemo } from 'react';

import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareOrder from 'api/eCareOrder/eCareOrder';
import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from '../ProfessionalEOrderFormDialog.styles';

type Props = {
  orderId: string;
  close: () => void;
};

const FORM_ID = 'trainingForm';

const TrainingForm: FC<Props> = ({ close, orderId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { control, handleSubmit, formState, getValues } = useForm<any>();

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ORDER, orderId], [orderId]);
  const orderData: ECareOrder | undefined = queryClient.getQueryData(queryKey);

  const statusMutation = useMutation(eCareOrder.patchECareOrderData(orderId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, { ...orderData, newStatus });
      close();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const onSubmit = () => {
    statusMutation.mutate({
      training_info: getValues().trainingInfo,
      training_date: format(new Date(getValues().trainingDate), DATE_FORMATS.API_DATE),
    });
  };

  return (
    <DialogFormWrapper formState={formState} id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.inputWrapper}>
        <FormInputText
          control={control}
          defaultValue={orderData?.trainingInfo || ''}
          label={t(proECareOrderMessages.devices.trainingInfo)}
          name='trainingInfo'
          required
        />
      </div>
      <div className={classes.inputWrapper}>
        <FormInputDate
          control={control}
          defaultValue={orderData?.trainingDate || null}
          label={t(proECareOrderMessages.devices.trainingDate)}
          name='trainingDate'
          required
        />
      </div>
    </DialogFormWrapper>
  );
};

export default TrainingForm;
