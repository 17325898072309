import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import useDeviceEventDictionary from 'hooks/useDeviceEventDictionary/useDeviceEventDictionary';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import InfoIconWithTooltip from '../../../../atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import UpdateInterventionDateForm from '../../_forms/InterventionDateForm/InterventionDateForm';

const useCusInfo = (data: ECareAlarm | undefined) => {
  const { t } = useTranslation();

  const { resolveValue } = useDeviceEventDictionary();
  if (!data) return null;

  const { internalId, interventionDate, connectionDate, device, event, source, payer } = data;

  const listData = [
    {
      label: t(proECareAlarmMessages.alarmCard.id),
      value: internalId,
    },
    {
      label: t(proECareAlarmMessages.alarmCard.program),
      value: 'CUS' || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmCard.interventionDate),
      value: interventionDate ? format(interventionDate, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noData),
      labelElement: data && data.sosStatus !== 'CLOSED' && (
        <UpdateInterventionDateForm alarmId={data.id} defaultValues={interventionDate} />
      ),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.alarmDate),
      value: format(connectionDate, DATE_FORMATS.DISPLAY_TIME),
      labelElement: (
        <InfoIconWithTooltip content={t(proECareAlarmMessages.alarmDetails.alarmDateDetails)} wrapperProps={{ sx: { ml: 1 } }} />
      ),
    },
    {
      label: t(proECareAlarmMessages.alarmCard.entity),
      value: payer || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmCard.device),
      value: device || t(generalMessages.noData),
    },
    {
      label: t(proECareAlarmMessages.alarmCard.event),
      value: resolveValue(event),
    },
    {
      label: t(proECareAlarmMessages.alarmCard.contractNumber),
      value: source?.contactNumber || t(generalMessages.noData),
    },
  ];

  return listData;
};
export default useCusInfo;
