import { useMutation, useQueryClient } from 'react-query';

import servicesProfile from 'api/servicesProfile/servicesProfile';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { Basket } from 'constants/_types/Basket';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import servicesMessages from 'translations/specific/services.mjs';

type UseSaveServicesProfile = (params: { close: () => void; dataToSave: Basket }) => { onSave: (name: string) => void; isSending: boolean };

const useSaveServicesProfile: UseSaveServicesProfile = ({ close, dataToSave }) => {
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());
  const { showSnackbar } = useShowSnackbar();
  const { patient } = usePatientContext();
  const client = useQueryClient();

  const mutation = useMutation(QUERY_KEYS.SAVE_SERVICES_PROFILE, servicesProfile.createServicesProfile(patient?.id), {
    onSuccess: () => {
      showSnackbar({ variant: 'success', translationArray: servicesMessages.saveServicesProfileDialog.profileSavedMessage });
      close();
      client.invalidateQueries([QUERY_KEYS.GET_SERVICES_PROFILE, createQueryKeyFromObject({ patientId: patient?.id, selectedPayersList })]);
    },
  });

  const onSave = (name: string) => {
    mutation.mutate({ patient: patient?.id, coreServices: dataToSave, name });
  };

  return { onSave, isSending: mutation.isLoading };
};

export default useSaveServicesProfile;
