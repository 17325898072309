import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  noPadding: {
    padding: 0,
  },
  section: {
    gap: 0,
  },
  cardContainer: {
    paddingBottom: `${theme.spacing(1)} !important`,
    paddingTop: `${theme.spacing(2)} !important`,
  },
  definitionListRoot: {
    gap: 20,
  },
}));

export default useStyles;
