import { useMemo } from 'react';

import { useQuery } from 'react-query';

import patients, { getPatientKeyGeneratorLegacy } from 'api/patient/patients';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';

import { parseAddressData } from '../../_services/parseAddressData/parseAddressData';

export const useAddressQuery = () => {
  const { patient } = usePatientContext();

  const { data, isFetched } = useQuery(getPatientKeyGeneratorLegacy(patient?.id as number), patients.getPatientLegacy(patient?.id), {
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
  });

  const addressParsedData = useMemo(() => data && parseAddressData(data.data.personal_data), [data]);

  return { isFetched, addressData: addressParsedData };
};
