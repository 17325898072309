import type { ChangeEvent, FC } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { ReactComponent as RadioCheckedIcon } from 'assets/icons/checkboxes/radio_checked.svg';
import { ReactComponent as RadioUncheckedIcon } from 'assets/icons/checkboxes/radio_unchecked.svg';
import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import type { RadioOption } from 'constants/_types/RadioOptions';
import type { DateSoonOptions } from 'constants/_types/SelectedServices';

import useStyles from './InputRadioGroup.styles';

export interface Props {
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  inlineError?: boolean;
  name: string;
  radioOptions: RadioOption<DateSoonOptions>[];
  required?: boolean;
  tiny?: boolean;
  title?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputRadioGroup: FC<Props> = ({ defaultValue, disabled, error, inlineError, radioOptions, required, tiny, title, ...field }) => {
  const { classes } = useStyles({ tiny, error: !!error });

  return (
    <div>
      <FormControl className={classes.root}>
        {title && (
          <Typography variant='body1'>
            {title}
            {required && <span className={classes.asterisk}>*</span>}
          </Typography>
        )}
        <RadioGroup className={classes.radioGroup} defaultValue={defaultValue} {...field}>
          {radioOptions.map(radioOption => (
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Radio
                  checkedIcon={<RadioCheckedIcon className={classes.icon} />}
                  color='secondary'
                  disabled={disabled}
                  icon={<RadioUncheckedIcon className={classes.icon} />}
                  required={required}
                />
              }
              key={radioOption.value}
              label={radioOption.label}
              value={radioOption.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {!inlineError && <InputFooter className={classes.footer} error={error} />}
    </div>
  );
};

export default InputRadioGroup;
