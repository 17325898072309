import { useCallback, useMemo } from 'react';

import type { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import type { ParsedPatientData } from 'api/_parsedTypes';
import patients, { getPatientKeyGeneratorLegacy } from 'api/patient/patients';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import type { PatientFormInput } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { useDefaultValues } from 'components/UI/organisms/PatientPersonalInfo/_hooks/useDefaultValues/useDefaultValues';
import { useFormDynamicData } from 'components/UI/organisms/PatientPersonalInfo/_hooks/useFormDynamicData/useFormDynamicData';
import getDefaultCountryName from 'components/UI/organisms/PatientPersonalInfo/_services/getDefaultCountryName/getDefaultCountryName';
import { prepareAddressDataForUpdate } from 'components/UI/organisms/PatientPersonalInfo/_services/prepareAddressDataForUpdate/prepareAddressDataForUpdate';
import { prepareDataForCreate } from 'components/UI/organisms/PatientPersonalInfo/_services/prepareDataForCreate/prepareDataForCreate';
import { preparePersonalDataForUpdate } from 'components/UI/organisms/PatientPersonalInfo/_services/preparePersonalDataForUpdate/preparePersonalDataForUpdate';
import { userDataParser } from 'components/UI/organisms/PatientPersonalInfo/_services/userDataParser/userDataParser';
import { CLAUSE_LOCATIONS } from 'constants/_types/ClauseLocations';
import arePhoneNumbersEqual from 'helpers/comparePhoneNumbers/arePhoneNumbersEqual';
import useConsentUpdate from 'hooks/_clauses/useConsentUpdate/useConsentUpdate';
import usePatientMutations from 'hooks/_mutations/usePatientMutations/usePatientMutations';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import useLoading from 'hooks/useLoading/useLoading';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import usePatientEditorUIStorage from 'storages/patientEditorUIStorage';

export interface FormDynamicData {
  hasForeignDocument: boolean;
}

type UsePatientInfoFormParams = {
  patientId: number | null;
  isPro: boolean;
};

type UsePatientInfoForm = {
  form: UseFormReturn<PatientFormInput>;
  parsedUserData: ParsedPatientData | null;
  refreshData: ({ action }: { action: 'create' | 'update' }) => Promise<void>;
  isLoading: boolean;
  isSending: boolean;
  onSubmit: () => void;
  restoreInitialData: () => void;
};

export const usePatientInfoForm = ({ patientId, isPro }: UsePatientInfoFormParams): UsePatientInfoForm => {
  const [canEditPersonalData, canEditAddressData] = usePermissionCheck(['change_personaldata', 'change_addressdata'], isPro);
  const { userInfo, refreshUserInfo } = useAuthContext();
  const languageCode = useLanguageCode();

  const { addToGlobalDialogQueue } = useGlobalDialogsContext();
  const { updateClausesConsents } = useConsentUpdate([CLAUSE_LOCATIONS.personalData]);
  const sending = useLoading();
  const { isPatientEditorOpen, closePatientEditor } = usePatientEditorUIStorage();

  const { data, isLoading, refetch } = useQuery(
    getPatientKeyGeneratorLegacy(patientId as number, isPro),
    patients.getPatientLegacy(patientId as number, isPro || undefined),
    {
      enabled: !!patientId,
    },
  );

  const refreshData = useCallback(
    async ({ action }: { action: 'create' | 'update' }) => {
      if (action === 'create') await refreshUserInfo();
      if (action === 'update') await Promise.all([refetch(), refreshUserInfo()]);
    },
    [refreshUserInfo, refetch],
  );

  const parsedUserData = useMemo((): ParsedPatientData | null => {
    if (!data) return null;
    return userDataParser(data.data, userInfo, isPro);
  }, [data?.data, userInfo]);

  const form = useForm<PatientFormInput>({ defaultValues: { [PATIENT_FORM_INPUT_KEYS.country]: getDefaultCountryName(languageCode) } });

  const { restoreInitialData } = useDefaultValues({ parsedUserData, form, isEditMode: isPatientEditorOpen, isPro });

  useFormDynamicData(form);

  const {
    createPersonalDataMutation,
    updatePersonalDataMutation,
    updateAddressDataMutation,
    partialUpdatePatientBaseData,
    revertNumberMutation,
  } = usePatientMutations({
    setError: form.setError,
    setValue: form.setValue,
    onLoadingEnd: sending.end,
    refreshData,
    isPro,
    patientId,
    personalDataId: data?.data.personal_data.id || null,
    patientAddressId: data?.data.personal_data.main_address.id || null,
  });

  const handleSubmit: SubmitHandler<PatientFormInput> = async formData => {
    sending.start();
    const shouldVerifyNumber =
      !isPro && !arePhoneNumbersEqual(userInfo.phoneNumber, formData[PATIENT_FORM_INPUT_KEYS.phoneNumber as 'phoneNumber']);
    if (shouldVerifyNumber) {
      try {
        const phoneVerified = await addToGlobalDialogQueue({
          type: GlobalDialogs.verifyMobileNumber,
          props: { initialNumber: formData[PATIENT_FORM_INPUT_KEYS.phoneNumber], cancelable: true },
          withPromise: true,
        });
        if (!phoneVerified) {
          closePatientEditor();
          return;
        }
        closePatientEditor();
      } catch (err: unknown) {
        if (err === 'expired') {
          await revertNumberMutation.mutateAsync(undefined);
          return;
        }
      }
    }

    if (patientId) {
      const promises: Promise<any>[] = [];

      if (canEditAddressData) promises.push(updateAddressDataMutation.mutateAsync(prepareAddressDataForUpdate(formData)));
      if (canEditPersonalData) promises.push(updatePersonalDataMutation.mutateAsync(preparePersonalDataForUpdate(formData)));
      if (canEditPersonalData) {
        promises.push(
          partialUpdatePatientBaseData.mutateAsync({
            address_directions: formData.addressDirections,
            contact_person: formData.contactPerson,
          }),
        );
      }
      if (!isPro) promises.push(updateClausesConsents(formData.clauses, { updateOnlyChecked: true }));

      await Promise.all(promises);
      refreshData({ action: 'update' });
    } else {
      // Pro user should not be able to create patient
      await createPersonalDataMutation.mutateAsync(prepareDataForCreate(formData));
      await updateClausesConsents(formData.clauses, { updateOnlyChecked: true });
    }
    sending.end();
    closePatientEditor();
  };

  const onSubmit = form.handleSubmit(handleSubmit);

  return { form, parsedUserData, onSubmit, isLoading, refreshData, isSending: sending.state, restoreInitialData };
};
