import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';
import type { CoreService, CoreServiceDTO } from 'api/coreService/coreService.types';
import { parseServicesForFE } from 'api/coreService/parseServicesForFE/parseServicesForFE';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../coreService';

interface Params {
  patientId: number;
  searchPhrase: string;
  payers: string[];
}

type GetServicesSearchResultsQuery = () => Promise<CoreService[]>;

export const getServicesSearchResults =
  ({ patientId, searchPhrase, payers }: Params): GetServicesSearchResultsQuery =>
  async () => {
    const { data } = await axios.get<GetServicesSearchResultsQuery, PaginatedApiResponse<CoreServiceDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: {
          patient_id: patientId,
          q: searchPhrase,
          payer: payers,
        },
      }),
    );

    return parseServicesForFE(data.results);
  };
