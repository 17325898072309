import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttonWrapper: {
    marginBottom: 10,
  },
  header: {
    marginBottom: 10,
  },
  cardContainer: {
    paddingTop: `${theme.spacing(2)} !important`,
  },
  notesWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: 20,

    '> section': {
      flex: 1,
    },
  },
}));

export default useStyles;
