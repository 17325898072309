import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { QueryKey } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import type { ListData, PaginatedApiResponse } from 'api/_types';
import type { UrlQueryParams } from 'constants/_types/Params';
import { PAGE_SIZE } from 'constants/pagination/paginationSettings';

type UsePagination = (args: {
  queryKey: QueryKey;
  queryFunction: (params?: UrlQueryParams) => () => Promise<PaginatedApiResponse<ListData>>;
  pageSize?: number;
  additionalParams?: Record<string, boolean>;
}) => {
  status: 'idle' | 'error' | 'loading' | 'success';
  isLoading: boolean;
  data: PaginatedApiResponse<ListData> | undefined;
  isPreviousData: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

export const usePagination: UsePagination = ({ queryKey, queryFunction, pageSize = PAGE_SIZE, additionalParams = {} }) => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);

  const basicQueryKey = useMemo(() => (Array.isArray(queryKey) ? [...queryKey, page] : [queryKey, page]), [queryKey, page]);
  const nextPageQueryKey = useMemo(() => (Array.isArray(queryKey) ? [...queryKey, page + 1] : [queryKey, page + 1]), [queryKey, page]);

  const { status, isLoading, data, isPreviousData } = useQuery(
    basicQueryKey,
    queryFunction({
      limit: pageSize,
      offset: pageSize * page,
      ...additionalParams,
    }),
  );

  useEffect(() => {
    if (data?.data.next) {
      queryClient.prefetchQuery(
        nextPageQueryKey,
        queryFunction({
          limit: pageSize,
          offset: pageSize * page,
          ...additionalParams,
        }),
      );
    }
  }, [page]);

  return { status, isLoading, data, isPreviousData, page, setPage };
};
