import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ECareSyncCusEndpoint } from 'api/eCareSyncWithCus/eCareSyncWithCus';
import { ECareTechnicalIssues } from 'api/eCareTechnicalIssues/eCareTechnicalIssues.types';
import { ReactComponent as EditIcon } from 'assets/icons/editPro.svg';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import ECareSyncWithCusButton from 'components/UI/molecules/ECareSyncWithCusButton/ECareSyncWithCusButton';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

import TechnicalIssueEditDialog from '../_dialogs/TechnicalIssueEditDialog/TechnicalIssueEditDialog';
import useBaseData from './_hooks/useBaseData/useBaseData';
import useDeviceData from './_hooks/useDeviceData/useDeviceData';
import useStyles from './ProfessionalECareTechnicalIssueInfo.styles';

type Props = {
  data: ECareTechnicalIssues;
};

const ProfessionalECareTechnicalIssueInfo: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const baseDefinitions = useBaseData(data);
  const deviceDefinitions = useDeviceData(data);

  const endpointsToSync: ECareSyncCusEndpoint[] = ['technicalIssues'];

  return (
    <>
      <CardContentContainer isLastSection>
        <div className={classes.twoColumnsContainer}>
          <SectionWithTitle
            className={classes.definitionList}
            title={t(proECareTechnicalIssuesMessages.baseIssueSectionTitle)}
            titleIcon={<EditIcon className={classes.icon} onClick={openDialog} />}
          >
            {baseDefinitions.map(def => (
              <DefinitionList columns={def.length} definitions={def} fontSize='small' />
            ))}
          </SectionWithTitle>
          <SectionWithTitle className={classes.definitionList} title={t(proECareTechnicalIssuesMessages.deviceSectionTitle)}>
            {deviceDefinitions.map(def => (
              <DefinitionList columns={def.length} definitions={def} fontSize='small' />
            ))}
          </SectionWithTitle>
        </div>
        <ECareSyncWithCusButton
          endpoints={endpointsToSync}
          id={data.id}
          message={
            data.lastSync
              ? `${t(ecarePhoneCallMessages.syncWithCusInfo)} ${unknownDateToString(data.lastSync, DATE_FORMATS.DISPLAY_TIME)}`
              : t(ecarePhoneCallMessages.noLastSyncData)
          }
        />
      </CardContentContainer>
      <TechnicalIssueEditDialog
        close={closeDialog}
        id={data.id}
        isOpen={isDialogOpen}
        values={{
          description: data.description,
          fixDescription: data?.fixDescription,
          fixDate: data?.fixDate,
        }}
      />
    </>
  );
};

export default ProfessionalECareTechnicalIssueInfo;
