import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  control: {
    '& label': {
      maxWidth: `calc(100% - ${theme.spacing(11)})`,
    },
  },
}));

export default useStyles;
