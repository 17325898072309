import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  label: {
    fontSize: '1rem',
    top: '-2px',
  },
  input: {},
  icons: {
    marginLeft: theme.spacing(2),

    path: {
      fill: theme.palette.primary.main,
    },
  },
  noPadding: {
    padding: 0,
  },
}));

export default useStyles;
