import type { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import contactForm from 'api/contactForm/contactForm';
import FormInputDropdown from 'components/UI/organisms/_formInputs/FormInputDropdown/FormInputDropdown';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import FormGlobalError from 'components/UI/organisms/FormGlobalError/FormGlobalError';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import generalMessages from 'translations/common/general.mjs';
import helpMessages from 'translations/specific/help.mjs';

import { CONTACT_FORM_INPUT_KEYS } from '../../_constants/inputKeys';
import { useContactForm } from '../../_hooks/useContactForm/useContactForm';
import { validateCategoryDropdown } from './_services/validateCategoryDropdown/validateCategoryDropdown';
import { validateInquiry } from './_services/validateInquiry/validateInquiry';
import useStyles from './ContactForm.styles';

export interface Props {
  hasInternalBranding: boolean;
}

const ContactForm: FC<Props> = ({ hasInternalBranding }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ hasInternalBranding });
  const languageCode = useLanguageCode();

  const { data: formDropdownCategories } = useQuery(
    [QUERY_KEYS.CONTACT_FORM_CATEGORIES, languageCode],
    contactForm.getContactFormCategories(),
    {
      retry: false,
      cacheTime: 60 * 60 * 1000, // 1 hour
    },
  );

  const { form, loading, postContactFormMutationGeneralErrors, onSubmit } = useContactForm();

  return (
    <div className={classes.root}>
      <Typography className={classes.header} component='p' variant='subtitle3'>
        {t(helpMessages.form.header)}
      </Typography>
      <div>
        <FormGlobalError errors={postContactFormMutationGeneralErrors} />
        <div className={classes.inputWrapper}>
          <FormInputDropdown
            control={form.control}
            inputProps={{ disabled: loading }}
            label={t(helpMessages.form.helperTexts.dropdown)}
            name={CONTACT_FORM_INPUT_KEYS.category}
            options={formDropdownCategories as DropdownOption[]}
            required
            rules={{
              validate: (currentValue?: string) => validateCategoryDropdown({ currentValue, t }),
            }}
            size='small'
          />
        </div>
        <div className={classes.inputWrapper}>
          <FormInputText
            control={form.control}
            inputProps={{ disabled: loading }}
            label={t(helpMessages.form.helperTexts.message)}
            multiline
            name={CONTACT_FORM_INPUT_KEYS.inquiry}
            required
            rows={3}
            rules={{
              validate: (currentValue?: string) => validateInquiry({ currentValue, t }),
            }}
            size='small'
          />
        </div>
        <Button className={classes.button} disabled={loading} variant='contained' onClick={onSubmit}>
          {t(generalMessages.submit)}
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
