import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
}));

export default useStyles;
