import { useEffect, type FC, useMemo } from 'react';

import { useMutation } from 'react-query';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import users from 'api/users';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import LayoutAuthorized from 'components/root/LayoutAuthorized/LayoutAuthorized';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import PATHS, { PROFESSIONAL_PREFIX } from 'constants/router/PATHS';
import useClausesConsentsQuery from 'hooks/_clauses/useClausesConsentsQuery/useClausesConsentsQuery';
import { REVERT_PATIENT_NUMBER_MUTATION_KEY } from 'hooks/_mutations/usePatientMutations/usePatientMutations';
import useIsProfessionalUser from 'hooks/useIsProfessionalUser/useIsProfessionalUser';
import { getLoginPathWithAttachedRememberedPath } from 'services/_rememberedPath/getLoginPathWithAttachedRememberedPath/getLoginPathWithAttachedRememberedPath';

const PrivateRoute: FC = () => {
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();
  const { clausesWithConsents } = useClausesConsentsQuery([], { onlySingle: true });
  const { brandInfo } = useBrandContext();
  const { userInfo } = useAuthContext();
  const location = useLocation();
  const revertNumberMutation = useMutation(REVERT_PATIENT_NUMBER_MUTATION_KEY, users.phoneUpdateRevert());

  const isProfessionalUser = useIsProfessionalUser();

  const optionalNumberVerification = async () => {
    try {
      await addToGlobalDialogQueue({
        type: GlobalDialogs.verifyMobileNumber,
        props: { initialNumber: userInfo.phoneNumber, cancelable: true },
        withPromise: true,
      });
    } catch (e) {
      revertNumberMutation.mutate();
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.isAuthorized && !isProfessionalUser) {
      // Phone number verification
      const phoneVerificationRequiredByBranding = !userInfo.hasPhoneVerified && brandInfo?.config.registration_required_phone_number;
      const phoneVerificationRequiredAfterChange = !userInfo.hasPhoneVerified && userInfo.phoneNumber;
      if (phoneVerificationRequiredByBranding) {
        addToGlobalDialogQueue({ type: GlobalDialogs.verifyMobileNumber });
      } else if (phoneVerificationRequiredAfterChange) {
        optionalNumberVerification();
      }

      // Consents verification
      if (!userInfo.hasObligatoryConsents?.result) {
        addToGlobalDialogQueue({
          type: GlobalDialogs.clausesConsentsForcedRequired,
        });
      } else if (
        clausesWithConsents &&
        clausesWithConsents.some(({ hasUserConsent, isObligatory }) => !isObligatory && hasUserConsent === null)
      ) {
        addToGlobalDialogQueue({
          type: GlobalDialogs.clausesConsentsForcedOptional,
        });
      }
    }
  }, [userInfo]);

  const isCommonRoute = useMemo(() => {
    const commonRoutes = [PATHS.SETTINGS, PATHS.HELP];
    return commonRoutes.includes(location.pathname);
  }, [location]);

  const routeOnlyForPro = !!useMemo(() => location.pathname.match(new RegExp(`/${PROFESSIONAL_PREFIX}`)), [location]);

  if (userInfo.isAuthorized === false) {
    return <Navigate replace state={{ from: location }} to={getLoginPathWithAttachedRememberedPath()} />;
  }

  if (userInfo.isAuthorized === null && isProfessionalUser === null) {
    return <LoaderOverlay />;
  }

  if (!isCommonRoute && !routeOnlyForPro && isProfessionalUser) {
    return <Navigate replace state={{ from: location }} to={PATHS.PROFESSIONAL_ECARE_ALARMS_LIST} />;
  }

  return (
    <LayoutAuthorized>
      <Outlet />
    </LayoutAuthorized>
  );
};

export default PrivateRoute;
