import type { FC } from 'react';

import { Card, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { NoteLog } from 'api/patient/patient.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import generalMessages from 'translations/common/general.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import useStyles from './PatientHealthDetailsNoteHistoryDialog.styles';

export type Props = {
  header: string;
  close: () => void;
  isOpen: boolean;
  historyData?: NoteLog[];
};

const PatientHealthDetailsNoteHistoryDialog: FC<Props> = ({ isOpen, header, close, historyData }) => {
  const { t } = useTranslation();

  const { classes } = useStyles();
  return (
    <DialogWrapper
      actionsConfig={[{ label: t(generalMessages.close), onClick: close, variant: 'contained' }]}
      close={close}
      header={`${t(proPatientMessages.editionHistory)} - ${header}`}
      isOpen={isOpen}
    >
      {historyData ? (
        <div className={classes.wrapper}>
          {historyData.map(({ id, createdAt, email, body, additionalData }) => (
            <Card className={classes.card} key={id}>
              <Typography variant='subtitle2'>
                {email} - {format(createdAt, DATE_FORMATS.DISPLAY_LONG_WITH_TIME)}
              </Typography>
              <Typography variant='caption'>{body}</Typography>
              <Typography variant='body1'>{additionalData.content}</Typography>
            </Card>
          ))}
        </div>
      ) : (
        t(generalMessages.noData)
      )}
    </DialogWrapper>
  );
};

export default PatientHealthDetailsNoteHistoryDialog;
