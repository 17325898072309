import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  section: {
    gap: 0,
  },
  bottomActionBar: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    textAlign: 'right',
  },
  boxList: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
