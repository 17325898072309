import type { FC } from 'react';
import { useMemo } from 'react';

import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import FormInputDateRange from 'components/UI/organisms/_formInputs/FormInputDateRange/FormInputDateRange';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { DATE_RANGE_AREA } from '../../_constants/gridAreas';
import DateSoon from '../DateSoon/DateSoon';
import { useDateRangeRelation } from './_hooks/useDateRangeRelation/useDateRangeRelation';
import { useInputsGroupsRelation } from './_hooks/useInputsGroupsRelation/useInputsGroupsRelation';
import { usePrepareValidationRules } from './_hooks/usePrepareValidationRules/usePrepareValidationRules';

export interface Props {
  kind: OrderServiceCoreServicesKinds;
  serviceItemId?: number;
}

const ServiceDates: FC<Props> = ({ kind, serviceItemId }) => {
  const { t } = useTranslation();
  const { form } = useOrderServiceFormContext();

  const isHeaderAssembled = useMemo(() => isNil(serviceItemId), [serviceItemId]);

  const { dateSoonName, dateFromName, dateUntilName } = useMemo(
    () => ({
      dateSoonName: isHeaderAssembled ? `details.${kind}.dateSoon` : `details.${kind}.serviceItems._${serviceItemId}.dateSoon`,
      dateFromName: isHeaderAssembled ? `details.${kind}.dateFrom` : `details.${kind}.serviceItems._${serviceItemId}.dateFrom`,
      dateUntilName: isHeaderAssembled ? `details.${kind}.dateUntil` : `details.${kind}.serviceItems._${serviceItemId}.dateUntil`,
    }),
    [isHeaderAssembled],
  );

  const dateSoonValue = form.watch(dateSoonName as any);
  const dateFromValue = form.watch(dateFromName as any);
  const dateUntilValue = form.watch(dateUntilName as any);

  useInputsGroupsRelation({
    dateSoonName,
    dateSoonValue,
    dateFromName,
    dateFromValue,
    dateUntilName,
    dateUntilValue,
  });

  const { dateFromMaxDate, dateUntilMinDate } = useDateRangeRelation({ dateFromValue, dateUntilValue });

  const { validationRadio, validationRangeFrom, validationRangeUntil } = usePrepareValidationRules({
    form,
    dateSoonName,
    dateFromName,
    dateUntilName,
  });

  return (
    <>
      <DateSoon dateSoonName={dateSoonName} validationRules={validationRadio} />
      <FormInputDateRange
        dateFromInputProps={{ maxDate: dateFromMaxDate }}
        dateFromLabel={t(orderServiceMessages.details.dateFrom)}
        dateFromName={dateFromName}
        datesControl={form.control}
        dateUntilInputProps={{ minDate: dateUntilMinDate }}
        dateUntilLabel={t(orderServiceMessages.details.dateUntil)}
        dateUntilName={dateUntilName}
        gridArea={DATE_RANGE_AREA}
        validationRulesFrom={validationRangeFrom}
        validationRulesUntil={validationRangeUntil}
      />
    </>
  );
};

export default ServiceDates;
