import type { ReactNode } from 'react';

type MessageOrigin = 'missingAccountFields' | 'noActivePolicy' | 'passwordChangeReminder' | 'submissionSuccess';

export const MESSAGE_ORIGINS: { [key in MessageOrigin]: MessageOrigin } = {
  missingAccountFields: 'missingAccountFields',
  noActivePolicy: 'noActivePolicy',
  passwordChangeReminder: 'passwordChangeReminder',
  submissionSuccess: 'submissionSuccess',
};

export type Message = {
  origin: MessageOrigin;
  content: string | JSX.Element;
  severity: 'error' | 'warning' | 'info' | 'success';
  icon: ReactNode;
  colorContent?: string;
  description?: string;
  action?: ReactNode;
  header?: JSX.Element;
};
