import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ withLeftPadding: boolean }>()((theme, { withLeftPadding }) => ({
  root: {
    marginTop: theme.spacing(3),
    paddingLeft: withLeftPadding ? theme.spacing(1) : 0,
  },
}));

export default useStyles;
