import { useEffect, useContext } from 'react';

import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import type { ConfirmationOptions } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';

type Config = { onYes: () => void; onNo?: () => void };

const useRedirectPrompt = (dialog: ConfirmationOptions, config: Config, when = true) => {
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return () => null;
    // using UNSAFE_NavigationContext to access to "block" method
    // https://github.com/remix-run/react-router/issues/8139#issuecomment-977790637
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const unblock = navigator.block(async tx => {
      const retryRedirect = () => {
        unblock();
        tx.retry();
      };
      if (tx.location.state?.force) {
        retryRedirect();
        return;
      }
      const confirmation = await showConfirmationDialog(dialog);
      if (confirmation) {
        retryRedirect();
        if (config.onYes) config.onYes();
      } else if (config.onNo) config.onNo();
    });
    return () => {
      unblock();
    };
  }, [navigator, when]);
};

export default useRedirectPrompt;
