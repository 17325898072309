import type { FC } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PATHS from 'constants/router/PATHS';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import PatientDevicesDialog from '../_dialogs/PatientDevicesDialog/PatientDevicesDialog';
import PatientEditionHistoryDialog from '../_dialogs/PatientEditionHistoryDialog/PatientEditionHistoryDialog';
import useStyles from './ProfessionalPatientHeaderTile.styles';

export type Props = {
  patientId: number;
};

// TODO pro-patient-page: pass proper filter keys

const ProfessionalPatientHeaderTile: FC<Props> = ({ patientId }) => {
  const [canViewEcareOrders, canViewAlarms, canViewHistory] = usePermissionCheck([
    'view_ecareserviceorder',
    'view_ecaresos',
    {
      comparisonMode: 'all',
      permissions: ['view_actionlogentry', 'view_contenttype'],
    },
  ]);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isHistoryDialogOpen, openHistoryDialog, closeHistoryDialog] = useBooleanState(false);
  const [isDevicesDialogOpen, openDevicesDialog, closeDevicesDialog] = useBooleanState(false);

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        component={Link}
        disabled={!canViewEcareOrders}
        state={{ filterBy: { key: 'id', value: patientId } }}
        to={`/${PATHS.PROFESSIONAL_ECARE_ORDER_LIST}`}
        variant='outlined'
      >
        {t(proPatientMessages.commissionList)}
      </Button>
      <Button
        className={classes.button}
        component={Link}
        disabled={!canViewAlarms}
        state={{ filterBy: { key: 'id', value: patientId } }}
        to={{ pathname: `/${PATHS.PROFESSIONAL_ECARE_ALARMS_LIST}` }}
        variant='outlined'
      >
        {t(proPatientMessages.alarmsList)}
      </Button>
      <Button className={classes.button} variant='contained' onClick={openDevicesDialog}>
        {t(proPatientMessages.showPatientDevices)}
      </Button>
      <Button className={classes.button} disabled={!canViewHistory} variant='contained' onClick={openHistoryDialog}>
        {t(proPatientMessages.showChangesHistory)}
      </Button>
      {canViewHistory && <PatientEditionHistoryDialog close={closeHistoryDialog} isOpen={isHistoryDialogOpen} patientId={patientId} />}
      <PatientDevicesDialog close={closeDevicesDialog} isOpen={isDevicesDialogOpen} patientId={patientId} />
    </div>
  );
};

export default ProfessionalPatientHeaderTile;
