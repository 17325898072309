import type { SelectedServices } from 'constants/_types/SelectedServices';

type OrderServiceFormInputKeys =
  | 'serviceType'
  | 'serviceSubtype'
  | 'serviceFromSubtype'
  | 'street'
  | 'buildingNumber'
  | 'apartmentNumber'
  | 'city'
  | 'postcode'
  | 'country'
  | 'searchAroundDistance'
  | 'searchNearest';

export const ORDER_SERVICE_FORM_INPUT_KEYS: { [key in OrderServiceFormInputKeys]: OrderServiceFormInputKeys } = {
  serviceType: 'serviceType',
  serviceSubtype: 'serviceSubtype',
  serviceFromSubtype: 'serviceFromSubtype',
  street: 'street',
  buildingNumber: 'buildingNumber',
  apartmentNumber: 'apartmentNumber',
  city: 'city',
  postcode: 'postcode',
  country: 'country',
  searchAroundDistance: 'searchAroundDistance',
  searchNearest: 'searchNearest',
};

export type OrderServiceFormInput = {
  payers: {
    [key: string]: boolean; // as id identifier for checkboxes
  };
  serviceType: string;
  serviceSubtype: string[];
  serviceFromSubtype: string;
  address: {
    street: string;
    buildingNumber: string;
    apartmentNumber: string;
    city: string;
    postcode: string;
    country: string;
    searchAroundDistance: string;
    searchNearest: boolean;
  };
  details: SelectedServices;
};
