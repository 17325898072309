import type { FC } from 'react';
import { memo } from 'react';

import { NavigateBefore } from '@mui/icons-material';
import { Button, ButtonBase, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PATHS from 'constants/router/PATHS';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import unifyTranslation from 'services/unifyTranslation/unifyTranslation';
import servicesMessages from 'translations/specific/services.mjs';

import useStyles from './ServicesTopBar.styles';

export type Props = {
  title?: string | string[];
  onActionButtonClick: () => void;
  disabledActionButton: boolean;
};

export const testId = createTestIdObject('ServicesTypeTopBar', {
  actionButton: 'actionButton',
  upSmNavigation: 'upSmNavigation',
});

const ServicesTopBar: FC<Props> = ({ title, onActionButtonClick, disabledActionButton }) => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {upSm && (
        <div className={classes.title} data-testid={testId.upSmNavigation}>
          <ButtonBase component={Link} disableRipple to={`/${PATHS.SERVICES}`}>
            <NavigateBefore color='secondary' fontSize='large' />
          </ButtonBase>
          {title && <Typography variant='subtitle3'>{t(unifyTranslation(title))}</Typography>}
        </div>
      )}
      <Button
        data-testid={testId.actionButton}
        disabled={disabledActionButton}
        size='small'
        variant='contained'
        onClick={onActionButtonClick}
      >
        {t(servicesMessages.addToRealizationBasket)}
      </Button>
    </div>
  );
};

const ServicesTopBarMemoized = memo(ServicesTopBar);

export { ServicesTopBarMemoized };

export default ServicesTopBar;
