import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import payers from 'api/payers/payers';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { parsePayersByServiceToDropdown } from '../../_services/parsePayersByServiceToDropdown/parsePayersByServiceToDropdown';

interface HookParams {
  serviceItemId: number;
}

export const usePayersByServiceQuery = ({ serviceItemId }: HookParams) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();

  const { data } = useQuery(
    `${QUERY_KEYS.PAYERS_BY_SERVICE}-${serviceItemId}`,
    payers.getPayersByService({ patientId: patient?.id, serviceItemId }),
    {
      enabled: !!patient?.id,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const payersByService = useMemo(() => parsePayersByServiceToDropdown(data, t(orderServiceMessages.payers.selfPayer)), [data]);

  return { payersByService };
};
