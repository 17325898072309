import { useEffect } from 'react';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';

interface HookParams {
  kind: OrderServiceCoreServicesKinds;
  serviceItemId: number;
}

export const useSetServiceIdAsFormValue = ({ kind, serviceItemId }: HookParams) => {
  const { form } = useOrderServiceFormContext();

  useEffect(() => {
    form.setValue(`details.${kind}.serviceItems._${serviceItemId}.id` as any, serviceItemId as never);
  }, []);
};
