const proECareAlarmMessages = {
  title: ['proECareAlarm>>title', 'Alarmy SOS'],
  alarmColumnTitle: ['proECareAlarm>>alarmColumnTitle', 'Alarm'],
  personDetailsColumnTitle: ['proECareAlarm>>personDetailsColumnTitle', 'Dane pacjenta'],
  interventionColumnTitle: ['proECareAlarm>>interventionColumnTitle', 'Interwencja'],
  alarmHistoryTitle: ['proECareAlarm>>alarmHistoryTitle', 'Opis alarmu'],
  alarmDescriptionButton: ['proECareAlarm>>alarmDescriptionButton', 'Zobacz opis alarmu'],
  noLastSyncData: ['proECareAlarm>>noLastSyncData', 'Synchronizacja z CUS jeszcze nie została wykonana'],
  columns: {
    eCareData: ['proECareAlarm>>columns>>eCareData', 'Dane zgłoszenia'],
    patientData: ['proECareAlarm>>columns>>patientData', 'Dane pacjenta'],
  },
  alarmDetails: {
    title: ['proECareAlarm>>alarmDetails>>title', 'Karta obsługi alarmu'],
    alarmDate: ['proECareAlarm>>alarmDetails>>alarmDate', 'Data alarmu'],
    alarmDateDetails: ['proECareAlarm>>alarmDetails>>alarmDateDetails', 'Data wpłynięcia alarmu do systemu providera'],
    serialNumber: ['proECareAlarm>>alarmDetails>>serialNumber', 'Numer seryjny'],
    sosType: ['proECareAlarm>>alarmDetails>>sosType', 'Rodzaj połączenia'],
    interventionDate: ['proECareAlarm>>alarmDetails>>interventionDate', 'Data interwencji'],
    order: ['proECareAlarm>>alarmDetails>>order', 'Zlecenie'],
    serviceRecipient: ['proECareAlarm>>alarmDetails>>serviceRecipient', 'Odbiorca usługi'],
    sosReason: ['proECareAlarm>>alarmDetails>>sosReason', 'Przyczyna Połączenia'],
    interventionType: ['proECareAlarm>>alarmDetails>>interventionType', 'Rodzaj Interwencji'],
    interventionEffect: ['proECareAlarm>>alarmDetails>>interventionEffect', 'Efekt Interwencji'],
    contentType: ['proECareAlarm>>alarmDetails>>contentType', 'Rodzaje urządzeń'],
    sendToCus: ['proECareAlarm>>alarmDetails>>sendToCus', 'Wyślij do CUS'],
    firstName: ['proECareAlarm>>alarmDetails>>firstName', 'Imię'],
    lastName: ['proECareAlarm>>alarmDetails>>lastName', 'Nazwisko'],
    pesel: ['proECareAlarm>>alarmDetails>>pesel', 'PESEL'],
    operator: ['proECareAlarm>>alarmDetails>>operator', 'Operator'],
    address: ['proECareAlarm>>alarmDetails>>address', 'Adres'],
    entity: ['proECareAlarm>>alarmDetails>>entity', 'Gmina'],
    alarmTime: ['proECareAlarm>>alarmDetails>>alarmTime', 'Czas alarmu'],
    payer: ['proECareAlarm>>alarmDetails>>payer', 'Gmina / Płatnik'],
    typeOfDisease: ['proECareAlarm>>alarmDetails>>typeOfDisease', 'Rodzaj schorzeń'],
    multipleTypeOfDisease: ['proECareAlarm>>alarmDetails>>multipleTypeOfDisease', 'schorzeń'],
    lifeStyle: ['proECareAlarm>>alarmDetails>>lifeStyle', 'Styl życia'],
    lifeMode: ['proECareAlarm>>alarmDetails>>lifeMode', 'Tryb życia'],
    dysfunctions: ['proECareAlarm>>alarmDetails>>dysfunctions', 'Dysfunkcje'],
    multipleDysfunctions: ['proECareAlarm>>alarmDetails>>multipleDysfunctions', 'dysfunkcji'],
    degreeOfCognitiveImpairment: ['proECareAlarm>>alarmDetails>>degreeOfCognitiveImpairment', 'Stopień niepełnosprawności poznawczej'],
    degreeOfMobilityDisability: ['proECareAlarm>>alarmDetails>>degreeOfMobilityDisability', 'Stopień niepełnosprawności ruchowej'],
    healthProblems: ['proECareAlarm>>alarmDetails>>healthProblems', 'Problemy zdrowotne'],
    multipleHealthProblems: ['proECareAlarm>>alarmDetails>>multipleHealthProblems', 'problemy zdrowotne'],
    allHealthProblems: ['proECareAlarm>>alarmDetails>>allHealthProblems', 'Wszystkie schorzenia'],
    projectUserNumber: ['proECareAlarm>>alarmDetails>>projectUserNumber', 'Kod Pacjenta (Bitrix)'],
  },
  interventionDate: ['proECareAlarm>>interventionDate', 'Dane interwencji'],
  interventionReasonStatus: ['proECareAlarm>>interventionReasonStatus', 'Przyczyna interwencji'],
  interventionTypeStatus: ['proECareAlarm>>interventionTypeStatus', 'Rodzaj Interwencji'],
  interventionEffectStatus: ['proECareAlarm>>interventionEffectStatus', 'Efekt Interwencji'],
  interventionAlarmDescription: ['proECareAlarm>>interventionAlarmDescription', 'Dodaj opis alarmu'],
  interventionAlarmPartnerTitle: ['proECareAlarm>>interventionAlarmPartnerTitle', 'Dodaj notatkę (idzie do partnera)'],
  interventionAlarmPartnerEditTitle: ['proECareAlarm>>interventionAlarmPartnerEditTitle', 'Edytuj notatkę (idzie do partnera)'],
  interventionAlarmPartnerDescription: ['proECareAlarm>>interventionAlarmPartnerDescription', 'Notatka (idzie do partnera)'],
  interventionAlarmPartnerNotes: ['proECareAlarm>>interventionAlarmPartnerNotes', 'Notatki (idzie do partnera)'],
  interventionAlarmPartnerMore: ['proECareAlarm>>interventionAlarmPartnerMore', 'Zobacz więcej notatek'],
  interventionPhoneCallNoteAdd: ['proECareAlarm>>interventionPhoneCallNoteAdd', 'Wpisz podsumowanie rozmowy'],
  changeInterventionDate: ['proECareAlarm>>changeInterventionDate', 'Zmień datę interwencji'],
  edit: ['proECareAlarm>>edit', 'Edytuj'],
  cusComment: ['proECareAlarm>>cusComment', 'Edytuj uwagę do CUS'],
  interventionBitixNoteTitle: ['proECareAlarm>>interventionPhoneCallNoteTitle', 'Notatka z rozmowy (Bitrix)'],
  interventionBitixNoteHistoryTitle: ['proECareAlarm>>interventionBitixNoteHistoryTitle', 'Notatki z rozmów (Bitrix)'],
  interventionBitixAddNoteTitle: ['proECareAlarm>>interventionBitixAddNoteTitle', 'Dodaj notatkę z rozmowy (Bitrix)'],
  interventionBitixEditNoteTitle: ['proECareAlarm>>interventionBitixEditNoteTitle', 'Edytuj notatkę z rozmowy (Bitrix)'],
  interventionBitrixNoteMore: ['proECareAlarm>>interventionBitrixNoteMore', 'Zobacz więcej notatek'],
  eCareAlertTableHeaders: {
    id: ['proECareAlarm>>eCareAlertTableHeaders>>id', 'ID'],
    alarmStatus: ['proECareAlarm>>eCareAlertTableHeaders>>alarmStatus', 'Status alarmu'],
    device: ['proECareAlarm>>eCareAlertTableHeaders>>device', 'Urządzenie'],
    event: ['proECareAlarm>>eCareAlertTableHeaders>>event', 'Zdarzenie'],
    subscription: ['proECareAlarm>>eCareAlertTableHeaders>>program', 'Abonament'],
    firstName: ['proECareAlarm>>eCareAlertTableHeaders>>firstName', 'Imię'],
    lastName: ['proECareAlarm>>eCareAlertTableHeaders>>lastName', 'Nazwisko'],
    pesel: ['proECareAlarm>>eCareAlertTableHeaders>>pesel', 'PESEL / Wiek'],
    address: ['proECareAlarm>>eCareAlertTableHeaders>>address', 'Ulica'],
    city: ['proECareAlarm>>eCareAlertTableHeaders>>city', 'Miasto'],
    postalCode: ['proECareAlarm>>eCareAlertTableHeaders>>postalCode', 'Kod'],
    sosType: ['proECareAlarm>>eCareAlertTableHeaders>>sosType', 'Rodzaj alarmu'],
    payer: ['proECareAlarm>>eCareAlertTableHeaders>>payer', 'Gmina / Płatnik'],
    alarmDate: ['proECareAlarm>>eCareAlertTableHeaders>>alarmDate', 'Data alarmu'],
    carer: ['proECareAlarm>>eCareAlertTableHeaders>>carer', 'Operator'],
    serviceRecipient: ['proECareAlarm>>eCareAlertTableHeaders>>serviceRecipient', 'Pacjent'],
    order: ['proECareAlarm>>eCareAlertTableHeaders>>order', 'Zlecenie'],
    entity: ['proECareAlarm>>eCareAlertTableHeaders>>entity', 'Gmina'],
    sosReason: ['proECareAlarm>>eCareAlertTableHeaders>>sosReason', 'Przyczyna Połączenia SOS'],
    projectUserNumber: ['proECareAlarm>>eCareAlertTableHeaders>>projectUserNumber', `Kod Pacjenta\xa0(Bitrix)`],
  },
  eCareAlertFilters: {
    id: ['proECareAlarm>>eCareAlertFilters>>id', 'ID'],
    alarmStatus: ['proECareAlarm>>eCareAlertFilters>>alarmStatus', 'Status alarmu'],
    event: ['proECareAlarm>>eCareAlertFilters>>event', 'Zdarzenie'],
    subscription: ['proECareAlarm>>eCareAlertFilters>>program', 'Abonament'],
    firstName: ['proECareAlarm>>eCareAlertFilters>>firstName', 'Imię'],
    lastName: ['proECareAlarm>>eCareAlertFilters>>lastName', 'Nazwisko'],
    pesel: ['proECareAlarm>>eCareAlertFilters>>pesel', 'PESEL'],
    address: ['proECareAlarm>>eCareAlertFilters>>address', 'Ulica'],
    city: ['proECareAlarm>>eCareAlertFilters>>city', 'Miasto'],
    postalCode: ['proECareAlarm>>eCareAlertFilters>>postalCode', 'Kod pocztowy'],
    sosType: ['proECareAlarm>>eCareAlertFilters>>sosType', 'Typ alarmu'],
    payer: ['proECareAlarm>>eCareAlertFilters>>payer', 'Gmina / Płatnik'],
    alarmDate: ['proECareAlarm>>eCareAlertFilters>>alarmDate', 'Data alarmu'],
    guardian: ['proECareAlarm>>eCareAlertFilters>>carer', 'Operator'],
    serviceRecipient: ['proECareAlarm>>eCareAlertFilters>>serviceRecipient', 'Pacjent'],
    sosReason: ['proECareAlarm>>eCareAlertFilters>>sosReason', 'Przyczyna Połączenia SOS'],
    patientId: ['proECareAlarm>>eCareAlertFilters>>patientId', 'Id pacjenta'],
    projectUserNumber: ['proECareAlarm>>eCareAlertFilters>>projectUserNumber', 'Kod Pacjenta (Bitrix)'],
  },
  eCareAlarmsPartnerNotesTable: {
    id: ['proECareAlarm>>eCareAlarmsPartnerNotesTable>>id', 'Sporządził'],
    creationTime: ['proECareAlarm>>eCareAlarmsPartnerNotesTable>>creationTime', 'Data utworzenia'],
    updatedTime: ['proECareAlarm>>eCareAlarmsPartnerNotesTable>>updatedTime', 'Data aktualizacji'],
    text: ['proECareAlarm>>eCareAlarmsPartnerNotesTable>>text', 'Treść'],
    lastSync: ['proECareAlarm>>eCareAlarmsPartnerNotesTable>>lastSync', 'Ostatnia synchronizacja'],
  },
  eCareAlarmsInternalNotesTable: {
    user: ['proECareAlarm>>eCareAlarmsInternalNotesTable>>user', 'Sporządził'],
    updatedBy: ['proECareAlarm>>eCareAlarmsInternalNotesTable>>updatedBy', 'Zmienił'],
    creationTime: ['proECareAlarm>>eCareAlarmsInternalNotesTable>>creationTime', 'Data utworzenia'],
    updatedTime: ['proECareAlarm>>eCareAlarmsInternalNotesTable>>updatedTime', 'Data edycji'],
    text: ['proECareAlarm>>eCareAlarmsInternalNotesTable>>text', 'Treść'],
    number: ['proECareAlarm>>eCareAlarmsInternalNotesTable>>number', 'Number rozmówcy'],
  },
  sosType: {
    interventionRequired: ['proECareAlarm>>sosType>>interventionRequired', 'Wymagana interwencja'],
    interventionNotRequired: ['proECareAlarm>>sosType>>interventionNotRequired', 'Niewymagana interwencja'],
    mistake: ['proECareAlarm>>sosType>>mistake', 'Pomyłka'],
    test: ['proECareAlarm>>sosType>>test', 'Połączenie próbne'],
  },
  sosStatus: {
    received: ['proECareAlarm>>sosStatus>>received', 'Odebrany'],
    missed: ['proECareAlarm>>sosStatus>>missed', 'Nieodebrany'],
    completeDocumentation: ['proECareAlarm>>sosStatus>>completeDocumentation', 'Dokumentacja kompletna'],
    closed: ['proECareAlarm>>sosStatus>>closed', 'Zamknięty'],
  },
  eventTypes: {
    sosButtonTerminal: ['proECareAlarm>>eventTypes>>sosButtonTerminal', 'Centrala'],
    sosButtonWristband: ['proECareAlarm>>eventTypes>>sosButtonWristband', 'Przycisk'],
    fall: ['proECareAlarm>>eventTypes>>fall', 'Upadek'],
    batteryLow: ['proECareAlarm>>eventTypes>>batteryLow', 'Słaba bateria'],
    co: ['proECareAlarm>>eventTypes>>co', 'Czad'],
    gas: ['proECareAlarm>>eventTypes>>gas', 'Gaz'],
  },
  alarmCard: {
    id: ['proECareAlarm>>alarmCard>>id', 'ID Alarmu'],
    subscription: ['proECareAlarm>>alarmCard>>subscription', 'Abonament'],
    program: ['proECareAlarm>>alarmCard>>program', 'Program'],
    interventionDate: ['proECareAlarm>>alarmCard>>interventionDate', 'Data interwencji'],
    received: ['proECareAlarm>>alarmCard>>recieved', 'Odebrał'],
    changed: ['proECareAlarm>>alarmCard>>changed', 'Zmienił'],
    device: ['proECareAlarm>>alarmCard>>device', 'Urządzenie'],
    healthConditions: ['proECareAlarm>>alarmCard>>healthConditions', 'Stan zdrowia'],
    socialSituation: ['proECareAlarm>>alarmCard>>socialSituation', 'Sytuacja socjalna'],
    branch: ['proECareAlarm>>alarmCard>>branch', 'Odział'],
    updatedBy: ['proECareAlarm>>alarmCard>>updatedBy', 'Zamienił'],
    entity: ['proECareAlarm>>alarmCard>>entity', 'Gmina / Płatnik'],
    event: ['proECareAlarm>>alarmCard>>event', 'Zdarzenie'],
    contractNumber: ['proECareAlarm>>alarmCard>>contractNumber', 'Numer kontraktu'],
    buttons: {
      sendToCus: ['proECareAlarm>>alarmCard>>buttons>>sendToCus', 'Wyślij do CUS'],
      medicalForm: ['proECareAlarm>>alarmCard>>buttons>>medicalForm', 'Otwórz ankietę medyczną'],
      contactPerson: ['proECareAlarm>>alarmCard>>buttons>>contactPerson', 'Osoby do kontaktu'],
      keyBox: ['proECareAlarm>>alarmCard>>buttons>>keyBox', 'Skrzynka na klucze'],
      keyBoxMissingPermissionInfo: [
        'proECareAlarm>>alarmCard>>buttons>>keyBoxMissingPermissionInfo',
        'Nie masz uprawnień do wyświetlenia kodu do skrzynki na klucze',
      ],
      addressDirection: ['proECareAlarm>>>alarmCard>buttons>>addressDirection', 'Wskazówki dojazdu'],
      endIntervention: ['proECareAlarm>>alarmCard>>buttons>>endIntervention', 'Zamknij interwencję'],
      endedIntervention: ['proECareAlarm>>alarmCard>>buttons>>endedIntervention', 'Interwencja zamknięta'],
      syncWithCus: ['proECareAlarm>>alarmCard>>buttons>>syncWithCus', 'Synchronizuj z CUS'],
      syncWithCusInfo: ['proECareAlarm>>alarmCard>>buttons>>syncWithCusInfo', 'Ostatnia synchronizacja:'],
      alarmDescription: ['proECareAlarm>>alarmCard>>buttons>>alarmDescription', 'Dodaj opis alarmu'],
      addNote: ['proECareAlarm>>alarmCard>>buttons>>addNote', 'Dodaj notatkę'],
      showAllIllnesses: ['proECareAlarm>>alarmCard>>buttons>>showAllIllnesses', 'Pokaż wszystkie schorzenia'],
    },
    dialogs: {
      medicalForm: ['proECareAlarm>>alarmCard>>dialogs>>openMedicalForm', 'Ankieta medyczna'],
      contactPerson: ['proECareAlarm>>alarmCard>>dialogs>>contactPerson', 'Osoby do kontaktu'],
      keyBox: ['proECareAlarm>>alarmCard>>dialogs>>keyBox', 'Skrzynka na klucze'],
      keyBoxLabel: ['proECareAlarm>>alarmCard>>dialogs>>keyBoxLabel', 'Kod do skrzynki na klucze'],
      keyBoxEmpty: ['proECareAlarm>>alarmCard>>dialogs>>keyBoxEmpty', 'Pacjent nie posiada skonfigurowanej skrzynki na klucze'],
      addressDirection: ['proECareAlarm>>alarmCard>>dialogs>>addressDirection', 'Wskazówki dojazdu'],
    },
  },
  confirmationDialog: {
    confirmationDialogBody: ['proECareAlarm>>confirmationDialog>>confirmationDialogBody', 'Czy na pewno chesz zmienić status?'],
    sosType: {
      confirmationDialogTitle: ['proECareAlarm>>confirmationDialog>>sosType>>confirmationDialogTitle', 'Zmiana statusu rodzaju połączenia'],
    },
    interventionReason: {
      confirmationDialogTitle: [
        'proECareAlarm>>confirmationDialog>>interventionReason>>confirmationDialogTitle',
        'Zmiana przyczyny interwencji',
      ],
    },
    interventionType: {
      confirmationDialogTitle: [
        'proECareAlarm>>confirmationDialog>>interventionType>>confirmationDialogTitle',
        'Zmiana rodzaju interwencji',
      ],
    },
    interventionEffect: {
      confirmationDialogTitle: [
        'proECareAlarm>>confirmationDialog>>interventionEffect>>confirmationDialogTitle',
        'Zmiana efektu interwencji',
      ],
    },
    closeIntervention: {
      closeInterventionDialogDescription: [
        'proECareAlarm>>confirmationDialog>>closeIntervention>>closeInterventionDialogDescription',
        'Czy na pewno chcesz zakończyć interwencję?',
      ],
      cusSync: ['proECareAlarm>>confirmationDialog>>closeIntervention>>cusSync', 'Dane z CUS zostaną zsynchronizowane!'],
    },
    syncWithCus: {
      syncWithCusDialogTitle: ['proECareAlarm>>confirmationDialog>>syncWithCus>>syncWithCusDialogTitle', 'Synchronizacja CUS'],
      syncWithCusDialogBody: [
        'proECareAlarm>>confirmationDialog>>syncWithCus>>syncWithCusDialogBody',
        'Czy napewno chcesz synchronizować dane z CUS?',
      ],
    },
  },
  file: {
    name: ['proECareAlarm>>file>>name', 'Nazwa pliku'],
    description: ['proECareAlarm>>file>>description', 'Opis'],
    updatedAt: ['proECareAlarm>>file>>updatedAt', 'Ostatia aktualizacja'],
    download: ['proECareAlarm>>file>>download', 'Pobierz'],
  },
  bitrix: {
    operator: ['proECareAlarm>>bitrix>>operator', 'Osoba, która odebrała połączenie alarmowe'],
    projectUserNumber: ['proECareAlarm>>bitrix>>projectUserNumber', 'Kod pacjenta (Bitrix)'],
    tooltip: {
      addNote: [
        'proECareAlarm>>bitrix>>tooltip>>addNote',
        'Ta uwaga zostanie przesłana do partnera jako informacja połączona ze zleceniem i sposobem jego obsługi.',
      ],
    },
  },
  tooltip: {
    addAlarmMessage: [
      'proECareAlarm>>tooltip>>addAlarmMessage',
      'Uzupełnienie tych danych jest niezbędne do umożliwienia synchronizacji z CUS',
    ],
  },
};

export default proECareAlarmMessages;
