import type { Palette } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

const typography = (palette: Palette): TypographyOptions => ({
  fontFamily: ['Manrope', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
  fontWeightLight: 500,
  fontWeightRegular: 600,
  fontWeightMedium: 700,
  fontWeightBold: 800,

  h1: {
    fontSize: '2rem',
    lineHeight: '2.75rem',
    fontWeight: 800,
    color: palette.primary.main,
  },
  h2: {
    fontSize: '1.5rem',
    lineHeight: '2.0625rem',
    fontWeight: 800,
    color: palette.secondary.main,
  },
  h3: {
    fontSize: '1.375rem',
    lineHeight: '1.875rem',
    fontWeight: 800,
    color: palette.primary.main,
  },
  h4: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 800,
    color: palette.secondary.main,
  },
  subtitle1: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontWeight: 800,
    color: palette.secondary.main,
  },
  subtitle2: {
    fontSize: '0.9286rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
    color: palette.secondary.main,
  },
  subtitle3: {
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
    color: palette.secondary.main,
  },
  body1: {
    fontWeight: 400,
    fontSize: '0.8125rem',
    color: palette.grey[800],
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.7858rem',
    color: palette.grey[800],
  },
  caption: {
    fontSize: '0.7858rem',
    lineHeight: '0.9375rem',
    fontWeight: 700,
    color: palette.grey[600],
  },
  captionBold: {
    fontSize: '0.7858rem',
    lineHeight: '0.9375rem',
    fontWeight: 800,
    color: palette.grey[600],
  },
  button: {
    textTransform: 'none',
  },
});

export default typography;
