const patientMessages = {
  title: [ 'patient>>title', 'Twój profil pacjenta' ],
  sectionTitles: {
    policiesAndPayments: ['patient>>sectionTitles>>policiesAndPayments', 'Twoje płatności i polisy'],
    clausesRequired: ['patient>>sectionTitles>>clausesRequired', 'Zgody i oświadczenia'],
    clausesNotRequired: ['patient>>sectionTitles>>clausesNotRequired', 'Zgody nieobowiązkowe'],
  },
  patientDataSectionTitles: {
    personal: ['patient>>patientDataSectionTitles>>personal', 'Dane osobowe'],
    contact: ['patient>>patientDataSectionTitles>>contact', 'Dane kontaktowe'],
    address: ['patient>>patientDataSectionTitles>>address', 'Dane adresowe'],
  },
  addNew: [ 'patient>>addNew', 'Dodaj pacjenta' ],
  age: [ 'patient>>age', '{{age}} lat' ],
  fields: {
    firstName: [ 'patient>>fields>>firstName', 'Imię' ],
    lastName: [ 'patient>>fields>>lastName', 'Nazwisko' ],
    firstAndLastName: ['patient>>fields>>firstAndLastName', 'Imię i nazwisko'],
    email: [ 'patient>>fields>>email', 'E-mail' ],
    pesel: [ 'patient>>fields>>pesel', 'PESEL' ],
    gender: [ 'patient>>fields>>gender', 'Płeć' ],
    dateOfBirth: [ 'patient>>fields>>dateOfBirth', 'Data urodzenia' ],
    foreignDocument: [ 'patient>>fields>>foreignDocument', 'Nie mam numeru pesel' ],
    foreignDocumentType: [ 'patient>>fields>>foreignDocumentType', 'Typ dokumentu' ],
    foreignDocumentNumber: [ 'patient>>fields>>foreignDocumentNumber', 'Numer dokumentu' ],
    foreignDocumentCountry: [ 'patient>>fields>>foreignDocumentCountry', 'Kraj wydania dokumentu' ],
  },
  dialog: {
    addPatientTitle: [ 'patient>>dialog>>addPatientTitle', 'Dodaj pacjenta' ],
    editPatientTitle: [ 'patient>>dialog>>editPatientTitle', 'Edytuj pacjenta' ],
    actions: {
      save: [ 'patient>>dialog>>actions>>save', 'Zapisz' ],
      saveGoDashboard: [ 'patient>>dialog>>actions>>saveGoDashboard', 'Zapisz i przejdź na stronę główną' ],
      saveGoToPolicies: [ 'patient>>dialog>>actions>>saveGoToPolicies', 'Zapisz i zobacz Twoje polisy' ],
    }
  },
  documentTypes: {
    id: [ 'patient>>documentTypes>>id', 'Identyfikator osób w państwach UE i strefy Shengen' ],
    idCard: [ 'patient>>documentTypes>>idCard', 'Numer dowodu osobistego w państwach UE i strefy Shengen' ],
    drivingLicence: [ 'patient>>documentTypes>>drivingLicence', 'Numer prawa jazdy w państwach UE i strefy Shengen' ],
    passport: [ 'patient>>documentTypes>>passport', 'Paszport' ],
  },
  buttons: {
    editProfile: ['patient>>buttons>>editProfile', 'Edytuj profil'],
    changePassword: ['patient>>buttons>>changePassword', 'Zmień hasło do konta'],
    editClauses: ['patient>>buttons>>editClauses', 'Edytuj zgody'],
    editClausesNotRequired: ['patient>>buttons>>editClausesNotRequired', 'Edytuj zgody nieobowiązkowe'],
  },
  clauseNotRequiredDescription: ['patient>>clauseNotRequiredDescription', 'Tutaj możesz zarządzać zgodami opcjonalnymi...'],
  attachment: ['patient>>attachment', 'Załącznik'],
  changePicture: ['patient>>changePicture', 'Zmień zdjęcie'],
  changePictureDisabled: ['patient>>changePictureDisabled', 'Ustawienie zdjęcia profilowego będzie możliwe po zapisaniu profilu pacjenta'],
  avatar: {
    header: ['patient>>avatar>>header', 'Zdjęcie profilowe'],
    subheader: ['patient>>avatar>>subheader', 'Dodaj i edytuj swoje zdjęcie profilowe'],
    addFileLabel: ['patient>>avatar>>addFileLabel', 'Dodaj plik'],
    changeFileLabel: ['patient>>avatar>>changeFileLabel', 'Zmień plik'],
    removeFileLabel: ['patient>>avatar>>removeFileLabel', 'Usuń plik'],
    missingFile: ['patient>>avatar>>missingFile', 'Brak pliku. Dodaj plik, aby edytować'],
    incorrectExtension: ['patient>>avatar>>incorrectExtension', 'Niewłaściwy plik, upewnij się, że plik jest zdjęciem'],
    tooBig: ['patient>>avatar>>tooBig', 'Plik za duży, maksymalny rozmiar pliku to {{ maxFileSize }} MB'],
  },
  policiesAndPaymentsSection: {
    policyScopeLink: ['patient>>policiesAndPaymentsSection>>policyScopeLink', 'Pokaż zakres usług'],
    selfPaid: {
      title: ['patient>>policiesAndPaymentsSection>>selfPaid>>title', 'Płacę sam'],
      description: ['patient>>policiesAndPaymentsSection>>selfPaid>>description', 'Płatność jest realizowana poza ubezpieczeniem, zależnie od cennika wykonawcy'],
      optionsLabel: ['patient>>policiesAndPaymentsSection>>selfPaid>>optionsLabel', 'Możliwe płatności'],
      options: { atPoint: ['patient>>policiesAndPaymentsSection>>selfPaid>>options>>atPoint', 'Płatność w placówce']},
    },
    policy: {
      generalConditionsLink: ['patient>>policiesAndPaymentsSection>>policy>>generalConditionsLink', 'Ogólne warunki ubezpieczenia'],
      numberLabel: ['patient>>policiesAndPaymentsSection>>policy>>policyNumberLabel', 'Numer polisy'],
      validityTime: ['patient>>policiesAndPaymentsSection>>policy>>policyValidityTime', 'Czas trwania polisy'],
    },
    subscription: {
      generalConditionsLink: ['patient>>policiesAndPaymentsSection>>subscription>>generalConditionsLink', 'Ogólne warunki abonamentu'],
      numberLabel: ['patient>>policiesAndPaymentsSection>>subscription>>numberLabel', 'Numer abonamentu'],
      validityTime: ['patient>>policiesAndPaymentsSection>>subscription>>validityTime', 'Czas trwania abonamentu'],
      servicesLabel: ['patient>>policiesAndPaymentsSection>>subscription>>servicesLabel', 'Usługi'],
    }
  },
};

export default patientMessages;
