import axios from 'axios';

import type { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import parseServicesProfileForBE from './parsers/parseServicesProfileForBE/parseServicesProfileForBE';
import parseServicesProfileForFE from './parsers/parseServicesProfileForFE/parseServicesProfileForFE';
import parseServicesProfileListForFE from './parsers/parseServicesProfileListForFE/parseServicesProfileListForFE';
import type { ServicesProfile, ServicesProfileDTO, ServicesProfileInput } from './servicesProfile.types';

export const endpoint = '/api/v1/patient-profile-groups/';

type GetServicesProfilesListParams = { patientId: number; payers: string[] };
type GetServicesProfileParams = { patientId: number; profileId: string | number; payers: string[] };
type GetServicesProfileListQuery = () => Promise<ServicesProfile[]>;
type GetServicesProfileQuery = () => Promise<ServicesProfile | null>;
type CreateServicesProfileMutation = (data: ServicesProfileInput) => Promise<unknown>;
type RemoveServicesProfileMutation = (id: number) => Promise<unknown>;

const servicesProfile = {
  getServicesProfilesList:
    ({ patientId, payers }: GetServicesProfilesListParams): GetServicesProfileListQuery =>
    async () => {
      const { data } = await axios.get<GetServicesProfileListQuery, PaginatedApiResponse<ServicesProfileDTO>>(
        createUrlWithParams({ url: endpoint, params: { patient_id: patientId, payers } }),
      );
      return parseServicesProfileListForFE(data);
    },
  getServicesProfile:
    ({ patientId, profileId, payers }: GetServicesProfileParams): GetServicesProfileQuery =>
    async () => {
      try {
        const { data } = await axios.get<GetServicesProfileQuery, DefaultApiResponse<ServicesProfileDTO>>(
          createUrlWithParams({ url: `${endpoint}${profileId}/`, params: { patient_id: patientId, payers } }),
        );
        return parseServicesProfileForFE(data);
      } catch (err: unknown) {
        const errorStatus = (err as { response: { status: number } }).response.status;
        if (errorStatus !== 404) {
          console.error('An error occurred - other than 404');
        }
        return null;
      }
    },
  createServicesProfile:
    (patientId: number): CreateServicesProfileMutation =>
    (inputData: ServicesProfileInput) =>
      axios.post<CreateServicesProfileMutation, DefaultApiResponse<ServicesProfileDTO>>(
        createUrlWithParams({ url: endpoint, params: { patient_id: patientId } }),
        parseServicesProfileForBE(inputData),
      ),
  removeServicesProfile:
    (patientId: number): RemoveServicesProfileMutation =>
    id =>
      axios.delete<RemoveServicesProfileMutation, DefaultApiResponse<ServicesProfileDTO>>(
        createUrlWithParams({ url: `${endpoint}${id}/`, params: { patient_id: patientId } }),
      ),
};
export default servicesProfile;
