import { FC, memo, useMemo } from 'react';

import { Download as DownloadIcon, Search as OpenIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import patientRelatedFiles from 'api/patientRelatedFiles/patientRelatedFiles';
import { PatientRelatedFile } from 'api/patientRelatedFiles/patientRelatedFiles.types';
import CancelButton from 'components/UI/molecules/BottomFixedContainer/_components/CancelButton/CancelButton';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import theme from 'styles/theme';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

type Props = {
  isOpen: boolean;
  close: () => void;
  patientId: number;
};

const ProfessionalAlarmMedicalFormDialog: FC<Props> = ({ isOpen, close, patientId }) => {
  const { t } = useTranslation();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_PATIENT_RELATED_FILES, patientId], [patientId]);
  const { data: filesData } = useQuery(queryKey, patientRelatedFiles.getPatientRelatedFiles(patientId, 'HEALTH_SURVEY'), {
    refetchOnWindowFocus: false,
  });

  const columnHelper = createColumnHelper<PatientRelatedFile>();
  const columns = [
    columnHelper.accessor('fileName', {
      header: t(proECareAlarmMessages.file.name) as string,
      id: 'fileName',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('description', {
      header: t(proECareAlarmMessages.file.description) as string,
      id: 'description',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('updatedAt', {
      header: t(proECareAlarmMessages.file.updatedAt) as string,
      id: 'updatedAt',
      cell: info => unknownDateToString(info.getValue(), DATE_FORMATS.DISPLAY),
    }),
    columnHelper.accessor('fileUrl', {
      header: '',
      id: 'fileUrl',
      cell: memo((info: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <a download={info.getValue()} href={info.getValue()} rel='noreferrer' target='_blank'>
            <DownloadIcon />
          </a>
          <a href={info.getValue()} rel='noreferrer' target='_blank'>
            <OpenIcon />
          </a>
        </Box>
      )),
    }),
  ];
  const config = { pageSize: 10 };
  return (
    <DialogWrapper
      close={close}
      customActions={<CancelButton action={close} label={t(generalMessages.close)} />}
      dialogProps={{
        maxWidth: 'sm',
      }}
      header={t(proECareAlarmMessages.alarmCard.dialogs.medicalForm)}
      isOpen={isOpen}
    >
      {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*   @ts-ignore */}
      <CustomTable<PatientRelatedFile> columns={columns} config={config} data={filesData} />
    </DialogWrapper>
  );
};

export default ProfessionalAlarmMedicalFormDialog;
