import type { FC } from 'react';

import { Typography } from '@mui/material';

import useStyles from './SummaryListNoItems.styles';

export interface Props {
  textLabel: string;
  testId?: string;
}

const SummaryListNoItems: FC<Props> = ({ textLabel, testId }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root} data-testid={testId}>
      <Typography component='p' variant='caption'>
        {textLabel}
      </Typography>
    </div>
  );
};

export default SummaryListNoItems;
