import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  button: {
    minWidth: '140px',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
      alignSelf: 'flex-end',
    },
  },
}));

export default useStyles;
