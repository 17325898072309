import type { Payer } from 'api/payers/types';
import type { Payers } from 'storages/payerStorage/_payerChoiceStorageBase';

interface Params {
  data: Payer[] | null;
  selectedPayers: Payers;
  selfPaymentLabel: string;
}

export const populateDropdownSelectedValuesWithPayers = ({ data, selectedPayers, selfPaymentLabel }: Params) => {
  if (!data || !Object.values(selectedPayers).length) return [];

  const dropdownSelectedValues = Object.entries(selectedPayers).reduce((acc, [selectedPayerId, isChecked]) => {
    const selectedPayerData = data.find(payerDataItem => `${payerDataItem.id}` === selectedPayerId);

    const companyPayerLabel = selectedPayerData?.company?.name ?? selectedPayerData?.code ?? '';
    const selectedPayerLabel = selectedPayerData?.isSelfPayer ? selfPaymentLabel : companyPayerLabel;

    if (isChecked) acc.push(selectedPayerLabel);
    return acc;
  }, [] as string[]);

  return dropdownSelectedValues;
};
