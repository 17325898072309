import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ gridArea?: string }>()((theme, { gridArea }) => ({
  input: {
    gridArea: gridArea || 'unset',
    display: 'grid',
    gridGap: theme.spacing(3),
  },
}));

export default useStyles;
