import { FC, useState } from 'react';

import { Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareDevices from 'api/eCareDevices/eCareDevices';
import { ECareDevice } from 'api/eCareDevices/eCareDevices.types';
import eCareTechnicalIssues from 'api/eCareTechnicalIssues/eCareTechnicalIssues';
import { ECareTechnicalIssuesCreateInput } from 'api/eCareTechnicalIssues/eCareTechnicalIssues.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputAsyncAutocomplete from 'components/UI/organisms/_formInputs/FormInputAsyncAutocomplete/FormInputAsyncAutocomplete';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import { DropdownOption } from 'constants/_types/DropdownOptions';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

export type Props = {
  close: () => void;
  isOpen: boolean;
};

type FormInput = Omit<ECareTechnicalIssuesCreateInput, 'deviceId'> & {
  device: DropdownOption | null;
};

const formId = 'TechnicalIssueCreateDialog_form';

const TechnicalIssueCreateDialog: FC<Props> = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useShowSnackbar();
  const queryClient = useQueryClient();
  const { control, handleSubmit, formState, setError } = useForm<FormInput>({
    defaultValues: {
      device: null,
      date: new Date(),
      description: '',
      fixDate: null,
      fixDescription: null,
    },
  });

  const [generalErrors, setGeneralErrors] = useState<string[]>();

  const onError = useOnBackendFormError<FormInput, ECareTechnicalIssuesCreateInput>({ setError, setGeneralErrors });

  const createMutation = useMutation('Create technical issue', eCareTechnicalIssues.createECareTechnicalIssue(), {
    onError,
  });

  const onSubmit: SubmitHandler<FormInput> = async formInput => {
    const { device, ...input } = formInput;
    await createMutation.mutateAsync({
      // it's safe to assume that device is not null here, react-hook-form will not allow to submit form with empty device
      deviceId: device?.value as number,
      ...input,
    });
    queryClient.invalidateQueries(QUERY_KEYS.GET_ECARE_TECHNICAL_ISSUES);
    showSnackbar({ variant: 'success', translationArray: snackbarMessages.success });
    close();
  };

  const optionsGetter = async (searchPhrase: string): Promise<DropdownOption[]> => {
    const { data } = await queryClient.fetchQuery([QUERY_KEYS.GET_ECARE_DEVICES, searchPhrase], () =>
      eCareDevices.getAllDevices()({
        limit: 100,
        sort: { key: 'createdAt', direction: 'asc' },
        filters: [{ key: 'serial_number', value: searchPhrase }],
        offset: 0,
      }),
    );

    return (data as ECareDevice[]).map(device => ({
      label: `${device.name} - ${device.serialNumber}`,
      value: device.id,
    }));
  };

  return (
    <DialogWrapper
      close={close}
      defaultActionsConfig={{
        isLoading: createMutation.isLoading,
        okLabel: t(generalMessages.save),
        cancelLabel: t(generalMessages.cancel),
        formId,
        onClose: close,
      }}
      dialogProps={{
        maxWidth: 'xs',
      }}
      header={t(proECareTechnicalIssuesMessages.createDialogTitle)}
      isOpen={isOpen}
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <SimpleFormWrapper formState={formState} globalErrors={generalErrors}>
          <FormInputAsyncAutocomplete
            control={control}
            hintText={t(proECareTechnicalIssuesMessages.newIssue.searchHint)}
            label={t(proECareTechnicalIssuesMessages.issueDetailLabel.device)}
            name='device'
            optionsGetter={optionsGetter}
            required
          />
          <FormInputDate control={control} label={t(proECareTechnicalIssuesMessages.issueDetailLabel.date)} name='date' required />
          <FormInputText
            control={control}
            inputProps={{ multiline: true }}
            label={t(proECareTechnicalIssuesMessages.issueDetailLabel.description)}
            multiline
            name='description'
            required
            rows={3}
          />
          <FormInputDate control={control} label={t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDate)} name='fixDate' />
          <FormInputText
            control={control}
            label={t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDescription)}
            multiline
            name='fixDescription'
            rows={3}
          />
          <Typography variant='body1'>{t(proECareTechnicalIssuesMessages.form.description)}</Typography>
        </SimpleFormWrapper>
      </form>
    </DialogWrapper>
  );
};

export default TechnicalIssueCreateDialog;
