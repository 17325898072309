import axios from 'axios';

import type { Consent, DefaultApiResponse } from './_types';

const endpoints = {
  consents: 'api/v1/consent/',
};

export type CreateConsentInput = {
  user_account: number | null;
  clause: number;
  patient?: number;
  declined: boolean;
  location: number | undefined;
  ordering: number | null;
};

export type CreateConsentOutput = {
  id: number;
  user_account: number;
  clause: number;
  patient?: number;
  declined: boolean;
  declined_at: string;
};

export type DeclineConsentInput = { id: number; declined: boolean };

export type DeclineConsentOutput = { id: number; declined: boolean };

type GetConsentsQuery = () => Promise<DefaultApiResponse<Consent[]>>;
type CreateConsentQuery = (data: CreateConsentInput[]) => Promise<DefaultApiResponse<CreateConsentOutput>>;
type DeclineConsentQuery = (data: DeclineConsentInput) => Promise<DefaultApiResponse<DeclineConsentOutput>>;

const consents = {
  getConsents: (): GetConsentsQuery => () => axios.get<GetConsentsQuery, DefaultApiResponse<Consent[]>>(endpoints.consents),
  createConsent: (): CreateConsentQuery => data =>
    axios.post<CreateConsentQuery, DefaultApiResponse<CreateConsentOutput>>(endpoints.consents, data),
  declineConsent:
    (): DeclineConsentQuery =>
    ({ id, declined }) =>
      axios.patch<DeclineConsentQuery, DefaultApiResponse<DeclineConsentOutput>>(`${endpoints.consents}${id}/`, { declined }),
};

export default consents;
