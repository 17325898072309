import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useGenerateHealthDetails from './_hooks/useGenerateHealthDetails/useGenerateHealthDetails';
import useGenerateOrders from './_hooks/useGenerateOrders/useGenerateOrders';
import useGeneratePersons from './_hooks/useGeneratePersons/useGeneratePersons';
import useStyles from './ProfessionalECareOrderInfo.styles';

type Props = {
  data: ECareOrder;
};

const ProfessionalECareOrderInfo: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  // Prepare data for columns
  const orderData = useGenerateOrders(data);
  const cusRecipientData = useGeneratePersons(data);
  const healthData = useGenerateHealthDetails(data);

  return (
    <CardContentContainer>
      <div className={classes.container}>
        {/* Order data */}
        <div className={classes.borderRight}>
          <SectionWithTitle title={t(proECareOrderMessages.columns.eCareData)}>
            <DefinitionList columns={2} customClasses={{ root: classes.uneven }} definitions={orderData.section1} fontSize='small' />
            <DefinitionList columns={3} definitions={orderData.section2} fontSize='small' />
            <DefinitionList columns={2} customClasses={{ root: classes.uneven }} definitions={orderData.section3} fontSize='small' />
          </SectionWithTitle>
        </div>
        {/* Personal data */}
        <div className={classes.borderRight}>
          <SectionWithTitle title={t(proECareOrderMessages.columns.cusPersonalData)}>
            <DefinitionList columns={3} definitions={cusRecipientData.section1} fontSize='small' />
            <DefinitionList columns={3} definitions={cusRecipientData.section2} fontSize='small' />
          </SectionWithTitle>
        </div>
        {/* Information Card */}
        <div>
          <SectionWithTitle title={t(proECareOrderMessages.columns.infoCard)}>
            <DefinitionList columns={2} definitions={healthData.section1} fontSize='small' />
            <DefinitionList columns={2} definitions={healthData.section2} fontSize='small' />
            <DefinitionList columns={2} definitions={healthData.section3} fontSize='small' />
          </SectionWithTitle>
        </div>
      </div>
    </CardContentContainer>
  );
};

export default ProfessionalECareOrderInfo;
