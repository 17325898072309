import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useDifferenceBetweenDates from 'hooks/useDifferenceBetweenDates/useDifferenceBetweenDates';
import timerMessages from 'translations/specific/timer.mjs';

import useStyles from './Timer.styles';

export const testId = createTestIdObject('Timer', {
  wrapper: 'wrapper',
  timer: 'timer',
  label: 'label',
});

export type Props = {
  date: Date;
  stopDate?: Date | null;
  statusColorClass?: string;
  labelElement?: string | JSX.Element;
  isInterval?: boolean;
};

const Timer: FC<Props> = ({ date, stopDate, labelElement, isInterval = false, statusColorClass }) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles({ hasInterval: isInterval });
  const timerDate = useDifferenceBetweenDates({ date, stopDate, isInterval });

  const numberFormat = (number: string | number) => (number > 10 ? number : `0${number}`.slice(-2));
  const generateDaysMessage = (numberOfDays: number) => (numberOfDays > 1 ? t(timerMessages.days) : t(timerMessages.day));

  const timerFormat = () => (
    <>
      {timerDate.days && timerDate.days > 0 ? `${timerDate.days} ${generateDaysMessage(timerDate.days)} ` : ''}
      {timerDate.hours ? `${numberFormat(timerDate.hours)}:` : '00:'}
      {timerDate.minutes ? `${numberFormat(timerDate.minutes)}:` : '00:'}
      {timerDate.seconds ? `${numberFormat(timerDate.seconds)}` : '00'}
    </>
  );

  return (
    <div className={cx(classes.container, statusColorClass)} data-testid={testId.wrapper}>
      <span data-testid={testId.timer}>{timerFormat()}</span>
      {labelElement && (
        <span className={classes.label} data-testid={testId.label}>
          {labelElement}
        </span>
      )}
    </div>
  );
};

export default Timer;
