import type { FC } from 'react';

import { FormControl, FormControlLabel, Switch } from '@mui/material';

import useStyles from './InputSwitch.styles';

export interface Props {
  label: string | JSX.Element;
  required: boolean;
  disabled?: boolean;
  value?: boolean;
}

const InputSwitch: FC<Props> = ({ label, required, ...field }) => {
  const { classes } = useStyles();

  return (
    <div>
      <FormControl className={classes.root}>
        <FormControlLabel control={<Switch checked={field.value} color='primary' required={required} {...field} />} label={label} />
      </FormControl>
    </div>
  );
};

export default InputSwitch;
