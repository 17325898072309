import type { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import NoClausesToShow from 'components/UI/atoms/NoClausesToShow/NoClausesToShow';
import ClauseAttachmentSection from 'components/UI/molecules/ClauseAttachmentSection/ClauseAttachmentSection';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import getLabelForClause from 'services/_clauses/getLabelForClause/getLabelForClause';
import clausesMessages from 'translations/specific/clauses.mjs';

import useStyles from './ClausesForm.styles';

type Props = {
  clausesToRender: ClauseWithConsent[];
  control: Control<any>;
  showAcceptDate: boolean;
};

const ClausesForm: FC<Props> = ({ clausesToRender, control, showAcceptDate }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (!clausesToRender?.length) return <NoClausesToShow />;

  return (
    <Grid container>
      {clausesToRender.map(clause => (
        <Grid item key={clause.clauseId} xs={12}>
          <FormInputCheckbox
            control={control}
            hideAsterisk
            inputProps={{ inlineError: true }}
            label={
              <div>
                <Typography className={classes.label}>
                  {getLabelForClause(clause.text, { hideLink: true, isObligatory: clause.isObligatory })}
                  {showAcceptDate && clause.updatedAt && (
                    <Typography component='p' variant='caption'>
                      {t(clausesMessages.acceptDate, { acceptDate: unknownDateToString(clause.updatedAt, DATE_FORMATS.DISPLAY_TIME) })}
                    </Typography>
                  )}
                </Typography>
                {clause.hasFile && <ClauseAttachmentSection clause={clause} withLeftPadding />}
              </div>
            }
            name={`${clause.clauseId}`}
            required={clause.isObligatory}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ClausesForm;
