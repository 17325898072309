import { makeStyles } from 'tss-react/mui';

import { INPUT_HEIGHT } from 'constants/styles/INPUT_HEIGHT';

const useStyles = makeStyles<{ error: boolean }>()((theme, { error }) => ({
  input: {
    width: '100% !important',
    padding: '13px 13px 13px 58px !important',
    minHeight: INPUT_HEIGHT,
    fontSize: '0.8125rem !important',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,

    '&:hover': {
      border: `1px solid #000 !important`,
    },
    '&:focus': {
      border: `1px solid ${error ? theme.palette.error.main : theme.palette.secondary.main} !important`,
    },

    border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[400]} !important`,
  },
  container: {
    '& .special-label': {
      fontSize: '0.7rem',
      color: `${error ? theme.palette.error.main : theme.palette.grey[500]} !important`,
      left: '4px !important',
      top: '-16px !important',
      padding: 0,
    },

    '& .selected-flag .arrow': {
      top: '55%',
    },
  },
}));

export default useStyles;
