import create from 'zustand';

import type { SummaryListGroup } from 'constants/_types/SummaryList';

import { RESULTS_NUMBER_TO_SHOW_CLARIFICATION_TEXT } from '../components/UI/organisms/GlobalSearchBar/constants/resultsNumberToShowClarificationText';
import { validateSearchPhrase } from '../services/validateSearchPhrase/validateSearchPhrase';

export type DataState = 'PRISTINE' | 'LOADING' | 'TOO_SHORT_PHRASE' | 'NO_RESULTS' | 'RESULTS_FETCHED_SUCCESSFULLY' | 'NEEDS_CLARIFICATION';

export type GlobalSearchStorage = {
  globalSearchIsOpen: boolean;
  hideGlobalSearch: () => void;
  showGlobalSearch: () => void;

  // data storage TODO split it to slices after update zustand
  searchPhrase: string;
  updateSearchPhrase: (newPhrase: string) => void;
  dataState: DataState;
  setDataState: (newDataState: DataState) => void;
  searchResults: SummaryListGroup | null;
  setSearchResults: (newSearchResults: SummaryListGroup | null) => void;
};

const useGlobalSearchStorage = create<GlobalSearchStorage>(set => ({
  globalSearchIsOpen: false,
  hideGlobalSearch: () => set({ globalSearchIsOpen: false }),
  showGlobalSearch: () => set({ globalSearchIsOpen: true }),

  // data storage
  searchPhrase: '',
  updateSearchPhrase: newPhrase => {
    let dataState: DataState = 'PRISTINE';
    if (newPhrase.length > 0) {
      dataState = validateSearchPhrase(newPhrase) ? 'LOADING' : 'TOO_SHORT_PHRASE';
    }
    set({ searchPhrase: newPhrase, dataState });
  },
  dataState: 'PRISTINE',
  setDataState: newDataState => set({ dataState: newDataState }),
  searchResults: null,
  setSearchResults: newSearchResults => {
    let dataState: DataState = 'NO_RESULTS';
    const resultsLength = Object.values(newSearchResults || {}).reduce((acc, curr) => acc + (curr?.items?.length || 0), 0);
    if (newSearchResults && !!resultsLength) {
      dataState = resultsLength >= RESULTS_NUMBER_TO_SHOW_CLARIFICATION_TEXT ? 'NEEDS_CLARIFICATION' : 'RESULTS_FETCHED_SUCCESSFULLY';
    }

    set({ searchResults: newSearchResults, dataState });
  },
}));

export default useGlobalSearchStorage;
