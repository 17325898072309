import type { FC } from 'react';

import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ContactInfo } from 'constants/_types/ContactInfo';
import helpMessages from 'translations/specific/help.mjs';

import useStyles from './ContactTile.styles';

export interface Props {
  hasInternalBranding: boolean;
  header: string;
  info: ContactInfo;
}

const ContactTile: FC<Props> = ({ hasInternalBranding, header, info: { address, email, logoAlt, logoSrc, phoneNumber } }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Grid className={classes.root} container item md={hasInternalBranding ? 6 : 12} spacing={2} wrap='nowrap' xs={12}>
      <Grid className={classes.contactBox} item xs={12}>
        <Typography variant='subtitle3'>{header}</Typography>
        {logoSrc ? (
          <img alt={logoAlt} className={classes.image} src={logoSrc} />
        ) : (
          <Typography component='p' variant='captionBold'>
            {logoAlt}
          </Typography>
        )}
        <div>
          <Typography color={theme.palette.grey[500]} variant='captionBold'>
            {t(helpMessages.hotline)}
          </Typography>
          <Typography
            className={classes.phoneNumber}
            color={theme.palette.primary.main}
            component='a'
            href={`tel:${phoneNumber}`}
            variant='subtitle1'
          >
            {phoneNumber}
          </Typography>
        </div>
        <Typography component='p' variant='captionBold'>
          {address}
        </Typography>
        <Typography className={classes.email} component='a' href={`mailto:${email}`} variant='captionBold'>
          {email}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContactTile;
