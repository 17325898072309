import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ProfessionalAlarmMedicalFormDialog from 'components/UI/organisms/_dialogs/ProfessionalAlarmMedicalFormDialog/ProfessionalAlarmMedicalFormDialog';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './MedicalFormButtonModal.styles';

const MedicalFormButtonModal = (patientId: number | null | undefined) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isOpenDialog, openDialog, closeDialog] = useBooleanState(false);

  return (
    <>
      <Button className={classes.patientButton} disabled={!patientId} variant='outlined' onClick={openDialog}>
        {t(proECareAlarmMessages.alarmCard.buttons.medicalForm)}
      </Button>
      {patientId && <ProfessionalAlarmMedicalFormDialog close={closeDialog} isOpen={isOpenDialog} patientId={patientId} />}
    </>
  );
};

export default MedicalFormButtonModal;
