import type { Dispatch, MouseEvent, SetStateAction } from 'react';

import { useMutation, useQueryClient, type QueryKey } from 'react-query';

import reports from 'api/reports';

interface HookParams {
  id: number;
  queryKey: QueryKey;
  handleMenuClose?: (e?: MouseEvent<HTMLElement>) => void;
  setButtonsState: Dispatch<
    SetStateAction<{
      isMedicalRecord: boolean;
      isHidden: boolean;
    }>
  >;
}

export const useReportRowMutation = ({ id, queryKey, handleMenuClose, setButtonsState }: HookParams) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(reports.updateReport(id), {
    onMutate: async mutationInput => {
      setButtonsState({
        isMedicalRecord: mutationInput.is_medical_record as boolean,
        isHidden: mutationInput.is_hidden as boolean,
      });
    },
    onSettled: async () => {
      handleMenuClose?.();
      await queryClient.invalidateQueries(queryKey);
    },
  });

  return mutation;
};
