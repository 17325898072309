import type { FC } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';

import TruncatedTextWithTooltip from 'components/UI/atoms/TruncatedTextWithTooltip/TruncatedTextWithTooltip';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';

import useStyles from './UploadedReferral.styles';

export const testId = createTestIdObject('UploadedReferral', {
  removeFile: 'removeFile',
});

export interface Props {
  filename: string;
  handleRemove: () => void;
}

const UploadedReferral: FC<Props> = ({ filename, handleRemove }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <TruncatedTextWithTooltip text={filename} />
      <IconButton className={classes.removeButton} data-testid={testId.removeFile} size='small' onClick={handleRemove}>
        <CloseOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default UploadedReferral;
