import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  searchButton: {
    borderRadius: '50%',
    background: theme.palette.background.paper,
  },

  searchIcon: {
    '& path': {
      fill: theme.palette.grey[400],
    },
  },
}));

export default useStyles;
