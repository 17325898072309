import type { BreakpointKeys } from 'constants/_types/BreakpointKeys';

const breakpoints: { values: Record<BreakpointKeys, number> } = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440, // <-- custom
    xxl: 1536, // <-- renamed (xl --> xxl)
    searchBar: 1010,
  },
};

export default breakpoints;
