import { resolvePostalCodePattern } from 'services/resolvePostalCodePattern/resolvePostalCodePattern';

interface Params {
  currentValue?: string;
  currentCountryValue: string;
  errorMessage: string;
}

export const validatePostalCode = ({ currentValue, currentCountryValue, errorMessage }: Params) => {
  const currentPostalCodePatterns = resolvePostalCodePattern({ selectedCountryOptionValue: currentCountryValue });

  const patternsRequiredAndValueDoesNotMatch = currentPostalCodePatterns && !currentValue?.match(currentPostalCodePatterns.rulePattern);

  if (patternsRequiredAndValueDoesNotMatch) return errorMessage;
  return true;
};
