import { makeStyles } from 'tss-react/mui';

import type { DirectionConfig } from 'services/resolveTransform/resolveTransform';
import { resolveTransform } from 'services/resolveTransform/resolveTransform';

const useStyles = makeStyles<{
  direction: DirectionConfig;
  isAlternated?: boolean;
}>()((theme, { direction, isAlternated }) => ({
  root: {
    transform: resolveTransform(direction, isAlternated),
    transformOrigin: 'center',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default useStyles;
