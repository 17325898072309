import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import PATHS from 'constants/router/PATHS';
import useServiceRequestsMutation from 'hooks/_mutations/useServiceRequestsMutation/useServiceRequestsMutation';
import useLoading from 'hooks/useLoading/useLoading';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import useUploadAttachments from 'hooks/useUploadAttachments/useUploadAttachments';
import { useServicesBasketStorage } from 'storages/serviceBasketStorage/servicesBasketStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { combineSubmissionErrors } from './_services/combineSubmissionErrors/combineSubmissionErrors';
import { gatherServicesAttachments } from './_services/gatherServicesAttachments/gatherServicesAttachments';
import { proceedOrderServiceFormSubmission } from './_services/proceedOrderServiceFormSubmission/proceedOrderServiceFormSubmission';

export const useOrderServiceFormSubmission = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { basketState, clearBasket, getBasketInfo } = useServicesBasketStorage();
  const { form } = useOrderServiceFormContext();
  const loading = useLoading();
  const { showSnackbar } = useShowSnackbar();

  const paramOrigin = useMemo(() => searchParams.get('origin') as OrderServiceOrigins, [searchParams]);

  const handleOnSuccess = useCallback(() => {
    const { selectedServicesNumber } = getBasketInfo();
    navigate(PATHS.ROOT, { state: { origin: paramOrigin, servicesNumber: selectedServicesNumber } });
    form.reset();
    clearBasket();
  }, [basketState]);

  const handleOnSettled = useCallback(() => {
    loading.end();
  }, []);

  const { uploadAttachments, uploadAttachmentErrors } = useUploadAttachments({
    mutationKey: 'Upload service referral attachment',
    onError: loading.end,
  });

  const { serviceRequestsMutateAsync, serviceRequestsMutateGeneralErrors } = useServiceRequestsMutation({
    paramOrigin,
    onSuccess: handleOnSuccess,
    onSettled: handleOnSettled,
    setError: form.setError,
  });

  const handleOnSubmit = useCallback(async () => {
    loading.start();
    const attachmentsData = gatherServicesAttachments(form.getValues().details);

    const uploadPromises = attachmentsData.map(async ({ file, kindKey, serviceItemUnderscoreId }) => {
      const attachmentId = await uploadAttachments([file]);
      form.setValue(`details.${kindKey}.serviceItems.${serviceItemUnderscoreId}.attachmentId`, attachmentId as never);

      return attachmentId;
    });

    const receivedAttachmentIds = await Promise.all(uploadPromises);

    proceedOrderServiceFormSubmission({
      form,
      receivedAttachmentIds: receivedAttachmentIds.flat(),
      serviceRequestsMutateAsync,
      abortedProcessCallback: () => {
        loading.end();
        showSnackbar({
          variant: 'error',
          translationArray: t(orderServiceMessages.submission.failure.attachments),
        });
      },
    });
  }, []);

  const submissionErrors = useMemo(
    () =>
      combineSubmissionErrors({
        mainError: t(orderServiceMessages.submission.failure.main),
        uploadingAttachmentErrors: uploadAttachmentErrors,
        formSubmissionErrors: serviceRequestsMutateGeneralErrors,
      }),
    [serviceRequestsMutateGeneralErrors, uploadAttachmentErrors],
  );

  return { handleOnSubmit, isSubmitting: loading.state, submissionErrors };
};
