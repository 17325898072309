import { makeStyles } from 'tss-react/mui';

import tableRowStylesFactory from 'styles/helpers/tableRowStylesFactory';

const useStyles = makeStyles()(theme => ({
  dropColor: tableRowStylesFactory(theme, theme.palette.table.received),
  completeColor: tableRowStylesFactory(theme, theme.palette.table.success),
  receivedColor: tableRowStylesFactory(theme, theme.palette.table.warning),
  missedColor: tableRowStylesFactory(theme, theme.palette.table.danger),
  noDataColor: tableRowStylesFactory(theme, theme.palette.table.default),
}));

export default useStyles;
