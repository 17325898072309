import type { FC } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, DialogActions, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import type { DialogActionsProps } from 'components/UI/molecules/DialogWrapper/DialogWrapper';

import useStyles from './DialogActionsResolver.styles';

const DialogActionsResolver: FC<DialogActionsProps> = ({ customActions, defaultActionsConfig, actionsConfig, actionsDescription }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { classes } = useStyles({ buttonsNumber: actionsConfig?.length || 2 });

  if (customActions) return <DialogActions>{customActions}</DialogActions>;
  if (defaultActionsConfig) {
    return (
      <DialogActions>
        <div className={classes.actions}>
          <Button
            disabled={defaultActionsConfig.isLoading || defaultActionsConfig.isDisabled}
            size={isMobile ? 'small' : 'medium'}
            variant='outlined'
            onClick={defaultActionsConfig.onClose}
          >
            {defaultActionsConfig.cancelLabel}
          </Button>
          <LoadingButton
            disabled={defaultActionsConfig.isDisabled || defaultActionsConfig.isOkDisabled}
            form={defaultActionsConfig.formId}
            loading={defaultActionsConfig.isLoading}
            size={isMobile ? 'small' : 'medium'}
            type='submit'
            variant='contained'
          >
            {defaultActionsConfig.okLabel}
          </LoadingButton>
        </div>
      </DialogActions>
    );
  }
  if (actionsConfig) {
    return (
      <>
        {actionsDescription && (
          <Typography className={classes.actionsDescription} component='p' variant='caption'>
            {actionsDescription}
          </Typography>
        )}
        <DialogActions>
          <div className={classes.actions}>
            {actionsConfig.map(({ label, isLoadingType, ...buttonProps }) => {
              const UnifiedButton = isLoadingType ? LoadingButton : Button;
              return (
                <UnifiedButton key={label} size={isMobile ? 'small' : 'medium'} {...buttonProps}>
                  {label}
                </UnifiedButton>
              );
            })}
          </div>
        </DialogActions>
      </>
    );
  }
  return null;
};

export default DialogActionsResolver;
