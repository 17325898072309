import { useTranslation } from 'react-i18next';

import { ECareTechnicalIssues } from 'api/eCareTechnicalIssues/eCareTechnicalIssues.types';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

const useDeviceData = (data: ECareTechnicalIssues) => {
  const { t } = useTranslation();

  return [
    [
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.name),
        value: data.device.name || t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.deviceType),
        value: data.device.deviceType?.name || t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.dateFrom),
        value: unknownDateToString(data.device.dateFrom, DATE_FORMATS.DISPLAY) || t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.dateTo),
        value: unknownDateToString(data.device.dateTo, DATE_FORMATS.DISPLAY) || t(generalMessages.noData),
      },
    ],
    [
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.serialNumber),
        value: data.device.serialNumber || t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.simNumber),
        value: data.device.simNumber || t(generalMessages.noData),
      },
    ],
    [
      {
        label: t(proECareTechnicalIssuesMessages.deviceElementLabel.description),
        value: data.device.description || t(generalMessages.noData),
      },
    ],
  ];
};

export default useDeviceData;
