import { useEffect } from 'react';

export const DEFAULT_TIMEOUT = 5000 * 1.5; // 5000 by default

interface HookParams {
  trigger: boolean;
  time?: number;
  action: () => void;
}

export const useTimeout = ({ trigger, time = DEFAULT_TIMEOUT, action }: HookParams) => {
  useEffect(() => {
    if (!trigger) return;

    const timeoutId = setTimeout(() => {
      action();
    }, time);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
};
