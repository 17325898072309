import { FC, useMemo } from 'react';

import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import eCareOrder from 'api/eCareOrder/eCareOrder';
import eCarePhoneCall from 'api/eCarePhoneCall/eCarePhoneCall';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import queryKeys from 'constants/queryKeys/queryKeys';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

import InputDropdown from '../InputDropdown/InputDropdown';

export type Props = {
  currentOrderId?: string;
  serviceRecipient?: string;
  callDate?: Date;
  queryKey: QueryKey;
  phoneCallId: string;
  isEnabled?: boolean;
};

const ECarePhoneCallECareOrderDropdown: FC<Props> = ({
  serviceRecipient,
  callDate,
  currentOrderId,
  queryKey,
  phoneCallId,
  isEnabled: isEnabledProps,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useShowSnackbar();

  const queryClient = useQueryClient();

  const mutation = useMutation(eCarePhoneCall.patchEcarePhoneCall(phoneCallId), {
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { isLoading, data } = useQuery(
    [queryKeys.GET_ECARE_ORDERS_PER_CALL, callDate?.toString(), serviceRecipient],
    // not null assertion is used because the query is enabled only when both values are present
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => eCareOrder.getAllOrdersForECareCall({ callDate: callDate!, serviceRecipient: serviceRecipient! }),
    {
      enabled: !!serviceRecipient && !!callDate,
    },
  );

  const isEnabled = useMemo(() => {
    if (isLoading && mutation.isLoading) return false;
    if (data && data.length <= 1) return false;
    if (!isEnabledProps) return false;

    return true;
  }, [isLoading, data, isEnabledProps, mutation.isLoading]);

  const onChange = ({ target }: SelectChangeEvent<unknown>) => {
    mutation.mutate({ order: target.value as string });
    showSnackbar({
      variant: 'success',
      translationArray: ecarePhoneCallMessages.eCareOrderDropdown.changeSuccess,
    });
  };

  return (
    <div>
      <Typography component='label' variant='body2'>
        {t(ecarePhoneCallMessages.eCareOrderDropdown.label)}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <InputDropdown disabled={!isEnabled} fullWidth options={data || []} value={currentOrderId || ''} onChange={onChange} />
        <InfoIconWithTooltip
          content={
            isEnabled ? t(ecarePhoneCallMessages.eCareOrderDropdown.infoEnabled) : t(ecarePhoneCallMessages.eCareOrderDropdown.infoDisabled)
          }
        />
      </Box>
    </div>
  );
};

export default ECarePhoneCallECareOrderDropdown;
