import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  tabsContainer: {
    width: '100%',
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1, 0, 1),
    },
  },
}));

export default useStyles;
