import type { FC } from 'react';

import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import type { Basket } from 'constants/_types/Basket';
import servicesMessages from 'translations/specific/services.mjs';

import useSaveServicesProfile from './_hooks/useSaveServicesProfile/useSaveServicesProfile';

export type Props = {
  close: () => void;
  dataToSave: Basket;
  isOpen: boolean;
};

type SaveServicesProfileForm = {
  name: string;
};

const FORM_ID = 'SaveServicesProfileDialog';

const SaveServicesProfileDialog: FC<Props> = ({ close, isOpen, dataToSave }) => {
  const { t } = useTranslation();

  const { onSave, isSending } = useSaveServicesProfile({ close, dataToSave });

  const { control, handleSubmit } = useForm<SaveServicesProfileForm>({ defaultValues: { name: '' } });

  const onSubmit: SubmitHandler<SaveServicesProfileForm> = ({ name }) => {
    onSave(name);
  };

  return (
    <DialogWrapper
      close={close}
      defaultActionsConfig={{
        isLoading: isSending,
        okLabel: t(servicesMessages.saveServicesProfileDialog.okLabel),
        cancelLabel: t(servicesMessages.saveServicesProfileDialog.cancelLabel),
        formId: FORM_ID,
        onClose: close,
      }}
      dialogProps={{ maxWidth: 'sm' }}
      header={t(servicesMessages.saveServicesProfileDialog.title)}
      isOpen={isOpen}
    >
      <DialogFormWrapper id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <FormInputText control={control} label={t(servicesMessages.saveServicesProfileDialog.inputLabel)} name='name' required />
      </DialogFormWrapper>
    </DialogWrapper>
  );
};

export default SaveServicesProfileDialog;
