import { useMemo } from 'react';

import { addDays, subDays } from 'date-fns';

interface HookParams {
  dateFromValue: string;
  dateUntilValue: string;
}

export const useDateRangeRelation = ({ dateFromValue, dateUntilValue }: HookParams) =>
  useMemo(
    () => ({
      dateFromMaxDate: dateUntilValue ? subDays(new Date(dateUntilValue), 1) : undefined,
      dateUntilMinDate: addDays(new Date(dateFromValue), 1),
    }),
    [dateFromValue, dateUntilValue],
  );
