import type { ActionLog, ActionLogDTO } from './actionLogs.types';

// Body contain change type and details wrapped in [ ]
// Example: "Modified by admin panel: [{'changed': { 'fields': ['PESEL'] }}]"
const splitBody = (body: string): { changeType: string; changeDetails: string } => {
  const [_, changeType, changeDetails] = body.match(/([^:]+):\s*\[(.*)\]/) || ['', ''];

  const result: ReturnType<typeof splitBody> = {
    changeType: '',
    changeDetails: '',
  };

  if (changeType) {
    result.changeType = changeType.trim();
  } else {
    result.changeType = body.trim();
  }

  if (changeDetails) {
    try {
      result.changeDetails = changeDetails ? JSON.stringify(JSON.parse(changeDetails.replaceAll(`'`, `"`)), null, 2) : '';
    } catch (err) {
      console.error('Error while parsing changeDetails', err, changeDetails);
    }
  }

  return result;
};

export const parseActionLogForFE = (actionLogsDTO: ActionLogDTO): ActionLog => ({
  id: actionLogsDTO.id,
  additionalData: actionLogsDTO.additional_data,
  by: actionLogsDTO.email,
  date: new Date(actionLogsDTO.created_at),
  ...splitBody(actionLogsDTO.body),
});
