import { useMemo } from 'react';

import type { TFunction } from 'i18next';
import { get, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreServiceGroups from 'api/coreServiceGroups/coreServiceGroups';
import type { CoreServiceGroup } from 'api/coreServiceGroups/coreServiceGroups.types';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { ColumnsDisplaySetEntities, ColumnsDisplaySetHeader } from 'constants/_types/ColumnsDisplaySetData';
import type { DynamicTranslatableName } from 'constants/_types/DynamicTranslatableName';
import type { ServiceGroup } from 'constants/_types/ServiceGroup';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import convertStringToDynamicTranslatableName from 'services/convertStringToDynamicTranslatableName/convertStringToDynamicTranslatableName';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

type PrepareData = (t: TFunction, groups: CoreServiceGroup[] | undefined) => ServiceGroup[] | null;

const hardcodedColumnsOrdering = [
  'MdtCoreServiceGroupType>>>PREVENTION',
  'MdtCoreServiceGroupType>>>SPECIALIZATION',
  'MdtCoreServiceGroupType>>>KIND',
  'MdtCoreServiceGroupType>>>DISEASES',
];

export const prepareData: PrepareData = (t, groups) => {
  if (!groups) return null;

  type StructuredData = Record<string, Record<string, { id: number; name: DynamicTranslatableName; ordering: number }[]>>;

  const structuredData: StructuredData = {};
  groups.forEach(({ groupSection, name, id }) => {
    groupSection.forEach(({ sectionName, typeName, ordering }) => {
      const path = [sectionName.key, typeName.key];
      const prev = get(structuredData, path, []);
      set(structuredData, path, [...prev, { name, id, ordering }]);
    });
  });

  return Object.entries(structuredData).map(([key, value], index) => {
    const headers: ColumnsDisplaySetHeader[] = [];
    const entities: ColumnsDisplaySetEntities = {};

    Object.entries(value).forEach(([columnId, entitiesArray]) => {
      entities[columnId] = entitiesArray
        .map(({ id, name, ordering }) => ({
          id,
          name,
          searchableName: t(name.key, name.defaultValue),
          ordering,
        }))
        .sort((a, b) => a.ordering - b.ordering);
      headers.push({ id: columnId, name: convertStringToDynamicTranslatableName(columnId) });
      headers.sort((a, b) => hardcodedColumnsOrdering.indexOf(a.id as string) - hardcodedColumnsOrdering.indexOf(b.id as string));
    });

    return {
      title: { key, id: `${key}-${index}`, defaultValue: key },
      data: {
        headers,
        entities,
      },
    };
  });
};

const useGetServicesGroups = (enabled: boolean) => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();

  const { patient } = usePatientContext();
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());
  const { data: groups, isLoading } = useQuery(
    [QUERY_KEYS.CORE_SERVICE_GROUPS, languageCode, selectedPayersList],
    coreServiceGroups.getServicesGroup({ patientId: patient?.id, payers: selectedPayersList }),
    {
      enabled: enabled && !!patient?.id,
    },
  );

  const groupsToDisplay = useMemo(() => prepareData(t, groups), [groups]);

  return { groupsToDisplay, isLoading };
};

export default useGetServicesGroups;
