import { AlarmTypeT } from 'api/eCareAlarms/eCareAlarms.types';
import { TranslationKey } from 'constants/_types/TranslationKey';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

// WARRNING: key of this dictionary is related with names from API get from Backend
export const alarmsTypesDictionary: Record<AlarmTypeT, TranslationKey> = {
  INTERVENTION_NOT_REQUIRED: proECareAlarmMessages.sosType.interventionNotRequired,
  MISTAKE: proECareAlarmMessages.sosType.mistake,
  TEST: proECareAlarmMessages.sosType.test,
  INTERVENTION_REQUIRED: proECareAlarmMessages.sosType.interventionRequired,
};
