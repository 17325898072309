import type { FC } from 'react';

import { Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as Image404 } from 'assets/404.svg';
import { ReactComponent as DisabledIcon } from 'assets/icons/decorative/disabled.svg';
import { ReactComponent as DnaIcon } from 'assets/icons/decorative/dna.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/decorative/heart.svg';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './NotFoundContent.styles';

const NotFoundContent: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Container className={classes.root} maxWidth='sm'>
      <Image404 className={classes.image404} />
      <Typography className={classes.title} component='h1' variant='h1'>
        {t(generalMessages.pageNotFound.title)}
      </Typography>
      <Typography className={classes.subtitle} component='h2' variant='h2'>
        {t(generalMessages.pageNotFound.subtitle)}
      </Typography>
      <Button className={classes.button} component={Link} size='small' to='/' variant='contained'>
        {t(generalMessages.pageNotFound.backToHomepage)}
      </Button>
      <div className={classes.contactContainer}>
        <Typography className={classes.contactDescription} variant='body1'>
          {t(generalMessages.pageNotFound.contactDescription)}
        </Typography>
        <Typography className={classes.contactPhoneNumber} variant='body1'>
          {t(generalMessages.pageNotFound.contactPhoneNumber)}
        </Typography>
      </div>
      <DnaIcon className={classes.dnaIcon} />
      <DisabledIcon className={classes.disabledIcon} />
      <HeartIcon className={classes.heartIcon} />
    </Container>
  );
};

export default NotFoundContent;
