import type { FC } from 'react';

import { IconButton, IconButtonProps } from '@mui/material';

import useStyles from './SmallIconButton.styles';

export type Props = IconButtonProps;

const SmallIconButton: FC<Props> = ({ children, ...rest }) => {
  const { classes, cx } = useStyles();
  return (
    <IconButton disableFocusRipple disableRipple {...rest} className={cx(classes.root, rest?.className)}>
      {children}
    </IconButton>
  );
};

export default SmallIconButton;
