import type { FC } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';

import type { OrderServiceFormInput } from './_constants/inputKeys';
import { useButtonsForAllFormSteps } from './_hooks/useButtonsForAllFormSteps/useButtonsForAllFormSteps';
import { useResetPickServices } from './_hooks/useResetPickServices/useResetPickServices';
import { useServiceForm } from './_hooks/useServiceForm/useServiceForm';

interface Props {
  formMaxStep: number;
  formStep: number;
}

type OrderServiceFormContextType = {
  formStep: number;
  formMaxStep: number;
  form: UseFormReturn<OrderServiceFormInput, any>;
  handleManualResetPickServices: () => void;
  isConfirmationModalOpened: boolean;
  handleCloseConfirmationModal: () => void;
};

const OrderServiceFormContext = createContext({} as OrderServiceFormContextType);

const OrderServiceFormContextProvider: FC<Props> = ({ children, formMaxStep, formStep }) => {
  const [isConfirmationModalOpened, handleOpenConfirmationModal, handleCloseConfirmationModal] = useBooleanState();
  const { openGlobalForm, closeGlobalForm } = useBottomFixedContainerStorage();

  const { form, isValid } = useServiceForm();

  const { handleManualResetPickServices } = useResetPickServices({ form });

  const { currentButtons, mobileGridTemplateAreas } = useButtonsForAllFormSteps({
    form,
    formStep,
    isValid,
    handleOpenConfirmationModal,
  });

  useEffect(() => {
    openGlobalForm(currentButtons, mobileGridTemplateAreas);

    return closeGlobalForm;
  }, [formStep, isValid, currentButtons, mobileGridTemplateAreas]);

  const value = useMemo(
    () => ({
      formStep,
      formMaxStep,
      form,
      handleManualResetPickServices,
      isConfirmationModalOpened,
      handleCloseConfirmationModal,
    }),
    [formStep, isConfirmationModalOpened, form.getValues()],
  );

  return <OrderServiceFormContext.Provider value={value}>{children}</OrderServiceFormContext.Provider>;
};

export const useOrderServiceFormContext = (): OrderServiceFormContextType => useContext(OrderServiceFormContext);

export default OrderServiceFormContextProvider;
