import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isClickable: boolean }>()((theme, { isClickable }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: isClickable ? 'pointer' : 'auto',
    padding: isClickable ? theme.spacing(1.75, 3.75) : 'inherit',
    '&:hover': {
      backgroundColor: isClickable ? theme.palette.background.default : 'inherit',
    },
  },

  removeButton: {
    marginRight: theme.spacing(3),
  },

  textItem: {
    fontSize: isClickable ? '0.9285rem' : 'inherit',
  },
}));

export default useStyles;
