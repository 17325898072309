import type { FC } from 'react';

import { AppBar } from '@mui/material';

import AppBarContentDesktop from 'components/UI/molecules/AppBarContentDesktop/AppBarContentDesktop';
import AppBarContentMobile from 'components/UI/molecules/AppBarContentMobile/AppBarContentMobile';

export type Props = {
  isMobile: boolean;
};

const AppTopBar: FC<Props> = ({ isMobile }) => (
  <AppBar position='static'>{isMobile ? <AppBarContentMobile /> : <AppBarContentDesktop />}</AppBar>
);

export default AppTopBar;
