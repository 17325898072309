const breadcrumbsMessages = {
  youAreHere: ['breadcrumbsMessages>>youAreHere', 'Jesteś tutaj'],
  myProfile: ['breadcrumbsMessages>>myProfile', 'Profil pacjenta'],
  settings: ['breadcrumbsMessages>>settings', 'Ustawienia'],
  help: ['breadcrumbsMessages>>help', 'Pomoc'],
  services: ['breadcrumbsMessages>>services', 'Usługi'],
  pro_patients_list: ['breadcrumbsMessages>>pro_patients_list', 'Lista pacjentów'],
  pro_patient_single: ['breadcrumbsMessages>>pro_patient_single', 'Profil pacjenta'],
  pro_ecare_order_single: ['breadcrumbsMessages>>pro_ecare_order_single', 'Zlecenie teleopieki'],
  pro_ecare_order_list: ['breadcrumbsMessages>>pro_ecare_order_list', 'List zleceń'],
  pro_ecare_alarm_single: ['breadcrumbsMessages>>pro_ecare_alarm_single', 'Alarm SOS'],
  pro_ecare_alarms_list: ['breadcrumbsMessages>>pro_ecare_alarms_list', 'Alarmy SOS'],
  pro_ecare_technical_issue_single: ['breadcrumbsMessages>>pro_ecare_technical_issues_single', 'Problem techniczny {{date}}'],
  pro_ecare_technical_issues_list: ['breadcrumbsMessages>>pro_ecare_technical_issue_list', 'Problemy techniczne'],
  pro_ecare_phone_calls_list: ['breadcrumbsMessages>>pro_ecare_phone_calls_list', 'Lista połączeń teleopieki'],
  pro_ecare_phone_call_single: ['breadcrumbsMessages>>pro_ecare_phone_call_single', 'Karta obsługi połączenia'],
};

export default breadcrumbsMessages;
