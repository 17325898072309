// TODO: https://mdtnet.atlassian.net/browse/MDT-1089
import { FC, useMemo } from 'react';

import { Divider } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import eCareDevices from 'api/eCareDevices/eCareDevices';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import proECareDevicesMessages from 'translations/specific/pro_ecare_devices.mjs';

import useStyles from '../ProfessionalEOrderFormDialog.styles';

type FormInput = {
  name: string;
  serialNumber: string;
  simNumber: string;
  description: string;
  dateFrom: Date;
  dateTo: Date;
};

type Props = {
  deviceId: string;
  close: () => void;
};

const FORM_ID = 'deviceForm';

const DeviceForm: FC<Props> = ({ close, deviceId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { orderId } = useParams();

  const { control, handleSubmit, formState } = useForm<FormInput>();

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_DEVICES, deviceId], [deviceId]);
  const { data: device } = useQuery(queryKey, eCareDevices.getDevice(deviceId as string), {
    refetchOnWindowFocus: false,
    enabled: !!deviceId,
  });

  const statusMutation = useMutation(eCareDevices.patchDeviceData(deviceId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, { ...device, newStatus });
      close();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_ECARE_DEVICES, orderId] });
    },
  });

  const onSubmit: SubmitHandler<FormInput> = data => {
    statusMutation.mutate(data);
    close();
  };

  return (
    <>
      <Divider />
      {device && (
        <DialogFormWrapper formState={formState} id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.inputWrapper}>
            <FormInputText
              control={control}
              defaultValue={device?.name || ''}
              label={t(proECareDevicesMessages.columns.name)}
              name='name'
              required
            />
          </div>
          <div className={classes.inputWrapper}>
            <FormInputText
              control={control}
              defaultValue={device?.serialNumber || ''}
              label={t(proECareDevicesMessages.columns.serialNumber)}
              name='serialNumber'
              required
            />
          </div>
          <div className={classes.inputWrapper}>
            <FormInputText
              control={control}
              defaultValue={device?.simNumber || ''}
              label={t(proECareDevicesMessages.columns.simNumber)}
              name='simNumber'
              required
            />
          </div>
          <div className={classes.inputWrapper}>
            <FormInputText
              control={control}
              defaultValue={device?.description || ''}
              label={t(proECareDevicesMessages.columns.description)}
              name='description'
              required
            />
          </div>
          <div className={classes.inputWrapper}>
            <FormInputDate
              control={control}
              defaultValue={device?.dateFrom || null}
              label={t(proECareDevicesMessages.columns.description)}
              name='dateFrom'
              required
            />
          </div>
          <div className={classes.inputWrapper}>
            <FormInputDate
              control={control}
              defaultValue={device?.dateTo || null}
              label={t(proECareDevicesMessages.columns.description)}
              name='dateTo'
              required
            />
          </div>
        </DialogFormWrapper>
      )}
    </>
  );
};

export default DeviceForm;
