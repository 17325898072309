import { parsePatientForFE } from '../patient/patient.parsers';
import { ECareContactNote, ECareContactNoteDTO } from './eCareContactNotes.types';

export const parseSingleECareContactNoteForFE = (eCareContactNoteDTO: ECareContactNoteDTO): ECareContactNote => ({
  id: eCareContactNoteDTO.id,
  patient: eCareContactNoteDTO.patient ? parsePatientForFE(eCareContactNoteDTO.patient) : null,
  createdAt: new Date(eCareContactNoteDTO.created_at),
  updatedAt: new Date(eCareContactNoteDTO.updated_at),
  createdBy: eCareContactNoteDTO.created_by,
  updatedBy: eCareContactNoteDTO.updated_by || null,
  lastSync: eCareContactNoteDTO.last_sync ? new Date(eCareContactNoteDTO.last_sync) : null,
  date: new Date(eCareContactNoteDTO.date),
  callInitiator: eCareContactNoteDTO.call_initiator,
  receivers: eCareContactNoteDTO.receivers,
  content: eCareContactNoteDTO.content,
  order: eCareContactNoteDTO.order,
  serviceRecipient: eCareContactNoteDTO.service_recipient,
});

export const parseECareContactNotesListForFE = (eCareContactNoteDTO: ECareContactNoteDTO[]): ECareContactNote[] =>
  eCareContactNoteDTO.map(parseSingleECareContactNoteForFE);
