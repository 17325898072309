import es from 'react-phone-input-2/lang/es.json';
import pl from 'react-phone-input-2/lang/pl.json';

import type { SupportedLanguages } from 'constants/translations/_types';
import { SUPPORTED_LANGUAGES } from 'constants/translations/_types';

export const getLocalization = (code: SupportedLanguages) => {
  switch (code) {
    case SUPPORTED_LANGUAGES.PL:
      return pl;
    case SUPPORTED_LANGUAGES.ES:
      return es;
    default:
      return undefined;
  }
};
