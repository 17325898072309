import { createTheme } from '@mui/material/styles';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

import breakpoints from './themeParts/breakpoints';
import components from './themeParts/components';
import customPalette from './themeParts/customPalette';
import shadows from './themeParts/shadows';
import typography from './themeParts/typography';

const defaultTheme = createTheme({
  breakpoints,
  palette: {
    primary: {
      main: '#00B5BD',
      light: '#00C4D0',
      dark: '#059EA4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#102F74',
      light: '#1F47A0',
      dark: '#02205E',
    },
    warning: {
      main: '#F78231',
      light: '#FEAB3C',
      dark: '#FA7719',
    },
    error: {
      light: '#EB7E7D',
      main: '#F7615C',
      dark: '#D32F2F',
    },
    success: {
      main: '#36C073',
      light: '#72D39D',
      dark: '#29B260',
    },
    background: {
      default: '#F5F8FC',
      paper: '#FFFFFF',
    },
    grey: {
      200: '#E9EDF5',
      300: '#E9EFF4',
      400: '#BECBD5',
      500: '#8D97AA',
      600: '#5F6672',
    },
    ...customPalette,
  },
  shape: {
    borderRadius: 4,
  },
  shadows,
  spacing: 6.5,
  mixins: {
    toolbar: {
      '@media (max-width:0px) and (orientation: landscape)': { height: LAYOUT_SIZES.HEADER.sm },
      '@media (max-width:600px)': { height: LAYOUT_SIZES.HEADER.sm },
      height: LAYOUT_SIZES.HEADER.xxl,
    },
  },
  components,
  typography,
});

export default defaultTheme;
