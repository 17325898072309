import { makeStyles } from 'tss-react/mui';

/* styles will be available later :D */

const useStyles = makeStyles<{ hasInterval: boolean }>()((theme, { hasInterval }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paper,
    fontSize: '22px',
    fontWeight: 600,
    color: hasInterval ? theme.palette.error.main : theme.palette.grey[400],
    border: `1px solid ${theme.palette.error.main}`,
    borderColor: hasInterval ? theme.palette.error.main : theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.15, 3, 1.15, 3),
    fontVariantNumeric: 'tabular-nums',
  },
  label: {
    marginLeft: theme.spacing(2),
    fontSize: '12px',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
