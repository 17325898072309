import { makeStyles } from 'tss-react/mui';

import AVATAR_SIZE from 'constants/styles/AVATAR_SIZE';

import { PATIENT_FORM_INPUT_KEYS } from './_constants/patientFormKeys';

const PERSONAL_SECTION_AREA = 'personalSection';
const CONTACT_SECTION_AREA = 'contactSection';
const ADDRESS_SECTION_AREA = 'addressSection';
const AVATAR_AREA = 'avatar';
const FORM_AREA = 'form';
const CONSENTS_AREA = 'consents';

const {
  addressDirections: ADDRESS_DIRECTIONS_AREA,
  apartmentNumber: APARTMENT_NUMBER_AREA,
  buildingNumber: BUILDING_NUMBER_AREA,
  city: CITY_AREA,
  country: COUNTRY_AREA,
  postcode: POSTCODE_AREA,
  street: STREET_AREA,
} = PATIENT_FORM_INPUT_KEYS as Record<string, string>;

const useStyles = makeStyles<{ isEditMode: boolean }>()((theme, { isEditMode }) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(3),
    alignItems: 'flex-start',
    gridTemplateColumns: isEditMode ? `${AVATAR_SIZE.width}px auto` : `${AVATAR_SIZE.width}px auto`,
    gridTemplateAreas: isEditMode ? `"${AVATAR_AREA} ${FORM_AREA}"` : `"${AVATAR_AREA} ${FORM_AREA}"`,

    '@media screen and (max-width: 1200px)': {
      gridTemplateColumns: isEditMode ? `${AVATAR_SIZE.width}px auto` : `${AVATAR_SIZE.width}px 160px`,
      width: '100%',
      justifyContent: 'space-between',

      gridTemplateAreas: isEditMode
        ? `
      "${AVATAR_AREA} ."
      "${FORM_AREA} ${FORM_AREA}"
      `
        : `
      "${AVATAR_AREA} ."
      "${FORM_AREA} ${FORM_AREA}"
      `,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: isEditMode ? `${AVATAR_SIZE.width}px auto` : `${AVATAR_SIZE.width}px 125px`,
    },
  },
  avatarContainer: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gridArea: AVATAR_AREA,
    '& > div': {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      '& > div': {
        display: 'grid',
        gridTemplateColumns: '147px auto',
        gap: theme.spacing(2),
        alignItems: 'center',
      },
    },
  },
  formContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-evenly',
    gridTemplateColumns: isEditMode ? '200px 200px 440px' : '320px 200px 320px',
    alignItems: 'flex-start',
    gridArea: FORM_AREA,
    gridTemplateAreas: `"${PERSONAL_SECTION_AREA} ${CONTACT_SECTION_AREA} ${ADDRESS_SECTION_AREA}"`,

    [theme.breakpoints.down('xxl')]: {
      gridGap: theme.spacing(2),
      gridTemplateColumns: isEditMode ? '210px 210px 320px' : '180px 180px 320px',
    },

    '@media screen and (max-width: 1400px)': {
      gridGap: theme.spacing(8, 3),
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: isEditMode
        ? `
        "${PERSONAL_SECTION_AREA} ${CONTACT_SECTION_AREA} ${ADDRESS_SECTION_AREA}"
        "${CONSENTS_AREA} ${CONSENTS_AREA} ${CONSENTS_AREA} ${CONSENTS_AREA}"
        `
        : `"${PERSONAL_SECTION_AREA} ${CONTACT_SECTION_AREA} ${ADDRESS_SECTION_AREA}"`,
    },
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
      "${PERSONAL_SECTION_AREA}"
      "${CONTACT_SECTION_AREA}"
      "${ADDRESS_SECTION_AREA}"
      "${CONSENTS_AREA}"
      `,
    },
  },
  standardColumnWrapper: {
    display: 'grid',
    gridGap: theme.spacing(3, 1),
    alignContent: 'flex-start',
    padding: isEditMode ? `${theme.spacing(4)} 0` : `${theme.spacing(2)} 0`,

    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: isEditMode ? 'auto' : '1fr 1fr',
    },
  },
  fourColumnsContainer: {
    padding: isEditMode ? `${theme.spacing(4)} 0` : `${theme.spacing(2)} 0`,
    display: 'grid',
    gridGap: theme.spacing(3, 4),
    alignContent: 'flex-start',
    gridTemplateColumns: isEditMode ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)',

    gridTemplateAreas: isEditMode
      ? `
    "${COUNTRY_AREA} ${COUNTRY_AREA} . ."
    "${POSTCODE_AREA} ${CITY_AREA} ${CITY_AREA} ."
    "${STREET_AREA} ${STREET_AREA} ${BUILDING_NUMBER_AREA} ${APARTMENT_NUMBER_AREA}"
    "${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA}"
    `
      : `
    "${COUNTRY_AREA} ${COUNTRY_AREA} . ."
    "${CITY_AREA} ${CITY_AREA} ${POSTCODE_AREA} ${POSTCODE_AREA}"
    "${STREET_AREA} ${STREET_AREA} ${BUILDING_NUMBER_AREA} ${APARTMENT_NUMBER_AREA}"
    "${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA}"
    `,

    [theme.breakpoints.down('xxl')]: {
      gridTemplateColumns: isEditMode ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',

      gridTemplateAreas: isEditMode
        ? `
        "${COUNTRY_AREA} ${COUNTRY_AREA} ."
        "${POSTCODE_AREA} ${CITY_AREA} ${CITY_AREA}"
        "${STREET_AREA} ${STREET_AREA} ${STREET_AREA}"
        "${BUILDING_NUMBER_AREA} ${APARTMENT_NUMBER_AREA} ."
        "${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA}"
        `
        : `
        "${COUNTRY_AREA} ${COUNTRY_AREA} ${COUNTRY_AREA} ${COUNTRY_AREA}"
        "${CITY_AREA} ${CITY_AREA} ${POSTCODE_AREA} ${POSTCODE_AREA}"
        "${STREET_AREA} ${STREET_AREA} ${STREET_AREA} ${STREET_AREA}"
        "${BUILDING_NUMBER_AREA} ${BUILDING_NUMBER_AREA} ${APARTMENT_NUMBER_AREA} ${APARTMENT_NUMBER_AREA}"
        "${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA}"
        `,
    },

    '@media screen and (max-width: 700px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: isEditMode
        ? `
      "${COUNTRY_AREA} ${COUNTRY_AREA}"
      "${CITY_AREA} ${POSTCODE_AREA}"
      "${STREET_AREA} ${STREET_AREA}"
      "${BUILDING_NUMBER_AREA} ${APARTMENT_NUMBER_AREA}"
      "${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA}"
      `
        : `
      "${COUNTRY_AREA} ${COUNTRY_AREA}"
      "${CITY_AREA} ${POSTCODE_AREA}"
      "${STREET_AREA} ${STREET_AREA}"
      "${BUILDING_NUMBER_AREA} ${APARTMENT_NUMBER_AREA}"
      "${ADDRESS_DIRECTIONS_AREA} ${ADDRESS_DIRECTIONS_AREA}"
      `,
    },
  },
  clausesContainer: {
    padding: isEditMode ? `${theme.spacing(4)} 0` : `${theme.spacing(2)} 0`,
    gridColumn: '1 / 4',
  },
  personalSection: {
    gridArea: PERSONAL_SECTION_AREA,
  },
  contactSection: {
    gridArea: CONTACT_SECTION_AREA,
  },
  addressSection: {
    gridArea: ADDRESS_SECTION_AREA,
  },
  editButton: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
  },
}));

export default useStyles;
