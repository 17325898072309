import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import type {
  CumulativeExtendedServiceItem,
  CumulativeServiceDetails,
  CumulativeServiceItem,
  RegularServiceDetails,
} from 'constants/_types/SelectedServices';
import { CUMULATIVE_CORE_SERVICE_KINDS } from 'constants/coreServices/cumulativeCoreServiceKinds';

import type { ServiceRequestsFormDataDto } from '../types';
import { parseCumulativeServices } from './_services/parseCumulativeServices/parseCumulativeServices';
import { parseRegularServices } from './_services/parseRegularServices/parseRegularServices';

export const parseServiceRequestsFormForBE = (data: OrderServiceFormInput, patientId: number, paramOrigin: OrderServiceOrigins) => {
  const addressData = { ...data.address } as OrderServiceFormInput['address'];

  const parsedData: ServiceRequestsFormDataDto[] = [];
  Object.entries(data.details).forEach(([key, details]) => {
    const isCumulative = CUMULATIVE_CORE_SERVICE_KINDS.includes(key as OrderServiceCoreServicesKinds);
    if (isCumulative) {
      parsedData.push(
        ...parseCumulativeServices({
          details: details as CumulativeServiceDetails<
            Record<string, CumulativeServiceItem> | Record<string, CumulativeExtendedServiceItem>
          >,
          addressData,
          patientId,
          paramOrigin,
        }),
      );
    } else {
      parsedData.push(...parseRegularServices({ details: details as RegularServiceDetails, addressData, patientId, paramOrigin }));
    }
  });

  return parsedData;
};
