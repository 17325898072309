import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ error: boolean }>()((theme, { error }) => ({
  footer: {
    display: error ? 'block' : 'none',
    transform: 'translateY(-5px)',
  },
}));

export default useStyles;
