import { isNil } from 'lodash';
import { makeStyles } from 'tss-react/mui';

import { PSEUDOELEMENT_ACTIVE_BORDER_BASE } from 'constants/styles/PSEUDOELEMENT_ACTIVE_BORDER_BASE';
import SCROLLBAR_SIZE from 'constants/styles/SCROLLBAR_SIZE';
import { SCROLLBAR_STYLING } from 'constants/styles/SCROLLBAR_STYLING';

const MAX_TABLE_HEIGHT = 800;

interface StylesProps {
  tableHeight: number;
  cellSize: number;
  columnsNumber: number | undefined;
  isFirefox: boolean;
  fixedHeight?: number;
}

const useStyles = makeStyles<StylesProps>()((theme, { tableHeight, cellSize, columnsNumber, isFirefox, fixedHeight }) => {
  const BORDER = `thin solid ${theme.palette.grey[200]}`;

  const cellSinglePadding = theme.spacing(0.5);
  const cellSinglePaddingValue = parseInt(cellSinglePadding, 10);
  const singleColumnWidth = cellSinglePaddingValue + cellSize + cellSinglePaddingValue;

  const resolveMinHeight = () => {
    if (!isNil(fixedHeight)) return 'unset';
    if (tableHeight < MAX_TABLE_HEIGHT) return `${tableHeight + SCROLLBAR_SIZE.width}px`;
    return '50vh';
  };

  return {
    root: {
      maxWidth: '100%',
      justifySelf: 'stretch',
      overflow: 'auto',
      position: 'relative',
      minHeight: resolveMinHeight(),
      height: fixedHeight || '100%',
      padding: '0 1px',
      ...SCROLLBAR_STYLING(theme),
    },

    table: {
      height: '1px',
      position: 'absolute',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      borderBottom: BORDER,
      width: columnsNumber ? '99.8%' : 'unset',
      minWidth: columnsNumber ? `${singleColumnWidth * columnsNumber}px` : 'unset',
    },

    thead: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: theme.palette.background.paper,

      '&:after': {
        content: '""',
        bottom: 0,
        position: 'absolute',
        height: '1px',
        width: '100%',
        background: theme.palette.grey[200],
      },

      '&:before': {
        content: '""',
        top: 0,
        position: 'absolute',
        height: '1px',
        width: '100%',
        background: theme.palette.grey[200],
      },
    },

    th: {
      border: BORDER,
      textAlign: 'center',
      padding: cellSinglePadding,
      width: columnsNumber ? 'unset' : `${cellSize}px`,
      minWidth: `${cellSize}px`,

      '&:after': isFirefox
        ? {
            content: '""',
            top: 0,
            right: 0,
            position: 'absolute',
            height: '100%',
            width: '1px',
            background: theme.palette.grey[200],
          }
        : {},
    },

    thActive: {
      position: 'relative',

      '&:before': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: '-1px',
        left: 0,
        height: '2px',
        width: '100%',
      },
    },

    activeBorderNode: {
      '&:before': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: 0,
        left: '-1px',
        // to avoid 1px gray space
        height: 'calc(100% + 1px)',
        width: '2px',
      },
      '&:after': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: 0,
        right: '-1px',
        // to avoid 1px gray space
        height: 'calc(100% + 1px)',
        width: '2px',
      },
    },
    loader: {
      zIndex: 1,
    },
  };
});

export default useStyles;
