import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    boxShadow: theme.shadows[3],
  },
  message: {
    width: '100%',
  },
  titleRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    marginBottom: theme.spacing(2),
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
  errorsList: {
    display: 'grid',
    gridGap: theme.spacing(1),
    color: theme.palette.error.dark,
    listStyle: 'none outside',
    paddingLeft: theme.spacing(2),

    '& li': {
      position: 'relative',
    },

    '& li:before': {
      content: '"-"',
      position: 'absolute',
      left: `-${theme.spacing(1.5)}`,
    },
  },
}));

export default useStyles;
