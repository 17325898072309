import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ECareContactNote } from 'api/eCareContactNotes/eCareContactNotes.types';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import PartnerNotesDialog from 'components/UI/organisms/_dialogs/PartnerNotesDialog/PartnerNotesDialog';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import { receiverDictionary, Receivers } from 'constants/receiverDictionary/receiverDictionary';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import generalMessages from 'translations/common/general.mjs';
import partnerNotesMessages from 'translations/specific/partnerNotes.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './ECareAlarmInternalNotes.styles';

type Props = {
  internalNote: ECareContactNote;
  disabled?: boolean;
};

const ECareAlarmInternalNotes: FC<Props> = ({ internalNote, disabled }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [isPartnerNotesDialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const header = [
    {
      label: internalNote.updatedAt
        ? t(partnerNotesMessages.eCarePartnerNotesTable.updatedTime)
        : t(partnerNotesMessages.eCarePartnerNotesTable.creationTime),
      value: internalNote.updatedAt
        ? unknownDateToString(internalNote.updatedAt, DATE_FORMATS.DISPLAY_TIME)
        : unknownDateToString(internalNote.createdAt, DATE_FORMATS.DISPLAY_TIME),
    },
    {
      label: t(partnerNotesMessages.eCarePartnerNotesTable.lastSync),
      value: internalNote.lastSync
        ? unknownDateToString(internalNote.lastSync, DATE_FORMATS.DISPLAY_TIME)
        : t(generalMessages.noLastSyncNote),
    },
    {
      label: t(partnerNotesMessages.eCarePartnerNotesTable.id),
      value: internalNote.updatedBy ? internalNote.updatedBy : internalNote.createdBy,
    },
    {
      label: t(partnerNotesMessages.eCarePartnerNotesTable.receivers),
      value: internalNote.receivers.map(receiver => receiver).join(', '),
    },
  ];

  const isReceiver = (receiver: Receivers) => internalNote.receivers.includes(receiver);

  const defaultValues = useMemo(
    () => ({
      content: internalNote.content,
      CUS: isReceiver(receiverDictionary.CUS),
      OPS: isReceiver(receiverDictionary.OPS),
      WYK: isReceiver(receiverDictionary.WYK),
      CT24: isReceiver(receiverDictionary.CT24),
    }),
    [internalNote],
  );
  const headerElement = internalNote.lastSync ? undefined : (
    <Button className={classes.button} disabled={disabled} size='small' variant='outlined' onClick={openDialog}>
      {t(proECareAlarmMessages.edit)}
    </Button>
  );

  return (
    <>
      <BoxWithHeader description={internalNote.content} header={header} headerElement={headerElement} />
      <PartnerNotesDialog
        close={closeDialog}
        defaultValue={defaultValues}
        isOpen={isPartnerNotesDialogOpen}
        noteId={internalNote.id}
        orderId={internalNote.order}
        serviceRecipientId={internalNote.serviceRecipient}
      />
    </>
  );
};

export default ECareAlarmInternalNotes;
