import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: '12px',
      justifySelf: 'center',
      alignSelf: 'center',
      borderRadius: '50%',
      background: 'none',
    },
  },
}));

export default useStyles;
