import type { MouseEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

export const useMenuState = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const isMenuOpened = useMemo(() => !!anchorElement, [anchorElement]);

  const handleMenuClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setAnchorElement(e.currentTarget);
    },
    [setAnchorElement],
  );
  const handleMenuClose = useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  return { isMenuOpened, anchorElement, handleMenuClick, handleMenuClose };
};
