import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import patientRelatedFiles from 'api/patientRelatedFiles/patientRelatedFiles';
import { PatientRelatedFile } from 'api/patientRelatedFiles/patientRelatedFiles.types';
import PatientCardSectionTitle from 'components/UI/atoms/PatientCardSectionTitle/PatientCardSectionTitle';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import PatientHealthDetailsFiles from './_components/PatientHealthDetailsFiles/PatientHealthDetailsFiles';
import PatientHealthDetailsNote from './_components/PatientHealthDetailsNote/PatientHealthDetailsNote';
import useStyles from './PatientHealthDetails.styles';

export type Props = {
  patientId: number;
};

const PatientHealthDetails: FC<Props> = ({ patientId }) => {
  // TODO permissions files
  const [canViewNotes] = usePermissionCheck(['view_note']);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data } = useQuery([QUERY_KEYS.GET_PATIENT_RELATED_FILES, patientId], patientRelatedFiles.getPatientRelatedFiles(patientId));

  const { otherFiles, healthSurveyFiles } = useMemo(() => {
    const result: {
      otherFiles: PatientRelatedFile[];
      healthSurveyFiles: PatientRelatedFile[];
    } = {
      otherFiles: [],
      healthSurveyFiles: [],
    };

    if (data) {
      data.forEach(relatedFile => {
        switch (relatedFile.type) {
          case 'HEALTH_SURVEY': {
            result.healthSurveyFiles.push(relatedFile);
            break;
          }
          case 'OTHER': {
            result.otherFiles.push(relatedFile);
            break;
          }
          default: {
            exhaustiveGuard(relatedFile.type);
          }
        }
      });
    }

    return result;
  }, [data]);

  return (
    <CardContentContainer isLastSection>
      <div>
        <PatientCardSectionTitle marginBottom={3} titleText={t(proPatientMessages.sectionTitles.healthDetails)} />
        {canViewNotes && <PatientHealthDetailsNote patientId={patientId} />}
      </div>
      <PatientCardSectionTitle marginBottom={3} titleText={t(proPatientMessages.sectionTitles.dataFromFiles)} />
      <div className={classes.filesWrapper}>
        <div>
          <Typography sx={{ marginBottom: 2, marginTop: 0 }} variant='subtitle2'>
            {t(proPatientMessages.sectionTitles.healthSurveyFile)}
          </Typography>
          <PatientHealthDetailsFiles fileLimit={1} files={healthSurveyFiles} fileType='HEALTH_SURVEY' patientId={patientId} />
        </div>
        <div>
          <Typography sx={{ marginBottom: 2, marginTop: 0 }} variant='subtitle2'>
            {t(proPatientMessages.sectionTitles.otherFiles)}
          </Typography>
          <PatientHealthDetailsFiles files={otherFiles} fileType='OTHER' patientId={patientId} />
        </div>
      </div>
    </CardContentContainer>
  );
};

export default PatientHealthDetails;
