import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import type { CoreService } from 'api/coreService/coreService.types';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import { useServicesBasketStorage } from 'storages/serviceBasketStorage/servicesBasketStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { handleDuplicatedItems } from './_services/handleDuplicatedItems/handleDuplicatedItems';

export const useServicesBasketStorageWrapper = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useShowSnackbar();
  const basketStorage = useServicesBasketStorage();

  const handleAddToBasket = useCallback(
    (basketInputToSet: CoreService | CoreService[]) => {
      const { duplicatedItems } = basketStorage.addToBasket(basketInputToSet);

      handleDuplicatedItems({
        duplicatedItems,
        prepareSnackbar: (duplicatedItemsNames: string) =>
          showSnackbar({
            variant: 'warning',
            translationArray: orderServiceMessages.basket.duplicatedItems,
            translationParams: { duplicatedItemsNames },
          }),
        t,
      });
    },
    [basketStorage],
  );

  return { ...basketStorage, addToBasket: handleAddToBasket };
};
