import { useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import contactForm from 'api/contactForm/contactForm';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import helpMessages from 'translations/specific/help.mjs';

import type { ContactFormInput } from '../../_constants/inputKeys';

export const useContactForm = () => {
  const { patient } = usePatientContext();
  const { brandInfo } = useBrandContext();
  const { showSnackbar } = useShowSnackbar();
  const [generalErrors, setGeneralErrors] = useState<string[]>();

  const form = useForm<ContactFormInput>();

  const handleOnSuccess = useCallback(() => {
    showSnackbar({ variant: 'success', translationArray: helpMessages.form.snackbar.success });
    form.reset();
  }, []);

  const onError = useOnBackendFormError<ContactFormInput, ContactFormInput>({
    setError: form.setError,
    setGeneralErrors,
  });

  const submitContactFormMutation = useMutation(QUERY_KEYS.CONTACT_FORM_POST, contactForm.submitContactForm(patient?.id, brandInfo?.id), {
    onSuccess: handleOnSuccess,
    onError,
  });

  const handleSubmit = useCallback(async (data: ContactFormInput) => {
    await submitContactFormMutation.mutateAsync(data);
  }, []);

  const onSubmit = useCallback(() => {
    form.handleSubmit(data => handleSubmit(data))();
  }, [form.handleSubmit]);

  return { form, loading: submitContactFormMutation.isLoading, postContactFormMutationGeneralErrors: generalErrors, onSubmit };
};
