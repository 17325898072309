import { FC, ReactNode, useMemo } from 'react';

import { Search as DetailIcon } from '@mui/icons-material';
import { Card } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { Address, PatientLegacy, PatientsFilters } from 'api/_types';
import patients from 'api/patient/patients';
import { ReactComponent as UsersListIcon } from 'assets/icons/hub/active/users.svg';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import { AvailableFilter } from 'constants/_types/AvailableFilter';
import patientStatusOptions from 'constants/dropdownOptions/patientStatus';
import PATHS from 'constants/router/PATHS';
import useTableData from 'hooks/useTableData/useTableData';
import translateOptions from 'services/translateOptions/translateOptions';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

const parseAddressFromBE = (addressObj: Address): ReactNode => {
  const parts = [
    addressObj.street_name,
    addressObj.building_number,
    addressObj.apartment_number ? `/ ${addressObj.apartment_number}` : null,
  ];

  return parts.filter(Boolean).join(' ');
};

const PatientsList: FC = () => {
  const { t } = useTranslation();

  const { page, onExactPage, pagesCount, onFilter, filters, sort, onSort, query, isFetchingNewPage } = useTableData<keyof PatientLegacy>({
    queryKeyBase: 'key',
    queryFunction: patients.getAllPatients(),
  });

  const { data } = query;

  const availableFilters: AvailableFilter<PatientsFilters>[] = useMemo(
    () => [
      { label: t(proPatientMessages.filters.firstName), type: 'string', key: 'personal_data__first_name' },
      { label: t(proPatientMessages.filters.lastName), type: 'string', key: 'personal_data__last_name' },
      { label: t(proPatientMessages.filters.pesel), type: 'number', key: 'pesel' },
      { label: t(proPatientMessages.filters.phoneNumber), type: 'number', key: 'personal_data__phone_number' },
      { label: t(proPatientMessages.filters.email), type: 'string', key: 'personal_data__email' },
      { label: t(proPatientMessages.filters.city), type: 'string', key: 'personal_data__main_address__city' },
      { label: t(proPatientMessages.filters.postalCode), type: 'string', key: 'personal_data__main_address__postcode' },
      { label: t(proPatientMessages.filters.address), type: 'string', key: 'personal_data__main_address__street_name' },
      { label: t(proPatientMessages.filters.payer), type: 'string', key: 'payers' },
      {
        label: t(proPatientMessages.filters.status),
        type: 'dropdown',
        key: 'status',
        options: translateOptions(t, patientStatusOptions),
      },
      { label: t(proPatientMessages.filters.ppaCount), type: 'number', key: 'ppa_count' },
    ],
    [t],
  );

  const customFilter = {
    availableFilters,
    filters,
    onFilter,
  };

  const pageTitle = {
    headerLabel: t(proPatientMessages.listTitle),
    icon: <UsersListIcon />,
    itemsCount: data?.count || 0,
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<PatientLegacy>();

    return [
      helper.accessor('personal_data.first_name', {
        id: 'personal_data__first_name',
        header: t(proPatientMessages.patientsTable.firstNameColumn) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('personal_data.last_name', {
        id: 'personal_data__last_name',
        header: t(proPatientMessages.patientsTable.lastNameColumn) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('pesel', {
        id: 'pesel',
        header: t(proPatientMessages.patientsTable.peselColumn) as string,
        cell: info => info.getValue() || '-',
        meta: {
          sx: { maxWidth: '11ch' },
        },
      }),
      helper.accessor('personal_data.phone_number', {
        id: 'personal_data__phone_number',
        header: t(proPatientMessages.patientsTable.phoneNumberColumn) as string,
        cell: info => info.getValue(),
        meta: {
          sx: { maxWidth: '18ch' },
        },
      }),
      helper.accessor('personal_data.email', {
        id: 'personal_data__email',
        header: t(proPatientMessages.patientsTable.emailColumn) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('personal_data.main_address.city', {
        id: 'personal_data__main_address__city',
        header: t(proPatientMessages.patientsTable.cityColumn) as string,
        cell: info => info.getValue() || '-',
      }),
      helper.accessor('personal_data.main_address.postcode', {
        id: 'personal_data__main_address__postcode',
        header: t(proPatientMessages.patientsTable.postalColumn) as string,
        cell: info => info.getValue() || '-',
        meta: {
          sx: { maxWidth: '8ch' },
        },
      }),

      helper.accessor('personal_data.main_address', {
        id: 'personal_data__main_address__street_name',
        header: t(proPatientMessages.patientsTable.addressColumn) as string,
        cell: info => parseAddressFromBE(info.getValue()) || '-',
      }),
      helper.accessor('payers', {
        id: 'payers',
        header: t(proPatientMessages.patientsTable.payer) as string,
        cell: info => info.getValue()?.join(', ') || '-',
      }),
      helper.accessor('status', {
        id: 'status',
        header: t(proPatientMessages.patientsTable.statusColumn) as string,
        cell: info => t(proPatientMessages.patientStatuses[info.getValue()]),
        meta: {
          sx: { maxWidth: '10ch' },
        },
      }),
      helper.accessor('ppa_count', {
        id: 'ppa_count',
        header: t(proPatientMessages.patientsTable.ppaCount) as string,
        cell: info => info.getValue() || 0,
        meta: {
          sx: { maxWidth: '4ch' },
        },
      }),
      helper.display({
        id: 'detailsView',
        enableSorting: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: info => (
          <Link to={`/${generatePath(PATHS.PROFESSIONAL_PATIENT_SINGLE, { patientId: `${info.row.original.id}` })}`}>
            <DetailIcon />
          </Link>
        ),
        meta: {
          sx: { maxWidth: '5ch' },
        },
      }),
    ];
  }, [t]);

  return (
    <>
      <PageTitleWithFiltersForTables customFilters={customFilter} pageTitle={pageTitle} />
      <Card>
        {/* Generic Component throws error, but idk why, it should work */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CustomTable<PatientLegacy>
          columns={columns}
          data={data?.data}
          page={page}
          pagesCount={pagesCount}
          showLoaderCover={isFetchingNewPage}
          sort={sort}
          onExactPage={onExactPage}
          onSort={onSort}
        />
      </Card>
    </>
  );
};

export default PatientsList;
