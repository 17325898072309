import type { TFunction } from 'i18next';

import { MAX_NUMBER_OF_CHARACTERS } from 'components/UI/organisms/ContactCard/_constants/maxNumberOfCharacters';
import validationMessages from 'translations/common/validation.mjs';

interface Params {
  currentValue: string | undefined;
  t: TFunction;
}

export const validateInquiry = ({ currentValue, t }: Params) => {
  if ((currentValue?.length ?? 0) > MAX_NUMBER_OF_CHARACTERS)
    return t(validationMessages.text.tooLong, { maxLength: MAX_NUMBER_OF_CHARACTERS });
  return true;
};
