import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ buttonsNumber: number }>()((theme, { buttonsNumber }) => ({
  actions: {
    display: 'grid',
    gridTemplateColumns: `repeat(${buttonsNumber}, auto)`,
    gridGap: theme.spacing(4),
    padding: theme.spacing(0, 4, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 2),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'stretch',
      gridTemplateColumns: 'auto',
      gridGap: theme.spacing(2),
      padding: theme.spacing(0, 0, 2),
      width: '100%',
    },
  },
  actionsDescription: {
    padding: theme.spacing(0, 7),
    textAlign: 'center',
  },
}));

export default useStyles;
