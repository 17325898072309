import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  clauseListItem: {
    paddingTop: theme.spacing(3),
  },
  clauseTitle: {
    marginBottom: theme.spacing(1),
  },
  fileDivider: {
    width: '95%',
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
