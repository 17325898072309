import { useEffect } from 'react';

import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface HookParams {
  serviceSubtypes: DropdownOption[];
  serviceSubtypeValues: string[];
  preselectedSubtypeId: string | undefined;
  setFormSubtypePreselectedValue: (serviceSubtypes: DropdownOption[]) => void;
}

export const useUpdateSelectedSubtypeByPreselectedSubtype = ({
  serviceSubtypes,
  serviceSubtypeValues,
  preselectedSubtypeId,
  setFormSubtypePreselectedValue,
}: HookParams) => {
  useEffect(() => {
    if (!preselectedSubtypeId || !serviceSubtypeValues.includes(preselectedSubtypeId)) return;

    setFormSubtypePreselectedValue(serviceSubtypes);
  }, [preselectedSubtypeId]);
};
