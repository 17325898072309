import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  patientButton: {
    justifySelf: 'flex-start',
    fontSize: '0.9rem',
    height: '3.55rem',
  },
}));

export default useStyles;
