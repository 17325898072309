import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../coreServiceGroups';
import type { CoreServiceGroup, CoreServiceGroupDTO } from '../coreServiceGroups.types';
import { parseServicesGroupForFE } from '../parseServicesGroupForFE/parseServicesGroupForFE';

type GetServicesGroupQuery = () => Promise<CoreServiceGroup[]>;

type QueryParams = {
  patientId: number;
  payers: string[];
};

export const getServicesGroup =
  ({ patientId, payers }: QueryParams): GetServicesGroupQuery =>
  async () => {
    const { data } = await axios.get<GetServicesGroupQuery, PaginatedApiResponse<CoreServiceGroupDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: { patient: patientId, limit: 250, payer: payers },
      }),
    );

    return parseServicesGroupForFE(data.results);
  };
