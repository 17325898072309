import { useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import ColumnsDisplayFallback from 'components/UI/molecules/ColumnsDisplayFallback/ColumnsDisplayFallback';
import { ServiceGroup } from 'constants/_types/ServiceGroup';
import { getServicesGroups } from 'constants/services/SERVICES_GROUPS';

import ElementWithLoader from '../ElementWithLoader/ElementWithLoader';
import ServicesReviewTableWrapper from '../ServicesReviewTable/_components/ServicesReviewTableWrapper/ServicesReviewTableWrapper';
import ServicesReviewTableContainer from '../ServicesReviewTableContainer/ServicesReviewTableContainer';
import useStyles from './ServicesReviewGroups.styles';

export type Props = {
  areEmptyGroups: boolean;
  groupsToDisplay: ServiceGroup[] | null;
  isLoading: boolean;
  isHighlighted: boolean;
  fallbackMessage: string;
};

const ServicesReviewGroups: FC<Props> = ({ areEmptyGroups, groupsToDisplay, isLoading, isHighlighted, fallbackMessage }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const hardCodedGroupsToDisplay = useMemo(() => getServicesGroups(t), [t]);

  if (!isLoading && areEmptyGroups) {
    return (
      <div className={classes.fallback}>
        {hardCodedGroupsToDisplay.map(({ id, title }) => (
          <ServicesReviewTableWrapper isLoading={false} key={id} title={title}>
            <ColumnsDisplayFallback fallbackMessage={fallbackMessage} />
          </ServicesReviewTableWrapper>
        ))}
      </div>
    );
  }

  return (
    <ElementWithLoader inheritDisplayType={!isLoading} isLoading={isLoading}>
      {groupsToDisplay &&
        groupsToDisplay.map(({ data, title }, index, array) => (
          <ServicesReviewTableContainer
            data={data}
            isHighlighted={isHighlighted}
            isLast={index === array.length - 1}
            key={title.id}
            title={t(title.key, title.defaultValue)}
          />
        ))}
    </ElementWithLoader>
  );
};

export default ServicesReviewGroups;
