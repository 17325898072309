import type { FC } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import passwordChangeReminder from 'translations/specific/passwordChangeReminder.mjs';

import useStyles from './PasswordChangeReminderActionButtons.styles';

type Props = {
  handleOpenChangePassword: () => void;
  handleCloseAllAndPostpone: () => void;
};

const PasswordChangeReminderActionButtons: FC<Props> = ({ handleOpenChangePassword, handleCloseAllAndPostpone }) => {
  const { t } = useTranslation();

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Button variant='text' onClick={handleCloseAllAndPostpone}>
        {t(passwordChangeReminder.alert.buttonRejectLabel)}
      </Button>
      <Button variant='text' onClick={handleOpenChangePassword}>
        {t(passwordChangeReminder.alert.buttonAcceptLabel)}
      </Button>
    </div>
  );
};

export default PasswordChangeReminderActionButtons;
