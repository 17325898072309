import { type FC, useCallback, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import coreService from 'api/coreService/coreService';
import { CoreService } from 'api/coreService/coreService.types';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import { SearchBarMemoized } from 'components/UI/molecules/SearchBar/SearchBar';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import { ServicesTopBarMemoized } from 'components/UI/organisms/ServicesTopBar/ServicesTopBar';
import useColumns from 'components/UI/organisms/ServicesTypeView/_hooks/useColumns/useColumns';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { normalizeString } from 'helpers/normalizeString/normalizeString';
import useSelectLogic from 'hooks/_userServices/useSelectLogic/useSelectLogic';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import { useServicesBasketStorageWrapper } from 'storageWrappers/useServicesBasketStorageWrapper/useServicesBasketStorageWrapper';

import { ServicesTableMemoized } from './_components/ServicesTable/ServicesTable';
import useGetTypeDetails from './_hooks/useGetTypeDetails/useGetTypeDetails';
import parseServicesData from './_services/parseServicesData/parseServicesData';
import useStyles from './ServicesTypeView.styles';

const fetchSize = 25;

export type ResultsRowValue = CoreService & { isSelected?: boolean; isMatched?: boolean; searchableName: string };
export type ResultsRow = { [key: string]: ResultsRowValue | null };

const ServicesTypeView: FC = () => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const { typeId, subtypeId } = useParams() as unknown as { typeId: string; subtypeId: string };

  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const { data: typeDetails } = useGetTypeDetails(typeId);

  const { toggleSelected, checkIsSelected, selected, clearSelection } = useSelectLogic();
  const [searchPhrase, setSearchPhrase] = useState('');

  const { columns, isSubtypesLoading } = useColumns({ typeId, toggleSelected, isSearchActive: !!searchPhrase });
  const [completedColumns, setCompletedColumns] = useState<string[]>([]);

  const queryClient = useQueryClient();

  const readyToFetch = useMemo(
    () => !!columns?.length && !!patient?.id && !!selectedPayersList.length,
    [columns, patient, selectedPayersList],
  );

  const { data, fetchNextPage, isFetching, isLoading, isFetched } = useInfiniteQuery<ResultsRow[]>(
    [QUERY_KEYS.GET_SERVICES_LIST, selectedPayersList, columns, patient?.id],
    async ({ pageParam = 0, queryKey }) => {
      const isInitial = pageParam === 0;
      const currentPayers = queryKey[1] as string[];
      queryClient.removeQueries({
        predicate: ({ queryKey: q }) => {
          if (!q.includes(QUERY_KEYS.GET_SERVICES_LIST)) return false;
          const selectedPayers = q[1];
          if (!selectedPayers) return false;
          return !isEqual((q[1] as string[]).sort(), currentPayers.sort());
        },
      });
      if (isInitial) setCompletedColumns([]);
      const result = await coreService.getPaginatedServicesFromSubtype({
        patientId: patient?.id,
        fetchSize,
        page: pageParam,
        payers: selectedPayersList,
        completedColumns: isInitial ? [] : completedColumns,
        columnsIds: (columns as { accessorKey: string }[]).map(({ accessorKey }) => `${accessorKey}`),
      });

      return parseServicesData(result, { setCompletedColumns, t });
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: readyToFetch,
    },
  );

  // we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(() => {
    if (!data) return [];
    if (!selected && !searchPhrase) return data.pages.flat();
    // TODO consider to keep it in state and update isSelected & isMatched by direct access to it (column id, row number)
    return data.pages.flatMap(row =>
      row.map(cell =>
        Object.entries(cell).reduce<ResultsRow>((acc, [id, value]) => {
          const cellValue = acc[id];
          if (value && cellValue) {
            cellValue.isSelected = checkIsSelected(value.id);
            cellValue.isMatched = searchPhrase ? value.searchableName.includes(searchPhrase) : false;
          }
          return acc;
        }, cell),
      ),
    );
  }, [data, selected, searchPhrase, selectedPayersList]);

  const { addToBasket } = useServicesBasketStorageWrapper();

  const onAddToBasket = useCallback(() => {
    if (flatData) {
      const itemsToBasket: CoreService[] = [];
      flatData.forEach(row => {
        Object.values(row).forEach(value => {
          if (value && value.isSelected) itemsToBasket.push(value);
        });
      });
      addToBasket(itemsToBasket);
      clearSelection();
    }
  }, [flatData]);

  const searchHandler = useCallback(
    (phrase: string) => {
      if (!flatData) return;
      setSearchPhrase(normalizeString(phrase));
    },
    [flatData],
  );

  const { classes } = useStyles();

  return (
    <>
      <SearchBarMemoized disabled={isLoading} onSearch={searchHandler} />
      <ServicesTopBarMemoized
        disabledActionButton={!selected.length}
        title={typeDetails && [typeDetails.name.key, typeDetails.name.defaultValue]}
        onActionButtonClick={onAddToBasket}
      />
      <ElementWithLoader isLoading={isLoading || isSubtypesLoading || !isFetched} overlayClassName={classes.loaderOverlay}>
        <ServicesTableMemoized
          activeColumnId={subtypeId}
          columns={columns}
          completedColumns={completedColumns}
          fetchNextPage={fetchNextPage}
          flatData={flatData}
          isFetching={isFetching}
          readyToFetch={readyToFetch}
        />
      </ElementWithLoader>
    </>
  );
};

export default ServicesTypeView;
