import { useTranslation } from 'react-i18next';

import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import { ECareSuspension } from 'api/eCareSuspension/eCareSuspension.types';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

const useGenereteSuspensions = (data: ECareOrder | undefined, sortBtUpdatedAt?: boolean) => {
  const { t } = useTranslation();

  if (!data) return [];

  const { suspensions } = data;

  if (!suspensions) return [];

  if (sortBtUpdatedAt) {
    suspensions.sort(
      (a: any, b: any) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new Date(b.dateFrom) - new Date(a.dateFrom),
    );
  }
  const newSuspensions = suspensions.map((suspension: ECareSuspension) => [
    {
      label: t(proECareOrderMessages.suspensions.dateFrom),
      value: suspension?.dateFrom ? unknownDateToString(suspension.dateFrom) : t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.suspensions.dateTo),
      value: suspension?.dateTo ? unknownDateToString(suspension.dateTo) : t(generalMessages.noData),
    },
    { label: t(proECareOrderMessages.suspensions.reason), value: suspension.reason },
  ]);

  return newSuspensions;
};

export default useGenereteSuspensions;
