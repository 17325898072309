import { useTranslation } from 'react-i18next';

import { eCareStatusDictionary } from 'components/UI/organisms/ECareList/ECareStatus';

const useECareOrderStatusDropdownList = () => {
  const { t } = useTranslation();

  return Object.entries(eCareStatusDictionary).map(([value, labelKey]) => ({
    value,
    label: t(labelKey),
  }));
};

export default useECareOrderStatusDropdownList;
