import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ gridArea: string | undefined }>()((theme, { gridArea }) => ({
  dateRange: {
    gridArea: gridArea || 'unset',
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1),

    '& input': {
      fontSize: '0.7858rem',
    },
  },
}));

export default useStyles;
