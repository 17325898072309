import { ChangeEvent, memo, useMemo } from 'react';

import { KeyboardArrowUp as SortIcon } from '@mui/icons-material';
import {
  CircularProgress,
  Fade,
  Pagination,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef } from '@tanstack/react-table';

import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import { TableDataSort } from 'constants/_types/TableDataSort';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import type { AcceptedCellValues, Config } from 'hooks/useTableData/useTableData';

import useStyles from './CustomTable.styles';
import { DEFAULT_TABLE_CONFIG } from './CustomTableDefaultConfig';

export const testId = createTestIdObject('CustomTable', {
  table: 'table',
});

export type ColumnMetadata = {
  sx?: SxProps;
};

export interface Props<ROW extends Record<string, AcceptedCellValues>> {
  data: ROW[];
  columns: ColumnDef<ROW, any>[];
  config: Config;
  pagesCount?: number;
  page: number;
  onExactPage: (newPage: number) => boolean;
  sort: TableDataSort | null;
  onSort: (newSortKey: keyof ROW) => TableDataSort | null;
  showLoaderCover: boolean;
  averageHeights?: {
    row?: number;
    header?: number;
  };
  rowClassResolver?: (status: Record<string, AcceptedCellValues>) => string | undefined;
}

const CustomTable = <ROW extends Record<string, AcceptedCellValues>>({
  columns,
  data,
  onExactPage,
  sort,
  pagesCount = 0,
  page,
  onSort,
  showLoaderCover,
  averageHeights,
  config: configOverrides,
  rowClassResolver,
}: Props<ROW>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const config = useMemo(() => ({ ...DEFAULT_TABLE_CONFIG, ...configOverrides }), [configOverrides]);

  const isProperKey = (key: unknown): key is ROW => columns.some(({ id }) => key === id);

  const sortHandler = (key: string) => () => {
    if (!isProperKey(key)) {
      throw Error(`Sorting key mismatch, ${key} doesn't exist in columns definition`);
    }

    return onSort(key);
  };

  const { classes, cx } = useStyles();

  if (!data) {
    const rowHeightFallback = 48.72;
    const headerHeightFallback = 56.53;

    const rowHeight = averageHeights?.row || rowHeightFallback;
    const headerHeight = averageHeights?.header || headerHeightFallback;
    const paginationHeight = 64.5;

    const minHeight = headerHeight + paginationHeight + rowHeight * config.pageSize;
    return <LoaderOverlay inner minHeight={`${minHeight}px`} />;
  }

  const activeOrder = (header: string, orderBy: unknown) => header === orderBy;

  return (
    <Table className={classes.table} data-testid={testId.table} size='small'>
      <TableHead className={classes.tableHead}>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isSortable = header.column.getCanSort();
              const { sx } = (header.column.columnDef.meta || {}) as ColumnMetadata;
              return (
                <TableCell key={header.id} sx={sx}>
                  <TableSortLabel
                    active={activeOrder(header.id, sort?.key)}
                    aria-hidden={!activeOrder(header.id, sort?.key)}
                    direction={sort?.direction || 'desc'}
                    disabled={!isSortable}
                    IconComponent={SortIcon}
                    onClick={sortHandler(header.id)}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody className={cx(classes.tableBody, classes.relative, showLoaderCover && classes.blockPointerEvents)}>
        {table.getRowModel().rows.map(row => {
          const className = rowClassResolver ? rowClassResolver(row.original) : '';
          return (
            <TableRow classes={{ root: className }} hover key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          );
        })}
        <Fade in={showLoaderCover}>
          <div className={classes.loaderCover}>
            <CircularProgress />
          </div>
        </Fade>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell align='center' colSpan={columns.length}>
            <Pagination
              className={classes.pagination}
              count={pagesCount}
              page={page}
              shape='rounded'
              size='small'
              variant='outlined'
              onChange={(e: ChangeEvent<unknown>, value) => onExactPage(value)}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default memo(CustomTable);
