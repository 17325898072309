import type { MobileOS } from 'constants/_types/MobileOS';

export const getMobileOperatingSystem = (): MobileOS => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its userAgent also contains 'Android'
  if (/windows phone/i.test(userAgent)) {
    return 'windowsPhone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return 'unknown';
};
