import type { PersonalData } from 'api/_types';

import type { ParsedAddress } from '../../_types/parsedAddress';

export const parseAddressData = (personalData: PersonalData): ParsedAddress => ({
  country: personalData.main_address.country,
  postcode: personalData.main_address.postcode,
  city: personalData.main_address.city,
  street: personalData.main_address.street_name,
  buildingNumber: personalData.main_address.building_number,
  apartmentNumber: personalData.main_address.apartment_number,
});
