import { createContext, useState, useContext, useMemo, type FC, type ReactNode, type Dispatch } from 'react';

type Props = {
  children: ReactNode;
};

type ServicesSearchContextType = {
  setSearchPhrase: Dispatch<string>;
  searchPhrase: string;
};

const ServicesSearchContext = createContext({} as ServicesSearchContextType);

const ServicesSearchContextProvider: FC<Props> = ({ children }) => {
  const [searchPhrase, setSearchPhrase] = useState('');

  const value = useMemo(() => ({ searchPhrase, setSearchPhrase }), [searchPhrase, setSearchPhrase]);

  return <ServicesSearchContext.Provider value={value}>{children}</ServicesSearchContext.Provider>;
};

export const useServicesSearchContext = () => useContext(ServicesSearchContext);

export default ServicesSearchContextProvider;
