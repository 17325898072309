import { Typography } from '@mui/material';

import type { Payer } from 'api/payers/types';
import type { ITableDesktopCardItemRow } from 'components/UI/organisms/_tables/TableDesktopCard/_types/ITableDesktopCardItemRow';

import PayerCell from '../../_components/PayerCell/PayerCell';
import type { ContentRow } from '../../_types/ContentRow';

export interface Params {
  rows: ContentRow[];
  payersData: Payer[] | undefined;
}

export const parseRows = ({ rows, payersData }: Params): ITableDesktopCardItemRow[] =>
  rows.map(row => {
    const { payerId, serviceDeadline, serviceName, serviceId } = row;

    return {
      id: serviceId,
      serviceName: <Typography variant='subtitle3'>{serviceName}</Typography>,
      serviceTime: <Typography variant='subtitle3'>{serviceDeadline}</Typography>,
      servicePayer: <PayerCell payerId={payerId} payersData={payersData || []} />,
    };
  });
