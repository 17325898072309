import type { FC } from 'react';

import { Typography } from '@mui/material';

import useStyles from './SettingSectionHeader.styles';

export interface Props {
  headerText: string;
}

const SettingSectionHeader: FC<Props> = ({ headerText }) => {
  const { classes } = useStyles();

  return (
    <header className={classes.root}>
      <Typography className={classes.header} variant='caption'>
        {headerText}
      </Typography>
    </header>
  );
};

export default SettingSectionHeader;
