import type { FC } from 'react';

import type { BasketItem } from 'constants/_types/Basket';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';

import DetailsItem from '../DetailsItem/DetailsItem';
import useStyles from './SectionContent.styles';

export interface Props {
  isCumulative: boolean;
  items: BasketItem[];
  kind: OrderServiceCoreServicesKinds;
}

const SectionContent: FC<Props> = ({ isCumulative, items, kind }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {items.map(({ id, name }) => (
        <DetailsItem isCumulative={isCumulative} key={id} kind={kind} name={name} serviceItemId={id} />
      ))}
    </div>
  );
};

export default SectionContent;
