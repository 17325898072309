import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import reports from 'api/reports';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import { SymptomCheckerInterviewData } from 'constants/_types/SymptomCheckerInterviewData';
import { SYMPTOM_CHECKER_MESSAGES } from 'constants/_types/SymptomCheckerMessage';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import PATHS from 'constants/router/PATHS';
import { TriageValue } from 'constants/translations/_types';
import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';
import safeJSONParse from 'helpers/safeJSONParse/safeJSONParse';
import useGetReportBlob from 'hooks/useGetReportBlob/useGetReportBlob';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import useUploadAttachments from 'hooks/useUploadAttachments/useUploadAttachments';
import snackbarMessages from 'translations/common/snackbar.mjs';
import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

import { ORDER_SERVICE_ORIGINS } from '../../../../constants/_types/OrderServiceOrigins';
import useStyles from './SymptomChecker.styles';

interface SymptomCheckerTriage {
  consultation: TriageValue;
  consultation_24: TriageValue;
  emergency: TriageValue;
  emergency_ambulance: TriageValue;
  self_care: TriageValue;
}

type Props = {
  src: string;
};

const SymptomChecker: FC<Props> = ({ src }) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const { showSnackbar } = useShowSnackbar();
  const { brandInfo } = useBrandContext();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const navigate = useNavigate();
  const languageCode = useLanguageCode();
  const { createReportBlobFromData } = useGetReportBlob();
  const { uploadAttachments } = useUploadAttachments({
    mutationKey: 'Upload report',
  });

  const [interviewData, setInterviewData] = useState<SymptomCheckerInterviewData | null>(null);
  const [reportId, setReportId] = useState<number | null>(null);

  const saveReport = useMutation('Save report', reports.saveReport(), {
    onSuccess: ({ data }) => {
      setReportId(data.id);
      showSnackbar({
        variant: 'success',
        translationArray: symptomCheckerReportsMessages.created,
      });
    },
  });

  const updateReport = useMutation(`Update report ${reportId}`, reports.updateReport(reportId as number), {
    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        translationArray: symptomCheckerReportsMessages.updated,
      });
    },
  });

  const onMessage = useCallback(
    async ({ data, origin }: MessageEvent) => {
      if (
        process.env.REACT_APP_SYMPTOM_CHECKER_URL_BASE &&
        removeTrailingSlash(process.env.REACT_APP_SYMPTOM_CHECKER_URL_BASE) === removeTrailingSlash(origin)
      ) {
        const message = safeJSONParse(data);
        if (!message) return;
        const messageTriageLevel = message.data.triageLevel as string;
        if (messageTriageLevel && messageTriageLevel !== interviewData?.triageLevel) {
          await showConfirmationDialog({
            title: t(get(symptomCheckerReportsMessages.triageLevelsDescriptions, `${messageTriageLevel}.header`)),
            body: brandInfo?.brand.symptom_checker_triage[messageTriageLevel as keyof SymptomCheckerTriage][languageCode],
            infoOnly: true,
          });
        }
        if (message.action === SYMPTOM_CHECKER_MESSAGES.interviewFinished) {
          setInterviewData(message.data);
          if (reportId) {
            updateReport.mutate({
              data: message.data,
              patient: patient.id,
            });
          } else {
            try {
              const blob = await createReportBlobFromData(message.data);
              const reportFile = new File([blob], `${format(new Date(), DATE_FORMATS.DISPLAY_TIME)}-raport.pdf`);
              const [report] = await uploadAttachments([reportFile]);
              saveReport.mutate({
                data: message.data,
                is_hidden: false,
                is_medical_record: true,
                patient: patient.id,
                report,
              });
            } catch (error) {
              showSnackbar({ variant: 'error', translationArray: snackbarMessages.failure });
            }
          }
        }
        if (message.action === SYMPTOM_CHECKER_MESSAGES.back) {
          navigate(PATHS.ROOT);
        }
        console.log(message.action);
        if (message.action === SYMPTOM_CHECKER_MESSAGES.discussWithDoctor) {
          navigate({ pathname: PATHS.ORDER_SERVICE, search: `?origin=${ORDER_SERVICE_ORIGINS.TELECONSULTATION}&step=1` });
        }
        if (message.action === SYMPTOM_CHECKER_MESSAGES.scheduleAnAppointment) {
          navigate({ pathname: PATHS.ORDER_SERVICE, search: `?origin=${ORDER_SERVICE_ORIGINS.APPOINTMENT}&step=1` });
        }
      }
    },
    [interviewData, reportId, brandInfo, patient],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  const { classes } = useStyles();

  return <iframe className={classes.iframe} src={src} title='Symptom checker' />;
};

export default SymptomChecker;
