import { ECareDeviceDesc, ECareDeviceDescDTO, ECareDeviceType, ECareDeviceTypeDTO } from './eCareDevicesType.types';

const parseSingleECareDeviceTypeForFE = (eCareDeviceDescDTO: ECareDeviceDescDTO): ECareDeviceDesc => ({
  id: eCareDeviceDescDTO.id,
  key: eCareDeviceDescDTO.key,
  defaultValue: eCareDeviceDescDTO.default_value,
});
export const parseSingleECareDevicesForFE = (eCareDeviceTypeDTO: ECareDeviceTypeDTO): ECareDeviceType => ({
  id: eCareDeviceTypeDTO.id,
  description: eCareDeviceTypeDTO.description ? parseSingleECareDeviceTypeForFE(eCareDeviceTypeDTO.description) : null,
  createdAt: new Date(eCareDeviceTypeDTO.created_at),
  updatedAt: new Date(eCareDeviceTypeDTO.updated_at),
  code: eCareDeviceTypeDTO.code,
  name: eCareDeviceTypeDTO.name,
  dateFrom: new Date(eCareDeviceTypeDTO.date_from),
  dateTo: eCareDeviceTypeDTO.date_to ? new Date(eCareDeviceTypeDTO.date_to) : null,
  order: eCareDeviceTypeDTO.order,
});

export const parseECareDevicesListForFE = (eCareDeviceTypeDTO: ECareDeviceTypeDTO[]): ECareDeviceType[] =>
  eCareDeviceTypeDTO.map(parseSingleECareDevicesForFE);
