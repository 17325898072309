import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';

import InputWithDebouncedAction from 'components/UI/atoms/InputWithDebouncedAction/InputWithDebouncedAction';
import GlobalSearchPayerSelector from 'components/UI/molecules/GlobalSearchPayerSelector/GlobalSearchPayerSelector';
import SearchResults from 'components/UI/molecules/SearchResults/SearchResults';
import { useGlobalSearch } from 'components/UI/organisms/GlobalSearchBar/_hooks/useGlobalSearch/useGlobalSearch';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useGlobalSearchStorage from 'storages/globalSearchStorage';
import useLayoutStorage from 'storages/layoutStorage';
import globalSearchMessages from 'translations/specific/globalSearch.mjs';

import useStyles from './GlobalSearchExpanded.styles';

export const testId = createTestIdObject('GlobalSearchExpanded', {
  closeIcon: 'closeIcon',
});

const GlobalSearchExpanded: FC = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { globalSearchIsOpen, updateSearchPhrase, hideGlobalSearch } = useGlobalSearchStorage();

  useGlobalSearch();

  const action = useCallback(
    newPhrase => {
      updateSearchPhrase(newPhrase);
    },
    [updateSearchPhrase],
  );

  useEffect(() => {
    if (inputRef.current) {
      if (globalSearchIsOpen) inputRef.current.focus();
      else inputRef.current.blur();
    }
  }, [globalSearchIsOpen]);

  const { isOpen } = useLayoutStorage();

  const { classes } = useStyles({ isDrawerOpen: isOpen, isVisible: globalSearchIsOpen });
  return (
    <div aria-hidden={!globalSearchIsOpen} className={classes.root} role='search'>
      <div className={classes.searchContainer}>
        <InputWithDebouncedAction
          action={action}
          formControlProps={{ className: classes.formInput }}
          inputProps={{
            tabIndex: globalSearchIsOpen ? undefined : 0,
            placeholder: t(globalSearchMessages.searchInput.placeholder),
            classes: { root: classes.inputRoot, notchedOutline: classes.inputOutline },
            inputRef,
          }}
          startAdornment={<SearchIcon />}
        />
        <div className={classes.selectWrapper}>
          <GlobalSearchPayerSelector inputDropdownProps={{ className: classes.select }} />
        </div>
        <ButtonBase className={classes.closeButton} data-testid={testId.closeIcon} disableRipple onClick={hideGlobalSearch}>
          <CloseIcon color='secondary' />
        </ButtonBase>
      </div>
      <SearchResults />
    </div>
  );
};

export default GlobalSearchExpanded;
