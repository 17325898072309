import { FC, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareOrder from 'api/eCareOrder/eCareOrder';
import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from '../ProfessionalEOrderFormDialog.styles';

type Props = {
  orderId: string;
  close: () => void;
};
const FORM_ID = 'orderForm';

const OrderForm: FC<Props> = ({ close, orderId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { control, handleSubmit, formState, getValues } = useForm<any>();

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ORDER, orderId], [orderId]);

  const data: ECareOrder | undefined = queryClient.getQueryData(queryKey);

  const statusMutation = useMutation(eCareOrder.patchECareOrderData(orderId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, { ...data, newStatus });
      close();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const onSubmit = () => {
    statusMutation.mutate({
      service_termination_reason: getValues().serviceTerminationReason,
    });
  };

  return (
    <DialogFormWrapper formState={formState} id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.inputWrapper}>
        <FormInputText
          control={control}
          defaultValue={data?.serviceTerminationReason || ''}
          label={t(proECareOrderMessages.orderForm.serviceTerminationReason)}
          name='serviceTerminationReason'
          required
        />
      </div>
    </DialogFormWrapper>
  );
};

export default OrderForm;
