import type { FC } from 'react';

import create from 'zustand';

import type { FormBarButtons } from 'constants/styles/_types/FormBarButtons';

export interface FormActionButtonProps {
  id?: number;
  label?: string;
  action: () => void;
  disabled?: boolean;
  hasLeftArrow?: boolean;
  gridArea?: FormBarButtons;
}

interface FormActionButton {
  buttonProps: FormActionButtonProps;
  buttonComponent: FC<FormActionButtonProps>;
}

export type FormActions = FormActionButton[];

type BottomFixedContainerStorage = {
  globalForm: FormActions | null;
  mobileGridTemplateAreas: string | null;
  areGlobalFormButtonsOpen: boolean;
  openGlobalForm: (formActions: FormActions, mobileGridTemplateAreas: string) => void;
  openGlobalFormAndDisableOuterElements: (formActions: FormActions, mobileGridTemplateAreas: string) => void;
  closeGlobalForm: () => void;
  globalFormLoading: boolean;
  startGlobalFormLoading: () => void;
  endGlobalFormLoading: () => void;
  buttonsBarHeight: number | null;
  setButtonsBarHeight: (currentHeight: number | null) => void;
  shouldShowMobileBasket: boolean;
  setShouldShowMobileBasket: (newState: boolean) => void;
};

const useBottomFixedContainerStorage = create<BottomFixedContainerStorage>(set => ({
  globalForm: null,
  mobileGridTemplateAreas: null,
  areGlobalFormButtonsOpen: false,
  openGlobalForm: (formActions: FormActions, mobileGridTemplateAreas: string) =>
    set({ globalForm: formActions, mobileGridTemplateAreas, areGlobalFormButtonsOpen: false }),
  openGlobalFormAndDisableOuterElements: (formActions: FormActions, mobileGridTemplateAreas: string) =>
    set({ globalForm: formActions, mobileGridTemplateAreas, areGlobalFormButtonsOpen: true }),
  closeGlobalForm: () => set({ globalForm: null, areGlobalFormButtonsOpen: false }),

  globalFormLoading: false,
  startGlobalFormLoading: () => set({ globalFormLoading: true }),
  endGlobalFormLoading: () => set({ globalFormLoading: false }),

  buttonsBarHeight: null,
  setButtonsBarHeight: currentHeight => set({ buttonsBarHeight: currentHeight }),

  shouldShowMobileBasket: false,
  setShouldShowMobileBasket: shouldShowMobileBasket => set({ shouldShowMobileBasket }),
}));

export default useBottomFixedContainerStorage;
