import { useState } from 'react';

import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import users, { PasswortResetData } from 'api/users';
import { ResetPasswordFormInput } from 'components/UI/organisms/ResetPasswordForm/ResetPasswordForm';
import PATHS from 'constants/router/PATHS';
import useMutationWithRecaptcha from 'hooks/useMutationWithRecaptcha/useMutationWithRecaptcha';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';

export const RESET_PASSWORD_MUTATION_KEY = 'Reset password mutation';

type UseResetPasswordMutationArguments = {
  setError: UseFormSetError<ResetPasswordFormInput>;
};

const useResetPasswordMutation = ({ setError }: UseResetPasswordMutationArguments) => {
  const navigate = useNavigate();
  const [generalErrors, setGeneralErrors] = useState<string[]>();
  const { mutateWithRecaptcha } = useMutationWithRecaptcha('Reset_password');

  const onError = useOnBackendFormError<ResetPasswordFormInput, ResetPasswordFormInput>({
    setError,
    setGeneralErrors,
  });

  const { mutate, isLoading } = useMutation(RESET_PASSWORD_MUTATION_KEY, users.passwordReset(), {
    retry: 0,
    onSuccess: () => {
      navigate(`/${PATHS.AUTH_RESET_PASSWORD_FORM_SUCCESS}`);
    },
    onError,
  });

  const resetPasswordMutate = async (mutationData: Omit<PasswortResetData, 'recaptcha'>) => {
    mutateWithRecaptcha<PasswortResetData>(mutate, mutationData);
  };

  return { resetPasswordMutate, isResetPasswordMutating: isLoading, resetPasswordMutationErrors: generalErrors };
};

export default useResetPasswordMutation;
