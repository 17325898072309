import { useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useServicesSearchContext } from 'components/context/ServicesSearchContext/ServicesSearchContext';
import useGetServicesCatalog from 'components/pages/_userServices/ServicesPage/_hooks/useGetServicesCatalog/useGetServicesCatalog';
import useGetServicesGroups from 'components/pages/_userServices/ServicesPage/_hooks/useGetServicesGroups/useGetServicesGroups';
import SearchBar from 'components/UI/molecules/SearchBar/SearchBar';
import { SERVICES_PATHS } from 'constants/router/PATHS';
import servicesMessages from 'translations/specific/services.mjs';

import ServicesPageContentWrapper from '../ServicesPageContentWrapper/ServicesPageContentWrapper';
import ServicesReviewGroups from '../ServicesReviewGroups/ServicesReviewGroups';
import ServicesReviewGroupsWrapper from '../ServicesReviewGroupsWrapper/ServicesReviewGroupsWrapper';
import ServicesReviewProfiles from '../ServicesReviewProfiles/ServicesReviewProfiles';
import ServicesReviewTable from '../ServicesReviewTable/ServicesReviewTable';
import { useTelemedicalMonitoringHighlight } from './_hooks/useTelemedicalMonitoringHighlight/useTelemedicalMonitoringHighlight';

export type Props = {
  enableQueries: boolean;
};

const ServicesTableView: FC<Props> = ({ enableQueries }) => {
  const { t } = useTranslation();

  const { data: servicesData, isLoading: isServicesLoading } = useGetServicesCatalog(enableQueries);

  const { groupsToDisplay, isLoading: isGroupsLoading } = useGetServicesGroups(enableQueries);

  const { setSearchPhrase } = useServicesSearchContext();

  const { isHighlighted } = useTelemedicalMonitoringHighlight();

  const areEmptyGroups = useMemo(() => !groupsToDisplay || !groupsToDisplay.length, [groupsToDisplay]);

  return (
    <>
      <SearchBar onSearch={setSearchPhrase} />
      <ServicesPageContentWrapper>
        <ServicesReviewGroupsWrapper areEmptyGroups={areEmptyGroups}>
          <ServicesReviewProfiles />
          <ServicesReviewGroups
            areEmptyGroups={areEmptyGroups}
            fallbackMessage={t(servicesMessages.noResultsForPaymentType)}
            groupsToDisplay={groupsToDisplay}
            isHighlighted={isHighlighted}
            isLoading={isGroupsLoading}
          />
        </ServicesReviewGroupsWrapper>
        <ServicesReviewTable
          data={servicesData || null}
          isLoading={isServicesLoading}
          pathToGenerator={({ headerId, entityId }) =>
            generatePath(SERVICES_PATHS.SERVICES_SUBTYPE, { typeId: `${headerId}`, subtypeId: `${entityId}` })
          }
          title={t(servicesMessages.tableTitles.general)}
        />
      </ServicesPageContentWrapper>
    </>
  );
};

export default ServicesTableView;
