import { makeStyles } from 'tss-react/mui';

import ICON_SIZES_STYLES from 'constants/styles/ICON_SIZES_STYLES';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  expandButton: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    minWidth: 'unset',
    maxWidth: '350px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
  },
  flagContainer: {
    display: 'flex',
  },
  flag: {
    border: `thin solid ${theme.palette.grey[200]}`,
    width: ICON_SIZES_STYLES.small.width,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  popper: {
    width: '100%',
    transform: 'translate3d(0, -49px, 0)',
    textTransform: 'capitalize',
  },
  chevron: {
    width: ICON_SIZES_STYLES.small.width,
    height: ICON_SIZES_STYLES.small.height,
  },
}));

export default useStyles;
