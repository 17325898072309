import { FC, useState } from 'react';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import { ReactComponent as EditIcon } from 'assets/icons/editPro.svg';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import theme from 'styles/theme';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import ProfessionalEOrderFormDialog, { OrderFormName } from '../_dialogs/ProfessionalEOrderFormDialog/ProfessionalEOrderFormDialog';
import ProfessionalEOrderHistoryDialog from '../_dialogs/ProfessionalEOrderHistoryDialog/ProfessionalEOrderHistoryDialog';
import useGenereteSuspensions from './_hooks/useGenereteSuspensions/useGenereteSuspensions';
import useTheNearestSuspension from './_hooks/useTheNearestSuspension/useTheNearestSuspension';
import useTrainingData from './_hooks/useTrainingData/useTrainingData';
import useStyles from './ProfessionalECareOrderSuspensions.styles';

type Props = {
  data: ECareOrder;
};

const ProfessionalECareOrderSuspensions: FC<Props> = ({ data }) => {
  const { orderId } = useParams() as unknown as { orderId: string };
  const { t } = useTranslation();
  const { cx, classes } = useStyles();
  const [formName, setFormName] = useState<OrderFormName>('orderForm');
  const [modalHeader, setModalHeader] = useState('');
  const [isNewForm, setIsNewForm] = useState(false);

  const training = useTrainingData(data);
  const suspensions = useGenereteSuspensions(data, true);
  const getTheNearestSuspension = useTheNearestSuspension(data?.suspensions);
  const suspension = getTheNearestSuspension?.nearestSuspension ? getTheNearestSuspension.nearestSuspension : null;

  const [isHistoryDialogOpen, openHistoryDialog, closeHistoryDialog] = useBooleanState(false);

  const [isOpenProfessionalEOrderEditDialog, openProfessionalEOrderEditDialog, closeProfessionalEOrderEditDialog] = useBooleanState(false);
  const openDialogHandler = (fName: OrderFormName, header: string, isNew = false) => {
    setFormName(fName);
    setModalHeader(header);
    setIsNewForm(isNew);
    openProfessionalEOrderEditDialog();
  };

  return (
    <CardContentContainer>
      <div className={classes.container}>
        <div>
          <SectionWithTitle
            title={t(proECareOrderMessages.columns.training)}
            titleIcon={
              <EditIcon
                className={classes.icon}
                onClick={() => {
                  openDialogHandler('trainingForm', t(proECareOrderMessages.dialog.trainingEditTitle));
                }}
              />
            }
          >
            <DefinitionList columns={2} definitions={training} fontSize='small' />
          </SectionWithTitle>
        </div>
        <div>
          <SectionWithTitle
            className={cx(classes.personalSection, classes.multiDefinitionList)}
            title={t(proECareOrderMessages.columns.suspensions)}
            titleIcon={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                <Button
                  size='small'
                  sx={{ marginRight: theme.spacing(1) }}
                  variant='contained'
                  onClick={() => {
                    openDialogHandler('suspensionsForm', t(proECareOrderMessages.dialog.addSuspension), true);
                  }}
                >
                  {t(proECareOrderMessages.dialog.addSuspension)}
                </Button>
                {suspension?.id && (
                  <EditIcon
                    className={classes.icon}
                    onClick={() => {
                      openDialogHandler('suspensionsForm', t(proECareOrderMessages.dialog.suspensionEditTitle), false);
                    }}
                  />
                )}
                {suspensions && suspensions.length > 0 && (
                  <Tooltip enterDelay={750} title={t(proECareOrderMessages.tooltip.suspensions) as string}>
                    <InfoIcon sx={{ width: '20px' }} />
                  </Tooltip>
                )}
              </Box>
            }
            titleRightSideComponent={
              suspensions.length > 0 && (
                <Button variant='outlined' onClick={() => openHistoryDialog()}>
                  {t(proECareOrderMessages.dialog.suspensionHistory)}
                </Button>
              )
            }
          >
            {suspensions && suspensions.length > 0 && (
              <DefinitionList columns={3} definitions={getTheNearestSuspension?.nearestSuspensionForDefinition || []} fontSize='small' />
            )}
          </SectionWithTitle>
        </div>
      </div>

      {data?.suspensions && (
        <ProfessionalEOrderHistoryDialog close={closeHistoryDialog} isOpen={isHistoryDialogOpen} suspensions={data.suspensions} />
      )}
      <ProfessionalEOrderFormDialog
        close={closeProfessionalEOrderEditDialog}
        formName={formName}
        header={modalHeader}
        isNew={isNewForm}
        isOpen={isOpenProfessionalEOrderEditDialog}
        orderId={orderId}
        suspension={suspension}
      />
    </CardContentContainer>
  );
};

export default ProfessionalECareOrderSuspensions;
