import type { FC } from 'react';

import ErrorIcon from '@mui/icons-material/Error';

import { IconAlertProps } from 'constants/_types/IconAlertProps';

import useStyles from './IconErrorAlert.styles';

export interface Props extends IconAlertProps {
  isAlternativeStyle?: boolean;
}

const IconErrorAlert: FC<Props> = ({ isBoxShadow = false, isAlternativeStyle = false }) => {
  const { classes } = useStyles({ isBoxShadow, isAlternativeStyle });

  // using fontSize='large' makes 35px width and height instead of 32px - therefore classes.icon is needed here
  return (
    <div className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <div className={classes.iconBackground} />
    </div>
  );
};

export default IconErrorAlert;
