import type { AddressParsed } from 'api/_parsedTypes';
import type { Address } from 'api/_types';

export const emptyAddress = {
  street: '',
  city: '',
  country: '',
  buildingNumber: '',
  postcode: '',
  apartmentNumber: '',
};

export const parseAddress = (address?: Address): AddressParsed => {
  if (!address) return emptyAddress;

  return {
    street: address.street_name,
    city: address.city,
    country: address.country,
    buildingNumber: address.building_number,
    postcode: address.postcode,
    apartmentNumber: address.apartment_number || '',
  };
};
