import type { FC, ReactElement, Ref } from 'react';
import { forwardRef } from 'react';

import { Dialog, Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';

import useStyles from './DialogLoader.styles';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) => <Grow ref={ref} {...props} />,
);

type Props = { isOpen: boolean };

const DialogLoader: FC<Props> = ({ isOpen }) => {
  const { classes } = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      open={isOpen}
      scroll='body'
      TransitionComponent={Transition}
      onBackdropClick={() => null}
    >
      <LoaderOverlay inner />
    </Dialog>
  );
};

export default DialogLoader;
