import type { FC } from 'react';

import BaseColumnCellRenderer from 'components/UI/molecules/BaseColumnCellRenderer/BaseColumnCellRenderer';
import { CellRendererProps } from 'components/UI/molecules/ColumnsDisplaySet/_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';

export type Props = {
  isSearchActive: boolean;
  toggleSelected: (id: number) => void;
  checkIsSelected: (id: number) => boolean;
  checkIsMatched: (id: number) => boolean;
} & Omit<CellRendererProps, 'config'>;

const SelectableSearchableCell: FC<Props> = ({ toggleSelected, isSearchActive, checkIsSelected, checkIsMatched, ...cellProps }) => {
  const isSelected = checkIsSelected(cellProps.entity?.id);
  const isMatched = checkIsMatched(cellProps.entity?.id);
  return (
    <BaseColumnCellRenderer
      isMatched={isMatched}
      isSearchActive={isSearchActive}
      isSelected={isSelected}
      toggleSelected={toggleSelected}
      {...cellProps}
    />
  );
};

export default SelectableSearchableCell;
