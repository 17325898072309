import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  summeryDescription: {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export default useStyles;
