import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import ServiceDates from '../ServiceDates/ServiceDates';
import useStyles from './SectionHeader.styles';

export interface Props {
  isCumulative: boolean;
  kind: OrderServiceCoreServicesKinds;
}

const SectionHeader: FC<Props> = ({ isCumulative, kind }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ isCumulative });

  return (
    <div className={classes.root}>
      <div className={classes.headerName}>
        <Typography variant='captionBold'>{t(orderServiceMessages.basket.typesSections[kind])}</Typography>
      </div>
      {isCumulative && <ServiceDates kind={kind} />}
    </div>
  );
};

export default SectionHeader;
