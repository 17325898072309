import { useState } from 'react';

import { History as HistoryIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import patients, { getPatientKeyGenerator } from 'api/patient/patients';
import { ReactComponent as EditIcon } from 'assets/icons/editPro.svg';
import SmallIconButton from 'components/UI/atoms/SmallIconButton/SmallIconButton';
import InputText from 'components/UI/molecules/InputText/InputText';
import PatientHealthDetailsNoteEditDialog from 'components/UI/organisms/PatientHealthDetails/_components/PatientHealthDetailsNoteEditDialog/PatientHealthDetailsNoteEditDialog';
import PatientHealthDetailsNoteHistoryDialog from 'components/UI/organisms/PatientHealthDetails/_components/PatientHealthDetailsNoteHistoryDialog/PatientHealthDetailsNoteHistoryDialog';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import useStyles from './usePatienceInfo.styles';

type Dialog = 'socialSituation-history' | 'healthCondition-history' | 'socialSituation-edit' | 'healthCondition-edit';

type Props = {
  patientId: number | null | undefined;
  hasHistory?: boolean;
  isClosed?: boolean;
};

const usePatienceInfo = ({ patientId, hasHistory, isClosed }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [currentDialog, setCurrentDialog] = useState<null | Dialog>(null);
  const openDialogFactory = (dialog: Dialog) => () => setCurrentDialog(dialog);
  const closeDialog = () => setCurrentDialog(null);

  const queryKey = getPatientKeyGenerator(patientId as number, true);
  const { data } = useQuery(queryKey, patients.getPatient(patientId as number, true), {
    enabled: !!patientId,
    refetchOnWindowFocus: false,
  });

  const healthCondition = (
    <>
      <InputText
        classes={{ label: classes.label, input: classes.input }}
        label={
          <>
            {t(proECareAlarmMessages.alarmCard.healthConditions)}
            {!isClosed && (
              <SmallIconButton className={classes.icons} disabled={!data} onClick={openDialogFactory('healthCondition-edit')}>
                <EditIcon />
              </SmallIconButton>
            )}
            {hasHistory && (
              <SmallIconButton className={classes.icons} onClick={openDialogFactory('healthCondition-history')}>
                <HistoryIcon />
              </SmallIconButton>
            )}
          </>
        }
        name='healthCondition'
        readOnly
        value={data?.healthCondition?.content || t(generalMessages.noData)}
      />
      <>
        <PatientHealthDetailsNoteHistoryDialog
          close={closeDialog}
          header={t(proPatientMessages.healthConditionNoteLabel)}
          historyData={data?.socialSituation?.logs}
          isOpen={currentDialog === 'healthCondition-history'}
        />
        {data && (
          <PatientHealthDetailsNoteEditDialog
            close={closeDialog}
            header={t(proPatientMessages.healthConditionNoteLabel)}
            initialValue={data.healthCondition?.content}
            isOpen={currentDialog === 'healthCondition-edit'}
            mutationFn={patients.createOrUpdateHealthConditions}
            noteId={data.healthCondition?.id}
            patientId={data.id}
          />
        )}
      </>
    </>
  );

  const socialSituation = (
    <>
      <InputText
        classes={{ label: classes.label, input: classes.input }}
        label={
          <>
            {t(proECareAlarmMessages.alarmCard.socialSituation)}{' '}
            {!isClosed && (
              <SmallIconButton className={classes.icons} disabled={!data} onClick={openDialogFactory('socialSituation-edit')}>
                <EditIcon />
              </SmallIconButton>
            )}
            {hasHistory && (
              <SmallIconButton className={classes.icons} onClick={openDialogFactory('healthCondition-history')}>
                <HistoryIcon />
              </SmallIconButton>
            )}
          </>
        }
        name='socialSituation'
        readOnly
        value={data?.socialSituation?.content || t(generalMessages.noData)}
      />
      <>
        <PatientHealthDetailsNoteHistoryDialog
          close={closeDialog}
          header={t(proPatientMessages.healthConditionNoteLabel)}
          historyData={data?.socialSituation?.logs}
          isOpen={currentDialog === 'socialSituation-history'}
        />
        {data && (
          <PatientHealthDetailsNoteEditDialog
            close={closeDialog}
            header={t(proPatientMessages.socialSituationNoteLabel)}
            initialValue={data.socialSituation?.content}
            isOpen={currentDialog === 'socialSituation-edit'}
            mutationFn={patients.createOrUpdateSocialSituation}
            noteId={data.socialSituation?.id}
            patientId={data.id}
          />
        )}
      </>
    </>
  );

  return [
    {
      label: '',
      value: healthCondition,
    },
    {
      label: '',
      value: socialSituation,
    },
  ];
};

export default usePatienceInfo;
