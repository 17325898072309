import type { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import CancelButton from 'components/UI/molecules/BottomFixedContainer/_components/CancelButton/CancelButton';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import patientKeylockCode from '../../../../../api/patientKeylockCode/patientKeylockCode';
import LoaderOverlay from '../../../molecules/LoaderOverlay/LoaderOverlay';

type Props = {
  isOpen: boolean;
  close: () => void;
  patientId: number;
};

const ProfessionalAlarmKeyBoxDialog: FC<Props> = ({ isOpen, close, patientId }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery([QUERY_KEYS.PATIENT_KEYLOCK_CODE, patientId], patientKeylockCode.getPatientKeylockCode(patientId), {
    enabled: isOpen,
  });

  const keyBoxDetails = [
    {
      label: t(proECareAlarmMessages.alarmCard.dialogs.keyBoxLabel),
      value: data?.keylockCode || t(proECareAlarmMessages.alarmCard.dialogs.keyBoxEmpty),
    },
  ];

  return (
    <DialogWrapper
      close={close}
      customActions={<CancelButton action={close} label={t(generalMessages.close)} />}
      dialogProps={{
        maxWidth: 'sm',
      }}
      header={t(proECareAlarmMessages.alarmCard.dialogs.keyBox)}
      isOpen={isOpen}
    >
      {isLoading ? <LoaderOverlay inner /> : <DefinitionList columns={1} definitions={keyBoxDetails} />}
    </DialogWrapper>
  );
};

export default ProfessionalAlarmKeyBoxDialog;
