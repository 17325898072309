import { FC, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import { ReactComponent as EditIcon } from 'assets/icons/editPro.svg';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './InterventionDateForm.styles';

type FormInput = {
  intervention_date: string;
};

type Props = {
  alarmId: string;
  defaultValues: Date | null;
};

const UpdateInterventionDateForm: FC<Props> = ({ alarmId, defaultValues }) => {
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ALARMS, alarmId], [alarmId]);
  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareAlarms.patchECareAlarm(alarmId as string), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  /* Send intervention date when date doesn't exist in BE */
  useEffect(() => {
    if (defaultValues) return;
    statusMutation.mutate({
      intervention_date: new Date().toISOString(),
    });
  }, []);

  const { control, getValues } = useForm<FormInput>();

  const updateDateHandler = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.changeInterventionDate),
      body: (
        <FormInputDate
          control={control}
          defaultValue={defaultValues}
          inputProps={{
            timePicker: true,
          }}
          label={t(proECareAlarmMessages.alarmCard.interventionDate)}
          name='intervention_date'
          required
        />
      ),
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed && getValues('intervention_date'))
      statusMutation.mutate({
        intervention_date: getValues('intervention_date'),
      });
  };

  return <EditIcon className={classes.icon} onClick={updateDateHandler} />;
};

export default UpdateInterventionDateForm;
