import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ backgroundColor: string }>()((theme, { backgroundColor }) => ({
  iconBackground: {
    backgroundColor,
    padding: theme.spacing(1.25),
  },
}));

export default useStyles;
