const ecarePhoneCallMessages = {
  title: ['eCarePhoneCall>>title', 'Połączenia teleopieki'],
  personalData: ['eCarePhoneCall>>personalData', 'Odbiorca usługi'],
  patientProfile: ['eCarePhoneCall>>patientProfile', 'Profil pacjenta'],
  noLastSyncData: ['eCarePhoneCall>>noLastSyncData', 'Synchronizacja z CUS jeszcze nie została wykonana'],
  syncWithCusInfo: ['eCarePhoneCall>>alarmCard>>buttons>>syncWithCusInfo', 'Ostatnia synchronizacja:'],
  personal: {
    firstName: ['eCarePhoneCall>>personal>>firstName', 'Imię'],
    lastName: ['eCarePhoneCall>>personal>>lastName', 'Nazwisko'],
    pesel: ['eCarePhoneCall>>personal>>pesel', 'Pesel'],
    phoneNumber: ['eCarePhoneCall>>personal>>phoneNumber', 'Numer telefonu'],
    projectUserNumber: ['eCarePhoneCall>>personal>>projectUserNumber', 'Kod Pacjenta (Bitrix)'],
  },
  operator: {
    title: ['eCarePhoneCall>>operator>>title', 'Operator'],
    firstName: ['eCarePhoneCall>>operator>>firstName', 'Imię'],
    lastName: ['eCarePhoneCall>>operator>>lastName', 'Nazwisko'],
    phoneNumber: ['eCarePhoneCall>>operator>>phoneNumber', 'Numer telefonu'],
    email: ['eCarePhoneCall>>operator>>email', 'Email'],
  },
  detail: {
    callReceivedDate: ['eCarePhoneCall>>detail>>callReceivedDate', 'Data rozpoczęcia połączenia'],
    callEndDate: ['eCarePhoneCall>>detail>>callEndDate', 'Data zakończenia połączenia'],
    phoneNumber: ['eCarePhoneCall>>detail>>phoneNumber', 'Numer telefonu'],
    caller: ['eCarePhoneCall>>detail>>caller', 'Dzwoniący'],
    status: ['eCarePhoneCall>>detail>>status', 'Status'],
    summary: ['eCarePhoneCall>>detail>>summary', 'Podsumowanie'],
    addSummary: ['eCarePhoneCall>>detail>>addSummary', 'Dodaj podsumowanie'],
    editSummary: ['eCarePhoneCall>>detail>>editSummary', 'Edytuj podsumowanie'],
    serviceRecipient: ['eCarePhoneCall>>detail>>serviceRecipient', 'Odbiorca usługi'],
    moreDetailsCell: ['eCarePhoneCall>>detail>>moreDetailsCell', 'Więcej szczegółów'],
    subscription: ['eCarePhoneCall>>detail>>subscription', 'Abonament'],
    program: ['eCarePhoneCall>>detail>>program', 'Program'],
    payer: ['eCarePhoneCall>>detail>>payer', 'Gmina / Płatnik'],
  },
  table: {
    id: ['eCarePhoneCall>>table>>id', 'ID'],
    status: ['eCarePhoneCall>>table>>status', 'Status połączenia'],
    subscription: ['eCarePhoneCall>>table>>subscription', 'Abonament'],
    callDate: ['eCarePhoneCall>>table>>callDate', 'Data i godz. połączenia'],
    firstName: ['eCarePhoneCall>>table>>firstName', 'Imię'],
    lastName: ['eCarePhoneCall>>table>>lastName', 'Nazwisko'],
    pesel: ['eCarePhoneCall>>table>>pesel', 'PESEL / Wiek'],
    address: ['eCarePhoneCall>>table>>address', 'Ulica'],
    city: ['eCarePhoneCall>>table>>city', 'Miasto'],
    street: ['eCarePhoneCall>>table>>street', 'Ulica'],
    postalCode: ['eCarePhoneCall>>table>>postalCode', 'Kod'],
    callType: ['eCarePhoneCall>>table>>alarmType', 'Rodzaj połączenia'],
    payer: ['eCarePhoneCall>>table>>payer', 'Gmina / Płatnik'],
    carer: ['eCarePhoneCall>>table>>carer', 'Operator'],
    projectUserNumber: ['eCarePhoneCall>>table>>projectUserNumber', 'Kod Pacjenta\xa0(Bitrix)'],
  },
  filters: {
    id: ['eCarePhoneCall>>filters>>id', 'ID'],
    status: ['eCarePhoneCall>>filters>>status', 'Status połączenia'],
    subscription: ['eCarePhoneCall>>filters>>subscription', 'Abonament'],
    callDate: ['eCarePhoneCall>>filters>>callDate', 'Data i godzina połączenia'],
    firstName: ['eCarePhoneCall>>filters>>firstName', 'Imię'],
    lastName: ['eCarePhoneCall>>filters>>lastName', 'Nazwisko'],
    pesel: ['eCarePhoneCall>>filters>>pesel', 'PESEL'],
    address: ['eCarePhoneCall>>filters>>address', 'Ulica'],
    city: ['eCarePhoneCall>>filters>>city', 'Miasto'],
    postalCode: ['eCarePhoneCall>>filters>>postalCode', 'Kod pocztowy'],
    callType: ['eCarePhoneCall>>filters>>alarmType', 'Rodzaj połączenia'],
    payer: ['eCarePhoneCall>>filters>>payer', 'Gmina / Płatnik'],
    carer: ['eCarePhoneCall>>filters>>carer', 'Operator'],
    projectUserNumber: ['eCarePhoneCall>>filters>>projectUserNumber', 'Kod Pacjenta (Bitrix)'],
  },
  caller: {
    ODBIORCA_USLUGI: ['eCarePhoneCall>>caller>>ODBIORCA_USLUGI', 'Odbiorca usługi'],
    PRACOWNIK_TELEOPIEKI: ['eCarePhoneCall>>caller>>PRACOWNIK_TELEOPIEKI', 'Pracownik teleopieki'],
  },
  phoneCallStatus: ['eCarePhoneCall>>phoneCallStatus', 'Status połączenia'],
  phoneCallType: ['eCarePhoneCall>>phoneCallType', 'Typ połączenia'],
  eCareOrderDropdown: {
    label: ['eCarePhoneCall>>eCareOrderDropdown>>label', 'Powiązane zlecenie'],
    infoEnabled: [
      'eCarePhoneCall>>eCareOrderDropdown>>infoEnabled',
      'Tu możesz zmienić zlecenie w sprawie którego zostało wykonane połączenie',
    ],
    infoDisabled: ['eCarePhoneCall>>eCareOrderDropdown>>infoDisabled', 'Brak możliwości zmiany zlecenia'],
    changeSuccess: ['eCarePhoneCall>>eCareOrderDropdown>>changeSuccess', 'Powiązane zlecenie zostało zmienione'],
  },
  status: {
    unanswered: ['eCarePhoneCall>>status>>unanswered', 'Nieodebrane'],
    answered: ['eCarePhoneCall>>status>>answered', 'Odebrane'],
    abandoned: ['eCarePhoneCall>>status>>abandoned', 'Porzucone'],
    finished: ['eCarePhoneCall>>status>>finished', 'Wykonane'],
  },
  callType: {
    incoming: ['eCarePhoneCall>>callType>>incoming', 'Przychodzące'],
    outgoing: ['eCarePhoneCall>>callType>>outgoing', 'Wychodzące'],
  },
  confirmationDialog: {
    confirmationDialogTitle: ['ecarePhoneCallMessages>>confirmationDialog>>confirmationDialogTitle', 'Zmiana statusu połączenia'],
    confirmationDialogBody: [
      'ecarePhoneCallMessages>>confirmationDialog>>confirmationDialogBody',
      'Czy na pewno chesz zmienić status połączenia?',
    ],
  },
  interventionPhoneCallNote: ['ecarePhoneCallMessages>>interventionPhoneCallNote', 'Notatka (Idzie do partnera)'],
  interventionPhoneCallNoteTitle: ['ecarePhoneCallMessages>>interventionPhoneCallNoteTitle', 'Notatki z rozmów'],
  eCarePhoneCallPartnerNotesTable: {
    id: ['ecarePhoneCallMessages>>eCareAlarmsPartnerNotesTable>>id', 'Sporządził'],
    creationTime: ['ecarePhoneCallMessages>>eCareAlarmsPartnerNotesTable>>creationTime', 'Data utworzenia'],
    text: ['ecarePhoneCallMessages>>eCareAlarmsPartnerNotesTable>>text', 'Treść'],
  },
  eCarePhoneCallInternalNotesTable: {
    user: ['ecarePhoneCallMessages>>eCareAlarmsInternalNotesTable>>user', 'Sporządził'],
    creationTime: ['ecarePhoneCallMessages>>eCareAlarmsInternalNotesTable>>creationTime', 'Data utworzenia'],
    text: ['ecarePhoneCallMessages>>eCareAlarmsInternalNotesTable>>text', 'Treść'],
  },
  bitrix: {
    operator: ['ecarePhoneCallMessages>>bitrix>>operator', 'Osoba, która odebrała połączenie alarmowe'],
    projectUserNumber: ['ecarePhoneCallMessages>>bitrix>>projectUserNumber', 'Kod pacjenta (Bitrix)'],
    tooltip: {
      addNote: ['ecarePhoneCallMessages>>bitrix>>tooltip>>addNote', 'Notatka zostanie wysłana do CUS'],
    },
  },
};

export default ecarePhoneCallMessages;
