import type { FC } from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Inputs } from 'components/UI/molecules/EditableDataField/EditableDataField';
import FormInputAutocomplete from 'components/UI/organisms/_formInputs/FormInputAutocomplete/FormInputAutocomplete';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputDropdown from 'components/UI/organisms/_formInputs/FormInputDropdown/FormInputDropdown';
import FormInputPhoneNumber from 'components/UI/organisms/_formInputs/FormInputPhoneNumber/FormInputPhoneNumber';
import FormInputSwitch from 'components/UI/organisms/_formInputs/FormInputSwitch/FormInputSwitch';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import { resolvePhoneNumberValidation } from 'services/_validators/resolvePhoneNumberValidation/resolvePhoneNumberValidation';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';

import useStyles from './DynamicInput.styles';

export type Props = {
  form: UseFormReturn<any>;
  inputs: Inputs;
  label: string;
  gridArea?: string;
};

const DynamicInput: FC<Props> = ({ form, inputs, gridArea, label }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ gridArea });

  return (
    <div className={classes.input}>
      {Object.entries(inputs).map(([name, { options, type, inputProps, label: inputLabel, rules, mask, required, tiny }]) => {
        const commonProps = { key: name, control: form.control, label: inputLabel || label, name, inputProps, rules, required, tiny };

        switch (type) {
          case 'text': {
            return <FormInputText {...commonProps} mask={mask} />;
          }
          case 'textarea': {
            return <FormInputText {...commonProps} mask={mask} multiline rows={6} />;
          }
          case 'dropdown': {
            return <FormInputDropdown {...commonProps} options={options || []} />;
          }
          case 'autocomplete': {
            return <FormInputAutocomplete {...commonProps} options={options || []} />;
          }
          case 'date': {
            return <FormInputDate {...commonProps} />;
          }
          case 'phone': {
            return (
              <FormInputPhoneNumber
                {...commonProps}
                inputProps={{ ...commonProps.inputProps, name: commonProps.name }}
                rules={{
                  validate: (currentValue: string) => resolvePhoneNumberValidation({ currentValue, t }),
                }}
              />
            );
          }
          case 'switch': {
            return <FormInputSwitch {...commonProps} />;
          }
          case 'checkbox': {
            return <FormInputCheckbox {...commonProps} />;
          }
          default:
            return exhaustiveGuard(type);
        }
      })}
    </div>
  );
};

export default DynamicInput;
