import type { FC } from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';

import type { ServicesProfile } from 'api/servicesProfile/servicesProfile.types';
import RemoveItemButton from 'components/UI/atoms/RemoveItemButton/RemoveItemButton';
import { SERVICES_PATHS } from 'constants/router/PATHS';

import { testId } from '../../ServicesReviewProfilesTable';
import useStyles from './ServicesReviewProfileTableRow.styles';

export type Props = {
  servicesProfile: ServicesProfile;
  onRemove: (id: number) => unknown;
};

const ServicesReviewProfileTableRow: FC<Props> = ({ servicesProfile, onRemove }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.profileWrapper} data-testid={testId.contentItem}>
      <ButtonBase
        className={classes.addButton}
        component={Link}
        to={generatePath(SERVICES_PATHS.SERVICES_USER_PROFILE, { profileId: `${servicesProfile.id}` })}
      >
        <Typography className={classes.label}>{servicesProfile.name}</Typography>
      </ButtonBase>
      <RemoveItemButton
        className={classes.removeButton}
        size='small'
        testId={testId.removeButton}
        onClick={() => onRemove(servicesProfile.id)}
      />
    </div>
  );
};

export default ServicesReviewProfileTableRow;
