import type { FC } from 'react';

import { Route } from 'react-router-dom';

import LoginPage from 'components/pages/_auth/LoginPage/LoginPage';
import DashboardPage from 'components/pages/DashboardPage/DashboardPage';
import NotFoundPage from 'components/pages/NotFoundPage/NotFoundPage';
import AuthPage from 'components/root/AuthPage/AuthPage';
import PrivateRoute from 'components/root/PrivateRoute/PrivateRoute';
import RoutesWrapper from 'components/root/RoutesWrapper/RoutesWrapper';
import { AUTH_PREFIX, INTERNAL_PREFIX } from 'constants/router/PATHS';
import routes, { NestedRoute as RouteType } from 'constants/router/routes';

const renderer = (routesToRender: RouteType[]) =>
  routesToRender.map(routeConfig => {
    const { path, element: Element, subRoutes } = routeConfig;

    if (subRoutes) {
      const indexRoute = subRoutes.find(({ index }) => index);
      return (
        <Route element={<Element />} key={path} path={path}>
          {indexRoute && <Route element={<indexRoute.element />} index />}
          {renderer(subRoutes)}
        </Route>
      );
    }

    return <Route element={<Element />} key={path} path={path} />;
  });

const Router: FC = () => (
  <RoutesWrapper>
    <Route element={<AuthPage />} path={AUTH_PREFIX}>
      <Route element={<LoginPage />} index />
      {routes.authRoutes.map(routeConfig => {
        const { path, element: Element } = routeConfig;
        return <Route element={<Element />} key={path} path={path} />;
      })}
    </Route>
    <Route element={<PrivateRoute />} path={INTERNAL_PREFIX}>
      <Route element={<DashboardPage />} index />
      {renderer(routes.internalRoutes)}
    </Route>
    <Route element={<NotFoundPage />} path='*' />
  </RoutesWrapper>
);
export default Router;
