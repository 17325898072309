import type { FC } from 'react';

import { get } from 'lodash';

import type { ITableDesktopCardItemHeader } from '../../_types/ITableDesktopCardItemHeader';
import type { ITableDesktopCardItemRow } from '../../_types/ITableDesktopCardItemRow';
import useStyles from './TableDesktopCardItemRow.styles';

export interface Props {
  headers: ITableDesktopCardItemHeader[];
  row: ITableDesktopCardItemRow;
}

const TableDesktopCardItemRow: FC<Props> = ({ headers, row }) => {
  const { classes } = useStyles({ headersCount: headers.length });

  return (
    <tr className={classes.rowContainer}>
      {headers.map(header => (
        <td className={classes.row} key={`${row.id}-${header.id}`}>
          {get(row, header.id, null)}
        </td>
      ))}
    </tr>
  );
};

export default TableDesktopCardItemRow;
