import { format } from 'date-fns';
import type { TFunction } from 'i18next';

import { DateSoonOptions } from 'constants/_types/SelectedServices';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import orderServiceMessages from 'translations/specific/orderService.mjs';

interface Params {
  dateSoon: DateSoonOptions | undefined;
  dateFrom: string;
  dateUntil: string;
  t: TFunction;
}

export const adjustDateOption = ({ dateSoon, dateFrom, dateUntil, t }: Params) => {
  if (!dateSoon) return `${format(new Date(dateFrom), DATE_FORMATS.DISPLAY)} - ${format(new Date(dateUntil), DATE_FORMATS.DISPLAY)}`;

  if (dateSoon === 'nextSevenDays') return t(orderServiceMessages.details.nextSevenDays);

  return t(orderServiceMessages.details.asap);
};
