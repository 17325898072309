import { useCallback, useMemo, useRef, type FC } from 'react';

import { FormControl, Select, MenuItem, Checkbox, type SelectChangeEvent, type SelectProps, type MenuProps } from '@mui/material';

import { ReactComponent as CheckedIcon } from 'assets/icons/checkboxes/checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/icons/checkboxes/unchecked.svg';
import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import TruncatedTextWithTooltip from 'components/UI/atoms/TruncatedTextWithTooltip/TruncatedTextWithTooltip';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

import DropdownClearButton from './_components/DropdownClearButton/DropdownClearButton';
import { resolveLabelOrPlaceholder } from './_services/resolveLabelOrPlaceholder/resolveLabelOrPlaceholder';
import useStyles from './InputDropdown.styles';

export type Props = Omit<SelectProps, 'error'> & {
  value: string | string[] | undefined;
  label?: string;
  hasFullHeight?: boolean;
  dropdownMaxWidth?: number;
  dropdownMinWidth?: number;
  dropdownMobileMinWidth?: number;
  helperText?: string;
  error?: string | boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  options?: DropdownOption[];
  multiple?: boolean;
  clearable?: boolean;
  placeholder?: string;
  shouldIncreaseDropdownHeight?: boolean;
  onChange?: (event: SelectChangeEvent<string | string[]>) => void;
  onValueUpdate?: (value: any) => void;
  menuProps?: Partial<MenuProps>;
};

const InputDropdown: FC<Props> = ({
  label,
  hasFullHeight = false,
  dropdownMaxWidth,
  dropdownMinWidth,
  dropdownMobileMinWidth,
  helperText,
  error,
  fullWidth = true,
  options,
  disabled,
  multiple,
  clearable,
  placeholder,
  shouldIncreaseDropdownHeight = false,
  size = 'medium',
  onValueUpdate,
  menuProps,
  ...rest
}) => {
  const { classes } = useStyles({
    clearable,
    dropdownMaxWidth,
    dropdownMinWidth,
    dropdownMobileMinWidth,
    hasFullHeight,
    size,
    shouldIncreaseDropdownHeight,
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const onClear = useCallback(() => {
    rest?.onChange?.('' as unknown as SelectChangeEvent<string | string[]>);
  }, [rest.onChange]);

  const labelOrPlaceholder = useMemo(
    () => resolveLabelOrPlaceholder({ className: classes.label, disabled, label, multiple, placeholder, value: rest.value }),
    [label, placeholder, rest.value],
  );

  return (
    <FormControl
      classes={{ root: classes.formControl }}
      color='secondary'
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      variant='outlined'
    >
      {labelOrPlaceholder}
      <div className={classes.selectWrapper} ref={ref}>
        <Select
          className={classes.select}
          disabled={disabled || !options}
          labelId={`select_label${label}`}
          MenuProps={menuProps}
          multiple={multiple}
          renderValue={selected =>
            Array.isArray(selected) ? (
              selected.join(', ')
            ) : (
              <TruncatedTextWithTooltip text={options?.find(option => option.value === selected)?.label || ''} />
            )
          }
          {...rest}
          onChange={e => {
            onValueUpdate?.(e.target.value);
            rest?.onChange?.(e as unknown as SelectChangeEvent<string | string[]>);
          }}
        >
          {options &&
            (multiple
              ? options.map(({ value, label: optionLabel }) => (
                  <MenuItem key={value} value={value}>
                    <Checkbox
                      checked={rest.value?.includes(`${value}`)}
                      checkedIcon={<CheckedIcon />}
                      color='secondary'
                      icon={<UncheckedIcon />}
                    />
                    {optionLabel}
                  </MenuItem>
                ))
              : options.map(({ value, label: optionLabel }) => (
                  <MenuItem key={value} value={value}>
                    {optionLabel}
                  </MenuItem>
                )))}
        </Select>
        {clearable && <DropdownClearButton disabled={disabled} multiple={multiple} value={rest.value} onClear={onClear} />}
      </div>
      {!hasFullHeight && <InputFooter error={error} helperText={helperText} />}
    </FormControl>
  );
};

export default InputDropdown;
