import { useQuery } from 'react-query';

import apiContentType from 'api/apiContentType/apiContentType';
import { type ApiContentTypeModels } from 'api/apiContentType/apiContentType.types';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

const useGetApiContentTypeId = (contentTypesToCheck: ApiContentTypeModels[]) => {
  const { data } = useQuery(QUERY_KEYS.GET_API_CONTENT_TYPES, apiContentType.getApiContentType(), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  if (!data) return [];

  return contentTypesToCheck.map(contentType => data[contentType]);
};

export default useGetApiContentTypeId;
