import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import type { Basket } from 'constants/_types/Basket';
import type { SelectedServiceItemMetaData } from 'constants/_types/SelectedServiceItemMetaData';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import MobileBasketContent from './_components/MobileBasketContent/MobileBasketContent';
import MobileBasketHead from './_components/MobileBasketHead/MobileBasketHead';
import useStyles from './OrderServiceMobileBasket.styles';

export interface Props {
  basket: Basket | null;
  selectedServicesNumber: number | null;
  removeItem: (metaData: SelectedServiceItemMetaData) => void;
}

const OrderServiceMobileBasket: FC<Props> = ({ basket, selectedServicesNumber, removeItem }) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const { classes } = useStyles({ isOpened });

  const handleToggleOpen = useCallback(() => {
    setIsOpened(prev => !prev);
  }, []);

  const buttonLabel = useMemo(
    () => (isOpened ? t(orderServiceMessages.basket.hideBasket) : t(orderServiceMessages.basket.showBasket)),
    [isOpened],
  );

  const headerLabel = useMemo(() => {
    if (!selectedServicesNumber) return t(orderServiceMessages.basket.header);
    return `${t(orderServiceMessages.basket.header)} (${selectedServicesNumber})`;
  }, [selectedServicesNumber]);

  return (
    <div>
      <div className={classes.head}>
        <MobileBasketHead buttonLabel={buttonLabel} handleToggleOpen={handleToggleOpen} headerLabel={headerLabel} isOpened={isOpened} />
      </div>
      <div className={classes.content}>
        <MobileBasketContent basket={basket} removeItem={removeItem} />
      </div>
    </div>
  );
};

export default OrderServiceMobileBasket;
