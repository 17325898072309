import { isFuture } from 'date-fns';

import { GENDER, type Gender } from 'constants/_types/Gender';

const errors = ['tooShort', 'tooLong', 'invalid', 'past'] as const;
export type Error = typeof errors[number];

type Data = {
  dob: Date;
  gender: Gender;
};

type Pesel = {
  validate: (value: string, onlyPast?: boolean) => { isValid: boolean; error: Error | null };
  getData: (value: string) => Data;
  validateAndGetData: (value: string, onlyPast?: boolean) => { isValid: boolean; error: Error | null; data: Data | null };
  getDOB: (value: string) => Date;
};

const isAfter2000 = (month: number): boolean => month > 20;

const getDOB = (value: string): Date => {
  const [yearString, monthNumber, dayNumber] = [value.substring(0, 2), Number(value.substring(2, 4)), Number(value.substring(4, 6))];
  const year = parseInt(isAfter2000(monthNumber) ? `20${yearString}` : `19${yearString}`, 10);
  const month = isAfter2000(monthNumber) ? monthNumber - 21 : monthNumber - 1;
  return new Date(year, month, dayNumber);
};

const validate = (value: string, onlyPast?: boolean): { isValid: boolean; error: Error | null } => {
  if (!value) return { isValid: false, error: 'tooShort' };
  const CORRECT_PESEL_LENGTH = 11;
  if (value.length < CORRECT_PESEL_LENGTH) return { isValid: false, error: 'tooShort' };
  if (value.length > CORRECT_PESEL_LENGTH) return { isValid: false, error: 'tooLong' };
  if (onlyPast && isFuture(getDOB(value))) return { isValid: false, error: 'past' };

  const WEIGHT = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  const controlNumber = parseInt(value.substring(10, 11), 10);

  for (let i = 0; i < WEIGHT.length; i += 1) {
    sum += parseInt(value.substring(i, i + 1), 10) * WEIGHT[i];
  }
  sum %= 10;
  return (10 - sum) % 10 === controlNumber ? { isValid: true, error: null } : { isValid: false, error: 'invalid' };
};

const getGender = (value: string): Gender => {
  const genderNumber = value.substring(9, 10);
  return Number(genderNumber) % 2 ? GENDER.m : GENDER.k;
};

const getData = (value: string) => ({ gender: getGender(value), dob: getDOB(value) });

const validateAndGetData = (value: string, onlyPast?: boolean) => {
  const validation = validate(value, onlyPast);
  if (!validation.isValid) return { ...validation, data: null };
  return { ...validation, data: getData(value) };
};

const pesel: Pesel = {
  validate,
  getDOB,
  getData,
  validateAndGetData,
};

export default pesel;
