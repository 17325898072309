import type { TFunction } from 'react-i18next';

// Hardcoded constant values.
//
// It was assumed that they won't not change.
//
// It's needed for showing group titles while services groups have no services because
// group titles are incorporated into services data (of services groups) provided by BE.
export const getServicesGroups = (t: TFunction) => [
  {
    id: 0,
    title: t(['MdtCoreServiceGroupSection>>>APPOINTMENTS_AND_TESTS', 'Wizyty i badania']),
  },
  {
    id: 1,
    title: t(['MdtCoreServiceGroupSection>>>TELEMEDICINE', 'Monitorowanie telemedyczne']),
  },
];
