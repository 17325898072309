import { useEffect } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

import type { ParsedAddress } from '../../_types/parsedAddress';

export interface HookParams {
  isFetched: boolean;
  form: UseFormReturn<OrderServiceFormInput, any>;
  addressData: ParsedAddress | undefined;
}

export const useFillAddressForm = ({ isFetched, form, addressData }: HookParams) => {
  useEffect(() => {
    if (!addressData) return;

    Object.entries(addressData).forEach(([key, value]) => {
      // @TODO: specify the type
      form.setValue(`address.${key}` as any, value);
    });
  }, [isFetched]);
};
