import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(0.5),
    background: 'transparent',
    opacity: 0.75,
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.background.default,
    },

    svg: {
      height: '1em',
      width: '1em',
    },
  },
}));

export default useStyles;
