import { makeStyles } from 'tss-react/mui';

import { DRAWER_PATIENT_INFO_WIDTH } from 'constants/styles/DRAWER_PATIENT_INFO_WIDTH';
import ICON_SIZES_FOR_MAIN_MENU from 'constants/styles/ICON_SIZES_FOR_MAIN_MENU';

const ACTIVE_BORDER_WIDTH = 4;

const useStyles = makeStyles<{ isActive: boolean; isOpen: boolean }>()((theme, { isActive, isOpen }) => ({
  root: {
    padding: theme.spacing(1, 1.5),
    width: isOpen ? '100%' : DRAWER_PATIENT_INFO_WIDTH,
    transition: theme.transitions.create(['width', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    fontWeight: 400,
    justifyItems: 'center',
    display: 'grid',

    '&:hover .activeBorder': {
      opacity: isActive ? 1 : 0.1,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  linkDisabled: {
    pointerEvents: 'none',
    opacity: isActive ? 1 : 0.35,
  },

  activeBorder: {
    // positioned relatively to drawer
    opacity: isActive ? 1 : 0,
    display: 'block',
    width: `${ACTIVE_BORDER_WIDTH}px`,
    height: isOpen ? '80px' : '60px',
    position: 'absolute',
    right: '0px',
    transform: `translateY(-${theme.spacing(1)})`,
    background: theme.palette.secondary.main,

    transition: theme.transitions.create(['opacity', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  iconContainer: {
    background: isActive ? theme.palette.background.default : 'none',
    minWidth: 'unset',
    padding: isActive && isOpen ? theme.spacing(2) : theme.spacing(1.5, 1, 1.25),
    transition: theme.transitions.create(['background', 'padding', 'margin-bottom'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '&:hover': {
      backgroundColor: isActive ? theme.palette.background.default : 'transparent !important',
    },

    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent !important',
      padding: theme.spacing(1),
    },
  },

  icon: {
    display: 'inline-block',
    color: theme.palette.grey[500],
  },

  label: {
    opacity: isOpen ? 1 : 0,
    height: isOpen ? '15px' : 0,
    width: isOpen ? 'unset' : ICON_SIZES_FOR_MAIN_MENU.desktop.closed,
    display: 'inline-block',
    whiteSpace: 'break-spaces',
    color: isActive ? theme.palette.secondary.main : theme.palette.grey[500],
    transition: theme.transitions.create(['color', 'opacity', 'height', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      opacity: 1,
      height: '15px',
      width: 'unset',
    },
  },
}));

export default useStyles;
