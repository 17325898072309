import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type { CumulativeExtendedServiceItem } from 'constants/_types/SelectedServices';

import type { ServicesAttachmentsData } from '../../_types/types';

export const gatherServicesAttachments = (servicesDetails: OrderServiceFormInput['details']) => {
  const servicesAttachmentsData: ServicesAttachmentsData[] = [];

  Object.entries(servicesDetails).forEach(([kindKey, { serviceItems }]) => {
    Object.entries(serviceItems).forEach(([serviceItemUnderscoreId, serviceItem]) => {
      const hasFile = !!(serviceItem as CumulativeExtendedServiceItem)?.file?.length;

      // eslint-disable-next-line no-unsafe-optional-chaining
      if (hasFile)
        servicesAttachmentsData.push({
          file: (serviceItem as CumulativeExtendedServiceItem).file[0],
          kindKey: kindKey as OrderServiceCoreServicesKinds,
          serviceItemUnderscoreId,
        });
    });
  });

  return servicesAttachmentsData;
};
