import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export default useStyles;
