import create from 'zustand';

type PatientEditorUIStorage = {
  isPatientEditorOpen: boolean;
  openPatientEditor: () => void;
  closePatientEditor: () => void;
};

const usePatientEditorUIStorage = create<PatientEditorUIStorage>(set => ({
  isPatientEditorOpen: false,
  openPatientEditor: () => set({ isPatientEditorOpen: true }),
  closePatientEditor: () => set({ isPatientEditorOpen: false }),
}));

export default usePatientEditorUIStorage;
