import type { FC } from 'react';

import TableDesktopCardItemHeader from './_components/TableDesktopCardItemHeader/TableDesktopCardItemHeader';
import TableDesktopCardItemRow from './_components/TableDesktopCardItemRow/TableDesktopCardItemRow';
import type { ITableDesktopCardItemHeader } from './_types/ITableDesktopCardItemHeader';
import type { ITableDesktopCardItemRow } from './_types/ITableDesktopCardItemRow';
import useStyles from './TableDesktopCard.styles';

export interface Props {
  headers: ITableDesktopCardItemHeader[];
  rows: ITableDesktopCardItemRow[];
}

const TableDesktopCard: FC<Props> = ({ headers, rows }) => {
  const { classes } = useStyles({ headersCount: headers.length });

  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.headerContainer}>
          {headers.map(({ id, ...rest }) => (
            <TableDesktopCardItemHeader key={id} {...rest} />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <TableDesktopCardItemRow headers={headers} key={row.id} row={row} />
        ))}
      </tbody>
    </table>
  );
};

export default TableDesktopCard;
