import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    background: theme.palette.background.default,
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  dialogTitleText: {
    marginBottom: 0,
    color: theme.typography.h2.color,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  dialogActions: {
    paddingBottom: theme.spacing(5),
  },
  iconWrapper: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    flexShrink: '0',
    background: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
      width: theme.spacing(5),
      padding: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
  },
  dialogContent: {
    scrollBehavior: 'smooth',

    marginTop: theme.spacing(2.5),
    display: 'block',
    alignItems: 'center',
  },
}));

export default useStyles;
