import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ numberOfColumns: number }>()((theme, { numberOfColumns }) => ({
  container: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
  },
  headerWrapper: {
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  header: {
    flex: 4,
    gridTemplateColumns: `repeat(${numberOfColumns}, fit-content(calc(${numberOfColumns} / 12 * 100%)))`,
    padding: theme.spacing(1, 2),
    gap: theme.spacing(2),
  },
  listItemClass: {
    gap: 5,
  },
  paragraph: {
    padding: theme.spacing(2),
    fontSize: '1rem',
    lineHeight: '1.4rem',
  },
}));

export default useStyles;
