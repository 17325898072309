import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  details: {
    display: 'grid',
    gap: theme.spacing(0.75),
  },
}));

export default useStyles;
