import { makeStyles } from 'tss-react/mui';

interface StylesProps {
  minHeight?: number;
  maxHeight?: number;
}

const useStyles = makeStyles<StylesProps>()((theme, { minHeight, maxHeight }) => ({
  root: {
    minHeight: minHeight ? `${minHeight}px` : 'unset',
    maxHeight: maxHeight ? `${maxHeight}px` : 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(5, 5, 0, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
}));

export default useStyles;
