import { useEffect, useState } from 'react';

type UseScale = () => {
  scale: number;
  scaleSlider: number;
  onScaleSliderChange: (e: Event, newValue: number | number[]) => void;
  resetScale: () => void;
};

const scaleConfig = {
  minimal: 1,
  threshold: 2,
};

export const useScale: UseScale = () => {
  const [scaleSlider, setScaleSlider] = useState(0);
  const [scale, setScale] = useState(1);

  const onScaleSliderChange = (e: Event, newValue: number | number[]) => setScaleSlider(newValue as number);
  const resetScale = () => setScaleSlider(0);

  useEffect(() => {
    const newScale = scaleConfig.minimal + scaleSlider * 0.01 * scaleConfig.threshold;
    setScale(newScale);
  }, [scaleSlider]);

  return {
    scale,
    scaleSlider,
    onScaleSliderChange,
    resetScale,
  };
};
