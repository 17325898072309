export default [
  {
    code: 'AF',
    name: 'Afganistan',
  },
  {
    code: 'AL',
    name: 'Albania',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'AD',
    name: 'Andora',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AG',
    name: 'Antigua i Barbuda',
  },
  {
    code: 'SA',
    name: 'Arabia Saudyjska',
  },
  {
    code: 'AR',
    name: 'Argentyna',
  },
  {
    code: 'AM',
    name: 'Armenia',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZ',
    name: 'Azerbejdżan',
  },
  {
    code: 'BS',
    name: 'Bahamy',
  },
  {
    code: 'BH',
    name: 'Bahrajn',
  },
  {
    code: 'BD',
    name: 'Bangladesz',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BE',
    name: 'Belgia',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BY',
    name: 'Białoruś',
  },
  {
    code: 'BO',
    name: 'Boliwia',
  },
  {
    code: 'BW',
    name: 'Botswana',
  },
  {
    code: 'BA',
    name: 'Bośnia i Hercegowina',
  },
  {
    code: 'BR',
    name: 'Brazylia',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    code: 'IO',
    name: 'Brytyjskie Terytorium Oceanu Indyjskiego',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'BG',
    name: 'Bułgaria',
  },
  {
    code: 'CL',
    name: 'Chile',
  },
  {
    code: 'CN',
    name: 'Chiny',
  },
  {
    code: 'HR',
    name: 'Chorwacja',
  },
  {
    code: 'CW',
    name: 'Curaçao',
  },
  {
    code: 'CY',
    name: 'Cypr',
  },
  {
    code: 'TD',
    name: 'Czad',
  },
  {
    code: 'ME',
    name: 'Czarnogóra',
  },
  {
    code: 'DK',
    name: 'Dania',
  },
  {
    code: 'DM',
    name: 'Dominika',
  },
  {
    code: 'DO',
    name: 'Dominikana',
  },
  {
    code: 'DJ',
    name: 'Dżibuti',
  },
  {
    code: 'EG',
    name: 'Egipt',
  },
  {
    code: 'EC',
    name: 'Ekwador',
  },
  {
    code: 'ER',
    name: 'Erytrea',
  },
  {
    code: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ET',
    name: 'Etiopia',
  },
  {
    code: 'FJ',
    name: 'Fidżi Republika',
  },
  {
    code: 'PH',
    name: 'Filipiny',
  },
  {
    code: 'FI',
    name: 'Finlandia',
  },
  {
    code: 'FR',
    name: 'Francja',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GR',
    name: 'Grecja',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GE',
    name: 'Gruzja',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    name: 'Gujana',
  },
  {
    code: 'GF',
    name: 'Gujana Francuska',
  },
  {
    code: 'GP',
    name: 'Gwadelupa',
  },
  {
    code: 'GT',
    name: 'Gwatemala',
  },
  {
    code: 'GN',
    name: 'Gwinea',
  },
  {
    code: 'GQ',
    name: 'Gwinea Równikowa',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'ES',
    name: 'Hiszpania',
  },
  {
    code: 'BQ',
    name: 'Holandia Karaibska',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hongkong',
  },
  {
    code: 'IN',
    name: 'Indie',
  },
  {
    code: 'ID',
    name: 'Indonezja',
  },
  {
    code: 'IQ',
    name: 'Irak',
  },
  {
    code: 'IR',
    name: 'Iran',
  },
  {
    code: 'IE',
    name: 'Irlandia',
  },
  {
    code: 'IS',
    name: 'Islandia',
  },
  {
    code: 'IL',
    name: 'Izrael',
  },
  {
    code: 'JM',
    name: 'Jamaika',
  },
  {
    code: 'JP',
    name: 'Japonia',
  },
  {
    code: 'YE',
    name: 'Jemen',
  },
  {
    code: 'JO',
    name: 'Jordania',
  },
  {
    code: 'KH',
    name: 'Kambodża',
  },
  {
    code: 'CM',
    name: 'Kamerun',
  },
  {
    code: 'CA',
    name: 'Kanada',
  },
  {
    code: 'QA',
    name: 'Katar',
  },
  {
    code: 'KZ',
    name: 'Kazachstan',
  },
  {
    code: 'KE',
    name: 'Kenia',
  },
  {
    code: 'KG',
    name: 'Kirgistan',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'CO',
    name: 'Kolombia',
  },
  {
    code: 'KM',
    name: 'Komory',
  },
  {
    code: 'CG',
    name: 'Kongo',
  },
  {
    code: 'CD',
    name: 'Kongo, Republika Demokratyczna',
  },
  {
    code: 'XK',
    name: 'Kosowo',
  },
  {
    code: 'CR',
    name: 'Kostaryka',
  },
  {
    code: 'CU',
    name: 'Kuba',
  },
  {
    code: 'KW',
    name: 'Kuwejt',
  },
  {
    code: 'LA',
    name: 'Laos',
  },
  {
    code: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LB',
    name: 'Liban',
  },
  {
    code: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LY',
    name: 'Libia',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LT',
    name: 'Litwa',
  },
  {
    code: 'LU',
    name: 'Luksemburg',
  },
  {
    code: 'MK',
    name: 'Macedonia',
  },
  {
    code: 'MG',
    name: 'Madagaskar',
  },
  {
    code: 'MO',
    name: 'Makau',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MV',
    name: 'Malediwy',
  },
  {
    code: 'MY',
    name: 'Malezja',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MA',
    name: 'Maroko',
  },
  {
    code: 'MQ',
    name: 'Martynika',
  },
  {
    code: 'MR',
    name: 'Mauretania',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'MX',
    name: 'Meksyk',
  },
  {
    code: 'FM',
    name: 'Mikronezja',
  },
  {
    code: 'MC',
    name: 'Monako',
  },
  {
    code: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'MZ',
    name: 'Mozambik',
  },
  {
    code: 'MD',
    name: 'Mołdowa',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Niderlandy',
  },
  {
    code: 'DE',
    name: 'Niemcy',
  },
  {
    code: 'NE',
    name: 'Niger',
  },
  {
    code: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NI',
    name: 'Nikaragua',
  },
  {
    code: 'NO',
    name: 'Norwegia',
  },
  {
    code: 'NC',
    name: 'Nowa Kaledonia',
  },
  {
    code: 'NZ',
    name: 'Nowa Zelandia',
  },
  {
    code: 'OM',
    name: 'Oman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Palestyna',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua Nowa Gwinea',
  },
  {
    code: 'PY',
    name: 'Paragwaj',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PF',
    name: 'Polinezja Francuska',
  },
  {
    code: 'PL',
    name: 'Polska',
  },
  {
    code: 'PR',
    name: 'Portoryko',
  },
  {
    code: 'PT',
    name: 'Portugalia',
  },
  {
    code: 'KR',
    name: 'Południowa Korei',
  },
  {
    code: 'KP',
    name: 'Północna Korea',
  },
  {
    code: 'CZ',
    name: 'Republika Czeska',
  },
  {
    code: 'ZA',
    name: 'Republika Południowej Afryki',
  },
  {
    code: 'CF',
    name: 'Republika Środkowoafrykańska',
  },
  {
    code: 'RE',
    name: 'Reunion',
  },
  {
    code: 'RU',
    name: 'Rosja',
  },
  {
    code: 'RO',
    name: 'Rumunia',
  },
  {
    code: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'KN',
    name: 'Saint Kitts i Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'VC',
    name: 'Saint Vincent i Grenadyny',
  },
  {
    code: 'SV',
    name: 'Salwador',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SC',
    name: 'Seszele',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapur',
  },
  {
    code: 'SO',
    name: 'Somalia',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'US',
    name: 'Stany Zjednoczone Ameryki',
  },
  {
    code: 'SZ',
    name: 'Suazi',
  },
  {
    code: 'SS',
    name: 'Sudan Południowy',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Surinam',
  },
  {
    code: 'SY',
    name: 'Syria',
  },
  {
    code: 'CH',
    name: 'Szwajcaria',
  },
  {
    code: 'SE',
    name: 'Szwecja',
  },
  {
    code: 'SK',
    name: 'Słowacja',
  },
  {
    code: 'SI',
    name: 'Słowenia',
  },
  {
    code: 'TJ',
    name: 'Tadżykistan',
  },
  {
    code: 'TH',
    name: 'Tajlandia',
  },
  {
    code: 'TW',
    name: 'Tajwan',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
  },
  {
    code: 'TL',
    name: 'Timor Wschodni',
  },
  {
    code: 'TG',
    name: 'Togo',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trynidad i Tobago',
  },
  {
    code: 'TN',
    name: 'Tunezja',
  },
  {
    code: 'TR',
    name: 'Turcja',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukraina',
  },
  {
    code: 'UY',
    name: 'Urugwaj',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VA',
    name: 'Watykan',
  },
  {
    code: 'VE',
    name: 'Wenezuela',
  },
  {
    code: 'GB',
    name: 'Wielka Brytania',
  },
  {
    code: 'VN',
    name: 'Wietnam',
  },
  {
    code: 'CI',
    name: 'Wybrzeże Kości Słoniowej',
  },
  {
    code: 'MH',
    name: 'Wyspy Marshalla',
  },
  {
    code: 'SB',
    name: 'Wyspy Salomona',
  },
  {
    code: 'ST',
    name: 'Wyspy Św.Tomasza i Książęca',
  },
  {
    code: 'HU',
    name: 'Węgry',
  },
  {
    code: 'IT',
    name: 'Włochy',
  },
  {
    code: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'CV',
    name: 'Zielony Przylądek',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
  },
  {
    code: 'AE',
    name: 'Zjednoczone Emiraty Arabskie',
  },
  {
    code: 'LV',
    name: 'Łotwa',
  },
];
