import { parsePatientForFE } from '../patient/patient.parsers';
import type { ECareAlarm, ECareAlarmDTO } from './eCareAlarms.types';

export const parseSingleECareAlarmForFE = (eCareAlarmDTO: ECareAlarmDTO): ECareAlarm => ({
  id: eCareAlarmDTO.id,
  internalId: eCareAlarmDTO.internal_id,
  createdAt: new Date(eCareAlarmDTO.created_at),
  updatedAt: new Date(eCareAlarmDTO.updated_at),
  device: eCareAlarmDTO?.device || null,
  source: eCareAlarmDTO?.source || null,
  event: eCareAlarmDTO?.event || null,
  connectionDate: new Date(eCareAlarmDTO.connection_date),
  serialNumber: eCareAlarmDTO.serial_number,
  sosType: eCareAlarmDTO.sos_type,
  sosStatus: eCareAlarmDTO.sos_status,
  interventionDate: eCareAlarmDTO.intervention_date ? new Date(eCareAlarmDTO.intervention_date) : null,
  objectId: eCareAlarmDTO.object_id,
  internalNotes: eCareAlarmDTO.internal_notes,
  order: eCareAlarmDTO.order,
  serviceRecipient: eCareAlarmDTO.service_recipient,
  patient: eCareAlarmDTO.patient ? parsePatientForFE(eCareAlarmDTO.patient) : null,
  sosReason: eCareAlarmDTO.sos_reason,
  interventionType: eCareAlarmDTO.intervention_type,
  interventionEffect: eCareAlarmDTO.intervention_effect,
  contentType: eCareAlarmDTO.content_type,
  entity: eCareAlarmDTO.entity,
  description: eCareAlarmDTO.description,
  subscription: eCareAlarmDTO.subscription,
  payer: eCareAlarmDTO.payer,
  operator: eCareAlarmDTO.operator,
  lastSync: eCareAlarmDTO.last_sync ? new Date(eCareAlarmDTO.last_sync) : null,
});

export const parseECareAlarmsListForFE = (eCareAlarmDTO: ECareAlarmDTO[]): ECareAlarm[] => eCareAlarmDTO.map(parseSingleECareAlarmForFE);
