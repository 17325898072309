import { isInvalid } from 'services/_formInput/isInvalid/isInvalid';

interface Params {
  currentValue?: string;
  hasForeignDocument: boolean;
  errorMessage: string;
}

export const validatePatientFormInput = ({ currentValue, hasForeignDocument, errorMessage }: Params) => {
  if (isInvalid(currentValue) && hasForeignDocument) return errorMessage;
  return true;
};
