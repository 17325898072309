import type { DropdownOption } from 'constants/_types/DropdownOptions';

interface Params {
  payersSelected: string[];
  payersByService: DropdownOption[];
}

export const NO_PRESELECTED_PAYER = '';

export const determineDefaultPayerId = ({ payersSelected, payersByService }: Params) => {
  let defaultPayerId = NO_PRESELECTED_PAYER;

  if (payersSelected.length === 1) {
    const selectedPayerByService = payersByService.find(payerByService => payerByService.value.toString() === payersSelected[0]);
    if (selectedPayerByService) {
      defaultPayerId = selectedPayerByService.value.toString();
    }
  }

  if (payersByService.length === 1) {
    defaultPayerId = `${payersByService[0].value}`;
  }

  return defaultPayerId;
};
