import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  cardContent: {
    padding: theme.spacing(5),
    paddingBottom: `${theme.spacing(5)} !important`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingBottom: `${theme.spacing(2)} !important`,
    },
  },
  sectionDivider: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

export default useStyles;
