import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import patients from 'api/patient/patients';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';

export type ErrorType = {
  response?: { status: number; data: { [key: string]: string[] } };
};

type HookParams = {
  mutationKey: string;
  onError?: () => void;
};

const useUploadAttachments = ({ mutationKey, onError }: HookParams) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const [uploadAttachmentErrors, setUploadAttachmentErrors] = useState<string[]>();

  const handleOnError = useCallback(
    (error: ErrorType) => {
      const { response } = error;
      if (response?.status && response.status < 500 && response?.data) {
        setUploadAttachmentErrors(Object.values(response.data).map(err => t(err)));
      }
      onError?.();
    },
    [setUploadAttachmentErrors],
  );

  const uploadAttachmentMutation = useMutation(mutationKey, patients.uploadFiles(patient?.id), {
    retry: false,
    onError: handleOnError,
  });

  const uploadAttachments = useCallback(
    async (attachments: File[]) => {
      const formData = new FormData();
      attachments.forEach(attachment => formData.append('attachments', attachment));
      const {
        data: { attachment_ids },
      } = await uploadAttachmentMutation.mutateAsync(formData);

      return attachment_ids;
    },
    [uploadAttachmentMutation],
  );

  return { uploadAttachments, uploadAttachmentErrors };
};

export default useUploadAttachments;
