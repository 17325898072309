import { TableDataSort } from 'constants/_types/TableDataSort';

type DefaultTableConfig = {
  pageSize: number;
  initialSort: TableDataSort;
};

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT: TableDataSort = {
  key: 'updated_at',
  direction: 'asc',
};

export const DEFAULT_TABLE_CONFIG: DefaultTableConfig = { pageSize: DEFAULT_PAGE_SIZE, initialSort: DEFAULT_SORT };
