import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ extraLeftMargin: boolean; marginBottom: number }>()((theme, { extraLeftMargin, marginBottom }) => ({
  header: {
    fontSize: '1rem',
    marginLeft: theme.spacing(extraLeftMargin ? 1 : 0),
    marginBottom: theme.spacing(marginBottom),
  },
}));

export default useStyles;
