import { useCallback, useMemo } from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { PatientFormInput } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from 'components/UI/organisms/PatientPersonalInfo/_constants/patientFormKeys';
import type { FormInputsConfig } from 'constants/_types/FormInputsConfig';
import { GENDER, type Gender } from 'constants/_types/Gender';
import genderOptions from 'constants/dropdownOptions/gender';
import patientDocumentTypes from 'constants/dropdownOptions/patientDocumentTypes';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useAddressDictionaries from 'hooks/useAddressDictionaries/useAddressDictionaries';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import getCountryDropdown from 'services/_dropdownParsers/getCountryDropdown/getCountryDropdown';
import { validatePostalCode } from 'services/_validators/validatePostalCode/validatePostalCode';
import { resolvePostalCodePattern } from 'services/resolvePostalCodePattern/resolvePostalCodePattern';
import translateOptions from 'services/translateOptions/translateOptions';
import generalMessages from 'translations/common/general.mjs';
import validationMessages from 'translations/common/validation.mjs';
import patientMessages from 'translations/specific/patient.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import { validatePatientFormInput } from './_services/validatePatientFormInput/validatePatientFormInput';
import { validatePesel } from './_services/validatePesel/validatePesel';

const dateParser = (value: { dateOfBirth: string }) => unknownDateToString(value.dateOfBirth);

const usePatientFormConfig = (form: UseFormReturn<PatientFormInput>, isPro: boolean) => {
  const [canEditPersonalData, canEditAddressData] = usePermissionCheck(['change_personaldata', 'change_addressdata'], isPro);
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const { patient, patientInfoFetched } = usePatientContext();
  const { userInfo } = useAuthContext();

  const countryValue = form.watch(PATIENT_FORM_INPUT_KEYS.country as 'country');
  const hasForeignDocument = form.watch(PATIENT_FORM_INPUT_KEYS.hasForeignDocument as 'hasForeignDocument');
  const hasPatient = useMemo(() => patientInfoFetched && !!patient, [patientInfoFetched, patient]);

  const postalCodePatterns = useMemo(() => resolvePostalCodePattern({ selectedCountryOptionValue: countryValue }), [countryValue]);

  const { cityOptions, streetOptions } = useAddressDictionaries<PatientFormInput>({
    form,
    postcodeInputKey: PATIENT_FORM_INPUT_KEYS.postcode,
    cityInputKey: PATIENT_FORM_INPUT_KEYS.city,
    streetInputKey: PATIENT_FORM_INPUT_KEYS.street,
    postalCodePattern: postalCodePatterns?.rulePattern,
  });

  const genderParser = useCallback(
    (values: { gender: Gender }) => (values.gender === GENDER.k ? t(generalMessages.gender.f) : t(generalMessages.gender.m)),
    [],
  );

  const personal = useMemo((): FormInputsConfig => {
    const base: FormInputsConfig = [
      {
        label: t(patientMessages.fields.firstAndLastName),
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.firstName]: {
            label: t(patientMessages.fields.firstName),
            type: 'text',
            required: true,
            inputProps: {
              disabled: isPro && !canEditPersonalData,
            },
          },
          [PATIENT_FORM_INPUT_KEYS.lastName]: {
            label: t(patientMessages.fields.lastName),
            type: 'text',
            required: true,
            inputProps: {
              disabled: isPro && !canEditPersonalData,
            },
          },
        },
        valueParser: (values: any) => `${values[PATIENT_FORM_INPUT_KEYS.firstName]} ${values[PATIENT_FORM_INPUT_KEYS.lastName]}`,
      },
    ];
    if (!hasPatient || (isPro && canEditPersonalData))
      base.push({
        label: t(patientMessages.fields.foreignDocument),
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.hasForeignDocument]: {
            type: 'switch',
          },
        },
      });
    const dynamic: FormInputsConfig = hasForeignDocument
      ? [
          {
            label: t(patientMessages.fields.foreignDocumentType),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.documentType]: {
                type: 'dropdown',
                options: translateOptions(t, patientDocumentTypes),
                required: true,
                rules: {
                  validate: (currentValue?: string) =>
                    validatePatientFormInput({ currentValue, hasForeignDocument, errorMessage: t(validationMessages.required_field) }),
                },
                inputProps: {
                  disabled: hasPatient || (isPro && !canEditPersonalData),
                },
              },
            },
            valueParser: data => {
              const currentValue = data[PATIENT_FORM_INPUT_KEYS.documentType];
              const foundLabel = patientDocumentTypes.find(({ value }) => value === currentValue)?.labelKey || '';

              return t(foundLabel);
            },
          },
          {
            label: t(patientMessages.fields.foreignDocumentNumber),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.documentNumber]: {
                type: 'text',
                required: true,
                rules: {
                  validate: (currentValue?: string) =>
                    validatePatientFormInput({ currentValue, hasForeignDocument, errorMessage: t(validationMessages.required_field) }),
                },
                inputProps: {
                  disabled: hasPatient || (isPro && !canEditPersonalData),
                },
              },
            },
          },
          {
            label: t(patientMessages.fields.foreignDocumentCountry),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.documentCountry]: {
                type: 'text',
                required: true,
                rules: {
                  validate: (currentValue?: string) =>
                    validatePatientFormInput({ currentValue, hasForeignDocument, errorMessage: t(validationMessages.required_field) }),
                },
                inputProps: {
                  disabled: hasPatient || (isPro && !canEditPersonalData),
                },
              },
            },
          },
          {
            label: t(patientMessages.fields.dateOfBirth),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.dateOfBirth]: {
                type: 'date',
                required: true,
                inputProps: { disableFuture: true, disabled: isPro && !canEditPersonalData },
              },
            },
            valueParser: dateParser,
          },
          {
            label: t(patientMessages.fields.gender),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.gender]: {
                type: 'dropdown',
                required: true,
                options: translateOptions(t, genderOptions),
                rules: {
                  validate: (currentValue?: string) =>
                    validatePatientFormInput({ currentValue, hasForeignDocument, errorMessage: t(validationMessages.required_field) }),
                },
              },
            },
            valueParser: genderParser,
          },
        ]
      : [
          {
            label: t(patientMessages.fields.pesel),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.pesel]: {
                type: 'text',
                required: true,
                rules: {
                  validate: (currentValue: string) => validatePesel({ currentValue, t, hasForeignDocument }),
                },
                inputProps: {
                  disabled: isPro ? !canEditPersonalData : hasPatient,
                },
              },
            },
          },
          {
            label: t(patientMessages.fields.dateOfBirth),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.dateOfBirth]: {
                type: 'date',
                required: true,
                rules: {
                  validate: (currentValue?: string) =>
                    validatePatientFormInput({ currentValue, hasForeignDocument, errorMessage: t(validationMessages.required_field) }),
                },
                inputProps: {
                  disabled: isPro ? !canEditPersonalData : hasPatient,
                },
              },
            },
            valueParser: dateParser,
          },
          {
            label: t(patientMessages.fields.gender),
            inputs: {
              [PATIENT_FORM_INPUT_KEYS.gender]: {
                type: 'dropdown',
                required: true,
                options: translateOptions(t, genderOptions),
                rules: {
                  validate: (currentValue?: string) =>
                    validatePatientFormInput({ currentValue, hasForeignDocument, errorMessage: t(validationMessages.required_field) }),
                },
                inputProps: {
                  disabled: isPro ? !canEditPersonalData : hasPatient,
                },
              },
            },
            valueParser: genderParser,
          },
        ];

    return [...base, ...dynamic];
  }, [hasPatient, hasForeignDocument]);

  const contact = useMemo((): FormInputsConfig => {
    const result: FormInputsConfig = [
      {
        label: t(generalMessages.phoneNumber),
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.phoneNumber]: {
            type: 'phone',
            required: true,
            inputProps: {
              disabled: isPro && !canEditPersonalData,
            },
          },
        },
      },
      {
        label: t(patientMessages.fields.email),
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.email]: {
            type: 'text',
            required: !isPro,
            rules: { pattern: { value: /@/, message: t(validationMessages.invalid_email) } },
            inputProps: {
              disabled: isPro ? !canEditPersonalData : !!userInfo.email,
            },
          },
        },
      },
    ];

    if (isPro) {
      result.push({
        label: t(proPatientMessages.contactPerson),
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.contactPerson]: {
            type: 'text',
          },
        },
      });
    }

    return result;
  }, [hasPatient]);

  const address = useMemo((): FormInputsConfig => {
    const result: FormInputsConfig = [
      {
        label: t(generalMessages.address.country),
        gridArea: PATIENT_FORM_INPUT_KEYS.country,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.country]: {
            type: 'autocomplete',
            inputProps: {
              freeSolo: true,
              disabled: isPro && !canEditAddressData,
            },
            options: getCountryDropdown(languageCode),
            required: true,
          },
        },
      },
      {
        label: t(generalMessages.address.postCode),
        gridArea: PATIENT_FORM_INPUT_KEYS.postcode,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.postcode]: {
            type: 'text',
            required: true,
            inputProps: {
              disabled: isPro && !canEditAddressData,
            },
            rules: {
              validate: (currentValue?: string) =>
                validatePostalCode({
                  currentValue,
                  currentCountryValue: form.getValues(PATIENT_FORM_INPUT_KEYS.country as 'country'),
                  errorMessage: t(validationMessages.invalid_postcode),
                }),
            },
            mask: postalCodePatterns
              ? {
                  pattern: postalCodePatterns.maskPattern,
                  definitions: {
                    '#': /[0-9]/,
                  },
                }
              : undefined,
          },
        },
      },
      {
        label: t(generalMessages.address.city),
        gridArea: PATIENT_FORM_INPUT_KEYS.city,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.city]: {
            type: 'autocomplete',
            options: cityOptions,
            required: true,
            inputProps: {
              freeSolo: true,
              disabled: isPro && !canEditAddressData,
            },
          },
        },
      },
      {
        label: t(generalMessages.address.street),
        gridArea: PATIENT_FORM_INPUT_KEYS.street,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.street]: {
            type: 'autocomplete',
            options: streetOptions,
            required: true,
            inputProps: {
              freeSolo: true,
              disabled: isPro && !canEditAddressData,
            },
          },
        },
      },
      {
        label: t(generalMessages.address.buildingNumber),
        gridArea: PATIENT_FORM_INPUT_KEYS.buildingNumber,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.buildingNumber]: {
            type: 'text',
            required: true,
            inputProps: {
              disabled: isPro && !canEditAddressData,
            },
          },
        },
      },
      {
        label: t(generalMessages.address.apartmentNumber),
        gridArea: PATIENT_FORM_INPUT_KEYS.apartmentNumber,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.apartmentNumber]: {
            type: 'text',
            inputProps: {
              disabled: isPro && !canEditAddressData,
            },
          },
        },
      },
    ];

    if (isPro) {
      result.push({
        label: t(proPatientMessages.addressDirections),
        gridArea: PATIENT_FORM_INPUT_KEYS.addressDirections,
        inputs: {
          [PATIENT_FORM_INPUT_KEYS.addressDirections]: {
            type: 'text',
            inputProps: {
              disabled: isPro && !canEditAddressData,
            },
          },
        },
      });
    }

    return result;
  }, [postalCodePatterns, cityOptions, streetOptions]);

  return { personal, contact, address };
};

export default usePatientFormConfig;
