import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import coreServiceGroups from 'api/coreServiceGroups/coreServiceGroups';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { ServicesForColumnsDisplaySet } from 'constants/_types/ServicesForColumnsDisplaySet';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

export const useGetServicesForGroup = () => {
  const { t } = useTranslation();
  const { groupId } = useParams() as { groupId: string };
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());
  const { patient } = usePatientContext();

  const { data: groupData, isLoading } = useQuery(
    [QUERY_KEYS.GET_SERVICES_FOR_GROUP, patient?.id, selectedPayersList, groupId],
    coreServiceGroups.getServicesForGroup({ groupId: +groupId, payers: selectedPayersList, patientId: patient?.id }),
    { enabled: mainFunctionalitiesAllowed && !!patient?.id },
  );

  const services = useMemo(() => {
    if (!groupData) return null;
    const initialAccumulator: ServicesForColumnsDisplaySet = { headers: [], entities: {} };
    return groupData.coreServices.reduce((accumulator, service) => {
      const { headers, entities } = accumulator;
      const key = service.subtype.masterType;
      if (!headers.some(({ id: headerId }) => key === headerId)) {
        headers.push({ id: key, name: service.subtype.masterTypeName });
      }
      if (!entities[key]) entities[key] = [];
      entities[key].push({
        id: service.id,
        name: service.name,
        metaData: service,
        searchableName: t(service.name.key, service.name.defaultValue),
      });
      return { headers, entities };
    }, initialAccumulator);
  }, [groupData]);

  return { groupData, services, isLoading };
};
