import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import eCareContactNotes from 'api/eCareContactNotes/eCareContactNotes';
import { ECareContactNote } from 'api/eCareContactNotes/eCareContactNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import PartnerNotesDialog from 'components/UI/organisms/_dialogs/PartnerNotesDialog/PartnerNotesDialog';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import generalMessages from 'translations/common/general.mjs';
import partnerNotesMessages from 'translations/specific/partnerNotes.mjs';

import useAlarmNotesTable from '../../_hooks/useAlarmNotesTable/useAlarmNotesTable';
import usePhoneCallNotes from './_hooks/usePhoneCallNotes/usePhoneCallNotes';
import useStyles from './PartnerNotes.styles';

type Props = {
  eCareAlarmsData: ECareAlarm;
  disabled?: boolean;
};

const PartnerNotes: FC<Props> = ({ eCareAlarmsData, disabled }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const [isPartnerNotesDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const orderId = eCareAlarmsData.order;

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, orderId], [orderId]);
  const { data: contactNotesData } = useQuery(queryKey, eCareContactNotes.getAllNotes(orderId), {
    refetchOnWindowFocus: false,
  });

  const alarmsNotesTable = useAlarmNotesTable(contactNotesData?.data as ECareContactNote[]);

  const showNotesHistory = () => {
    showConfirmationDialog({
      title: t(partnerNotesMessages.title),
      body:
        alarmsNotesTable?.data &&
        alarmsNotesTable.data.map(note => (
          <BoxWithHeader
            className={classes.boxList}
            description={note.content}
            header={[
              {
                label: note.updatedAt
                  ? t(partnerNotesMessages.eCarePartnerNotesTable.updatedTime)
                  : t(partnerNotesMessages.eCarePartnerNotesTable.creationTime),
                value: note.updatedAt
                  ? unknownDateToString(note.updatedAt, DATE_FORMATS.DISPLAY_TIME)
                  : unknownDateToString(note.createdAt, DATE_FORMATS.DISPLAY_TIME),
              },
              {
                label: t(partnerNotesMessages.eCarePartnerNotesTable.lastSync),
                value: note.lastSync ? unknownDateToString(note.lastSync, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noLastSyncNote),
              },
              {
                label: t(partnerNotesMessages.eCarePartnerNotesTable.id),
                value: note.updatedBy ? note.updatedBy : note.createdBy,
              },
              {
                label: t(partnerNotesMessages.eCarePartnerNotesTable.receivers),
                value: note.receivers.map(receiver => receiver).join(', '),
              },
            ]}
            key={note.id}
          />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  const phoneCallNote = usePhoneCallNotes(alarmsNotesTable?.data, disabled);

  return (
    <>
      <SectionWithTitle
        className={classes.section}
        title={t(partnerNotesMessages.description)}
        titleIcon={
          <>
            <Button disabled={disabled} variant='contained' onClick={openDialog}>
              {t(partnerNotesMessages.buttons.addNote)}
            </Button>
            <InfoIconWithTooltip content={t(partnerNotesMessages.tooltip.addNote)} />
          </>
        }
        titleRightSideComponent={
          phoneCallNote &&
          phoneCallNote.length > 0 && (
            <Button variant='outlined' onClick={showNotesHistory}>
              {t(partnerNotesMessages.more)}
            </Button>
          )
        }
      >
        <DefinitionList
          columns={1}
          customClasses={{
            listItemClass: classes.noPadding,
          }}
          definitions={phoneCallNote}
        />
      </SectionWithTitle>
      <PartnerNotesDialog
        close={closeDialog}
        isNew
        isOpen={isPartnerNotesDialogOpen}
        orderId={orderId}
        serviceRecipientId={eCareAlarmsData.serviceRecipient}
      />
    </>
  );
};

export default PartnerNotes;
