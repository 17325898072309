import type { FC, ReactNode } from 'react';

import useStyles from './CustomColorText.styles';

export interface Props {
  colorPath?: string;
  children: ReactNode;
}

const CustomColorText: FC<Props> = ({ colorPath, children }) => {
  const { classes } = useStyles({ colorPath });

  return <span className={classes.root}>{children}</span>;
};

export default CustomColorText;
