import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    minWidth: 'unset',
    whiteSpace: 'unset',
  },
}));

export default useStyles;
