import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  caption: {
    margin: theme.spacing(2, 0),
  },
  editButtonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
}));

export default useStyles;
