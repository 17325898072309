import { useState } from 'react';

import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';

import users from 'api/users';
import { ResetPasswordRequestFormInput } from 'components/UI/organisms/ResetPasswordRequestForm/ResetPasswordRequestForm';
import PATHS from 'constants/router/PATHS';
import useMutationWithRecaptcha from 'hooks/useMutationWithRecaptcha/useMutationWithRecaptcha';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery/useNavigateWithQuery';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';

export const RESET_PASSWORD_REQUEST_MUTATION_KEY = 'ResetPasswordRequest dialog request';

type UseResetPasswordRequestMutationArguments = {
  setError: UseFormSetError<ResetPasswordRequestFormInput>;
};

const useResetPasswordRequestMutation = ({ setError }: UseResetPasswordRequestMutationArguments) => {
  const navigateWithQuery = useNavigateWithQuery();
  const { mutateWithRecaptcha } = useMutationWithRecaptcha('Reset_password_request');

  const [generalErrors, setGeneralErrors] = useState<string[]>();

  const onError = useOnBackendFormError<ResetPasswordRequestFormInput, ResetPasswordRequestFormInput>({
    setError,
    setGeneralErrors,
  });

  const { mutate, isLoading } = useMutation(RESET_PASSWORD_REQUEST_MUTATION_KEY, users.passwordResetRequest(), {
    retry: 0,
    onSuccess: () => {
      navigateWithQuery(`/${PATHS.AUTH_RESET_PASSWORD_SUCCESS}`);
    },
    onError,
  });

  const resetPasswordRequestMutate = async (mutationData: ResetPasswordRequestFormInput) => {
    mutateWithRecaptcha<ResetPasswordRequestFormInput>(mutate, mutationData);
  };

  return {
    resetPasswordRequestMutate,
    resetPasswordRequestMutateGeneralErrors: generalErrors,
    isResetPasswordRequestMutating: isLoading,
  };
};

export default useResetPasswordRequestMutation;
