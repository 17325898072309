import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { Payer } from 'api/payers/types';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type {
  CumulativeExtendedServiceItem,
  CumulativeServiceDetails,
  CumulativeServiceItem,
  RegularServiceDetails,
  SelectedServices,
} from 'constants/_types/SelectedServices';
import { CUMULATIVE_CORE_SERVICE_KINDS } from 'constants/coreServices/cumulativeCoreServiceKinds';

import { parseCumulativeServiceToRow } from '../../_services/parseCumulativeServiceToRow/parseCumulativeServiceToRow';
import { parseRegularServiceToRow } from '../../_services/parseRegularServiceToRow/parseRegularServiceToRow';
import { parseRows } from '../../_services/parseRows/parseRows';
import type { ContentRow } from '../../_types/ContentRow';

interface HookParams {
  selectedServices: SelectedServices;
  payersData: Payer[] | undefined;
}

export const useParseRowsForOrderDetailsConfirmationDialog = ({ selectedServices, payersData }: HookParams) => {
  const { t } = useTranslation();

  const parsedRows = useMemo(() => {
    const rows: ContentRow[] = [];
    Object.entries(selectedServices).forEach(([key, details]) => {
      const isCumulative = CUMULATIVE_CORE_SERVICE_KINDS.includes(key as OrderServiceCoreServicesKinds);
      if (isCumulative) {
        rows.push(
          ...parseCumulativeServiceToRow({
            details: details as CumulativeServiceDetails<
              Record<string, CumulativeServiceItem> | Record<string, CumulativeExtendedServiceItem>
            >,
            t,
          }),
        );
      } else {
        rows.push(...parseRegularServiceToRow({ details: details as RegularServiceDetails, t }));
      }
    });

    return parseRows({ rows, payersData });
  }, [selectedServices, payersData, t]);

  return parsedRows;
};
