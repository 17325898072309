import type { FC } from 'react';
import { useCallback } from 'react';

import { MenuItem } from '@mui/material';
import type { FlagComponent } from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';

import type { SupportedLanguages } from 'constants/translations/_types';

import { renderFlag } from '../../_services/renderFlag/renderFlag';

export interface Props {
  className: string;
  code: SupportedLanguages;
  disabled: boolean;
  label: string[];
  icon: FlagComponent;
  changeLanguage: (code: SupportedLanguages) => Promise<void>;
}

const LanguageItem: FC<Props> = ({ className, code, disabled, label, icon, changeLanguage }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    changeLanguage(code);
  }, []);

  return (
    <MenuItem disabled={disabled} onClick={handleClick}>
      {renderFlag(icon, className)}
      {t(label)}
    </MenuItem>
  );
};

export default LanguageItem;
