import type { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';

import useStyles from './SectionWithTitle.styles';

export type Props = {
  title: string | ReactNode;
  titleIcon?: ReactNode;
  titleRightSideComponent?: ReactNode;
  children: ReactNode | ReactNode[];
  className?: string;
};

const SectionWithTitle: FC<Props> = ({ title, titleIcon, children, className, titleRightSideComponent }) => {
  const { cx, classes } = useStyles();
  return (
    <section className={cx(classes.root, className)}>
      <div className={classes.wrapper}>
        <Typography className={classes.title} component='h3' variant='h4'>
          {title} {titleIcon}
        </Typography>
        {titleRightSideComponent}
      </div>
      {children}
    </section>
  );
};

export default SectionWithTitle;
