import type { FC } from 'react';
import { useMemo } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import LinkWithQuery from 'components/UI/atoms/LinkWithQuery/LinkWithQuery';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import generalMessages from 'translations/common/general.mjs';
import registerMessages from 'translations/specific/register.mjs';

const RegisterSuccessPage: FC = () => {
  usePageConfig();

  const { t } = useTranslation();
  const location = useLocation();
  const email = useMemo(() => {
    const state = location.state as { email: string };
    return state && state.email ? state.email : t(registerMessages.your_email_fallback);
  }, []);
  return (
    <SimpleFormWrapper>
      <Typography align='center'>{t(registerMessages.registration_successful, { email })}</Typography>
      <Button component={LinkWithQuery} to={`/${PATHS.AUTH_LOGIN}`} variant='contained'>
        {t(generalMessages.ok)}
      </Button>
    </SimpleFormWrapper>
  );
};

export default RegisterSuccessPage;
