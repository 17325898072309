import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  filters: {
    padding: theme.spacing(0, 0, 2),
  },
  filterBtn: {
    position: 'relative',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      zIndex: -1,
      transition: 'transform .2s linear',
      transform: isOpen ? 'translateY(100%)' : 'translateY(0)',
      transitionDelay: isOpen ? '0' : '.2s',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export default useStyles;
