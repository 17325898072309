import type { FC } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PatientCardSectionTitle from 'components/UI/atoms/PatientCardSectionTitle/PatientCardSectionTitle';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './PatientClausesNotRequired.styles';

interface Props {
  openClausesDialog: () => void;
}

const PatientClausesNotRequired: FC<Props> = ({ openClausesDialog }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <PatientCardSectionTitle titleText={t(patientMessages.sectionTitles.clausesNotRequired)} />
        <Typography className={classes.caption} component='p' variant='caption'>
          {t(patientMessages.clauseNotRequiredDescription)}
        </Typography>
        <Button size='small' variant='contained' onClick={openClausesDialog}>
          {t(patientMessages.buttons.editClausesNotRequired)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PatientClausesNotRequired;
