import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  relative: {
    position: 'relative',
  },
  blockPointerEvents: {
    pointerEvents: 'none',
  },
  loaderCover: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    background: 'rgba(255,255,255, 0.55)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 3px',
  },
  tableHead: {
    background: theme.palette.colorBackground.primary.light,
    tr: {
      outline: `1px solid ${theme.palette.grey[200]}`,
      borderTopRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: theme.shape.borderRadius,
    },
    th: {
      borderBottom: 0,
      '.Mui-active': {
        color: theme.palette.primary.main,
        svg: {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  tableBody: {
    tr: {
      transition: 'all .2s ease-in-out',
      'td:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },

      'td:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  },
  pagination: {
    display: 'inline-block',

    '.Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '&:hover': { backgroundColor: theme.palette.primary.light },
    },
  },
}));

export default useStyles;
