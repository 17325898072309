import symptomCheckerReports from 'translations/specific/symptomCheckerReports.mjs';

export const reportsTableHeaders = [
  {
    id: 'report_name',
    translationArray: symptomCheckerReports.reportsList.tableHeaders.reportName,
    styleConfig: {
      maxWidth: 'auto',
      width: '35%',
      minWidth: 'auto',
    },
  },
  {
    id: 'created_at',
    translationArray: symptomCheckerReports.reportsList.tableHeaders.createdAt,
    styleConfig: {
      maxWidth: 'auto',
      width: '30%',
      minWidth: 'auto',
    },
  },
  {
    id: 'recommendation',
    translationArray: symptomCheckerReports.reportsList.tableHeaders.recommendation,
    styleConfig: {
      maxWidth: '360px',
      width: '35%',
      minWidth: 'auto',
    },
  },
];
