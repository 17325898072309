import type { FC } from 'react';

import type { Theme } from '@mui/material/styles';

import { ReactComponent as ConsultationIcon } from 'assets/icons/triage/consultation.svg';
import { ReactComponent as Consultation24Icon } from 'assets/icons/triage/consultation_24.svg';
import { ReactComponent as EmergencyIcon } from 'assets/icons/triage/emergency.svg';
import { ReactComponent as EmergencyAmbulanceIcon } from 'assets/icons/triage/emergency_ambulance.svg';
import { ReactComponent as SelfCareIcon } from 'assets/icons/triage/self_care.svg';
import { SYMPTOM_CHECKER_TRIAGE_LEVELS, type SymptomCheckerTriageLevelValues } from 'constants/_types/SymptomCheckerTriageLevels';

const resolveTriageColor = (theme: Theme, triage: SymptomCheckerTriageLevelValues) => {
  const { triage: triageColor } = theme.palette;
  switch (triage) {
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.consultation:
      return triageColor.consultation;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.consultation24:
      return triageColor.consultation_24;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.emergency:
      return triageColor.emergency;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.selfCare:
      return triageColor.self_care;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.emergencyAmbulance:
      return triageColor.emergency_ambulance;
    default:
      return theme.palette.primary;
  }
};

type ResolveTriageIcon = (triage: SymptomCheckerTriageLevelValues) => FC;
const resolveTriageIcon: ResolveTriageIcon = triage => {
  switch (triage) {
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.consultation:
      return ConsultationIcon;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.consultation24:
      return Consultation24Icon;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.emergency:
      return EmergencyIcon;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.selfCare:
      return SelfCareIcon;
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.emergencyAmbulance:
      return EmergencyAmbulanceIcon;
    default:
      return ConsultationIcon;
  }
};

export { resolveTriageColor, resolveTriageIcon };
