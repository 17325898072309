import axios from 'axios';

import type { Clause, ClauseLocation, DefaultApiResponse, PaginatedApiResponse } from './_types';

const endpoints = {
  clauses: 'api/v1/clause/',
  locations: 'api/v1/locations/',
};

type GetClausesQuery = () => Promise<DefaultApiResponse<Clause[]>>;
type GetClauseForContextQuery = () => Promise<DefaultApiResponse<Clause[]>>;
type GetClauseLocationsQuery = () => Promise<PaginatedApiResponse<ClauseLocation>>;

const clauses = {
  getClauses: (): GetClausesQuery => () => axios.get<GetClausesQuery, DefaultApiResponse<Clause[]>>(endpoints.clauses),
  getClausesForLocation:
    (location?: number): GetClauseForContextQuery =>
    () =>
      axios.get<GetClauseForContextQuery, DefaultApiResponse<Clause[]>>(`${endpoints.clauses}?slots__location=${location}`),
  getClauseLocations: (): GetClauseLocationsQuery => () =>
    axios.get<GetClauseLocationsQuery, PaginatedApiResponse<ClauseLocation>>(endpoints.locations),
};

export default clauses;
