import type { TFunction } from 'react-i18next';

import type { RadioOption } from 'constants/_types/RadioOptions';
import { DateSoonOptions } from 'constants/_types/SelectedServices';
import orderServiceMessages from 'translations/specific/orderService.mjs';

export const getDateSoonRadioOptions = (t: TFunction): RadioOption<DateSoonOptions>[] => [
  { value: 'asap', label: t(orderServiceMessages.details.asap) },
  { value: 'nextSevenDays', label: t(orderServiceMessages.details.nextSevenDays) },
];
