interface Params {
  mainError: string;
  uploadingAttachmentErrors: string[] | undefined;
  formSubmissionErrors: string[] | undefined;
}

export const combineSubmissionErrors = ({ mainError, uploadingAttachmentErrors, formSubmissionErrors }: Params) => {
  const submissionErrors = [...(uploadingAttachmentErrors || []), ...(formSubmissionErrors || [])];

  const shouldAddMainError = submissionErrors.length > 0;
  if (shouldAddMainError) submissionErrors.push(mainError);

  return !submissionErrors.length ? undefined : submissionErrors;
};
