import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import LanguageChanger from 'components/UI/molecules/LanguageChanger/LanguageChanger';
import TwoFASwitch from 'components/UI/molecules/TwoFASwitch/TwoFASwitch';
import PasswordChangeContainer from 'components/UI/organisms/PasswordChangeContainer/PasswordChangeContainer';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

export interface UserSetting {
  id: string;
  headerText: string;
  content: JSX.Element;
}

type UseSettingsData = () => { settingsData: UserSetting[] };

export const useSettingsData: UseSettingsData = () => {
  const { t } = useTranslation();

  const settingsData = useMemo(
    () => [
      { id: '0', headerText: t(userSettingsMessages.chooseLanguage), content: <LanguageChanger /> },
      { id: '1', headerText: t(userSettingsMessages.twoFA.title), content: <TwoFASwitch /> },
      { id: '2', headerText: t(userSettingsMessages.passwordChange), content: <PasswordChangeContainer /> },
    ],
    [t],
  );

  return { settingsData };
};
