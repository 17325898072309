import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import eCareDeviceEvents from 'api/eCareDeviceEvents/eCareDeviceEvents';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';

const CACHE_TIME = 1000 * 60 * 60;

const useDeviceEventDictionary = () => {
  const { t } = useTranslation();
  const { data } = useQuery(QUERY_KEYS.GET_ECARE_DEVICE_EVENTS, eCareDeviceEvents.getAll(), {
    cacheTime: CACHE_TIME,
    refetchOnWindowFocus: false,
  });

  const dictionary: Record<string, string> = {};

  data?.forEach(({ value, label }) => {
    dictionary[value] = label;
  });

  const hasKey = (key: string) => key in dictionary;
  const resolveValue = (key: string | null) => (key && hasKey(key) ? t(dictionary[key]) : t(generalMessages.noData));

  return { data, dictionary, resolveValue };
};

export default useDeviceEventDictionary;
