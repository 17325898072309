import { memo, type FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

import InputWithDebouncedAction from 'components/UI/atoms/InputWithDebouncedAction/InputWithDebouncedAction';
import servicesMessages from 'translations/specific/services.mjs';

export type Props = {
  onSearch: (phrase: string) => void;
  disabled?: boolean;
};

const SearchBar: FC<Props> = ({ onSearch, disabled }) => {
  const { t } = useTranslation();

  return (
    <InputWithDebouncedAction
      action={onSearch}
      clearable
      inputProps={{ disabled, placeholder: `${t(servicesMessages.search.placeholder)}...` }}
      startAdornment={<SearchIcon />}
    />
  );
};

const SearchBarMemoized = memo(SearchBar);

export { SearchBarMemoized };

export default SearchBar;
