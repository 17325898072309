import { getPaginatedServicesFromSubtype } from './getPaginatedServicesFromSubtype/getPaginatedServicesFromSubtype';
import { getServicesFromSubtype } from './getServicesFromSubtype/getServicesFromSubtype';
import { getServicesSearchResults } from './getServicesSearchResults/getServicesSearchResults';

export const endpoint = '/api/v1/core-service/';

const coreService = {
  getServicesFromSubtype,
  getPaginatedServicesFromSubtype,
  getServicesSearchResults,
};

export default coreService;
