import type { FC } from 'react';
import { useMemo } from 'react';

import type { InputProps } from '@mui/material';
import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputAutocomplete from 'components/UI/molecules/InputAutocomplete/InputAutocomplete';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import validationMessages from 'translations/common/validation.mjs';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  options: DropdownOption[] | [];
  helperText?: string;
  required?: boolean;
  defaultValue?: string;
  rules?: ValidationRule<any>;
  inputProps?: InputProps;
  freeSolo?: boolean;
  setSelectedItemMetaData?: (metaData: Pick<DropdownOption, 'meta'>) => void;
};

const FormInputAutocomplete: FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = '',
  inputProps = {},
  rules = {},
  required = false,
  options,
  freeSolo,
  setSelectedItemMetaData,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_field) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <InputAutocomplete
          error={fieldState.error?.message}
          freeSolo={freeSolo}
          helperText={helperText}
          label={label}
          options={options}
          setSelectedItemMetaData={setSelectedItemMetaData}
          {...inputProps}
          {...field}
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputAutocomplete;
