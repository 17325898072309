import { type FC } from 'react';

import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  control: Control<any>;
  defaultValue?: string;
};

const FormInputTextHidden: FC<Props> = ({ control, name, defaultValue = '' }) => (
  <Controller control={control} defaultValue={defaultValue} name={name} render={({ field }) => <input type='hidden' {...field} />} />
);

export default FormInputTextHidden;
