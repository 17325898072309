import axios from 'axios';

import { DefaultApiResponse } from 'api/_types';

import { parseUserPermissionsForFE } from './userPermissions.parsers';
import { UserPermissions, UserPermissionsDTO } from './userPermissions.types';

const endpoint = '/api/v1/users/permissions/';

type GetUserPermissionsQuery = () => Promise<UserPermissions>;

const userPermissions = {
  getUserPermissions: (): GetUserPermissionsQuery => async () => {
    const { data } = await axios.get<GetUserPermissionsQuery, DefaultApiResponse<UserPermissionsDTO>>(endpoint);
    return parseUserPermissionsForFE(data);
  },
};
export default userPermissions;
