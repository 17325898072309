import { FC, useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import actionLogs from 'api/actionLogs/actionLogs';
import type { ActionLog } from 'api/actionLogs/actionLogs.types';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useGetApiContentTypeId from 'hooks/useGetApiContentTypeId/useGetApiContentTypeId';
import useTableData from 'hooks/useTableData/useTableData';
import generalMessages from 'translations/common/general.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

type PatientHistoryEntity = ActionLog;

export type Props = {
  close: () => void;
  isOpen: boolean;
  patientId: number;
};

const PatientEditionHistoryDialog: FC<Props> = ({ close, isOpen, patientId }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const helper = createColumnHelper<PatientHistoryEntity>();

    return [
      helper.accessor('date', {
        id: 'date',
        header: t(proPatientMessages.changesHistoryDialog.dateColumn) as string,
        enableSorting: false,
        cell: info => unknownDateToString(info.getValue()),
      }),
      helper.accessor('by', {
        id: 'by',
        header: t(proPatientMessages.changesHistoryDialog.userColumn) as string,
        enableSorting: false,
        cell: info => info.getValue(),
      }),
      helper.accessor('changeType', {
        id: 'changeType',
        header: t(proPatientMessages.changesHistoryDialog.actionColumn) as string,
        enableSorting: false,
        cell: info => info.getValue(),
      }),

      helper.accessor('changeDetails', {
        id: 'changeDetails',
        header: t(proPatientMessages.changesHistoryDialog.actionDetails) as string,
        enableSorting: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: info => {
          const value = info.getValue();

          return value ? <InfoIconWithTooltip content={<pre>{value}</pre>} /> : null;
        },
      }),
    ];
  }, [t]);

  const [patientContentTypeId] = useGetApiContentTypeId(['patient']);

  const { query, sort, onSort, page, onExactPage, pagesCount, isFetchingNewPage } = useTableData<keyof PatientHistoryEntity>({
    queryFunction: actionLogs.getActionLogs({ contentType: patientContentTypeId, objectId: patientId }),
    queryKeyBase: `${QUERY_KEYS.GET_PATIENT_EDITION_HISTORY}-${patientId}`,
    queryOptions: { enabled: isOpen },
  });

  return (
    <DialogWrapper
      actionsConfig={[{ label: t(generalMessages.close), onClick: close, variant: 'contained' }]}
      close={close}
      header={t(proPatientMessages.changesHistoryDialog.dialogHeader)}
      isOpen={isOpen}
    >
      {/* Generic Component throws error, but idk why, it should work */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CustomTable<PatientHistoryEntity>
        columns={columns}
        data={query.data?.data}
        page={page}
        pagesCount={pagesCount}
        showLoaderCover={isFetchingNewPage}
        sort={sort}
        onExactPage={onExactPage}
        onSort={onSort}
      />
    </DialogWrapper>
  );
};

export default PatientEditionHistoryDialog;
