const proPatientMessages = {
    title: ['proPatient>>title', 'Profil pacjenta'],
    listTitle: ['proPatient>>listTitle', 'Lista pacjentów'],
    patientStatus: ['proPatient>>patientStatus', 'Obecny status pacjenta'],
    commissionList: ['proPatient>>commissionList', 'Zlecenia pacjenta'],
    alarmsList: ['proPatient>>alarmsList', 'Alarmy SOS pacjenta'],
    showChangesHistory: ['proPatient>>showChangesHistory', 'Pokaż historię edycji'],
    showPatientDevices: ['proPatient>>showPatientDevices', 'Pokaż urządzenia'],
    changesHistoryDialog: {
        dialogHeader: ['proPatient>>changesHistoryDialog>>dialogHeader', 'Historia edycji danych pacjenta'],
        dateColumn: ['proPatient>>changesHistoryDialog>>dateColumn', 'Data i czas'],
        userColumn: ['proPatient>>changesHistoryDialog>>userColumn', 'Użytkownik'],
        actionColumn: ['proPatient>>changesHistoryDialog>>actionColumn', 'Rodzaj modyfikacji'],
        actionDetails: ['proPatient>>changesHistoryDialog>>actionDetails', 'Szczegoły modyfikacji'],
    },
    showPatientDevicesDialog: {
        title: ['proPatient>>showPatientDevicesDialog>>title', 'Urządzenia pacjenta'],
        availableDevices: ['proPatient>>showPatientDevicesDialog>>availableDevices', 'Pokaż rodzaje urządzeń'],
    },
    sectionTitles: {
        policiesAndSubscriptions: ['proPatient>>sectionTitles>>policiesAndSubscriptions', 'Polisy i abonamenty'],
        healthDetails: ['proPatient>>sectionTitles>>healthDetails', 'Dane o zdrowiu pacjenta'],
        dataFromEcare: ['proPatient>>sectionTitles>>dataFromEcare', 'Dane ze zleceń Teleopieki'],
        dataFromNote: ['proPatient>>sectionTitles>>dataFromNote', 'Notatki o pacjencie'],
        dataFromFiles: ['proPatient>>sectionTitles>>dataFromFiles', 'Pliki związane z pacjentem'],
        healthSurveyFile: ['proPatient>>sectionTitles>>healthSurveyFile', 'Ankieta medyczna'],
        otherFiles: ['proPatient>>sectionTitles>>otherFiles', 'Inne pliki'],
    },
    healthDetails: {
        emergencyContactPhone: ['proPatient>>healthDetails>>emergencyContactPhone', 'Telefon kontaktowy do osoby bliskiej'],
        dwellingType: ['proPatient>>healthDetails>>dwellingType', 'Rodzaj mieszkania'],
        otherDwellingType: ['proPatient>>healthDetails>>otherDwellingType', 'Inny rodzaj mieszkania'],
        healthConditions: ['proPatient>>healthDetails>>healthConditions', 'Warunki zdrowotne'],
        healthProblems: ['proPatient>>healthDetails>>healthProblems', 'Problemy zdrowotne'],
        dysfunctions: ['proPatient>>healthDetails>>dysfunctions', 'Dysfunkcje'],
        lifestyle: ['proPatient>>healthDetails>>lifestyle', 'Styl życia'],
        lifemode: ['proPatient>>healthDetails>>lifemode', 'Tryb życia'],
        mobilityDisabilityDegree: ['proPatient>>healthDetails>>mobilityDisabilityDegree', 'Stopień niepełnoprawności ruchowej'],
        cognitiveDisabilityDegree: ['proPatient>>healthDetails>>cognitiveDisabilityDegree', 'Stopień niepełnoprawności poznawczej'],
        deviceTypes: ['proPatient>>healthDetails>>deviceTypes', 'Rodzaje urządzeń'],
        showFileInputLabel: ['proPatient>>healthDetails>>showFileInputLabel', 'Dodaj plik'],
        fileDescriptionInputLabel: ['proPatient>>healthDetails>>fileDescriptionInputLabel', 'Opis pliku'],
        removeFile: ['proPatient>>healthDetails>>removeFile', 'Usuń plik'],
        editFile: ['proPatient>>healthDetails>>editFile', 'Edytuj plik'],
        removeFileConfirmationTitle: ['proPatient>>healthDetails>>removeFileConfirmationTitle', 'Usuwanie pliku'],
        removeFileConfirmationBody: ['proPatient>>healthDetails>>removeFileConfirmationBody', 'Czy na pewno chcesz usunąć ten plik?'],
        fileRemoved: ['proPatient>>healthDetails>>fileRemoved', 'Plik usunięty'],
    },
    filters: {
        firstName: ['proPatient>>filters>>firstName', 'Imię'],
        lastName: ['proPatient>>filters>>lastName', 'Nazwisko'],
        pesel: ['proPatient>>filters>>pesel', 'Pesel'],
        phoneNumber: ['proPatient>>filters>>phoneNumber', 'Numer telefonu'],
        address: ['proPatient>>filters>>address', 'Ulica'],
        city: ['proPatient>>filters>>city', 'Miasto'],
        postalCode: ['proPatient>>filters>>postalCode', 'Kod pocztowy'],
        email: ['proPatient>>filters>>email', 'Email'],
        status: ['proPatient>>filters>>status', 'Status'],
        payer: ['proPatient>>filters>>payer', 'Gmina / Płatnik'],
        ppaCount: ['proPatient>>filters>>ppaCount', 'PPA'],
    },
    patientsTable: {
        firstNameColumn: ['proPatient>>patientsTable>>firstNameColumn', 'Imię'],
        lastNameColumn: ['proPatient>>patientsTable>>lastNameColumn', 'Nazwisko'],
        peselColumn: ['proPatient>>patientsTable>>peselColumn', 'Pesel'],
        phoneNumberColumn: ['proPatient>>patientsTable>>phoneNumberColumn', 'Numer telefonu'],
        addressColumn: ['proPatient>>patientsTable>>addressColumn', 'Ulica'],
        cityColumn: ['proPatient>>patientsTable>>cityColumn', 'Miasto'],
        postalColumn: ['proPatient>>patientsTable>>postalColumn', 'Kod'],
        emailColumn: ['proPatient>>patientsTable>>emailColumn', 'Email'],
        statusColumn: ['proPatient>>patientsTable>>statusColumn', 'Status'],
        payer: ['proPatient>>patientsTable>>payer', 'Gmina / Płatnik'],
        ppaCount: ['proPatient>>patientsTable>>ppaCount', 'PPA'],
    },
    addNote: ['proPatient>>addNote', 'Dodaj notatkę'],
    patientStatuses: {
        // keys are coupled with values on the backend
        ACTIVE: ['proPatient>>patientStatuses>>active', 'Aktywny'],
        INACTIVE: ['proPatient>>patientStatuses>>inactive', 'Nieaktywny'],
    },
    addressDirections: ['proPatient>>addressDirections', 'Wskazówki dojazdu'],
    contactPerson: ['proPatient>>contactPerson', 'Osoby do kontaktu'],
    socialSituationNoteLabel: ['proPatient>>socialSituationNoteLabel', 'Sytuacja socjalna'],
    healthConditionNoteLabel: ['proPatient>>healthConditionNoteLabel', 'Stan zdrowia'],
    editionHistory: ['proPatient>>editionHistory', 'Historia edycji notatki'],
    editNote: ['proPatient>>editNote', 'Edutuj notatkę'],
    noteInputLabel: ['proPatient>>noteInputLabel', 'Zawartość notatki'],
};

export default proPatientMessages;
