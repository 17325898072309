import { FC, useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ProfessionalEOrderHistoryEntity } from 'api/eCareOrder/eCareOrder.types';
import { ECareSuspension } from 'api/eCareSuspension/eCareSuspension.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

export type Props = {
  close: () => void;
  isOpen: boolean;
  suspensions: ECareSuspension[];
};

const ProfessionalEOrderHistoryDialog: FC<Props> = ({ close, isOpen, suspensions }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const helper = createColumnHelper<ProfessionalEOrderHistoryEntity>();

    return [
      helper.accessor('reason', {
        id: 'reason',
        header: t(proECareOrderMessages.suspensionTable.reason) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('dateFrom', {
        id: 'date_from',
        header: t(proECareOrderMessages.suspensionTable.dateFrom) as string,
        cell: info => unknownDateToString(info.getValue()),
      }),
      helper.accessor('dateTo', {
        id: 'date_to',
        header: t(proECareOrderMessages.suspensionTable.dateTo) as string,
        cell: info => unknownDateToString(info.getValue()),
      }),
    ];
  }, [t]);

  return (
    <DialogWrapper
      actionsConfig={[{ label: t(generalMessages.close), onClick: close, variant: 'contained' }]}
      close={close}
      header={t(proECareOrderMessages.dialog.suspensionHistory)}
      isOpen={isOpen}
    >
      {/* Generic Component throws error, but idk why, it should work */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CustomTable<ProfessionalEOrderHistoryEntity> columns={columns} data={suspensions} />
    </DialogWrapper>
  );
};

export default ProfessionalEOrderHistoryDialog;
