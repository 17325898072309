import type { FC } from 'react';

import { ReactComponent as AvatarPlaceholder } from 'assets/icons/hub/user.svg';

import useStyles from './Avatar.styles';

export type Props = {
  imageUrl?: string;
};

export { AVATAR_SIZE } from './Avatar.styles';

const Avatar: FC<Props> = ({ imageUrl }) => {
  const { classes } = useStyles({ placeholder: !imageUrl, imageUrl });
  return (
    <div className={classes.root} data-testid='avatar-wrapper'>
      {!imageUrl && <AvatarPlaceholder className={classes.icon} />}
    </div>
  );
};

export default Avatar;
