import { format } from 'date-fns';

import type { ServiceRequestsFormDataDto } from 'api/serviceRequests/types';
import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import type { CumulativeExtendedServiceItem, CumulativeServiceDetails, CumulativeServiceItem } from 'constants/_types/SelectedServices';
import { SERVICE_HOURS_RANGE_PLACEHOLDER } from 'constants/coreServices/serviceHoursRangePlaceholder';
import { SERVICE_ORIGINS_REQUIRING_HOURS_RANGE } from 'constants/coreServices/serviceOriginsRequiringHoursRange';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import { resolveIsServiceItemExtended } from 'services/_serviceItems/resolveIsServiceItemExtended/resolveIsServiceItemExtended';

import { parseAddressData } from '../parseAddressData/parseAddressData';

export interface Params {
  details: CumulativeServiceDetails<Record<string, CumulativeServiceItem> | Record<string, CumulativeExtendedServiceItem>>;
  addressData: OrderServiceFormInput['address'];
  patientId: number;
  paramOrigin: OrderServiceOrigins;
}

export const parseCumulativeServices = ({ details, addressData, patientId, paramOrigin }: Params): ServiceRequestsFormDataDto[] =>
  Object.values(details.serviceItems).map(serviceItem => {
    const parsedServiceCommonData = {
      patient: patientId,
      payer: serviceItem.payer,
      service: serviceItem.id,
      asap: details.dateSoon === 'asap',
      first_week: details.dateSoon === 'nextSevenDays',
      completion_date_from: details.dateFrom ? format(new Date(details.dateFrom), DATE_FORMATS.API_DATE) : null,
      completion_date_to: details.dateUntil ? format(new Date(details.dateUntil), DATE_FORMATS.API_DATE) : null,
      hours_range: SERVICE_ORIGINS_REQUIRING_HOURS_RANGE.includes(paramOrigin) ? SERVICE_HOURS_RANGE_PLACEHOLDER : '',
      medical_issue: '',
      additional_information: '',
    };

    const isExtendedServiceItem = resolveIsServiceItemExtended(serviceItem);

    if (isExtendedServiceItem) {
      return {
        ...parsedServiceCommonData,
        attachments: (serviceItem as CumulativeExtendedServiceItem).attachmentId,
        referral_request: (serviceItem as CumulativeExtendedServiceItem).orderReferral,
        ...parseAddressData(addressData),
      };
    }
    return {
      ...parsedServiceCommonData,
      ...parseAddressData(addressData),
    };
  });
