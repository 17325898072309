import { useTranslation } from 'react-i18next';

import { Operator } from 'api/eCarePhoneCall/eCarePhoneCall.types';
import generalMessages from 'translations/common/general.mjs';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

const useOperatorData = (operator: Operator | undefined) => {
  const { t } = useTranslation();

  return [
    {
      label: t(ecarePhoneCallMessages.operator.firstName),
      value: operator?.first_name || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.operator.lastName),
      value: operator?.last_name || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.operator.phoneNumber),
      value: operator?.phone_number || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.operator.email),
      value: operator?.email || t(generalMessages.noData),
    },
  ];
};

export default useOperatorData;
