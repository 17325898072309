import { useTranslation } from 'react-i18next';

import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

const useGeneratePersons = (data: ECareOrder | undefined) => {
  const { t } = useTranslation();

  return {
    section1: [
      {
        label: t(proECareOrderMessages.patientsTable.guardianFirstName),
        value: data?.serviceRecipient?.guardianFirstName || t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.patientsTable.guardianLastName),
        value: data?.serviceRecipient?.guardianLastName || t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.patientsTable.guardianPhoneNumber),
        value: data?.serviceRecipient?.guardianPhoneNumber || t(generalMessages.noData),
      },
    ],
    section2: [
      {
        label: t(proECareOrderMessages.healthDetails.phoneNumber),
        labelElement: <InfoIconWithTooltip content={t(proECareOrderMessages.healthDetails.phoneNumberInfo)} />,
        value: data?.serviceRecipient?.phoneNumber || t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.healthDetails.emergencyContactPhone),
        value: data?.emergencyContactPhone || t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.healthDetails.lifemode),
        value: data?.lifestyle ? data.lifestyle : t(generalMessages.noData),
      },
    ],
  };
};

export default useGeneratePersons;
