import type { FC } from 'react';

import { Card, Divider, Typography } from '@mui/material';

import useStyles from './CardWithSeparatedTitle.styles';

export interface Props {
  title: string;
  maxWidth?: number;
}

const CardWithSeparatedTitle: FC<Props> = ({ title, maxWidth, children }) => {
  const { classes } = useStyles({ maxWidth });

  return (
    <Card className={classes.root}>
      <Typography className={classes.title} variant='h4'>
        {title}
      </Typography>
      <Divider variant='fullWidth' />
      <div className={classes.content}>{children}</div>
    </Card>
  );
};

export default CardWithSeparatedTitle;
