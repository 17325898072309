import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';

import { endpoints } from '../faq';
import { parseFaqForFE } from '../parseFaqForFE/parseFaqForFE';
import type { FaqDto, Faq } from '../types';

type GetFaqQuery = () => Promise<PaginatedApiResponse<FaqDto>>;
type GetFaq = () => Promise<Faq[]>;

export const getFaq = (): GetFaq => async () => {
  const data = await axios.get<GetFaqQuery, PaginatedApiResponse<FaqDto>>(endpoints.faq);

  return parseFaqForFE(data.data.results);
};
