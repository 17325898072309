type SymptomCheckerTriageLevelKeys = 'consultation' | 'consultation24' | 'emergency' | 'emergencyAmbulance' | 'selfCare';

export type SymptomCheckerTriageLevelValues = 'consultation' | 'consultation_24' | 'emergency' | 'emergency_ambulance' | 'self_care';

export const SYMPTOM_CHECKER_TRIAGE_LEVELS: { [key in SymptomCheckerTriageLevelKeys]: SymptomCheckerTriageLevelValues } = {
  consultation: 'consultation',
  consultation24: 'consultation_24',
  emergency: 'emergency',
  emergencyAmbulance: 'emergency_ambulance',
  selfCare: 'self_care',
};
