import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { ClickAwayListener, OutlinedInput, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GlobalSearchOpenMobileButton from 'components/UI/molecules/GlobalSearchOpenMobileButton/GlobalSearchOpenMobileButton';
import GlobalSearchPayerSelector from 'components/UI/molecules/GlobalSearchPayerSelector/GlobalSearchPayerSelector';
import GlobalSearchExpanded from 'components/UI/organisms/GlobalSearchExpanded/GlobalSearchExpanded';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import { useIsMatchingMedia } from 'hooks/_breakpoints/useIsMatchingMedia/useIsMatchingMedia';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import useGlobalSearchStorage from 'storages/globalSearchStorage';
import breakpoints from 'styles/themeParts/breakpoints';
import globalSearchMessages from 'translations/specific/globalSearch.mjs';

import useStyles from './GlobalSearchBar.styles';

export const testId = createTestIdObject('GlobalSearchBar', {
  searchDesktop: 'searchDesktop',
});

const GlobalSearchBar: FC = () => {
  const { t } = useTranslation();
  const { globalSearchIsOpen, showGlobalSearch, hideGlobalSearch, searchPhrase } = useGlobalSearchStorage();
  const isMatchingGlobalSearchBarBreakpoint = useIsMatchingMedia({ prefix: 'max', breakpointValue: breakpoints.values.searchBar });
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();

  const onScroll = useCallback(hideGlobalSearch, []);

  const removeScrollListener = useCallback(() => window.removeEventListener('scroll', onScroll), []);

  useEffect(() => {
    if (globalSearchIsOpen) {
      window.addEventListener('scroll', onScroll);
    } else {
      removeScrollListener();
    }
    return removeScrollListener;
  }, [globalSearchIsOpen]);

  const { classes } = useStyles({ disabled: !mainFunctionalitiesAllowed });
  return (
    <ClickAwayListener mouseEvent='onMouseUp' onClickAway={hideGlobalSearch}>
      <Tooltip placement='bottom' title={mainFunctionalitiesAllowed ? '' : (t(globalSearchMessages.globalSearchDisabled) as string)}>
        <div className={classes.wrapper}>
          {isMatchingGlobalSearchBarBreakpoint ? (
            <GlobalSearchOpenMobileButton disabled={!mainFunctionalitiesAllowed} />
          ) : (
            <div className={classes.searchBar} data-testid={testId.searchDesktop}>
              <OutlinedInput
                classes={{ root: classes.inputRoot, notchedOutline: classes.inputOutline }}
                disabled={!mainFunctionalitiesAllowed}
                placeholder={t(globalSearchMessages.searchInput.placeholder)}
                readOnly
                startAdornment={<SearchIcon className={classes.searchIcon} />}
                value={searchPhrase}
                onClick={mainFunctionalitiesAllowed ? showGlobalSearch : undefined}
              />
              <GlobalSearchPayerSelector disabled={!mainFunctionalitiesAllowed} />
            </div>
          )}
          {/* visible only when expanded */}
          <GlobalSearchExpanded />
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default GlobalSearchBar;
