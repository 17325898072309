import type { PersonalDataUnassigned } from 'api/_types';
import { GENDER } from 'constants/_types/Gender';
import { GENDER_TITLES } from 'constants/_types/GenderTitle';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';

import type { PatientFormInput } from '../../_constants/patientFormKeys';

export const preparePersonalDataForUpdate = (rawData: PatientFormInput): PersonalDataUnassigned => {
  const result: PersonalDataUnassigned = {
    title: rawData.gender === GENDER.m ? GENDER_TITLES.mr : GENDER_TITLES.mrs,
    first_name: rawData.firstName,
    last_name: rawData.lastName,
    email: rawData.email,
    phone_number: rawData.phoneNumber,
    gender: rawData.gender,
    pesel: rawData.pesel,
    date_of_birth: unknownDateToString(new Date(rawData.dateOfBirth), DATE_FORMATS.API_DATE),
  };
  if (rawData.hasForeignDocument) {
    result.date_of_birth = unknownDateToString(new Date(rawData.dateOfBirth), DATE_FORMATS.API_DATE);
  }

  return result;
};
