import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import type { SelectedServiceItemMetaData } from 'constants/_types/SelectedServiceItemMetaData';
import isProduction from 'helpers/isProduction/isProduction';

import { getBasketItemsInfo } from './_services/getBasketItemsInfo/getBasketItemsInfo';
import { incorporateNewItemsIntoBasket } from './_services/incorporateNewItemsIntoBasket/incorporateNewItemsIntoBasket';
import { removeItemFromBasket } from './_services/removeItemFromBasket/removeItemFromBasket';
import type { ServicesBasketStorage } from './_types/ServicesBasketStorage';

const storage = persist<ServicesBasketStorage>(
  (set, get) => ({
    basketState: null,
    selectedItemMetaData: null,

    setSelectedItemMetaData: metaData => set({ selectedItemMetaData: metaData as SelectedServiceItemMetaData }),

    getBasketInfo: () => getBasketItemsInfo(get().basketState),

    addToBasket: basketInputToSet => {
      const { modifiedBasketState, duplicatedItems } = incorporateNewItemsIntoBasket(get().basketState, basketInputToSet);

      set({ basketState: modifiedBasketState, selectedItemMetaData: null });

      return { duplicatedItems };
    },

    removeFromBasket: metaData => {
      const modifiedBasketState = removeItemFromBasket(get().basketState, metaData);

      set({ basketState: modifiedBasketState });
    },

    clearBasket: () => set({ basketState: null, selectedItemMetaData: null }),
  }),
  { name: 'servicesBasketStorage', getStorage: () => sessionStorage },
);

export const useServicesBasketStorage = isProduction() ? create(storage) : create(devtools(storage));
