import type { FC, ReactNode } from 'react';
import { lazy, Suspense } from 'react';

import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import { DEEP_BRANDING } from 'constants/_types/DeepBranding';

const DashboardDefault = lazy(() => import('./_versions/DashboardDefault/DashboardDefault'));
const DashboardInterrisk = lazy(() => import('./_versions/DashboardInterrisk/DashboardInterrisk'));

const renderDashboard: () => ReactNode = () => {
  switch (process.env.REACT_APP_DEEP_BRANDING) {
    case DEEP_BRANDING.interrisk:
      return <DashboardInterrisk />;
    default:
      return <DashboardDefault />;
  }
};

const isReducedPadding = DEEP_BRANDING.interrisk === process.env.REACT_APP_DASHBOARD_VERSION;

const DashboardPage: FC = () => (
  <MainContentWrapper isReducedPadding={isReducedPadding}>
    <Suspense fallback={<LoaderOverlay inner minHeight='80vh' />}>{renderDashboard()}</Suspense>
  </MainContentWrapper>
);
export default DashboardPage;
