import type { FC, FunctionComponent } from 'react';

import useStyles from './TwoStateIcon.styles';

export type Props = {
  icon: FunctionComponent<{ className?: string }>;
  isActive: boolean;
  customSize?: { width: string; height: string };
  className?: string;
};

const TwoStateIcon: FC<Props> = ({ icon: Icon, isActive, customSize, className }) => {
  const { classes, cx } = useStyles({ isActive, customSize });
  return <Icon className={cx(classes.icon, className)} />;
};

export default TwoStateIcon;
