import { FC, useMemo } from 'react';

import { Card, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';

import patients from 'api/patient/patients';
import { ReactComponent as PatientProfileIcon } from 'assets/icons/hub/active/user_plus.svg';
import DisabledWrapper from 'components/UI/atoms/DisabledWrapper/DisabledWrapper';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import PatientStatusDropdown from 'components/UI/molecules/PatientStatusDropdown/PatientStatusDropdown';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import PatientHealthDetails from 'components/UI/organisms/PatientHealthDetails/PatientHealthDetails';
import PatientPersonalInfo from 'components/UI/organisms/PatientPersonalInfo/PatientPersonalInfo';
import PatientPolicyInfo from 'components/UI/organisms/PatientPolicyInfo/PatientPolicyInfo';
import ProfessionalPatientHeaderTile from 'components/UI/organisms/ProfessionalPatientHeaderTile/ProfessionalPatientHeaderTile';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import usePatientEditorUIStorage from 'storages/patientEditorUIStorage';
import generalMessages from 'translations/common/general.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

const ProfessionalPatientSinglePage: FC = () => {
  usePageConfig();

  const { t } = useTranslation();
  const { isPatientEditorOpen } = usePatientEditorUIStorage();
  const { patientId } = useParams();

  const patientIdNum = useMemo(() => Number(patientId), [patientId]);

  const queryKey = useMemo(() => [QUERY_KEYS.PATIENT_INFO, patientIdNum], [patientIdNum]);
  const { data: patientData } = useQuery(queryKey, patients.getPatient(patientIdNum, true), {
    enabled: !!patientId,
    refetchOnWindowFocus: false,
  });

  const subLabel = useMemo(() => {
    if (!patientData) return <Skeleton width={120} />;

    const age = getAgeFromDOB(patientData.personalData.dateOfBirth);
    return `${patientData.personalData.firstName} ${patientData.personalData.lastName}, ${age} ${t(generalMessages.person.age)}`;
  }, [patientData]);

  if (!patientId) return <Navigate to={PATHS.PROFESSIONAL_PATIENTS} />;

  const pageTitle = {
    headerLabel: t(proPatientMessages.title),
    subLabel,
    extraContentComponent: <ProfessionalPatientHeaderTile patientId={patientIdNum} />,
    icon: <PatientProfileIcon />,
    extraAfterIconComponent: <PatientStatusDropdown patientId={patientIdNum} />,
  };

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitleWithFiltersForTables pageTitle={pageTitle} />
      <Card>
        <PatientPersonalInfo isPro patientId={patientIdNum} />
        <DisabledWrapper disabled={isPatientEditorOpen}>
          <PatientPolicyInfo
            isPro
            patientId={patientIdNum}
            showSelfPayment={false}
            showServicesInDialog
            title={t(proPatientMessages.sectionTitles.policiesAndSubscriptions)}
          />
          <PatientHealthDetails patientId={patientIdNum} />
        </DisabledWrapper>
      </Card>
    </MainContentWrapper>
  );
};

export default ProfessionalPatientSinglePage;
