import type { FC } from 'react';

import { Grid } from '@mui/material';

import { UserSetting } from 'components/UI/organisms/SettingsCard/_hooks/useSettingsData/useSettingsData';

import SettingSectionContent from './_components/SettingSectionContent/SettingSectionContent';
import SettingSectionHeader from './_components/SettingSectionHeader/SettingSectionHeader';
import useStyles from './SettingSection.styles';

export type Props = Omit<UserSetting, 'id'>;

const SettingSection: FC<Props> = ({ headerText, content }) => {
  const { classes } = useStyles();

  return (
    <Grid container item md={6} xs={12}>
      <div className={classes.root}>
        <SettingSectionHeader headerText={headerText} />
        <SettingSectionContent content={content} />
      </div>
    </Grid>
  );
};

export default SettingSection;
