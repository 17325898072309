import type { CoreServiceType, CoreServiceTypeDTO } from 'api/coreServiceTypes/types';
import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

export const parseServiceTypesForFE = (serviceTypes: CoreServiceTypeDTO[]): CoreServiceType[] =>
  serviceTypes.map(entity => ({
    id: entity.id,
    serviceOrigin: entity.service_origin,
    name: parseDynamicTranslatableName(entity.name),
    ordering: entity.ordering,
  }));
