import { makeStyles } from 'tss-react/mui';

const PERSONAL_SECTION = 'personalSection';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'block',
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    textAlign: 'right',
  },
  value: {
    marginTop: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  noPaddingX: {
    padding: '5px 0',
  },
  standardColumnWrapper: {
    display: 'grid',
    alignContent: 'flex-start',
  },
  container: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'start',
    width: '100%',

    '> div': {
      flex: 1,
    },
  },
  personalSection: {
    gridArea: PERSONAL_SECTION,
    gridGap: 10,
  },
  patientButton: {
    justifySelf: 'flex-start',
  },

  multiDefinitionList: {
    ul: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      ':last-of-type': {
        borderBottom: 0,
      },
    },
  },

  icon: {
    cursor: 'pointer',
    width: 15,
  },
}));

export default useStyles;
