import { ALL_COUNTRIES_LANGUAGES_VERSIONS } from 'constants/dropdownOptions/countries/ALL_COUNTRIES_LANGUAGES_VERSIONS';

export const getCountryCodeByCountryOptionValue = (selectedCountryOptionValue: string) => {
  let selectedCountryCode: string | undefined;

  ALL_COUNTRIES_LANGUAGES_VERSIONS.forEach(countries => {
    if (selectedCountryCode) return;
    const matchingCountryOfCurrentLanguageVersion = countries.find(({ name }) => name === selectedCountryOptionValue);
    selectedCountryCode = matchingCountryOfCurrentLanguageVersion?.code;
  });

  return selectedCountryCode;
};
