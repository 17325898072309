import type { FC } from 'react';
import { memo } from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableHeader as TableHeaderType } from 'constants/_types/TableHeader';

import useStyles from './TableHeader.styles';

interface Props {
  headers: TableHeaderType[];
}

const TableHeader: FC<Props> = ({ headers }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.row}>
        {headers.map(({ id, translationArray, styleConfig: { maxWidth, width, minWidth } }) => (
          <TableCell align='left' className={classes.cell} key={id} style={{ maxWidth, width, minWidth }}>
            {t(translationArray)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default memo(TableHeader);
