import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parsePayersForFE } from '../parsePayersForFE/parsePayersForFE';
import { endpoint } from '../payers';
import type { Payer, PayerDTO } from '../types';

type GetPayersQuery = () => Promise<Payer[]>;

export const getPayers =
  (patientId: number): GetPayersQuery =>
  async () => {
    const { data } = await axios.get<GetPayersQuery, DefaultApiResponse<PayerDTO[]>>(
      createUrlWithParams({
        url: endpoint.payers,
        params: { patient_id: patientId },
      }),
    );

    return parsePayersForFE(data);
  };
