import type { FC } from 'react';
import { useEffect } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import auth from 'api/auth';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery/useNavigateWithQuery';
import authMessages from 'translations/common/auth.mjs';

const ActivateAccountPage: FC = () => {
  const { t } = useTranslation();
  const { token, uid } = useParams() as { token: string; uid: string };
  const navigateWithQuery = useNavigateWithQuery();

  const activateAccountMutation = useMutation(QUERY_KEYS.ACTIVATE_ACCOUNT, auth.accountActivation(), { onError: undefined, retry: false });

  useEffect(() => {
    activateAccountMutation.mutate({ token, uid });
  }, []);

  if (activateAccountMutation.isError) {
    return (
      <Typography align='center' variant='subtitle1'>
        {t(authMessages.invalid_activation_link)}
      </Typography>
    );
  }

  if (activateAccountMutation.isSuccess) {
    navigateWithQuery(`/${PATHS.AUTH_LOGIN}`, { state: { accountActivated: true } });
  }

  return <LoaderOverlay />;
};

export default ActivateAccountPage;
