import Cookies from 'js-cookie';

type Config = {
  string?: boolean;
};

class CookiesService {
  cookieKey: string;

  string?: boolean = false;

  constructor(cookieKey: string, { string }: Config = {}) {
    this.cookieKey = cookieKey;
    this.string = string;
  }

  get = () => {
    let data: string | undefined;
    try {
      data = Cookies.get(this.cookieKey);
      if (!data) return undefined;
      return this.string ? data : JSON.parse(data);
    } catch {
      return data;
    }
  };

  set = (data: string | object | []) => {
    try {
      const dataJSON = this.string ? data : JSON.stringify(data);
      Cookies.set(this.cookieKey, dataJSON);
      return dataJSON;
    } catch {
      return undefined;
    }
  };

  destroy = () => {
    Cookies.remove(this.cookieKey);
    return true;
  };
}

export default CookiesService;
