import axios from 'axios';

import type { PaginatedApiResponse } from 'api/_types';
import type { CoreServiceDTO } from 'api/coreService/coreService.types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

interface Params {
  patientId: number;
  fetchSize: number;
  payers: string[];
  page?: number;
  completedColumns: string[];
  columnsIds: string[];
}

type GetPaginatedServicesFromSubtypeQuery = (
  params: Params,
) => Promise<{ dataForType: CoreServiceDTO[] | null; type: string; isCompleted: boolean }[]>;

export const getPaginatedServicesFromSubtype: GetPaginatedServicesFromSubtypeQuery = async ({
  patientId,
  fetchSize,
  page = 0,
  payers,
  completedColumns,
  columnsIds,
}) => {
  const endpoint = '/api/v1/core-service/';
  const prepareParams = (type: string) =>
    createUrlWithParams({
      url: endpoint,
      params: {
        patient_id: patientId,
        limit: fetchSize,
        offset: page * fetchSize,
        payer: payers,
        type,
      },
    });

  const queries: (Promise<PaginatedApiResponse<CoreServiceDTO>> | null)[] = columnsIds.map(id => {
    const abortFetch = completedColumns.includes(id);
    if (abortFetch) return null;
    return axios.get<GetPaginatedServicesFromSubtypeQuery, PaginatedApiResponse<CoreServiceDTO>>(prepareParams(id));
  });

  const responses = await Promise.all(queries);
  return responses.map((resolved, index) => ({
    dataForType: resolved?.data.results || null,
    type: columnsIds[index],
    isCompleted: !resolved?.data.next,
  }));
};
