import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

interface StylesProps {
  isOpen: boolean;
  menuItemsNumber: number;
  shouldDisplayMoreButton: boolean;
}

const useStyles = makeStyles<StylesProps>()((theme, { isOpen, menuItemsNumber, shouldDisplayMoreButton }) => ({
  drawer: {
    width: '100%',
    visibility: 'visible',
  },
  drawerPaper: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: isOpen ? LAYOUT_SIZES.BOTTOM_MENU.open : LAYOUT_SIZES.BOTTOM_MENU.closed,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: shouldDisplayMoreButton ? 'repeat(4, 1fr)' : `repeat(${menuItemsNumber}, 1fr)`,
    justifyItems: 'center',
  },
}));

export default useStyles;
