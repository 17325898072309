import type { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';

import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';

import useStyles from './ServicesReviewTableWrapper.styles';

export type Props = {
  title: string;
  children: ReactNode;
  isLoading: boolean;
  isTableActive?: boolean;
};

const ServicesReviewTableWrapper: FC<Props> = ({ title, children, isLoading, isTableActive = false }) => {
  const { classes, cx } = useStyles({ isLoading: isLoading || false, isTableActive });

  return (
    <div className={cx(classes.root, isTableActive && classes.rootBorder)}>
      <Typography align='center' variant='h4'>
        {title}
      </Typography>
      <ElementWithLoader className={classes.table} isLoading={isLoading}>
        {children}
      </ElementWithLoader>
      {isTableActive && <span className={classes.rootBorderNode} />}
    </div>
  );
};

export default ServicesReviewTableWrapper;
