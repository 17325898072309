import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  row: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    boxShadow: 'unset',
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& > div': {
      // action buttons section
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '&:hover': {
      boxShadow: theme.shadows[5],
      '& > div': {
        // action buttons section
        opacity: 1,
      },
    },
  },
  cell: {
    color: theme.palette.secondary.main,
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      wordBreak: 'break-word',
    },
  },
  actionsBox: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingRight: theme.spacing(2),
  },
}));

export default useStyles;
