import { useState } from 'react';

export type UseLoadingReturn = {
  state: boolean;
  start: () => void;
  end: () => void;
};
type UseLoading = (initial?: boolean) => UseLoadingReturn;

const useLoading: UseLoading = (initial = false) => {
  const [isLoading, setIsLoading] = useState(initial);

  const start = () => setIsLoading(true);
  const end = () => setIsLoading(false);

  return { state: isLoading, start, end };
};

export default useLoading;
