import type { FC } from 'react';

import { HomeOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import PATHS from 'constants/router/PATHS';

import useStyles from './BreadcrumbHomeIcon.styles';

const BreadcrumbHomeIcon: FC = () => {
  const { classes } = useStyles();

  return (
    <Link to={PATHS.ROOT}>
      <HomeOutlined className={classes.icon} />
    </Link>
  );
};

export default BreadcrumbHomeIcon;
