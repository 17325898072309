import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ headersCount: number }>()((theme, { headersCount }) => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },

  headerContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(${headersCount}, ${100 / headersCount}%)`,
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
