import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(5),
    '& > button': {
      flex: 1,
    },
  },
}));

export default useStyles;
