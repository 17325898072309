import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';

import branding, { Brand } from 'api/branding';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

type Props = {
  children: ReactNode;
};

export type BrandInfoState = (Brand & { isDefault: boolean }) | null;

type BrandContextType = {
  brandInfo: BrandInfoState;
};

const DEFAULT_BRAND = 'default';

const BrandContext = createContext({} as BrandContextType);

const getBrandFromURL = (): string => {
  const { hostname } = window.location;
  const domain = process.env.REACT_APP_DOMAIN || '';

  return hostname.replace('www.', '').replace(domain, '').split('.')[0] || DEFAULT_BRAND;
};

const redirectToBaseDomain = (brandFromURL: string): void => {
  window.location.href = process.env.REACT_APP_BASE_URL || window.location.href.replace(`${brandFromURL}.`, '');
};

const BrandContextProvider: FC<Props> = ({ children }) => {
  const [brandInfo, setBrandInfo] = useState<BrandInfoState>(null);
  const brandFromURL = getBrandFromURL();

  const { isLoading, data: brandData } = useQuery(QUERY_KEYS.BRAND_INFO, branding.getInfo(brandFromURL), {
    retry: false,
    refetchOnWindowFocus: true,
    onSuccess: ({ data }) => {
      setBrandInfo({ ...data, isDefault: DEFAULT_BRAND === brandFromURL });
    },
    onError: ({ response }) => {
      if (response.status === 404) {
        // eslint-disable-next-line no-console
        console.log(`redirect from ${window.location.origin} to ${brandFromURL}`);
        if (window.location.origin !== process.env.REACT_APP_BASE_URL) redirectToBaseDomain(brandFromURL);
      }
    },
  });

  const value = useMemo(() => ({ brandInfo }), [brandInfo]);
  if (isLoading) return <LoaderOverlay />;
  return (
    <BrandContext.Provider value={value}>
      <Helmet>
        <link href={brandData?.data.brand.favicon} rel='icon' sizes='any' />
        <title>{brandInfo?.name}</title>
      </Helmet>
      {children}
    </BrandContext.Provider>
  );
};

export const useBrandContext = () => useContext(BrandContext);

export default BrandContextProvider;
