import { makeStyles } from 'tss-react/mui';

import type { FormBarButtons } from 'constants/styles/_types/FormBarButtons';

const useStyles = makeStyles<{ gridArea: FormBarButtons | undefined }>()((theme, { gridArea = '' }) => ({
  globalFormButton: {
    gridArea,
  },
}));

export default useStyles;
