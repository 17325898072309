import { FC } from 'react';

import ECareList from 'components/UI/organisms/ECareList/ECareList';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ProfessionalEOrdersListPage: FC = () => {
  usePageConfig();

  return (
    <MainContentWrapper extraContainer noPadding>
      <ECareList />
    </MainContentWrapper>
  );
};

export default ProfessionalEOrdersListPage;
