import { DynamicTranslatableName, DynamicTranslatableNameDTO } from 'constants/_types/DynamicTranslatableName';

const idBase = 100000000;

const parseDynamicTranslatableName = (rawData?: DynamicTranslatableNameDTO | string): DynamicTranslatableName => {
  if (typeof rawData === 'string' || !rawData) {
    return {
      id: Math.floor(Math.random() * idBase) + idBase,
      key: 'general>>missingTranslation',
      defaultValue: 'Brakujące tłumaczenie',
    };
  }

  return {
    id: rawData.id,
    key: rawData.key,
    defaultValue: rawData.default_value,
  };
};

export default parseDynamicTranslatableName;
