import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ tiny?: boolean; error?: boolean }>()((theme, { tiny, error }) => ({
  root: {
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: 0,
    color: theme.palette.grey[500],
    fontSize: '.75rem',
    transform: 'translateY(-100%)',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  footer: {
    transform: 'translateY(-5px)',
  },
  label: {
    fontSize: tiny ? '.6875rem' : '1rem',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    '& > span': {
      display: 'flex',
    },
  },
  icon: {
    width: tiny ? '16px' : 'inherit',
    height: tiny ? '16px' : 'inherit',
  },
  asterisk: {
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
