import type { UseFormReturn } from 'react-hook-form';
import type { UseMutateAsyncFunction } from 'react-query';

import type { DefaultApiResponse } from 'api/_types';
import type { ServiceRequestsFormDataDto } from 'api/serviceRequests/types';
import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { terminateRecursiveLoop } from 'services/terminateRecursiveLoop/terminateRecursiveLoop';

import { gatherServicesAttachments } from '../gatherServicesAttachments/gatherServicesAttachments';

interface Params {
  form: UseFormReturn<OrderServiceFormInput, any>;
  receivedAttachmentIds: number[];
  serviceRequestsMutateAsync: UseMutateAsyncFunction<
    DefaultApiResponse<ServiceRequestsFormDataDto>,
    {
      response?:
        | {
            status: number;
            data: {
              [key: string]: string[];
            };
          }
        | undefined;
    },
    OrderServiceFormInput,
    unknown
  >;
  abortedProcessCallback: () => void;
}

// It ensures to insert all received attachment ids to the form data
// before the form submission
export const proceedOrderServiceFormSubmission = async ({
  form,
  receivedAttachmentIds,
  serviceRequestsMutateAsync,
  abortedProcessCallback,
}: Params) => {
  const { shouldTerminateRecursiveLoop, increaseRecursiveIterationCounter } = terminateRecursiveLoop('proceedOrderServiceFormSubmission');

  const checkAllAttachmentIdsInsertedToFormData = () => {
    increaseRecursiveIterationCounter();
    const newestFormAttachmentsData = gatherServicesAttachments(form.getValues().details);

    return newestFormAttachmentsData.length === receivedAttachmentIds.length;
  };

  const areAllAttachmentIdsInsertedToFormData = checkAllAttachmentIdsInsertedToFormData();

  if (shouldTerminateRecursiveLoop) {
    abortedProcessCallback();
    return;
  }

  if (areAllAttachmentIdsInsertedToFormData) await form.handleSubmit(data => serviceRequestsMutateAsync(data))();
  else checkAllAttachmentIdsInsertedToFormData();
};
