import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const { SIDE_MENU } = LAYOUT_SIZES;

const PADDING = 4.5;
const PADDING_MOBILE = 1.5;

const animatedProperties = ['transform', 'opacity', 'box-shadow'];

const labelFontSize = '1rem';
const labelFontSizeMobile = '0.875rem';

const GRID = {
  INPUT: 'INPUT',
  SELECT: 'SELECT',
  CLOSE: 'CLOSE',
};

const useStyles = makeStyles<{ isDrawerOpen: boolean; isVisible: boolean }>()((theme, { isDrawerOpen, isVisible }) => ({
  root: {
    zIndex: theme.zIndex.modal,
    position: 'fixed',
    top: theme.spacing(1.5),
    right: theme.spacing(PADDING),
    background: theme.palette.background.paper,
    border: `thin solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    width: `calc(100% - ${theme.spacing(PADDING * 2)} - ${isDrawerOpen ? SIDE_MENU.open : SIDE_MENU.closed}px)`,
    pointerEvents: isVisible ? 'auto' : 'none',

    // animated
    transform: `translateY(${isVisible ? 0 : '-150%'})`,
    opacity: isVisible ? 1 : 0,
    boxShadow: isVisible ? theme.shadows[5] : theme.shadows[0],

    willChange: animatedProperties.join(', '),
    transition: theme.transitions.create(animatedProperties, {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(PADDING_MOBILE * 2)})`,
      top: theme.spacing(PADDING_MOBILE),
      right: theme.spacing(PADDING_MOBILE),
    },
  },
  searchContainer: {
    gridTemplateAreas: `"${GRID.INPUT} ${GRID.SELECT} ${GRID.CLOSE}"`,

    display: 'grid',
    gridTemplateColumns: '3fr 1fr 64px',
    borderBottom: `thin solid ${theme.palette.grey[200]}`,

    '& > *:not(:last-child)': {
      borderRight: `thin solid ${theme.palette.grey[200]}`,

      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 48px',
      gridTemplateAreas: `
"${GRID.INPUT} ${GRID.CLOSE}"
"${GRID.SELECT} ${GRID.SELECT}"
    `,
    },
  },

  formInput: {
    gridArea: GRID.INPUT,
  },

  // select styles
  selectWrapper: {
    gridArea: GRID.SELECT,

    [theme.breakpoints.down('sm')]: {
      borderTop: `thin solid ${theme.palette.grey[200]}`,
    },
  },

  select: {
    fontSize: labelFontSize,
    padding: theme.spacing(1.25, 2),

    [theme.breakpoints.down('sm')]: {
      fontSize: labelFontSizeMobile,
      padding: theme.spacing(0.75, 1.25),
    },

    '& fieldset': {
      border: 'none',
    },
  },

  closeButton: {
    gridArea: GRID.CLOSE,
  },

  // input styles
  inputRoot: {
    fontSize: labelFontSize,
    padding: theme.spacing(1.25, 2),

    [theme.breakpoints.down('sm')]: {
      fontSize: labelFontSizeMobile,
      padding: theme.spacing(0.75, 1.25),
    },
  },
  inputOutline: {
    border: 'none',
  },
}));

export default useStyles;
