import type { FC } from 'react';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import type { Directions } from 'constants/_types/Direction';

import useStyles from './Chevron.styles';

export type Props = {
  direction: {
    base: Directions;
    alternate: Directions;
  };
  isAlternated?: boolean;
  color: string;
  className?: string;
};

const Chevron: FC<Props> = ({ color, direction, isAlternated, className }) => {
  const { cx, classes } = useStyles({ direction, isAlternated });
  return (
    <div className={cx(classes.root, className)}>
      <ChevronIcon stroke={color} />
    </div>
  );
};

export default Chevron;
