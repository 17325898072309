import type { FC } from 'react';

import { IconButton } from '@mui/material';

import useStyles from './PaginationChevronButton.styles';

interface Props {
  isDisabled: boolean;
  icon: JSX.Element;
  handleClick: () => void;
}

const PaginationChevronButton: FC<Props> = ({ isDisabled, icon, handleClick }) => {
  const { classes } = useStyles();

  return (
    <IconButton className={classes.root} disabled={isDisabled} onClick={handleClick}>
      {icon}
    </IconButton>
  );
};

export default PaginationChevronButton;
