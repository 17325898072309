import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import type { Message } from 'components/UI/organisms/MainContentWrapper/_types/types';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import { useTimeout } from 'hooks/useTimeout/useTimeout';

import { useMessageHandlers } from './_hooks/useMessageHandlers/useMessageHandlers';
import { useResolvedTextAndIcon } from './_hooks/useResolvedTextAndIcon/useResolvedTextAndIcon';

export const useSubmissionNotification = (setMessages: Dispatch<SetStateAction<Message[]>>) => {
  const location = useLocation() as { state: { origin: OrderServiceOrigins; servicesNumber: number } };
  const state = {
    type: location.state?.origin,
    servicesNumber: location.state?.servicesNumber,
  };

  const { shouldShowSubmissionSuccessNotification, servicesNumber, isSingleService } = useMemo(
    () => ({
      shouldShowSubmissionSuccessNotification: !!state?.type,
      servicesNumber: state?.servicesNumber,
      isSingleService: state?.servicesNumber === 1,
    }),
    [state],
  );

  const { text, headerText, icon } = useResolvedTextAndIcon({
    locationStateType: state?.type,
    isSingleService,
    servicesNumber,
  });
  const { setShowSubmissionSuccessNotification, removeSubmissionSuccessNotification } = useMessageHandlers({
    text,
    headerText,
    icon,
    setMessages,
  });

  useTimeout({ trigger: shouldShowSubmissionSuccessNotification, action: removeSubmissionSuccessNotification });

  return { shouldShowSubmissionSuccessNotification, setShowSubmissionSuccessNotification };
};
