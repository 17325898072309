import { FC } from 'react';

import { ECareInternalNotes } from 'api/eCareInternalNotes/eCareInternalNotes.types';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';

import InternalNotes from '../InternalNotes/InternalNotes';

type Props = {
  data: ECareInternalNotes[];
};

const InternalNotesList: FC<Props> = ({ data }) => {
  const lastInternalNote = data && data.length > 0 ? data[0] : undefined;

  const internalNotesList = [
    {
      label: '',
      value: lastInternalNote && <InternalNotes note={lastInternalNote} />,
    },
  ];

  return <DefinitionList columns={1} definitions={internalNotesList} />;
};

export default InternalNotesList;
