import type { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import generalMessages from 'translations/common/general.mjs';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

export interface Props {
  isOpened: boolean;
  close: () => void;
  confirm: () => void;
}

const TwoFASwitchConfirmationDialog: FC<Props> = ({ isOpened, close, confirm }) => {
  const { t } = useTranslation();

  return (
    <DialogWrapper
      close={close}
      customActions={
        <>
          <Button variant='outlined' onClick={close}>
            {t(generalMessages.cancel)}
          </Button>
          <Button variant='contained' onClick={confirm}>
            {t(generalMessages.save)}
          </Button>
        </>
      }
      dialogProps={{ maxWidth: 'sm', disablePortal: true, disableEnforceFocus: true }}
      header={t(userSettingsMessages.twoFA.modal.header)}
      isOpen={isOpened}
    >
      <Typography>{t(userSettingsMessages.twoFA.modal.content)}</Typography>
    </DialogWrapper>
  );
};

export default TwoFASwitchConfirmationDialog;
