const ICON_SIZES_STYLES = {
  small: {
    width: '16px',
    height: '16px',
  },
  root: {
    // as medium
    width: '24px',
    height: '24px',
  },
  large: {
    width: '32px',
    height: '32px',
  },
  extraLarge: {
    width: '42px',
    height: '42px',
  },
};

export default ICON_SIZES_STYLES;
