import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ hasAction: boolean; hasHeader: boolean }>()((theme, { hasAction, hasHeader }) => ({
  messageOuterContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(hasHeader ? 0 : 2),
      margin: theme.spacing(0, 3),
    },
  },

  messageContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: hasAction ? `minmax(100px, 200px) 1fr minmax(100px, 200px)` : 'auto',
    justifyItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
      gridGap: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  contentContainer: {
    display: 'grid',
    gridColumnStart: hasAction ? '2' : 'unset',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      gridColumn: '1/3',
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumnStart: 'unset',
      gridTemplateColumns: 'auto',
      gridGap: hasHeader ? 0 : 'inherit',
    },
  },

  headerContainer: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: hasHeader ? theme.palette.background.default : 'unset',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      padding: hasHeader ? theme.spacing(0.5, 2) : 0,
    },
  },

  header: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  content: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(hasHeader ? 2 : 0),
    },
  },

  title: {
    marginBottom: 0,
  },

  action: {
    justifySelf: 'flex-end',
  },
}));

export default useStyles;
