import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(5),
    [theme.breakpoints.down('xl')]: {
      gap: theme.spacing(2.45),
    },
  },
}));

export default useStyles;
