import type { Payer } from 'api/payers/types';
import type { DropdownOption } from 'constants/_types/DropdownOptions';

export const parsePayersToDropdownOptions = (payers: Payer[] | null, selfPaymentLabel: string): DropdownOption[] => {
  if (!payers) return [];

  return payers.map(payer => {
    const companyName = payer.company ? payer.company.name : payer.code;

    return {
      value: payer.isSelfPayer ? selfPaymentLabel : companyName,
      label: payer.isSelfPayer ? selfPaymentLabel : companyName,
      meta: { id: `${payer.id}` },
    };
  });
};
