import { makeStyles } from 'tss-react/mui';

import AVATAR_SIZE from 'constants/styles/AVATAR_SIZE';

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled }) => ({
  root: {
    position: 'relative',
    maxWidth: `${AVATAR_SIZE.width}px`,
    '&:hover': {
      '& > *': {
        opacity: 1,
      },
      '& > svg': {
        // works only for unset avatar
        opacity: disabled ? 0.1 : 1,
      },
    },
  },
  elementOnHover: {
    opacity: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  avatar: {
    width: '100%',
    maxWidth: `${AVATAR_SIZE.width}px`,
    maxHeight: `${AVATAR_SIZE.height}px`,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default useStyles;
