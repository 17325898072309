import type { ChangeEventHandler, FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, OutlinedInputProps } from '@mui/material';
import type { FormControlProps } from '@mui/material';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useDebounce from 'hooks/useDebounce/useDebounce';

import useStyles from './InputWithDebouncedAction.styles';

export const testId = createTestIdObject('InputWithDebouncedAction', {
  searchInput: 'searchInput',
  clearButton: 'clearButton',
  startAdornment: 'startAdornment',
});

export type Props = {
  label?: string;
  action: (param: string) => void;
  delay?: number;
  startAdornment?: ReactNode;
  clearable?: boolean;
  formControlProps?: FormControlProps;
  inputProps?: OutlinedInputProps;
};

const InputWithDebouncedAction: FC<Props> = ({
  label,
  action,
  formControlProps = {},
  delay = 500,
  startAdornment,
  clearable,
  inputProps,
}) => {
  const [value, setValue] = useState('');
  const updateValue: ChangeEventHandler<HTMLInputElement> = ({ target }) => setValue(target.value);

  const debouncedValue = useDebounce(value, delay);

  const onClear = () => {
    setValue('');
  };

  useEffect(() => {
    action(debouncedValue);
  }, [debouncedValue]);

  const { classes, cx } = useStyles({ size: inputProps?.size || 'medium' });
  return (
    <FormControl fullWidth variant='outlined' {...formControlProps}>
      <InputLabel color='secondary' htmlFor={`debounced-input-${label}`}>
        {label}
      </InputLabel>
      <OutlinedInput
        color='secondary'
        endAdornment={
          clearable && (
            <InputAdornment position='end'>
              <IconButton color='primary' data-testid={testId.clearButton} disabled={!value} disableRipple edge='end' onClick={onClear}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        id={`debounced-input-${label}`}
        inputProps={{
          'data-testid': testId.searchInput,
        }}
        startAdornment={
          startAdornment && (
            <InputAdornment classes={{ root: classes.startAdornment }} data-testid={testId.startAdornment} position='start'>
              {startAdornment}
            </InputAdornment>
          )
        }
        {...inputProps}
        classes={{
          input: cx(classes.input, inputProps?.classes?.input),
          root: cx(classes.inputRoot, inputProps?.classes?.root),
          ...(inputProps?.classes || {}),
        }}
        label={label}
        value={value}
        onChange={updateValue}
      />
    </FormControl>
  );
};

export default InputWithDebouncedAction;
