import { makeStyles } from 'tss-react/mui';

import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';

import { DefinitionListFontSizeT } from './DefinitionList';

const getFontSize = (size: DefinitionListFontSizeT) => {
  switch (size) {
    case 'small':
      return '1rem';
    case 'medium':
      return '1.3rem';
    case 'large':
      return '2rem';
    default:
      return exhaustiveGuard(size);
  }
};

const useStyles = makeStyles<{ columns: number; fontSize: DefinitionListFontSizeT }>()((theme, { columns, fontSize = 'medium' }) => ({
  value: {
    marginTop: theme.spacing(1),
  },
  label: {
    color: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
  },
  columns: {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    alignItems: 'start',
    gap: '5px 20px',
  },
  listItem: {
    padding: theme.spacing(0.5, 0),
  },
  fontSize: {
    fontSize: getFontSize(fontSize),
  },
}));

export default useStyles;
