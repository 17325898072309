import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { ECareTechnicalIssues } from 'api/eCareTechnicalIssues/eCareTechnicalIssues.types';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import PATHS from 'constants/router/PATHS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

import useStyles from './useBaseData.styles';

const useBaseData = (data: ECareTechnicalIssues) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return [
    [
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.serviceRecipient),
        value: data?.patient ? (
          <Link
            className={classes.titleLink}
            to={`/${generatePath(PATHS.PROFESSIONAL_PATIENT_SINGLE, { patientId: data.patient.id.toString() })}`}
          >
            {data.patient.personalData.firstName} {data.patient.personalData.lastName}
          </Link>
        ) : (
          t(generalMessages.noData)
        ),
      },
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.createdAt),
        value: data?.createdAt ? unknownDateToString(data?.createdAt, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.createdBy),
        value: data?.createdBy || t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.lastSync),
        value: data?.lastSync ? unknownDateToString(data?.lastSync, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
      },
    ],
    [
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.order),
        value: data?.order || t(generalMessages.noData),
      },
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDate),
        value: data?.fixDate ? unknownDateToString(data?.fixDate, DATE_FORMATS.DISPLAY) : t(generalMessages.noData),
      },
    ],
    [
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.description),
        value: data?.description || t(generalMessages.noData),
      },
    ],
    [
      {
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDescription),
        value: data?.fixDescription || t(generalMessages.noData),
      },
    ],
  ];
};

export default useBaseData;
