import type { FC } from 'react';

import { Menu } from '@mui/material';

import MenuButton from 'components/UI/molecules/MenuButton/MenuButton';
import type { MenuEntity } from 'hooks/useMenuContent/useMenuContent';

export interface Props {
  anchorElement: HTMLElement | null;
  isMenuOpened: boolean;
  listItems: MenuEntity[];
  handleMenuClose: () => void;
}

const MoreButtonList: FC<Props> = ({ anchorElement, isMenuOpened, listItems, handleMenuClose }) => (
  <Menu
    anchorEl={anchorElement}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    elevation={2}
    open={isMenuOpened}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    onClose={handleMenuClose}
  >
    {listItems.map(({ label, to, isActiveFor, icon, testId, hidden }) => (
      <MenuButton hidden={hidden} icon={icon} isActiveFor={isActiveFor} key={label} testId={testId} to={to}>
        {label}
      </MenuButton>
    ))}
  </Menu>
);

export default MoreButtonList;
