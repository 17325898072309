import { makeStyles } from 'tss-react/mui';

import { BASKET_AREA, MAIN_FORM_AREA } from './_constants/gridAreas';

const useStyles = makeStyles<{ formStep: number }>()((theme, { formStep }) => {
  let desktopGridConfig = { columns: '', areas: '' };
  switch (formStep) {
    case 2:
      desktopGridConfig = {
        columns: '1fr',
        areas: `
          "${MAIN_FORM_AREA}"
        `,
      };
      break;
    case 1:
    default:
      desktopGridConfig = {
        columns: '2.5fr 1fr',
        areas: `
          "${MAIN_FORM_AREA} ${BASKET_AREA}"
        `,
      };
  }

  return {
    root: {
      display: 'grid',
      gridTemplateColumns: desktopGridConfig.columns,
      gridTemplateRows: '1fr',
      gridTemplateAreas: desktopGridConfig.areas,
      gridGap: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2),
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
          "${MAIN_FORM_AREA}"
        `,
      },
    },
  };
});

export default useStyles;
