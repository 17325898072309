import { useMemo } from 'react';

import { isNil } from 'lodash';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { DateSoonOptions } from 'constants/_types/SelectedServices';
import orderServiceMessages from 'translations/specific/orderService.mjs';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  dateSoonName: string;
  dateFromName: string;
  dateUntilName: string;
}

export const usePrepareValidationRules = ({ form, dateSoonName, dateFromName, dateUntilName }: HookParams) => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const validateByDefault = () => {
      const dateSoonValue = form.getValues(dateSoonName as any) as DateSoonOptions;
      const dateFromValue = form.getValues(dateFromName as any) as string;
      const dateUntilValue = form.getValues(dateUntilName as any) as string;
      const isDateSoonEmpty = isNil(dateSoonValue) || !dateSoonValue;

      if (isDateSoonEmpty && (!dateFromValue || !dateUntilValue)) {
        return t(orderServiceMessages.details.validationErrors.deadline);
      }

      return false;
    };

    const resolveValidationSuccess = (): true => {
      const dateSoonError = form.getFieldState(dateSoonName as any).error?.message;
      const dateFromError = form.getFieldState(dateFromName as any).error?.message;
      const dateUntilError = form.getFieldState(dateUntilName as any).error?.message;
      if (dateSoonError) form.clearErrors(dateSoonName as any);
      if (dateFromError) form.clearErrors(dateFromName as any);
      if (dateUntilError) form.clearErrors(dateUntilName as any);

      return true;
    };

    // split this object to separate validators (not common) if they should validate in different way
    const commonValidation = {
      validate: () => {
        const validationDefaultOutput = validateByDefault();
        const hasErrorValidationDefault = !!validationDefaultOutput;

        return hasErrorValidationDefault ? validationDefaultOutput : resolveValidationSuccess();
      },
    };

    return {
      validationRangeFrom: commonValidation,
      validationRangeUntil: commonValidation,
      validationRadio: commonValidation,
    };
  }, [t, i18n.language]);
};
