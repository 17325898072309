export type Unit = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks';

export const getMs = (number: number, unit: Unit) => {
  if (!number || !unit) {
    throw new Error('Pass unit and number');
  }
  switch (unit) {
    case 'seconds':
      return number * 1000;
    case 'minutes':
      return number * 1000 * 60;
    case 'hours':
      return number * 1000 * 60 * 60;
    case 'days':
      return number * 1000 * 60 * 60 * 24;
    case 'weeks':
      return number * 1000 * 60 * 60 * 24 * 7;
    default:
      return number;
  }
};
