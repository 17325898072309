import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

interface Params {
  patientId: number;
  serviceOrigin?: OrderServiceOrigins;
  payers: string[] | undefined;
}

export const prepareParamsForGetServiceTypes = ({ patientId, serviceOrigin, payers }: Params) => ({
  patient_id: patientId,
  service_origin: serviceOrigin,
  limit: 250,
  payer: payers ?? [],
});
