import { useEffect, useState } from 'react';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import type { Message } from 'components/UI/organisms/MainContentWrapper/_types/types';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import { usePasswordChangeReminder } from 'hooks/usePageAlertMessages/_helperHooks/usePasswordChangeReminder/usePasswordChangeReminder';

import { useMissingAccountFields } from './_helperHooks/useMissingAccountFields/useMissingAccountFields';
import { useSubmissionNotification } from './_helperHooks/useSubmissionNotification/useSubmissionNotification';

export const usePageAlertMessages = () => {
  const { userInfo } = useAuthContext();
  const languageCode = useLanguageCode();

  const [messages, setMessages] = useState<Message[]>([]);
  const { shouldRemindPasswordChange, setPasswordChangeReminder } = usePasswordChangeReminder(setMessages);
  const { shouldAlertMissingAccountFields, setMissingAccountFields } = useMissingAccountFields();
  const { shouldShowSubmissionSuccessNotification, setShowSubmissionSuccessNotification } = useSubmissionNotification(setMessages);

  useEffect(() => {
    const tempMessages: Message[] = [];
    if (!userInfo.isAuthorized) {
      return;
    }
    if (shouldAlertMissingAccountFields) {
      setMissingAccountFields(tempMessages);
    }
    if (shouldRemindPasswordChange) {
      setPasswordChangeReminder(tempMessages);
    }
    if (shouldShowSubmissionSuccessNotification) {
      setShowSubmissionSuccessNotification(tempMessages);
    }

    setMessages(tempMessages);
  }, [shouldRemindPasswordChange, userInfo?.isAuthorized, userInfo?.hasFullAccount, languageCode]);

  return { alertMessages: messages };
};
