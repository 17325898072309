import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contactBox: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  image: {
    width: '100%',
    maxWidth: '125px',
  },
  phoneNumber: {
    display: 'block',
  },
  email: {
    display: 'block',
    wordBreak: 'break-word',
  },
}));

export default useStyles;
