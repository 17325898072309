import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareSyncWithCus, { ECareSyncCusEndpoint } from 'api/eCareSyncWithCus/eCareSyncWithCus';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import snackbarMessages from 'translations/common/snackbar.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './ECareSyncWithCusButton.styles';

type Props = {
  id: string;
  endpoints: ECareSyncCusEndpoint[];
  message: string;
  refreshQueryKey?: string[];
  disabled?: boolean;
};

const ECareSyncWithCusButton: FC<Props> = ({ id, endpoints, message, refreshQueryKey, disabled }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { showSnackbar } = useShowSnackbar();

  const queryKey = useMemo(() => [QUERY_KEYS.SYNC_ECARE_WITH_CUS, id], [id]);
  const queryClient = useQueryClient();

  const statusMutation = useMutation(eCareSyncWithCus.syncWithCus(id, endpoints), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async data => {
      if (!data?.success) {
        const { errorMessage } = data?.error as { errorMessage: string };
        showSnackbar({
          variant: 'error',
          translationArray: [errorMessage],
        });
      } else {
        await queryClient.invalidateQueries(queryKey);
        await queryClient.invalidateQueries(refreshQueryKey);
        showSnackbar({
          variant: 'success',
          translationArray: snackbarMessages.savedSyncSuccessfully,
        });
      }
    },
  });

  const syncWithCusDialog = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.confirmationDialog.syncWithCus.syncWithCusDialogTitle),
      body: t(proECareAlarmMessages.confirmationDialog.syncWithCus.syncWithCusDialogBody),
    });
    if (confirmed) statusMutation.mutate();
  };

  return (
    <div className={classes.container}>
      <span className={classes.message}>{message}</span>
      <Button disabled={disabled} variant='contained' onClick={syncWithCusDialog}>
        {t(proECareAlarmMessages.alarmCard.buttons.syncWithCus)}
      </Button>
    </div>
  );
};

export default ECareSyncWithCusButton;
