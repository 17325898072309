export const apiContentTypeModels = ['patient'] as const;

export type ApiContentTypeModels = typeof apiContentTypeModels[number];

export type ApiContentTypeDTO = {
  id: number;
  app_label: string;
  model: string;
};

export type ApiContentType = Record<ApiContentTypeModels, number>;
