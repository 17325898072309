import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import CardWithSeparatedTitle from 'components/UI/molecules/CardWithSeparatedTitle/CardWithSeparatedTitle';
import DynamicInput from 'components/UI/molecules/DynamicInput/DynamicInput';
import { FORM_CARD_MAX_WIDTH } from 'constants/styles/FORM_CARD_MAX_WIDTH';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { useAddressFormConfig } from './_hooks/useAddressFormConfig/useAddressFormConfig';
import { useAddressQuery } from './_hooks/useAddressQuery/useAddressQuery';
import { useFillAddressForm } from './_hooks/useFillAddressForm/useFillAddressForm';
import useStyles from './DetermineLocation.styles';

const DetermineLocation: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { form } = useOrderServiceFormContext();

  const { isFetched, addressData } = useAddressQuery();
  const addressInputs = useAddressFormConfig();

  useFillAddressForm({ isFetched, form, addressData });

  return (
    <CardWithSeparatedTitle maxWidth={FORM_CARD_MAX_WIDTH} title={t(orderServiceMessages.location.header)}>
      <div className={classes.inputsContainer}>
        {addressInputs.map(props => (
          <DynamicInput form={form} key={props.label} {...props} />
        ))}
      </div>
    </CardWithSeparatedTitle>
  );
};

export default DetermineLocation;
