import { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import type { PreselectedServiceData } from 'constants/_types/PreselectedServiceData';

export const usePreselectedData = () => {
  const { state } = useLocation() as { state?: PreselectedServiceData };

  // Preselected data in local state ensures updated UI
  const [localPreselectedData, setLocalPreselectedData] = useState<PreselectedServiceData | null>(null);

  useEffect(() => {
    if (!state) return;
    setLocalPreselectedData(state);
  }, [state]);

  const forgetPreselection = useCallback(() => {
    setLocalPreselectedData(null);
  }, []);

  return {
    preselectedTypeId: localPreselectedData?.serviceTypeId,
    preselectedSubtypeId: localPreselectedData?.serviceSubtypeId,
    preselectedServiceId: localPreselectedData?.serviceId ?? state?.serviceId,
    forgetPreselection,
  };
};
