export default () => (
  <svg height='22' viewBox='0 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'>
    <g data-name='Group 17' id='Group_17' transform='translate(-2 -2)'>
      <path d='M15.333,16.222H12a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0v1.222h2.333a1,1,0,0,1,0,2Z' id='primary' transform='translate(1 1.111)' />
      <path
        d='M21.889,24H4.111A2.114,2.114,0,0,1,2,21.889V5.222A2.114,2.114,0,0,1,4.111,3.111H7.556V3a1,1,0,0,1,2,0v.111h6.889V3a1,1,0,0,1,2,0v.111h3.444A2.114,2.114,0,0,1,24,5.222V21.889A2.114,2.114,0,0,1,21.889,24ZM4,10.667V21.889A.111.111,0,0,0,4.111,22H21.889A.111.111,0,0,0,22,21.889V10.667Zm0-2H22V5.222a.111.111,0,0,0-.111-.111H18.444V6.333a1,1,0,0,1-2,0V5.111H9.556V6.333a1,1,0,0,1-2,0V5.111H4.111A.111.111,0,0,0,4,5.222ZM16.333,19.556a1,1,0,0,1-1-1V14.111a1,1,0,0,1,2,0v4.444A1,1,0,0,1,16.333,19.556Zm-6.667,0a1,1,0,0,1-1-1V14.111a1,1,0,0,1,2,0v4.444A1,1,0,0,1,9.667,19.556Z'
        data-name='primary'
        id='primary-2'
      />
    </g>
  </svg>
);
