import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  step: {
    minWidth: '52px',
    minHeight: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: `0 2px 3px ${theme.palette.grey[400]}`,
    borderRadius: '50%',
    marginRight: theme.spacing(3),
  },
}));

export default useStyles;
