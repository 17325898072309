import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  grid: {
    padding: theme.spacing(2, 0),
    display: 'grid',
    gridGap: theme.spacing(3, 5),
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  rowEnd: {
    gridColumn: -2,
    gridColumnEnd: '6',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  topPanel: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'center',
  },
  collapse: {
    transitionDelay: '.1s',
  },
}));

export default useStyles;
