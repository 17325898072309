import type { FC } from 'react';

import { ReactComponent as ScreenIcon } from 'assets/icons/hub/screen.svg';
import type { IconAlertProps } from 'constants/_types/IconAlertProps';

import useStyles from './IconInfoAlert.styles';

const IconInfoAlert: FC<IconAlertProps> = ({ isBoxShadow = false }) => {
  const { classes } = useStyles({ isBoxShadow });

  return (
    <div className={classes.root}>
      <ScreenIcon />
    </div>
  );
};

export default IconInfoAlert;
