import { FC, memo, useMemo } from 'react';

import { List, ListItem } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import eCareDevicesType from 'api/eCareDevicesType/eCareDevicesType';
import { ECareDeviceType } from 'api/eCareDevicesType/eCareDevicesType.types';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useTableData from 'hooks/useTableData/useTableData';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from './PatientDevicesTypeTable.styles';

export type Props = {
  orderId: number | string;
};

const PatientDevicesTypeTable: FC<Props> = ({ orderId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const config = { pageSize: 25, initialFilters: [{ key: 'order', value: orderId }] };

  const columns = useMemo(() => {
    const helper = createColumnHelper<ECareDeviceType>();

    return [
      helper.accessor('name', {
        id: 'name',
        header: t(proECareOrderMessages.deviceTableHeaders.name) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('code', {
        id: 'code',
        header: t(proECareOrderMessages.deviceTableHeaders.code) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('dateFrom', {
        id: 'date_from',
        header: t(proECareOrderMessages.deviceTableHeaders.dateFrom) as string,
        cell: info => format(info.getValue(), DATE_FORMATS.DISPLAY),
      }),
      helper.accessor('dateTo', {
        id: 'date_to',
        header: t(proECareOrderMessages.deviceTableHeaders.dateTo) as string,
        cell: info => {
          const dateTo = info?.getValue();
          if (!dateTo) return t(generalMessages.noData);
          return format(dateTo, DATE_FORMATS.DISPLAY);
        },
      }),
      helper.accessor('description', {
        id: 'description',
        enableSorting: false,
        header: t(proECareOrderMessages.deviceTableHeaders.description) as string,
        cell: memo((info: any) => {
          const data = info.getValue();
          if (!data) return t(generalMessages.noData);

          const { id, key, defaultValue } = data;

          return (
            <List>
              <ListItem className={classes.listItem}>
                <b>id:</b> {id}
              </ListItem>
              <ListItem className={classes.listItem}>
                <b>key:</b> {key}
              </ListItem>
              <ListItem className={classes.listItem}>
                <b>defaultValue:</b> {defaultValue}
              </ListItem>
            </List>
          );
        }),
      }),
    ];
  }, [t]);

  const { query, sort, onSort, page, onExactPage, pagesCount, isFetchingNewPage } = useTableData<keyof ECareDeviceType>({
    queryFunction: eCareDevicesType.getAllDevices(),
    queryKeyBase: QUERY_KEYS.GET_ECARE_DEVICES,
    config,
  });

  return (
    <>
      {/* Generic Component throws error, but idk why, it should work */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CustomTable<ECareDeviceType>
        columns={columns}
        config={config}
        data={query.data?.data}
        page={page}
        pagesCount={pagesCount}
        showLoaderCover={isFetchingNewPage}
        sort={sort}
        onExactPage={onExactPage}
        onSort={onSort}
      />
    </>
  );
};

export default PatientDevicesTypeTable;
