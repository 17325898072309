import { useState } from 'react';

import type { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';

import serviceRequests from 'api/serviceRequests/serviceRequests';
import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';

export const SERVICE_REQUESTS_MUTATION_KEY = 'Service requests';

type UseServiceRequestsMutationParams = {
  paramOrigin: OrderServiceOrigins;
  onSuccess: () => void;
  onSettled: () => void;
  setError: UseFormSetError<OrderServiceFormInput>;
};

const useServiceRequestsMutation = ({ paramOrigin, onSuccess, onSettled, setError }: UseServiceRequestsMutationParams) => {
  const { patient } = usePatientContext();
  const [generalErrors, setGeneralErrors] = useState<string[]>();

  const onError = useOnBackendFormError<OrderServiceFormInput, any>({
    setError,
    setGeneralErrors,
  });

  const { mutateAsync, isLoading } = useMutation(
    SERVICE_REQUESTS_MUTATION_KEY,
    serviceRequests.submitServiceRequestsForm(patient?.id, paramOrigin),
    {
      retry: false,
      onSuccess,
      onError,
      onSettled,
    },
  );

  return {
    serviceRequestsMutateAsync: mutateAsync,
    serviceRequestsMutateGeneralErrors: generalErrors,
    areServiceRequestsMutating: isLoading,
  };
};

export default useServiceRequestsMutation;
