import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  removeButton: {
    opacity: 0,
    pointerEvents: 'none',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  profileWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    '&:hover': {
      '& button': {
        opacity: 1,
        pointerEvents: 'auto',
      },
    },
  },
  addButton: {
    width: '100%',
    display: 'block',
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
  },
}));

export default useStyles;
