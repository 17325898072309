import { userPermissionsPossibleValues, UserPermission } from 'constants/_types/UserPermission';

import type { UserPermissions, UserPermissionsDTO } from './userPermissions.types';

export const parseUserPermissionsForFE = (userPermissionsDTO: UserPermissionsDTO): UserPermissions => {
  const flatUserPermissionsDTO = userPermissionsDTO.map(({ codename }) => codename);
  return userPermissionsPossibleValues.reduce<Record<UserPermission, boolean>>((accumulator, current) => {
    accumulator[current] = flatUserPermissionsDTO.includes(current);
    return accumulator;
  }, {} as Record<UserPermission, boolean>);
};
