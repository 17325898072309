import type { AddressUnassigned } from 'api/_types';

import type { PatientFormInput } from '../../_constants/patientFormKeys';

export const prepareAddressDataForUpdate = (rawData: PatientFormInput): AddressUnassigned => ({
  street_name: rawData.street,
  building_number: rawData.buildingNumber,
  apartment_number: rawData.apartmentNumber,
  postcode: rawData.postcode,
  city: rawData.city,
  country: rawData.country,
});
