import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ maxWidth: number }>()((theme, { maxWidth }) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth,
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
