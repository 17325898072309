import { useMemo, type FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import type { OrderServiceType } from 'constants/_types/OrderServiceType';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import useStyles from './PageTitleStepperContent.styles';

export interface Props {
  formMaxStep: number;
  formStep: number;
  paramOrigin: OrderServiceOrigins;
}

const PageTitleStepperContent: FC<Props> = ({ formMaxStep, formStep, paramOrigin }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const translationKey = useMemo(() => {
    const orderServiceType = orderServiceMessages[paramOrigin] as OrderServiceType;

    return orderServiceType.formSteps[`${formStep}`].title;
  }, [formStep]);

  return (
    <div className={classes.root}>
      <div className={classes.step}>
        <Typography variant='h4'>{`${formStep}/${formMaxStep}`}</Typography>
      </div>
      <Typography variant='h4'>{t(translationKey)}</Typography>
    </div>
  );
};

export default PageTitleStepperContent;
