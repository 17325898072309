import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { PartialRecord } from '../../constants/_types/PartialRecord';
import { TableDataBaseParamsForQuery } from '../../constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from '../../constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from '../../constants/_types/TableDataQueryResult';
import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import { parseSingleECareAlarmForFE, parseECareAlarmsListForFE } from './eCareAlarms.parsers';
import { ECareAlarm, ECareAlarmDTO, ECareAlarmListFilters, ECareAlertUnassigned } from './eCareAlarms.types';

const endpoint = '/api/v1/ecare-sos/';

export type UpdateECareAlertInput = ECareAlertUnassigned;

type GetECareAlarmsQuery = (params: TableDataQueryFunctionParams<keyof ECareAlarm>) => Promise<TableDataQueryResult<keyof ECareAlarm>>;
type GetECareAlarmQuery = () => Promise<ECareAlarm>;
type UpdateECareAlarmQuery = (data: UpdateECareAlertInput) => Promise<DefaultApiResponse<ECareAlarm>>;

const eCareAlarms = {
  getAllAlarms: (): GetECareAlarmsQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery & PartialRecord<ECareAlarmListFilters, string> = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      exclude_event: 'DEVICE_BATTERY_LOW',
      ...parseFiltersForBE<ECareAlarmListFilters>(params.filters),
    };

    const { data } = await axios.get<GetECareAlarmsQuery, PaginatedApiResponse<ECareAlarmDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: paramsForBE,
        removeEmptyParams: true,
      }),
    );
    return { data: parseECareAlarmsListForFE(data.results), count: data.count };
  },
  getECareAlarm:
    (id: string): GetECareAlarmQuery =>
    async () => {
      const { data } = await axios.get<GetECareAlarmQuery, DefaultApiResponse<ECareAlarmDTO>>(`${endpoint}${id}/`);

      return parseSingleECareAlarmForFE(data);
    },
  patchECareAlarm:
    (id: string): UpdateECareAlarmQuery =>
    data =>
      axios.patch<UpdateECareAlarmQuery, DefaultApiResponse<ECareAlarm>>(`${endpoint}${id}/`, data),
  setStatusECareAlarm:
    (id: string): UpdateECareAlarmQuery =>
    data =>
      axios.post<UpdateECareAlarmQuery, DefaultApiResponse<ECareAlarm>>(`${endpoint}${id}/set-status/`, data),
};
export default eCareAlarms;
