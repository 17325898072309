import type { FC, FunctionComponent, ReactNode } from 'react';
import { useMemo } from 'react';

import { Button, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import TwoStateIcon from 'components/UI/atoms/TwoStateIcon/TwoStateIcon';
import { useIsMobile } from 'hooks/_breakpoints/useIsMobile/useIsMobile';
import useLayoutStorage from 'storages/layoutStorage';

import { getIconSize } from './_services/getIconSize/getIconSize';
import { getIsActive } from './_services/getIsActive/getIsActive';
import useStyles from './MenuButton.styles';

export type Props = {
  icon: FunctionComponent;
  children: ReactNode;
  to: string;
  isActiveFor: string[];
  onClick?: () => void;
  testId?: string;
  disabled?: boolean;
  hidden?: boolean;
};

const MenuButton: FC<Props> = ({ icon, children, to, isActiveFor, onClick, testId, disabled, hidden }) => {
  const { isOpen } = useLayoutStorage();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();

  const isActive = useMemo(() => getIsActive(pathname, to, isActiveFor), [pathname]);

  const { classes, cx } = useStyles({ isActive, isOpen });
  if (hidden) return null;
  return (
    <NavLink className={cx(classes.root, disabled && classes.linkDisabled)} data-testid={testId} to={to} onClick={onClick}>
      <Button className={cx(classes.iconContainer, 'MenuButton_icon-container')} disableRipple={isMobile}>
        <TwoStateIcon className={classes.icon} customSize={getIconSize(isMobile, isOpen)} icon={icon} isActive={isActive} />
      </Button>
      <Typography align='center' className={cx(classes.label, 'MenuButton_label')} component='p' variant='caption'>
        {children}
      </Typography>
      <div className={cx(classes.activeBorder, 'activeBorder')} />
    </NavLink>
  );
};

export default MenuButton;
