import type { FC } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreService from 'api/coreService/coreService';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import { DynamicTranslatableName } from 'constants/_types/DynamicTranslatableName';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generateQueryKeyFromObject from 'services/generateQueryKeyFromObject/generateQueryKeyFromObject';

import useStyles from './ServicesSubtypeAccordion.styles';

export type Props = {
  isExpanded: boolean;
  onExpand: () => void;
  title: DynamicTranslatableName;
  subtypeId: number;
  patientId: number;
  payerId: number;
  policyId: number;
};

const ServicesSubtypeAccordion: FC<Props> = ({ isExpanded, onExpand, title, subtypeId, payerId, patientId, policyId }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    generateQueryKeyFromObject({ base: QUERY_KEYS.GET_SERVICES_BY_POLICY_ID, patientId, payerId, subtypeId }),

    coreService.getServicesFromSubtype({ limit: 100, patientId, payers: [payerId], types: [subtypeId.toString()], pro: true, policyId }),
    {
      enabled: isExpanded,
    },
  );

  const { classes } = useStyles();

  return (
    <Accordion expanded={isExpanded} onChange={onExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='subtitle2'>{t(title.key, title.defaultValue ? title.defaultValue : title.key)}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {isLoading && <LoaderOverlay inner minHeight='20px' />}
        {data &&
          data.map(({ name }) => (
            <Typography key={name.key} variant='captionBold'>
              {t(name.key, name.defaultValue ? name.defaultValue : name.key)}
            </Typography>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ServicesSubtypeAccordion;
