import i18n from 'config/i18n';

import type { ContactFormCategory, ContactFormCategoryDto } from '../types';

export const parseContactFormCategoriesForFE = (data: ContactFormCategoryDto[]): ContactFormCategory[] =>
  data
    ? data.map(category => ({
        value: category.id,
        label: category[`name_${i18n.language}` as keyof Omit<ContactFormCategoryDto, 'id'>],
      }))
    : [];
