import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material';
import { Control, Controller, type ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import { DropdownOption } from 'constants/_types/DropdownOptions';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import useDebounce from 'hooks/useDebounce/useDebounce';
import generalMessages from 'translations/common/general.mjs';
import validationMessages from 'translations/common/validation.mjs';

import useStyles from './FormInputAsyncAutocomplete.styles';

export type Props = {
  label: string;
  name: string;
  control: Control<any>;
  optionsGetter: (searchPhrase: string) => Promise<DropdownOption[]>;
  allowEmptySearch?: boolean;
  hintText?: string;

  delay?: number;
  required?: boolean;
  rules?: ValidationRule<any>;
};

const FormInputAsyncAutocomplete: FC<Props> = ({
  label,
  name,
  control,
  rules,
  required,
  hintText,
  allowEmptySearch,
  optionsGetter,
  delay = 150,
}) => {
  const { t, i18n } = useTranslation();

  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [isLoading, setLoading, setLoaded] = useBooleanState(false);

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_field) }, ...rules }),
    [rules, required, i18n.language],
  );

  const debouncedValue: string = useDebounce(searchPhrase, delay);

  const onTextInputChange = (_: SyntheticEvent<Element, Event>, value: string) => {
    setSearchPhrase(value);
  };

  const updateOptions = async (getterSearchPhrase: string) => {
    setOptions([]);
    setLoading();
    const newOptions = await optionsGetter(getterSearchPhrase);
    setLoaded();
    setOptions(newOptions);
  };

  useEffect(() => {
    if (debouncedValue || allowEmptySearch) {
      updateOptions(debouncedValue);
    } else {
      setOptions([]);
    }
  }, [debouncedValue]);

  const noOptionsText = useMemo(() => {
    if (!searchPhrase) return hintText || t(generalMessages.searchHintText);
    if (isLoading) return <CircularProgress size={20} />;

    return t(generalMessages.noResults);
  }, [searchPhrase, isLoading, t]);

  const { classes } = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message;

        const onChange = (_: SyntheticEvent<Element, Event>, newValue: DropdownOption) => {
          field.onChange(newValue);
        };

        return (
          <FormControl classes={{ root: classes.control }} error={!!errorMessage} fullWidth variant='outlined'>
            <Autocomplete
              getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
              inputValue={searchPhrase}
              noOptionsText={noOptionsText}
              options={options}
              renderInput={params => <TextField {...params} error={!!errorMessage} label={label} />}
              value={field.value || ''}
              onChange={onChange}
              onInputChange={onTextInputChange}
            />
            <InputFooter error={errorMessage} />
          </FormControl>
        );
      }}
      rules={rulesProp}
    />
  );
};

export default FormInputAsyncAutocomplete;
