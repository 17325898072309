import type { FC } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { MenuEntity } from 'hooks/useMenuContent/useMenuContent';
import { useMenuState } from 'hooks/useMenuState/useMenuState';
import generalMessages from 'translations/common/general.mjs';

import MoreButtonList from '../MoreButtonList/MoreButtonList';
import useStyles from './MoreButton.styles';

export interface Props {
  listItems: MenuEntity[];
}

const MoreButton: FC<Props> = ({ listItems }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isMenuOpened, anchorElement, handleMenuClick, handleMenuClose } = useMenuState();

  return (
    <>
      <IconButton aria-label='more' className={classes.moreButton} size='large' onClick={handleMenuClick}>
        <div>
          <MoreHorizIcon />
        </div>
        <Typography align='center' component='p' variant='caption'>
          {t(generalMessages.more)}
        </Typography>
      </IconButton>
      <MoreButtonList anchorElement={anchorElement} handleMenuClose={handleMenuClose} isMenuOpened={isMenuOpened} listItems={listItems} />
    </>
  );
};

export default MoreButton;
