import { SPECIFIC_POSTAL_CODE_PATTERNS } from 'constants/specificPostalCodePatterns/specificPostalCodePatterns';
import { getCountryCodeByCountryOptionValue } from 'services/getCountryCodeByCountryOptionValue/getCountryCodeByCountryOptionValue';

interface Params {
  selectedCountryOptionValue: string;
}

export const resolvePostalCodePattern = ({ selectedCountryOptionValue }: Params) => {
  const selectedCountryCode = getCountryCodeByCountryOptionValue(selectedCountryOptionValue);
  const specificPostalCodePattern = SPECIFIC_POSTAL_CODE_PATTERNS.find(({ countryCode }) => countryCode === selectedCountryCode);

  return (
    specificPostalCodePattern && { maskPattern: specificPostalCodePattern.maskPattern, rulePattern: specificPostalCodePattern.rulePattern }
  );
};
