import { makeStyles } from 'tss-react/mui';

import { FORM_BAR_BUTTONS } from 'constants/styles/_types/FormBarButtons';

const useStyles = makeStyles()(theme => ({
  nextButton: {
    gridArea: FORM_BAR_BUTTONS.nextButtonArea,
  },
}));

export default useStyles;
