import { Button, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ECareEntity, ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from './useGenerateHealthDetails.styles';

const useGenerateHealthDetails = (data: ECareOrder) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const generateListItem = (item: ECareEntity) => (
    <ListItem className={classes.noPaddingX} key={item.code}>
      {item.code} - {item.name}
    </ListItem>
  );
  const dwellingType = (item: ECareEntity) => <List>{generateListItem(item)}</List>;

  const healthProblem = [
    {
      label: t(proECareAlarmMessages.alarmDetails.typeOfDisease),
      value: data?.healthConditions.map(item => item.name),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.healthProblems),
      value: data?.healthProblems.map(item => item.name),
    },
    {
      label: t(proECareAlarmMessages.alarmDetails.dysfunctions),
      value: data?.dysfunctions.map(item => item.name),
    },
  ];

  const showAllHealthIssues = () => {
    showConfirmationDialog({
      title: t(proECareAlarmMessages.alarmDetails.allHealthProblems),
      body: <DefinitionList columns={3} definitions={healthProblem} fontSize='small' />,
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  return {
    section1: [
      {
        label: t(proECareOrderMessages.healthDetails.dwellingType),
        value: data?.dwellingType ? dwellingType(data.dwellingType) : t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.healthDetails.otherDwellingType),
        value: data?.otherDwellingType || t(generalMessages.noData),
      },
    ],
    section2: [
      {
        label: t(proECareOrderMessages.healthDetails.cognitiveDisabilityDegree),
        value: data?.cognitiveDisabilityDegree ? data.cognitiveDisabilityDegree : t(generalMessages.noData),
      },
      {
        label: t(proECareOrderMessages.healthDetails.mobilityDisabilityDegree),
        value: data?.mobilityDisabilityDegree ? data.mobilityDisabilityDegree : t(generalMessages.noData),
      },
    ],
    section3: [
      {
        label: t(proECareOrderMessages.healthDetails.healthProblems),
        value: data ? `${data.healthProblems.length} ${t(proECareOrderMessages.healthDetails.healthProblems)}` : t(generalMessages.missing),
      },
      {
        label: t(proECareOrderMessages.healthDetails.dysfunctions),
        value: data ? `${data.dysfunctions.length} ${t(proECareOrderMessages.healthDetails.dysfunctions)}` : t(generalMessages.missing),
      },
      {
        label: t(proECareOrderMessages.healthDetails.typeOfDisease),
        value: data
          ? `${data.healthConditions.length} ${t(proECareOrderMessages.healthDetails.diseaseCounter)}`
          : t(generalMessages.missing),
      },

      {
        label: '',
        value: (
          <Button size='small' variant='outlined' onClick={showAllHealthIssues}>
            {t(proECareAlarmMessages.alarmCard.buttons.showAllIllnesses)}
          </Button>
        ),
      },
    ],
  };
};

export default useGenerateHealthDetails;
