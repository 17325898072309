import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import LinkWithQuery from 'components/UI/atoms/LinkWithQuery/LinkWithQuery';
import AuthTileFooter from 'components/UI/organisms/AuthTileFooter/AuthTileFooter';
import ResetPasswordRequestForm from 'components/UI/organisms/ResetPasswordRequestForm/ResetPasswordRequestForm';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import resetPasswordMessages from 'translations/specific/resetPassword.mjs';

const ResetPasswordRequestPage: FC = () => {
  usePageConfig();

  const { t } = useTranslation();
  return (
    <>
      <ResetPasswordRequestForm />
      <AuthTileFooter>
        <LinkWithQuery to={`/${PATHS.AUTH_LOGIN}`}>{t(resetPasswordMessages.footer.login_link)}</LinkWithQuery>
      </AuthTileFooter>
    </>
  );
};

export default ResetPasswordRequestPage;
