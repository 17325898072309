import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ITableDesktopCardItemHeader } from '../../_types/ITableDesktopCardItemHeader';
import useStyles from './TableDesktopCardItemHeader.styles';

export type Props = Omit<ITableDesktopCardItemHeader, 'id'>;

const TableDesktopCardItemHeader: FC<Props> = ({ icon, translationArray }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <th className={classes.header}>
      {icon}{' '}
      <Typography className={classes.label} variant='caption'>
        {t(translationArray)}
      </Typography>
    </th>
  );
};

export default TableDesktopCardItemHeader;
