import type { FC } from 'react';

import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import type { FormActionButtonProps } from 'storages/bottomFixedContainerStorage';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './SubmitButton.styles';

export type Props = FormActionButtonProps;

const SubmitButton: FC<Props> = ({ action, disabled, label }) => {
  const { t } = useTranslation();
  const { globalFormLoading } = useBottomFixedContainerStorage();
  const { classes } = useStyles();

  return (
    <LoadingButton className={classes.nextButton} disabled={disabled} loading={globalFormLoading} variant='contained' onClick={action}>
      {label || t(generalMessages.save)}
    </LoadingButton>
  );
};

export default SubmitButton;
