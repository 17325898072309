import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    background: theme.palette.background.default,
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  dialogTitleText: {
    marginBottom: 0,
    fontSize: '1.3rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  dialogContent: {
    scrollBehavior: 'smooth',

    marginTop: theme.spacing(2.5),
    display: 'block',
    alignItems: 'center',
  },
  filedTitle: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    marginTop: theme.spacing(3),
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
