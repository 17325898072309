import type { FC } from 'react';

import { Tooltip, TooltipProps, Typography } from '@mui/material';

import useStyles from './TruncatedTextWithTooltip.styles';

export interface Props {
  linesBeforeTruncate?: number;
  text: string;
  tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
}

const TruncatedTextWithTooltip: FC<Props> = ({ linesBeforeTruncate = 1, text, tooltipProps = {} }) => {
  const { classes } = useStyles({ linesBeforeTruncate });

  return (
    <Tooltip placement='bottom' title={<Typography variant='subtitle3'>{text}</Typography>} {...tooltipProps}>
      <span className={classes.text}>{text}</span>
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
