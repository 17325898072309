export type ClauseLocations = 'register' | 'personalData' | 'services' | 'userSettings' | 'forcedDialog';
export type ClauseLocationsDTO = 'register' | 'personal_data' | 'services' | 'user_settings' | 'forced_dialog';

export const CLAUSE_LOCATIONS: { [key in ClauseLocations]: ClauseLocationsDTO } = {
  register: 'register',
  personalData: 'personal_data',
  services: 'services',
  userSettings: 'user_settings',
  forcedDialog: 'forced_dialog',
};
