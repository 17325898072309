import type { CheckFileTypeCoreParams } from '../_types/params';

type Parameters = CheckFileTypeCoreParams & {
  file: File;
};

export const checkFileType = ({ file, acceptedType }: Parameters) => {
  if (file.type.match(acceptedType)) return true;

  return false;
};
