import { parsePatientForFE } from '../patient/patient.parsers';
import type { EcarePhoneCall, EcarePhoneCallDTO } from './eCarePhoneCall.types';

export const parseEcarePhoneCallForFE = (ecarePhoneCallDTO: EcarePhoneCallDTO): EcarePhoneCall => ({
  id: ecarePhoneCallDTO.id,
  internalId: ecarePhoneCallDTO.internal_id,
  createdAt: new Date(ecarePhoneCallDTO.created_at),
  updatedAt: new Date(ecarePhoneCallDTO.updated_at),
  callDate: new Date(ecarePhoneCallDTO.call_date),
  callReceivedDate: new Date(ecarePhoneCallDTO.call_received_date),
  callEndDate: new Date(ecarePhoneCallDTO.call_end_date),
  serviceRecipientPhoneNumber: ecarePhoneCallDTO.service_recipient_phone_number,
  caller: ecarePhoneCallDTO.caller,
  internalNotes: ecarePhoneCallDTO.internal_notes,
  status: ecarePhoneCallDTO.status,
  callType: ecarePhoneCallDTO.call_type,
  callSummary: ecarePhoneCallDTO.call_summary,
  order: ecarePhoneCallDTO.order,
  serviceRecipient: ecarePhoneCallDTO.service_recipient,
  patient: ecarePhoneCallDTO.patient ? parsePatientForFE(ecarePhoneCallDTO.patient) : null,
  payer: ecarePhoneCallDTO.payer,
  subscription: ecarePhoneCallDTO.subscription,
  operator: ecarePhoneCallDTO.operator,
  lastSync: ecarePhoneCallDTO.last_sync ? new Date(ecarePhoneCallDTO.last_sync) : null,
});
