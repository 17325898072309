import { useEffect, useMemo } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import pesel from 'helpers/pesel/pesel';

import type { PatientFormInput } from '../../_constants/patientFormKeys';
import { PATIENT_FORM_INPUT_KEYS } from '../../_constants/patientFormKeys';
import type { FormDynamicData } from '../usePatientInfoForm/usePatientInfoForm';

type UseFormDynamicData = (form: UseFormReturn<PatientFormInput, any>) => { formDynamicData: FormDynamicData };

export const useFormDynamicData: UseFormDynamicData = form => {
  const hasForeignDocument = form.watch(PATIENT_FORM_INPUT_KEYS.hasForeignDocument as 'hasForeignDocument');
  const peselFieldValue = form.watch(PATIENT_FORM_INPUT_KEYS.pesel as 'pesel');
  const genderFieldValue = form.watch(PATIENT_FORM_INPUT_KEYS.gender as 'gender');

  useEffect(() => {
    if (!hasForeignDocument) form.clearErrors([PATIENT_FORM_INPUT_KEYS.gender, PATIENT_FORM_INPUT_KEYS.dateOfBirth]);

    if (peselFieldValue && pesel.validate(peselFieldValue, true).isValid) {
      const { gender, dob } = pesel.getData(peselFieldValue);
      form.setValue(PATIENT_FORM_INPUT_KEYS.gender, gender);
      form.setValue(PATIENT_FORM_INPUT_KEYS.dateOfBirth, dob);
    } else {
      form.setValue(PATIENT_FORM_INPUT_KEYS.gender, genderFieldValue || '');
    }
  }, [peselFieldValue, hasForeignDocument]);

  const formDynamicData = useMemo(
    () => ({
      hasForeignDocument,
    }),
    [hasForeignDocument, form],
  );

  return { formDynamicData };
};
