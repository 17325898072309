import { useCallback, useEffect, useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import type { CellRendererType } from 'components/UI/molecules/ColumnsDisplaySet/_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';
import ColumnsDisplaySet from 'components/UI/molecules/ColumnsDisplaySet/ColumnsDisplaySet';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import ServicesTopBar from 'components/UI/organisms/ServicesTopBar/ServicesTopBar';
import useSelectLogic from 'hooks/_userServices/useSelectLogic/useSelectLogic';
import servicesMessages from 'translations/specific/services.mjs';

import SelectableSearchableCell from '../../atoms/SelectableSearchableCell/SelectableSearchableCell';
import SearchBar from '../../molecules/SearchBar/SearchBar';
import useSearch from '../ServicesTypeView/_hooks/useSearch/useSearch';
import useGetServicesForProfile from './_hooks/useGetServicesForProfile/useGetServicesForProfile';

const ServicesUserProfileView: FC = () => {
  const { t } = useTranslation();

  const { profileServices, profileBaseInformation, profileServicesLoading } = useGetServicesForProfile();

  const { toggleSelected, checkIsSelected, selected, handleAddToBasket, batchSelect } = useSelectLogic();

  const { isSearchActive, checkIsMatched, onSearch } = useSearch();
  const dataToSearchIn = useMemo(
    () => (profileServices ? Object.values(profileServices.entities).flat() : undefined),
    [profileServices?.entities],
  );
  const searchHandler = useCallback(
    (phrase: string) => {
      if (!dataToSearchIn) return;
      onSearch(phrase, dataToSearchIn);
    },
    [dataToSearchIn],
  );

  useEffect(() => {
    // select all on start
    if (profileServices) {
      const allServicesIds = Object.values(profileServices.entities).flatMap(type => type.map(({ id }) => id));
      batchSelect(allServicesIds);
    }
  }, [profileServices]);

  const onAddToBasket = useCallback(() => {
    handleAddToBasket(profileServices?.entities);
  }, [profileServices, selected]);

  const cellRenderer: CellRendererType = useCallback(
    cellProps => (
      <SelectableSearchableCell
        checkIsMatched={checkIsMatched}
        checkIsSelected={checkIsSelected}
        isSearchActive={isSearchActive}
        toggleSelected={toggleSelected}
        {...cellProps}
      />
    ),
    [selected, toggleSelected, isSearchActive, checkIsMatched, checkIsSelected],
  );

  return (
    <>
      <SearchBar onSearch={searchHandler} />
      <ServicesTopBar disabledActionButton={!selected.length} title={profileBaseInformation?.name} onActionButtonClick={onAddToBasket} />
      <ElementWithLoader isLoading={profileServicesLoading}>
        <ColumnsDisplaySet
          cellRenderer={cellRenderer}
          cellSize={150}
          content={profileServices}
          fallbackMessage={t(servicesMessages.noResultsForPaymentType)}
          showGrid
        />
      </ElementWithLoader>
    </>
  );
};

export default ServicesUserProfileView;
