import type { FC } from 'react';
import { useMemo } from 'react';

import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputCheckbox from 'components/UI/molecules/InputCheckbox/InputCheckbox';
import validationMessages from 'translations/common/validation.mjs';

type Props = {
  label: string | JSX.Element;
  name: string;
  tiny?: boolean;
  control: Control<any>;
  title?: string;
  required?: boolean;
  defaultValue?: boolean;
  rules?: ValidationRule<any>;
  inputProps?: {
    fullWidth?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    minDate?: Date;
    inlineError?: boolean;
  };
  hasRadioIcon?: boolean;
  hideAsterisk?: boolean;
};

const FormInputCheckbox: FC<Props> = ({
  tiny,
  control,
  label,
  title,
  name,
  defaultValue = false,
  inputProps = {},
  rules = {},
  required = false,
  hasRadioIcon = false,
  hideAsterisk = false,
}) => {
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_consent) }, ...rules }),
    [rules, required, i18n.language],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <InputCheckbox
          error={fieldState.error?.message}
          hasRadioIcon={hasRadioIcon}
          hideAsterisk={hideAsterisk}
          label={label}
          required={required}
          tiny={tiny}
          title={title}
          {...inputProps}
          {...field}
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputCheckbox;
