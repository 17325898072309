const validationMessages = {
  required_field: [ 'validation>>required_field', 'Pole wymagane' ],
  general_form_error_title: [ 'validation>>general_form_error_title', 'Przed zapisaniem problemu musisz dokonać zmian w panelu admina:' ],
  required_consent: [ 'validation>>required_consent', 'Zgoda wymagana' ],
  required_file: [ 'validation>>required_file', 'Plik jest wymagany' ],
  invalid_email: [ 'validation>>invalid_email', 'Adres Email niepoprawny' ],
  invalid_postcode: [ 'validation>>invalid_postcode', 'Kod pocztowy niepoprawny' ],
  invalid_dateformat: [ 'validation>>invalid_dateformat', 'Niepoprawny format daty' ],
  invalid_date_past: [ 'validation>>invalid_date_past', 'Data nie może być w przeszłości' ],
  invalid_date_future: [ 'validation>>invalid_date_future', 'Data nie może być w przyszłości' ],
  invalid_date_before_than: [ 'validation>>invalid_date_before_than', 'Data nie może być wcześniej niż data {{referenceDate}}' ],
  invalid_date_after_than: [ 'validation>>invalid_date_after_than', 'Data nie może być później niż data {{referenceDate}}' ],
  password: {
    min_length: [ 'validation>>password>>min_length', 'Hasło musi się składać z co najmniej {{minLength}} znaków' ],
    lowercase: [ 'validation>>password>>lowercase', 'Hasło musi zawierać co najmniej jedną małą literę' ],
    uppercase: [ 'validation>>password>>uppercase', 'Hasło musi zawierać co najmniej jedną wielką literę' ],
    number: [ 'validation>>password>>number', 'Hasło musi zawierać co najmniej jedną cyfrę' ],
    special_char: [ 'validation>>password>>special_char', 'Hasło musi zawierać co najmniej jeden znak specjalny' ],
    match: [ 'validation>>password>>dont_match', 'Podane hasła muszą do siebie pasować' ],
  },
  pesel: {
    too_short: [ 'validation>>pesel>>too_short', 'Pesel jest za krótki' ],
    too_long: [ 'validation>>pesel>>too_long', 'Pesel jest za długi' ],
    invalid: [ 'validation>>pesel>>invalid', 'Pesel jest niepoprawny' ],
    past: [ 'validation>>pesel>>past', 'Data wynikająca z numeru Pesel nie może byc z przyszłości' ],
  },
  phoneNumber: {
    invalid: [ 'validation>>phoneNumber>>invalid', 'Niepoprawny numer telefonu' ],
  },
  text: {
    tooLong: ['validation>>text>>tooLong', 'Tekst powinien mieć mniej niż {{maxLength}} znaków'],
  },
  verificationCode: {
    too_short: [ 'validation>>verificationCode>>too_short', 'Kod weryfikacyjny jest za krótki' ],
    too_long: [ 'validation>>verificationCode>>too_long', 'Kod weryfikacyjny jest za długi' ],
  },
  fileToBig: [ 'validation>>fileToBig', 'Plik {{filename}} jest za duży, maksymalny rozmiar pliku to {{maxSize}} MB' ],
  invalidFile: [ 'validation>>invalidFile', 'Typ pliku {{filename}} nie jest akceptowany' ],
}

export default validationMessages
