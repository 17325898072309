import { FC } from 'react';

import { Box, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QueryKey, useMutation, useQueryClient } from 'react-query';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import { alarmStatuses, AlarmStatusT, ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import { alarmStatusesDictionary } from 'components/UI/organisms/AlarmsList/_dictionaries/alarmStatusesDictionary';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';

import useStyles from './ECareAlarmStatusDropdown.styles';

export type Props = {
  alarmId: string;
  queryKey: QueryKey;
  value?: string;
};

const ECareAlarmStatusDropdown: FC<Props> = ({ alarmId, queryKey, value }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { palette } = useTheme();

  const queryClient = useQueryClient();

  const statusMutation = useMutation(eCareAlarms.setStatusECareAlarm(alarmId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      const currentData = queryClient.getQueryData(queryKey) as ECareAlarm;
      queryClient.setQueryData(queryKey, { ...currentData, sosStatus: newStatus.sos_status });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const colorResolver = (status: AlarmStatusT) => {
    switch (status) {
      case 'RECEIVED':
        return palette.table.warning.main;
      case 'CLOSED':
        return palette.table.closed.main;
      case 'MISSED':
        return palette.table.danger.main;
      case 'COMPLETE_DOCUMENTATION':
        return palette.table.success.main;
      default:
        return exhaustiveGuard(status);
    }
  };

  const onChange = ({ target }: SelectChangeEvent<{ status: AlarmStatusT } | string>) => {
    statusMutation.mutate({ sos_status: target.value as AlarmStatusT });
  };

  const renderValue = (selectedValue: string | { status: string }) => {
    const stringSelectedValue = typeof selectedValue === 'string' ? selectedValue : selectedValue.status;
    const selectedValueTyped = stringSelectedValue as AlarmStatusT;
    return (
      <Box
        className={classes.selectedMenuItem}
        sx={{
          color: colorResolver(selectedValueTyped),
          '&::before': {
            borderColor: colorResolver(selectedValueTyped),
          },
        }}
      >
        {t(alarmStatusesDictionary[selectedValueTyped])}
      </Box>
    );
  };

  return (
    <Select className={classes.select} disabled={value === 'CLOSED'} renderValue={renderValue} value={value || ''} onChange={onChange}>
      {alarmStatuses.map(status => (
        <MenuItem
          className={classes.menuItem}
          disabled={['CLOSED', 'MISSED'].includes(status)}
          key={status}
          sx={{
            color: colorResolver(status),
          }}
          value={status}
        >
          {t(alarmStatusesDictionary[status])}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ECareAlarmStatusDropdown;
