import { FC } from 'react';

import { Paper, Chip, Button } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';

import type { AvailableFilter } from 'constants/_types/AvailableFilter';
import type { TableDataFilter } from 'constants/_types/TableDataFilter';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './SelectedFilters.styles';

type Props = {
  filters?: TableDataFilter[] | null;
  availableFilters: AvailableFilter[];
  onDelete: (key: string) => void;
};

const resolveLabel = (t: TFunction, availableFilters: AvailableFilter[], key: string): string => {
  const translationKey = availableFilters.find(filter => filter.key === key)?.label;
  return translationKey ? t(translationKey) : key;
};

const SelectedFilters: FC<Props> = ({ filters, availableFilters, onDelete }) => {
  const isFilters = () => (filters && filters.length > 0) || false;

  const { t } = useTranslation();
  const { classes } = useStyles({ isFilters: isFilters() });

  const clearAll = () => filters?.map(({ value, key }) => onDelete(key));

  return (
    <Paper className={classes.filterChips} elevation={2}>
      <div>
        {filters?.map(({ value, key }) => (
          <Chip
            className={classes.chip}
            color='primary'
            key={`${value}-${key}`}
            label={`${resolveLabel(t, availableFilters, key)}: ${value}`}
            onDelete={() => onDelete(key)}
          />
        ))}
      </div>
      {isFilters() && (
        <Button className={classes.clearBtn} size='small' variant='outlined' onClick={clearAll}>
          {t(generalMessages.clearAll)}
        </Button>
      )}
    </Paper>
  );
};

export default SelectedFilters;
