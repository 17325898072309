import { FC, useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareInternalNotes from 'api/eCareInternalNotes/eCareInternalNotes';
import { ECareInternalNotes } from 'api/eCareInternalNotes/eCareInternalNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import eCareInternalNotesMessages from 'translations/specific/eCareInternalNotes.mjs';

import useStyles from './InternalNotes.styles';

type FormInput = {
  content: string;
};

type Props = {
  note: ECareInternalNotes;
};
const InternalNotes: FC<Props> = ({ note }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, note.order], [note.order]);

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareInternalNotes.patchNoteData(note.id), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSuccess: async () => {
      await queryClient.resetQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues } = useForm<FormInput>();

  const updateNote = useCallback(async () => {
    const confirmed = await showConfirmationDialog({
      title: t(eCareInternalNotesMessages.modal.editNote),
      body: <FormInputText control={control} defaultValue={note.content} label='' multiline name='content' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed)
      statusMutation.mutate({
        content: getValues('content'),
        order_id: note.order,
      });
  }, [note.content]);

  const header = [
    {
      label: t(eCareInternalNotesMessages.table.updateDate),
      value: format(note.updatedAt, DATE_FORMATS.DISPLAY),
    },
  ];

  const description = note.content;

  return (
    <BoxWithHeader
      description={description}
      header={header}
      headerElement={
        <Button className={classes.button} size='small' variant='outlined' onClick={updateNote}>
          {t(eCareInternalNotesMessages.modal.edit)}
        </Button>
      }
    />
  );
};

export default InternalNotes;
