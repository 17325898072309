import { ECareContactNote } from 'api/eCareContactNotes/eCareContactNotes.types';
import ECareAlarmInternalNotes from 'components/UI/molecules/ECareAlarmForms/ECareAlarmInternalNotes/ECareAlarmInternalNotes';
import { PartialRecord } from 'constants/_types/PartialRecord';
import { AcceptedCellValues } from 'hooks/useTableData/useTableData';

const usePhoneCallNotes = (eCareInternalNotesData: PartialRecord<keyof ECareContactNote, AcceptedCellValues>[] | null) => {
  const internalNote = eCareInternalNotesData && eCareInternalNotesData[0];

  return [
    {
      label: '',
      value: internalNote && <ECareAlarmInternalNotes internalNote={internalNote as ECareContactNote} />,
    },
  ];
};

export default usePhoneCallNotes;
