import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import InputCheckbox from 'components/UI/molecules/InputCheckbox/InputCheckbox';
import { reorderSelfPayerFirst } from 'services/reorderSelfPayerFirst/reorderSelfPayerFirst';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import AvailablePayerLabel from '../AvailablePayerLabel/AvailablePayerLabel';
import useStyles from './AvailablePayers.styles';

const AvailablePayers: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { payers, rawPayers, toggle } = useUserPayersStorage();

  const reorderedData = useMemo(() => reorderSelfPayerFirst(rawPayers), [rawPayers]);

  const handleChange = useCallback((id: number) => {
    toggle(id);
  }, []);

  return (
    <div className={classes.root}>
      {reorderedData &&
        reorderedData.map(({ id, payerImg, isSelfPayer, code }) => (
          <InputCheckbox
            checkboxProps={{ className: classes.checkbox }}
            className={classes.inputCheckbox}
            key={id}
            // controlled checkbox is necessary to keep UI updated
            label={<AvailablePayerLabel alt={isSelfPayer ? t(orderServiceMessages.payers.selfPayer) : code} logoSrc={payerImg?.logoLeft} />}
            value={payers[id] ?? true}
            onChange={() => handleChange(id)}
          />
        ))}
    </div>
  );
};

export default AvailablePayers;
