import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const baseStyles: CSSObject = {
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '1rem',
};

const useStyles = makeStyles()(theme => ({
  select: {
    height: '36px',

    fieldset: {
      border: 0,
    },
    svg: {
      top: 'calc(50%)',
      transform: 'translate(0, -50%)',
    },
  },
  selectedMenuItem: {
    ...baseStyles,
  },
  menuItem: {
    ...baseStyles,
    '&.Mui-disabled': {
      display: 'none',
    },
  },
}));

export default useStyles;
