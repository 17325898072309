import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ConsultationOnlineIcon } from 'assets/icons/dashboard/consultation_online.svg';
import { ReactComponent as HospitalIcon } from 'assets/icons/dashboard/hospital.svg';
import { ReactComponent as InstitutionIcon } from 'assets/icons/dashboard/institution.svg';
import { ReactComponent as LungsIcon } from 'assets/icons/dashboard/lungs.svg';
import { ReactComponent as SymptomCheckerIcon } from 'assets/icons/dashboard/symptom_checker.svg';
import { ReactComponent as VirusIcon } from 'assets/icons/dashboard/virus.svg';
import type { DashboardButtonType, OrderServiceProps } from 'constants/_types/DashboardButtons';
import { ORDER_SERVICE_ORIGINS } from 'constants/_types/OrderServiceOrigins';
import PATHS from 'constants/router/PATHS';
import dashboardMessages from 'translations/specific/dashboard.mjs';

export const useGetSickCardButtons = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = useCallback((orderServiceProps: OrderServiceProps) => {
    navigate({ pathname: orderServiceProps.path, search: `?origin=${orderServiceProps.origin}&step=1` });
  }, []);

  const sickCardButtons: DashboardButtonType[] = useMemo(
    () => [
      {
        label: t(dashboardMessages.sickCard.virtual_doctor.header),
        to: PATHS.SYMPTOM_CHECKER,
        icon: SymptomCheckerIcon,
      },
      {
        label: t(dashboardMessages.sickCard.teleconsultation.header),
        onNavigate: handleNavigate,
        orderServiceProps: { origin: ORDER_SERVICE_ORIGINS.TELECONSULTATION, path: PATHS.ORDER_SERVICE, icon: <ConsultationOnlineIcon /> },
        icon: ConsultationOnlineIcon,
      },
      {
        label: t(dashboardMessages.sickCard.stationary_visit.header),
        onNavigate: handleNavigate,
        orderServiceProps: { origin: ORDER_SERVICE_ORIGINS.APPOINTMENT, path: PATHS.ORDER_SERVICE, icon: <InstitutionIcon /> },
        icon: InstitutionIcon,
      },
      {
        label: t(dashboardMessages.sickCard.examinations.header),
        onNavigate: handleNavigate,
        orderServiceProps: { origin: ORDER_SERVICE_ORIGINS.TESTS, path: PATHS.ORDER_SERVICE, icon: <LungsIcon /> },
        icon: LungsIcon,
      },
      {
        label: t(dashboardMessages.sickCard.otherServices.header),
        onNavigate: handleNavigate,
        orderServiceProps: { origin: ORDER_SERVICE_ORIGINS.OTHERS, path: PATHS.ORDER_SERVICE, icon: <VirusIcon /> },
        icon: VirusIcon,
      },
      {
        label: t(dashboardMessages.sickCard.homeVisits.header),
        onNavigate: handleNavigate,
        orderServiceProps: { origin: ORDER_SERVICE_ORIGINS.HOME_VISIT, path: PATHS.ORDER_SERVICE, icon: <HospitalIcon /> },
        icon: HospitalIcon,
      },
    ],
    [i18n.language],
  );

  return { sickCardButtons };
};
