import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  inputWrapper: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
}));

export default useStyles;
