import { useCallback, useState } from 'react';

import { normalizeString } from 'helpers/normalizeString/normalizeString';

const useSearch = () => {
  const [matchedServices, setMatchedServices] = useState<(number | string)[]>([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [lastSearchPhrase, setLastSearchPhrase] = useState<null | string>(null);

  const onSearch = useCallback(
    (phrase: string, entities: { id: number | string; searchableName?: string }[], repeated = false) => {
      const newMatchedServices: (number | string)[] = [];
      if (entities && phrase) {
        entities.forEach(({ id, searchableName }) => {
          if (normalizeString(searchableName)?.includes(normalizeString(phrase))) {
            newMatchedServices.push(id);
          }
        });
        setIsSearchActive(true);
        if (!repeated) setLastSearchPhrase(phrase);
      } else {
        setIsSearchActive(false);
        if (!repeated) setLastSearchPhrase(null);
      }
      setMatchedServices(newMatchedServices);
    },
    [matchedServices, setMatchedServices],
  );

  const repeatSearch = (newEntities: { id: number | string; searchableName?: string }[]) => {
    if (lastSearchPhrase) onSearch(lastSearchPhrase, newEntities, true);
  };

  const checkIsMatched = useCallback(
    (id?: number) => {
      if (!id) return false;
      return matchedServices.includes(id);
    },
    [matchedServices],
  );

  return { isSearchActive, checkIsMatched, onSearch, repeatSearch };
};

export default useSearch;
