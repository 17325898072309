import type { FC } from 'react';

import useStyles from './SettingSectionContent.styles';

export interface Props {
  content: JSX.Element;
}

const SettingSectionContent: FC<Props> = ({ content }) => {
  const { classes } = useStyles();

  return <section className={classes.root}>{content}</section>;
};

export default SettingSectionContent;
