import type { FaqDto, Faq } from '../types';

export const parseFaqForFE = (data: FaqDto[]): Faq[] =>
  data
    ? data.map(({ category, ordering, translation_array_question, translation_array_answer }) => ({
        category,
        ordering,
        translationArrayQuestion: translation_array_question,
        translationArrayAnswer: translation_array_answer,
      }))
    : [];
