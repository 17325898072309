import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    '& > p': {
      margin: 0,
    },
  },
}));

export default useStyles;
