import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from '../../_constants/inputKeys';
import { ORDER_SERVICE_FORM_INPUT_KEYS } from '../../_constants/inputKeys';

export const resetPickServices = (form: UseFormReturn<OrderServiceFormInput, any>) => {
  const shouldReset = form
    .getValues([
      ORDER_SERVICE_FORM_INPUT_KEYS.serviceType as 'serviceType',
      ORDER_SERVICE_FORM_INPUT_KEYS.serviceSubtype as 'serviceSubtype',
      ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype as 'serviceFromSubtype',
    ])
    .some(
      value =>
        (
          value as
            | OrderServiceFormInput['serviceType']
            | OrderServiceFormInput['serviceSubtype']
            | OrderServiceFormInput['serviceFromSubtype']
        ).length,
    );

  if (shouldReset) {
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceType as 'serviceType', '');
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceSubtype as 'serviceSubtype', []);
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype as 'serviceFromSubtype', '');
  }
};
