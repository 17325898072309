import type { FC } from 'react';

import type { BasketItem } from 'constants/_types/Basket';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';

import SectionContent from './_components/SectionContent/SectionContent';
import SectionHeader from './_components/SectionHeader/SectionHeader';
import useStyles from './DetailsKindSection.styles';

export interface Props {
  isCumulative: boolean;
  items: BasketItem[];
  kind: OrderServiceCoreServicesKinds;
}

const DetailsKindSection: FC<Props> = ({ isCumulative, items, kind }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <SectionHeader isCumulative={isCumulative} kind={kind} />
      <SectionContent isCumulative={isCumulative} items={items} kind={kind} />
    </div>
  );
};

export default DetailsKindSection;
