import type { Dispatch, SetStateAction } from 'react';

interface PerformToggleParams {
  isOnInit: boolean;
  initialSwitchState: boolean;
  switchState: boolean;
  isEnforcedByBranding: boolean;
  setIsModalShown: Dispatch<SetStateAction<boolean>>;
  checkPhoneNumberVerification: () => void;
}

export const performToggle = ({
  isOnInit,
  initialSwitchState,
  switchState,
  isEnforcedByBranding,
  setIsModalShown,
  checkPhoneNumberVerification,
}: PerformToggleParams) => {
  if (isEnforcedByBranding) {
    checkPhoneNumberVerification();
    return;
  }

  const stateDidNotChange = initialSwitchState === switchState;
  if (isOnInit || stateDidNotChange) return;

  const isGonnaBeTurnedOff = !switchState;
  if (isGonnaBeTurnedOff) setIsModalShown(true);
  else checkPhoneNumberVerification();
};
