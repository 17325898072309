import type { FC, ReactNode } from 'react';

import useStyles from './ServicesPageContentWrapper.styles';

export type Props = {
  children: ReactNode;
};

const ServicesPageContentWrapper: FC<Props> = ({ children }) => {
  const { classes } = useStyles();
  return <main className={classes.root}>{children}</main>;
};

export default ServicesPageContentWrapper;
