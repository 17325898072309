import { makeStyles } from 'tss-react/mui';

import { FORM_BAR_BUTTONS } from 'constants/styles/_types/FormBarButtons';

const useStyles = makeStyles<{ hasArrow?: boolean }>()((theme, { hasArrow = false }) => ({
  previousButton: {
    fontSize: hasArrow ? '0.7858rem' : 'inherit',
    gridArea: FORM_BAR_BUTTONS.previousButtonArea,
  },

  arrow: {
    display: 'block',
    marginRight: theme.spacing(2),
    height: '9px',
  },
}));

export default useStyles;
