import type { FC } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import clausesMessages from 'translations/specific/clauses.mjs';

import useStyles from './ClauseAttachmentSection.styles';

export interface Props {
  clause: ClauseWithConsent;
  withLeftPadding?: boolean;
}

const ClauseAttachmentSection: FC<Props> = ({ clause, withLeftPadding = false }) => {
  const { classes } = useStyles({ withLeftPadding });
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Button color='primary' component='a' href={clause.file} size='small' target='_blank' variant='outlined'>
        {t(clausesMessages.fileLink)}
      </Button>
    </div>
  );
};

export default ClauseAttachmentSection;
