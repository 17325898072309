import { Theme } from '@mui/material';

import { DEEP_BRANDING } from 'constants/_types/DeepBranding';
import interriskTheme from 'styles/brandingThemes/interrisk';
import defaultTheme from 'styles/theme';

const resolveTheme = (): Theme => {
  switch (process.env.REACT_APP_DEEP_BRANDING) {
    case DEEP_BRANDING.interrisk:
      return interriskTheme;
    default: {
      return defaultTheme;
    }
  }
};

export default resolveTheme();
