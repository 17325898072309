import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OrderServiceBasketActions from 'components/UI/molecules/OrderServiceBasketActions/OrderServiceBasketActions';
import SummaryListGroup from 'components/UI/molecules/SummaryListGroup/SummaryListGroup';
import type { Basket } from 'constants/_types/Basket';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type { SelectedServiceItemMetaData } from 'constants/_types/SelectedServiceItemMetaData';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { useShouldShowActionButtons } from '../../_hooks/useShouldShowActionButtons/useShouldShowActionButtons';

export interface Props {
  basket: Basket | null;
  removeItem: (metaData: SelectedServiceItemMetaData) => void;
}

const MobileBasketContent: FC<Props> = ({ basket, removeItem }) => {
  const { t } = useTranslation();
  const showButtons = useShouldShowActionButtons();

  return (
    // eslint-disable-next-line
    <>
      {basket ? (
        Object.keys(basket).map((coreServiceKind, index, array) => (
          <SummaryListGroup
            header={t(orderServiceMessages.basket.typesSections[coreServiceKind as OrderServiceCoreServicesKinds])}
            isLast={index === array.length - 1}
            items={basket[coreServiceKind as OrderServiceCoreServicesKinds]?.items || []}
            key={coreServiceKind}
            removeItem={removeItem}
          />
        ))
      ) : (
        <Typography component='h5' variant='caption'>
          {t(orderServiceMessages.basket.noItemsText)}
        </Typography>
      )}
      {showButtons && <OrderServiceBasketActions basket={basket} />}
    </>
  );
};

export default MobileBasketContent;
