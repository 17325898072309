import { useCallback, useEffect, useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useServicesSearchContext } from 'components/context/ServicesSearchContext/ServicesSearchContext';
import type {
  CellRendererType,
  PathToGenerator,
} from 'components/UI/molecules/ColumnsDisplaySet/_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';
import ColumnsDisplaySet from 'components/UI/molecules/ColumnsDisplaySet/ColumnsDisplaySet';
import type { ColumnsDisplaySetEntities, ColumnsDisplaySetHeader } from 'constants/_types/ColumnsDisplaySetData';
import servicesMessages from 'translations/specific/services.mjs';

import useSearch from '../ServicesTypeView/_hooks/useSearch/useSearch';
import ServicesReviewCellTile from './_components/ServicesReviewCellTile/ServicesReviewCellTile';
import ServicesReviewTableWrapper from './_components/ServicesReviewTableWrapper/ServicesReviewTableWrapper';

export type ServicesReviewTableDataInput = {
  headers: ColumnsDisplaySetHeader[];
  entities: ColumnsDisplaySetEntities;
} | null;

export type Props = {
  title: string;
  isLoading: boolean;
  columnsNumber?: number;
  isTableActive?: boolean;
  data: ServicesReviewTableDataInput;
  pathToGenerator: PathToGenerator;
  fixedHeight?: number;
};

const ServicesReviewTable: FC<Props> = ({ title, isLoading, columnsNumber, isTableActive = false, data, pathToGenerator, fixedHeight }) => {
  const { t } = useTranslation();

  const { searchPhrase } = useServicesSearchContext();
  const { isSearchActive, checkIsMatched, onSearch } = useSearch();

  const dataToSearchIn = useMemo(() => (data ? Object.values(data.entities).flat() : undefined), [data?.entities]);
  useEffect(() => {
    if (dataToSearchIn) {
      onSearch(searchPhrase, dataToSearchIn);
    }
  }, [searchPhrase, dataToSearchIn]);

  const cellRenderer: CellRendererType = useCallback(
    cellProps => <ServicesReviewCellTile checkIsMatched={checkIsMatched} isSearchActive={isSearchActive} {...cellProps} />,

    [isSearchActive, checkIsMatched],
  );

  return (
    <ServicesReviewTableWrapper isLoading={isLoading} isTableActive={isTableActive} title={title}>
      <ColumnsDisplaySet
        cellRenderer={cellRenderer}
        columnsNumber={columnsNumber}
        content={data}
        fallbackMessage={t(servicesMessages.noResultsForPaymentType)}
        fixedHeight={fixedHeight}
        pathToGenerator={pathToGenerator}
      />
    </ServicesReviewTableWrapper>
  );
};

export default ServicesReviewTable;
