import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  searchResults: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3.75),
    overflow: 'auto',
    background: theme.palette.background.paper,
    padding: theme.spacing(3, 0, 4),
    maxHeight: 'calc(100vh - 250px)',
  },

  clarificationText: {
    padding: theme.spacing(0, 4),
  },

  loader: {
    alignSelf: 'center',
  },
}));

export default useStyles;
