import type { ApiContentType, ApiContentTypeDTO, ApiContentTypeModels } from './apiContentType.types';
import { apiContentTypeModels } from './apiContentType.types';

const keyIsApiContentTypeModel = (key: string): key is ApiContentTypeModels => apiContentTypeModels.includes(key as ApiContentTypeModels);

export const parseApiContentTypeForFE = (apiContentTypeDTO: ApiContentTypeDTO[]): ApiContentType =>
  apiContentTypeDTO.reduce<ApiContentType>((acc, item) => {
    const key = item.model;
    if (keyIsApiContentTypeModel(key)) acc[key] = item.id;
    return acc;
  }, {} as ApiContentType);
