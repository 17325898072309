import { useCallback, useEffect } from 'react';

import type { UseFormReturn } from 'react-hook-form';

import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';

import type { OrderServiceFormInput } from '../../_constants/inputKeys';
import { resetPickServices } from '../../_services/resetPickServices/resetPickServices';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
}

export const useResetPickServices = ({ form }: HookParams) => {
  const selectedPayersList = useUserPayersStorage(state => state.getSelectedPayersList());

  const isPayersStateChanged = selectedPayersList.length;

  const handleManualResetPickServices = useCallback(() => {
    resetPickServices(form);
  }, []);

  useEffect(() => {
    handleManualResetPickServices();
  }, [isPayersStateChanged]);

  return { handleManualResetPickServices };
};
