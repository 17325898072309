import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ICON_SIZES_STYLES from 'constants/styles/ICON_SIZES_STYLES';
import theme from 'styles/theme';

interface StyleProps {
  isBoxShadow: boolean;
  isAlternativeStyle: boolean;
}

const useStyles = makeStyles<StyleProps>()((_, { isBoxShadow, isAlternativeStyle }) => ({
  // additional background and a lot of styles in order to avoid gap between boxShadow and the icon once boxShadow is used on <svg />
  // (using boxShadow on <path /> should solve the issue but boxShadow doesn't work on <path />)
  root: {
    width: `${ICON_SIZES_STYLES.large.width} !important`,
    height: `${ICON_SIZES_STYLES.large.height} !important`,
    backgroundColor: theme.palette.error.dark,
    borderRadius: '50%',
    boxShadow: isBoxShadow ? `0 8px 16px ${alpha(theme.palette.error.main, 0.3)} !important` : 'none',
    position: isAlternativeStyle ? 'unset' : 'absolute',
  },

  icon: {
    width: `${ICON_SIZES_STYLES.large.width} !important`,
    height: `${ICON_SIZES_STYLES.large.height} !important`,
    position: 'relative',
    zIndex: 2,
    color: isAlternativeStyle ? theme.palette.error.dark : 'inherit',
  },

  iconBackground: {
    position: 'relative',
    backgroundColor: '#fff',
    width: '20px',
    height: '20px',
    zIndex: 1,
    top: isAlternativeStyle ? '-33px' : '-30px',
    left: '6px',
    borderRadius: '50%',
  },
}));

export default useStyles;
