import { useState } from 'react';

import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';

import users from 'api/users';
import { PasswordChangeFormInput } from 'components/UI/organisms/_dialogs/PasswordChangeDialog/PasswordChangeDialog';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import snackbarMessages from 'translations/common/snackbar.mjs';

export const PASSWORD_CHANGE_MUTATION_KEY = 'PasswordChange dialog request';

type UsePasswordChangeMutationArguments = {
  close: () => void;
  setError: UseFormSetError<PasswordChangeFormInput>;
};

const usePasswordChangeMutation = ({ close, setError }: UsePasswordChangeMutationArguments) => {
  const { showSnackbar } = useShowSnackbar();
  const [generalErrors, setGeneralErrors] = useState<string[]>();

  const onError = useOnBackendFormError<PasswordChangeFormInput, PasswordChangeFormInput>({
    setError,
    setGeneralErrors,
  });

  const { mutate, isLoading } = useMutation(PASSWORD_CHANGE_MUTATION_KEY, users.passwordChange(), {
    retry: 0,
    onSuccess: () => {
      showSnackbar({
        variant: 'success',
        translationArray: snackbarMessages.success,
      });
      close();
    },
    onError,
  });
  return { passwordChangeMutate: mutate, passwordChangeMutateGeneralErrors: generalErrors, isPasswordChangeMutating: isLoading };
};

export default usePasswordChangeMutation;
