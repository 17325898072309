import { PaletteColor } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { StyleSheet } from '@react-pdf/renderer';

const getStyles = (theme: Theme, triageColor: PaletteColor) => {
  const spacing = {
    marginBottom: theme.spacing(3),
  };
  return StyleSheet.create({
    page: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(8, 6),
      fontFamily: 'Roboto',
      color: theme.palette.text.primary,
    },
    view: {},
    h1: {
      fontSize: '24px',
      fontWeight: 100,
    },
    h2: {
      color: theme.typography.h2.color,
      fontSize: '18px',
      ...spacing,
    },
    subtitle1: {
      fontSize: '12px',
      ...spacing,
    },
    caption: {
      fontSize: '9px',
    },
    triageContainer: {
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      ...spacing,
    },
    triageIndicator: {
      width: '100px',
      padding: theme.spacing(4, 2),
      backgroundColor: triageColor.main,
    },
    triageIcon: {
      width: '64px',
      height: '64px',
    },
    triageMain: {
      padding: theme.spacing(5, 3),
    },
    triageRecommendation: {
      color: theme.typography.h2.color,
      fontSize: '11px',
      paddingBottom: theme.spacing(1),
    },
    triageHeader: {
      color: theme.typography.h2.color,
      fontFamily: 'Roboto-Bold',
      fontSize: '14px',
      paddingBottom: theme.spacing(1),
      paddingRight: '100px',
    },
    triageContent: {
      fontSize: '12px',
      paddingRight: '100px',
    },
    triageBrandText: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3, 0),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      ...spacing,
    },
    triageBrandTextContent: {
      textAlign: 'center',
      padding: theme.spacing(0, 12),
    },
    card: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3, 2),
      borderRadius: theme.shape.borderRadius,
      ...spacing,
    },
    cardTitle: {
      fontFamily: 'Roboto-Bold',
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(0.75),
      fontSize: '12px',
    },
    cardValue: {
      fontSize: '11px',
      marginBottom: theme.spacing(0.25),
    },
    cardValueRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '3px',
    },
    cardSection: {
      marginTop: theme.spacing(2),
    },
    icon: {
      width: '7px',
      height: '7px',
      marginRight: theme.spacing(0.5),
      marginBottom: '1px',
    },
    divider: {
      backgroundColor: theme.palette.grey[300],
      margin: theme.spacing(2, 0),
      height: '1px',
      width: '100%',
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexChild: {
      paddingRight: theme.spacing(2),
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableCell: {
      width: '50%',
    },
    barContainer: {
      width: '50px',
      height: '8px',
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      borderRadius: '100px',
      overflow: 'hidden',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.25),
    },
    bar: {
      backgroundColor: 'red',
      position: 'absolute',
      height: '100%',
      width: `50%`,
    },
  });
};

export default getStyles;
