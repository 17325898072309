import { useMemo } from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import orderServiceMessages from 'translations/specific/orderService.mjs';

interface HookParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  formFileName: string;
  formOrderReferralName: string;
}

export const usePrepareValidationRules = ({ form, formFileName, formOrderReferralName }: HookParams) => {
  const { t, i18n } = useTranslation();

  return useMemo(
    () => ({
      validate: () => {
        const formFileValue = form.getValues(formFileName as any) as File[] | File;
        const formOrderReferralValue = form.getValues(formOrderReferralName as any) as boolean;
        const touched = form.getFieldState(formFileName as any).isTouched || form.getFieldState(formOrderReferralName as any).isTouched;

        const file = Array.isArray(formFileValue) ? formFileValue[0] : formFileValue;

        if (!file && !formOrderReferralValue) {
          if (touched) return t(orderServiceMessages.details.validationErrors.referral);
          return '';
        }

        const formFileError = form.getFieldState(formFileName as any).error?.message;
        const formOrderReferralError = form.getFieldState(formOrderReferralName as any).error?.message;
        if (formFileError) form.clearErrors(formFileName as any);
        if (formOrderReferralError) form.clearErrors(formOrderReferralName as any);

        return true;
      },
    }),
    [t, i18n.language, form],
  );
};
