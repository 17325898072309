const authMessages = {
  emailOrAuthIdentifier: [ 'auth>>email_or_auth_identifier', 'Email lub identyfikator użytkownika' ],
  email: [ 'auth>>email', 'Adres Email' ],
  password: [ 'auth>>password', 'Hasło' ],
  current_password: [ 'auth>>current_password', 'Obecne hasło' ],
  repeat_password: [ 'auth>>repeat_password', 'Powtórz hasło' ],
  new_password: [ 'auth>>new_password', 'Nowe hasło' ],
  repeat_new_password: [ 'auth>>repeat_new_password', 'Powtórz nowe hasło' ],
  invalid_activation_link: [ 'auth>>invalid_activation_link', 'Link został wykorzystany lub upłynął termin jego ważności' ],
  account_activated: [ 'auth>>account_activated', 'Konto zostało aktywowane, możesz teraz się zalogować' ],
}

export default authMessages
