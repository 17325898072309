import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { SnackbarVariant } from 'constants/_types/SnackbarVariant';
import { SNACKBAR_FONT_SETTINGS } from 'constants/styles/SNACKBAR_FONT_SETTINGS';

const hasLargeLeftMargin = (iconVariant: SnackbarVariant) => iconVariant === 'error';
const getMainFontColor = (iconVariant: SnackbarVariant, theme: Theme) => {
  switch (iconVariant) {
    case 'error':
      return theme.palette.error.main;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
    case 'info':
    default:
      return '#5F6672';
  }
};
const getAccentFontColor = (iconVariant: SnackbarVariant, theme: Theme) => {
  switch (iconVariant) {
    case 'warning':
      return theme.palette.warning.main;
    case 'info':
      return theme.palette.secondary.main;
    case 'success':
    default:
      return '#616874';
  }
};

const useStyles = makeStyles<{ iconVariant: SnackbarVariant }>()((theme, { iconVariant }) => ({
  text: {
    marginLeft: theme.spacing(hasLargeLeftMargin(iconVariant) ? 6.5 : 2.5),
    marginRight: theme.spacing(2),
    color: getMainFontColor(iconVariant, theme),
    ...SNACKBAR_FONT_SETTINGS,
  },

  accentColorText: {
    // error variant has no accent text
    color: iconVariant === 'error' ? theme.palette.error.main : getAccentFontColor(iconVariant, theme),
  },

  refreshButton: {
    textTransform: 'none !important' as 'none',
    ...SNACKBAR_FONT_SETTINGS,
  },
}));

export default useStyles;
