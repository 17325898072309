import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  buttons: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(3),
    alignItems: 'flex-end',
    marginBottom: theme.spacing(0.5),
  },
  tableWrapper: {
    marginBottom: 20,
  },
  section: {
    gap: 0,
  },
  bottomActionBar: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    textAlign: 'right',
  },
  boxList: {
    marginBottom: theme.spacing(2),
  },
  filedTitle: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    marginTop: theme.spacing(3),
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
