import { makeStyles } from 'tss-react/mui';

import { PSEUDOELEMENT_ACTIVE_BORDER_BASE } from 'constants/styles/PSEUDOELEMENT_ACTIVE_BORDER_BASE';

const useStyles = makeStyles<{ showGrid?: boolean }>()((theme, { showGrid }) => {
  const BORDER = `thin solid ${theme.palette.grey[200]}`;

  const resolveBorder = () => {
    if (showGrid) return { border: BORDER };
    return {
      borderRight: BORDER,
      borderLeft: BORDER,
    };
  };

  return {
    tr: {
      // hack to expand tile to all cell
      height: '1px',

      '&:first-of-type td': {
        paddingTop: theme.spacing(0.5),
        borderTop: 'none',
      },
    },
    td: {
      // hack to expand tile to all cell
      height: '100%',
      position: 'relative',
      padding: theme.spacing(0.5),
      ...resolveBorder(),
    },
    tdActive: {
      '&:before': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: '-1px',
        left: '-1px',
        height: 'calc(100% + 1px)',
        width: '2px',
      },
      '&:after': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        top: '-1px',
        right: '-1px',
        height: 'calc(100% + 1px)',
        width: '2px',
      },
    },
    activeBorderNode: {
      '&:after': {
        ...PSEUDOELEMENT_ACTIVE_BORDER_BASE(theme),
        bottom: '-1px',
        left: 0,
        width: '100%',
        height: '2px',
      },
    },
  };
});

export default useStyles;
