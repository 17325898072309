import { useQuery } from 'react-query';

import referralNeeded from 'api/referralNeeded/referralNeeded';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

export interface HookParams {
  serviceItemId: number;
  currentPayerId: string;
}

export const useReferralSectionQuery = ({ serviceItemId, currentPayerId }: HookParams) => {
  const { data } = useQuery(
    `${QUERY_KEYS.REFERRAL_NEEDED}-${serviceItemId}-${currentPayerId}`,
    referralNeeded.getReferralNeeded({ serviceItemId, currentPayerId }),
    {
      enabled: !!serviceItemId && !!currentPayerId,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return { referralNeeded: data?.referralNeeded };
};
