import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

import type { CoreServiceGroup, CoreServiceGroupDTO } from '../coreServiceGroups.types';

export const parseServicesGroupForFE = (rawData: CoreServiceGroupDTO[]): CoreServiceGroup[] =>
  rawData.map(({ group_section, name, ...rest }) => ({
    ...rest,
    groupSection: group_section.map(({ id, section_name, type_name, ordering }) => ({
      id,
      sectionName: parseDynamicTranslatableName(section_name),
      typeName: parseDynamicTranslatableName(type_name),
      ordering,
    })),
    name: parseDynamicTranslatableName(name),
  }));
