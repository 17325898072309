import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gap: theme.spacing(3),
    height: '100%',
  },

  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    overflow: 'auto',
    height: '100%',

    [theme.breakpoints.down('xs')]: {
      maxHeight: 'unset',
    },
  },
}));

export default useStyles;
