import type { CoreService, CoreServiceDTO } from 'api/coreService/coreService.types';
import { replaceDashWithUnderscore } from 'helpers/replaceDashWithUnderscore/replaceDashWithUnderscore';

import parseDynamicTranslatableName from '../parseDynamicTranslatableName/parseDynamicTranslatableName';

const parseCoreService = (raw: CoreServiceDTO): CoreService => ({
  id: raw.id,
  name: parseDynamicTranslatableName(raw.name),
  coreServiceKind: replaceDashWithUnderscore(raw.kind.code),
  order: raw.column_order ?? 0,
  subtype: {
    id: raw.subtype.id,
    name: parseDynamicTranslatableName(raw.subtype.name),
    serviceOrigin: raw.subtype.service_origin,
    masterType: raw.subtype.master_type,
    masterTypeName: parseDynamicTranslatableName(raw.subtype.master_type_name),
    ordering: raw.subtype.ordering,
  },
});

export default parseCoreService;
