import { FC, ReactNode, useMemo, useState, MouseEvent } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Button, Divider, IconButton, Menu, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import patientRelatedFiles from 'api/patientRelatedFiles/patientRelatedFiles';
import { PatientRelatedFile } from 'api/patientRelatedFiles/patientRelatedFiles.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import { useFileHandlers } from 'components/UI/molecules/DetailsKindSection/_components/ReferralAttachment/_hooks/useFileHandlers/useFileHandlers';
import FormInputFileHidden from 'components/UI/organisms/_formInputs/FormInputFileHidden/FormInputFileHidden';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import { ACCEPTED_FILE_FORMATS_REGEX } from 'constants/_files/acceptedFileFormats';
import { FileUploadState } from 'constants/_types/fileUploadState';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import trimString from 'helpers/trimString/trimString';
import { useProcessUploadedFile } from 'hooks/_fileProcessing/useProcessUploadedFile/useProcessUploadedFile';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import generalMessages from 'translations/common/general.mjs';
import orderServiceMessages from 'translations/specific/orderService.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import useStyles from './PatientHealthDetailsFiles.styles';

type FormInput = {
  file: File[] | null;
  description: string;
  filePlaceholder: string;
  fileToUpdate: number | null;
};

type Props = {
  patientId: number;
  files: PatientRelatedFile[];
  fileType: PatientRelatedFile['type'];
  fileLimit?: number;
};

// TODO edit file

const PatientHealthDetailsFiles: FC<Props> = ({ patientId, files, fileType, fileLimit = Infinity }) => {
  const { showSnackbar } = useShowSnackbar();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { t } = useTranslation();
  const [fileUploadState, setFileUploadState] = useState<FileUploadState>('processing');
  const queryClient = useQueryClient();

  const form = useForm<FormInput>({ defaultValues: { file: null, description: '', filePlaceholder: '', fileToUpdate: null } });
  const filePlaceholder = form.watch('filePlaceholder');

  const { filename, inputRef, handleAdd, handleFileProceed, handleRemove } = useFileHandlers({
    form,
    formFileName: 'file',
    setUploadState: setFileUploadState,
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const openMenuFactory = (fileToUpdate?: PatientRelatedFile) => (event: MouseEvent<HTMLButtonElement>) => {
    if (fileToUpdate) {
      form.setValue('description', fileToUpdate.description);
      form.setValue('file', [new File([], fileToUpdate.fileName)]);
      form.setValue('filePlaceholder', fileToUpdate.fileName);
      form.setValue('fileToUpdate', fileToUpdate.id);
      setFileUploadState('loaded');
    }
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    form.reset();
    setMenuAnchorEl(null);
  };

  const onSettled = () => {
    queryClient.invalidateQueries([QUERY_KEYS.GET_PATIENT_RELATED_FILES, patientId]);
  };

  const createMutation = useMutation(patientRelatedFiles.createPatientRelatedFiles(), {
    onSettled,
  });

  const updateMutation = useMutation(patientRelatedFiles.updatePatientRelatedFiles(), {
    onSettled,
  });

  const removeMutation = useMutation(patientRelatedFiles.removePatientRelatedFiles(), {
    onSettled,
  });

  const onSubmit = async (formState: FormInput) => {
    const filesList = formState.file;
    if (!filesList || !filesList[0]) return;

    const mutationData = {
      description: formState.description,
      patient: patientId,
      file: filesList[0],
      type: fileType,
    };

    if (formState.fileToUpdate) {
      await updateMutation.mutateAsync({ fileId: formState.fileToUpdate, data: mutationData });
    } else {
      await createMutation.mutateAsync(mutationData);
    }

    form.reset();
    await handleRemove();
    closeMenu();
  };

  const onRemoveFactory = (id: number) => async () => {
    const shouldRemove = await showConfirmationDialog({
      title: t(proPatientMessages.healthDetails.removeFileConfirmationTitle),
      body: t(proPatientMessages.healthDetails.removeFileConfirmationBody),
    });

    if (shouldRemove) {
      await removeMutation.mutateAsync(id);
      showSnackbar({ variant: 'success', translationArray: proPatientMessages.healthDetails.fileRemoved });
    }
  };

  const { classes } = useStyles();

  const { fileButtonProps, fileButtonLabel } = useMemo<{
    fileButtonLabel: ReactNode;
    fileButtonProps: LoadingButtonProps;
  }>(() => {
    switch (fileUploadState) {
      case 'loaded':
        return { fileButtonLabel: null, fileButtonProps: {} };
      case 'processing':
        return { fileButtonLabel: t(generalMessages.addFile), fileButtonProps: { loading: true } };
      case 'initial': {
        const isError = form.formState.errors.file;
        return {
          fileButtonLabel: t(generalMessages.addFile),
          fileButtonProps: {
            loading: false,
            onClick: handleAdd,
            ...(isError ? { color: 'error', variant: 'contained' } : { variant: 'outlined' }),
          },
        };
      }
      default:
        return exhaustiveGuard(fileUploadState);
    }
  }, [fileUploadState, filename, handleRemove, handleAdd, t, form.formState]);

  const fileProcessingConfig = useMemo(
    () => ({
      proceedingCallback: handleFileProceed,
      config: {
        fileType: {
          acceptedType: ACCEPTED_FILE_FORMATS_REGEX,
          errorMessageTranslationArray: orderServiceMessages.details.file.incorrectExtension,
        },
        fileSize: { errorMessageTranslationArray: orderServiceMessages.details.file.tooBig, acceptedMaxSize: 5 },
      },
    }),
    [],
  );

  // TODO try to simplify it
  const handleOnUploadedFile = useProcessUploadedFile(fileProcessingConfig);

  return (
    <div>
      <div className={classes.filesList}>
        {files && files.length > 0 ? (
          files.map((file, index) => {
            const { id, description, fileName, fileUrl, createdAt } = file;
            return (
              <>
                <div className={classes.file} key={id}>
                  <div>
                    <Typography component='span' variant='subtitle2'>
                      <a href={fileUrl} rel='noreferrer' target='_blank'>
                        {fileName}
                      </a>
                    </Typography>
                    <Typography component='span'> - {description}</Typography>
                    <Typography component='p' sx={{ mb: 1 }} variant='caption'>
                      {t(generalMessages.lastUpdate, { date: format(createdAt, DATE_FORMATS.DISPLAY) })}
                    </Typography>
                  </div>
                  <div>
                    <Button onClick={openMenuFactory(file)}>{t(proPatientMessages.healthDetails.editFile)}</Button>
                    <Button onClick={onRemoveFactory(id)}>{t(proPatientMessages.healthDetails.removeFile)}</Button>
                  </div>
                </div>
                {index < files.length - 1 && <Divider />}
              </>
            );
          })
        ) : (
          <Typography>{t(generalMessages.noData)}</Typography>
        )}
      </div>
      <Button disabled={files.length >= fileLimit} size='small' variant='outlined' onClick={openMenuFactory()}>
        {t(proPatientMessages.healthDetails.showFileInputLabel)}
      </Button>
      <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={closeMenu}>
        <form className={classes.form} onSubmit={form.handleSubmit(onSubmit)}>
          <FormInputText
            control={form.control}
            inputProps={{ disabled: createMutation.isLoading }}
            label={t(proPatientMessages.healthDetails.fileDescriptionInputLabel)}
            name='description'
            required
          />
          <div className={classes.addButton}>
            {fileUploadState === 'loaded' ? (
              <div className={classes.buttonContainer}>
                <Typography color='primary'>{trimString(15, filename || filePlaceholder, { keepExtension: true })}</Typography>
                <IconButton disabled={createMutation.isLoading} size='small' onClick={handleRemove}>
                  <CloseOutlinedIcon fontSize='small' />
                </IconButton>
              </div>
            ) : (
              <LoadingButton {...fileButtonProps}>{fileButtonLabel}</LoadingButton>
            )}
            <FormInputFileHidden
              acceptedFormats='.pdf'
              control={form.control}
              defaultValue={null}
              inputRef={inputRef}
              name='file'
              required
              onUploadedFile={handleOnUploadedFile}
            />
          </div>

          <LoadingButton loading={createMutation.isLoading} type='submit' variant='contained'>
            {t(generalMessages.save)}
          </LoadingButton>
        </form>
      </Menu>
    </div>
  );
};

export default PatientHealthDetailsFiles;
