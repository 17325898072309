import * as process from 'process';

import type { FC, ReactNode } from 'react';
import { createContext, lazy, Suspense, useContext, useMemo, useRef, useState } from 'react';

import { drop } from 'lodash';

import ClausesDialog, { CLAUSES_DIALOG_VERSIONS } from 'components/UI/organisms/_dialogs/ClausesDialog/ClausesDialog';
import DialogLoader from 'components/UI/organisms/_dialogs/DialogLoader/DialogLoader';
import DisplayServicesInPolicyDialog from 'components/UI/organisms/_dialogs/DisplayServicesInPolicyDialog/DisplayServicesInPolicyDialog';
import SaveServicesProfileDialog from 'components/UI/organisms/_dialogs/SaveServicesProfileDialog/SaveServicesProfileDialog';
import type { Basket } from 'constants/_types/Basket';

const VerifyMobileNumberDialog = lazy(() => import('components/UI/organisms/_dialogs/VerifyMobileNumberDialog/VerifyMobileNumberDialog'));
const PassTwoFADialog = lazy(() => import('components/UI/organisms/_dialogs/PassTwoFADialog/PassTwoFADialog'));

export type GlobalDialog =
  | 'verifyMobileNumber'
  | 'passTwoFA'
  | 'clausesConsentsManual'
  | 'clausesConsentsForcedRequired'
  | 'clausesConsentsForcedOptional'
  | 'saveServicesProfile'
  | 'displayServicesInPolicy';

export const GlobalDialogs: { [key in GlobalDialog]: GlobalDialog } = {
  verifyMobileNumber: 'verifyMobileNumber',
  passTwoFA: 'passTwoFA',
  clausesConsentsManual: 'clausesConsentsManual',
  clausesConsentsForcedRequired: 'clausesConsentsForcedRequired',
  clausesConsentsForcedOptional: 'clausesConsentsForcedOptional',
  saveServicesProfile: 'saveServicesProfile',
  displayServicesInPolicy: 'displayServicesInPolicy',
};

type Props = {
  children: ReactNode;
};

type Dialog = {
  type: GlobalDialog;
  props?: {
    promise?: { resolve: (value: any) => any; reject: (value: any) => any };
    [key: string]: any;
  };
  withPromise?: boolean;
};

type GlobalDialogsContextType = {
  dialogQueue: Dialog[];
  closeGlobalDialog: () => void;
  addToGlobalDialogQueue: (dialogs: Dialog) => Promise<any> | null;
};

const GlobalDialogsContext = createContext({} as GlobalDialogsContextType);

const hasTokenInProps = (props: any): props is { isOpen: boolean; close: () => void; token: string } => 'token' in props;

const hasDataToSaveProps = (props: any): props is { dataToSave: Basket } => 'dataToSave' in props;

const hasPolicyProps = (props: any): props is { patientId: number; payerId: number; policyId: number } =>
  'patientId' in props && 'payerId' in props && 'policyId' in props;

const onMissingProps = (place: string, props: Record<string, any>) => {
  if (process.env.NODE_ENV === 'development') {
    throw new Error(`Missing required props in: ${place}, passed props: ${JSON.stringify(props, null, 2)}`);
  }
  return null;
};

const GlobalDialogsContextProvider: FC<Props> = ({ children }) => {
  const [dialogQueue, setDialogQueue] = useState<Dialog[]>([]);
  const promise = useRef<any>(null);

  const closeGlobalDialog = () => {
    setDialogQueue(prevDialogQueue => drop(prevDialogQueue));
  };

  const addToGlobalDialogQueue = (dialog: Dialog) => {
    const sameType = dialogQueue.find(({ type }) => type === dialog.type);
    if (dialog.withPromise) {
      return new Promise((resolve, reject) => {
        if (!sameType) {
          promise.current = { resolve, reject };
          setDialogQueue(prevDialogQueue => [...prevDialogQueue, dialog]);
        }
      });
    }
    if (!sameType) {
      setDialogQueue(prevDialogQueue => [...prevDialogQueue, dialog]);
    }
    return null;
  };

  const flushGlobalDialogQueue = () => {
    setDialogQueue([]);
  };

  const renderDialog = (dialog: Dialog) => {
    if (!dialog) return null;
    const props = {
      close: closeGlobalDialog,
      isOpen: true,
      promise: dialog.withPromise ? promise.current : undefined,
      flushGlobalDialogQueue,
      ...dialog.props,
    };
    switch (dialog.type) {
      case GlobalDialogs.verifyMobileNumber:
        return <VerifyMobileNumberDialog {...props} />;
      case GlobalDialogs.passTwoFA:
        return hasTokenInProps(props) ? <PassTwoFADialog {...props} /> : onMissingProps(dialog.type, props);
      case GlobalDialogs.clausesConsentsManual:
        return <ClausesDialog dialogVersion={CLAUSES_DIALOG_VERSIONS.manual} {...props} />;
      case GlobalDialogs.clausesConsentsForcedOptional:
        return <ClausesDialog dialogVersion={CLAUSES_DIALOG_VERSIONS.forcedOptional} {...props} />;
      case GlobalDialogs.clausesConsentsForcedRequired:
        return <ClausesDialog dialogVersion={CLAUSES_DIALOG_VERSIONS.forcedRequired} {...props} />;
      case GlobalDialogs.saveServicesProfile:
        return hasDataToSaveProps(props) ? <SaveServicesProfileDialog {...props} /> : onMissingProps(dialog.type, props);
      case GlobalDialogs.displayServicesInPolicy:
        return hasPolicyProps(props) ? <DisplayServicesInPolicyDialog {...props} /> : onMissingProps(dialog.type, props);
      default:
        return null;
    }
  };

  const value = useMemo(
    () => ({
      dialogQueue,
      closeGlobalDialog,
      addToGlobalDialogQueue,
      flushGlobalDialogQueue,
    }),
    [dialogQueue, closeGlobalDialog, addToGlobalDialogQueue, flushGlobalDialogQueue],
  );

  return (
    <GlobalDialogsContext.Provider value={value}>
      {children}
      {dialogQueue.length ? <Suspense fallback={<DialogLoader isOpen />}>{renderDialog(dialogQueue[0])}</Suspense> : null}
    </GlobalDialogsContext.Provider>
  );
};

export const useGlobalDialogsContext = () => useContext(GlobalDialogsContext);

export default GlobalDialogsContextProvider;
