import { InputLabel } from '@mui/material';

export interface Params {
  className: string;
  disabled: boolean | undefined;
  label: string | undefined;
  multiple: boolean | undefined;
  placeholder: string | undefined;
  value: string | string[] | undefined;
}

export const resolveLabelOrPlaceholder = ({ className, disabled, label, multiple, placeholder, value }: Params) => {
  const labelOrPlaceholderComponent = (
    <InputLabel className={className} disabled={disabled} id={label ? `select_label${label}` : `select_placeholder${placeholder}`}>
      {label || placeholder}
    </InputLabel>
  );

  const hasValue = multiple ? !!value?.length : !!value;
  const shouldShowPlaceholder = placeholder && !hasValue;
  const shouldShowComponent = label ? true : shouldShowPlaceholder;

  return shouldShowComponent && labelOrPlaceholderComponent;
};
