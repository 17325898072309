import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  divider: {
    width: '100%',
    marginBottom: theme.spacing(4),
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
