import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  body: {
    display: 'grid',
    gap: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  headerImage: {
    maxWidth: '100px',
  },
  headerLinks: {
    display: 'grid',
    justifyItems: 'flex-end',
    gridGap: theme.spacing(1),
  },
  disabledLink: {
    pointerEvents: 'none',
    color: theme.palette.grey[400],
  },
  infoBox: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
}));

export default useStyles;
