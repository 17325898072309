import type { UseFormReturn } from 'react-hook-form';

import { ORDER_SERVICE_FORM_INPUT_KEYS, type OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

interface Params {
  currentValue: string;
  shouldDisableSearchAroundDistance: boolean;
  form: UseFormReturn<OrderServiceFormInput>;
  errorMessage: string;
}

export const validateSearchAround = ({ currentValue, shouldDisableSearchAroundDistance, form, errorMessage }: Params) => {
  if (!currentValue && !shouldDisableSearchAroundDistance) return errorMessage;

  const searchAroundError = form.getFieldState(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as 'searchAroundDistance'}`)
    .error?.message;
  if (searchAroundError) form.clearErrors(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as 'searchAroundDistance'}`);

  return true;
};
