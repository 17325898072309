import axios from 'axios';

import { DefaultApiResponse } from './_types';

interface ShouldRemindData {
  should_change_password: boolean;
}
interface PostponeRemindInput {
  userId: number;
}

type GetShouldRemindQuery = () => Promise<DefaultApiResponse<ShouldRemindData>>;
type PostPostponeRemindQuery = (data: PostponeRemindInput) => Promise<DefaultApiResponse<null>>;

const endpoints = {
  check: 'api/v1/users/should-change-password/',
  postpone: 'api/v1/users/postpone-password-change/',
};

const passwordChangeReminder = {
  getShouldRemind: (): GetShouldRemindQuery => () => axios.get<GetShouldRemindQuery, DefaultApiResponse<ShouldRemindData>>(endpoints.check),
  postPostponeRemind: (): PostPostponeRemindQuery => (data: PostponeRemindInput) =>
    axios.post<PostPostponeRemindQuery, DefaultApiResponse<null>>(endpoints.postpone, data),
};

export default passwordChangeReminder;
