import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ maxWidth?: number }>()((theme, { maxWidth }) => ({
  root: {
    maxWidth: maxWidth ?? 'unset',
    width: '100%',
  },

  title: {
    padding: theme.spacing(3),
  },

  content: {
    padding: theme.spacing(4),
  },
}));

export default useStyles;
