import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreService from 'api/coreService/coreService';
import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import type { DropdownOption } from 'constants/_types/DropdownOptions';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import createQueryKeyFromObject from 'helpers/createQueryKeyFromObject/createQueryKeyFromObject';

import { checkIsServicesQueryEnabled } from './_services/checkIsServicesQueryEnabled/checkIsServicesQueryEnabled';
import { parseServices } from './_services/parseServices/parseServices';

interface HookParams {
  selectedPayerIds: string[];
  selectedServiceSubtypesIds: string[];
  setFormServicePreselectedValue: (servicesFromSubtypes: DropdownOption[]) => void;
}

export const useServicesFromSubtypesQuery = ({
  selectedPayerIds,
  selectedServiceSubtypesIds,
  setFormServicePreselectedValue,
}: HookParams) => {
  const { t } = useTranslation();
  const { form } = useOrderServiceFormContext();
  const { patient } = usePatientContext();

  const onClearThisDropdown = useCallback(() => {
    form.setValue(ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype as 'serviceFromSubtype', '');
  }, []);

  const {
    data,
    isLoading,
    refetch: refetchServices,
  } = useQuery(
    [QUERY_KEYS.CORE_SERVICE, createQueryKeyFromObject({ selectedServiceSubtypesIds })],
    coreService.getServicesFromSubtype({
      patientId: patient?.id,
      types: selectedServiceSubtypesIds,
      payers: selectedPayerIds,
    }),
    {
      enabled: checkIsServicesQueryEnabled({ selectedServiceSubtypesIds, patientId: patient?.id }),
      refetchOnWindowFocus: false,
      retry: false,
      onSettled: _data => {
        onClearThisDropdown();
        const parsedData = parseServices({ data: _data, t });
        setFormServicePreselectedValue(parsedData);
      },
    },
  );

  const servicesFromSubtypes = useMemo(() => parseServices({ data, t }), [data, t]);

  return { servicesFromSubtypes, servicesFromSubtypesFetchedData: data, servicesFromSubtypesLoading: isLoading, refetchServices };
};
