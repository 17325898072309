import { useEffect, useMemo, useState } from 'react';

import { QueryOptions } from '@testing-library/react';
import { isNil } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';

import { DEFAULT_TABLE_CONFIG } from 'components/UI/organisms/_tables/CustomTable/CustomTableDefaultConfig';
import { TableDataFilter } from 'constants/_types/TableDataFilter';
import { TableDataQueryFunction } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import { TableDataSort } from 'constants/_types/TableDataSort';
import generateQueryKeyFromObject from 'services/generateQueryKeyFromObject/generateQueryKeyFromObject';

// TODO probably will be reusable with CustomTable
// TODO extract common types
export type AcceptedCellValues = string | number | boolean | Date | null | undefined | Record<string, any>;

export type Config = {
  pageSize: number;
  initialPageNumber?: number;
  initialSort?: TableDataSort;
  initialFilters?: TableDataFilter[];
};

type UseTableDataParams<COLUMN_KEY extends string> = {
  config?: Partial<Config>;
  queryKeyBase: string;
  queryFunction: TableDataQueryFunction<COLUMN_KEY>;
  queryOptions?: QueryOptions;
};

type UseTableDataReturn<COLUMN_KEY extends string> = {
  query: UseQueryResult<TableDataQueryResult<COLUMN_KEY>>;
  isFetchingNewPage: boolean;
  // PAGINATION
  page: number;
  pagesCount: number;
  onNextPage: () => boolean;
  onPreviousPage: () => boolean;
  onExactPage: (newPage: number) => boolean;
  // SORTING
  sort: null | TableDataSort;
  onSort: (newSortKey: COLUMN_KEY) => TableDataSort | null;
  // FILTERS
  filters: null | TableDataFilter[];
  onFilter: (newFilters: TableDataFilter[]) => TableDataFilter[];
};

const firstPage = 1;

const validatePageChange = (currentPage: number, newPage: number, pagesCount: number | null): boolean => {
  if (newPage < firstPage) return false;
  if (newPage === currentPage) return false;
  if (!pagesCount) return false;
  return newPage <= pagesCount;
};

const pruneEmptyFilters = (filters: TableDataFilter[]): TableDataFilter[] => filters.filter(({ value }) => !isNil(value) && value !== '');

const useTableData = <COLUMN_KEY extends string>({
  config: configOverrides,
  queryKeyBase,
  queryFunction,
  queryOptions = {},
}: UseTableDataParams<COLUMN_KEY>): UseTableDataReturn<COLUMN_KEY> => {
  const config = useMemo(() => ({ ...DEFAULT_TABLE_CONFIG, ...configOverrides }), [configOverrides]);

  const [dataFetched, setDataFetched] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(
    !isNil(config.initialPageNumber) && config.initialPageNumber >= firstPage ? config.initialPageNumber : firstPage,
  );
  const [sort, setSort] = useState<TableDataSort | null>(config.initialSort || null);

  const [filters, setFilters] = useState<TableDataFilter[] | null>(config.initialFilters ? pruneEmptyFilters(config.initialFilters) : null);

  const query = useQuery(
    generateQueryKeyFromObject({ base: queryKeyBase, currentPageNumber, sort, filters }),
    () => queryFunction({ limit: config.pageSize, offset: config.pageSize * (currentPageNumber - 1), sort, filters }),
    { keepPreviousData: true, ...queryOptions },
  );

  // PAGINATION
  const pagesCount = useMemo(() => {
    if (!query.data) return 0;
    return Math.ceil(query.data.count / config.pageSize);
  }, [query.data, config.pageSize]);

  const onFirstDataLoad = () => {
    setDataFetched(true);
    if (currentPageNumber > pagesCount) {
      const isValid = validatePageChange(currentPageNumber, pagesCount, pagesCount);
      if (isValid) setCurrentPageNumber(pagesCount);
    }
  };

  useEffect(() => {
    if (!dataFetched && query.data) onFirstDataLoad();
  }, [query.data]);

  const safeSetCurrentPageNumber = (newPageGetter: (prevPageNumber: number) => number) => {
    let validation = true;
    setCurrentPageNumber(prevCurrentPageNumber => {
      const newPage = newPageGetter(prevCurrentPageNumber);
      validation = validatePageChange(prevCurrentPageNumber, newPage, pagesCount);

      return validation ? newPage : prevCurrentPageNumber;
    });

    return validation;
  };

  const onNextPage = () => safeSetCurrentPageNumber(prevPageNumber => prevPageNumber + 1);
  const onPreviousPage = () => safeSetCurrentPageNumber(prevPageNumber => prevPageNumber - 1);
  const onExactPage = (newPage: number) => safeSetCurrentPageNumber(() => newPage);

  // SORTING
  const onSort = (newSort: string): TableDataSort | null => {
    let sortToSet: TableDataSort | null = null;

    setSort((prevSort: TableDataSort | null) => {
      // CASE IF SORT BY NEW COLUMN
      if (prevSort === null || prevSort.key !== newSort) {
        sortToSet = { key: newSort, direction: 'asc' };
        return sortToSet;
      }
      // CASES IF SORT BY THE SAME COLUMN AS PREVIOUS
      if (prevSort.key === newSort) {
        if (prevSort.direction === 'asc') sortToSet = { key: prevSort.key, direction: 'desc' };
        if (prevSort.direction === 'desc') sortToSet = null;
      }

      return sortToSet;
    });

    return sortToSet;
  };

  // FILTERS
  const onFilter = (newFilters: TableDataFilter[]): TableDataFilter[] => {
    const filtersToSet: TableDataFilter[] = pruneEmptyFilters(newFilters);
    setFilters(filtersToSet);

    // Reset page number to 1 if filters are set
    if (filtersToSet.length && currentPageNumber !== 0) setCurrentPageNumber(0);
    return filtersToSet;
  };

  return {
    query,
    isFetchingNewPage: query.isFetching && query.isPreviousData,
    // PAGINATION
    page: currentPageNumber,
    pagesCount,
    onNextPage,
    onPreviousPage,
    onExactPage,
    // SORTING
    sort,
    onSort,
    // FILTERS
    filters,
    onFilter,
  };
};

export default useTableData;
