import { CSSObject } from 'tss-react';

import Theme from '../theme';

const tableRowStylesFactory = (theme: typeof Theme, color: { main: string; light: string }): CSSObject => ({
  background: color.main,
  '&:hover': {
    backgroundColor: `${color.light} !important`,
  },
  td: {
    color: theme.palette.primary.contrastText,
    fontWeight: '800',

    a: {
      color: theme.palette.primary.contrastText,
      transition: 'color .2s linear',

      ':hover': {
        color: theme.palette.grey[100],
      },
    },
  },
});

export default tableRowStylesFactory;
