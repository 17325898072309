import type { FC } from 'react';
import { useMemo } from 'react';

import { Card, List, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import faq from 'api/faq/faq';
import type { Faq } from 'api/faq/types';
import AccordionText from 'components/UI/atoms/AccordionText/AccordionText';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import helpMessages from 'translations/specific/help.mjs';

import useStyles from './FaqCard.styles';

const FaqCard: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { isLoading, data } = useQuery(QUERY_KEYS.FAQ, faq.getFaq(), { placeholderData: [] });

  const faqList = useMemo(() => sortBy(data, ['ordering']) as Faq[], [data]);

  return (
    <Card className={classes.root}>
      <ElementWithLoader isLoading={isLoading}>
        <Typography className={classes.header} variant='subtitle1'>
          {t(helpMessages.faqHeader)}
        </Typography>
        {faqList.length > 0 ? (
          <List className={classes.list}>
            {faqList.map(({ ordering, translationArrayAnswer, translationArrayQuestion }) => (
              <AccordionText
                key={`${ordering}-${translationArrayQuestion}`}
                translationArrayAnswer={translationArrayAnswer}
                translationArrayQuestion={translationArrayQuestion}
              />
            ))}
          </List>
        ) : (
          <Typography align='center' component='p' variant='captionBold'>
            {t(helpMessages.noFaqListMessage)}
          </Typography>
        )}
      </ElementWithLoader>
    </Card>
  );
};

export default FaqCard;
