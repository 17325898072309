import { makeStyles } from 'tss-react/mui';

import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

const useStyles = makeStyles()(theme => ({
  inputsContainer: {
    display: 'grid',
    gridGap: theme.spacing(3, 2),
    alignContent: 'flex-start',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateAreas: `
      "${ORDER_SERVICE_FORM_INPUT_KEYS.country as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.country as string} . ."
      
      "${ORDER_SERVICE_FORM_INPUT_KEYS.postcode as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.city as string} ${
      ORDER_SERVICE_FORM_INPUT_KEYS.city as string
    } ."
      
      "${ORDER_SERVICE_FORM_INPUT_KEYS.street as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.street as string} ${
      ORDER_SERVICE_FORM_INPUT_KEYS.buildingNumber as string
    } ${ORDER_SERVICE_FORM_INPUT_KEYS.apartmentNumber as string}"
      
      "${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as string} ${
      ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as string
    } ."
    `,

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateAreas: `
        "${ORDER_SERVICE_FORM_INPUT_KEYS.country as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.country as string} ."

        "${ORDER_SERVICE_FORM_INPUT_KEYS.postcode as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.city as string} ${
        ORDER_SERVICE_FORM_INPUT_KEYS.city as string
      }"
        
        "${ORDER_SERVICE_FORM_INPUT_KEYS.street as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.street as string} ${
        ORDER_SERVICE_FORM_INPUT_KEYS.street as string
      }"
        
        "${ORDER_SERVICE_FORM_INPUT_KEYS.buildingNumber as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.apartmentNumber as string} ."
        
        "${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as string} ${
        ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as string
      }"
      `,
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "${ORDER_SERVICE_FORM_INPUT_KEYS.country as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.country as string}"
        "${ORDER_SERVICE_FORM_INPUT_KEYS.postcode as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.city as string}"
        "${ORDER_SERVICE_FORM_INPUT_KEYS.street as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.street as string}"
        "${ORDER_SERVICE_FORM_INPUT_KEYS.buildingNumber as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.apartmentNumber as string}"
        "${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as string} ${ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as string}"
      `,
    },
  },
}));

export default useStyles;
