import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

interface StyleProps {
  isDrawerOpened: boolean;
  mobileGridTemplateAreas: string;
  isVisibleMobileBasketSection: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, { isDrawerOpened, mobileGridTemplateAreas, isVisibleMobileBasketSection }) => {
  const sensitivePaddingLeftValue = isDrawerOpened ? LAYOUT_SIZES.SIDE_MENU.open : LAYOUT_SIZES.SIDE_MENU.closed;

  return {
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      right: 0,
      zIndex: theme.zIndex.drawer - 1,
      boxShadow: theme.shadows[9],
      [theme.breakpoints.down('sm')]: {
        zIndex: theme.zIndex.drawer + 1,
        bottom: LAYOUT_SIZES.BOTTOM_MENU.closed,
      },
    },

    mobileBasketContainer: {
      backgroundColor: theme.palette.background.default,
      display: 'none',
      transition: theme.transitions.create('padding-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down('md')]: {
        display: 'block',
        padding: theme.spacing(2, 3),
        paddingLeft: sensitivePaddingLeftValue + 16,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
      },
    },

    buttonsContainer: {
      background: theme.palette.background.default,
      display: 'flex',
      flexWrap: 'wrap-reverse',
      gap: theme.spacing(5),
      justifyContent: 'flex-end',
      padding: theme.spacing(5),
      paddingLeft: sensitivePaddingLeftValue + 16,

      [theme.breakpoints.down('md')]: {
        background: isVisibleMobileBasketSection ? theme.palette.grey[300] : theme.palette.background.default,
        padding: theme.spacing(3),
        paddingLeft: sensitivePaddingLeftValue + 16,
        gap: theme.spacing(3),
      },

      [theme.breakpoints.down('sm')]: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: mobileGridTemplateAreas,
        gridGap: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
  };
});

export default useStyles;
