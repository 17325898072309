import type { PatientUnassigned } from 'api/_types';

import type { PatientFormInput } from '../../_constants/patientFormKeys';
import { prepareAddressDataForUpdate } from '../prepareAddressDataForUpdate/prepareAddressDataForUpdate';
import { preparePersonalDataForUpdate } from '../preparePersonalDataForUpdate/preparePersonalDataForUpdate';

export const prepareDataForCreate = (rawData: PatientFormInput): PatientUnassigned => {
  const result: PatientUnassigned = {
    pesel: rawData.pesel as string,
    foreign_document: rawData.hasForeignDocument,
    personal_data: {
      ...preparePersonalDataForUpdate(rawData),
      main_address: {
        ...prepareAddressDataForUpdate(rawData),
      },
    },
  };
  if (result.foreign_document) {
    result.foreign_document_type = rawData.documentType;
    result.foreign_document_number = rawData.documentNumber;
    result.foreign_document_country = rawData.documentCountry;
  }

  return result;
};
