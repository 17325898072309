import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import users from 'api/users/users';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import type { SupportedLanguages } from 'constants/translations/_types';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import { setLanguage } from 'services/_language/setLanguage/setLanguage';
import languageMessages from 'translations/specific/languageMessages.mjs';

interface Params {
  handleClose: () => void;
}

export const useChangeUserLanguage = ({ handleClose }: Params) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useShowSnackbar();
  const { i18n } = useTranslation();

  const handleOnSuccess = useCallback(async () => {
    await queryClient.invalidateQueries(QUERY_KEYS.USER_DATA_REFRESH);
  }, []);

  const handleOnError = useCallback(() => {
    showSnackbar({
      variant: 'error',
      translationArray: languageMessages.errors.change_language,
    });
  }, []);

  const updateUserLanguageMutation = useMutation(QUERY_KEYS.LANGUAGE, users.updateUserLanguage(), {
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  const changeLanguage = useCallback(
    async (code: SupportedLanguages) => {
      await updateUserLanguageMutation.mutateAsync(code);
      await setLanguage(code, i18n);
      handleClose();
    },
    [setLanguage, handleClose],
  );

  return { isLoading: updateUserLanguageMutation.isLoading, changeLanguage };
};
