import { getFaq } from './getFaq/getFaq';

export const endpoints = {
  faq: 'api/v1/faq/',
};

const faq = {
  getFaq,
};

export default faq;
