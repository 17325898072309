import type { FC } from 'react';
import { useMemo } from 'react';

import { DatePicker, DateTimePicker } from '@mui/lab';
import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CalendarIcon from 'assets/icons/calendar.svg';
import InputFooter from 'components/UI/atoms/InputFooter/InputFooter';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import generalMessages from 'translations/common/general.mjs';

export type Props = {
  label: string;
  helperText?: string;
  error?: string;
  onChange: (value: any) => void;
  name?: string;
  value?: Date | null;
  maxDate?: Date;
  minDate?: Date;
  fullWidth?: boolean;
  timePicker?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  shouldDisableDate?: (date: Date) => boolean;
};

const InputDate: FC<Props> = ({
  label,
  helperText,
  error,
  onChange,
  value,
  maxDate,
  minDate,
  fullWidth = true,
  timePicker,
  name,
  ...rest
}) => {
  const { t } = useTranslation();

  const Component = useMemo(() => (timePicker ? DateTimePicker : DatePicker), [timePicker]);
  return (
    <FormControl error={!!error} fullWidth={fullWidth}>
      <Component
        cancelText={t(generalMessages.cancel)}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        inputFormat={timePicker ? DATE_FORMATS.INPUT_TIME : DATE_FORMATS.DISPLAY}
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        okText={t(generalMessages.ok)}
        renderInput={params => <TextField color='secondary' fullWidth={fullWidth} name={name} {...params} error={!!error} />}
        value={value || null}
        onChange={onChange}
        {...rest}
      />
      <InputFooter error={error} helperText={helperText} />
    </FormControl>
  );
};

export default InputDate;
