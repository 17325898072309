import { mapValues } from 'lodash';

import { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';

type FilterFunc = (clause: ClauseWithConsent) => boolean;

type Groups = {
  [key: string]: FilterFunc;
};

type GroupedClausesWithConsents = {
  [key: string]: ClauseWithConsent[];
};

type GroupClausesWithConsentsType = (clausesWithConsents: ClauseWithConsent[] | null, groups: Groups) => GroupedClausesWithConsents;

const groupClausesWithConsents: GroupClausesWithConsentsType = (clausesWithConsents, groups) => {
  const result: GroupedClausesWithConsents = mapValues({ ...groups }, () => []);
  if (!clausesWithConsents) return result;

  Object.entries(groups).forEach(([groupName, filter]) => {
    result[groupName].push(...clausesWithConsents.filter(filter));
  });

  return result;
};

export default groupClausesWithConsents;
