import generatePathName from 'services/generatePathName/generatePathName';

export const AUTH_PREFIX = 'auth';
export const SERVICES_PREFIX = 'services';
export const PROFESSIONAL_PREFIX = 'professional';
export const INTERNAL_PREFIX = '';

interface Paths {
  [key: string]: string;
}

const addPrefix = (paths: Paths, prefix: string): Paths =>
  Object.entries(paths).reduce((acc, [key, value]) => ({ ...acc, [key]: generatePathName([prefix, value], '/') }), {});

export const INTERNAL_PATHS = {
  ROOT: '',
  SYMPTOM_CHECKER: 'symptom-checker',
  ORDER_SERVICE: 'order-service',
  MY_PROFILE: 'my-profile',
  REPORTS: 'reports',
  REPORT_SINGLE: 'reports/:id',
  SETTINGS: 'settings',
  HELP: 'help',
};

export const SERVICES_PATHS = {
  SERVICES: '',
  SERVICES_SUBTYPE: 'subtypes/:typeId/:subtypeId',
  SERVICES_GROUP: 'group-services/:groupId',
  SERVICES_USER_PROFILE: 'user-profile/:profileId',
};

export const PROFESSIONAL_PATHS = {
  PROFESSIONAL_DASHBOARD: '',
  // ecare order
  PROFESSIONAL_ECARE_ORDER_LIST: 'e-care-orders',
  PROFESSIONAL_ECARE_ORDER_SINGLE: 'e-care-orders/:orderId',
  // ecare sos alarms
  PROFESSIONAL_ECARE_ALARMS_LIST: 'e-care-alarms',
  PROFESSIONAL_ECARE_ALARM_SINGLE: 'e-care-alarms/:alarmId',
  // ecare technical issues
  PROFESSIONAL_ECARE_TECHNICAL_ISSUES_LIST: 'technical-issues',
  PROFESSIONAL_ECARE_TECHNICAL_ISSUE_SINGLE: 'technical-issues/:issueId',
  // patient profile
  PROFESSIONAL_PATIENTS: 'patients',
  PROFESSIONAL_PATIENT_SINGLE: 'patients/:patientId',
  // ecare phone calls
  PROFESSIONAL_ECARE_PHONE_CALLS_LIST: 'e-care-phone-calls',
  PROFESSIONAL_ECARE_PHONE_CALL_SINGLE: 'e-care-phone-calls/:phoneCallId',
};

export const AUTH_PATHS = {
  AUTH_LOGIN: 'login',
  AUTH_REGISTER: 'register',
  AUTH_REGISTER_SUCCESS: 'register-success',
  AUTH_RESET_PASSWORD_REQUEST: 'reset-password-request',
  AUTH_RESET_PASSWORD_FORM: 'reset-password/:uid/:token',
  AUTH_RESET_PASSWORD_SUCCESS: 'reset-password-request-success',
  AUTH_RESET_PASSWORD_FORM_SUCCESS: 'reset-password-success',
  AUTH_ACTIVATE_ACCOUNT: 'activate-account/:uid/:token',
};

const PATHS: Paths = {
  ...addPrefix(INTERNAL_PATHS, INTERNAL_PREFIX),
  ...addPrefix(AUTH_PATHS, AUTH_PREFIX),
  ...addPrefix(SERVICES_PATHS, SERVICES_PREFIX),
  ...addPrefix(PROFESSIONAL_PATHS, PROFESSIONAL_PREFIX),
};

export default PATHS;
