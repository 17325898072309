import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';

import { endpoint } from '../coreServiceSubtypes';
import type { CoreServiceSubtypeDetails, CoreServiceSubtypeDetailsDTO } from '../coreServiceSubtypes.types';
import { parseServiceSubtypeDetailsForFE } from './_services/parseServiceSubtypeDetailsForFE/parseServiceSubtypeDetailsForFE';

interface Params {
  serviceSubtypeId: string;
}

type GetServiceSubtypeDetailsQuery = () => Promise<CoreServiceSubtypeDetails>;

export const getServiceSubtypeDetails =
  ({ serviceSubtypeId }: Params) =>
  async () => {
    const { data } = await axios.get<GetServiceSubtypeDetailsQuery, DefaultApiResponse<CoreServiceSubtypeDetailsDTO>>(
      `${endpoint}${serviceSubtypeId}`,
    );

    return parseServiceSubtypeDetailsForFE(data);
  };
