import type { TFunction } from 'i18next';

import pesel from 'helpers/pesel/pesel';
import validationMessages from 'translations/common/validation.mjs';

interface Params {
  currentValue: string;
  t: TFunction;
  hasForeignDocument: boolean;
}

export const validatePesel = ({ currentValue, t, hasForeignDocument }: Params) => {
  if (hasForeignDocument) return true;

  if (!currentValue) return t(validationMessages.required_field);
  const { error } = pesel.validate(currentValue, true);
  switch (error) {
    case 'tooShort':
      return t(validationMessages.pesel.too_short);
    case 'tooLong':
      return t(validationMessages.pesel.too_long);
    case 'invalid':
      return t(validationMessages.pesel.invalid);
    case 'past':
      return t(validationMessages.pesel.past);
    default:
      return true;
  }
};
