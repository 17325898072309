import type { FC } from 'react';

import { Button } from '@mui/material';

import type { FormActionButtonProps } from 'storages/bottomFixedContainerStorage';

import useStyles from './GlobalFormButton.styles';

export type Props = FormActionButtonProps;

const GlobalFormButton: FC<Props> = ({ label, action, gridArea, disabled }) => {
  const { classes } = useStyles({ gridArea });

  if (!gridArea) return null;

  return (
    <Button className={classes.globalFormButton} disabled={disabled} variant='outlined' onClick={action}>
      {label}
    </Button>
  );
};

export default GlobalFormButton;
