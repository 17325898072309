import { makeStyles } from 'tss-react/mui';

import { ORDER_REFERRAL_AREA } from '../../_constants/gridAreas';

const useStyles = makeStyles()(theme => ({
  orderReferral: {
    gridArea: ORDER_REFERRAL_AREA,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
