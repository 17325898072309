import { FC } from 'react';

import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import usePatienceInfo from 'components/UI/organisms/ProfessionalECareAlarmPersonalData/_hooks/usePatienceInfo/usePatienceInfo';

import useStyles from './PatientHealthDetailsNote.styles';

type Props = {
  patientId: number;
  isClosed?: boolean;
};

const PatientHealthDetailsNote: FC<Props> = ({ patientId, isClosed }) => {
  const { classes } = useStyles();
  const healthData = usePatienceInfo({ patientId, hasHistory: true, isClosed });

  return (
    <div className={classes.container}>
      <DefinitionList
        columns={2}
        customClasses={{
          root: classes.definitionListRoot,
          listItemClass: classes.noPadding,
        }}
        definitions={healthData}
        fontSize='small'
      />
    </div>
  );
};
export default PatientHealthDetailsNote;
