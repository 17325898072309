import type { FC, ReactNode } from 'react';

import { Card } from '@mui/material';

import useStyles from './TableContainer.styles';

interface Props {
  minHeight?: number;
  maxHeight?: number;
  pagination?: ReactNode;
}

const TableContainer: FC<Props> = ({ minHeight, maxHeight, pagination: Pagination, children }) => {
  const { classes } = useStyles({ minHeight, maxHeight });

  return (
    <Card className={classes.root}>
      <div>{children}</div>
      {Pagination && Pagination}
    </Card>
  );
};

export default TableContainer;
