import type { ChangeEvent } from 'react';
import { useCallback } from 'react';

import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import type { CheckFileSizeCoreParams, CheckFileTypeCoreParams } from 'services/_fileProcessing/_types/params';
import { verifyUploadedFile } from 'services/_fileProcessing/verifyUploadedFile/verifyUploadedFile';

interface HookParams {
  proceedingCallback: (file: File) => void;
  config?: {
    fileType?: CheckFileTypeCoreParams;
    fileSize?: CheckFileSizeCoreParams;
  };
}

export const useProcessUploadedFile = ({ proceedingCallback, config }: HookParams) => {
  const { showSnackbar } = useShowSnackbar();

  return useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const file = target.files?.[0];
    if (!file) return;

    const isOk = verifyUploadedFile({
      target,
      file,
      config: {
        showSnackbar,
        fileType: config?.fileType,
        fileSize: config?.fileSize,
      },
    });

    if (!isOk) return;
    proceedingCallback(file);
  }, []);
};
