import type { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

type Size = 'medium' | 'small';

const useStyles = makeStyles<{ size: Size }>()((theme, { size }) => {
  const { startAdornmentSvg, input, inputRoot, startAdornment } = ((): {
    startAdornmentSvg: CSSObject;
    input: CSSObject;
    inputRoot: CSSObject;
    startAdornment: CSSObject;
  } => {
    const base = {
      startAdornment: {},
      startAdornmentSvg: {},
      input: {},
      inputRoot: {},
    };
    switch (size) {
      case 'small':
        return {
          ...base,
          startAdornment: {
            marginRight: theme.spacing(0),
            marginLeft: theme.spacing(0.25),
          },
          startAdornmentSvg: {
            width: '1.15rem',
            height: '1.15rem',
          },
          input: {
            padding: theme.spacing(0.5),
          },
          inputRoot: {
            paddingLeft: theme.spacing(0.5),
            fontSize: '0.675rem',
            height: '28px',
          },
        };
      case 'medium':
      default:
        return base;
    }
  })();

  return {
    startAdornment: {
      ...startAdornment,
      '& svg': {
        ...startAdornmentSvg,
      },
    },
    input: {
      ...input,
    },
    inputRoot: {
      ...inputRoot,
    },
  };
});

export default useStyles;
