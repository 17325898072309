import patientAttachment from 'api/patientAttachment/patientAttachment';
import { downloadFile } from 'helpers/downloadFile/downloadFile';

interface Params {
  attachmentId: number;
  patientId: number;
  fileName: string;
}

export const downloadFileFromUrl = async ({ attachmentId, fileName, patientId }: Params) => {
  const fileData = await patientAttachment.getPatientAttachment({ attachmentId, patientId })();
  if (!fileData) return;

  downloadFile(fileData.fileUrl, fileName);
};
