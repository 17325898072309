import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';

import { Routes, useLocation, matchPath } from 'react-router-dom';

import routes from 'constants/router/routes';
import type { NestedRoute } from 'constants/router/routes';
import useCurrentPathConfigStorage from 'storages/currentPathConfigStorage';

type Props = {
  children: ReactNode;
};

const RoutesWrapper: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { setBasePathConfig } = useCurrentPathConfigStorage();
  useEffect(() => {
    const match = routes.allRoutes.find(route => matchPath(route.path, pathname));
    setBasePathConfig(match as NestedRoute);
  }, [pathname]);
  return <Routes>{children}</Routes>;
};

export default RoutesWrapper;
