import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import PasswordValidation from 'components/UI/molecules/PasswordValidation/PasswordValidation';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import usePasswordChangeMutation from 'hooks/_mutations/usePasswordChangeMutation/usePasswordChangeMutation';
import authMessages from 'translations/common/auth.mjs';
import generalMessages from 'translations/common/general.mjs';
import passwordChangeReminder from 'translations/specific/passwordChangeReminder.mjs';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

type Props = {
  isOpen: boolean;
  close: () => void;
  closeAndPostpone?: () => void;
};

export type PasswordChangeFormInput = {
  current_password: string;
  new_password: string;
  re_new_password: string;
};

const FORM_ID = 'PasswordChangeDialog_form';

const PasswordChangeDialog: FC<Props> = ({ isOpen, close, closeAndPostpone }) => {
  const { t } = useTranslation();
  const [passwordValidators, setPasswordValidators] = useState<boolean>(false);

  const { control, handleSubmit, watch, getValues, setError, formState } = useForm<PasswordChangeFormInput>();
  watch();

  const { passwordChangeMutate, isPasswordChangeMutating, passwordChangeMutateGeneralErrors } = usePasswordChangeMutation({
    setError,
    close,
  });

  const handleClose = useMemo(() => closeAndPostpone ?? close, [closeAndPostpone]);

  const onSubmit: SubmitHandler<PasswordChangeFormInput> = async data => {
    passwordChangeMutate(data);
  };

  return (
    <DialogWrapper
      close={handleClose}
      defaultActionsConfig={{
        isLoading: isPasswordChangeMutating,
        isOkDisabled: isPasswordChangeMutating || !passwordValidators,
        okLabel: t(generalMessages.save),
        cancelLabel: t(passwordChangeReminder.alert.buttonRejectLabel),
        formId: FORM_ID,
        onClose: close,
      }}
      dialogProps={{
        maxWidth: 'sm',
      }}
      header={t(userSettingsMessages.passwordChange)}
      isOpen={isOpen}
    >
      <DialogFormWrapper
        formState={formState}
        globalErrors={passwordChangeMutateGeneralErrors}
        id={FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
      >
        <SimpleFormWrapper>
          <FormInputPassword control={control} label={t(authMessages.current_password)} name='current_password' required />
          <FormInputPassword control={control} label={t(authMessages.new_password)} name='new_password' required />
          <FormInputPassword control={control} label={t(authMessages.repeat_new_password)} name='re_new_password' required />
          <PasswordValidation
            password={getValues().new_password}
            rePassword={getValues().re_new_password}
            setIsValid={setPasswordValidators}
          />
        </SimpleFormWrapper>
      </DialogFormWrapper>
    </DialogWrapper>
  );
};

export default PasswordChangeDialog;
