import type { FC, ReactNode } from 'react';

import { CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import resolveAsArray from 'helpers/resolveAsArray/resolveAsArray';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './DefinitionList.styles';

export type DefinitionListValue = string | ReactNode | null | undefined;
export type DefinitionListFontSizeT = 'small' | 'medium' | 'large';

export type Props = {
  definitions:
    | {
        label: string;
        labelElement?: ReactNode;
        value: DefinitionListValue | DefinitionListValue[];
        id?: string | number;
        isLoading?: boolean;
      }[]
    | null;
  columns?: number;
  customClasses?: {
    root?: string;
    listItemClass?: string;
  };
  fontSize?: DefinitionListFontSizeT;
};

const DefinitionList: FC<Props> = ({ definitions, columns = 3, customClasses, fontSize = 'medium' }) => {
  const { t } = useTranslation();
  const { cx, classes } = useStyles({ columns, fontSize });

  if (!definitions) return <Typography>{t(generalMessages.noData)}</Typography>;

  return (
    <List className={cx(classes.columns, customClasses?.root)}>
      {definitions.map(({ label, labelElement, value, id, isLoading }, index) => (
        <ListItem className={cx(classes.listItem, customClasses?.listItemClass)} key={id || `${label}-${index}`}>
          <ListItemText
            primary={
              <Typography className={classes.label} component='label' variant='body2'>
                {label} {labelElement}
              </Typography>
            }
            secondary={resolveAsArray<DefinitionListValue>(value).map(singleValue => (
              <div className={classes.value} key={`${label}-${singleValue}`}>
                {isLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  <Typography className={classes.fontSize} color='secondary' component='p' variant='subtitle3'>
                    {singleValue ?? t(generalMessages.noData)}
                  </Typography>
                )}
              </div>
            ))}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DefinitionList;
