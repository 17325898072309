import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import { ListData, PaginatedApiResponse } from 'api/_types';
import PaginationButton from 'components/UI/organisms/_pagination/PaginationButton/PaginationButton';
import PaginationChevronButton from 'components/UI/organisms/_pagination/PaginationChevronButton/PaginationChevronButton';
import { PAGE_SIZE } from 'constants/pagination/paginationSettings';

import useStyles from './Pagination.styles';

interface Props {
  status: 'loading' | 'idle' | 'error' | 'success';
  data: PaginatedApiResponse<ListData> | undefined;
  isPreviousData: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const Pagination: FC<Props> = ({ status, data, isPreviousData, page, setPage }) => {
  const { classes } = useStyles();

  const onPrev = useCallback(() => setPage(prev => Math.max(prev - 1, 0)), [setPage]);
  const onNext = useCallback(() => setPage(prev => (data?.data.next ? prev + 1 : prev)), [data, setPage]);
  const onPageNumber = useCallback(
    (pageNumber: number) => {
      if (pageNumber !== page) setPage(pageNumber);
    },
    [page, setPage],
  );

  const pagesCount = useMemo(() => (data?.data?.count ? Math.ceil(data.data.count / PAGE_SIZE) : 0), [data]);
  const shouldDisplay = useMemo(() => !!pagesCount, [pagesCount]);

  return (
    <div className={classes.root}>
      {shouldDisplay && (
        <PaginationChevronButton handleClick={onPrev} icon={<ChevronLeftOutlinedIcon />} isDisabled={status === 'loading' || page === 0} />
      )}
      {Array.from(Array(pagesCount).keys()).map(possiblePage => (
        <PaginationButton key={possiblePage} page={page} possiblePage={possiblePage} onPageNumber={onPageNumber} />
      ))}
      {shouldDisplay && (
        <PaginationChevronButton
          handleClick={onNext}
          icon={<ChevronRightOutlinedIcon />}
          isDisabled={status === 'loading' || isPreviousData || !data?.data.next}
        />
      )}
    </div>
  );
};

export default Pagination;
