import { makeStyles } from 'tss-react/mui';

import { APP_LOGO_TOP_BAR_HEIGHT } from 'constants/styles/APP_LOGO_TOP_BAR_HEIGHT';

const useStyles = makeStyles<{ hasNoImage: boolean }>()((theme, { hasNoImage }) => ({
  root: {
    background: theme.palette.grey[700],
    minHeight: hasNoImage ? '100vh' : `calc(100vh - ${APP_LOGO_TOP_BAR_HEIGHT})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: hasNoImage ? 0 : APP_LOGO_TOP_BAR_HEIGHT,
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.grey[50],
    },
  },
  tile: {
    margin: theme.spacing(8),
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(4),
    maxWidth: '720px',
    width: '100%',
    background: theme.palette.grey[50],
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(8, 2),
      padding: theme.spacing(4, 2),
    },
  },
  logo: {
    margin: '0 auto',
    width: '150px',
  },
}));

export default useStyles;
