import { useState } from 'react';

type UseBooleanState = (initial?: boolean) => [boolean, () => void, () => void, () => void];

const useBooleanState: UseBooleanState = initial => {
  const [state, setState] = useState<boolean>(initial || false);
  const setTrue = () => setState(true);
  const setFalse = () => setState(false);
  const toggle = () => setState(prev => !prev);

  return [state, setTrue, setFalse, toggle];
};

export default useBooleanState;
