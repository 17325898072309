import type { FC, ReactNode } from 'react';

import type { FormState } from 'react-hook-form';

import FormGlobalError from 'components/UI/organisms/FormGlobalError/FormGlobalError';
import useFormScrollToError from 'hooks/useFormScrollToError/useFormScrollToError';

import useStyles from './SimpleFormWrapper.styles';

type Props = {
  children: ReactNode;
  globalErrors?: string[];
  formState?: FormState<any>;
};

const SimpleFormWrapper: FC<Props> = ({ children, globalErrors, formState }) => {
  const { classes } = useStyles();
  useFormScrollToError(formState);

  return (
    <div>
      <FormGlobalError errors={globalErrors} />
      <div className={classes.root}>{children}</div>
    </div>
  );
};

export default SimpleFormWrapper;
