const proECareTechnicalIssuesMessages = {
  title: ['proECareTechnicalIssuesMessages>>title', 'Problemy techniczne'],
  createDialogTitle: ['proECareTechnicalIssuesMessages>>createDialogTitle', 'Utwórz nowy problem techniczny'],
  editDialogTitle: ['proECareTechnicalIssuesMessages>>editDialogTitle', 'Edytuj problem techniczny'],
  baseIssueSectionTitle: ['proECareTechnicalIssuesMessages>>baseIssueSectionTitle', 'Podstawowe informacje o problemie'],
  deviceSectionTitle: ['proECareTechnicalIssuesMessages>>deviceSectionTitle', 'Dane urządzenia'],
  deviceElementLabel: {
    name: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>name', 'Nazwa urzadzenia'],
    dateFrom: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>dateFrom', 'Data rozpoczęcia korzystania z urządzenia'],
    dateTo: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>dateTo', 'Data zakończenia korzystania z urządzenia'],
    description: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>description', 'Opis urządzenia'],
    deviceType: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>deviceType', 'Typ urządzenia'],
    serialNumber: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>serialNumber', 'Numer seryjny'],
    simNumber: ['proECareTechnicalIssuesMessages>>deviceElementLabel>>simNumber', 'Numer sim'],
  },
  issueDetailLabel: {
    date: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>date', 'Data zdarzenia'],
    description: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>description', 'Opis'],
    fixDate: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>fixDate', 'Data usunięcia problemu'],
    fixDescription: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>fixDescription', 'Opis rozwiązania'],
    order: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>order', 'Zlecenie'],
    device: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>device', 'Urządzenie'],
    createdBy: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>createdBy', 'Utworzony przez'],
    createdAt: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>createdAt', 'Data utworzenia'],
    updatedAt: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>updatedAt', 'Data ostatniej aktualizacji'],
    lastSync: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>lastSync', 'Data wystawienia do CUS'],
    serviceRecipient: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>serviceRecipient', 'Odbiorca usługi'],
    serviceRecipientFirstName: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>serviceRecipientFirstName', 'Odbiorca usługi - Imię'],
    serviceRecipientLastName: ['proECareTechnicalIssuesMessages>>issueDetailLabel>>serviceRecipientLastName', 'Odbiorca usługi - Nazwisko'],
  },
  issueTableHeaders: {
    date: ['proECareTechnicalIssuesMessages>>issueTableHeaders>>date', 'Data zdarzenia'],
    fixDate: ['proECareTechnicalIssuesMessages>>issueTableHeaders>>fixDate', 'Data usunięcia problemu'],
    fixDescription: ['proECareTechnicalIssuesMessages>>issueTableHeaders>>fixDescription', 'Opis rozwiązania problemu'],
  },
  newIssue: {
    addNew: ['proECareTechnicalIssuesMessages>>newIssue>>addNew', 'Dodaj nowy problem'],
    searchHint: ['proECareTechnicalIssuesMessages>>newIssue>>searchHint', 'Wpisz numer seryjny urządzenia, aby wyszukać'],
  },
  form: {
    description: ['proECareTechnicalIssuesMessages>>form>>description', 'Jeśli udało Ci się rozwiązać problem, od razu dodaj rozwiązanie. W przeciwnym wypadku uzupełnij te informacje później w widoku szczegółu Problemu.'],
  }
}

export default proECareTechnicalIssuesMessages
