import { FC, ReactNode, useEffect, useMemo } from 'react';

import { Button, Collapse, Paper } from '@mui/material';
import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SelectedFilters from 'components/UI/molecules/SelectedFilters/SelectedFilters';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputDropdown from 'components/UI/organisms/_formInputs/FormInputDropdown/FormInputDropdown';
import FormInputNumber from 'components/UI/organisms/_formInputs/FormInputNumber/FormInputNumber';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import FormInputTextHidden from 'components/UI/organisms/_formInputs/FormInputTextHidden/FormInputTextHidden';
import DateRangePicker from 'components/UI/organisms/DateRangePicker/DateRangePicker';
import { AvailableFilter, FilterType } from 'constants/_types/AvailableFilter';
import type { TableDataAcceptedFilterTypes, TableDataFilter } from 'constants/_types/TableDataFilter';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import tableMessages from 'translations/common/table.mjs';

import useStyles from './CustomFilters.styles';

export type Props = {
  availableFilters: AvailableFilter[];
  onFilter: (newFilters: TableDataFilter[]) => void;
  children?: ReactNode;
  wrapperClassName?: string;
  filters?: TableDataFilter[] | null;
  isFilterOpen?: boolean;
};

const prepareDefaults = (availableFilters: AvailableFilter[]): Record<string, TableDataAcceptedFilterTypes> => {
  const getFallback = (type: FilterType) => {
    switch (type) {
      case 'boolean':
        return false;
      default:
        return '';
    }
  };
  return availableFilters.reduce<Record<string, TableDataAcceptedFilterTypes>>((accumulator, current) => {
    accumulator[current.key] = isNil(current.initialValue) ? getFallback(current.type) : current.initialValue;
    return accumulator;
  }, {} as Record<string, TableDataAcceptedFilterTypes>);
};

export const testId = createTestIdObject('CustomFilters', {
  toggleButton: 'toggleButton',
  filtersTile: 'filtersTile',
  submitButton: 'submitButton',
});

const CustomFilters: FC<Props> = ({ onFilter, availableFilters, wrapperClassName, filters, children, isFilterOpen }) => {
  const { t } = useTranslation();
  const [isOpen, , , toggleIsOpen] = useBooleanState(false);

  const { setValue, control, handleSubmit, resetField, register } = useForm({
    defaultValues: prepareDefaults(availableFilters),
  });

  const onSubmit = async (rawData: Record<string, TableDataAcceptedFilterTypes>) => {
    const filtersToSet = Object.entries(rawData).map(([key, value]) => ({ key, value }));
    onFilter(filtersToSet);
  };

  const { classes } = useStyles();

  const Inputs = useMemo(
    () =>
      availableFilters.map(filter => {
        switch (filter.type) {
          case 'dateRange':
            return (
              <DateRangePicker
                control={control}
                hasTooltip={filter.hasTooltip}
                name={filter.key}
                register={register}
                setValue={setValue}
                text={filter.text}
                tooltipText={filter.tooltipText}
              />
            );
          case 'date':
            return <FormInputDate control={control} label={t(filter.label)} name={filter.key} />;
          case 'string':
            return <FormInputText control={control} label={t(filter.label)} name={filter.key} />;
          case 'boolean':
            return <FormInputCheckbox control={control} label={t(filter.label)} name={filter.key} />;
          case 'number':
            return <FormInputNumber control={control} label={t(filter.label)} name={filter.key} />;
          case 'dropdown':
            return <FormInputDropdown control={control} label={t(filter.label)} name={filter.key} options={filter.options} />;
          case 'hidden':
            return <FormInputTextHidden control={control} label={t(filter.label)} name={filter.key} />;
          default:
            return exhaustiveGuard(filter);
        }
      }),
    [availableFilters],
  );

  useEffect(() => {
    handleSubmit(onSubmit)();
  }, [availableFilters]);

  const onFilterClearFactory = (key: string) => {
    resetField(key, { defaultValue: '' });
    handleSubmit(onSubmit)();
  };

  return (
    <div className={wrapperClassName}>
      <div className={classes.topPanel}>
        {children || null}
        {isFilterOpen === undefined && (
          <Button data-testid={testId.toggleButton} size='small' variant='outlined' onClick={toggleIsOpen}>
            {isOpen ? t(tableMessages.closeFilters) : t(tableMessages.openFilters)}
          </Button>
        )}
      </div>
      <Collapse
        classes={{
          root: classes.collapse,
        }}
        in={isOpen || isFilterOpen}
      >
        <Paper className={classes.paper} data-testid={testId.filtersTile} elevation={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.grid}>
              {Inputs}
              <Button className={classes.rowEnd} data-testid={testId.submitButton} size='small' type='submit' variant='contained'>
                {t(tableMessages.applyFilters)}
              </Button>
            </div>
          </form>
        </Paper>
      </Collapse>
      <SelectedFilters availableFilters={availableFilters} filters={filters} onDelete={onFilterClearFactory} />
    </div>
  );
};

export default CustomFilters;
