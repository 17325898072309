import { FC, useMemo } from 'react';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ECarePhoneCall from 'api/eCarePhoneCall/eCarePhoneCall';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import theme from 'styles/theme';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

import ECarePhoneCallECareOrderDropdown from '../../molecules/ECarePhoneCallECareOrderDropdown/ECarePhoneCallECareOrderDropdown';
import ECarePhoneCallIncomingCallDropdown from '../../molecules/ECarePhoneCallIncomingCallDropdown/ECarePhoneCallIncomingCallDropdown';
import ECarePhoneCallStatusDropdown from '../../molecules/ECarePhoneCallStatusDropdown/ECarePhoneCallStatusDropdown';
import useOperatorData from './_hooks/useOperatorData/useOperatorData';
import usePersonalData from './_hooks/usePersonalData/usePersonalData';
import usePhoneCalls from './_hooks/usePhoneCalls/usePhoneCalls';
import useStyles from './ProfessionalEcarePhoneCallInfo.styles';

const ProfessionalEcarePhoneCallInfo: FC = () => {
  const { t } = useTranslation();
  const { phoneCallId } = useParams() as unknown as { phoneCallId: string };
  const { classes } = useStyles();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_PHONE_CALL, phoneCallId], [phoneCallId]);
  const { data } = useQuery(queryKey, ECarePhoneCall.getEcarePhoneCall(phoneCallId), {
    refetchOnWindowFocus: false,
  });

  const phoneCalls = usePhoneCalls(data);
  const personalData = usePersonalData(data?.patient);
  const operatorData = useOperatorData(data?.operator);

  return (
    <div className={classes.container}>
      <div className={classes.borderRight}>
        <SectionWithTitle title=''>
          <Box sx={{ display: 'grid', gap: 2 }}>
            <ECarePhoneCallStatusDropdown phoneCallId={phoneCallId} />
            <ECarePhoneCallIncomingCallDropdown phoneCallId={phoneCallId} />
            <ECarePhoneCallECareOrderDropdown
              callDate={data?.callDate}
              currentOrderId={data?.order}
              isEnabled={!!data?.order && data?.callType === 'WYCHODZACE'}
              phoneCallId={phoneCallId}
              queryKey={queryKey}
              serviceRecipient={data?.serviceRecipient}
            />
          </Box>
        </SectionWithTitle>
      </div>
      <div className={classes.borderRight}>
        <SectionWithTitle title={t(ecarePhoneCallMessages.personalData)}>
          <DefinitionList columns={2} definitions={personalData} fontSize='small' />
        </SectionWithTitle>
      </div>
      <div className={classes.borderRight}>
        <SectionWithTitle
          title={t(ecarePhoneCallMessages.operator.title)}
          titleIcon={
            <Tooltip enterDelay={750} title={t(ecarePhoneCallMessages.bitrix.operator) as string}>
              <InfoIcon sx={{ width: '15px', marginLeft: theme.spacing(0.5) }} />
            </Tooltip>
          }
        >
          <DefinitionList columns={2} definitions={operatorData} fontSize='small' />
        </SectionWithTitle>
      </div>
      <div>
        <SectionWithTitle title={t(ecarePhoneCallMessages.title)}>
          <DefinitionList columns={2} definitions={phoneCalls} fontSize='small' />
        </SectionWithTitle>
      </div>
    </div>
  );
};

export default ProfessionalEcarePhoneCallInfo;
