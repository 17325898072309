import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import CardWithSeparatedTitle from 'components/UI/molecules/CardWithSeparatedTitle/CardWithSeparatedTitle';
import DetailsKindSection from 'components/UI/molecules/DetailsKindSection/DetailsKindSection';
import { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import { CUMULATIVE_CORE_SERVICE_KINDS } from 'constants/coreServices/cumulativeCoreServiceKinds';
import { FORM_CARD_MAX_WIDTH } from 'constants/styles/FORM_CARD_MAX_WIDTH';
import { useServicesBasketStorage } from 'storages/serviceBasketStorage/servicesBasketStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import OrderDetailsConfirmationDialog from '../OrderDetailsConfirmationDialog/OrderDetailsConfirmationDialog';
import { useOrderServiceFormSubmission } from './_hooks/useOrderServiceFormSubmission/useOrderServiceFormSubmission';
import useStyles from './OrderDetails.styles';

const OrderDetails: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isConfirmationModalOpened, handleCloseConfirmationModal } = useOrderServiceFormContext();
  const { basketState } = useServicesBasketStorage();
  const { handleOnSubmit, isSubmitting, submissionErrors } = useOrderServiceFormSubmission();

  return (
    <CardWithSeparatedTitle maxWidth={FORM_CARD_MAX_WIDTH} title={t(orderServiceMessages.details.header)}>
      <div className={classes.sectionsContainer}>
        {basketState &&
          Object.entries(basketState).map(([coreServiceKind, { items }]) => (
            <DetailsKindSection
              isCumulative={CUMULATIVE_CORE_SERVICE_KINDS.includes(coreServiceKind as OrderServiceCoreServicesKinds)}
              items={items}
              key={coreServiceKind}
              kind={coreServiceKind as OrderServiceCoreServicesKinds}
            />
          ))}
      </div>
      {isConfirmationModalOpened && (
        <OrderDetailsConfirmationDialog
          close={handleCloseConfirmationModal}
          handleSubmit={handleOnSubmit}
          isOpened={isConfirmationModalOpened}
          isSubmitting={isSubmitting}
          submissionErrors={submissionErrors}
        />
      )}
    </CardWithSeparatedTitle>
  );
};

export default OrderDetails;
