import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useCusInfo from './_hooks/useCusInfo/useCusInfo';
import usePatienceInfo from './_hooks/usePatienceInfo/usePatienceInfo';
import usePersonalData from './_hooks/usePersonalData/usePersonalData';
import useStyles from './ProfessionalECareAlarmPersonalData.styles';

type Props = {
  eCareAlarmsData: ECareAlarm;
  patientId?: number | null;
};

const ProfessionalECareAlarmPersonalData: FC<Props> = ({ eCareAlarmsData, patientId }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const cusData = useCusInfo(eCareAlarmsData);
  const healthData = usePatienceInfo({ patientId, isClosed: eCareAlarmsData.sosStatus === 'CLOSED' });
  const patientData = usePersonalData(eCareAlarmsData);

  return (
    <CardContentContainer className={classes.cardContainer}>
      <div className={classes.container}>
        <div className={classes.borderRight}>
          <SectionWithTitle className={classes.alertSection} title={t(proECareAlarmMessages.columns.eCareData)}>
            <DefinitionList
              columns={4}
              customClasses={{
                listItemClass: classes.noPadding,
              }}
              definitions={cusData}
              fontSize='small'
            />
          </SectionWithTitle>
        </div>
        <div>
          <SectionWithTitle className={classes.alertSection} title={t(proECareAlarmMessages.columns.patientData)}>
            <DefinitionList
              columns={7}
              customClasses={{
                listItemClass: classes.noPadding,
              }}
              definitions={patientData}
              fontSize='small'
            />
          </SectionWithTitle>
        </div>
      </div>
      <DefinitionList
        columns={2}
        customClasses={{
          root: classes.definitionListRoot,
          listItemClass: classes.noPadding,
        }}
        definitions={healthData}
        fontSize='small'
      />
    </CardContentContainer>
  );
};

export default ProfessionalECareAlarmPersonalData;
