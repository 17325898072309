import { CSSObject, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { DRAWER_PATIENT_INFO_WIDTH } from 'constants/styles/DRAWER_PATIENT_INFO_WIDTH';
import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const openedMixin = (theme: Theme): CSSObject => ({
  width: LAYOUT_SIZES.SIDE_MENU.open,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: LAYOUT_SIZES.SIDE_MENU.closed,
});

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  caption: {
    width: isOpen ? '100%' : DRAWER_PATIENT_INFO_WIDTH,
  },
  drawer: {
    width: LAYOUT_SIZES.SIDE_MENU.open,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isOpen &&
      ({
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      } as Record<any, any>)),
    ...(!isOpen &&
      ({
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      } as Record<any, any>)),
  },
  drawerInner: {
    padding: theme.spacing(0, 2),
    background: theme.palette.background.paper,
  },
  drawerOptions: {
    margin: '0 auto',
    display: 'grid',
    gridGap: theme.spacing(isOpen ? 4 : 1.5),
    transition: theme.transitions.create('grid-gap', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(isOpen ? 2 : 0),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: LAYOUT_SIZES.HEADER.xxl,
    [theme.breakpoints.down('xl')]: {
      height: LAYOUT_SIZES.HEADER.xl,
    },
  },
  logo: {
    width: '100%',
  },
}));

export default useStyles;
