import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'grid',
    gap: theme.spacing(1),
  },

  card: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
