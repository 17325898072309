import { useEffect } from 'react';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import type { DateSoonOptions } from 'constants/_types/SelectedServices';

import { clearOppositeGroupOfDateInputs } from '../../_services/clearOppositeGroupOfDateInputs/clearOppositeGroupOfDateInputs';

interface HookParams {
  dateSoonName: string;
  dateSoonValue: DateSoonOptions | '';
  dateFromName: string;
  dateFromValue: string;
  dateUntilName: string;
  dateUntilValue: string;
}

export const useInputsGroupsRelation = ({
  dateSoonName,
  dateSoonValue,
  dateFromName,
  dateFromValue,
  dateUntilName,
  dateUntilValue,
}: HookParams) => {
  const { form } = useOrderServiceFormContext();

  useEffect(() => {
    // clears the date range
    clearOppositeGroupOfDateInputs({
      form,
      listeningOnValues: { valueOne: dateSoonValue },
      inputKeysToClear: { inputKeyOneToClear: dateFromName, inputKeyTwoToClear: dateUntilName },
      defaultValue: null,
    });
  }, [dateSoonValue]);

  useEffect(() => {
    // clears the date radio
    clearOppositeGroupOfDateInputs({
      form,
      listeningOnValues: { valueOne: dateFromValue, valueTwo: dateUntilValue },
      inputKeysToClear: { inputKeyOneToClear: dateSoonName },
      defaultValue: '',
    });
  }, [dateFromValue, dateUntilValue]);
};
