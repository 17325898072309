import { useCallback, type FC, type MouseEvent } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';

import ReportsTableCardActionButtons from 'components/UI/organisms/_tables/_reports/ReportsTableCardActionButtons/ReportsTableCardActionButtons';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { useMenuState } from 'hooks/useMenuState/useMenuState';

import useStyles from './ReportsTableCardActions.styles';

export interface MenuData {
  id: number;
  createdAtTime: string;
  isHidden: boolean;
  isMedicalRecord: boolean;
  reportFileId: number;
}

export interface Props {
  menuData: MenuData;
}

const ReportsTableCardActions: FC<Props> = ({ menuData }) => {
  const { classes } = useStyles();

  const { isMenuOpened, anchorElement, handleMenuClick, handleMenuClose } = useMenuState();

  const onClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleMenuClick(e);
  }, []);

  const onMenuClose = useCallback((e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    handleMenuClose();
  }, []);

  return (
    <>
      <IconButton aria-label='more' className={classes.cardActionButton} onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <ReportsTableCardActionButtons
        anchorElement={anchorElement}
        handleMenuClose={onMenuClose}
        isMenuOpened={isMenuOpened}
        menuData={menuData}
        queryKey={QUERY_KEYS.REPORTS_LIST}
      />
    </>
  );
};

export default ReportsTableCardActions;
