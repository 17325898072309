import { useMemo } from 'react';

import { get, isNil, sortBy } from 'lodash';
import { useQueries, useQuery } from 'react-query';

import type { Clause, Consent } from 'api/_types';
import clauses from 'api/clauses';
import consents from 'api/consents';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { CLAUSE_ACCEPTANCE } from 'constants/_types/ClauseAcceptance';
import type { ClauseLocationsDTO } from 'constants/_types/ClauseLocations';
import type { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import type { SupportedLanguages } from 'constants/translations/_types';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';

type Options = {
  clausesQueryOptions?: object;
  shouldGetConsents?: boolean;
  onlySingle?: boolean;
  disabled?: boolean;
};

type UseClausesConsentsQueryReturn = {
  clausesWithConsents: ClauseWithConsent[] | null;
  locationsMapping: { [key in ClauseLocationsDTO]?: number };
  queryStatuses: {
    locations: { isLoading: boolean };
    clauses: { isLoading: boolean };
    consents: { isLoading: boolean };
    all: { isLoading: boolean };
  };
};

const prepareLangKey = (prefix: string, lang: SupportedLanguages) => `${prefix}_${lang}`;

const getLocationId = (
  locationsMapping: { [key in ClauseLocationsDTO]?: number },
  locationString: ClauseLocationsDTO,
): number | undefined => locationsMapping[locationString];

const consentExists = (clause: Clause, consentsToCheck?: Consent[]) => {
  // user decision:
  // if any not declined consent exist - user consent exists

  // return bool when clause is singleAcceptance and has user decision
  // return null when clause has no decision - is multipleAcceptance or is new (user don't agree or disagree for consent)
  if (clause.acceptance_type !== CLAUSE_ACCEPTANCE.singleAcceptance) return null;
  if (!consentsToCheck) return null;
  const matchingConsentsToCheck = consentsToCheck.filter(({ clause: clauseConsentId }) => clause.id === clauseConsentId);
  if (!matchingConsentsToCheck.length) return null;
  return matchingConsentsToCheck.some(({ declined }) => !declined);
};

const useClausesConsentsQuery = (locations: ClauseLocationsDTO[], options?: Options): UseClausesConsentsQueryReturn => {
  const languageCode = useLanguageCode();
  const { userInfo } = useAuthContext();
  const clauseQueryOptions = options?.clausesQueryOptions || {};

  const locationsData = useQuery(QUERY_KEYS.CLAUSES_LOCATION, clauses.getClauseLocations(), {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
    enabled: !options?.disabled,
  });

  const locationsMapping = useMemo(() => {
    const result: { [key in ClauseLocationsDTO]?: number } = {};
    const locationsFromAPI = locationsData.data?.data.results;
    if (locationsFromAPI) {
      locationsFromAPI.forEach(({ id, name }) => {
        result[name as ClauseLocationsDTO] = id;
      });
    }

    return result;
  }, [locationsData]);

  const clausesData = useQueries(
    locations.length
      ? locations.map(location => {
          const locationId = getLocationId(locationsMapping, location);
          return {
            queryKey: [QUERY_KEYS.CLAUSES, location],
            queryFn: clauses.getClausesForLocation(locationId),
            refetchOnWindowFocus: false,
            enabled: !isNil(locationId),
            ...clauseQueryOptions,
          };
        })
      : [
          {
            queryKey: [QUERY_KEYS.CLAUSES],
            queryFn: clauses.getClauses(),
            refetchOnWindowFocus: false,
            ...clauseQueryOptions,
          },
        ],
  );

  const isEnabled = useMemo(() => {
    if (!userInfo.isAuthorized) return false;
    if (!options) return true;
    if (options.disabled) return false;

    return options.shouldGetConsents;
  }, [userInfo, options]);

  const userConsentData = useQuery(QUERY_KEYS.CONSENTS, consents.getConsents(), {
    enabled: isEnabled,
    refetchOnWindowFocus: false,
  });

  const clausesWithConsents: null | ClauseWithConsent[] = useMemo(() => {
    if (!clausesData) return null;
    if (clausesData.some(({ isLoading }) => isLoading)) return null;
    if (userConsentData.isLoading) return null;
    if (locationsData.isLoading) return null;

    const result: ClauseWithConsent[] = [];

    clausesData.forEach(location => {
      if (location.data) {
        const queryURL = new URL(location.data.request.responseURL);
        const locationId = queryURL.searchParams.get('slots__location');
        const clausesOrdered = sortBy(location.data.data, ['slot']);
        clausesOrdered.forEach(clause => {
          const matchedConsent =
            userConsentData.data &&
            userConsentData.data.data.find(({ clause: clauseConsentId, declined }) => clauseConsentId === clause.id && !declined);
          const hasUserConsent = consentExists(clause, userConsentData.data?.data);
          result.push({
            acceptanceType: clause.acceptance_type,
            company: clause.company,
            file: get(clause, prepareLangKey('file', languageCode), ''),
            hasFile: clause.has_file,
            hasUserConsent,
            clauseId: Number(clause.id),
            consentId: matchedConsent?.id,
            isObligatory: clause.is_obligatory,
            slot: clause.slot,
            text: get(clause, prepareLangKey('text', languageCode), ''),
            type: clause.type,
            locationId: Number(locationId),
            updatedAt: matchedConsent?.updated_at ? new Date(matchedConsent?.updated_at) : null,
          });
        });
      }
    });
    if (options?.onlySingle) {
      return result.filter(({ acceptanceType }) => acceptanceType === CLAUSE_ACCEPTANCE.singleAcceptance);
    }

    return result;
  }, [clausesData, userConsentData, locationsData, languageCode, options]);

  return {
    clausesWithConsents,
    locationsMapping,
    queryStatuses: {
      locations: { isLoading: locationsData.isLoading },
      consents: { isLoading: userConsentData.isLoading },
      clauses: { isLoading: clausesData.some(({ isLoading }) => isLoading) },
      all: { isLoading: [...clausesData, locationsData, userConsentData].some(({ isLoading }) => isLoading) },
    },
  };
};

export default useClausesConsentsQuery;
