import axios from 'axios';

import { DefaultApiResponse } from 'api/_types';
import type { ContactFormInput } from 'components/UI/organisms/ContactCard/_constants/inputKeys';

import { endpoints } from '../contactForm';
import { parseContactFormForBE } from '../parseContactFormForBE/parseContactFormForBE';
import type { ContactFormDataDto } from '../types';

type Response = ContactFormDataDto & { created_at: string; updated_at: string; id: number };
type PostContactFormQuery = (data: ContactFormInput) => Promise<DefaultApiResponse<Response>>;

export const submitContactForm =
  (patientId: number | null, brandId: number | undefined): PostContactFormQuery =>
  data =>
    axios.post<PostContactFormQuery, DefaultApiResponse<Response>>(
      endpoints.postContactForm,
      parseContactFormForBE(data, patientId, brandId),
    );
