import type { FC } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import LinkWithQuery from 'components/UI/atoms/LinkWithQuery/LinkWithQuery';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import PATHS from 'constants/router/PATHS';
import useLoginMutation from 'hooks/_mutations/useLoginMutation/useLoginMutation';
import authMessages from 'translations/common/auth.mjs';
import loginMessages from 'translations/specific/login.mjs';

export type LoginFormInput = {
  login_identifier: string;
  password: string;
};

const LoginForm: FC = () => {
  const { t } = useTranslation();

  const { control, handleSubmit, watch, setError, formState } = useForm<LoginFormInput>();

  const { loginMutate, isLoginMutating, loginMutationErrors } = useLoginMutation({ setError });

  const loginIdentifierValue = watch('login_identifier');

  const onSubmit: SubmitHandler<LoginFormInput> = data => {
    loginMutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography align='center' component='h1' marginBottom={2} variant='h3'>
        {t(loginMessages.header)}
      </Typography>
      <SimpleFormWrapper formState={formState} globalErrors={loginMutationErrors}>
        <FormInputText control={control} label={t(authMessages.emailOrAuthIdentifier)} name='login_identifier' required />
        <FormInputPassword control={control} label={t(authMessages.password)} name='password' required />
        <Typography align='right' justifySelf='right' variant='caption'>
          <LinkWithQuery state={{ loginIdentifier: loginIdentifierValue }} to={`/${PATHS.AUTH_RESET_PASSWORD_REQUEST}`}>
            {t(loginMessages.forgot_password_link)}
          </LinkWithQuery>
        </Typography>
        <LoadingButton data-testid='submit_button' loading={isLoginMutating} type='submit' variant='contained'>
          {t(loginMessages.button_label)}
        </LoadingButton>
      </SimpleFormWrapper>
    </form>
  );
};

export default LoginForm;
