import { FC } from 'react';

import { Box, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QueryKey, useMutation, useQueryClient } from 'react-query';

import eCareOrder from 'api/eCareOrder/eCareOrder';
import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import { availableECareStatuses, eCareStatusDictionary, ECareStatusT } from 'components/UI/organisms/ECareList/ECareStatus';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';

import useStyles from './ECareOrderStatusDropdown.styles';

export type Props = {
  orderId: string;
  queryKey: QueryKey;
  value: ECareStatusT | null;
};

// https://miro.com/app/board/uXjVK3y8vRM=/
const statesFlow: Record<ECareStatusT, ECareStatusT[]> = {
  NEW: ['CLARIFICATION_REQUIRED', 'IN_PROGRESS'],
  CLARIFICATION_REQUIRED: ['IN_PROGRESS'],
  IN_PROGRESS: ['CLARIFICATION_REQUIRED', 'ACTIVE'],
  ACTIVE: ['ENDED'],
  ENDED: [],
  SUSPENDED: [],
};

const ECareOrderStatusDropdown: FC<Props> = ({ orderId, value, queryKey }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { palette } = useTheme();

  const queryClient = useQueryClient();

  const statusMutation = useMutation(eCareOrder.setStatusECareOrderData(orderId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      const currentData = queryClient.getQueryData(queryKey) as ECareOrder;
      queryClient.setQueryData(queryKey, { ...currentData, status: newStatus.status });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const colorResolver = (status: ECareStatusT) => {
    switch (status) {
      case 'ENDED':
        return palette.table.default.main;
      case 'CLARIFICATION_REQUIRED':
        return palette.table.clarify.main;
      case 'NEW':
        return palette.table.danger.main;
      case 'IN_PROGRESS':
        return palette.table.warning.main;
      case 'ACTIVE':
        return palette.table.success.main;
      case 'SUSPENDED':
        return palette.table.closed.main;
      default:
        return exhaustiveGuard(status);
    }
  };

  const onChange = ({ target }: SelectChangeEvent<{ status: ECareStatusT } | string>) => {
    statusMutation.mutate({ status: target.value as ECareStatusT });
  };

  const renderValue = (selectedValue: string | { status: string }) => {
    const stringSelectedValue = typeof selectedValue === 'string' ? selectedValue : selectedValue.status;
    const selectedValueTyped = stringSelectedValue as ECareStatusT;
    return (
      <Box
        className={classes.selectedMenuItem}
        sx={{
          color: colorResolver(selectedValueTyped),
          '&::before': {
            borderColor: colorResolver(selectedValueTyped),
          },
        }}
      >
        {t(eCareStatusDictionary[selectedValueTyped])}
      </Box>
    );
  };

  return (
    <Select className={classes.select} renderValue={renderValue} value={value || ''} onChange={onChange}>
      {availableECareStatuses.map(status => {
        const enabledOptions = statesFlow[value || 'NEW'];
        const disabled = !enabledOptions.includes(status);
        return (
          <MenuItem className={classes.menuItem} disabled={disabled} key={status} sx={{ color: colorResolver(status) }} value={status}>
            {t(eCareStatusDictionary[status])}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default ECareOrderStatusDropdown;
