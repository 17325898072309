import type { FC, ReactNode } from 'react';

import { CardContent, Divider } from '@mui/material';

import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';

import useStyles from './CardContentContainer.styles';

interface Props {
  children: ReactNode;
  isLoading?: boolean;
  isLastSection?: boolean;
  className?: string;
}

const CardContentContainer: FC<Props> = ({ children, isLoading, isLastSection = false, className }) => {
  const { classes, cx } = useStyles();

  return (
    <>
      <CardContent className={cx(classes.cardContent, className)}>
        <ElementWithLoader isLoading={isLoading || false}>{children}</ElementWithLoader>
      </CardContent>
      {!isLastSection && <Divider className={classes.sectionDivider} variant='middle' />}
    </>
  );
};

export default CardContentContainer;
