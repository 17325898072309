const clausesMessages = {
  acceptedClauseLink: ['clauses>>acceptedClauseLink', 'Zaakceptowany przez Ciebie regulamin znajduje się <a href="{{href}}">tutaj</a>'],
  cardTitle: ['clauses>>cardTitle', 'Zgody'],
  requiredClauseUpdate: ['clauses>>requiredClauseUpdate', 'Aktualizacja zgody obowiązkowej'],
  notRequiredClauseUpdate: ['clauses>>notRequiredClauseUpdate', 'Aktualizacja zgody nieobowiązkowej'],
  clausesChange: ['clauses>>clausesChange', 'Zarządzanie zgodami nieobowiązkowymi'],
  clausePreviousVersionAvailability: ['clauses>>clausePreviousVersionAvailability', 'Poprzednie wersje Twoich zgód są dostępne po kontakcie z infolinią.'],
  notAcceptAndLogout: ['clauses>>notAcceptAndLogout', 'Nie akceptuję i wylogowuję się'],
  manageFollowingClauses: ['clauses>>manageFollowingClauses', 'Możesz zarządzać następującymi zgodami'],
  dialogTitle: ['clauses>>dialogTitle', 'Zarządzaj zgodami'],
  noData: ['clauses>>noData', 'Brak klauzul do wyświetlenia'],
  fileLink: ['clauses>>fileLink', 'Zobacz plik'],
  acceptDate: ['clauses>>acceptDate', 'Data akceptacji: {{ acceptDate }}'],
};

export default clausesMessages;
