import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import { PartialRecord } from 'constants/_types/PartialRecord';
import { TableDataBaseParamsForQuery } from 'constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import {
  parseECareFormInputForCreate,
  parseECareFormInputForUpdate,
  parseECareTechnicalIssuesListForFE,
  parseSingleECareTechnicalIssuesForFE,
} from './eCareTechnicalIssues.parsers';
import {
  ECareTechnicalIssues,
  ECareTechnicalIssuesCreateInput,
  ECareTechnicalIssuesDTO,
  ECareTechnicalIssuesListFilters,
  ECareTechnicalIssuesUpdateInput,
} from './eCareTechnicalIssues.types';

const endpoint = '/api/v1/ecare-technical-problem/';

type GetECareOrdersQuery = (
  params: TableDataQueryFunctionParams<keyof ECareTechnicalIssues>,
) => Promise<TableDataQueryResult<keyof ECareTechnicalIssues>>;
type GetECareOrderQuery = () => Promise<ECareTechnicalIssues>;
type CreateECareOrderMutation = (formData: ECareTechnicalIssuesCreateInput) => Promise<ECareTechnicalIssues>;
type UpdateECareOrderMutation = (formData: ECareTechnicalIssuesUpdateInput) => Promise<ECareTechnicalIssues>;

const eCareTechnicalIssues = {
  getAllECareTechnicalIssues: (): GetECareOrdersQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery & PartialRecord<ECareTechnicalIssuesListFilters, string> = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      ...parseFiltersForBE<ECareTechnicalIssuesListFilters>(params.filters),
    };

    const { data } = await axios.get<GetECareOrdersQuery, PaginatedApiResponse<ECareTechnicalIssuesDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: paramsForBE,
        removeEmptyParams: true,
      }),
    );
    return { data: parseECareTechnicalIssuesListForFE(data.results), count: data.count };
  },
  getECareTechnicalIssue:
    (id: string | number): GetECareOrderQuery =>
    async () => {
      const { data } = await axios.get<GetECareOrderQuery, DefaultApiResponse<ECareTechnicalIssuesDTO>>(`${endpoint}${id}/`);

      return parseSingleECareTechnicalIssuesForFE(data);
    },
  createECareTechnicalIssue: (): CreateECareOrderMutation => async formData => {
    const dataInput = parseECareFormInputForCreate(formData);

    const { data } = await axios.post<CreateECareOrderMutation, DefaultApiResponse<ECareTechnicalIssuesDTO>>(endpoint, dataInput);

    return parseSingleECareTechnicalIssuesForFE(data);
  },
  patchECareTechnicalIssue:
    (id: string | number): UpdateECareOrderMutation =>
    async formData => {
      const dataInput = parseECareFormInputForUpdate(formData);

      const { data } = await axios.patch<UpdateECareOrderMutation, DefaultApiResponse<ECareTechnicalIssuesDTO>>(
        `${endpoint}${id}/`,
        dataInput,
      );

      return parseSingleECareTechnicalIssuesForFE(data);
    },
};
export default eCareTechnicalIssues;
