import { useCallback, useMemo } from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CancelButton from 'components/UI/molecules/BottomFixedContainer/_components/CancelButton/CancelButton';
import GlobalFormButton from 'components/UI/molecules/BottomFixedContainer/_components/GlobalFormButton/GlobalFormButton';
import SubmitButton from 'components/UI/molecules/BottomFixedContainer/_components/SubmitButton/SubmitButton';
import { useProfileSave } from 'components/UI/molecules/OrderServiceBasketActions/OrderServiceBasketActions.logic';
import PATHS from 'constants/router/PATHS';
import { FORM_BAR_BUTTONS } from 'constants/styles/_types/FormBarButtons';
import { useFormStep } from 'hooks/_form/useFormStep/useFormStep';
import { useServicesBasketStorage } from 'storages/serviceBasketStorage/servicesBasketStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import type { OrderServiceFormInput } from '../../_constants/inputKeys';
import { stepOneMobileGridTemplateAreas, stepTwoMobileGridTemplateAreas } from '../../_constants/styles';
import { decreaseFormServicesNumberWithBasketState } from '../../_services/decreaseFormServicesNumberWithBasketState/decreaseFormServicesNumberWithBasketState';

export interface UseButtonsForAllFormStepsParams {
  form: UseFormReturn<OrderServiceFormInput, any>;
  formStep: number;
  isValid: boolean;
  handleOpenConfirmationModal: () => void;
}

export const useButtonsForAllFormSteps = ({ form, formStep, isValid, handleOpenConfirmationModal }: UseButtonsForAllFormStepsParams) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { basketState, getBasketInfo } = useServicesBasketStorage();
  const { profileSave } = useProfileSave();

  const { hasSelectedServices } = useMemo(() => getBasketInfo(), [basketState]);

  const { handlePreviousStep, handleNextStep } = useFormStep({ formStep });

  const handleSavedProfiles = useCallback(() => navigate(`/${PATHS.SERVICES}`), []);
  const handleSaveProfile = useCallback(() => profileSave(basketState), [basketState]);

  const handleOrder = useCallback(() => {
    decreaseFormServicesNumberWithBasketState({ form, basketState });
    handleOpenConfirmationModal();
  }, [basketState, form]);

  const buttonsForAllSteps = useMemo(
    () => [
      [
        {
          buttonProps: {
            id: 0,
            label: t(orderServiceMessages.formStepsButtonLabels.savedProfiles),
            action: handleSavedProfiles,
            gridArea: FORM_BAR_BUTTONS.savedProfilesButtonArea,
          },
          buttonComponent: GlobalFormButton,
        },
        {
          buttonProps: {
            id: 1,
            label: t(orderServiceMessages.formStepsButtonLabels.saveAsProfile),
            action: handleSaveProfile,
            gridArea: FORM_BAR_BUTTONS.saveAsProfileButtonArea,
            disabled: !hasSelectedServices,
          },
          buttonComponent: GlobalFormButton,
        },
        {
          buttonProps: {
            id: 2,
            label: t(orderServiceMessages.formStepsButtonLabels.nextStep),
            action: handleNextStep,
            disabled: !hasSelectedServices,
          },
          buttonComponent: SubmitButton,
        },
      ],
      [
        {
          buttonProps: {
            id: 0,
            label: t(orderServiceMessages.formStepsButtonLabels.prevStep),
            action: handlePreviousStep,
            hasLeftArrow: true,
          },
          buttonComponent: CancelButton,
        },
        {
          buttonProps: {
            id: 1,
            label: t(orderServiceMessages.formStepsButtonLabels.saveAsProfile),
            action: handleSaveProfile,
            gridArea: FORM_BAR_BUTTONS.saveAsProfileButtonArea,
          },
          buttonComponent: GlobalFormButton,
        },
        {
          buttonProps: {
            id: 2,
            label: t(orderServiceMessages.formStepsButtonLabels.order),
            action: handleOrder,
            disabled: !isValid,
          },
          buttonComponent: SubmitButton,
        },
      ],
    ],
    [handlePreviousStep, handleNextStep, handleSaveProfile, hasSelectedServices, isValid],
  );

  const mobileGridTemplateAreas = useMemo(() => [stepOneMobileGridTemplateAreas, stepTwoMobileGridTemplateAreas], []);

  return {
    currentButtons: buttonsForAllSteps[formStep - 1],
    mobileGridTemplateAreas: mobileGridTemplateAreas[formStep - 1],
  };
};
