import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import orderServiceMessages from 'translations/specific/orderService.mjs';

import { resolveIsPayerChosen } from '../../_services/resolveIsPayerChosen/resolveIsPayerChosen';

export const useDetailsItemPrepareValidationRules = () => {
  const { t, i18n } = useTranslation();

  return useMemo(
    () => ({
      validate: (currentPayer: string) => {
        if (!resolveIsPayerChosen(currentPayer)) return t(orderServiceMessages.details.validationErrors.payer);
        return true;
      },
    }),
    [t, i18n.language],
  );
};
