type ContactFormInputKey = 'category' | 'inquiry';

export const CONTACT_FORM_INPUT_KEYS: { [key in ContactFormInputKey]: ContactFormInputKey } = {
  category: 'category',
  inquiry: 'inquiry',
};

export type ContactFormInput = {
  category: number;
  inquiry: string;
};
