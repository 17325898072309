import { makeStyles } from 'tss-react/mui';

type Size = 'medium' | 'small' | 'large';

const getSpacingForSize = (size: Size) => {
  switch (size) {
    case 'large':
      return 1.25; // adjust for usage
    case 'small':
      return 0.5;
    case 'medium':
    default:
      return 0.875;
  }
};
const useStyles = makeStyles<{ size: Size }>()((theme, { size }) => ({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(getSpacingForSize(size)),

    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export default useStyles;
