import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ECareSosInternalNote } from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes.types';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

const useBitrixTable = (data: ECareSosInternalNote[] | undefined) => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<ECareSosInternalNote>();
  const columns = [
    columnHelper.accessor('createdBy.firstName', {
      header: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.id) as string,
      id: 'created_by_first_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('creationTime', {
      header: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.creationTime) as string,
      id: 'creation_time',
      cell: info => unknownDateToString(new Date(info.getValue()), DATE_FORMATS.DISPLAY_LONG_WITH_TIME),
    }),
    columnHelper.accessor('text', {
      header: t(proECareAlarmMessages.eCareAlarmsPartnerNotesTable.text) as string,
      id: 'text',
      cell: info => info.getValue(),
    }),
  ];

  return {
    columns,
    data: data || null,
  };
};

export default useBitrixTable;
