import { FC, ReactNode } from 'react';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, BoxProps, Tooltip, TooltipProps, Typography } from '@mui/material';

import useStyles from './InfoIconWithTooltip.styles';

export type Props = {
  content: ReactNode;
  tooltipProps?: Partial<TooltipProps>;
  wrapperProps?: Partial<BoxProps>;
};

const InfoIconWithTooltip: FC<Props> = ({ content, tooltipProps = {}, wrapperProps = {} }) => {
  const { classes } = useStyles();
  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={<Typography variant='subtitle3'>{content}</Typography>} {...tooltipProps}>
      <Box className={classes.iconWrapper} component='span' data-testid='InfoIconWithTooltip_iconWrapper' {...wrapperProps}>
        <InfoIcon className={classes.icon} />
      </Box>
    </Tooltip>
  );
};

export default InfoIconWithTooltip;
