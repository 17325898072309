import axios from 'axios';
import { format } from 'date-fns';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parseECareSuspencionListForFE, parseSingleECareSuspensionForFE } from './eCareSuspension.parsers';
import { ECareSuspension, ECareSuspensionDTO, ECareSuspensionUnassigned } from './eCareSuspension.types';

const endpoint = '/api/v1/ecare-suspension/';

type GetEcareSuspensionQuery = () => Promise<TableDataQueryResult<keyof ECareSuspension>>;
type GetECareSuspensionQuery = () => Promise<ECareSuspension>;
export type UpdateECareSuspensionInput = ECareSuspensionUnassigned;

type CreateECareSuspensionQuery = (data: ECareSuspensionUnassigned) => Promise<DefaultApiResponse<ECareSuspension>>;
type UpdateECareSuspensionQuery = (data: UpdateECareSuspensionInput) => Promise<DefaultApiResponse<ECareSuspension>>;

const eCareSuspension = {
  getAllSuspensions:
    (orderId: string): GetEcareSuspensionQuery =>
    async () => {
      const { data } = await axios.get<GetEcareSuspensionQuery, PaginatedApiResponse<ECareSuspensionDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: { order_id: orderId || '' },
          removeEmptyParams: true,
        }),
      );
      return { data: parseECareSuspencionListForFE(data.results), count: data.count };
    },
  getSuspension:
    (suspensionId: string): GetECareSuspensionQuery =>
    async () => {
      const { data } = await axios.get<GetECareSuspensionQuery, DefaultApiResponse<ECareSuspensionDTO>>(`${endpoint}${suspensionId}/`);

      return parseSingleECareSuspensionForFE(data);
    },
  createECareSuspension: (): CreateECareSuspensionQuery => data => {
    const parseDataBE = {
      date_from: data.date_from ? format(new Date(data.date_from), DATE_FORMATS.API_DATE) : null,
      date_to: data.date_to ? format(new Date(data.date_to), DATE_FORMATS.API_DATE) : null,
      reason: data.reason,
      order: data.order,
    };
    return axios.post<UpdateECareSuspensionQuery, DefaultApiResponse<ECareSuspension>>(
      createUrlWithParams({
        url: endpoint,
        removeEmptyParams: true,
      }),
      parseDataBE,
    );
  },
  patchECareSuspensionData:
    (id: string): UpdateECareSuspensionQuery =>
    data => {
      const parseDataBE = {
        date_from: data.date_from ? format(new Date(data.date_from), DATE_FORMATS.API_DATE) : null,
        date_to: data.date_to ? format(new Date(data.date_to), DATE_FORMATS.API_DATE) : null,
        reason: data.reason,
      };
      return axios.patch<UpdateECareSuspensionQuery, DefaultApiResponse<ECareSuspension>>(`${endpoint}${id}/`, parseDataBE);
    },
};
export default eCareSuspension;
