import type { TranslatableDropdownOption } from 'constants/_types/DropdownOptions';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import { PatientStatus, patientStatusesArray } from '../_types/PatientStatusesArray';

const patientStatusOptions: TranslatableDropdownOption<PatientStatus>[] = patientStatusesArray.map(status => ({
  labelKey: proPatientMessages.patientStatuses[status],
  value: status,
}));

export default patientStatusOptions;
