import { get } from 'lodash';
import { TFunction } from 'react-i18next';

import { ReportData } from 'api/reports';
import { Evidence } from 'constants/_types/SymptomCheckerInterviewData';
import resolveGender from 'services/_dictionaryResolvers/resolveGender/resolveGender';
import { normalizeGender } from 'services/_symptomChecker/symptomCheckerReportDataParser/normalizeGender/normalizeGender';
import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

export type ReportCommonDataResult = {
  createdAt: string;
  gender: string;
  age: number;
  symptoms: {
    present: { name: string; id: string }[];
    absent: { name: string; id: string }[];
    unknown: { name: string; id: string }[];
  };
  riskFactors: { name: string; id: string }[];
  visitedPlaces: { name: string; id: string }[];
  reportedSymptoms: { name: string; id: string }[];
  conditions: { common_name: string; id: string; probability: number }[];
  triageBaseText: {
    header: string;
    content: string;
  };
};

type ReportCommonDataParser = (
  t: TFunction,
  rawData: Omit<ReportData, 'id' | 'is_hidden' | 'is_medical_record' | 'report'>,
) => ReportCommonDataResult;

const symptomCheckerReportDataParser: ReportCommonDataParser = (t, rawData) => {
  const {
    data: { answers, evidence, conditions, triageLevel },
    created_at,
  } = rawData;

  const tempRiskFactors: Evidence[] = [];
  const tempVisitedPlaces: Evidence[] = [];
  const tempSymptoms: {
    absent: Evidence[];
    present: Evidence[];
    unknown: Evidence[];
  } = {
    absent: [],
    present: [],
    unknown: [],
  };

  evidence.forEach((element: Evidence) => {
    const { source, id: evidenceId, choice_id } = element;
    if (source !== 'predefined' && source !== 'initial') {
      tempSymptoms[choice_id].push(element);
      return;
    }
    const idNumber = Number(evidenceId.replace(/[a-z]_/, ''));
    // empiric logic...
    // API description: https://developer.infermedica.com/docs/v3/diagnosis
    if ((idNumber >= 13 && idNumber <= 21) || idNumber === 236) {
      tempVisitedPlaces.push(element);
    } else if (choice_id === 'present' && source !== 'initial') {
      tempRiskFactors.push(element);
    }
  });

  return {
    createdAt: created_at,
    gender: resolveGender(t, normalizeGender(answers.patientSex)),
    age: answers.patientAge.value,
    symptoms: tempSymptoms,
    riskFactors: tempRiskFactors,
    visitedPlaces: tempVisitedPlaces,
    reportedSymptoms: evidence.filter(({ source }) => source === 'initial'),
    conditions,
    triageBaseText: {
      header: t(get(symptomCheckerReportsMessages.triageLevelsDescriptions, `${triageLevel}.header`)),
      content: t(get(symptomCheckerReportsMessages.triageLevelsDescriptions, `${triageLevel}.content`)),
    },
  };
};

export default symptomCheckerReportDataParser;
