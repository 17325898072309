import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { Alert, AlertTitle, Collapse, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IconErrorAlert from 'components/UI/atoms/IconErrorAlert/IconErrorAlert';
import validationMessages from 'translations/common/validation.mjs';

import useStyles from './FormGlobalError.styles';

export type Props = {
  errors?: string[];
};

const FormGlobalError: FC<Props> = ({ errors }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: 'center' });
  }, [errors]);

  return (
    <Collapse collapsedSize={0} in={!!errors}>
      <Alert classes={{ message: classes.message }} className={classes.root} icon={false} severity='error' variant='outlined'>
        <div ref={ref}>
          <div className={classes.titleRow}>
            <IconErrorAlert isAlternativeStyle isBoxShadow />
            <AlertTitle>{t(validationMessages.general_form_error_title)}</AlertTitle>
          </div>
          <ul className={classes.errorsList}>
            {errors &&
              errors.map(error => (
                <Typography component='li' key={error} variant='body2'>
                  {error}
                </Typography>
              ))}
          </ul>
        </div>
      </Alert>
    </Collapse>
  );
};

export default FormGlobalError;
