import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  label: {
    fontSize: '0.95rem',
    lineHeight: '1.375rem',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(1),
    display: 'block',
  },
}));

export default useStyles;
