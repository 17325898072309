import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { useQuery } from 'react-query';

import payers from 'api/payers/payers';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import TableDesktopCard from 'components/UI/organisms/_tables/TableDesktopCard/TableDesktopCard';
import TableMobileCard from 'components/UI/organisms/_tables/TableMobileCard/TableMobileCard';
import type { SelectedServices } from 'constants/_types/SelectedServices';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { useIsMobile } from 'hooks/_breakpoints/useIsMobile/useIsMobile';

import { headers } from '../../_constants/headers';
import { useParseRowsForOrderDetailsConfirmationDialog } from '../../_hooks/useParseRowsForOrderDetailsConfirmationDialog/useParseRowsForOrderDetailsConfirmationDialog';
import { prepareMobileRowCustomStyles } from '../../_services/prepareMobileRowCustomStyles/prepareMobileRowCustomStyles';

export interface Props {
  selectedServices: SelectedServices;
}

const OrderDetailsConfirmationDialogContent: FC<Props> = ({ selectedServices }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { patient } = usePatientContext();

  const { data: payersData } = useQuery([QUERY_KEYS.PAYER, patient?.id], payers.getPayers(patient?.id), {
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
  });

  const parsedRows = useParseRowsForOrderDetailsConfirmationDialog({ selectedServices, payersData });

  const mobileRowCustomStyles = useMemo(() => prepareMobileRowCustomStyles(theme), []);

  const commonProps = { headers, rows: parsedRows };

  return isMobile ? <TableMobileCard rowCustomStyles={mobileRowCustomStyles} {...commonProps} /> : <TableDesktopCard {...commonProps} />;
};

export default memo(OrderDetailsConfirmationDialogContent);
