import type { FC, ReactNode } from 'react';

import type { AlertProps } from '@mui/material';
import { Alert, AlertTitle, Typography } from '@mui/material';
import Grow from '@mui/material/Grow';

import useStyles from './AlertWithOptionalActions.styles';

export interface Props extends AlertProps {
  content: string | JSX.Element;
  severity: 'error' | 'warning' | 'info' | 'success';
  icon: ReactNode;
  colorContent?: string;
  description?: string;
  action?: ReactNode;
  header?: JSX.Element;
}

const AlertWithOptionalActions: FC<Props> = ({ action, icon, content, severity, description, header, ...rest }) => {
  const { classes } = useStyles({ hasAction: !!action, hasHeader: !!header });

  return (
    <Grow className={classes.messageOuterContainer} in>
      <Alert classes={{ message: classes.messageContainer }} icon={false} severity={severity} variant='outlined' {...rest}>
        <div className={classes.contentContainer}>
          <div className={classes.headerContainer}>
            <div>{icon || null}</div>
            {header && (
              <Typography className={classes.header} variant='subtitle3'>
                {header}
              </Typography>
            )}
          </div>
          <div className={classes.content}>
            <AlertTitle className={classes.title}>{content}</AlertTitle>
            {description && <Typography variant='caption'>{description}</Typography>}
          </div>
        </div>
        {action && <div className={classes.action}>{action}</div>}
      </Alert>
    </Grow>
  );
};

export default AlertWithOptionalActions;
