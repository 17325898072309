import type { FC } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SummaryListNoItems from 'components/UI/atoms/SummaryListNoItems/SummaryListNoItems';
import SummaryListGroup from 'components/UI/molecules/SummaryListGroup/SummaryListGroup';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import { SEARCH_PHRASE_MIN_LENGTH } from 'constants/globalSearch/SEARCH_PHRASE_MIN_LENGTH';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useGlobalSearchStorage, { type DataState } from 'storages/globalSearchStorage';
import globalSearchMessages from 'translations/specific/globalSearch.mjs';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { usePreselectOrderServiceForm } from './_hooks/usePreselectOrderServiceForm/usePreselectOrderServiceForm';
import useStyles from './SearchResults.styles';

export const testId = createTestIdObject('SearchResults', {
  welcomeInfo: 'welcomeInfo',
  invalidPhrase: 'invalidPhrase',
  loading: 'loading',
  searchResults: 'searchResults',
  clarificationInfo: 'clarificationInfo',
  noDataInfo: 'noDataInfo',
});

const SUCCESS_DATA_STATES: DataState[] = ['RESULTS_FETCHED_SUCCESSFULLY', 'NEEDS_CLARIFICATION'];

const SearchResults: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { dataState, searchResults } = useGlobalSearchStorage();

  const { preselectOrderServiceFormUsingItemDetails } = usePreselectOrderServiceForm();

  switch (dataState) {
    case 'PRISTINE': {
      return (
        <div className={classes.searchResults} data-testid={testId.welcomeInfo}>
          <SummaryListNoItems textLabel={t(globalSearchMessages.searchInput.initialPhrase)} />
        </div>
      );
    }
    case 'TOO_SHORT_PHRASE': {
      return (
        <div className={classes.searchResults} data-testid={testId.invalidPhrase}>
          <SummaryListNoItems textLabel={t(globalSearchMessages.searchInput.tooShortPhrase, { length: SEARCH_PHRASE_MIN_LENGTH })} />
        </div>
      );
    }
    case 'LOADING': {
      return (
        <div className={classes.searchResults} data-testid={testId.loading}>
          <CircularProgress className={classes.loader} />
        </div>
      );
    }
    default: {
      return (
        <div className={classes.searchResults} data-testid={testId.searchResults}>
          {SUCCESS_DATA_STATES.includes(dataState) && searchResults ? (
            <>
              {Object.keys(searchResults).map((coreServiceKind, index, array) => (
                <SummaryListGroup
                  clickCallback={preselectOrderServiceFormUsingItemDetails}
                  header={t(orderServiceMessages.basket.typesSections[coreServiceKind as OrderServiceCoreServicesKinds])}
                  isLast={index === array.length - 1}
                  isSearchResult
                  items={searchResults[coreServiceKind as OrderServiceCoreServicesKinds]?.items || []}
                  key={coreServiceKind}
                />
              ))}
              {dataState === 'NEEDS_CLARIFICATION' && (
                <Typography className={classes.clarificationText} data-testid={testId.clarificationInfo} variant='subtitle3'>
                  {t(globalSearchMessages.searchResults.clarificationText)}
                </Typography>
              )}
            </>
          ) : (
            <SummaryListNoItems testId={testId.noDataInfo} textLabel={t(globalSearchMessages.searchInput.noSearchResults)} />
          )}
        </div>
      );
    }
  }
};

export default SearchResults;
