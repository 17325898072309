import { alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme();
const shadows: typeof baseTheme.shadows = [...baseTheme.shadows];
const shadowBaseColor = '#BECBD5'; // gray[400]
shadows[0] = 'none';
shadows[1] = `0 1px 2px ${alpha(shadowBaseColor, 0.3)}`;
shadows[2] = `0 2px 3px ${alpha(shadowBaseColor, 0.5)}`;
shadows[3] = `0 14px 14px ${alpha(shadowBaseColor, 0.5)}`;
shadows[4] = `0 24px 24px ${alpha(shadowBaseColor, 0.3)}`;
shadows[5] = `0 46px 46px ${alpha(shadowBaseColor, 0.4)}`;
// shadows on top
shadows[6] = `0 -1px 2px ${alpha(shadowBaseColor, 0.3)}`;
shadows[7] = `0 -2px 3px ${alpha(shadowBaseColor, 0.5)}`;
shadows[8] = `0 -14px 14px ${alpha(shadowBaseColor, 0.5)}`;
shadows[9] = `0 -24px 24px ${alpha(shadowBaseColor, 0.3)}`;
shadows[10] = `0 -46px 46px ${alpha(shadowBaseColor, 0.4)}`;

export default shadows;
