import { useEffect } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { UseMutateAsyncFunction, UseMutateFunction } from 'react-query';

import RECAPTCHA_REGEX from 'constants/regex/recaptcha';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import snackbarMessages from 'translations/common/snackbar.mjs';

const useMutationWithRecaptcha = (key: string) => {
  const { showSnackbar } = useShowSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha() || { executeRecaptcha: undefined };

  useEffect(() => {
    if (!key || !RECAPTCHA_REGEX.test(key)) {
      throw Error(`Invalid recaptcha key - key "${key}" provided to useMutationWithRecaptcha is invalid, it may only include "A-Za-z/_"`);
    }
  }, [key]);

  const mutateWithRecaptcha = async <MutationData>(
    mutation: UseMutateFunction<any, any, any, any> | UseMutateAsyncFunction<any, any, any, any>,
    mutationData: Omit<MutationData, 'recaptcha'>,
  ) => {
    if (!executeRecaptcha && process.env.REACT_APP_RECAPTCHA_DISABLE !== 'true') {
      showSnackbar({ variant: 'error', translationArray: snackbarMessages.recaptchaFailure });
      return null;
    }

    const recaptcha = process.env.REACT_APP_RECAPTCHA_DISABLE !== 'true' ? await executeRecaptcha?.(key) : 'recaptcha';
    return mutation({ ...mutationData, recaptcha });
  };

  return { mutateWithRecaptcha };
};

export default useMutationWithRecaptcha;
