import { format } from 'date-fns';

import { parseSingleECareDevicesForFE } from 'api/eCareDevices/eCareDevices.parsers';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';

import { parsePatientForFE } from '../patient/patient.parsers';
import {
  ECareTechnicalIssues,
  ECareTechnicalIssuesCreateInput,
  ECareTechnicalIssuesCreateInputDTO,
  ECareTechnicalIssuesDTO,
  ECareTechnicalIssuesUpdateInput,
  ECareTechnicalIssuesUpdateInputDTO,
} from './eCareTechnicalIssues.types';

export const parseSingleECareTechnicalIssuesForFE = (eCareIssuesTechnicalDTO: ECareTechnicalIssuesDTO): ECareTechnicalIssues => ({
  date: new Date(eCareIssuesTechnicalDTO.date),
  description: eCareIssuesTechnicalDTO.description,
  fixDate: eCareIssuesTechnicalDTO.fix_date ? new Date(eCareIssuesTechnicalDTO.fix_date) : null,
  fixDescription: eCareIssuesTechnicalDTO.fix_description || null,
  order: eCareIssuesTechnicalDTO.order,
  serviceRecipient: eCareIssuesTechnicalDTO.service_recipient,
  patient: eCareIssuesTechnicalDTO.patient ? parsePatientForFE(eCareIssuesTechnicalDTO.patient) : null,
  device: parseSingleECareDevicesForFE(eCareIssuesTechnicalDTO.device),
  id: eCareIssuesTechnicalDTO.id,
  createdAt: new Date(eCareIssuesTechnicalDTO.created_at),
  updatedAt: new Date(eCareIssuesTechnicalDTO.updated_at),
  createdBy: eCareIssuesTechnicalDTO.created_by,
  lastSync: eCareIssuesTechnicalDTO.last_sync ? new Date(eCareIssuesTechnicalDTO.last_sync) : null,
});

export const parseECareTechnicalIssuesListForFE = (eCareTechnicalIssueDTO: ECareTechnicalIssuesDTO[]): ECareTechnicalIssues[] =>
  eCareTechnicalIssueDTO.map(parseSingleECareTechnicalIssuesForFE);

export const parseECareFormInputForCreate = (formData: ECareTechnicalIssuesCreateInput): ECareTechnicalIssuesCreateInputDTO => ({
  device_id: formData.deviceId,
  date: format(formData.date, DATE_FORMATS.API_DATE),
  description: formData.description,
  fix_date: formData.fixDate ? format(formData.fixDate, DATE_FORMATS.API_DATE) : null,
  fix_description: formData.fixDescription || null,
});

export const parseECareFormInputForUpdate = (formData: ECareTechnicalIssuesUpdateInput): ECareTechnicalIssuesUpdateInputDTO => ({
  description: formData.description,
  fix_date: formData.fixDate ? format(formData.fixDate, DATE_FORMATS.API_DATE) : null,
  fix_description: formData.fixDescription || null,
});
