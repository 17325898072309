import { AlarmStatusT } from 'api/eCareAlarms/eCareAlarms.types';
import { TranslationKey } from 'constants/_types/TranslationKey';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

export const alarmStatusesDictionary: Record<AlarmStatusT, TranslationKey> = {
  RECEIVED: proECareAlarmMessages.sosStatus.received,
  MISSED: proECareAlarmMessages.sosStatus.missed,
  COMPLETE_DOCUMENTATION: proECareAlarmMessages.sosStatus.completeDocumentation,
  CLOSED: proECareAlarmMessages.sosStatus.closed,
};
