import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import AvailablePayers from './_components/AvailablePayers/AvailablePayers';
import useStyles from './ServicePayersBar.styles';

const ServicePayersBar: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.generalTextContainer}>
        <Typography variant='h4'>{t(orderServiceMessages.payments.choosePaymentMethod)}</Typography>
        <InfoIconWithTooltip content={t(orderServiceMessages.payments.choosePaymentTooltip)} tooltipProps={{ placement: 'bottom' }} />
      </div>
      <AvailablePayers />
    </div>
  );
};

export default ServicePayersBar;
