import { useEffect } from 'react';

import type { Breadcrumb } from 'constants/router/routes';
import useCurrentPathConfigStorage from 'storages/currentPathConfigStorage';

type UseDynamicPathConfig = () => {
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
};

const useDynamicPathConfig: UseDynamicPathConfig = () => {
  const { setBreadcrumbsOverride, pruneOverrides } = useCurrentPathConfigStorage();

  const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    if (breadcrumbs) setBreadcrumbsOverride(breadcrumbs);
  };

  useEffect(() => pruneOverrides, []);

  return { setBreadcrumbs };
};

export default useDynamicPathConfig;
