import { FC } from 'react';

import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import TechnicalIssuesList from 'components/UI/organisms/TechnicalIssuesList/TechnicalIssuesList';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ProfessionalTechnicalIssuesListPage: FC = () => {
  usePageConfig();

  return (
    <MainContentWrapper extraContainer noPadding>
      <TechnicalIssuesList />
    </MainContentWrapper>
  );
};

export default ProfessionalTechnicalIssuesListPage;
