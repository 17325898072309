import type { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import useResetPasswordRequestMutation from 'hooks/_mutations/useResetPasswordRequestMutation/useResetPasswordRequestMutation';
import authMessages from 'translations/common/auth.mjs';
import generalMessages from 'translations/common/general.mjs';
import resetPasswordMessages from 'translations/specific/resetPassword.mjs';

export type ResetPasswordRequestFormInput = {
  login_identifier: string;
};

const ResetPasswordRequestForm: FC = () => {
  const { t } = useTranslation();
  // SAVED FOR LATER USE
  // const location = useLocation();
  //
  // const defaultLoginIdentifier = useMemo(() => {
  //   const state = location.state as { loginIdentifier: string };
  //   return state && state.loginIdentifier ? state.loginIdentifier : '';
  // }, []);
  //
  // const { control, handleSubmit } = useForm<EmailResetFormInput>({
  //   defaultValues: { login_identifier: defaultLoginIdentifier },
  // });

  const { control, handleSubmit, setError, formState } = useForm<ResetPasswordRequestFormInput>();

  const { resetPasswordRequestMutate, resetPasswordRequestMutateGeneralErrors, isResetPasswordRequestMutating } =
    useResetPasswordRequestMutation({ setError });

  const onSubmit: SubmitHandler<ResetPasswordRequestFormInput> = async data => {
    resetPasswordRequestMutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography align='center' component='h1' marginBottom={2} variant='h3'>
        {t(resetPasswordMessages.header)}
      </Typography>
      <SimpleFormWrapper formState={formState} globalErrors={resetPasswordRequestMutateGeneralErrors}>
        <Typography align='center' mb={1} variant='body2'>
          {t(resetPasswordMessages.instruction)}
        </Typography>
        <FormInputText control={control} label={t(authMessages.emailOrAuthIdentifier)} name='login_identifier' required />
        <Button data-testid='submit_button' disabled={isResetPasswordRequestMutating} type='submit' variant='contained'>
          {t(generalMessages.submit)}
        </Button>
      </SimpleFormWrapper>
    </form>
  );
};

export default ResetPasswordRequestForm;
