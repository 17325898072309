const eCareInternalNotesMessages = {
  title: [ 'eCareInternalNotes>>title', 'Notatka wewnętrzna' ],
  historyTitle: [ 'eCareInternalNotes>>historyTitle', 'Notatki wewnętrzne' ],
  addNote: [ 'eCareInternalNotes>>addNote', 'Dodaj notatkę wewnętrzną' ],
  moreNotes: [ 'eCareInternalNotes>>moreNotes', 'Zobacz więcej notatek' ],
  modal: {
    edit: [ 'eCareInternalNotes>>modal>>edit', 'Edytuj' ],
    editNote: [ 'eCareInternalNotes>>modal>>editNote', 'Edytuj notatkę wewnętrzną' ],
  },
  table: {
    updateDate: [ 'eCareInternalNotes>>table>>updateDate', 'Data aktualizacji' ],
  }
}

export default eCareInternalNotesMessages;
