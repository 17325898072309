import { makeStyles } from 'tss-react/mui';

import tableRowStylesFactory from 'styles/helpers/tableRowStylesFactory';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  closedColor: tableRowStylesFactory(theme, theme.palette.table.default),
  completeDocumentationColor: tableRowStylesFactory(theme, theme.palette.table.success),
  receivedColor: tableRowStylesFactory(theme, theme.palette.table.warning),
  missedColor: tableRowStylesFactory(theme, theme.palette.table.danger),
}));

export default useStyles;
