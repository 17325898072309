import type { FC } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './AccordionText.styles';

export interface Props {
  translationArrayAnswer: string[];
  translationArrayQuestion: string[];
}

const AccordionText: FC<Props> = ({ translationArrayAnswer, translationArrayQuestion }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.titleText} variant='subtitle3'>
          {t(translationArrayQuestion)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='captionBold'>{t(translationArrayAnswer)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionText;
