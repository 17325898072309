import { makeStyles } from 'tss-react/mui';

import { DATE_RANGE_AREA, DATE_SOON_AREA, HEADER_NAME_AREA } from '../../_constants/gridAreas';

const useStyles = makeStyles<{ isCumulative: boolean }>()((theme, { isCumulative }) => {
  const gridAreasRegular = `
    "${HEADER_NAME_AREA}"
  `;
  const gridAreasDesktopCumulative = `
    "${HEADER_NAME_AREA} ${DATE_SOON_AREA} ${DATE_RANGE_AREA}"
  `;
  const gridAreasMobileCumulative = `
    "${HEADER_NAME_AREA}"
    "${DATE_SOON_AREA}"
    "${DATE_RANGE_AREA}"
  `;

  return {
    root: {
      padding: theme.spacing(3),
      background: theme.palette.grey[300],
      display: 'grid',
      gridGap: theme.spacing(1),
      gridTemplateColumns: '0.85fr 1.35fr 1.75fr',
      gridTemplateAreas: isCumulative ? gridAreasDesktopCumulative : gridAreasRegular,

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: isCumulative ? gridAreasMobileCumulative : gridAreasRegular,
      },
    },

    headerName: {
      gridArea: HEADER_NAME_AREA,
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        wordBreak: 'break-word',
        fontSize: '0.9286rem',
        paddingBottom: theme.spacing(0.5),
      },
    },
  };
});

export default useStyles;
