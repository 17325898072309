import { useCallback, useState } from 'react';

import { useChangeUserLanguage } from '../useChangeUserLanguage/useChangeUserLanguage';

export const useLanguageChangeControl = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { isLoading, changeLanguage } = useChangeUserLanguage({ handleClose });

  return { open, isLoading, changeLanguage, handleToggle, handleClose };
};
