import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

const useStyles = makeStyles<{ isDrawerOpened: boolean }>()((theme, { isDrawerOpened }) => ({
  root: {},
  content: {
    paddingLeft: isDrawerOpened ? LAYOUT_SIZES.SIDE_MENU.open : LAYOUT_SIZES.SIDE_MENU.closed,
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  toggleButton: {
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    borderRadius: '100%',
    top: `${LAYOUT_SIZES.HEADER.xxl / 2}px`,
    height: '40px',
    width: '40px',
    left: '0',
    transform: `translate(calc(${isDrawerOpened ? LAYOUT_SIZES.SIDE_MENU.open : LAYOUT_SIZES.SIDE_MENU.closed}px - 20px), -50%)`,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer - 1,
    '& > *': {
      marginLeft: '14px',
    },

    [theme.breakpoints.down('xl')]: {
      top: `${LAYOUT_SIZES.HEADER.xl / 2}px`,
    },
  },
}));

export default useStyles;
