import type { CheckFileSizeCoreParams, CheckFileTypeCoreParams } from '../_types/params';
import { checkFileSize } from '../checkFileSize/checkFileSize';
import { checkFileType } from '../checkFileType/checkFileType';
import { clearInput } from '../clearInput/clearInput';

type Parameters = {
  target: EventTarget & HTMLInputElement;
  file: File;
  config: {
    showSnackbar: (config: any) => void;
    fileType?: CheckFileTypeCoreParams;
    fileSize?: CheckFileSizeCoreParams;
  };
};

export const verifyUploadedFile = ({ target, file, config }: Parameters) => {
  const { showSnackbar } = config;

  const validationOutputs: boolean[] = [];

  if (config.fileType) {
    const output = checkFileType({ file, ...config.fileType });
    validationOutputs.push(output);
    if (!output) showSnackbar({ variant: 'error', translationArray: config.fileType.errorMessageTranslationArray });
  }

  if (config.fileSize) {
    const output = checkFileSize({ file, ...config.fileSize });
    validationOutputs.push(output);
    if (!output)
      showSnackbar({
        variant: 'error',
        translationArray: config.fileSize.errorMessageTranslationArray,
        translationParams: { maxFileSize: config.fileSize.acceptedMaxSize },
      });
  }

  const isNotValidated = validationOutputs.some(output => !output);
  if (isNotValidated) {
    clearInput(target);

    return false;
  }

  return true;
};
