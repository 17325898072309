import isProduction from 'helpers/isProduction/isProduction';

export const terminateRecursiveLoop = (recursiveFunctionName: string, iterationsNumberToTerminateLoop = 50) => {
  let shouldTerminateRecursiveLoop = false;
  let iterationCounter = 0;

  const increaseRecursiveIterationCounter = () => {
    // eslint-disable-next-line no-plusplus
    iterationCounter++;

    if (iterationCounter === iterationsNumberToTerminateLoop) {
      const errorMessage = `${recursiveFunctionName} has exceeded ${iterationsNumberToTerminateLoop} iterations of recursive loop and had to be terminated`;
      if (isProduction()) {
        // eslint-disable-next-line no-console
        console.error(errorMessage);
      } else {
        throw Error(errorMessage);
      }
      shouldTerminateRecursiveLoop = true;
    }
  };

  return { shouldTerminateRecursiveLoop, increaseRecursiveIterationCounter };
};
