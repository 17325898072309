import { useLayoutEffect, useMemo, useRef, useState, type FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import type { ColumnsDisplaySetEntities, ColumnsDisplaySetHeader } from 'constants/_types/ColumnsDisplaySetData';
import { getBrowserType } from 'services/getBrowserType/getBrowserType';

import ColumnsDisplayFallback from '../ColumnsDisplayFallback/ColumnsDisplayFallback';
import ColumnsDisplaySetRows from './_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';
import type { PathToGenerator, CellRendererType } from './_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';
import useStyles from './ColumnsDisplaySet.styles';

export type HeaderRendererProps = { header: ColumnsDisplaySetHeader; isActive: boolean };
export type HeaderRendererType = (props: HeaderRendererProps) => JSX.Element;

export type ColumnsDisplaySetContent = {
  headers: ColumnsDisplaySetHeader[];
  entities: ColumnsDisplaySetEntities;
};

export type Props = {
  content: ColumnsDisplaySetContent | null;
  headerRenderer?: HeaderRendererType;
  cellSize?: number;
  columnsNumber?: number;
  cellRenderer: CellRendererType;
  activeColumnId?: number | string;
  showGrid?: boolean;
  pathToGenerator?: PathToGenerator;
  fallbackMessage?: string;
  isLoading?: boolean;
  fixedHeight?: number;
};

const MIN_TABLE_HEIGHT = 100;

const ColumnsDisplaySet: FC<Props> = ({
  content,
  columnsNumber,
  headerRenderer,
  cellSize = 120,
  cellRenderer,
  activeColumnId,
  showGrid,
  pathToGenerator,
  fallbackMessage,
  isLoading,
  fixedHeight,
}) => {
  const { t } = useTranslation();
  const tBodyRef = useRef<null | HTMLTableElement>(null);

  const [tableHeight, setTableHeight] = useState(MIN_TABLE_HEIGHT);
  useLayoutEffect(() => {
    if (!tBodyRef.current || !content) return;
    const height = Math.max(tBodyRef.current.offsetHeight, MIN_TABLE_HEIGHT);
    setTableHeight(height);
  }, [content, tBodyRef]);

  const isFirefox = useMemo(() => getBrowserType() === 'firefox', []);

  const { classes, cx } = useStyles({ tableHeight, cellSize, columnsNumber, isFirefox, fixedHeight });

  const hasNoElementsToRender = useMemo(() => {
    if (isLoading) return false;
    return !content || !content.entities || Object.values(content.entities).every(entity => !entity.length);
  }, [content, isLoading]);

  if (hasNoElementsToRender && !fallbackMessage) return null;
  if (hasNoElementsToRender) {
    return <ColumnsDisplayFallback fallbackMessage={fallbackMessage} />;
  }

  return (
    <ElementWithLoader isLoading={!!isLoading} overlayClassName={classes.loader}>
      <div className={classes.root}>
        <table className={classes.table} ref={tBodyRef}>
          {content && (
            <>
              <thead className={classes.thead}>
                <tr>
                  {content.headers.map(header => {
                    const isActive = header.id.toString() === activeColumnId;
                    return (
                      <th className={cx(classes.th, isActive && classes.thActive)} key={header.id}>
                        {headerRenderer ? (
                          headerRenderer({ header, isActive })
                        ) : (
                          <Typography color={isActive ? 'primary' : undefined} variant='caption'>
                            {t(header.name.key, header.name.defaultValue) || header.name.defaultValue}
                          </Typography>
                        )}
                        {isActive && <span className={classes.activeBorderNode} />}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <ColumnsDisplaySetRows
                  activeColumnId={activeColumnId}
                  cellRenderer={cellRenderer}
                  cellSize={cellSize}
                  columnsNumber={columnsNumber}
                  entities={content.entities}
                  headerIds={content.headers.map(({ id }) => id)}
                  pathToGenerator={pathToGenerator}
                  showGrid={showGrid}
                />
              </tbody>
            </>
          )}
        </table>
      </div>
    </ElementWithLoader>
  );
};

export default ColumnsDisplaySet;
