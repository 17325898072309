import type { Theme } from '@mui/material';

export const prepareMobileRowCustomStyles = (theme: Theme) => ({
  cardPadding: theme.spacing(2),

  label: {
    color: theme.palette.grey[600],
    fontSize: '0.7858rem',
    lineHeight: '0.9375rem',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },

  rowItem: {
    color: theme.palette.secondary.main,
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    margin: 0,
  },
});
