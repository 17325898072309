import type { FC } from 'react';
import { useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PasswordChangeDialog from 'components/UI/organisms/_dialogs/PasswordChangeDialog/PasswordChangeDialog';
import { DIALOGS } from 'constants/_types/Dialogs';
import { useDialogs } from 'hooks/_dialogs/useDialogs/useDialogs';
import patientMessages from 'translations/specific/patient.mjs';

const PasswordChangeContainer: FC = () => {
  const { t } = useTranslation();
  const { dialog, closeDialog, openPasswordChangeDialog } = useDialogs();

  const isPasswordChangeDialogOpened = useMemo(() => dialog === DIALOGS.passwordChange, [dialog]);

  return (
    <>
      <Button size='small' variant='contained' onClick={openPasswordChangeDialog}>
        {t(patientMessages.buttons.changePassword)}
      </Button>
      {isPasswordChangeDialogOpened && <PasswordChangeDialog close={closeDialog} isOpen={isPasswordChangeDialogOpened} />}
    </>
  );
};

export default PasswordChangeContainer;
