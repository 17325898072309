import type { FC } from 'react';
import { useCallback } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import RemoveItemButton from 'components/UI/atoms/RemoveItemButton/RemoveItemButton';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import type { PreselectingOrderServiceFormMetaData } from 'constants/_types/PreselectingOrderServiceFormMetaData';
import type { SelectedServiceItemMetaData } from 'constants/_types/SelectedServiceItemMetaData';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';

import useStyles from './SummaryListItem.styles';

export interface Props {
  id: number;
  kind: OrderServiceCoreServicesKinds;
  serviceOrigin?: OrderServiceOrigins;
  serviceSubtypeId?: number;
  serviceTypeId?: number;
  translationArray: string[];
  clickCallback?: (data: PreselectingOrderServiceFormMetaData) => void;
  removeItem?: (metaData: SelectedServiceItemMetaData) => void;
}

export const testId = createTestIdObject('SummaryListItem', {
  removeButton: 'removeButton',
});

const SummaryListItem: FC<Props> = ({
  id,
  kind,
  serviceOrigin,
  serviceTypeId,
  serviceSubtypeId,
  translationArray,
  clickCallback,
  removeItem,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ isClickable: !!clickCallback });

  const handleOnRemove = useCallback(() => {
    removeItem?.({ id: `${id}`, coreServiceKind: kind });
  }, []);

  const handleOnClick = useCallback(() => {
    if (!serviceOrigin) return;

    clickCallback?.({
      serviceOrigin,
      serviceTypeId: `${serviceTypeId}`,
      serviceSubtypeId: `${serviceSubtypeId}`,
      serviceId: `${id}`,
    });
  }, []);

  return (
    // eslint-disable-next-line
    <div className={classes.root} onClick={handleOnClick}>
      {removeItem && <RemoveItemButton className={classes.removeButton} testId={testId.removeButton} onClick={handleOnRemove} />}
      <Typography className={classes.textItem} variant='subtitle3'>
        {t(translationArray)}
      </Typography>
    </div>
  );
};

export default SummaryListItem;
