import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  fallback: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
}));

export default useStyles;
