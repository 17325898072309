import { FC, useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { format, isEqual } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareSosPcInternalNotes from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes';
import { ECareSosInternalNote } from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import { DefinitionListValue } from 'components/UI/molecules/DefinitionList/DefinitionList';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import CreatedByTooltip from './_components/CreatedByTooltip/CreatedByTooltip';
import useStyles from './ECareBitrixNotes.styles';

type FormInput = {
  text: string;
};

type HeaderData = {
  label: string;
  value: DefinitionListValue | DefinitionListValue[];
}[];

type Props = {
  note: ECareSosInternalNote;
  disabled?: boolean;
};
const ECareBitrixNotes: FC<Props> = ({ note, disabled }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_BITRIX_NOTES, note.id], [note.id]);

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareSosPcInternalNotes.patchNoteData(note.id), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSuccess: async () => {
      await queryClient.resetQueries(QUERY_KEYS.GET_BITRIX_NOTES);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues } = useForm<FormInput>();

  const updateNote = useCallback(async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionBitixEditNoteTitle),
      body: <FormInputText control={control} defaultValue={note.text} label='' multiline name='text' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed)
      statusMutation.mutate({
        text: getValues('text'),
      });
  }, [note.text]);

  const isEdited = !isEqual(note.creationTime, note?.updateTime);
  const userData = useMemo(() => {
    if (isEdited) return note.updatedBy?.email || t(generalMessages.noData);
    return note.createdBy.email;
  }, [note.createdBy, note.updatedBy]);

  const noteDate = {
    label: isEdited
      ? t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.updatedTime)
      : t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.creationTime),
    value: isEdited ? `${format(note.updateTime, DATE_FORMATS.DISPLAY_TIME)}` : `${format(note.creationTime, DATE_FORMATS.DISPLAY_TIME)}`,
  };
  const noteUser = {
    label: isEdited
      ? t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.updatedBy)
      : t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.user),
    value: userData,
  };

  const header: HeaderData = [
    {
      label: t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.number),
      value: note.createdBy.phoneNumber || t(generalMessages.noData),
    },
    noteDate,
    noteUser,
  ];

  if (isEdited)
    header.push({
      label: '',
      value: <CreatedByTooltip note={note} />,
    });

  const description = note.text;

  return (
    <BoxWithHeader
      description={description}
      header={header}
      headerElement={
        <Button className={classes.button} disabled={disabled} size='small' variant='outlined' onClick={updateNote}>
          {t(proECareAlarmMessages.edit)}
        </Button>
      }
    />
  );
};

export default ECareBitrixNotes;
