import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { PersonalData, PersonalDataUnassigned, DefaultApiResponse } from './_types';

const endpoints = {
  personalData: 'api/v1/personal-data/',
};

export type UpdatePersonalDataInput = PersonalDataUnassigned;

type UpdatePersonalDataQuery = (data: UpdatePersonalDataInput) => Promise<DefaultApiResponse<PersonalData>>;

const patients = {
  putPersonalData:
    (id: number): UpdatePersonalDataQuery =>
    data =>
      axios.put<UpdatePersonalDataQuery, DefaultApiResponse<PersonalData>>(`${endpoints.personalData}${id}/`, data),
  patchPersonalData:
    (id: number, isPro: boolean): UpdatePersonalDataQuery =>
    data =>
      axios.patch<UpdatePersonalDataQuery, DefaultApiResponse<PersonalData>>(
        createUrlWithParams({ url: `${endpoints.personalData}${id}/`, params: { pro: isPro }, removeEmptyParams: true }),
        data,
      ),
};

export default patients;
