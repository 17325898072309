import { makeStyles } from 'tss-react/mui';

const ALERT_SECTION = 'alertSection';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'block',
    padding: theme.spacing(2),
  },
  value: {
    marginTop: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  standardColumnWrapper: {
    display: 'grid',
    alignContent: 'flex-start',
  },
  cardContainer: {
    paddingBottom: `${theme.spacing(0.5)} !important`,
  },
  container: {
    display: 'grid',
    gridGap: theme.spacing(3, 4),
    alignContent: 'flex-start',
    gridTemplateColumns: '1fr 2fr',
    marginBottom: theme.spacing(3),
  },
  alertSection: {
    gridArea: ALERT_SECTION,
    gridGap: 1,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    button: {
      maxWidth: '50%',
    },
  },
  patientButton: {
    justifySelf: 'flex-start',
  },
  ddlWrapper: {
    display: 'flex',
    gap: 20,
  },
  borderRight: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(3),
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  definitionListRoot: {
    gap: 20,
  },
  icon: {
    width: '15px',
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

export default useStyles;
