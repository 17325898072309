import type { FC } from 'react';

import { Typography } from '@mui/material';

import useStyles from './PatientCardSectionTitle.styles';

export interface Props {
  titleText: string;
  extraLeftMargin?: boolean;
  marginBottom?: number;
}

const PatientCardSectionTitle: FC<Props> = ({ titleText, extraLeftMargin = false, marginBottom = 0 }) => {
  const { classes } = useStyles({ extraLeftMargin, marginBottom });

  return (
    <Typography className={classes.header} component='h3' variant='h2'>
      {titleText}
    </Typography>
  );
};

export default PatientCardSectionTitle;
