import { useCallback } from 'react';

export const useRefreshPage = () => {
  const handleRefreshPage = useCallback(() => {
    // Workaround because window.location.reload(true) is deprecated: https://stackoverflow.com/a/65544086

    // eslint-disable-next-line
    window.location.href = window.location.href;
  }, []);

  return handleRefreshPage;
};
