import { Receivers } from 'constants/receiverDictionary/receiverDictionary';

import { PatientDTO, PatientFE } from '../patient/patient.types';

export const ECareContactNoteCallInitiatorDict = ['CT24', 'CUS', 'OPS'] as const;
export type ECareContactNoteCallInitiatorT = typeof ECareContactNoteCallInitiatorDict[number];

export const ECareReceiversResultDict = ['CT24', 'CUS', 'OPS', 'WYK'] as const;

export type ECareContactNoteDTO = {
  id: string;
  patient: PatientDTO | null;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string | null;
  last_sync: string | null;
  date: string;
  call_initiator: ECareContactNoteCallInitiatorT;
  receivers: Receivers[];
  content: string;
  order: string;
  service_recipient: string;
};

export type ECareContactNote = {
  id: string;
  patient: PatientFE | null;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string | null;
  lastSync: Date | null;
  date: Date;
  callInitiator: ECareContactNoteCallInitiatorT;
  receivers: Receivers[];
  content: string;
  order: string;
  serviceRecipient: string;
};

export type CreateECareContactNotesInput = {
  service_recipient_id: string;
  content: string;
  order: string;
  receivers: Receivers[];
};

export type UpdateECareContactNotesInput = {
  service_recipient_id?: string;
  content?: string;
  order?: string;
  receivers?: Receivers[];
};
