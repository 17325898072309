import type { FC } from 'react';

import { FormHelperText, Typography } from '@mui/material';

import resolvedTheme from 'styles/resolvedTheme';

export type Props = {
  helperText?: string;
  error?: string | boolean;
  className?: string;
  name?: string;
};

const InputFooter: FC<Props> = ({ helperText, error, className, name }) => (
  <FormHelperText className={className}>
    <Typography color='error' data-testid={`${name}_error`} variant='caption'>
      {error}
    </Typography>
    {!!error && !!helperText && ' - '}
    <Typography color={resolvedTheme.palette.grey[500]} variant='caption'>
      {helperText}
    </Typography>
  </FormHelperText>
);

export default InputFooter;
