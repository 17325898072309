import type { FC } from 'react';
import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PasswordValidation from 'components/UI/molecules/PasswordValidation/PasswordValidation';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import FormInputPassword from 'components/UI/organisms/_formInputs/FormInputPassword/FormInputPassword';
import useResetPasswordMutation from 'hooks/_mutations/useResetPasswordMutation/useResetPasswordMutation';
import authMessages from 'translations/common/auth.mjs';
import generalMessages from 'translations/common/general.mjs';
import resetPassword from 'translations/specific/resetPassword.mjs';

type Props = {
  uid: string;
  token: string;
};

export type ResetPasswordFormInput = {
  new_password: string;
  re_new_password: string;
};

const ResetPasswordForm: FC<Props> = ({ token, uid }) => {
  const { t } = useTranslation();

  const [passwordValidators, setPasswordValidators] = useState<boolean>(false);

  const { control, handleSubmit, getValues, setError, formState, watch } = useForm<ResetPasswordFormInput>();
  watch();

  const { resetPasswordMutate, isResetPasswordMutating, resetPasswordMutationErrors } = useResetPasswordMutation({ setError });

  const onSubmit: SubmitHandler<ResetPasswordFormInput> = async data => {
    resetPasswordMutate({ ...data, uid, token });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography align='center' component='h1' marginBottom={2} variant='h3'>
        {t(resetPassword.header_form)}
      </Typography>
      <SimpleFormWrapper formState={formState} globalErrors={resetPasswordMutationErrors}>
        <FormInputPassword control={control} label={t(authMessages.new_password)} name='new_password' required />
        <FormInputPassword control={control} label={t(authMessages.repeat_new_password)} name='re_new_password' required />
        <PasswordValidation
          password={getValues().new_password}
          rePassword={getValues().re_new_password}
          setIsValid={setPasswordValidators}
        />
        <LoadingButton
          data-testid='submit_button'
          disabled={!passwordValidators}
          loading={isResetPasswordMutating}
          type='submit'
          variant='contained'
        >
          {t(generalMessages.submit)}
        </LoadingButton>
      </SimpleFormWrapper>
    </form>
  );
};

export default ResetPasswordForm;
