type PossibleValues = string | number | undefined | null;

/**
 * This function should be used to create a unique query key in react-query
 * It helps to unify keys and prevent falsy mismatch
 * Also has positive impact on debugging possibilities
 * @param keyObject
 */
const createQueryKeyFromObject = (keyObject: Record<string, PossibleValues | PossibleValues[]>): string => {
  const result = Object.keys(keyObject)
    .sort()
    .reduce((accumulator, key) => {
      const currentValue = keyObject[key];
      return {
        ...accumulator,
        [key]: Array.isArray(currentValue) ? currentValue.sort() : currentValue,
      };
    }, {});

  return JSON.stringify(result);
};
export default createQueryKeyFromObject;
