import { isNil } from 'lodash';

import type { UrlQueryParams, UrlQueryParamsWithPrimitives, UrlQueryParamsWithArrays, UrlQueryParamValue } from 'constants/_types/Params';

import { prepareFinalUrl } from './_services/prepareFinalUrl/prepareFinalUrl';

export type UrlPreparationData = {
  url: string;
  params?: UrlQueryParams;
  removeEmptyParams?: boolean;
  shouldReturnFullUrl?: boolean;
};

type CreateUrlWithParams = (obj: UrlPreparationData) => string;

const createUrlWithParams: CreateUrlWithParams = ({ url, params, removeEmptyParams = true, shouldReturnFullUrl = true }) => {
  if (!url) throw new Error('Url is required');
  if (!params) return `${url}`;

  // Separating params into two categories
  const { paramsWithPrimitives, paramsWithArrays } = Object.entries(params).reduce<{
    paramsWithPrimitives: UrlQueryParamsWithPrimitives;
    paramsWithArrays: UrlQueryParamsWithArrays;
  }>(
    (acc, [paramKey, paramValue]) => {
      if (Array.isArray(paramValue)) return { ...acc, paramsWithArrays: { ...acc.paramsWithArrays, [paramKey]: paramValue } };
      return { ...acc, paramsWithPrimitives: { ...acc.paramsWithPrimitives, [paramKey]: paramValue } };
    },
    { paramsWithPrimitives: {}, paramsWithArrays: {} },
  );

  // Removing empty params
  if (removeEmptyParams) {
    Object.entries(paramsWithPrimitives).forEach(([key, value]) => {
      // eslint-disable-next-line no-param-reassign
      if (isNil(value) || value === '') delete paramsWithPrimitives[key];
    });

    Object.entries(paramsWithArrays).forEach(([key, arrayValue]) => {
      // eslint-disable-next-line no-param-reassign
      if (!arrayValue.length) delete paramsWithArrays[key];
    });
  }

  // Attaching params with primitives to search params
  const urlParams = new URLSearchParams(paramsWithPrimitives as unknown as URLSearchParams);

  // Attaching params with arrays to search params
  Object.entries(paramsWithArrays).forEach(([key, arrayValue]) => {
    const arrayValueWithNonEmptyItems = arrayValue.filter(arrayValueItem => !isNil(arrayValueItem)) as Exclude<
      UrlQueryParamValue,
      undefined | null
    >[];
    const isEmptyParamArray = !arrayValueWithNonEmptyItems.length;
    if (isEmptyParamArray) return;

    arrayValueWithNonEmptyItems.forEach(arrayValueItem => {
      urlParams.append(key, arrayValueItem.toString());
    });
  });

  return prepareFinalUrl(url, urlParams, shouldReturnFullUrl);
};

export default createUrlWithParams;
