import type { Theme } from '@mui/material';

export const prepareMobileRowCustomStyles = (theme: Theme) => ({
  cardPadding: theme.spacing(4),

  label: {
    color: theme.palette.grey[500],
    alignSelf: 'flex-start',
  },

  rowItem: {
    marginLeft: theme.spacing(3),
  },
});
