import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '17px auto',
      gridGap: theme.spacing(1),
    },
  },

  label: {
    color: theme.palette.grey[500],

    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
    },
  },
}));

export default useStyles;
