import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormInputSwitch from 'components/UI/organisms/_formInputs/FormInputSwitch/FormInputSwitch';
import userSettingsMessages from 'translations/specific/userSettings.mjs';

import TwoFASwitchConfirmationDialog from './_components/TwoFASwitchConfirmationDialog/TwoFASwitchConfirmationDialog';
import { TWO_FA_INPUT_KEYS } from './_constants/inputKeys';
import { useInitialValue } from './_hooks/useInitialValue/useInitialValue';
import { useTwoFAForm } from './_hooks/useTwoFAForm/useTwoFAForm';

const TwoFASwitch: FC = () => {
  const { t } = useTranslation();
  const [isModalShown, setIsModalShown] = useState(false);

  const { isEnforcedByBranding, initialValue } = useInitialValue();

  const { form, switchState, isLoading, handleAbortSubmission, handleConfirmSubmission } = useTwoFAForm({
    fetchedInitialValue: initialValue,
    isEnforcedByBranding,
    setIsModalShown,
  });

  const label = useMemo(
    () => (switchState ? t(userSettingsMessages.twoFA.labelOff) : t(userSettingsMessages.twoFA.labelOn)),
    [switchState, t],
  );

  return (
    <>
      <FormInputSwitch
        control={form.control}
        inputProps={{ disabled: isLoading || isEnforcedByBranding }}
        label={label}
        name={TWO_FA_INPUT_KEYS.twoFA}
      />
      {isEnforcedByBranding && <Typography variant='caption'>{t(userSettingsMessages.twoFA.forcedInfo)}</Typography>}

      {isModalShown && (
        <TwoFASwitchConfirmationDialog close={handleAbortSubmission} confirm={handleConfirmSubmission} isOpened={isModalShown} />
      )}
    </>
  );
};

export default TwoFASwitch;
