import { getServiceSubtypeDetails } from './getServiceSubtypeDetails/getServiceSubtypeDetails';
import { getServiceSubtypes } from './getServiceSubtypes/getServiceSubtypes';

export const endpoint = '/api/v1/core-service-subtypes/';

const coreServiceSubtypes = {
  getServiceSubtypes,
  getServiceSubtypeDetails,
};

export default coreServiceSubtypes;
