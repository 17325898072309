import type { FC } from 'react';

import ServicesSearchContextProvider from 'components/context/ServicesSearchContext/ServicesSearchContext';
import ServicesTableView from 'components/UI/organisms/ServicesTableView/ServicesTableView';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';

const ServicesPage: FC = () => {
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();

  if (!mainFunctionalitiesAllowed) return null;

  return (
    <ServicesSearchContextProvider>
      <ServicesTableView enableQueries={mainFunctionalitiesAllowed} />
    </ServicesSearchContextProvider>
  );
};

export default ServicesPage;
