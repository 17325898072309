import type { FC } from 'react';
import { memo } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useStyles from './BreadcrumbItem.styles';

export interface Props {
  translationArray: string[];
  translationParams?: Record<string, string | number>;
  path: string;
  isCurrent: boolean;
}

const BreadcrumbItem: FC<Props> = ({ translationArray, path, isCurrent, translationParams = {} }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {isCurrent ? (
        <Typography color='secondary' variant='caption'>
          {t(translationArray, translationParams)}
        </Typography>
      ) : (
        <Link className={classes.link} to={path}>
          <Typography color='secondary' variant='caption'>
            {t(translationArray, translationParams)}
          </Typography>
        </Link>
      )}
    </div>
  );
};

export default memo(BreadcrumbItem);
