const proECareDevicesMessages = {
  title: [ 'proECareDevicesMessages>>title', 'Urządzenia' ],
  columns: {
    deviceType: [ 'proECareDevicesMessages>>columns>>deviceType', 'Typy urządzeń' ],
    name: [ 'proECareDevicesMessages>>columns>>name', 'Nazwa urządzenia' ],
    serialNumber: [ 'proECareDevicesMessages>>columns>>serialNumber', 'Numer seryjny' ],
    simNumber: [ 'proECareDevicesMessages>>columns>>simNumber', 'Numer sim' ],
    description: [ 'proECareDevicesMessages>>columns>>description', 'Opis' ],
    dateFrom: [ 'proECareDevicesMessages>>columns>>dateFrom', 'Data od' ],
    dateTo: [ 'proECareDevicesMessages>>columns>>dateTo', 'Data do' ],
    dateFromTo: [ 'proECareDevicesMessages>>columns>>dateFromTo', 'Data od-do' ],
  }
};

export default proECareDevicesMessages;
