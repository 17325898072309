import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'grid',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(3),
    alignItems: 'flex-end',
    marginBottom: theme.spacing(0.5),
  },
  noPadding: {
    padding: 0,
  },
  definitionListRoot: {
    gap: 20,
  },
}));

export default useStyles;
