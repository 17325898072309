import create from 'zustand';
import { devtools } from 'zustand/middleware';

import isProduction from 'helpers/isProduction/isProduction';

import payerChoiceStorage from './_payerChoiceStorageBase';

const useUserPayersStorage = isProduction() ? create(payerChoiceStorage) : create(devtools(payerChoiceStorage));

export default useUserPayersStorage;
