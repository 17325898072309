import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      '&:first-of-type': {
        marginTop: 0,
      },
    },
  },
  contactBox: {
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    marginLeft: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      borderLeft: 'unset',
      marginLeft: theme.spacing(0),
    },
  },
  image: {
    width: '100%',
    maxWidth: '125px',
  },
  phoneNumber: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      'a[href*="tel:"]': {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  },
  email: {
    display: 'block',
    wordBreak: 'break-word',
  },
}));

export default useStyles;
