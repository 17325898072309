import type { FC } from 'react';
import { useMemo } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import { getResolvedTitleIcon } from 'components/pages/OrderServicePage/_services/getResolvedTitleIcon/getResolvedTitleIcon';
import CancelButton from 'components/UI/molecules/BottomFixedContainer/_components/CancelButton/CancelButton';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import FormGlobalError from 'components/UI/organisms/FormGlobalError/FormGlobalError';
import type { DashboardButtonOrderServiceType } from 'constants/_types/DashboardButtons';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import type { SelectedServices } from 'constants/_types/SelectedServices';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import { useGetSickCardButtons } from 'hooks/_dashboardButtons/useGetSickCardButtons/useGetSickCardButtons';
import generalMessages from 'translations/common/general.mjs';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import OrderDetailsConfirmationDialogContent from './_components/OrderDetailsConfirmationDialogContent/OrderDetailsConfirmationDialogContent';
import useStyles from './OrderDetailsConfirmationDialog.styles';

export const testId = createTestIdObject('OrderDetailsConfirmationDialog', {
  confirmButton: 'confirmButton',
  cancelButton: 'cancelButton',
});

export interface Props {
  isOpened: boolean;
  isSubmitting: boolean;
  submissionErrors: string[] | undefined;
  close: () => void;
  handleSubmit: () => void;
}

const OrderDetailsConfirmationDialog: FC<Props> = ({ isOpened, isSubmitting, submissionErrors, close, handleSubmit }) => {
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { form } = useOrderServiceFormContext();

  const paramOrigin = useMemo(() => searchParams.get('origin') as OrderServiceOrigins, [searchParams]);

  const { sickCardButtons } = useGetSickCardButtons() as { sickCardButtons: DashboardButtonOrderServiceType[] };
  const iconResolver = useMemo(() => () => getResolvedTitleIcon(sickCardButtons, paramOrigin), [sickCardButtons, searchParams]);

  const selectedServices = useMemo(() => form.getValues().details as SelectedServices, []);

  return (
    <DialogWrapper
      close={close}
      customActions={
        <div className={classes.buttonsContainer}>
          <CancelButton action={close} dataTestId={testId.cancelButton} />
          <LoadingButton
            data-testid={testId.confirmButton}
            disabled={isSubmitting}
            loading={isSubmitting}
            variant='contained'
            onClick={handleSubmit}
          >
            {t(generalMessages.confirm)}
          </LoadingButton>
        </div>
      }
      dialogProps={{ maxWidth: 'sm', disablePortal: true, disableEnforceFocus: true }}
      header={t(orderServiceMessages[paramOrigin].confirmationTitle)}
      icon={iconResolver}
      isOpen={isOpened}
    >
      <FormGlobalError errors={submissionErrors} />
      <OrderDetailsConfirmationDialogContent selectedServices={selectedServices} />
    </DialogWrapper>
  );
};

export default OrderDetailsConfirmationDialog;
