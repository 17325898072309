import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import type { SupportedLanguages } from 'constants/translations/_types';

import { parseLanguageForBE } from '../parseLanguageForBE/parseLanguageForBE';
import type { LanguageDto } from '../types';
import { endpoints } from '../users';

export type UpdateUserLanguageResponse = LanguageDto;
type UpdateUserLangaugeQuery = (languageCode: SupportedLanguages) => Promise<DefaultApiResponse<UpdateUserLanguageResponse>>;

export const updateUserLanguage = (): UpdateUserLangaugeQuery => languageCode => {
  const parsedData = parseLanguageForBE(languageCode);

  return axios.post<UpdateUserLangaugeQuery, DefaultApiResponse<UpdateUserLanguageResponse>>(endpoints.updateUserLanguage, parsedData);
};
