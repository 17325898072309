import { makeStyles } from 'tss-react/mui';

import { SCROLLBAR_STYLING } from 'constants/styles/SCROLLBAR_STYLING';

const useStyles = makeStyles()(theme => ({
  root: {
    height: '165px',
    overflowY: 'auto',
    border: `thin solid ${theme.palette.grey[200]}`,
    display: 'grid',
    gap: theme.spacing(1.5),
    alignContent: 'flex-start',
    ...SCROLLBAR_STYLING(theme),
  },

  search: {
    position: 'sticky',
    top: theme.spacing(-1.5),
    background: theme.palette.background.paper,
    padding: theme.spacing(2, 2, 0.5, 2),
    zIndex: 1,
  },
}));

export default useStyles;
