import type { FC } from 'react';

import { Divider, Typography } from '@mui/material';

import SummaryListItem from 'components/UI/molecules/SummaryListItem/SummaryListItem';
import type { PreselectingOrderServiceFormMetaData } from 'constants/_types/PreselectingOrderServiceFormMetaData';
import type { SelectedServiceItemMetaData } from 'constants/_types/SelectedServiceItemMetaData';
import type { SummaryListItem as TSummaryListItem } from 'constants/_types/SummaryList';

import useStyles from './SummaryListGroup.styles';

export interface Props {
  items: TSummaryListItem[];
  header: string;
  isSearchResult?: boolean;
  clickCallback?: (data: PreselectingOrderServiceFormMetaData) => void;
  removeItem?: (metaData: SelectedServiceItemMetaData) => void;
  isLast: boolean;
}

const SummaryListGroup: FC<Props> = ({ items, header, isSearchResult = false, clickCallback, removeItem, isLast }) => {
  const { classes } = useStyles({ isSearchResult });

  if (!items.length) return null;

  return (
    <div className={classes.root}>
      <Typography className={classes.header} component='h5' variant='caption'>
        {header}
      </Typography>
      <div className={classes.sectionItemsContainer}>
        {items.map(({ id, name: { key, defaultValue }, coreServiceKind, subtype }) => (
          <SummaryListItem
            clickCallback={clickCallback}
            id={id}
            key={`${id}`}
            kind={coreServiceKind}
            removeItem={removeItem}
            serviceOrigin={subtype?.serviceOrigin}
            serviceSubtypeId={subtype?.id}
            serviceTypeId={subtype?.masterType}
            translationArray={[key, defaultValue]}
          />
        ))}
      </div>
      {!isLast && <Divider />}
    </div>
  );
};

export default SummaryListGroup;
