import { makeStyles } from 'tss-react/mui';

import ICON_SIZES_STYLES from 'constants/styles/ICON_SIZES_STYLES';

const useStyles = makeStyles<{ tiny?: boolean; error?: boolean }>()((theme, { tiny, error }) => ({
  root: {
    position: 'relative',
  },
  title: {
    position: 'absolute',
    top: 0,
    color: theme.palette.grey[500],
    fontSize: '.75rem',
    transform: 'translateY(-100%)',
  },
  footer: {
    transform: 'translateY(-5px)',
  },
  label: {
    padding: theme.spacing(1.75, 0),
    fontSize: tiny ? '.75rem' : '1rem',
    '& > span': {
      display: 'flex',
    },
  },
  asterisk: {
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
  icon: {
    width: tiny ? ICON_SIZES_STYLES.small.width : ICON_SIZES_STYLES.root.width,
    height: tiny ? ICON_SIZES_STYLES.small.height : ICON_SIZES_STYLES.root.height,
  },
}));

export default useStyles;
