import { useMemo, type FC } from 'react';

import type { Control, ValidationRule } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Mask } from 'components/UI/molecules/InputText/InputText';
import InputText from 'components/UI/molecules/InputText/InputText';
import { InputSizes } from 'constants/_types/InputSizes';
import validationMessages from 'translations/common/validation.mjs';

import useStyles from './FormInputText.styles';

type Props = {
  label: string;
  name: string;
  control: Control<any>;
  helperText?: string;
  required?: boolean;
  defaultValue?: string;
  customError?: string;
  rules?: ValidationRule<any>;
  inputProps?: {
    fullWidth?: boolean;
    multiline?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
  };
  mask?: Mask;
  rows?: number;
  multiline?: boolean;
  size?: InputSizes;
};

const FormInputText: FC<Props> = ({
  control,
  label,
  helperText,
  name,
  defaultValue = '',
  inputProps = {},
  rules = {},
  required = false,
  customError,
  mask,
  rows = 1,
  multiline = false,
  size = 'medium',
}) => {
  const { classes } = useStyles({ size });
  const { t, i18n } = useTranslation();

  const rulesProp: { [key: string]: ValidationRule } = useMemo(
    () => ({ required: { value: required, message: t(validationMessages.required_field) }, ...rules }),
    [rules, required, i18n.language],
  );
  const inputPropsFinal = useMemo(() => ({ ...inputProps, rows }), []);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => (
        <InputText
          classes={{ label: classes.label, input: classes.input }}
          error={customError || fieldState?.error?.message}
          helperText={helperText}
          label={label}
          mask={mask}
          multiline={multiline}
          {...inputPropsFinal}
          {...field}
        />
      )}
      rules={rulesProp}
    />
  );
};

export default FormInputText;
