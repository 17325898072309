import { isFuture } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ECareSuspension } from 'api/eCareSuspension/eCareSuspension.types';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

const useTheNearestSuspension = (suspensions: ECareSuspension[] | null) => {
  const { t } = useTranslation();

  if (!suspensions) return null;

  const referenceDate = new Date();
  const nearestSuspension = suspensions
    .filter(value => isFuture(value.dateTo))
    .reduce((closest, suspension) => {
      const dateTo = new Date(suspension.dateTo).getTime();
      const referenceTime = new Date(referenceDate).getTime();
      const currentDiff = Math.abs(dateTo - referenceTime);
      const closestDiff = Math.abs(new Date(closest.dateTo).getTime() - referenceTime);
      return currentDiff < closestDiff ? suspension : closest;
    }, suspensions[0]);

  const nearestSuspensionForDefinition = [
    {
      label: t(proECareOrderMessages.suspensions.dateFrom),
      value: nearestSuspension?.dateFrom ? unknownDateToString(nearestSuspension.dateFrom) : t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.suspensions.dateTo),
      value: nearestSuspension?.dateTo ? unknownDateToString(nearestSuspension.dateTo) : t(generalMessages.noData),
    },
    { label: t(proECareOrderMessages.suspensions.reason), value: nearestSuspension?.reason || t(generalMessages.noData) },
  ];

  return { nearestSuspension, nearestSuspensionForDefinition };
};

export default useTheNearestSuspension;
