import type { FC } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow-short.svg';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import type { FormActionButtonProps } from 'storages/bottomFixedContainerStorage';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './CancelButton.styles';

export const testId = createTestIdObject('CancelButton', {
  button: 'button',
});

export type Props = FormActionButtonProps & { dataTestId?: string };

const CancelButton: FC<Props> = ({ action, dataTestId, disabled, hasLeftArrow, label }) => {
  const { t } = useTranslation();
  const { globalFormLoading } = useBottomFixedContainerStorage();
  const { classes } = useStyles({ hasArrow: hasLeftArrow });

  return (
    <Button
      className={classes.previousButton}
      data-testid={dataTestId || testId.button}
      disabled={globalFormLoading || disabled}
      variant='outlined'
      onClick={action}
    >
      {hasLeftArrow && <LeftArrowIcon className={classes.arrow} />} {label || t(generalMessages.cancel)}
    </Button>
  );
};

export default CancelButton;
