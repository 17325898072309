// TODO: replace with a generic component once it has been developed
// https://mdtnet.atlassian.net/jira/software/projects/MDT/boards/1?selectedIssue=MDT-1054
import { FC, useMemo } from 'react';

import { SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import ECarePhoneCall from 'api/eCarePhoneCall/eCarePhoneCall';
import { eCarePhoneCallStatusDictionary, PhoneCallStatus } from 'api/eCarePhoneCall/eCarePhoneCall.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import InputDropdown from 'components/UI/molecules/InputDropdown/InputDropdown';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

import useStyles from './ECarePhoneCallStatusDropdown.styles';

export type Props = {
  phoneCallId: string;
};

const ECarePhoneCallStatusDropdown: FC<Props> = ({ phoneCallId }) => {
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_STATUS_PHONE_CALL, phoneCallId], [phoneCallId]);
  const options = Object.entries(eCarePhoneCallStatusDictionary).map(([value, labelKey]) => ({
    value,
    label: t(labelKey),
  }));

  const statusMutation = useMutation(ECarePhoneCall.patchEcarePhoneCall(phoneCallId), {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, newStatus);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { isLoading, data } = useQuery(queryKey, ECarePhoneCall.getEcarePhoneCall(phoneCallId));

  const onStatusChange = async ({ target }: SelectChangeEvent<unknown>) => {
    const confirmed = await showConfirmationDialog({
      title: t(ecarePhoneCallMessages.confirmationDialog.confirmationDialogTitle),
      body: t(ecarePhoneCallMessages.confirmationDialog.confirmationDialogBody),
    });
    if (confirmed)
      statusMutation.mutate({
        // The status dropdown list is restricted to include only values defined in the ECareStatusT type
        status: target.value as PhoneCallStatus,
      });
  };

  return (
    <div>
      <Typography component='label' variant='body2'>
        {t(ecarePhoneCallMessages.phoneCallStatus)}
      </Typography>
      <InputDropdown disabled={isLoading} fullWidth options={options} value={data?.status || ''} onChange={onStatusChange} />
    </div>
  );
};

export default ECarePhoneCallStatusDropdown;
