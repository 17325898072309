import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { isEqual } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import apiContentType from 'api/apiContentType/apiContentType';
import eCareSosPcInternalNotes from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes';
import { ECareSosInternalNote } from 'api/eCareSosPcInternalNotes/eCareSosPcInternalNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import { DefinitionListValue } from '../DefinitionList/DefinitionList';
import BitrixList from './_components/BitrixList/BitrixList';
import CreatedByTooltip from './_components/ECareBitrixNotes/_components/CreatedByTooltip/CreatedByTooltip';
import useBitrixTable from './_hooks/useBitrixTable/useBitrixTable';
import useStyles from './BitrixNotes.styles';

type FormInput = {
  text: string;
};

type Props = {
  objectId: string;
  contentType: 'ecaresos' | 'phonecall';
  disabled?: boolean;
};

type BasicHeaderData = {
  label: string;
  value: DefinitionListValue | DefinitionListValue[];
}[];

const STALE_TIME = 1000 * 60 * 2;

const BitrixNotes: FC<Props> = ({ objectId, contentType, disabled }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const contentTypesQueryKey = useMemo(() => [QUERY_KEYS.GET_API_CONTENT_TYPES, objectId], [objectId]);
  const { data: contentTypes } = useQuery(contentTypesQueryKey, apiContentType.getAllContentTypes(), {
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME,
  });

  const eCareSosObj = useMemo(
    () => contentTypes && contentTypes.find(el => el.model === contentType && el.app_label === 'e_care'),
    [contentTypes],
  );

  const queryKey = useMemo(() => [QUERY_KEYS.GET_BITRIX_NOTES, objectId], [objectId]);
  const { data: notesData } = useQuery(queryKey, eCareSosPcInternalNotes.getAllNotes(objectId), { refetchOnWindowFocus: false });

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareSosPcInternalNotes.createNote(), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues, reset } = useForm<FormInput>();

  const addNewNote = async () => {
    if (!eCareSosObj || !objectId) return;
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionBitixAddNoteTitle),
      body: <FormInputText control={control} label='' multiline name='text' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed) {
      await statusMutation.mutateAsync({
        text: getValues('text'),
        object_id: objectId,
        content_type: eCareSosObj.id,
      });
    }

    // reset form
    reset();
  };

  const alarmsPartnerNotesTable = useBitrixTable(notesData?.data as ECareSosInternalNote[]);
  const header = (note: ECareSosInternalNote) => {
    const isEdited = !isEqual(note.creationTime, note?.updateTime);

    const basicHeaderData: BasicHeaderData = [
      {
        label: t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.number),
        value: note.createdBy.phoneNumber || t(generalMessages.noData),
      },
      {
        label: isEdited
          ? t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.updatedTime)
          : t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.creationTime),
        value: unknownDateToString(note.creationTime, DATE_FORMATS.DISPLAY),
      },
      {
        label: isEdited
          ? t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.updatedBy)
          : t(proECareAlarmMessages.eCareAlarmsInternalNotesTable.user),
        value: note.createdBy.email,
      },
    ];

    if (isEdited)
      basicHeaderData.push({
        label: '',
        value: <CreatedByTooltip note={note} />,
      });

    return basicHeaderData;
  };

  const showNotesHistory = () => {
    showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionBitixNoteHistoryTitle),
      body:
        alarmsPartnerNotesTable?.data &&
        alarmsPartnerNotesTable.data.map(note => (
          <BoxWithHeader className={classes.boxList} description={note.text} header={header(note)} key={note.id} />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  return (
    <SectionWithTitle
      className={classes.section}
      title={t(proECareAlarmMessages.interventionBitixNoteTitle)}
      titleIcon={
        <Button disabled={disabled} variant='contained' onClick={addNewNote}>
          {t(proECareAlarmMessages.alarmCard.buttons.addNote)}
        </Button>
      }
      titleRightSideComponent={
        notesData &&
        notesData.data.length > 0 && (
          <Button variant='outlined' onClick={showNotesHistory}>
            {t(proECareAlarmMessages.interventionBitrixNoteMore)}
          </Button>
        )
      }
    >
      {notesData && <BitrixList data={notesData.data as ECareSosInternalNote[]} disabled={disabled} />}
    </SectionWithTitle>
  );
};

export default BitrixNotes;
