import { useEffect, type FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import FormInputDropdown from 'components/UI/organisms/_formInputs/FormInputDropdown/FormInputDropdown';
import type { DynamicTranslatableName } from 'constants/_types/DynamicTranslatableName';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import ReferralSection from '../ReferralSection/ReferralSection';
import ServiceDates from '../ServiceDates/ServiceDates';
import { useCurrentPayer } from './_hooks/useCurrentPayer/useCurrentPayer';
import { useDetailsItemPrepareValidationRules } from './_hooks/useDetailsItemPrepareValidationRules/useDetailsItemPrepareValidationRules';
import { usePayersByServiceQuery } from './_hooks/usePayersByServiceQuery/usePayersByServiceQuery';
import { useReferralSectionQuery } from './_hooks/useReferralSectionQuery/useReferralSectionQuery';
import { useSetServiceIdAsFormValue } from './_hooks/useSetServiceIdAsFormValue/useSetServiceIdAsFormValue';
import useStyles from './DetailsItem.styles';

export interface Props {
  isCumulative: boolean;
  kind: OrderServiceCoreServicesKinds;
  name: DynamicTranslatableName;
  serviceItemId: number;
}

const DetailsItem: FC<Props> = ({ isCumulative, kind, name, serviceItemId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ isCumulative });
  const { form } = useOrderServiceFormContext();

  useSetServiceIdAsFormValue({ kind, serviceItemId });

  const { payersByService } = usePayersByServiceQuery({ serviceItemId });

  const payersFormInputKey = `details.${kind}.serviceItems._${serviceItemId}.payer`;

  const { currentPayerId, interceptCurrentPayer } = useCurrentPayer({ form, payersByService, payersFormInputKey });

  const { referralNeeded } = useReferralSectionQuery({ serviceItemId, currentPayerId });

  const validationRules = useDetailsItemPrepareValidationRules();

  useEffect(() => {
    form.setValue(`details.${kind}.serviceItems._${serviceItemId}.nameToDisplay`, t([name.key, name.defaultValue]) as never);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.itemName}>
        <Typography variant='subtitle3'>{t([name.key, name.defaultValue])}</Typography>
      </div>
      {!isCumulative && <ServiceDates kind={kind} serviceItemId={serviceItemId} />}
      <div className={classes.payer}>
        <FormInputDropdown
          control={form.control}
          label={t(orderServiceMessages.details.payer)}
          name={payersFormInputKey}
          options={payersByService}
          placeholder={t(orderServiceMessages.details.choose)}
          rules={validationRules}
          size='small'
          onValueUpdate={interceptCurrentPayer}
        />
      </div>
      {referralNeeded && <ReferralSection kind={kind} serviceItemId={serviceItemId} />}
    </div>
  );
};

export default DetailsItem;
