import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import REMEMBERED_PATH from 'constants/_queryParams/REMEMBERED_PATH';

export const useRememberedPathFromUrl = () => {
  const [searchParams] = useSearchParams();

  const rememberedPath = useMemo(() => searchParams.get(REMEMBERED_PATH) || undefined, [searchParams]);

  return { rememberedPath };
};
