import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OrderServiceBasketActions from 'components/UI/molecules/OrderServiceBasketActions/OrderServiceBasketActions';
import SummaryListGroup from 'components/UI/molecules/SummaryListGroup/SummaryListGroup';
import { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';
import { useServicesBasketStorage } from 'storages/serviceBasketStorage/servicesBasketStorage';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import useStyles from './OrderServiceBasket.styles';

export interface Props {
  showButtons?: boolean;
}

const OrderServiceBasket: FC<Props> = ({ showButtons }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { basketState: basket, removeFromBasket: removeItem } = useServicesBasketStorage();

  const content = basket ? (
    Object.keys(basket).map((coreServiceKind, index, array) => (
      <SummaryListGroup
        header={t(orderServiceMessages.basket.typesSections[coreServiceKind as OrderServiceCoreServicesKinds])}
        isLast={index === array.length - 1}
        items={basket[coreServiceKind as OrderServiceCoreServicesKinds]?.items || []}
        key={coreServiceKind}
        removeItem={removeItem}
      />
    ))
  ) : (
    <Typography component='h5' variant='caption'>
      {t(orderServiceMessages.basket.noItemsText)}
    </Typography>
  );

  return (
    <div className={classes.wrapper}>
      <Typography variant='h4'>{t(orderServiceMessages.basket.header)}</Typography>
      <div className={classes.sectionsContainer}>
        {content}
        {showButtons && <OrderServiceBasketActions basket={basket} />}
      </div>
    </div>
  );
};

export default OrderServiceBasket;
