import { makeStyles } from 'tss-react/mui';

import { InputSizes } from 'constants/_types/InputSizes';

const useStyles = makeStyles<{ size: InputSizes }>()((theme, { size }) => {
  const fontSize = size === 'small' ? '0.8125rem' : 'inherit';

  return {
    label: { fontSize },
    input: {
      '& > input': { fontSize },
      '& > textarea': { fontSize },
    },
  };
});

export default useStyles;
