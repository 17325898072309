import { useCallback, useEffect, useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import SelectableSearchableCell from 'components/UI/atoms/SelectableSearchableCell/SelectableSearchableCell';
import type { CellRendererType } from 'components/UI/molecules/ColumnsDisplaySet/_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';
import ColumnsDisplaySet from 'components/UI/molecules/ColumnsDisplaySet/ColumnsDisplaySet';
import SearchBar from 'components/UI/molecules/SearchBar/SearchBar';
import ServicesTopBar from 'components/UI/organisms/ServicesTopBar/ServicesTopBar';
import useSelectLogic from 'hooks/_userServices/useSelectLogic/useSelectLogic';
import servicesMessages from 'translations/specific/services.mjs';

import useSearch from '../ServicesTypeView/_hooks/useSearch/useSearch';
import { useGetServicesForGroup } from './ServicesGroupView.logic';

const ServicesGroupView: FC = () => {
  const { t } = useTranslation();

  const { groupData, services, isLoading } = useGetServicesForGroup();

  const { isSearchActive, checkIsMatched, onSearch } = useSearch();

  const dataToSearchIn = useMemo(() => (services ? Object.values(services.entities).flat() : undefined), [services?.entities]);
  const searchHandler = useCallback(
    (phrase: string) => {
      if (!dataToSearchIn) return;
      onSearch(phrase, dataToSearchIn);
    },
    [dataToSearchIn],
  );

  const { toggleSelected, checkIsSelected, handleAddToBasket, selected, batchSelect } = useSelectLogic();

  useEffect(() => {
    // select all on start
    if (services && groupData?.preselectedServices) {
      const allServicesIds = Object.values(services.entities).flatMap(type => type.map(({ id }) => id));
      batchSelect(allServicesIds);
    }
  }, [services, groupData]);

  const onAddToBasket = useCallback(() => {
    handleAddToBasket(services?.entities);
  }, [services?.entities, selected]);

  const cellRenderer: CellRendererType = useCallback(
    cellProps => (
      <SelectableSearchableCell
        checkIsMatched={checkIsMatched}
        checkIsSelected={checkIsSelected}
        isSearchActive={isSearchActive}
        toggleSelected={toggleSelected}
        {...cellProps}
      />
    ),
    [selected, toggleSelected, isSearchActive, checkIsMatched, checkIsSelected],
  );

  return (
    <>
      <SearchBar onSearch={searchHandler} />
      <ServicesTopBar
        disabledActionButton={!selected.length}
        title={groupData && [groupData.name.key, groupData.name.defaultValue]}
        onActionButtonClick={onAddToBasket}
      />
      <ColumnsDisplaySet
        cellRenderer={cellRenderer}
        cellSize={150}
        content={services}
        fallbackMessage={t(servicesMessages.noResultsForPaymentType)}
        isLoading={isLoading}
        showGrid
      />
    </>
  );
};

export default ServicesGroupView;
