import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parsePatientRelatedFilesForFE, parsePatientRelatedFilesForBE } from './patientRelatedFiles.parsers';
import { PatientRelatedFile, PatientRelatedFileDTO, PatientRelatedFileInput } from './patientRelatedFiles.types';

const endpoint = '/api/v1/patient-related-file/';

type GetPatientRelatedFilesQuery = () => Promise<PatientRelatedFile[]>;
type CreatePatientRelatedFilesMutation = (data: PatientRelatedFileInput) => Promise<PatientRelatedFile>;
type UpdatePatientRelatedFilesMutation = (params: {
  fileId: number;
  data: Partial<PatientRelatedFileInput>;
}) => Promise<PatientRelatedFile>;
type RemovePatientRelatedFilesMutation = (fileId: number) => Promise<null>;

const patientRelatedFiles = {
  getPatientRelatedFiles:
    (patientId: number, fileType?: string): GetPatientRelatedFilesQuery =>
    async () => {
      const { data } = await axios.get<GetPatientRelatedFilesQuery, PaginatedApiResponse<PatientRelatedFileDTO>>(
        createUrlWithParams({ url: endpoint, params: { limit: 100, patient: patientId, type: fileType } }),
      );
      return data.results.map(parsePatientRelatedFilesForFE);
    },
  createPatientRelatedFiles: (): CreatePatientRelatedFilesMutation => async inputData => {
    const { data } = await axios.post<CreatePatientRelatedFilesMutation, DefaultApiResponse<PatientRelatedFileDTO>>(
      endpoint,
      parsePatientRelatedFilesForBE(inputData),
    );
    return parsePatientRelatedFilesForFE(data);
  },
  updatePatientRelatedFiles:
    (): UpdatePatientRelatedFilesMutation =>
    async ({ fileId, data: inputData }) => {
      const { data } = await axios.patch<UpdatePatientRelatedFilesMutation, DefaultApiResponse<PatientRelatedFileDTO>>(
        `${endpoint}${fileId}/`,
        parsePatientRelatedFilesForBE(inputData),
      );
      return parsePatientRelatedFilesForFE(data);
    },
  removePatientRelatedFiles: (): RemovePatientRelatedFilesMutation => async id => {
    await axios.delete<UpdatePatientRelatedFilesMutation, DefaultApiResponse<PatientRelatedFileDTO>>(`${endpoint}${id}/`);
    return null;
  },
};

export default patientRelatedFiles;
