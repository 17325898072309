import { memo, useMemo } from 'react';

import { Search as DetailIcon } from '@mui/icons-material';
import { Button, Card } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import eCareTechnicalIssues from 'api/eCareTechnicalIssues/eCareTechnicalIssues';
import { ECareTechnicalIssues, ECareTechnicalIssuesListFilters } from 'api/eCareTechnicalIssues/eCareTechnicalIssues.types';
import { ReactComponent as ECareTechnicalIssuesListIcon } from 'assets/icons/hub/active/issues.svg';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import type { AvailableFilter } from 'constants/_types/AvailableFilter';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import useTableData from 'hooks/useTableData/useTableData';
import generalMessages from 'translations/common/general.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

import TechnicalIssueCreateDialog from '../_dialogs/TechnicalIssueCreateDialog/TechnicalIssueCreateDialog';
import useStyles from './TechnicalIssuesList.styles';

const TechnicalIssuesList = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isDialogOpen, openDialog, closeDialog] = useBooleanState(false);

  const { query, onSort, page, onFilter, onExactPage, filters, pagesCount } = useTableData<keyof ECareTechnicalIssues>({
    queryKeyBase: QUERY_KEYS.GET_ECARE_TECHNICAL_ISSUES,
    queryFunction: eCareTechnicalIssues.getAllECareTechnicalIssues(),
  });
  const { data } = query;

  const columnHelper = createColumnHelper<ECareTechnicalIssues>();

  const columns = [
    columnHelper.accessor('date', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.date) as string,
      id: 'date',
      cell: info => unknownDateToString(info.getValue() as Date, DATE_FORMATS.DISPLAY) || t(generalMessages.noData),
      meta: {
        sx: { maxWidth: '15ch' },
      },
    }),
    columnHelper.accessor('lastSync', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.lastSync) as string,
      id: 'last_sync',
      cell: info => (info.getValue() ? unknownDateToString(info.getValue() as Date, DATE_FORMATS.DISPLAY) : t(generalMessages.noData)),
      meta: {
        sx: { maxWidth: '15ch' },
      },
    }),
    columnHelper.accessor('device', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.device) as string,
      id: 'device',
      cell: info => `${info.getValue().name} (${info.getValue().serialNumber})`,
    }),
    columnHelper.accessor('createdBy', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.createdBy) as string,
      id: 'created_by',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('description', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.description) as string,
      id: 'description',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('fixDate', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDate) as string,
      id: 'fix_date',
      cell: info => (info.getValue() ? unknownDateToString(info.getValue() as Date, DATE_FORMATS.DISPLAY) : t(generalMessages.noData)),
      meta: {
        sx: { maxWidth: '15ch' },
      },
    }),
    columnHelper.accessor('fixDescription', {
      header: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDescription) as string,
      id: 'fix_description',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.display({
      id: 'detailsView',
      enableSorting: false,
      cell: memo((info: any) => (
        <Link
          className={classes.link}
          to={`/${generatePath(PATHS.PROFESSIONAL_ECARE_TECHNICAL_ISSUE_SINGLE, { issueId: `${info.row.original.id}` })}`}
        >
          <DetailIcon />
        </Link>
      )),
      meta: {
        sx: { maxWidth: '5ch' },
      },
    }),
  ];

  const availableFilters: AvailableFilter<ECareTechnicalIssuesListFilters>[] = useMemo(
    () => [
      {
        key: 'date',
        type: 'dateRange',
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.date) as string,
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(proECareTechnicalIssuesMessages.issueDetailLabel.date),
      },
      {
        key: 'last_sync',
        type: 'dateRange',
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.lastSync) as string,
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(proECareTechnicalIssuesMessages.issueDetailLabel.lastSync),
      },
      { key: 'device', type: 'string', label: t(proECareTechnicalIssuesMessages.issueDetailLabel.device) as string },
      { key: 'created_by', type: 'string', label: t(proECareTechnicalIssuesMessages.issueDetailLabel.createdBy) as string },
      { key: 'description', type: 'string', label: t(proECareTechnicalIssuesMessages.issueDetailLabel.description) as string },
      { key: 'fix_description', type: 'string', label: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDescription) as string },
      {
        key: 'fix_date',
        type: 'dateRange',
        label: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDate) as string,
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(proECareTechnicalIssuesMessages.issueDetailLabel.fixDate),
      },
    ],
    [],
  );

  const pageTitle = {
    headerLabel: t(proECareTechnicalIssuesMessages.title),
    icon: <ECareTechnicalIssuesListIcon />,
    extraContentComponent: (
      <Button size='small' variant='contained' onClick={openDialog}>
        {t(proECareTechnicalIssuesMessages.newIssue.addNew)}
      </Button>
    ),
    itemsCount: data?.count || 0,
    includeFilterButton: true,
  };

  const customFilter = {
    availableFilters,
    filters,
    onFilter,
  };

  return (
    <div>
      <PageTitleWithFiltersForTables customFilters={customFilter} pageTitle={pageTitle} />
      <Card>
        {/* Generic Component throws error, but idk why, it should work */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CustomTable<ECareTechnicalIssues>
          columns={columns}
          data={data?.data}
          page={page}
          pagesCount={pagesCount}
          onExactPage={onExactPage}
          onSort={onSort}
        />
      </Card>
      <TechnicalIssueCreateDialog close={closeDialog} isOpen={isDialogOpen} />
    </div>
  );
};

export default TechnicalIssuesList;
