import type { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import clausesMessages from 'translations/specific/clauses.mjs';

import useStyles from './NoClausesToShow.styles';

export const testId = createTestIdObject('NoClausesToShow', {
  noClausesInfo: 'noClausesInfo',
});

const NoClausesToShow: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Typography className={classes.noData} component='p' data-testid={testId.noClausesInfo} variant='captionBold'>
      {t(clausesMessages.noData)}
    </Typography>
  );
};

export default NoClausesToShow;
