import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 32px',
    gridGap: theme.spacing(1),
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  loggedInfo: {
    display: 'grid',
    justifyItems: 'flex-start',
    lineHeight: '1.125rem',
  },
  popperContent: {
    padding: theme.spacing(2),
    boxShadow: '0px 24px 24px #C8D3E367',
    display: 'grid',
    gap: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1, 2),
    display: 'grid',
    gridTemplateColumns: '32px auto',
  },
}));

export default useStyles;
