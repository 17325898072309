import type { FC } from 'react';

import { Card } from '@mui/material';

import useStyles from './FixedSizeCard.styles';

export type Props = {
  maxWidth: number;
  children: JSX.Element;
};

const FixedSizeCard: FC<Props> = ({ maxWidth, children }) => {
  const { classes } = useStyles({ maxWidth });
  return (
    <Card className={classes.root} data-testid='FixedSizeCard'>
      {children}
    </Card>
  );
};

export default FixedSizeCard;
