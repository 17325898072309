import type { FC } from 'react';

import { IMaskInput } from 'react-imask';

import type { Mask } from '../InputText';

export interface TextMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: Mask;
}

export const TextMask: FC<TextMaskProps> = ({ mask, onChange, name, ...rest }) => (
  <IMaskInput
    {...rest}
    definitions={mask.definitions}
    mask={mask.pattern}
    name={name}
    onAccept={(value: any) => onChange({ target: { name, value } })}
    // overwrite
  />
);
