import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { endpoint } from '../coreServiceGroups';
import type { CoreServiceForGroup, CoreServiceForGroupDTO } from '../coreServiceGroups.types';
import { parseServicesForGroupForFE } from '../parseServicesForGroupForFE/parseServicesForGroupForFE';

type GetServicesForGroupQuery = () => Promise<CoreServiceForGroup>;

type GetServicesGroupParams = { patientId: number; groupId: number; payers: string[] };

export const getServicesForGroup =
  ({ patientId, groupId, payers }: GetServicesGroupParams): GetServicesForGroupQuery =>
  async () => {
    const { data } = await axios.get<GetServicesForGroupQuery, DefaultApiResponse<CoreServiceForGroupDTO>>(
      createUrlWithParams({
        url: `${endpoint}${groupId}/`,
        params: { patient: patientId, payer: payers },
      }),
    );

    return parseServicesForGroupForFE(data);
  };
