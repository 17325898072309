import type { FC } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import symptomChecker from 'api/symptomChecker';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import GeneralErrorContent from 'components/root/GeneralError/_components/GeneralErrorContent';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import FunctionalityPlaceholder from 'components/UI/organisms/FunctionalityPlaceholder/FunctionalityPlaceholder';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import SymptomChecker from 'components/UI/organisms/SymptomChecker/SymptomChecker';
import { GENDER, type Gender } from 'constants/_types/Gender';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import { SupportedLanguages } from 'constants/translations/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import removeTrailingSlash from 'helpers/removeTrailngSlash/removeTrailingSlash';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import useAppPermissionsStorage from 'storages/appPermissionsStorage';
import symptomCheckerReportsMessages from 'translations/specific/symptomCheckerReports.mjs';

const getSrc = ({
  languageCode,
  token,
  dob,
  gender,
}: {
  languageCode: SupportedLanguages;
  token: string;
  gender: Gender;
  dob: string | Date;
}) => {
  const base = removeTrailingSlash(process.env.REACT_APP_SYMPTOM_CHECKER_URL_BASE);
  const age = getAgeFromDOB(dob);
  const sex = gender === GENDER.k ? 'f' : 'm';
  return createUrlWithParams({ url: `${base}/form/${languageCode}/`, params: { token, age, sex } });
};

const SymptomCheckerPage: FC = () => {
  const { mainFunctionalitiesAllowed } = useAppPermissionsStorage();

  usePageConfig();
  const { t } = useTranslation();

  const languageCode = useLanguageCode();
  const { patient } = usePatientContext();
  const { isLoading, data, isError, isIdle } = useQuery(
    QUERY_KEYS.SYMPTOM_CHECKER_TOKEN,
    symptomChecker.getAccessToken({ patient: patient?.id }),
    {
      refetchOnWindowFocus: false,
      enabled: mainFunctionalitiesAllowed && !!patient?.id,
    },
  );

  if (isLoading || isIdle) {
    return (
      <MainContentWrapper>
        <LoaderOverlay inner />
      </MainContentWrapper>
    );
  }
  if (isError) {
    return (
      <MainContentWrapper>
        <GeneralErrorContent standAlone />
      </MainContentWrapper>
    );
  }
  if (!data || !mainFunctionalitiesAllowed) {
    return (
      <MainContentWrapper>
        <FunctionalityPlaceholder
          action={
            <Link state={{ openDialog: true }} to={PATHS.MY_PROFILE}>
              <Button variant='contained'>{t(symptomCheckerReportsMessages.patientProfileRequired.buttonLabel)}</Button>
            </Link>
          }
          description={t(symptomCheckerReportsMessages.patientProfileRequired.description)}
          header={t(symptomCheckerReportsMessages.patientProfileRequired.header)}
        />
      </MainContentWrapper>
    );
  }
  return (
    <MainContentWrapper isFullHeight>
      <SymptomChecker
        src={getSrc({
          languageCode,
          token: data.data.token,
          gender: patient.personal_data.gender,
          dob: patient.personal_data.date_of_birth,
        })}
      />
    </MainContentWrapper>
  );
};

export default SymptomCheckerPage;
