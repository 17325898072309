const helpMessages = {
  header: ['helpMessages>>header', 'W czym możemy Ci pomóc?'],
  headerExtraContentInfo: ['helpMessages>>headerExtraContentInfo', 'Nasza infolinia czynna 24/7:'],
  faqHeader: ['helpMessages>>faqHeader', 'Poniżej znajdziesz listę najczęściej zadawanych pytań'],
  contactHeader: ['helpMessages>>contactHeader', 'Jeśli nie znalazłeś odpowiedzi na Twoje pytanie skontaktuj się z nami'],
  brandContact: {
    header: ['helpMessages>>brandContact>>header', 'Kontakt z podmiotem'],
  },
  generalContact: {
    header: ['helpMessages>>generalContact>>header', 'Support techniczny'],
  },
  hotline: ['helpMessages>>hotline', 'Infolinia'],
  form: {
    header: ['helpMessages>>form>>header', 'Przez nasz elektroniczny formularz - odpowiedź przyjdzie na Twojego maila, którego podałeś przy rejestracji'],
    helperTexts: {
      dropdown: ['helpMessages>>form>>helperTexts>>dropdown', 'W jakiej sprawie się kontaktujesz?'],
      message: ['helpMessages>>form>>helperTexts>>message', 'Opisz krótko swój problem...'],
    },
    snackbar: {
      success: ['helpMessages>>form>>snackbar>>success', 'Twoje zgłoszenie zostało zapisane. Odpowiedź przyjdzie na Twojego emaila podanego przy rejestracji.'],
    },
  },
  noFaqListMessage: ['helpMessages>>noFaqListMessage', 'Brak elementów do wyświetlenia'],
};

export default helpMessages;
