import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxWidth: 'unset',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.grey[300],
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
  },
  image404: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing(12.5),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  title: {
    fontSize: '1.75rem',
    lineHeight: '2.375rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginTop: theme.spacing(8),
    position: 'relative',
    zIndex: 1,
  },
  subtitle: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  contactContainer: {
    marginTop: theme.spacing(4.5),
    marginBottom: theme.spacing(17.5),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: 1,
  },
  contactDescription: {
    width: '100%',
    textAlign: 'center',
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
    color: theme.palette.grey[500],
  },
  contactPhoneNumber: {
    fontSize: '0.8125rem',
    lineHeight: '1.125rem',
    color: theme.palette.primary.main,
  },
  dnaIcon: {
    position: 'absolute',
    zIndex: 0,
    top: '103px',
    left: 0,
    transform: 'translateX(-40%)',
    [theme.breakpoints.down('sm')]: {
      top: '43px',
    },
  },
  disabledIcon: {
    position: 'absolute',
    zIndex: 0,
    bottom: '270px',
    right: 0,
    transform: 'translateX(15%)',
  },
  heartIcon: {
    position: 'absolute',
    zIndex: 0,
    bottom: 0,
    left: '290px',
    transform: 'translateY(55px)',
    [theme.breakpoints.down('md')]: {
      left: '90px',
    },
  },
}));

export default useStyles;
