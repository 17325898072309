import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import servicesProfile from 'api/servicesProfile/servicesProfile';
import type { ServicesProfile } from 'api/servicesProfile/servicesProfile.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import servicesMessages from 'translations/specific/services.mjs';

const useServicesProfilesRemoveFromBasket = () => {
  const { patient } = usePatientContext();
  const queryClient = useQueryClient();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { showSnackbar } = useShowSnackbar();
  const { t } = useTranslation();

  const removeMutation = useMutation(QUERY_KEYS.REMOVE_SERVICES_PROFILE, servicesProfile.removeServicesProfile(patient?.id));

  const onRemove = async (id: number) => {
    const confirmed = await showConfirmationDialog({
      title: t(servicesMessages.removeServicesProfileDialog.confirmationDialogTitle),
      body: t(servicesMessages.removeServicesProfileDialog.confirmationDialogBody),
    });
    if (confirmed) {
      await removeMutation.mutateAsync(id);
      await queryClient.invalidateQueries(QUERY_KEYS.GET_SERVICES_PROFILE);
      showSnackbar({
        variant: 'success',
        translationArray: servicesMessages.removeServicesProfileDialog.servicesProfileRemoved,
      });
    }
  };

  return { onRemove };
};

const useServicesProfilesSearch = (content?: ServicesProfile[]) => {
  const [localContent, setLocalContent] = useState(content);
  const [searchPhrase, setSearchPhrase] = useState('');

  useEffect(() => {
    if (!content) return;
    setLocalContent(content.filter(({ name }) => name.includes(searchPhrase)));
  }, [content, searchPhrase]);

  const onSearch = useCallback((phrase: string) => {
    setSearchPhrase(phrase);
  }, []);

  return { onSearch, localContent };
};

export { useServicesProfilesRemoveFromBasket, useServicesProfilesSearch };
