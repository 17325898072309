import type { TranslatableDropdownOption } from 'constants/_types/DropdownOptions';
import { PATIENT_DOCUMENTS } from 'constants/_types/PatientDocuments';
import patientMessages from 'translations/specific/patient.mjs';

const patientDocumentTypes: TranslatableDropdownOption[] = [
  {
    value: PATIENT_DOCUMENTS.passport,
    labelKey: patientMessages.documentTypes.passport,
  },
  {
    value: PATIENT_DOCUMENTS.id,
    labelKey: patientMessages.documentTypes.id,
  },
  {
    value: PATIENT_DOCUMENTS.idCard,
    labelKey: patientMessages.documentTypes.idCard,
  },
  {
    value: PATIENT_DOCUMENTS.drivingLicence,
    labelKey: patientMessages.documentTypes.drivingLicence,
  },
];

export default patientDocumentTypes;
