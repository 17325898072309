import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
      marginBottom: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(5),
  },
  list: {
    padding: 0,
  },
}));

export default useStyles;
