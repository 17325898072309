import { useMemo } from 'react';

import type { QueryKey } from 'react-query';
import { useQuery } from 'react-query';

import type { ListData, PaginatedApiResponse } from 'api/_types';
import type { UrlQueryParams } from 'constants/_types/Params';

type UseTitleItemsCount = (args: {
  queryKey: QueryKey;
  queryFunction: (params?: UrlQueryParams) => () => Promise<PaginatedApiResponse<ListData>>;
  additionalParams?: Record<string, boolean>;
}) => number;

export const useTitleItemsCount: UseTitleItemsCount = ({ queryKey, queryFunction, additionalParams = {} }) => {
  const { data } = useQuery(queryKey, queryFunction({ ...additionalParams }));

  const itemsCount = useMemo(() => data?.data?.count ?? 0, [data?.data?.count]);

  return itemsCount;
};
