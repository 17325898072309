import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parsePatientAttachmentForFE } from '../parsePatientAttachmentForFE/parsePatientAttachmentForFE';
import { endpoints } from '../patientAttachment';
import type { PatientAttachment, PatientAttachmentDTO } from '../types';

type GetQuery = () => Promise<DefaultApiResponse<PatientAttachmentDTO>>;
type Get = () => Promise<PatientAttachment | null>;

export const getPatientAttachment =
  ({ attachmentId, patientId }: { attachmentId: number; patientId: number }): Get =>
  async () => {
    const data = await axios.get<GetQuery, DefaultApiResponse<PatientAttachmentDTO>>(
      createUrlWithParams({ url: endpoints.patientAttachment, params: { patient_id: patientId, patient_attachment_id: attachmentId } }),
    );

    return parsePatientAttachmentForFE(data.data);
  };
