import type { TFunction } from 'i18next';

import type { RegularServiceDetails } from 'constants/_types/SelectedServices';

import type { ContentRow } from '../../_types/ContentRow';
import { adjustDateOption } from '../adjustDateOption/adjustDateOption';

interface Params {
  details: RegularServiceDetails;
  t: TFunction;
}

export const parseRegularServiceToRow = ({ details, t }: Params): ContentRow[] =>
  Object.values(details.serviceItems).map(serviceItem => ({
    serviceId: serviceItem.id,
    serviceName: serviceItem?.nameToDisplay || '',
    serviceDeadline: adjustDateOption({
      dateSoon: serviceItem.dateSoon,
      dateFrom: serviceItem.dateFrom,
      dateUntil: serviceItem.dateUntil,
      t,
    }),
    payerId: serviceItem.payer,
  }));
