import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  toggleButton: {
    '& > span:nth-of-type(2) > div': {
      fontSize: 'unset',
    },
  },
}));

export default useStyles;
