import { makeStyles } from 'tss-react/mui';

import AVATAR_SIZE from 'constants/styles/AVATAR_SIZE';

const useStyles = makeStyles()(theme => ({
  buttonWrapper: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  editorWrapper: {
    display: 'grid',
    maxWidth: `${64 + AVATAR_SIZE.width * 2}px`,
    margin: '0 auto',
  },
}));

export default useStyles;
