const LAYOUT_SIZES = {
  HEADER: {
    xxl: 80,
    xl: 70,
    sm: 60,
  },
  SIDE_MENU: {
    open: 125,
    closed: 90,
  },
  BOTTOM_MENU: {
    open: 300,
    closed: 75,
  },
};

export default LAYOUT_SIZES;
