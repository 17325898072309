import type { FC } from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import type { PatientPolicy } from 'api/patientPolicies/patientPolicies.types';
import FixedSizeCard from 'components/UI/atoms/FixedSizeCard/FixedSizeCard';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import normalizePath from 'helpers/normalizePath/normalizePath';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './SubscriptionCard.styles';

export const testId = createTestIdObject('PolicyCard', {
  generalConditions: 'generalConditions',
  policyScope: 'policyScope',
});

export type Props = {
  cardData: PatientPolicy;
  onScopeClick?: (cardData?: PatientPolicy) => void;
};

const SubscriptionCard: FC<Props> = ({ cardData, onScopeClick }) => {
  const { t } = useTranslation();

  const { classes, cx } = useStyles();
  return (
    <FixedSizeCard maxWidth={364}>
      <>
        <header className={classes.header}>
          <div>
            {cardData.payerImg.logoLeft ? (
              <img alt={t(cardData.name.key)} className={classes.headerImage} src={normalizePath(cardData.payerImg.logoLeft)} />
            ) : (
              <Typography variant='subtitle1'>{t(cardData.name.key)}</Typography>
            )}
          </div>
          <div className={classes.headerLinks}>
            {onScopeClick && (
              <Typography
                className={cx(!cardData.payerId && classes.disabledLink)}
                color='primary'
                component={ButtonBase}
                data-testid={testId.policyScope}
                disabled={!cardData.payerId}
                disableRipple
                variant='captionBold'
                onClick={() => onScopeClick(cardData)}
              >
                {t(patientMessages.policiesAndPaymentsSection.policyScopeLink)}
              </Typography>
            )}
            {cardData.generalConditions?.file && (
              <Typography
                color='primary'
                component='a'
                data-testid={testId.generalConditions}
                href={normalizePath(cardData.generalConditions.file)}
                target='_blank'
                variant='captionBold'
              >
                {cardData.generalConditions?.name || t(patientMessages.policiesAndPaymentsSection.subscription.generalConditionsLink)}
              </Typography>
            )}
          </div>
        </header>
        <div className={classes.body}>
          <div className={classes.infoBox}>
            <Typography variant='caption'>{t(patientMessages.policiesAndPaymentsSection.subscription.numberLabel)}</Typography>
            <Typography variant='subtitle3'>{cardData.policyNumber}</Typography>
          </div>
          <div className={classes.infoBox}>
            <Typography variant='caption'>{t(patientMessages.policiesAndPaymentsSection.subscription.validityTime)}</Typography>
            <Typography variant='subtitle3'>
              {format(cardData.validFrom, DATE_FORMATS.DISPLAY)} - {format(cardData.validUntil, DATE_FORMATS.DISPLAY)}
            </Typography>
          </div>
        </div>
      </>
    </FixedSizeCard>
  );
};

export default SubscriptionCard;

// usługi zamiast możliwych płatności
