import { makeStyles } from 'tss-react/mui';

export const AVATAR_SIZE = 32;

const useStyles = makeStyles<{ placeholder: boolean; imageUrl?: string }>()((theme, { placeholder, imageUrl }) => ({
  root: {
    padding: placeholder ? theme.spacing(0.5) : 0,
    border: placeholder ? `1px solid ${theme.palette.secondary.main}` : 'none',
    borderRadius: '100px',
    backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: `${AVATAR_SIZE}px`,
    height: `${AVATAR_SIZE}px`,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
