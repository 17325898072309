import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import CustomFilters from 'components/UI/organisms/CustomFilters/CustomFilters';
import type { AvailableFilter } from 'constants/_types/AvailableFilter';
import type { TableDataFilter } from 'constants/_types/TableDataFilter';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import tableMessages from 'translations/common/table.mjs';

import useStyles from './PageTitleWithFiltersForTables.styles';

type Props = {
  pageTitle: {
    extraAfterIconComponent?: JSX.Element;
    extraContentComponent?: JSX.Element;
    headerLabel: string;
    subLabel?: string | JSX.Element;
    icon: JSX.Element;
    itemsCount?: number;
    includeFilterButton?: boolean;
  };
  customFilters?: {
    availableFilters: AvailableFilter[];
    filters?: TableDataFilter[] | null;
    onFilter: (newFilters: TableDataFilter[]) => void;
  };
};

export const testId = createTestIdObject('DialogWrapper', {
  toggleButton: 'toggleButton',
});

const PageTitleWithFiltersForTables: FC<Props> = ({ pageTitle, customFilters }) => {
  const { t } = useTranslation();

  const [isOpen, , , toggleIsOpen] = useBooleanState(false);
  const { classes } = useStyles({ isOpen });

  const filterBtn = useMemo(
    () => (
      <Button className={classes.filterBtn} data-testid={testId.toggleButton} size='small' variant='outlined' onClick={toggleIsOpen}>
        {isOpen ? t(tableMessages.closeFilters) : t(tableMessages.openFilters)}
      </Button>
    ),
    [isOpen],
  );

  const itemCount = (counter: number | undefined) => (counter ? `(${counter})` : '');
  const headerLabel = useMemo(
    () => `${pageTitle.headerLabel} ${itemCount(pageTitle.itemsCount)}`,
    [pageTitle.headerLabel, pageTitle.itemsCount],
  );

  const mergeElement = useMemo(
    () => (
      <div className={classes.buttonsWrapper}>
        {filterBtn}
        {pageTitle.extraContentComponent}
      </div>
    ),
    [pageTitle.extraContentComponent, isOpen],
  );

  const renderExtraContentComponent = useMemo(() => {
    if (pageTitle.includeFilterButton) return mergeElement;
    return pageTitle.extraContentComponent || filterBtn;
  }, [pageTitle.includeFilterButton, pageTitle.extraContentComponent, isOpen]);

  return (
    <>
      <PageTitle
        extraAfterIconComponent={pageTitle.extraAfterIconComponent}
        extraContentComponent={renderExtraContentComponent}
        headerLabel={headerLabel}
        icon={pageTitle.icon}
        subLabel={pageTitle.subLabel}
      />
      {customFilters && (
        <CustomFilters
          availableFilters={customFilters.availableFilters}
          filters={customFilters.filters}
          isFilterOpen={isOpen}
          onFilter={customFilters.onFilter}
        />
      )}
    </>
  );
};

export default PageTitleWithFiltersForTables;
