import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  filters: {
    padding: theme.spacing(2, 0),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    display: 'block',
    textAlign: 'right',
    width: '24px',
  },
}));

export default useStyles;
