import type { FC, FormEvent } from 'react';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { Button } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import patients from 'api/patient/patients';
import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import ImageEditor, { ImageEditorVariant } from 'components/UI/molecules/ImageEditor/ImageEditor';
import InputFileHiddenNative from 'components/UI/molecules/InputFileHiddenNative/InputFileHiddenNative';
import { ACCEPTED_AVATAR_FILE_FORMATS_REGEX } from 'constants/_files/acceptedAvatarFileFormatsRegex';
import queryKeys from 'constants/queryKeys/queryKeys';
import { useProcessUploadedFile } from 'hooks/_fileProcessing/useProcessUploadedFile/useProcessUploadedFile';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import generalMessages from 'translations/common/general.mjs';
import snackbarMessages from 'translations/common/snackbar.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import { imageReducer, initialImageState } from './AvatarDialog.logic';
import useStyles from './AvatarDialog.styles';

export type Props = {
  isOpen: boolean;
  close: () => void;
  onAvatarChange: (config: { action: 'create' | 'update' }) => void;
};

const FORM_ID = 'Avatar_form';

const AvatarDialog: FC<Props> = ({ close, isOpen, onAvatarChange }) => {
  const { t } = useTranslation();
  const { patient } = usePatientContext();
  const { showSnackbar } = useShowSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<AvatarEditor>(null);

  const openFilePicker = () => inputRef.current?.click();

  const [image, dispatch] = useReducer(imageReducer, initialImageState);

  const setInitial = useCallback(initialData => {
    const { cropped_image: src } = initialData;
    dispatch({ type: 'updateSrc', src });
    dispatch({ type: 'toggleHasSavedImage' });
  }, []);

  const onError = useCallback(({ response }) => {
    if (response.status !== 404) showSnackbar({ variant: 'error' });
  }, []);
  const { data } = useQuery(queryKeys.USER_AVATAR_GET, patients.getPatientAvatar(patient?.id), {
    retry: false,
    onError,
    cacheTime: 0,
    enabled: !!patient?.id,
  });

  useEffect(() => {
    if (data?.data && !image.src) setInitial(data.data);
  }, [data?.data]);

  const clearPicture = () => {
    dispatch({ type: 'clearSrc' });
    if (inputRef.current?.value) inputRef.current.value = '';
  };

  const fileProcessingConfig = useMemo(
    () => ({
      proceedingCallback: (file: File) => dispatch({ type: 'updateSrcByUser', src: URL.createObjectURL(file) }),
      config: {
        fileType: {
          acceptedType: ACCEPTED_AVATAR_FILE_FORMATS_REGEX,
          errorMessageTranslationArray: patientMessages.avatar.incorrectExtension,
        },
        fileSize: { errorMessageTranslationArray: patientMessages.avatar.tooBig },
      },
    }),
    [dispatch, URL.createObjectURL],
  );

  const handleOnUploadedFile = useProcessUploadedFile(fileProcessingConfig);

  const onSuccess = async () => {
    showSnackbar({
      variant: 'success',
      translationArray: snackbarMessages.success,
    });
    await onAvatarChange({ action: 'update' });
    close();
  };

  const createMutation = useMutation(queryKeys.USER_AVATAR_CREATE, patients.createPatientAvatar(), { onSuccess });

  const updateMutation = useMutation(queryKeys.USER_AVATAR_UPDATE, patients.updatePatientAvatar(patient?.id), { onSuccess });

  const deleteMutation = useMutation(queryKeys.USER_AVATAR_DELETE, patients.deletePatientAvatar(patient?.id), { onSuccess });

  const onSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const imageRef = editorRef.current;
    if (imageRef && image.src) {
      imageRef.getImageScaledToCanvas().toBlob(blob => {
        const dataFile = new File([blob as Blob], `profilePicture.png`, {
          type: 'image/png',
        });

        const formData = new FormData();
        formData.append('cropped_image', dataFile);
        formData.append('patient', `${patient.id}`);

        if (image.hasSavedImage) {
          updateMutation.mutate(formData);
        } else {
          createMutation.mutate(formData);
        }
      });
    } else if (image.hasSavedImage) {
      deleteMutation.mutate();
    } else {
      onSuccess();
    }
  };

  const variant: ImageEditorVariant = useMemo(() => {
    if (!image.src) return 'empty';
    if (image.imageUploaded) return 'editor';
    if (!image.imageUploaded && image.hasSavedImage) return 'preview';
    return 'empty';
  }, [image]);

  const { classes } = useStyles();
  return (
    <DialogWrapper
      actionsDescription='ACTIONS'
      close={close}
      defaultActionsConfig={{
        cancelLabel: t(generalMessages.cancel),
        okLabel: t(generalMessages.save),
        isLoading: false,
        formId: FORM_ID,
        onClose: close,
        isOkDisabled: !image.imageUploaded && !!image.src,
      }}
      dialogProps={{ maxWidth: 'sm' }}
      header={t(patientMessages.avatar.header)}
      isOpen={isOpen}
      noMarginTop
      subheader={t(patientMessages.avatar.subheader)}
    >
      <form id={FORM_ID} onSubmit={onSave}>
        <div className={classes.buttonWrapper}>
          <Button size='small' variant='contained' onClick={openFilePicker}>
            {image.src ? t(patientMessages.avatar.changeFileLabel) : t(patientMessages.avatar.addFileLabel)}
          </Button>
          {image.src && (
            <Button size='small' variant='outlined' onClick={clearPicture}>
              {t(patientMessages.avatar.removeFileLabel)}
            </Button>
          )}
        </div>
        <ImageEditor editorRef={editorRef} image={image} variant={variant} />
        <InputFileHiddenNative accept='image/*' inputRef={inputRef} onChange={handleOnUploadedFile} />
      </form>
    </DialogWrapper>
  );
};

export default AvatarDialog;
