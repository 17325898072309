import type { ContactFormInput } from 'components/UI/organisms/ContactCard/_constants/inputKeys';

import type { ContactFormDataDto } from '../types';

export const parseContactFormForBE = (
  data: ContactFormInput,
  patientId: number | null,
  brandId: number | undefined,
): ContactFormDataDto => ({
  patient: patientId,
  category: data.category,
  inquiry: data.inquiry,
  recipient: brandId,
});
