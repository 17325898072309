const orderServiceMessages = {
  TELECONSULTATION: {
    basicName: ['orderService>>TELECONSULTATION>>basicName', 'Telekonsultacja'],
    title: ['orderService>>TELECONSULTATION>>title', 'Umów telekonsultację'],
    cardTitle: ['orderService>>TELECONSULTATION>>cardTitle', 'Wybierz typ telekonsultacji'],
    chooseTypeLabel: ['orderService>>TELECONSULTATION>>chooseTypeLabel', 'Wybierz typ telekonsultacji'],
    chooseSubtypeLabel: ['orderService>>TELECONSULTATION>>chooseSubtypeLabel', 'Wybierz podtyp telekonsultacji'],
    chooseServiceFromSubtypeLabel: ['orderService>>TELECONSULTATION>>chooseServiceFromSubtypeLabel', 'Wybierz telekonsultację z podtypu'],
    confirmationTitle: ['orderService>>TELECONSULTATION>>confirmationTitle', 'Czy potwierdzasz telekonsultację?'],
    formSteps: {
      '1': {
        title: ['orderService>>TELECONSULTATION>>formSteps>>1>>title', 'Wybierz telekonsultację'],
      },
      '2': {
        title: ['orderService>>TELECONSULTATION>>formSteps>>2>>title', 'Określ szczegóły telekonsultacji'],
      },
    },
  },
  APPOINTMENT: {
    basicName: ['orderService>>APPOINTMENT>>basicName', 'Wizyta stacjonarna'],
    title: ['orderService>>APPOINTMENT>>title', 'Umów wizytę stacjonarną'],
    cardTitle: ['orderService>>APPOINTMENT>>cardTitle', 'Wybierz typ wizyty stacjonarnej'],
    chooseTypeLabel: ['orderService>>APPOINTMENT>>chooseTypeLabel', 'Wybierz typ wizyty stacjonarnej'],
    chooseSubtypeLabel: ['orderService>>APPOINTMENT>>chooseSubtypeLabel', 'Wybierz podtyp wizyty stacjonarnej'],
    chooseServiceFromSubtypeLabel: ['orderService>>APPOINTMENT>>chooseServiceFromSubtypeLabel', 'Wybierz wizytę stacjonarną z podtypu'],
    confirmationTitle: ['orderService>>APPOINTMENT>>confirmationTitle', 'Czy potwierdzasz wizytę stacjonarną?'],
    formSteps: {
      '1': {
        title: ['orderService>>APPOINTMENT>>formSteps>>1>>title', 'Wybierz wizytę stacjonarną'],
      },
      '2': {
        title: ['orderService>>APPOINTMENT>>formSteps>>2>>title', 'Określ szczegóły wizyty stacjonarnej'],
      },
    },
  },
  TESTS: {
    basicName: ['orderService>>TESTS>>basicName', 'Badania'],
    title: ['orderService>>TESTS>>title', 'Umów badania'],
    cardTitle: ['orderService>>TESTS>>cardTitle', 'Wybierz typ badania'],
    chooseTypeLabel: ['orderService>>TESTS>>chooseTypeLabel', 'Wybierz typ badania'],
    chooseSubtypeLabel: ['orderService>>TESTS>>chooseSubtypeLabel', 'Wybierz podtyp badania'],
    chooseServiceFromSubtypeLabel: ['orderService>>TESTS>>chooseServiceFromSubtypeLabel', 'Wybierz badanie z podtypu'],
    confirmationTitle: ['orderService>>TESTS>>confirmationTitle', 'Czy potwierdzasz badania?'],
    formSteps: {
      '1': {
        title: ['orderService>>TESTS>>formSteps>>1>>title', 'Wybierz badanie'],
      },
      '2': {
        title: ['orderService>>TESTS>>formSteps>>2>>title', 'Określ szczegóły badania'],
      },
    },
  },
  OTHERS: {
    basicName: ['orderService>>OTHERS>>basicName', 'Usługi'],
    title: ['orderService>>OTHERS>>title', 'Zamów inne usługi'],
    cardTitle: ['orderService>>OTHERS>>cardTitle', 'Wybierz typ usługi'],
    chooseTypeLabel: ['orderService>>OTHERS>>chooseTypeLabel', 'Wybierz typ usługi'],
    chooseSubtypeLabel: ['orderService>>OTHERS>>chooseSubtypeLabel', 'Wybierz podtyp usługi'],
    chooseServiceFromSubtypeLabel: ['orderService>>OTHERS>>chooseServiceFromSubtypeLabel', 'Wybierz usługę z podtypu'],
    confirmationTitle: ['orderService>>OTHERS>>confirmationTitle', 'Czy potwierdzasz usługi?'],
    formSteps: {
      '1': {
        title: ['orderService>>OTHERS>>formSteps>>1>>title', 'Wybierz inne usługi'],
      },
      '2': {
        title: ['orderService>>OTHERS>>formSteps>>2>>title', 'Określ szczegóły innych usług'],
      },
    },
  },
  HOME_VISIT: {
    basicName: ['orderService>>HOME_VISIT>>basicName', 'Wizyta domowa'],
    title: ['orderService>>HOME_VISIT>>title', 'Umów wizytę domową'],
    cardTitle: ['orderService>>HOME_VISIT>>cardTitle', 'Wybierz typ wizyty domowej'],
    chooseTypeLabel: ['orderService>>HOME_VISIT>>chooseTypeLabel', 'Wybierz typ wizyty domowej'],
    chooseSubtypeLabel: ['orderService>>HOME_VISIT>>chooseSubtypeLabel', 'Wybierz podtyp wizyty domowej'],
    chooseServiceFromSubtypeLabel: ['orderService>>HOME_VISIT>>chooseServiceFromSubtypeLabel', 'Wybierz wizytę domową z podtypu'],
    confirmationTitle: ['orderService>>HOME_VISIT>>confirmationTitle', 'Czy potwierdzasz wizytę domową?'],
    formSteps: {
      '1': {
        title: ['orderService>>HOME_VISIT>>formSteps>>1>>title', 'Wybierz wizytę domową'],
      },
      '2': {
        title: ['orderService>>HOME_VISIT>>formSteps>>2>>title', 'Określ szczegóły wizyty domowej'],
      },
    },
  },
  SERVICES: {
    basicName: ['orderService>>SERVICES>>basicName', 'Usługi'],
    title: ['orderService>>SERVICES>>title', 'Zamów usługi'],
    cardTitle: ['orderService>>SERVICES>>cardTitle', 'Wybierz typ usługi'],
    chooseTypeLabel: ['orderService>>SERVICES>>chooseTypeLabel', 'Wybierz typ usługi'],
    chooseSubtypeLabel: ['orderService>>SERVICES>>chooseSubtypeLabel', 'Wybierz podtyp usługi'],
    chooseServiceFromSubtypeLabel: ['orderService>>SERVICES>>chooseServiceFromSubtypeLabel', 'Wybierz usługę z podtypu'],
    confirmationTitle: ['orderService>>SERVICES>>confirmationTitle', 'Czy potwierdzasz usługi?'],
    formSteps: {
      '1': {
        title: ['orderService>>SERVICES>>formSteps>>1>>title', 'Wybierz usługi'],
      },
      '2': {
        title: ['orderService>>SERVICES>>formSteps>>2>>title', 'Określ szczegóły usług'],
      },
    },
  },
  formStepsButtonLabels: {
    nextStep: ['orderService>>formStepsButtonLabels>>nextStep', 'Następny krok'],
    prevStep: ['orderService>>formStepsButtonLabels>>prevStep', 'Poprzedni krok'],
    order: ['orderService>>formStepsButtonLabels>>order', 'Zamów'],
    saveAsProfile: ['orderService>>formStepsButtonLabels>>saveAsProfile', 'Zapisz jako profil'],
    savedProfiles: ['orderService>>formStepsButtonLabels>>savedProfiles', 'Zapisane profile'],
  },
  buttonLabels: {
    confirmButton: ['orderService>>buttonLabels>>confirmButton', 'Dodaj do realizacji'],
  },
  payments: {
    choosePaymentMethod: ['orderService>>payments>>choosePaymentMethod', 'Wybierz sposób płatności'],
    choosePaymentTooltip: ['orderService>>payments>>choosePaymentTooltip', 'Po dokonaniu wyboru zobaczysz wyłącznie usługi medyczne możliwe do zrealizowania w ramach wybranych płatności: płatność we własnym zakresie lub pokryta przez płatnika w ramach Twojej polisy (jeśli posiadasz polisy).'],
  },
  payers: {
    selfPayer: ['orderService>>payments>>selfPayer', 'Płacę sam'],
  },
  basket: {
    header: ['orderService>>basket>>header', 'Do realizacji'],
    showBasket: ['orderService>>basket>>showBasket', 'Rozwiń koszyk'],
    hideBasket: ['orderService>>basket>>hideBasket', 'Zwiń koszyk'],
    typesSections: {
      M_INN: ['orderService>>basket>>typesSections>>M_INN', 'Inne'],
      M_KUM: ['orderService>>basket>>typesSections>>M_KUM', 'Zabiegi'],
      M_SUM: ['orderService>>basket>>typesSections>>M_SUM', 'Badania laboratoryjne'],
      M_GAB: ['orderService>>basket>>typesSections>>M_GAB', 'Badania'],
      M_OSO: ['orderService>>basket>>typesSections>>M_OSO', 'Wizyty'],
    },
    noItemsText: ['orderService>>basket>>noItemsText', 'Dodaj usługę do realizacji'],
    order: ['orderService>>basket>>order', 'Zrealizuj'],
    saveAsProfile: ['orderService>>basket>>saveAsProfile', 'Zapisz jako profil'],
    duplicatedItems: ['orderService>>basket>>duplicatedItems', 'Następujące usługi są już dodane do realizacji: {{duplicatedItemsNames}}'],
  },
  location: {
    header: ['orderService>>location>>header', 'Określ miejsce'],
    searchAround: ['orderService>>location>>searchAround', 'Szukaj w promieniu'],
    searchNearest: ['orderService>>location>>searchNearest', 'Jak najbliżej adresu'],
    validationErrors: {
      searchLocation: ['orderService>>location>>validationErrors>>searchLocation', 'Określ obszar poszukiwań'],
    },
  },
  details: {
    header: ['orderService>>details>>header', 'Określ szczegóły zamówień: datę, płatność i dodaj skierowanie jeśli potrzeba'],
    choose: ['orderService>>details>>choose', 'Wybierz'],
    payer: ['orderService>>details>>payer', 'Gmina / Płatnik'],
    deadline: ['orderService>>details>>deadline', 'Termin'],
    asap: ['orderService>>details>>asap', 'Jak najszybciej'],
    nextSevenDays: ['orderService>>details>>nextSevenDays', 'Najbliższe 7 dni'],
    dateFrom: ['orderService>>details>>dateFrom', 'Od'],
    dateUntil: ['orderService>>details>>dateUntil', 'Do'],
    referralAttachment: {
      addReferral: ['orderService>>details>>referralAttachment>>addReferral', 'Dodaj skierowanie'],
      loadingDocument: ['orderService>>details>>referralAttachment>>loadingDocument', 'Wgrywanie dokumentu'],
    },
    referralOrder: ['orderService>>details>>referralOrder', 'Zamów skierowanie'],
    file: {
      incorrectExtension: ['orderService>>details>>file>>incorrectExtension', 'Niewłaściwy rodzaj pliku'],
      tooBig: ['orderService>>details>>file>>tooBig', 'Plik za duży, maksymalny rozmiar pliku to {{ maxFileSize }} MB'],
    },
    validationErrors: {
      deadline: ['orderService>>details>>validationErrors>>deadline', 'Należy wybrać termin - szybki lub w postaci przedziału dat'],
      payer: ['orderService>>details>>validationErrors>>payer', 'Należy wybrać płatnika'],
      referral: ['orderService>>details>>validationErrors>>referral', 'Należy dodać skierowanie lub zamówić skierowanie'],
    },
    confirmation: {
      serviceItemHeader: ['orderService>>details>>confirmation>>serviceItemHeader', 'Wizyta/badanie'],
      serviceTimeHeader: ['orderService>>details>>confirmation>>serviceTimeHeader', 'Data wizyty'],
      servicePaymentHeader: ['orderService>>details>>confirmation>>servicePaymentHeader', 'Metoda płatności'],
    },
  },
  submission: {
    success: {
      notification: {
        singleRequest: ['orderService>>submission>>success>>notification>>singleRequest', 'Twoje {{ servicesNumber }} zlecenie na {{ serviceTypeTranslated }} zostało <strong>{{ strongContentTranslated }}</strong> przekazane do działu call center'],
        multipleRequests: ['orderService>>submission>>success>>notification>>multipleRequests', 'Twoje {{ servicesNumber }} zlecenia na {{ serviceTypeTranslated }} zostały <strong>{{ strongContentTranslated }}</strong> przekazane do działu call center'],
        requestType: {
          TELECONSULTATION: ['orderService>>submission>>success>>notification>>TELECONSULTATION', 'telekonsultację'],
          APPOINTMENT: ['orderService>>submission>>success>>notification>>APPOINTMENT', 'wizytę stacjonarną'],
          TESTS: ['orderService>>submission>>success>>notification>>TESTS', 'badania'],
          OTHERS: ['orderService>>submission>>success>>notification>>OTHERS', 'usługi'],
          HOME_VISIT: ['orderService>>submission>>success>>notification>>HOME_VISIT', 'wizytę domową'],
          SERVICES: ['orderService>>submission>>success>>notification>>SERVICES', 'usługi'],
        },
        strongContent: ['orderService>>submission>>success>>notification>>strongContent', 'pomyślnie'],
      },
    },
    failure: {
      main: ['orderService>>submission>>failure>>main', 'W trakcie składania zamówienia wystąpił błąd. Spróbuj ponownie'],
      attachments: ['orderService>>submission>>failure>>attachments', 'W trakcie składania zamówienia wystąpił błąd związany z identyfikatorami załączonych plików w formularzu. Spróbuj ponownie']
    },
  },
  warnings: {
    noServicesWithChosenPayers: ['orderServices>>warnings>>noServicesWithChosenPayers', 'Brak wyników dla wybranych krytetriów']
  }
};

export default orderServiceMessages;
