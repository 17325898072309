import type { TFunction } from 'react-i18next';

import type { BasketItem } from 'constants/_types/Basket';

interface Params {
  duplicatedItems: BasketItem[];
  prepareSnackbar: (duplicatedItemsNames: string) => void;
  t: TFunction;
}

export const handleDuplicatedItems = ({ duplicatedItems, prepareSnackbar, t }: Params) => {
  if (!duplicatedItems.length) return;

  const duplicatedItemsNames = duplicatedItems.map(({ name: { key, defaultValue } }) => t([key, defaultValue])).join(', ');
  prepareSnackbar(duplicatedItemsNames);
};
