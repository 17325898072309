import { useEffect, useMemo, useRef, type FC } from 'react';

import OrderServiceMobileBasket from 'components/UI/molecules/OrderServiceMobileBasket/OrderServiceMobileBasket';
import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';
import useLayoutStorage from 'storages/layoutStorage';
import { useServicesBasketStorage } from 'storages/serviceBasketStorage/servicesBasketStorage';

import useStyles from './BottomFixedContainer.styles';

const BottomFixedContainer: FC = () => {
  const barRef = useRef<HTMLDivElement>(null);
  const {
    size: [viewportWidth],
  } = useWindowSize();
  const { isOpen } = useLayoutStorage();
  const { globalForm, mobileGridTemplateAreas, setButtonsBarHeight, shouldShowMobileBasket } = useBottomFixedContainerStorage();
  const { basketState, getBasketInfo, removeFromBasket } = useServicesBasketStorage();

  const { classes } = useStyles({
    mobileGridTemplateAreas: mobileGridTemplateAreas as string,
    isDrawerOpened: isOpen,
    isVisibleMobileBasketSection: shouldShowMobileBasket,
  });

  useEffect(() => {
    if (barRef.current) setButtonsBarHeight(barRef.current.clientHeight);

    return () => setButtonsBarHeight(null);
  }, [barRef.current?.clientHeight, viewportWidth]);

  const basketProps = useMemo(
    () => ({
      basket: basketState,
      selectedServicesNumber: getBasketInfo().selectedServicesNumber,
      removeItem: removeFromBasket,
    }),
    [basketState],
  );

  if (!globalForm?.length && !shouldShowMobileBasket) return null;

  return (
    <div className={classes.root} ref={barRef}>
      {shouldShowMobileBasket && (
        <div className={classes.mobileBasketContainer}>
          <OrderServiceMobileBasket {...basketProps} />
        </div>
      )}
      {globalForm && (
        <div className={classes.buttonsContainer}>
          {globalForm.map(({ buttonProps, buttonComponent: ButtonComponent }) => (
            <ButtonComponent key={buttonProps.id} {...buttonProps} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BottomFixedContainer;
