const languageMessages = {
  simple_names: {
    en: [ 'language>>simple_names>>en', 'Angielski' ],
    pl: [ 'language>>simple_names>>pl', 'Polski' ],
    es: [ 'language>>simple_names>>es', 'Hiszpański' ],
  },
  country_names: {
    en: ['language>>country_names>>en', 'Anglia'],
    pl: [ 'language>>country_names>>pl', 'Polska' ],
    es: [ 'language>>country_names>>es', 'Hiszpania' ],
  },
  language: [ 'language>>language', 'Język' ],
  change_language: [ 'language>>change_language', 'Zmień język' ],
  errors: {
    change_language: ['language>>errors>>change_language', 'Nie udało się zmienić języka'],
  },
};

export default languageMessages;
