import { SYMPTOM_CHECKER_TRIAGE_LEVELS, type SymptomCheckerTriageLevelValues } from 'constants/_types/SymptomCheckerTriageLevels';

export const getTriageLevelColorPath = (triageLevel: SymptomCheckerTriageLevelValues) => {
  switch (triageLevel) {
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.selfCare:
      return 'triage.self_care.main';
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.consultation:
      return 'triage.consultation.main';
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.consultation24:
      return 'triage.consultation_24.main';
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.emergency:
      return 'triage.emergency.main';
    case SYMPTOM_CHECKER_TRIAGE_LEVELS.emergencyAmbulance:
      return 'triage.emergency_ambulance.main';
    default:
      return 'secondary.main';
  }
};
