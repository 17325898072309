import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      margin: theme.spacing(0, 2),
      '& label > span:last-of-type': {
        padding: 0,
      },
    },
  },
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  inputCheckbox: {
    padding: theme.spacing(0, 2),
  },
}));

export default useStyles;
