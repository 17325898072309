import type { ServicesProfileInputDTO, ServicesProfileInput } from '../../servicesProfile.types';

const parseServicesProfileForBE = (servicesProfile: ServicesProfileInput): ServicesProfileInputDTO => ({
  core_services: Object.values(servicesProfile.coreServices)
    .map(({ items }) => items.map(({ id }) => id))
    .flat(),
  name: servicesProfile.name,
  patient: servicesProfile.patient,
});

export default parseServicesProfileForBE;
