import type { FC } from 'react';

import { Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NoClausesToShow from 'components/UI/atoms/NoClausesToShow/NoClausesToShow';
import PatientCardSectionTitle from 'components/UI/atoms/PatientCardSectionTitle/PatientCardSectionTitle';
import ClauseAttachmentSection from 'components/UI/molecules/ClauseAttachmentSection/ClauseAttachmentSection';
import { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import getLabelForClause from 'services/_clauses/getLabelForClause/getLabelForClause';
import clausesMessages from 'translations/specific/clauses.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import useStyles from './PatientClausesRequired.styles';

export type Props = {
  clausesRequired: ClauseWithConsent[];
};

export const testId = createTestIdObject('PatientClausesRequired', {
  clauseElement: 'clauseElement',
  nonFileClauseDivider: 'nonFileClauseDivider',
  clauseDivider: 'clauseDivider',
});

const PatientClausesRequired: FC<Props> = ({ clausesRequired }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <PatientCardSectionTitle titleText={t(patientMessages.sectionTitles.clausesRequired)} />
        {clausesRequired.length > 0 ? (
          clausesRequired.map((clause, index) => (
            <div className={classes.clauseListItem} data-testid={testId.clauseElement} key={clause.clauseId}>
              <Typography className={classes.clauseTitle} component='p' variant='body2'>
                {getLabelForClause(clause.text, { hideLink: true })}
              </Typography>
              {clause.updatedAt && (
                <Typography component='p' variant='caption'>
                  {t(clausesMessages.acceptDate, { acceptDate: unknownDateToString(clause.updatedAt, DATE_FORMATS.DISPLAY_TIME) })}
                </Typography>
              )}

              {!clause.file && index !== clausesRequired.length - 1 && (
                <Divider className={classes.fileDivider} data-testid={testId.nonFileClauseDivider} />
              )}
              {clause.file && (
                <>
                  <ClauseAttachmentSection clause={clause} />
                  {index !== clausesRequired.length - 1 && <Divider className={classes.fileDivider} data-testid={testId.clauseDivider} />}
                </>
              )}
            </div>
          ))
        ) : (
          <NoClausesToShow />
        )}
      </Grid>
    </Grid>
  );
};

export default PatientClausesRequired;
