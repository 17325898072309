import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  noPadding: {
    padding: 0,
  },
  section: {
    gap: 0,
  },
  boxList: {
    marginBottom: theme.spacing(2),
  },
  filedTitle: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    marginTop: theme.spacing(3),
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    paddingBottom: theme.spacing(5),
  },
}));

export default useStyles;
