import { get } from 'lodash';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ colorPath?: string }>()((theme, { colorPath }) => ({
  root: {
    color: colorPath ? get(theme.palette, colorPath, theme.palette.text.primary) : theme.palette.text.primary,
  },
}));

export default useStyles;
