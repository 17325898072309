import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { IconButton, Typography } from '@mui/material';

import useStyles from './PaginationButton.styles';

type Props = {
  possiblePage: number;
  page: number;
  onPageNumber: (pageNumber: number) => void;
};

const PaginationButton: FC<Props> = ({ possiblePage, page, onPageNumber }) => {
  const { classes } = useStyles();

  const color = useMemo(() => (possiblePage === page ? 'primary' : 'default'), [possiblePage, page]);
  const fontWeight = useMemo(() => (possiblePage === page ? 'bold' : 'default'), [possiblePage, page]);
  const pageNumberToShow = useMemo(() => possiblePage + 1, [possiblePage]);

  const handleClick = useCallback(() => onPageNumber(possiblePage), [onPageNumber]);

  return (
    <IconButton className={classes.root} key={possiblePage} onClick={handleClick}>
      <Typography color={color} fontWeight={fontWeight}>
        {pageNumberToShow}
      </Typography>
    </IconButton>
  );
};

export default PaginationButton;
