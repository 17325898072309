import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ linesBeforeTruncate: number }>()((theme, { linesBeforeTruncate }) => ({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: linesBeforeTruncate,
    WebkitBoxOrient: 'vertical',
  },
}));

export default useStyles;
