import { FC, useMemo } from 'react';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import eCareContactNotes from 'api/eCareContactNotes/eCareContactNotes';
import { ECareContactNote } from 'api/eCareContactNotes/eCareContactNotes.types';
import { EcarePhoneCall } from 'api/eCarePhoneCall/eCarePhoneCall.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import PartnerNotesDialog from 'components/UI/organisms/_dialogs/PartnerNotesDialog/PartnerNotesDialog';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useBooleanState from 'hooks/useBooleanState/useBooleanState';
import generalMessages from 'translations/common/general.mjs';
import partnerNotesMessages from 'translations/specific/partnerNotes.mjs';

import usePhoneCallNotes from './_hooks/usePhoneCallNotes/usePhoneCallNotes';
import usePhoneCallNotesTable from './_hooks/usePhoneCallNotesTable/usePhoneCallNotesTable';
import useStyles from './PartnerNotes.styles';

type Props = {
  eCarePhoneCallsData: EcarePhoneCall;
};

const PartnerNotes: FC<Props> = ({ eCarePhoneCallsData }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const [isPartnerNotesDialogOpen, openDialog, closeDialog] = useBooleanState(false);
  const orderId = eCarePhoneCallsData.order;

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, orderId], [orderId]);
  const { data: contactNotesData } = useQuery(queryKey, eCareContactNotes.getAllNotes(orderId), {
    refetchOnWindowFocus: false,
  });

  const phoneCallsPartnerNotesTable = usePhoneCallNotesTable(contactNotesData?.data as ECareContactNote[]);

  const showNotesHistory = () => {
    showConfirmationDialog({
      title: t(partnerNotesMessages.title),
      body:
        phoneCallsPartnerNotesTable?.data &&
        phoneCallsPartnerNotesTable.data.map(note => (
          <BoxWithHeader
            className={classes.boxList}
            description={note.content}
            header={[
              {
                label: note.updatedAt
                  ? t(partnerNotesMessages.eCarePartnerNotesTable.updatedTime)
                  : t(partnerNotesMessages.eCarePartnerNotesTable.creationTime),
                value: note.updatedAt
                  ? unknownDateToString(note.updatedAt, DATE_FORMATS.DISPLAY_TIME)
                  : unknownDateToString(note.createdAt, DATE_FORMATS.DISPLAY_TIME),
              },
              {
                label: t(partnerNotesMessages.eCarePartnerNotesTable.lastSync),
                value: note.lastSync ? unknownDateToString(note.lastSync, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noLastSyncNote),
              },
              {
                label: t(partnerNotesMessages.eCarePartnerNotesTable.id),
                value: note.updatedBy ? note.updatedBy : note.createdBy,
              },
            ]}
            key={note.id}
          />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  const phoneCallNote = usePhoneCallNotes(phoneCallsPartnerNotesTable?.data);

  return (
    <>
      <SectionWithTitle
        className={classes.section}
        title={t(partnerNotesMessages.description)}
        titleIcon={
          <>
            <Button variant='contained' onClick={openDialog}>
              {t(partnerNotesMessages.buttons.addNote)}
            </Button>
            <Tooltip enterDelay={750} title={t(partnerNotesMessages.tooltip.addNote) as string}>
              <InfoIcon sx={{ width: '20px' }} />
            </Tooltip>
          </>
        }
        titleRightSideComponent={
          <Button variant='outlined' onClick={showNotesHistory}>
            {t(partnerNotesMessages.more)}
          </Button>
        }
      >
        <DefinitionList
          columns={1}
          customClasses={{
            listItemClass: classes.noPadding,
          }}
          definitions={phoneCallNote}
        />
      </SectionWithTitle>
      <PartnerNotesDialog
        close={closeDialog}
        isNew
        isOpen={isPartnerNotesDialogOpen}
        orderId={orderId}
        serviceRecipientId={eCarePhoneCallsData.serviceRecipient}
      />
    </>
  );
};

export default PartnerNotes;
