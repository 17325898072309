import { replaceDashWithUnderscore } from 'helpers/replaceDashWithUnderscore/replaceDashWithUnderscore';
import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

import type { ServicesProfile, ServicesProfileDTO } from '../../servicesProfile.types';

const parseServicesProfileForFE = (rawData: ServicesProfileDTO): ServicesProfile => ({
  coreServices: rawData.core_services.map(({ id, name, kind, subtype, column_order }) => ({
    name: parseDynamicTranslatableName(name),
    id,
    coreServiceKind: replaceDashWithUnderscore(kind.code),
    order: column_order ?? 0,
    subtype: {
      id: subtype.id,
      masterType: subtype.master_type,
      masterTypeName: parseDynamicTranslatableName(subtype.master_type_name),
      name: parseDynamicTranslatableName(subtype.name),
      serviceOrigin: subtype.service_origin,
      ordering: subtype.ordering,
    },
  })),
  name: rawData.name,
  id: rawData.id,
  patient: rawData.patient,
});

export default parseServicesProfileForFE;
