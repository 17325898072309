import { FC, useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import coreServiceSubtypes from 'api/coreServiceSubtypes/coreServiceSubtypes';
import { CoreServiceSubtype } from 'api/coreServiceSubtypes/coreServiceSubtypes.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import ServicesSubtypeAccordion from 'components/UI/organisms/ServicesSubtypeAccordion/ServicesSubtypeAccordion';
import { DynamicTranslatableName } from 'constants/_types/DynamicTranslatableName';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generateQueryKeyFromObject from 'services/generateQueryKeyFromObject/generateQueryKeyFromObject';
import generalMessages from 'translations/common/general.mjs';
import servicesMessages from 'translations/specific/services.mjs';

import useStyles from './DisplayServicesInPolicyDialog.styles';

export type Props = {
  close: () => void;
  isOpen: boolean;
  patientId: number;
  payerId: number;
  policyId: number;
  policyType?: string;
};

type ServiceSubtype = {
  masterTypeName: DynamicTranslatableName;
  subtypes: CoreServiceSubtype[];
};

type ServiceSubtypes = Record<string, ServiceSubtype>;

const parseData = (subtypes: CoreServiceSubtype[]): ServiceSubtypes =>
  subtypes.reduce<ServiceSubtypes>((acc, subtype) => {
    const key = subtype.masterType?.toString();
    if (!key) return acc;
    if (!acc[key]) {
      acc[key] = {
        masterTypeName: subtype.masterTypeName,
        subtypes: [],
      };
    }

    acc[key].subtypes.push(subtype);

    return acc;
  }, {} as ServiceSubtypes);

const DisplayServicesInPolicyDialog: FC<Props> = ({ close, isOpen, patientId, payerId, policyId, policyType }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | null>(null);

  const { data, isLoading } = useQuery(
    generateQueryKeyFromObject({ base: QUERY_KEYS.GET_SERVICES_SUBTYPES_BY_POLICY_ID, patientId, payerId, policyId }),
    coreServiceSubtypes.getServiceSubtypes({ patientId, payers: [payerId], masterTypeIsNull: false, policyId }),
  );

  const parsedData = useMemo(() => {
    if (data) return parseData(data);
    return null;
  }, [data]);

  const expanderFactory = (key: string) => () => setExpanded(prev => (prev === key ? null : key));

  const { classes } = useStyles();

  const header = useMemo(() => {
    switch (policyType) {
      case 'PACKAGE':
      case 'POLICY':
        return t(servicesMessages.servicesListInPolicy);
      default:
        return t(servicesMessages.servicesListInSubscription);
    }
  }, [policyType]);

  return (
    <DialogWrapper
      actionsConfig={[{ label: t(generalMessages.close), onClick: close, variant: 'contained' }]}
      close={close}
      dialogProps={{ maxWidth: 'sm' }}
      header={header}
      isOpen={isOpen}
    >
      {isLoading && <LoaderOverlay inner minHeight='500px' />}
      {parsedData &&
        Object.entries(parsedData).map(([key, value]) => (
          <div key={key}>
            <Typography className={classes.title} variant='subtitle2'>
              {t(
                value.masterTypeName.key,
                value.masterTypeName.defaultValue ? value.masterTypeName.defaultValue : value.masterTypeName.key,
              )}
            </Typography>

            {value.subtypes.map(subtype => {
              const id = `${key}-${subtype.id}`;
              const isExpanded = expanded === id;
              return (
                <ServicesSubtypeAccordion
                  isExpanded={isExpanded}
                  key={id}
                  patientId={patientId}
                  payerId={payerId}
                  policyId={policyId}
                  subtypeId={subtype.id}
                  title={subtype.name}
                  onExpand={expanderFactory(id)}
                />
              );
            })}
          </div>
        ))}
    </DialogWrapper>
  );
};

export default DisplayServicesInPolicyDialog;
