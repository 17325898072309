import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    display: 'grid',
    gridGap: theme.spacing(4, 2),
    maxWidth: '360px',
    width: '100%',
    margin: '0 auto',
    justifyItems: 'center',
  },
}));

export default useStyles;
