import { BrowserTypes } from 'constants/_types/BrowserType';

export const getBrowserType = (): BrowserTypes => {
  const userAgentString = navigator.userAgent;

  let isChromeAgent = userAgentString.indexOf('Chrome') > -1;
  const isEdgeAgent = userAgentString.indexOf('Edg') > -1;
  const isFirefoxAgent = userAgentString.indexOf('Firefox') > -1;
  const isIEAgent = userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1;
  const isOperaAgent = userAgentString.indexOf('OP') > -1;
  let isSafariAgent = userAgentString.indexOf('Safari') > -1;

  // Discards Safari since it also matches Chrome
  if (isChromeAgent && isSafariAgent) isSafariAgent = false;
  // Discards Chrome since it also mathces Opera
  if (isChromeAgent && isOperaAgent) isChromeAgent = false;

  if (isChromeAgent) return 'chrome';
  if (isEdgeAgent) return 'edge';
  if (isFirefoxAgent) return 'firefox';
  if (isIEAgent) return 'ie';
  if (isOperaAgent) return 'opera';
  if (isSafariAgent) return 'safari';
  return 'unknown';
};
