type Params = {
  patch?: string;
  base?: Location;
  searchToAppend?: Record<string, string>;
};

/**
 * Returns path with query params, it's also possible to add more search params
 * @param params
 */
export const getPathWithQueryParams: (params?: Params) => string = params => {
  const defaults = {
    patch: window.location.pathname,
    base: window.location,
  };

  const { patch, base, searchToAppend } = { ...defaults, ...params };
  const url = new URL(patch, base.href);
  const searchParams = new URLSearchParams(window.location.search);
  if (searchToAppend) {
    Object.entries(searchToAppend).forEach(([key, value]) => {
      searchParams.append(key, value);
    });
  }
  url.search = decodeURIComponent(searchParams.toString());

  return url.pathname + url.search;
};
