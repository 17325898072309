import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  clausesContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default useStyles;
