import type { UseFormReturn } from 'react-hook-form';

import { ORDER_SERVICE_FORM_INPUT_KEYS, type OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

interface Params {
  currentValue: boolean;
  form: UseFormReturn<OrderServiceFormInput>;
  errorMessage: string;
}

export const validateSearchNearest = ({ currentValue, form, errorMessage }: Params) => {
  const searchAroundValue = form.getValues(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance as 'searchAroundDistance'}`);
  if (!currentValue && !searchAroundValue) return errorMessage;

  const searchNearestError = form.getFieldState(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as 'searchNearest'}`).error?.message;
  if (searchNearestError) form.clearErrors(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest as 'searchNearest'}`);

  return true;
};
