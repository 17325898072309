import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';

import { parseServiceRequestsFormForBE } from '../parseServiceRequestsFormForBE/parseServiceRequestsFormForBE';
import { endpoints } from '../serviceRequests';
import type { ServiceRequestsFormDataDto } from '../types';

type Response = ServiceRequestsFormDataDto;
type PostServiceRequestsFormQuery = (data: OrderServiceFormInput) => Promise<DefaultApiResponse<Response>>;

export const submitServiceRequestsForm =
  (patientId: number, paramOrigin: OrderServiceOrigins): PostServiceRequestsFormQuery =>
  data =>
    axios.post<PostServiceRequestsFormQuery, DefaultApiResponse<Response>>(
      endpoints.postServiceRequests,
      parseServiceRequestsFormForBE(data, patientId, paramOrigin),
    );
