// TODO: replace with a generic component once it has been developed
// https://mdtnet.atlassian.net/jira/software/projects/MDT/boards/1?selectedIssue=MDT-1054

import { FC, useMemo } from 'react';

import { SelectChangeEvent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import { AlarmTypeT } from 'api/eCareAlarms/eCareAlarms.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import InputDropdown from 'components/UI/molecules/InputDropdown/InputDropdown';
import { alarmsTypesDictionary } from 'components/UI/organisms/AlarmsList/_dictionaries/alarmsTypesDictionary';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useStyles from './ECareAlarmType.styles';

export type Props = {
  alarmId: string;
  currentOption: AlarmTypeT;
  isValid: boolean;
  isClosed: boolean;
};

const ECareAlarmType: FC<Props> = ({ alarmId, currentOption, isValid, isClosed }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const options = Object.entries(alarmsTypesDictionary).map(([value, label]) => ({ value, label: t(label as string[]) }));

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ALARMS, alarmId], [alarmId]);

  const statusMutation = useMutation(eCareAlarms.patchECareAlarm(alarmId), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
      await queryClient.refetchQueries({ queryKey });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });
  const onStatusChange = async ({ target }: SelectChangeEvent<unknown>) => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.confirmationDialog.sosType.confirmationDialogTitle),
      body: t(proECareAlarmMessages.confirmationDialog.confirmationDialogBody),
    });
    if (confirmed)
      statusMutation.mutate({
        sos_type: target.value as AlarmTypeT,
      });
  };

  return (
    <div>
      <Typography className={classes.label} component='label' variant='body2'>
        {t(proECareAlarmMessages.alarmDetails.sosType)}
      </Typography>
      <InputDropdown
        disabled={isClosed}
        error={isValid && !currentOption}
        fullWidth
        options={options}
        value={currentOption}
        onChange={onStatusChange}
      />
    </div>
  );
};

export default ECareAlarmType;
