import type { FC } from 'react';

import { Card } from '@mui/material';

import OrderServiceBasket from 'components/UI/molecules/OrderServiceBasket/OrderServiceBasket';
import OrderServiceFormServiceTypes from 'components/UI/molecules/OrderServiceFormServiceTypes/OrderServiceFormServiceTypes';
import ServicePayersBar from 'components/UI/molecules/ServicePayersBar/ServicePayersBar';

import useStyles from './PickServicesStep.styles';

const PickServicesStep: FC = () => {
  const { classes } = useStyles();

  return (
    <>
      <section className={classes.mainForm}>
        <Card className={classes.payerFormWrapper}>
          <ServicePayersBar />
        </Card>
        <OrderServiceFormServiceTypes />
      </section>
      <aside className={classes.basket}>
        <OrderServiceBasket />
      </aside>
    </>
  );
};

export default PickServicesStep;
