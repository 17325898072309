import axios from 'axios';

import { PaginatedApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parseApiContentTypeForFE } from './apiContentType.parsers';
import { ApiContentType, ApiContentTypeDTO } from './apiContentType.types';

const endpoint = '/api/v1/content-type/';

type GetAllContentTypesQuery = () => Promise<ApiContentTypeDTO[]>;
type GetApiContentTypeQuery = () => Promise<ApiContentType>;

const apiContentType = {
  getAllContentTypes: (): GetAllContentTypesQuery => async () => {
    const { data } = await axios.get<ApiContentTypeDTO, PaginatedApiResponse<ApiContentTypeDTO>>(
      createUrlWithParams({ url: endpoint, params: { limit: 1000 } }),
    );
    return data.results;
  },
  getApiContentType: (): GetApiContentTypeQuery => async () => {
    const { data } = await axios.get<ApiContentTypeDTO, PaginatedApiResponse<ApiContentTypeDTO>>(
      createUrlWithParams({ url: endpoint, params: { limit: 1000 } }),
    );
    return parseApiContentTypeForFE(data.results);
  },
};
export default apiContentType;
