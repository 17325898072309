import ICON_SIZES_FOR_MAIN_MENU from 'constants/styles/ICON_SIZES_FOR_MAIN_MENU';

const prepareCSSRule = (size: number) => ({
  width: `${size}px !important`,
  height: `${size}px !important`,
});

export const getIconSize = (isMobile: boolean, isOpen: boolean) => {
  if (isMobile) return prepareCSSRule(ICON_SIZES_FOR_MAIN_MENU.mobile.default);
  if (isOpen) return prepareCSSRule(ICON_SIZES_FOR_MAIN_MENU.desktop.open);
  return prepareCSSRule(ICON_SIZES_FOR_MAIN_MENU.desktop.closed);
};
