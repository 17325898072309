import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import { Drawer } from '@mui/material';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import MenuButton from 'components/UI/molecules/MenuButton/MenuButton';
import isIOS from 'helpers/isIOS/isIOS';
import { useIsMobile } from 'hooks/_breakpoints/useIsMobile/useIsMobile';
import useLayoutStorage from 'storages/layoutStorage';

import MoreButton from './_components/MoreButton/MoreButton';
import { useGroupedMenuItems } from './_hooks/useGroupedMenuItems/useGroupedMenuItems';
import useStyles from './MainMenuMobile.styles';

type Props = {
  disabled?: boolean;
};
const MainMenuMobile: FC<Props> = ({ disabled }) => {
  const bodyScrollLockElement = useRef<HTMLDivElement>(null);
  const { isOpen, hideMenu } = useLayoutStorage();
  const { menuItemsToDisplayOnBar, menuItemsToDisplayOnMoreList, shouldDisplayMoreButton } = useGroupedMenuItems();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isIOS()) {
      if (isOpen) disableBodyScroll(bodyScrollLockElement.current as Element);
      else enableBodyScroll(bodyScrollLockElement.current as Element);
    }
    return () => enableBodyScroll(bodyScrollLockElement.current as Element);
  }, [isOpen]);

  useEffect(() => {
    if (isMobile) hideMenu();
    // TODO: implement expand listener
    // TODO: implement on expand layout changes
  }, [isMobile]);

  const { classes } = useStyles({ isOpen, menuItemsNumber: menuItemsToDisplayOnBar.length, shouldDisplayMoreButton });

  return (
    <Drawer
      anchor='bottom'
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true,
      }}
      open={isOpen}
      ref={bodyScrollLockElement}
      variant='permanent'
      onClose={hideMenu}
    >
      <div className={classes.container}>
        {menuItemsToDisplayOnBar.map(({ label, to, isActiveFor, icon, testId, hidden }) => (
          <MenuButton disabled={disabled} hidden={hidden} icon={icon} isActiveFor={isActiveFor} key={label} testId={testId} to={to}>
            {label}
          </MenuButton>
        ))}
        {shouldDisplayMoreButton && <MoreButton listItems={menuItemsToDisplayOnMoreList} />}
      </div>
    </Drawer>
  );
};

export default MainMenuMobile;
