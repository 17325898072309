import { FC, useEffect, useMemo } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareContactNotes from 'api/eCareContactNotes/eCareContactNotes';
import { ECareReceiversResultDict } from 'api/eCareContactNotes/eCareContactNotes.types';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import { Receivers } from 'constants/receiverDictionary/receiverDictionary';
import generalMessages from 'translations/common/general.mjs';
import partnerNotesMessages from 'translations/specific/partnerNotes.mjs';

import useStyles from './PartnerNotesDialog.styles';

type FormInput = {
  content: string;
  CUS: boolean;
  OPS: boolean;
  WYK: boolean;
  CT24: boolean;
};

export type Props = {
  orderId: string;
  serviceRecipientId: string;
  noteId?: string;
  isOpen: boolean;
  close: () => void;
  defaultValue?: FormInput;
  isNew?: boolean;
};

const FORM_ID = 'PartnerNotes_form';

const PartnerNotesDialog: FC<Props> = ({ orderId, serviceRecipientId, noteId, defaultValue, close, isOpen, isNew }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { content, CUS, WYK, OPS, CT24 } = defaultValue || {};

  const { control, getValues, handleSubmit, reset } = useForm<FormInput>({
    defaultValues: {
      content: content || '',
      CUS: isNew ? true : CUS,
      OPS: isNew ? true : OPS,
      WYK: isNew ? true : WYK,
      CT24: isNew ? true : CT24,
    },
  });

  useEffect(() => reset(defaultValue), [defaultValue]);

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, orderId], [orderId]);
  const queryClient = useQueryClient();

  const createNote = eCareContactNotes.createNote();
  const updateNote = eCareContactNotes.patchNoteData(noteId as string);

  const statusMutation = useMutation(isNew ? createNote : updateNote, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const receiversResult = (formData: { [key: string]: boolean | string }) =>
    Object.keys(formData).filter(key => typeof formData[key] === 'boolean' && formData[key] === true);

  const isValidReceivers = () => {
    const formData: FormInput = getValues();
    return receiversResult(formData).length > 0;
  };

  const onSave = async (formData: FormInput) => {
    await statusMutation.mutateAsync({
      content: formData.content as string,
      service_recipient_id: serviceRecipientId,
      receivers: receiversResult(formData) as Receivers[],
      order: orderId,
    });

    reset();
    close();
  };

  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      fullWidth
      maxWidth='sm'
      open={isOpen}
      onClose={close}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText} component='div' variant='h2'>
          {t(partnerNotesMessages.title)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id='alert-dialog-description'>
          <form id={FORM_ID} onSubmit={handleSubmit(onSave)}>
            <FormInputText control={control} label={t(partnerNotesMessages.form.note)} multiline name='content' required rows={6} />
            <div className={classes.filedTitle}>{t(partnerNotesMessages.form.receiverTitle)}</div>
            <div className={classes.checkboxWrapper}>
              {ECareReceiversResultDict.map(callInitiator => (
                <FormInputCheckbox
                  control={control}
                  key={callInitiator}
                  label={callInitiator}
                  name={callInitiator}
                  rules={{
                    validate: () => isValidReceivers() || t(partnerNotesMessages.form.receiverError),
                  }}
                />
              ))}
              <InfoIconWithTooltip content={t(partnerNotesMessages.tooltip.receiver)} wrapperProps={{ sx: { ml: 1 } }} />
            </div>
            <div>{t(partnerNotesMessages.form.receiverHelperText)}</div>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={close}>
          {t(generalMessages.cancel)}
        </Button>
        <Button autoFocus variant='contained' onClick={handleSubmit(onSave)}>
          {t(generalMessages.save)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartnerNotesDialog;
