import { ECareInternalNotes, ECareInternalNotesDTO } from './eCareInternalNotes.types';

export const parseSingleECareInternalNoteForFE = (eCareInternalNoteDTO: ECareInternalNotesDTO): ECareInternalNotes => ({
  id: eCareInternalNoteDTO.id,
  createdAt: new Date(eCareInternalNoteDTO.created_at),
  updatedAt: new Date(eCareInternalNoteDTO.updated_at),
  lastSync: eCareInternalNoteDTO.last_sync ? new Date(eCareInternalNoteDTO.last_sync) : null,
  content: eCareInternalNoteDTO.content,
  order: eCareInternalNoteDTO.order,
  user: eCareInternalNoteDTO.user,
});

export const parseECareInternalNotesListForFE = (eCareInternalNoteDTO: ECareInternalNotesDTO[]): ECareInternalNotes[] =>
  eCareInternalNoteDTO.map(parseSingleECareInternalNoteForFE);
