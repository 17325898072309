import { alpha } from '@mui/material';

const customPalette = {
  triage: {
    consultation: {
      main: '#4dadf7',
      contrastText: '#fafafa',
    },
    consultation_24: {
      main: '#4dadf7',
      contrastText: '#fafafa',
    },
    emergency: {
      main: '#fcc41a',
      contrastText: '#fafafa',
    },
    emergency_ambulance: {
      main: '#ff6a67',
      contrastText: '#fafafa',
    },
    self_care: {
      main: '#1ac98e',
      contrastText: '#fafafa',
    },
  },
  probability: {
    high: {
      main: '#fd6d6c',
    },
    medium: {
      main: '#fcc52d',
    },
    low: {
      main: '#1ec98f',
    },
  },
  colorBackground: {
    primary: {
      light: '#f9fbfd',
      dark: '#effafc',
      main: '#EFFAFC',
      contrastText: '#22a8c9',
    },
  },
  violet: {
    main: '#830def',
    light: alpha('#7F00FF', 0.5),
    dark: alpha('#7F00FF', 0.9),
    contrastText: '#fff',
  },
  table: {
    success: {
      light: '#36C073',
      main: '#29B260',
    },
    warning: {
      light: '#FEAB3C',
      main: '#F78231',
    },
    danger: {
      light: '#EB7E7D',
      main: '#F7615C',
    },
    clarify: {
      light: '#e79898',
      main: '#EB7E7D',
    },
    pending: {
      light: alpha('#E3D844', 0.2),
      main: '#E3D844',
    },
    default: {
      light: '#8D97AA',
      main: '#5F6672',
    },
    received: {
      light: '#5a72a2',
      main: '#3b5da1',
    },
    closed: {
      light: alpha('#7F00FF', 0.5),
      main: '#830def',
    },
  },
};

export default customPalette;
