import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  icon: {
    width: '15px',
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

export default useStyles;
