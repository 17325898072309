import axios from 'axios';

import { DefaultApiResponse } from 'api/_types';

import { parseUserGroupsForFE } from './userGroups.parsers';
import { UserGroups, UserGroupsDTO } from './userGroups.types';

const endpoint = '/api/v1/users/groups/';

type GetUserGroupsQuery = () => Promise<UserGroups>;

const userGroups = {
  getUserGroups: (): GetUserGroupsQuery => async () => {
    const { data } = await axios.get<GetUserGroupsQuery, DefaultApiResponse<UserGroupsDTO>>(endpoint);
    return parseUserGroupsForFE(data);
  },
};
export default userGroups;
