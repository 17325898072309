import { useForm } from 'react-hook-form';

import type { OrderServiceFormInput } from '../../_constants/inputKeys';
import { ORDER_SERVICE_FORM_INPUT_KEYS } from '../../_constants/inputKeys';

export const DEFAULT_VALUES = {
  [ORDER_SERVICE_FORM_INPUT_KEYS.serviceType]: '',
  [ORDER_SERVICE_FORM_INPUT_KEYS.serviceSubtype]: [],
  [ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype]: '',
  address: {
    [ORDER_SERVICE_FORM_INPUT_KEYS.street]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.buildingNumber]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.apartmentNumber]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.city]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.postcode]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.country]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance]: '',
    [ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest]: true,
  },
  details: {},
};

export const useServiceForm = () => {
  const form = useForm<OrderServiceFormInput>({
    defaultValues: DEFAULT_VALUES,
    mode: 'all',
  });

  return {
    form,
    isValid: form.formState.isValid,
  };
};
