import { isArray, set } from 'lodash';

import type { CoreService } from 'api/coreService/coreService.types';
import type { Basket, BasketItem } from 'constants/_types/Basket';

export const incorporateNewItemsIntoBasket = (currentBasket: Basket | null, basketInputToSet: CoreService | CoreService[]) => {
  const basketState = currentBasket || {};
  const basketItemsToSet = isArray(basketInputToSet) ? basketInputToSet : [basketInputToSet];

  const duplicatedItems: BasketItem[] = [];

  basketItemsToSet.forEach(basketItemToSet => {
    const currentKindItems = basketState?.[basketItemToSet.coreServiceKind]?.items || [];

    const duplicatedItem = currentKindItems.find(item => item.id === basketItemToSet.id);

    if (duplicatedItem) {
      duplicatedItems.push(duplicatedItem);
      return;
    }

    set(basketState, `${basketItemToSet.coreServiceKind}.items`, [...currentKindItems, basketItemToSet]);
  });

  return { modifiedBasketState: basketState, duplicatedItems };
};
