export type OrderServiceOrigins = 'TELECONSULTATION' | 'APPOINTMENT' | 'TESTS' | 'OTHERS' | 'HOME_VISIT' | 'SERVICES';

// Adjusted to BE values
export const ORDER_SERVICE_ORIGINS: { [key in OrderServiceOrigins]: OrderServiceOrigins } = {
  TELECONSULTATION: 'TELECONSULTATION', // teleconsultation
  APPOINTMENT: 'APPOINTMENT', // stationaryVisit
  TESTS: 'TESTS', // examinations
  OTHERS: 'OTHERS', // otherServices
  HOME_VISIT: 'HOME_VISIT', // homeVisits
  SERVICES: 'SERVICES', // manually created for services selected from 'services catalogs'
};
