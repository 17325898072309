import type { TFunction } from 'i18next';

import type { CumulativeExtendedServiceItem, CumulativeServiceDetails, CumulativeServiceItem } from 'constants/_types/SelectedServices';

import type { ContentRow } from '../../_types/ContentRow';
import { adjustDateOption } from '../adjustDateOption/adjustDateOption';

interface Params {
  details: CumulativeServiceDetails<Record<string, CumulativeServiceItem> | Record<string, CumulativeExtendedServiceItem>>;
  t: TFunction;
}

export const parseCumulativeServiceToRow = ({ details, t }: Params): ContentRow[] =>
  Object.values(details.serviceItems).map(serviceItem => ({
    serviceId: serviceItem.id,
    serviceName: serviceItem?.nameToDisplay || '',
    serviceDeadline: adjustDateOption({ dateSoon: details.dateSoon, dateFrom: details.dateFrom, dateUntil: details.dateUntil, t }),
    payerId: serviceItem.payer,
  }));
