import type { FC } from 'react';
import { useEffect } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import LinkWithQuery from 'components/UI/atoms/LinkWithQuery/LinkWithQuery';
import AuthTileFooter from 'components/UI/organisms/AuthTileFooter/AuthTileFooter';
import LoginForm from 'components/UI/organisms/LoginForm/LoginForm';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import authMessages from 'translations/common/auth.mjs';
import loginMessages from 'translations/specific/login.mjs';

const LoginPage: FC = () => {
  usePageConfig();
  const { state } = useLocation() as { state?: { accountActivated: boolean } };
  const { showSnackbar } = useShowSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (state?.accountActivated) showSnackbar({ variant: 'success', translationArray: authMessages.account_activated });
  }, []);

  return (
    <>
      <LoginForm />
      <AuthTileFooter>
        <Typography component='span'>{t(loginMessages.footer.register_link_label)}</Typography>{' '}
        <LinkWithQuery to={`/${PATHS.AUTH_REGISTER}`}>{t(loginMessages.footer.register_link)}</LinkWithQuery>
      </AuthTileFooter>
    </>
  );
};

export default LoginPage;
