import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
  },
}));

export default useStyles;
