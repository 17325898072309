import type { FC } from 'react';

import { Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorBackground } from 'assets/error_bg.svg';
import { ReactComponent as FirstAidIcon } from 'assets/error_first_aid.svg';
import { ReactComponent as DisabledIcon } from 'assets/icons/decorative/disabled.svg';
import { ReactComponent as DnaIcon } from 'assets/icons/decorative/dna.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/decorative/heart.svg';
import { useRefreshPage } from 'hooks/useRefreshPage/useRefreshPage';
import generalMessages from 'translations/common/general.mjs';

import useStyles from './GeneralErrorContent.styles';

type Props = {
  standAlone?: boolean;
  refreshable?: boolean;
};
const GeneralErrorContent: FC<Props> = ({ standAlone = false, refreshable = true }) => {
  const { t } = useTranslation();
  const handleRefreshPage = useRefreshPage();

  const { classes } = useStyles({ standAlone });

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.iconsContainer}>
          <Typography className={classes.title} color='primary' component='h1' data-testid='errorboundary'>
            {t(generalMessages.generalInterfaceError.title)}
          </Typography>
          <ErrorBackground className={classes.errorBackground} />
          <FirstAidIcon className={classes.firstAid} />
        </div>

        <Typography className={classes.header} color='secondary' component='h2'>
          {t(generalMessages.generalInterfaceError.header)}
        </Typography>
        {refreshable && (
          <Typography className={classes.subheader} color='secondary' component='h3'>
            {t(generalMessages.generalInterfaceError.subheader)}
          </Typography>
        )}

        <div className={classes.buttonWrapper}>
          {refreshable && (
            <Button className={classes.button} color='primary' variant='contained' onClick={handleRefreshPage}>
              {t(generalMessages.generalInterfaceError.refreshButtonLabel)}
            </Button>
          )}
        </div>

        <div className={classes.contactContainer}>
          <Typography className={classes.contactDescription} component='p'>
            {t(generalMessages.generalInterfaceError.contactDescription)}
          </Typography>
          <Typography className={classes.contactPhoneNumber} component='p'>
            {t(generalMessages.pageNotFound.contactPhoneNumber)}
          </Typography>
        </div>

        <DnaIcon className={classes.dnaIcon} />
        <DisabledIcon className={classes.disabledIcon} />
        <HeartIcon className={classes.heartIcon} />
      </Card>
    </div>
  );
};

export default GeneralErrorContent;
