import { useState } from 'react';

import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';

import users, { RegisterQueryInput } from 'api/users';
import { RegisterFormInput } from 'components/UI/organisms/RegisterForm/RegisterForm';
import PATHS from 'constants/router/PATHS';
import useMutationWithRecaptcha from 'hooks/useMutationWithRecaptcha/useMutationWithRecaptcha';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery/useNavigateWithQuery';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';

export const REGISTER_MUTATION_KEY = 'Register dialog request';

type UseRegisterMutationArguments = {
  setError: UseFormSetError<RegisterFormInput>;
};

const useRegisterMutation = ({ setError }: UseRegisterMutationArguments) => {
  const navigateWithQuery = useNavigateWithQuery();

  const [generalErrors, setGeneralErrors] = useState<string[]>();
  const { mutateWithRecaptcha } = useMutationWithRecaptcha('Register_dialog');

  const onError = useOnBackendFormError<RegisterFormInput, RegisterQueryInput>({
    setError,
    setGeneralErrors,
  });

  const { mutate, isLoading } = useMutation(REGISTER_MUTATION_KEY, users.register(), {
    retry: 0,
    onSuccess: ({ data }) => {
      const { email } = data;
      if (email) navigateWithQuery(`/${PATHS.AUTH_REGISTER_SUCCESS}`, { state: { email } });
    },
    onError,
  });

  const registerMutate = async (mutationData: Omit<RegisterQueryInput, 'recaptcha'>) => {
    mutateWithRecaptcha<Omit<RegisterQueryInput, 'recaptcha'>>(mutate, mutationData);
  };

  return { registerMutate, registerMutateGeneralErrors: generalErrors, isRegisterMutating: isLoading };
};

export default useRegisterMutation;
