import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import eCareInternalNotes from 'api/eCareInternalNotes/eCareInternalNotes';
import { ECareInternalNotes } from 'api/eCareInternalNotes/eCareInternalNotes.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import BoxWithHeader from 'components/UI/molecules/BoxWithHeader/BoxWithHeader';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import eCareInternalNotesMessages from 'translations/specific/eCareInternalNotes.mjs';

import InternalNotesList from './_components/InternalNotesList/InternalNotesList';
import useInternalNoteTable from './_hooks/useInternalNoteTable/useInternalNoteTable';
import useStyles from './ECareInternalNotes.styles';

type FormInput = {
  content: string;
};

type Props = {
  orderId: string;
};

const ECareInternalNotesComponent: FC<Props> = ({ orderId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_INTERNAL_NOTES, orderId], [orderId]);
  const { data: internalNoteData } = useQuery(queryKey, eCareInternalNotes.getAllNotes(orderId), {
    refetchOnWindowFocus: false,
  });

  const internalNoteTable = useInternalNoteTable(internalNoteData?.data as ECareInternalNotes[]);

  const showNotesHistory = () => {
    showConfirmationDialog({
      title: t(eCareInternalNotesMessages.historyTitle),
      body:
        internalNoteTable?.data &&
        internalNoteTable.data.map(note => (
          <BoxWithHeader
            className={classes.boxList}
            description={note.content}
            header={[
              {
                label: t(eCareInternalNotesMessages.table.updateDate),
                value: unknownDateToString(note.updatedAt, DATE_FORMATS.DISPLAY) || t(generalMessages.noData),
              },
            ]}
            key={note.id}
          />
        )),
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareInternalNotes.createNote(), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues, reset } = useForm<FormInput>();

  const addNewNote = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(eCareInternalNotesMessages.addNote),
      body: <FormInputText control={control} label='' multiline name='content' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed) {
      await statusMutation.mutateAsync({
        content: getValues('content'),
        order_id: orderId,
      });
    }

    // reset form;
    reset();
  };

  return (
    <SectionWithTitle
      className={classes.section}
      title={t(eCareInternalNotesMessages.title)}
      titleIcon={
        <Button variant='contained' onClick={addNewNote}>
          {t(eCareInternalNotesMessages.addNote)}
        </Button>
      }
      titleRightSideComponent={
        internalNoteData &&
        internalNoteData.data.length > 0 && (
          <Button variant='outlined' onClick={showNotesHistory}>
            {t(eCareInternalNotesMessages.moreNotes)}
          </Button>
        )
      }
    >
      {internalNoteData && <InternalNotesList data={internalNoteData.data as ECareInternalNotes[]} />}
    </SectionWithTitle>
  );
};

export default ECareInternalNotesComponent;
