import axios from 'axios';

const endpoints = {
  suspension: '/api/v1/ecare-suspension/',
  phoneCall: '/api/v1/ecare-phone-call/',
  contactNote: '/api/v1/ecare-contact-note/',
  comment: '/api/v1/ecare-comment/',
  technicalIssues: '/api/v1/ecare-technical-problem/',
  sos: '/api/v1/ecare-sos/',
  order: '/api/v1/ecare-order/',
};

export type ECareSyncCusEndpoint = keyof typeof endpoints;

const eCareSyncWithCus = {
  syncWithCus: (id: string, syncEndpoints: ECareSyncCusEndpoint[]) => async () => {
    try {
      const promises = syncEndpoints.map(endpoint => axios.post(`${endpoints[endpoint]}${id}/send/`, {}));
      await Promise.all(promises);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
};

export default eCareSyncWithCus;
