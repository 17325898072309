import { FC, useMemo } from 'react';

import { Input } from '@mui/material';
import { isBefore } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareSuspension from 'api/eCareSuspension/eCareSuspension';
import { ECareSuspension } from 'api/eCareSuspension/eCareSuspension.types';
import DialogFormWrapper from 'components/UI/molecules/DialogFormWrapper/DialogFormWrapper';
import FormInputDate from 'components/UI/organisms/_formInputs/FormInputDate/FormInputDate';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import useStyles from '../ProfessionalEOrderFormDialog.styles';

export type Props = {
  orderId: string;
  close: () => void;
  suspension?: ECareSuspension;
  isNew?: boolean;
};

type FormInput = {
  date_from: string;
  date_to: string;
  reason: string;
  order?: string;
};

const FORM_ID = 'suspensionsForm';

const SuspensionsForm: FC<Props> = ({ suspension, orderId, close, isNew = false }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState, reset, getValues } = useForm<FormInput>();
  const { classes } = useStyles();

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_SUSPENSIONS, suspension?.id], [suspension?.id]);
  const queryClient = useQueryClient();

  const apiMethod = useMemo(() => {
    if (!isNew && suspension) return eCareSuspension.patchECareSuspensionData(suspension.id);
    return eCareSuspension.createECareSuspension();
  }, [isNew, suspension]);

  const statusMutation = useMutation(apiMethod, {
    onMutate: async newStatus => {
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueryData(queryKey, { ...suspension, newStatus });
      close();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.GET_ECARE_ORDER, orderId]);
      reset();
    },
  });

  const onSubmit = (formData: FormInput) => {
    statusMutation.mutate(formData);
  };

  const defaultValues = useMemo(() => {
    if (isNew) return {};
    return {
      dateFrom: suspension?.dateFrom || null,
      dateTo: suspension?.dateTo || null,
      reason: suspension?.reason || '',
    };
  }, [suspension, isNew]);

  return (
    <DialogFormWrapper formState={formState} id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className={classes.inputWrapper}>
          <FormInputDate
            control={control}
            defaultValue={defaultValues.dateFrom}
            disablePast
            label={t(proECareOrderMessages.suspensions.dateFrom)}
            name='date_from'
            required
            rules={{
              validate: (value: Date) =>
                !isBefore(new Date(getValues('date_to')), value) || t(proECareOrderMessages.validationForm.dateFromIsLargerThenDateTo),
            }}
          />
        </div>
        <div className={classes.inputWrapper}>
          <FormInputDate
            control={control}
            defaultValue={defaultValues.dateTo}
            disablePast
            label={t(proECareOrderMessages.suspensions.dateTo)}
            name='date_to'
            required
          />
        </div>
        <div className={classes.inputWrapper}>
          <FormInputText
            control={control}
            defaultValue={defaultValues.reason}
            label={t(proECareOrderMessages.suspensions.reason)}
            name='reason'
            required
          />
        </div>
        <Controller control={control} defaultValue={orderId} name='order' render={({ field }) => <Input {...field} type='hidden' />} />
      </>
    </DialogFormWrapper>
  );
};

export default SuspensionsForm;
