import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import isProduction from 'helpers/isProduction/isProduction';

type AppPermissionsStorage = {
  mainFunctionalitiesAllowed: boolean;
  setMainFunctionalitiesAllowed: (newValue: boolean) => void;
};

const storage = persist<AppPermissionsStorage>(
  set => ({
    mainFunctionalitiesAllowed: false,
    setMainFunctionalitiesAllowed: newValue => set({ mainFunctionalitiesAllowed: newValue }),
  }),
  { name: 'appPermissionsStorage', getStorage: () => sessionStorage },
);

const useAppPermissionsStorage = isProduction() ? create(storage) : create(devtools(storage));

export default useAppPermissionsStorage;
