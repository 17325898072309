import type { FC } from 'react';
import { useCallback } from 'react';

import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { ParsedListData } from 'api/_parsedTypes';
import type { MobileCardHeader } from 'constants/_types/MobileCardHeader';
import type { MobileCardRow, MobileCardRowCustomStyles } from 'constants/_types/MobileCardRow';

import useStyles from './TableMobileCardRow.styles';

export interface Props {
  mainHeader: MobileCardHeader | null;
  bodyHeaders: MobileCardHeader[];
  row: MobileCardRow;
  rowCustomStyles: MobileCardRowCustomStyles;
  rowLink?: ({ meta }: ParsedListData) => string;
  actionsRenderer?: ({ meta, created_at }: ParsedListData, shouldShowVerticalVersion?: boolean) => JSX.Element;
}

const TableMobileCardRow: FC<Props> = ({ mainHeader, bodyHeaders, row, rowCustomStyles, rowLink, actionsRenderer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { classes } = useStyles({ headersHaveIcons: !!(mainHeader?.icon || bodyHeaders[0]?.icon), rowCustomStyles });

  const handleClick = useCallback(() => {
    if (!rowLink) return;

    navigate(rowLink(row as ParsedListData));
  }, [navigate, rowLink]);

  return (
    <Card className={classes.card}>
      <CardActionArea disableRipple onClick={handleClick}>
        {mainHeader && actionsRenderer && (
          // the first item in the line of action button
          <CardHeader
            action={actionsRenderer(row as ParsedListData, true)}
            classes={{
              root: classes.materialCardHeaderRoot,
              content: classes.materialCardHeaderContent,
              title: classes.materialCardHeaderTitle,
              subheader: classes.materialCardHeaderSubheader,
            }}
            subheader={get(row, mainHeader.id, null)} // first item row content
            title={t(mainHeader.translationArray)} // first item header
          />
        )}
        <CardContent className={classes.materialCardContent}>
          {bodyHeaders.map(({ id, icon, translationArray }) => (
            <div className={classes.cardItem} key={id}>
              <div className={classes.header}>
                {icon && icon}
                <Typography className={classes.label} variant='caption'>
                  {t(translationArray)}
                </Typography>
              </div>
              <div className={classes.rowItem}>{get(row, id, null)}</div>
            </div>
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TableMobileCardRow;
