import { has } from 'lodash';
import { Path, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import scrollToError from 'services/scrollToError/scrollToError';

type UseOnBackendFormErrorReturn<InputType> = (
  error: { response?: { status: number; data: { [key: string]: string[] } } },
  variables: InputType,
) => void;

type UseOnBackendFormErrorArguments<FormType> = {
  setError: UseFormSetError<FormType>;
  setGeneralErrors: (errors: string[]) => void;
};

const useOnBackendFormError = <FormType, InputType>({
  setError,
  setGeneralErrors,
}: UseOnBackendFormErrorArguments<FormType>): UseOnBackendFormErrorReturn<InputType> => {
  const { t } = useTranslation();

  return (error, variables) => {
    const { response } = error;
    if (response?.status && response.status < 500) {
      const generalErrors: string[] = [];
      let scrolled = false;
      Object.entries(response?.data).forEach(([key, value]) => {
        if (has(variables, key)) {
          setError(key as Path<FormType>, { message: t(value) });
          if (!scrolled) scrollToError(key);
          scrolled = true;
        } else {
          generalErrors.push(t(value));
        }
      });
      if (generalErrors.length) setGeneralErrors(generalErrors);
    }
  };
};

export default useOnBackendFormError;
