import type { FC, ReactNode } from 'react';

import useStyles from './ServicesReviewGroupsWrapper.styles';

export type Props = {
  areEmptyGroups: boolean;
  children: ReactNode;
};

const ServicesReviewGroupsWrapper: FC<Props> = ({ areEmptyGroups, children }) => {
  const { classes } = useStyles({ areEmptyGroups });

  return <section className={classes.root}>{children}</section>;
};

export default ServicesReviewGroupsWrapper;
