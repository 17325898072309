import en from 'constants/dropdownOptions/countries/en';
import es from 'constants/dropdownOptions/countries/es';
import pl from 'constants/dropdownOptions/countries/pl';
import type { SupportedLanguages } from 'constants/translations/_types';
import { SUPPORTED_LANGUAGES } from 'constants/translations/_types';

type LanguageJSON = { name: string; code: string };

export const getCountryJSON = (languageCode: SupportedLanguages): LanguageJSON[] => {
  switch (languageCode) {
    case SUPPORTED_LANGUAGES.EN: {
      return en;
    }
    case SUPPORTED_LANGUAGES.ES: {
      return es;
    }
    case SUPPORTED_LANGUAGES.PL:
    default: {
      return pl;
    }
  }
};
