import { FC, useMemo } from 'react';

import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import { KeyHolder } from 'api/_commonTypes/ServiceRecipient';
import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import generatePolishPostCode from 'helpers/generatePolishPostCode/generatePolishPostCode';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

import CardContentContainer from '../CardContentContainer/CardContentContainer';
import useStyles from './ProfessionalECareOrderInternalPatientData.styles';

export type Props = {
  data: ECareOrder | undefined;
};

const parseStreet = (street: string, buildingNumber: string, apartmentNumber: string) => {
  const parts = [street];
  if (buildingNumber) parts.push(` ${buildingNumber}`);
  if (apartmentNumber) parts.push(`/${apartmentNumber}`);
  return parts.join('');
};
const InfoAboutEdition = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ml: 1 }}>
      <InfoIconWithTooltip content={t(proECareOrderMessages.columns.innerPersonalDataTooltip)} />
    </Box>
  );
};

const ProfessionalECareOrderInternalPatientData: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const keyHoldersList = (keyHolder: KeyHolder) => [
    {
      label: t(proECareOrderMessages.keyHolders.firstName),
      value: keyHolder.firstName,
    },
    {
      label: t(proECareOrderMessages.keyHolders.lastName),
      value: keyHolder.lastName,
    },

    {
      label: t(proECareOrderMessages.keyHolders.phoneNumber),
      value: keyHolder.phoneNumber,
    },
  ];

  const generateKeyHolders = useMemo(
    () =>
      data?.serviceRecipient?.keyHolders?.map(keyHolder => (
        <DefinitionList columns={3} definitions={keyHoldersList(keyHolder)} fontSize='small' />
      )),
    [data?.serviceRecipient?.keyHolders],
  );

  const patientData = [
    {
      label: t(proECareOrderMessages.patientsTable.phoneNumber),
      labelElement: <InfoAboutEdition />,
      value: data?.patient?.personalData.phoneNumber || t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.patientsTable.dateOfBirth),
      value: data?.patient?.personalData.dateOfBirth
        ? unknownDateToString(data.serviceRecipient.dateOfBirth, DATE_FORMATS.DISPLAY)
        : t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.patientsTable.pesel),
      value: data?.patient?.pesel || t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.eOrderTableHeaders.alertSystemContract),
      labelElement: (
        <Box sx={{ ml: 1 }}>
          <InfoIconWithTooltip content={t(proECareOrderMessages.tooltip.alertSystemContract)} />
        </Box>
      ),
      value: data?.companyContractId || t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.patientsTable.projectUserNumber),
      value: data?.patient?.projectUserNumber || t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.eOrderTableHeaders.callFrequency),
      labelElement: (
        <Box sx={{ ml: 1 }}>
          <InfoIconWithTooltip content={t(proECareOrderMessages.tooltip.callFrequency)} />
        </Box>
      ),
      value: isNil(data?.callFrequency)
        ? t(generalMessages.noData)
        : // not null assertion - data is not null, it's checked in isNil function
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          t(proECareOrderMessages.callFrequencyWithUnit, { frequency: data!.callFrequency }),
    },
    {
      label: t(proECareOrderMessages.eOrderTableHeaders.city),
      labelElement: <InfoAboutEdition />,
      value: data?.patient?.personalData.mainAddress.city || t(generalMessages.noData),
    },

    {
      label: t(proECareOrderMessages.eOrderTableHeaders.postalCode),
      labelElement: <InfoAboutEdition />,
      value: data?.patient?.personalData.mainAddress.postcode
        ? generatePolishPostCode(data.patient.personalData.mainAddress.postcode)
        : t(generalMessages.noData),
    },

    {
      label: t(proECareOrderMessages.eOrderTableHeaders.street),
      labelElement: <InfoAboutEdition />,
      value: data?.patient?.personalData.mainAddress.streetName
        ? parseStreet(
            data.patient.personalData.mainAddress.streetName,
            data.patient.personalData.mainAddress.buildingNumber,
            data.patient.personalData.mainAddress.apartmentNumber,
          )
        : t(generalMessages.noData),
    },
  ];

  return (
    <CardContentContainer>
      <div className={classes.container}>
        <div className={classes.borderRight}>
          <SectionWithTitle title={t(proECareOrderMessages.columns.internalPersonalData)}>
            <DefinitionList columns={5} definitions={patientData} fontSize='small' />
          </SectionWithTitle>
        </div>
        <div>
          <SectionWithTitle title={t(proECareOrderMessages.patientsTable.keyHolders)}>{generateKeyHolders}</SectionWithTitle>
        </div>
      </div>
    </CardContentContainer>
  );
};

export default ProfessionalECareOrderInternalPatientData;
