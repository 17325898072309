import REMEMBERED_PATH from 'constants/_queryParams/REMEMBERED_PATH';
import PATHS, { AUTH_PREFIX } from 'constants/router/PATHS';

import { getPathWithQueryParams } from '../getPathWithQueryParams/getPathWithQueryParams';

export const getLoginPathWithAttachedRememberedPath = (currentPath = '') => {
  const loginPath = `/${AUTH_PREFIX}`;
  const pathToRemember = currentPath || getPathWithQueryParams();

  // prevents to remember given paths
  const currentUrlIsDashboardUrl = pathToRemember === PATHS.ROOT;
  const isAuthPath = pathToRemember.includes(AUTH_PREFIX);
  if (!pathToRemember || currentUrlIsDashboardUrl || isAuthPath) return loginPath;

  const searchToAppend = {
    [REMEMBERED_PATH]: pathToRemember,
  };

  return getPathWithQueryParams({ patch: loginPath, searchToAppend });
};
