import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import type { FormInputsConfig } from 'constants/_types/FormInputsConfig';
import useAddressDictionaries from 'hooks/useAddressDictionaries/useAddressDictionaries';
import useLanguageCode from 'hooks/useLanguageCode/useLanguageCode';
import getCountryDropdown from 'services/_dropdownParsers/getCountryDropdown/getCountryDropdown';
import { validatePostalCode } from 'services/_validators/validatePostalCode/validatePostalCode';
import { resolvePostalCodePattern } from 'services/resolvePostalCodePattern/resolvePostalCodePattern';
import generalMessages from 'translations/common/general.mjs';
import validationMessages from 'translations/common/validation.mjs';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { getSearchAroundOptions } from '../../_services/getSearchAroundOptions/getSearchAroundOptions';
import { useSearchAddressInputsRelation } from '../useSearchAddressInputsRelation/useSearchAddressInputsRelation';
import { validateSearchAround } from './_services/validateSearchAround/validateSearchAround';
import { validateSearchNearest } from './_services/validateSearchNearest/validateSearchNearest';

export const useAddressFormConfig = () => {
  const { t } = useTranslation();
  const languageCode = useLanguageCode();
  const { form } = useOrderServiceFormContext();

  const countryValue = form.watch(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.country as 'country'}`);
  const postalCodePatterns = useMemo(() => resolvePostalCodePattern({ selectedCountryOptionValue: countryValue }), [countryValue]);

  const { cityOptions, streetOptions } = useAddressDictionaries<OrderServiceFormInput>({
    form,
    postcodeInputKey: `address.${ORDER_SERVICE_FORM_INPUT_KEYS.postcode as 'postcode'}`,
    cityInputKey: `address.${ORDER_SERVICE_FORM_INPUT_KEYS.city as 'city'}`,
    streetInputKey: `address.${ORDER_SERVICE_FORM_INPUT_KEYS.street as 'street'}`,
    isOutsidePatientEditor: true,
    postalCodePattern: postalCodePatterns?.rulePattern,
  });

  const { shouldDisableSearchAroundDistance } = useSearchAddressInputsRelation();

  const addressInputs = useMemo(
    (): FormInputsConfig => [
      {
        label: t(generalMessages.address.country),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.country,
        inputs: {
          [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.country}`]: {
            type: 'autocomplete',
            inputProps: {
              freeSolo: true,
            },
            options: getCountryDropdown(languageCode),
            required: true,
          },
        },
      },
      {
        label: t(generalMessages.address.postCode),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.postcode,
        inputs: {
          [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.postcode}`]: {
            type: 'text',
            required: true,
            rules: {
              validate: (currentValue?: string) =>
                validatePostalCode({
                  currentValue,
                  currentCountryValue: form.getValues(`address.${ORDER_SERVICE_FORM_INPUT_KEYS.country as 'country'}`),
                  errorMessage: t(validationMessages.invalid_postcode),
                }),
            },
            mask: postalCodePatterns
              ? {
                  pattern: postalCodePatterns.maskPattern,
                  definitions: {
                    '#': /[0-9]/,
                  },
                }
              : undefined,
          },
        },
      },
      {
        label: t(generalMessages.address.city),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.city,
        inputs: {
          [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.city}`]: {
            type: 'autocomplete',
            options: cityOptions,
            required: true,
            inputProps: { freeSolo: true },
          },
        },
      },
      {
        label: t(generalMessages.address.street),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.street,
        inputs: {
          [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.street}`]: {
            type: 'autocomplete',
            options: streetOptions,
            required: true,
            inputProps: { freeSolo: true },
          },
        },
      },
      {
        label: t(generalMessages.address.buildingNumber),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.buildingNumber,
        inputs: { [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.buildingNumber}`]: { type: 'text', required: true } },
      },
      {
        label: t(generalMessages.address.apartmentNumber),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.apartmentNumber,
        inputs: { [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.apartmentNumber}`]: { type: 'text' } },
      },
      {
        label: t(orderServiceMessages.location.searchAround),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance,
        inputs: {
          [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance}`]: {
            type: 'dropdown',
            options: getSearchAroundOptions(),
            inputProps: { disabled: shouldDisableSearchAroundDistance },
            rules: {
              validate: (currentValue: string) =>
                validateSearchAround({
                  currentValue,
                  shouldDisableSearchAroundDistance,
                  form,
                  errorMessage: t(orderServiceMessages.location.validationErrors.searchLocation),
                }),
            },
          },
        },
      },
      {
        label: t(orderServiceMessages.location.searchNearest),
        gridArea: ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest,
        inputs: {
          [`address.${ORDER_SERVICE_FORM_INPUT_KEYS.searchNearest}`]: {
            type: 'checkbox',
            tiny: true,
            rules: {
              validate: (currentValue: boolean) =>
                validateSearchNearest({
                  currentValue,
                  form,
                  errorMessage: t(orderServiceMessages.location.validationErrors.searchLocation),
                }),
            },
          },
        },
      },
    ],
    [languageCode, shouldDisableSearchAroundDistance, cityOptions, streetOptions, postalCodePatterns],
  );

  return addressInputs;
};
