import { FC, useEffect, useMemo } from 'react';

import { Card } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import eCareTechnicalIssues from 'api/eCareTechnicalIssues/eCareTechnicalIssues';
import { ReactComponent as ECareTechnicalIssuesListIcon } from 'assets/icons/hub/active/issues.svg';
import AsyncDisplay from 'components/UI/molecules/AsyncDisplay/AsyncDisplay';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import ProfessionalECareTechnicalIssueInfo from 'components/UI/organisms/ProfessionalECareTechnicalIssueInfo/ProfessionalECareTechnicalIssueInfo';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import useDynamicPathConfig from 'hooks/useDynamicPathConfig/useDynamicPathConfig';
import breadcrumbsMessages from 'translations/common/breadcrumbs.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';

const ProfessionalTechnicalIssuesSinglePage: FC = () => {
  const { setBreadcrumbs } = useDynamicPathConfig();
  const { issueId } = useParams() as unknown as { issueId: string };

  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.GET_ECARE_TECHNICAL_ISSUES, issueId],
    eCareTechnicalIssues.getECareTechnicalIssue(issueId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const headerLabel = useMemo(() => {
    if (!data) return t(proECareTechnicalIssuesMessages.title);
    const deviceName = data.device.name;
    const deviceSerialNumber = data.device.serialNumber;
    return `${t(proECareTechnicalIssuesMessages.title)}: ${deviceName} (${deviceSerialNumber})`;
  }, [t, data]);

  useEffect(() => {
    if (data) {
      setBreadcrumbs([
        {
          translationArray: breadcrumbsMessages.pro_ecare_technical_issue_single,
          translationParams: {
            date: format(data.date, 'dd.MM.yyyy'),
          },
          path: '',
          isCurrent: true,
        },
      ]);
    } else {
      setBreadcrumbs([{ translationArray: breadcrumbsMessages.pro_ecare_technical_issue_single, path: '', isCurrent: true }]);
    }
  }, [data]);

  const pageTitle = {
    headerLabel,
    icon: <ECareTechnicalIssuesListIcon />,
    extraContentComponent: <span />,
  };

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitleWithFiltersForTables pageTitle={pageTitle} />
      <Card>
        <AsyncDisplay hasData={!!data} isError={isError} isLoading={isLoading}>
          <ProfessionalECareTechnicalIssueInfo data={data!} />
        </AsyncDisplay>
      </Card>
    </MainContentWrapper>
  );
};

export default ProfessionalTechnicalIssuesSinglePage;
