import { FC } from 'react';

import { Typography } from '@mui/material';

import DefinitionList, { DefinitionListValue } from 'components/UI/molecules/DefinitionList/DefinitionList';

import useStyles from './BoxWithHeader.styles';

export type Props = {
  header: {
    label: string;
    value: DefinitionListValue | DefinitionListValue[];
  }[];
  description: string;
  headerElement?: JSX.Element;
  className?: string;
};

const NUMBER_OF_COLUMNS = 4;

const BoxWithHeader: FC<Props> = ({ header, description, headerElement, className }) => {
  const { cx, classes } = useStyles({ numberOfColumns: NUMBER_OF_COLUMNS });

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.headerWrapper}>
        <DefinitionList
          columns={NUMBER_OF_COLUMNS}
          customClasses={{
            root: classes.header,
            listItemClass: classes.listItemClass,
          }}
          definitions={header}
          fontSize='small'
        />
        {headerElement}
      </div>
      <Typography className={classes.paragraph} component='p' data-testid='subheader' variant='caption'>
        {description}
      </Typography>
    </div>
  );
};

export default BoxWithHeader;
