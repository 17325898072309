import { makeStyles } from 'tss-react/mui';

import type { MobileCardRowCustomStyles } from 'constants/_types/MobileCardRow';

const useStyles = makeStyles<{ headersHaveIcons: boolean; rowCustomStyles: MobileCardRowCustomStyles }>()(
  (theme, { headersHaveIcons, rowCustomStyles: { cardPadding, ...rowCustomStylesRest } }) => {
    const gapBetweenCardItemsInSpacing = 2;

    const cardItem = {
      display: 'grid',
      gridGap: theme.spacing(1),
    };

    return {
      card: {
        display: 'grid',
        gridGap: theme.spacing(3),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        '& > button': {
          padding: cardPadding,
        },
      },

      cardItem,

      header: {
        display: 'grid',
        gridTemplateColumns: headersHaveIcons ? '17px auto' : 'auto',
        gridGap: theme.spacing(1),
      },

      ...rowCustomStylesRest,

      materialCardHeaderRoot: {
        padding: theme.spacing(0, 0, gapBetweenCardItemsInSpacing, 0),
      },

      materialCardHeaderContent: {
        ...cardItem,
      },

      materialCardHeaderTitle: {
        ...rowCustomStylesRest.label,
      },

      materialCardHeaderSubheader: {
        ...rowCustomStylesRest.rowItem,
      },

      materialCardContent: {
        display: 'grid',
        gridGap: theme.spacing(gapBetweenCardItemsInSpacing),
        padding: 0,
      },
    };
  },
);

export default useStyles;
