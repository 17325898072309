import axios from 'axios';

import { authEndpoints } from 'api/auth';
import languages from 'constants/translations/languages';
import isDevelopment from 'helpers/isDevelopment/isDevelopment';
import { getLoginPathWithAttachedRememberedPath } from 'services/_rememberedPath/getLoginPathWithAttachedRememberedPath/getLoginPathWithAttachedRememberedPath';
import accessTokenStorage from 'storages/accessTokenStorage';
import refreshTokenStorage from 'storages/refreshTokenStorage';

const urlsWithoutRefresh = [authEndpoints.login];

let refreshing = false;
let lastRefresh = new Date().getTime();

export const refreshToken = async () => {
  const refresh = refreshTokenStorage.get();
  if (!refresh || refreshing) return null;
  if (new Date().getTime() - lastRefresh < 100) return null;
  lastRefresh = new Date().getTime();
  refreshing = true;
  const { data: newTokens } = await axios.post(authEndpoints.refresh, { refresh: refresh.data });

  accessTokenStorage.set(newTokens.access);
  refreshTokenStorage.set({ data: newTokens.refresh });

  const authorization = `Bearer ${newTokens.access}`;
  axios.defaults.headers.common.Authorization = authorization;
  refreshing = false;
  return authorization;
};

const axiosSetup = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  let initLang = window.localStorage.getItem('i18nextLng');
  if (!initLang) {
    const supportedLanguages: string[] = languages.map(({ code }) => code);
    initLang = navigator.languages.find(userPreferredLang => supportedLanguages.includes(userPreferredLang)) || 'en';
  }
  axios.defaults.headers.common['Accept-Language'] = initLang;

  const createAxiosResponseInterceptor = () => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const status = error?.response?.status;
        const url = error?.config?.url;
        if (status !== 401 || urlsWithoutRefresh.some(disabledUrl => url.includes(disabledUrl))) {
          return Promise.reject(error);
        }

        if (status === 401 && url === authEndpoints.refresh) {
          accessTokenStorage.destroy();
          refreshTokenStorage.destroy();
          window.location.href = getLoginPathWithAttachedRememberedPath();
          axios.defaults.headers.common.Authorization = false;
        }

        // prevent looping
        axios.interceptors.response.eject(interceptor);
        try {
          // eslint-disable-next-line no-param-reassign
          error.response.config.headers.Authorization = await refreshToken();
          return axios(error.response.config);
        } catch (axiosError) {
          accessTokenStorage.destroy();
          refreshTokenStorage.destroy();
          window.location.href = getLoginPathWithAttachedRememberedPath(); // saves current path if session expires
          // eslint-disable-next-line no-console
          if (isDevelopment()) console.error(axiosError);
          return Promise.reject(error);
        } finally {
          createAxiosResponseInterceptor();
        }
      },
    );
  };
  createAxiosResponseInterceptor();
  return axios;
};

export default axiosSetup;
