import { ParsedPatientData } from 'api/_parsedTypes';
import type { PatientLegacy } from 'api/_types';
import type { UserInfo } from 'components/context/AuthContext/AuthContext';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import { parseAddress } from 'services/parseAddress/parseAddress';

export const userDataParser = (dataToParse: PatientLegacy, userInfo: UserInfo, isPro: boolean): ParsedPatientData => {
  const phoneNumber = isPro ? dataToParse.personal_data?.phone_number : userInfo?.phoneNumber;

  return {
    name: dataToParse.personal_data.first_name,
    surname: dataToParse.personal_data.last_name,
    email: dataToParse.personal_data.email,
    gender: dataToParse.personal_data.gender,
    pesel: dataToParse.pesel,
    hasForeignDocument: dataToParse.foreign_document,
    documentType: dataToParse.foreign_document_type,
    documentNumber: dataToParse.foreign_document_number,
    documentCountry: dataToParse.foreign_document_country,
    dateOfBirth: unknownDateToString(dataToParse.personal_data.date_of_birth),
    phoneNumber: phoneNumber || '',
    imageUrl: dataToParse.cropped_image,
    addressDirections: dataToParse.address_directions,
    contactPerson: dataToParse.contact_person,
    ...parseAddress(dataToParse.personal_data.main_address),
  };
};
