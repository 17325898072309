import { FC } from 'react';

import { EcarePhoneCall } from 'api/eCarePhoneCall/eCarePhoneCall.types';
import BitrixNotes from 'components/UI/molecules/BitrixNotes/BitrixNotes';

import PartnerNotes from './_components/PartnerNotes/PartnerNotes';
import useStyles from './ProfessionalEcarePhoneCallNotes.styles';

type Props = {
  data: EcarePhoneCall;
};

const ProfessionalEcarePhoneCallNotes: FC<Props> = ({ data }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.notesWrapper}>
      <BitrixNotes contentType='phonecall' objectId={data.id} />
      <PartnerNotes eCarePhoneCallsData={data} />
    </div>
  );
};

export default ProfessionalEcarePhoneCallNotes;
