import type { FC } from 'react';

import { Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ContactInfoData } from 'api/contactInfo/types';
import { useIsMobile } from 'hooks/_breakpoints/useIsMobile/useIsMobile';
import helpMessages from 'translations/specific/help.mjs';

import ContactForm from './_component/ContactForm/ContactForm';
import ContactTiles from './_component/ContactTiles/ContactTiles';
import MobileContactTiles from './_component/MobileContactTiles/MobileContactTiles';
import useStyles from './ContactCard.styles';

export interface Props {
  data: ContactInfoData;
  hasInternalBranding: boolean;
}

const ContactCard: FC<Props> = ({ data, hasInternalBranding }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  return (
    <>
      <Card className={classes.root}>
        <Typography className={classes.header} variant='subtitle1'>
          {t(helpMessages.contactHeader)}
        </Typography>
        <Grid container>
          <ContactForm hasInternalBranding={hasInternalBranding} />
          {!isMobile && <ContactTiles data={data} hasInternalBranding={hasInternalBranding} />}
        </Grid>
      </Card>

      {isMobile && <MobileContactTiles data={data} hasInternalBranding={hasInternalBranding} />}
    </>
  );
};

export default ContactCard;
