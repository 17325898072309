import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  filesList: {
    display: 'grid',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    display: 'grid',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr 40px',
    alignItems: 'center',
  },
  addButton: {
    display: 'grid',
    gap: theme.spacing(1),
  },
  file: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateColumns: '1fr auto',
  },
}));

export default useStyles;
