import type { FC, FunctionComponent, ReactElement, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import { alpha, ButtonProps, Dialog, DialogContent, DialogContentProps, DialogProps, DialogTitle, Grow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';

import DialogActionsResolver from './_components/DialogActionsResolver/DialogActionsResolver';
import useStyles from './DialogWrapper.styles';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) => <Grow ref={ref} {...props} />,
);

export const testId = createTestIdObject('DialogWrapper', {
  dialogHeader: 'dialogHeader',
});

export type ActionsConfig = (ButtonProps & {
  label: string;
  isLoading?: boolean;
  isLoadingType?: boolean;
})[];

export type DialogActionsProps = {
  customActions?: ReactElement;
  defaultActionsConfig?: {
    cancelLabel: string;
    okLabel: string;
    isLoading: boolean;
    isDisabled?: boolean;
    isOkDisabled?: boolean;
    formId?: string;
    onClose?: () => void;
  };
  actionsConfig?: ActionsConfig;
  actionsDescription?: string;
};

export type Props = {
  header: string;
  children: ReactNode;
  isOpen: boolean;
  close: () => void;
  subheader?: string;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogContentProps?: DialogContentProps;
  icon?: FunctionComponent;
  noMarginTop?: boolean;
} & DialogActionsProps;

const DialogWrapper: FC<Props> = ({
  isOpen,
  header,
  children,
  customActions,
  subheader,
  dialogProps = {},
  dialogContentProps = {},
  icon: Icon,
  noMarginTop = false,
  defaultActionsConfig,
  actionsConfig,
  actionsDescription,
}) => {
  const theme = useTheme();

  const { classes, cx } = useStyles({ hasSubheader: !!subheader, noMarginTop });
  const mergedDialogProps = {
    ...dialogProps,
    classes: { paper: dialogProps?.classes?.paper ? cx(dialogProps.classes.paper, classes.paper) : classes.paper },
  };

  return (
    <Dialog
      BackdropProps={{ style: { backgroundColor: alpha(theme.palette.secondary.light, 0.35) } }}
      fullWidth
      maxWidth='md'
      open={isOpen}
      scroll='body'
      TransitionComponent={Transition}
      {...mergedDialogProps}
    >
      <div>
        <DialogTitle className={classes.dialogTitle}>
          {!!Icon && (
            <div className={classes.iconWrapper}>
              <Icon />
            </div>
          )}
          <div>
            <Typography className={classes.dialogTitleText} component='div' data-testid={testId.dialogHeader} variant='h2'>
              {header}
            </Typography>
            {subheader && (
              <Typography component='div' data-testid='subheader' variant='caption'>
                {subheader}
              </Typography>
            )}
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} {...dialogContentProps}>
          {children}
        </DialogContent>
        <DialogActionsResolver
          actionsConfig={actionsConfig}
          actionsDescription={actionsDescription}
          customActions={customActions}
          defaultActionsConfig={defaultActionsConfig}
        />
      </div>
    </Dialog>
  );
};

export default DialogWrapper;
