import { set } from 'date-fns';

import { ECareSuspension, ECareSuspensionDTO } from './eCareSuspension.types';

export const parseSingleECareSuspensionForFE = (eCareSuspensionDTO: ECareSuspensionDTO): ECareSuspension => ({
  id: eCareSuspensionDTO.id,
  createdAt: new Date(eCareSuspensionDTO.created_at),
  updatedAt: new Date(eCareSuspensionDTO.updated_at),
  dateFrom: set(new Date(eCareSuspensionDTO.date_from), { hours: 0 }),
  dateTo: set(new Date(eCareSuspensionDTO.date_to), { hours: 23, minutes: 59 }),
  reason: eCareSuspensionDTO.reason,
  order: eCareSuspensionDTO.order,
});

export const parseECareSuspencionListForFE = (eCareSuspensionDTO: ECareSuspensionDTO[]): ECareSuspension[] =>
  eCareSuspensionDTO.map(parseSingleECareSuspensionForFE);
