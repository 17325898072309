import { makeStyles } from 'tss-react/mui';

import {
  DATE_RANGE_AREA,
  DATE_SOON_AREA,
  ITEM_NAME_AREA,
  ORDER_REFERRAL_AREA,
  PAYER_AREA,
  REFERRAL_AREA,
} from '../../_constants/gridAreas';

const useStyles = makeStyles<{ isCumulative: boolean }>()((theme, { isCumulative }) => {
  const gridAreasDesktopRegular = `
    "${ITEM_NAME_AREA} ${DATE_SOON_AREA} ${DATE_RANGE_AREA} ${DATE_RANGE_AREA}"
    "${ITEM_NAME_AREA} ${PAYER_AREA} ${REFERRAL_AREA} ${ORDER_REFERRAL_AREA}"
  `;
  const gridAreasDesktopCumulative = `
    "${ITEM_NAME_AREA} ${PAYER_AREA} ${REFERRAL_AREA} ${ORDER_REFERRAL_AREA}"
  `;
  const gridAreasMobileRegular = `
    "${ITEM_NAME_AREA} ${PAYER_AREA}"
    "${DATE_SOON_AREA} ${DATE_SOON_AREA}"
    "${DATE_RANGE_AREA} ${DATE_RANGE_AREA}"
    "${REFERRAL_AREA} ${ORDER_REFERRAL_AREA}"
  `;
  const gridAreasMobileCumulative = `
    "${ITEM_NAME_AREA} ${PAYER_AREA}"
    "${REFERRAL_AREA} ${ORDER_REFERRAL_AREA}"
  `;
  const gridAreasXSMobileRegular = `
    "${ITEM_NAME_AREA} ${ITEM_NAME_AREA}"
    "${PAYER_AREA} ${PAYER_AREA}"
    "${DATE_SOON_AREA} ${DATE_SOON_AREA}"
    "${DATE_RANGE_AREA} ${DATE_RANGE_AREA}"
    "${REFERRAL_AREA} ${REFERRAL_AREA}"
    "${ORDER_REFERRAL_AREA} ${ORDER_REFERRAL_AREA}"
  `;
  const gridAreasXSMobileCumulative = `
    "${ITEM_NAME_AREA} ${ITEM_NAME_AREA}"
    "${PAYER_AREA} ${PAYER_AREA}"
    "${REFERRAL_AREA} ${REFERRAL_AREA}"
    "${ORDER_REFERRAL_AREA} ${ORDER_REFERRAL_AREA}"
  `;

  return {
    root: {
      display: 'grid',
      gridGap: theme.spacing(4, 2),
      gridTemplateColumns: '1.25fr 1.75fr 1fr 1fr',
      gridTemplateAreas: isCumulative ? gridAreasDesktopCumulative : gridAreasDesktopRegular,

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: isCumulative ? gridAreasMobileCumulative : gridAreasMobileRegular,
      },

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: isCumulative ? gridAreasXSMobileCumulative : gridAreasXSMobileRegular,
      },
    },

    itemName: {
      gridArea: ITEM_NAME_AREA,
      wordBreak: 'break-word',
      '& > *': {
        fontSize: '0.9286rem',
        paddingTop: theme.spacing(2),
      },

      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(1),
      },
    },
    payer: {
      gridArea: PAYER_AREA,
      display: 'flex',
      justifySelf: 'flex-end',
      alignItems: 'center',
      minWidth: '120px',

      [theme.breakpoints.down('sm')]: {
        justifySelf: 'flex-start',
      },
    },
  };
});

export default useStyles;
