import { useMemo, type FC } from 'react';

import { Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { Basket } from 'constants/_types/Basket';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { useOnServicesOrder, useProfileSave } from './OrderServiceBasketActions.logic';
import useStyles from './OrderServiceBasketActions.styles';

export const testId = createTestIdObject('OrderServiceBasketActions', {
  buttonsSection: 'buttonsSection',
  buttonOrder: 'buttonOrder',
  buttonSaveProfile: 'buttonSaveProfile',
});

export type Props = {
  basket: Basket | null;
};

const OrderServiceBasketActions: FC<Props> = ({ basket }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { onServicesOrder } = useOnServicesOrder();
  const { profileSave } = useProfileSave();

  const handleSaveProfileClick = () => profileSave(basket);

  const shouldDisableButtons = useMemo(() => !basket || !Object.keys(basket).length, [basket]);

  return (
    <div className={classes.buttonsContainer}>
      <Divider />
      <div className={classes.buttons} data-testid={testId.buttonsSection}>
        <Button
          data-testid={testId.buttonSaveProfile}
          disabled={shouldDisableButtons}
          size='small'
          variant='outlined'
          onClick={handleSaveProfileClick}
        >
          {t(orderServiceMessages.basket.saveAsProfile)}
        </Button>
        <Button
          color='primary'
          data-testid={testId.buttonOrder}
          disabled={shouldDisableButtons}
          size='small'
          variant='contained'
          onClick={onServicesOrder}
        >
          {t(orderServiceMessages.basket.order)}
        </Button>
      </div>
    </div>
  );
};

export default OrderServiceBasketActions;
