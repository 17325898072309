import { FC } from 'react';

import ECareCusComments from 'components/UI/molecules/ECareCusComments/ECareCusComments';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';

import ECareInternalNotesComponent from '../ECareInternalNotes/ECareInternalNotes';
import useStyles from './ProfessionalECareCommentsAndNotes.styles';

type Props = {
  orderId: string;
  recipientId: string;
};

const ProfessionalECareCommentsAndNotes: FC<Props> = ({ orderId, recipientId }) => {
  const { classes } = useStyles();

  return (
    <CardContentContainer className={classes.cardContainer} isLastSection>
      <div className={classes.notesWrapper}>
        {/* <BitrixNotes orderId={orderId} /> */}
        <ECareInternalNotesComponent orderId={orderId} />
        <ECareCusComments recipientId={recipientId} />
      </div>
    </CardContentContainer>
  );
};

export default ProfessionalECareCommentsAndNotes;
