import type { FC, ReactNode } from 'react';

import type { FormState } from 'react-hook-form';

import FormGlobalError from 'components/UI/organisms/FormGlobalError/FormGlobalError';
import useFormScrollToError from 'hooks/useFormScrollToError/useFormScrollToError';

import useStyles from './DialogFormWrapper.styles';

export type Props = {
  id?: string;
  children: ReactNode;
  onSubmit?: () => void;
  globalErrors?: string[];
  formState?: FormState<any>;
};

const DialogFormWrapper: FC<Props> = ({ id, children, onSubmit, globalErrors, formState }) => {
  const { classes } = useStyles();
  useFormScrollToError(formState);

  return (
    <form className={classes.root} id={id} onSubmit={onSubmit}>
      <FormGlobalError errors={globalErrors} />
      {children}
    </form>
  );
};

export default DialogFormWrapper;
