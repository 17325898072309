import type { FC } from 'react';

import { useTheme } from '@mui/material/styles';

import { resolveProbabilityColor } from 'services/_dictionaryResolvers/resolveProbability/resolveProbability';

import useStyles from './ProbabilityBar.styles';

export type Props = {
  probability: number;
};

const ProbabilityBar: FC<Props> = ({ probability }) => {
  const theme = useTheme();
  const { classes } = useStyles({ width: probability * 100, color: resolveProbabilityColor(theme, probability) });
  return (
    <div className={classes.root}>
      <span className={classes.fill} />
    </div>
  );
};

export default ProbabilityBar;
