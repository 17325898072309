import type { FC } from 'react';

import type { ButtonProps, SvgIconTypeMap } from '@mui/material';
import { Button } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import useStyles from './MobileFixedActionIconButton.styles';

export interface Props {
  // eslint-disable-next-line
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  handleClick: () => void;
  buttonProps?: ButtonProps;
}

const MobileFixedActionIconButton: FC<Props> = ({ icon: Icon, handleClick, buttonProps = {} }) => {
  const { classes } = useStyles();

  return (
    <Button className={classes.root} size='small' variant='contained' onClick={handleClick} {...buttonProps}>
      <Icon fontSize='large' />
    </Button>
  );
};

export default MobileFixedActionIconButton;
