import { useMemo } from 'react';

import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { useBrandContext } from 'components/context/BrandContext/BrandContext';

export const useInitialValue = () => {
  const { brandInfo } = useBrandContext();
  const { userInfo } = useAuthContext();

  const isEnforcedByBranding = useMemo(() => brandInfo?.config.force_2fa ?? false, [brandInfo]);
  const initialValue = useMemo(() => isEnforcedByBranding || userInfo.uses2fa, [isEnforcedByBranding, userInfo]);

  return { isEnforcedByBranding, initialValue };
};
