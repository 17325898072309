import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { parseReferralNeededForFE } from '../parseReferralNeededForFE/parseReferralNeededForFE';
import { endpoint } from '../referralNeeded';
import type { ReferralNeeded, ReferralNeededDTO } from '../types';

interface Params {
  serviceItemId: number;
  currentPayerId: string;
}

type GetReferralNeededQuery = () => Promise<ReferralNeeded>;

export const getReferralNeeded =
  ({ serviceItemId, currentPayerId }: Params): GetReferralNeededQuery =>
  async () => {
    const { data } = await axios.get<GetReferralNeededQuery, DefaultApiResponse<ReferralNeededDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: {
          core_service_id: serviceItemId,
          payer_id: currentPayerId,
        },
      }),
    );

    return parseReferralNeededForFE(data);
  };
