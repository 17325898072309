const servicesMessages = {
    pageTitle: ['services>>pageTitle', 'Usługi'],
    tableTitles: {
        general: ['services>>tableTitles>>general', 'Katalog wizyt i badań'],
        profiles: ['services>>tableTitles>>profiles', 'Moje profile'],
    },
    addToRealizationBasket: ['services>>addToRealizationBasket', 'Dodaj zaznaczone do realizacji'],
    noResultsForPaymentType: ['services>>noResultsForPaymentType', 'Dla podanych typów płatności nie ma dostępnych usług'],
    noResultsForProfileSearch: ['services>>noResultsForProfileSearch', 'Brak profili'],
    saveServicesProfileDialog: {
        title: ['services>>saveServicesProfileDialog>>title', 'Zapisz nowy profil zamówień'],
        inputLabel: ['services>>saveServicesProfileDialog>>inputLabel', 'Nazwa profilu'],
        okLabel: ['services>>saveServicesProfileDialog>>okLabel', 'Zapisz'],
        cancelLabel: ['services>>saveServicesProfileDialog>>cancelLabel', 'Anuluj'],
        profileSavedMessage: ['services>>saveServicesProfileDialog>>profileSavedMessage', 'Profil poprawnie zapisany'],
    },
    search: {
        placeholder: ['services>>search>>placeholder', 'Wyszukaj wizyty lub badania'],
    },
    removeServicesProfileDialog: {
        confirmationDialogTitle: ['services>>removeServicesProfileDialog>>confirmationDialogTitle', 'Usuń profil'],
        confirmationDialogBody: ['services>>removeServicesProfileDialog>>confirmationDialogBody', 'Czy na pewno chcesz usunąć profil? Tej akcji nie da się cofnąć.'],
        servicesProfileRemoved: ['services>>removeServicesProfileDialog>>servicesProfileRemoved', 'Profil pomyślnie usunięty']
    },
    servicesListInPolicy: ['services>>servicesListInPolicy', 'Lista usług w polisie'],
    servicesListInSubscription: ['services>>servicesListInSubscription', 'Lista usług w abonamencie'],
}

export default servicesMessages;
