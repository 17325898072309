import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import { PartialRecord } from 'constants/_types/PartialRecord';
import { TableDataBaseParamsForQuery } from 'constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import { ECareTechnicalIssuesListFilters } from '../eCareTechnicalIssues/eCareTechnicalIssues.types';
import { parseECareDevicesListForFE, parseSingleECareDevicesForFE } from './eCareDevices.parsers';
import { ECareDevice, ECareDeviceDTO, ECareDeviceListFilters, ECareDeviceUnassigned } from './eCareDevices.types';

const endpoint = '/api/v1/ecare-device/';

export type UpdateECareDeviceInput = ECareDeviceUnassigned;

type GetECareAllDevicesQuery = (
  params: TableDataQueryFunctionParams<keyof ECareDevice>,
) => Promise<TableDataQueryResult<keyof ECareDevice>>;

type GetECareDeviceQuery = () => Promise<ECareDevice>;
type UpdateECareDeviceQuery = (data: UpdateECareDeviceInput) => Promise<DefaultApiResponse<ECareDevice>>;

const eCareDevices = {
  getAllDevices: (): GetECareAllDevicesQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery & PartialRecord<ECareDeviceListFilters, string> = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      ...parseFiltersForBE<ECareTechnicalIssuesListFilters>(params.filters),
    };

    const { data } = await axios.get<GetECareAllDevicesQuery, PaginatedApiResponse<ECareDeviceDTO>>(
      createUrlWithParams({
        url: endpoint,
        params: paramsForBE,
        removeEmptyParams: true,
      }),
    );
    return { data: parseECareDevicesListForFE(data.results), count: data.count };
  },

  getDevice:
    (deviceId: string): GetECareDeviceQuery =>
    async () => {
      const { data } = await axios.get<GetECareDeviceQuery, DefaultApiResponse<ECareDeviceDTO>>(`${endpoint}${deviceId}/`);

      return parseSingleECareDevicesForFE(data);
    },

  patchDeviceData:
    (id: string): UpdateECareDeviceQuery =>
    data =>
      axios.patch<UpdateECareDeviceQuery, DefaultApiResponse<ECareDevice>>(`${endpoint}${id}/`, data),
};
export default eCareDevices;
