import type { Basket, BasketItem } from 'constants/_types/Basket';
import type { OrderServiceCoreServicesKinds } from 'constants/_types/OrderServiceCoreServicesKinds';

interface Params {
  kind: OrderServiceCoreServicesKinds;
  kindItems: BasketItem[];
  basket: Basket;
}

export const removeEmptyKindSection = ({ kind, kindItems, basket }: Params) => {
  const isEmpty = !kindItems.length;

  const basketCopy = { ...basket };
  if (isEmpty) {
    Object.entries(basket).forEach(([key]) => {
      if (key === kind) delete basketCopy[key];
    });
  }

  return basketCopy;
};
