const ICON_SIZES_FOR_MAIN_MENU = {
  desktop: {
    open: 25,
    closed: 23,
  },
  mobile: {
    default: 20,
  },
};

export default ICON_SIZES_FOR_MAIN_MENU;
