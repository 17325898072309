import { makeStyles } from 'tss-react/mui';

import { REFERRAL_AREA } from '../../_constants/gridAreas';

const useStyles = makeStyles()(theme => ({
  referral: {
    gridArea: REFERRAL_AREA,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export default useStyles;
