import type { FC } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { IconAlertProps } from 'constants/_types/IconAlertProps';

import useStyles from './IconSuccessAlert.styles';

const IconSuccessAlert: FC<IconAlertProps> = ({ isBoxShadow = false }) => {
  const { classes } = useStyles({ isBoxShadow });

  return (
    <div className={classes.root}>
      <CheckCircleOutlineIcon fontSize='small' />
    </div>
  );
};

export default IconSuccessAlert;
