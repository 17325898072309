export type SupportedLanguages = 'pl' | 'es' | 'en';

export const SUPPORTED_LANGUAGES: { [key: string]: SupportedLanguages } = {
  PL: 'pl',
  ES: 'es',
  EN: 'en',
};

export type TriageValue = {
  pl: string;
  es: string;
  en: string;
};
