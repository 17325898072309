import type { FC } from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CellRendererProps } from 'components/UI/molecules/ColumnsDisplaySet/_components/ColumnsDisplaySetRows/ColumnsDisplaySetRows';

import useStyles from './ServicesReviewCellTile.styles';

export type Props = { isSearchActive: boolean; checkIsMatched: (id: number) => boolean } & CellRendererProps;

const ServicesReviewCellTile: FC<Props> = ({
  isSearchActive,
  checkIsMatched,
  entity,
  config: { headerId, cellSize, columnsNumber, pathToGenerator },
}) => {
  const { t } = useTranslation();
  const { name, id } = entity || { name: {} };

  const { classes } = useStyles({ cellSize, columnsNumber, isSearchActive, isMatched: checkIsMatched(entity?.id) });

  if (!pathToGenerator) throw Error('pathToGenerator function is required');

  if (!entity) return <span data-testid='ServicesReviewCellTile-placeholder' />;

  return (
    <ButtonBase className={classes.tdTile} component={Link} to={pathToGenerator({ headerId, entityId: id })}>
      <Typography className={classes.tdLabel} component='span'>
        {t(name.key, name.defaultValue) || name.defaultValue}
      </Typography>
    </ButtonBase>
  );
};

export default ServicesReviewCellTile;
