import { makeStyles } from 'tss-react/mui';

const PERSONAL_SECTION = 'personalSection';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: 'block',
    padding: theme.spacing(2),
  },
  bottomActionBar: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    textAlign: 'right',
  },
  value: {
    marginTop: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  noPaddingX: {
    padding: '5px 0',
  },
  standardColumnWrapper: {
    display: 'grid',
    alignContent: 'flex-start',
  },
  container: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(3, 4),
    alignContent: 'flex-start',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  personalSection: {
    gridArea: PERSONAL_SECTION,
    gridGap: 10,
  },
  patientButton: {
    justifySelf: 'flex-start',
  },
  section: {
    gap: 0,
  },
  notesWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: 20,

    '> section': {
      flex: 1,
    },
  },
}));

export default useStyles;
