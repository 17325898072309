import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  isLoading: boolean;
  hasFullHeight: boolean;
  minHeight?: number;
  inheritDisplayType?: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, { isLoading, hasFullHeight, minHeight, inheritDisplayType }) => ({
  root: {
    position: 'relative',
    display: inheritDisplayType ? 'contents' : 'initial',
  },

  fullHeight: {
    height: hasFullHeight ? '100%' : 'inherit',
  },

  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    minHeight: minHeight || 0,
    top: 0,
    background: theme.palette.background.default,
    display: isLoading ? 'block' : 'none',
  },
}));

export default useStyles;
