import type { FC } from 'react';

import useStyles from './IconWithBackground.styles';

export interface Props {
  backgroundColor: string;
  icon: JSX.Element;
}

const IconWithBackground: FC<Props> = ({ backgroundColor, icon }) => {
  const { classes } = useStyles({ backgroundColor });

  return <div className={classes.iconBackground}>{icon}</div>;
};

export default IconWithBackground;
