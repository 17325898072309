import { FC } from 'react';

import { ProfessionalECareCusCommentsEntity } from 'api/eCareCusComments/eCareCusComments.types';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';

import ECareCusCommentsHistory from '../ECareCusCommentsHistory/ECareCusCommentsHistory';

type Props = {
  data: ProfessionalECareCusCommentsEntity[];
};

const CusCommentList: FC<Props> = ({ data }) => {
  const lastComment = data && data.length > 0 ? data[0] : undefined;

  const cusCommentList = [
    {
      label: '',
      value: lastComment && <ECareCusCommentsHistory comment={lastComment} />,
    },
  ];

  return <DefinitionList columns={1} definitions={cusCommentList} />;
};

export default CusCommentList;
