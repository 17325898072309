import type { FC, ReactNode } from 'react';

import { ButtonBase, useTheme } from '@mui/material';

import Chevron from 'components/UI/atoms/Chevron/Chevron';
import AppTopBar from 'components/UI/organisms/AppTopBar/AppTopBar';
import MainMenuDesktop from 'components/UI/organisms/MainMenuDesktop/MainMenuDesktop';
import MainMenuMobile from 'components/UI/organisms/MainMenuMobile/MainMenuMobile';
import { DIRECTIONS } from 'constants/_types/Direction';
import { useIsMobile } from 'hooks/_breakpoints/useIsMobile/useIsMobile';
import useLayoutStorage from 'storages/layoutStorage';

import useStyles from './LayoutAuthorized.styles';

export type Props = {
  children: ReactNode;
};

const LayoutAuthorized: FC<Props> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const { isOpen, toggleOpen } = useLayoutStorage();
  const { classes } = useStyles({ isDrawerOpened: isOpen });

  return (
    <div className={classes.root}>
      <AppTopBar isMobile={isMobile} />
      {isMobile ? <MainMenuMobile /> : <MainMenuDesktop />}
      <div className={classes.content}>{children}</div>
      {!isMobile && (
        <ButtonBase className={classes.toggleButton} disableRipple onClick={toggleOpen}>
          <Chevron
            color={theme.palette.grey[500]}
            direction={{ base: DIRECTIONS.left, alternate: DIRECTIONS.right }}
            isAlternated={!isOpen}
          />
        </ButtonBase>
      )}
    </div>
  );
};

export default LayoutAuthorized;
