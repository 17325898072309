import axios from 'axios';

import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { DefaultApiResponse, PaginatedApiResponse } from '../_types';
import { parseECareSosInternalNoteForFE } from './eCareSosPcInternalNotes.parsers';
import {
  CreateECareSosInternalNoteInput,
  ECareSosInternalNote,
  ECareSosInternalNoteDTO,
  UpdateECareSosInternalNoteInput,
} from './eCareSosPcInternalNotes.types';

type GetECareAllContactNotesQuery = () => Promise<TableDataQueryResult<keyof ECareSosInternalNote>>;
type CreateContactNoteQuery = (data: CreateECareSosInternalNoteInput) => Promise<DefaultApiResponse<ECareSosInternalNote>>;
type UpdateECareContactNotesQuery = (data: UpdateECareSosInternalNoteInput) => Promise<DefaultApiResponse<ECareSosInternalNote>>;

const endpoint = '/api/v1/sos-pc-internal-note/';

const eCareSosPcInternalNotes = {
  getAllNotes:
    (alertId: string): GetECareAllContactNotesQuery =>
    async () => {
      const { data } = await axios.get<GetECareAllContactNotesQuery, PaginatedApiResponse<ECareSosInternalNoteDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: { object_id: alertId || '' },
          removeEmptyParams: true,
        }),
      );
      return { data: parseECareSosInternalNoteForFE(data.results), count: data.count };
    },

  createNote: (): CreateContactNoteQuery => data =>
    axios.post<CreateContactNoteQuery, DefaultApiResponse<ECareSosInternalNote>>(endpoint, data),

  patchNoteData:
    (id: string): UpdateECareContactNotesQuery =>
    data =>
      axios.patch<UpdateECareContactNotesQuery, DefaultApiResponse<ECareSosInternalNote>>(`${endpoint}${id}/`, data),
};
export default eCareSosPcInternalNotes;
