import type { TFunction } from 'i18next';

import { isInvalid } from 'services/_formInput/isInvalid/isInvalid';
import validationMessages from 'translations/common/validation.mjs';

interface Params {
  currentValue: string | undefined;
  t: TFunction;
}

export const validateCategoryDropdown = ({ currentValue, t }: Params) => {
  if (isInvalid(currentValue)) return t(validationMessages.required_field);
  return true;
};
