import { useMemo, type FC } from 'react';

import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import PatientClausesNotRequired from 'components/UI/molecules/PatientClausesNotRequired/PatientClausesNotRequired';
import PatientClausesRequired from 'components/UI/molecules/PatientClausesRequired/PatientClausesRequired';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import type { ClauseWithConsent } from 'constants/_types/ClauseWithConsent';
import { getClauseLocationsValues } from 'helpers/getClauseLocationsValues/getClauseLocationsValues';
import useClausesConsentsQuery from 'hooks/_clauses/useClausesConsentsQuery/useClausesConsentsQuery';
import groupClausesWithConsents from 'services/_clauses/groupClausesWithConsents/groupClausesWithConsents';
import { removeDuplicates } from 'services/removeDuplicates/removeDuplicates';

import { compareClauses } from './_services/compareClauses/compareClauses';

const PatientClauses: FC = () => {
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();

  const openClausesDialog = () => {
    addToGlobalDialogQueue({
      type: GlobalDialogs.clausesConsentsManual,
    });
  };

  const allLocations = useMemo(() => getClauseLocationsValues(), []);

  const { clausesWithConsents, queryStatuses } = useClausesConsentsQuery(allLocations, { onlySingle: true });
  const { requiredWithConsent: requiredWithConsentWithDuplicates } = useMemo(
    () =>
      groupClausesWithConsents(clausesWithConsents, {
        requiredWithConsent: ({ isObligatory, hasUserConsent }) => isObligatory && !!hasUserConsent,
      }),
    [clausesWithConsents],
  );
  const requiredWithConsent = useMemo(
    () => removeDuplicates<ClauseWithConsent>(requiredWithConsentWithDuplicates, compareClauses),
    [requiredWithConsentWithDuplicates],
  );

  return (
    <>
      <CardContentContainer isLoading={queryStatuses.all.isLoading}>
        <PatientClausesRequired clausesRequired={requiredWithConsent} />
      </CardContentContainer>
      <CardContentContainer isLastSection isLoading={queryStatuses.all.isLoading}>
        <PatientClausesNotRequired openClausesDialog={openClausesDialog} />
      </CardContentContainer>
    </>
  );
};

export default PatientClauses;
