import type { FC } from 'react';

import { Typography } from '@mui/material';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import useStyles from './ReferralOrder.styles';

export interface Props {
  form: UseFormReturn<OrderServiceFormInput, any>;
  formInputName: string;
  validationRules: { validate: () => string | true };
}

const ReferralOrder: FC<Props> = ({ form, formInputName, validationRules }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.orderReferral}>
      <FormInputCheckbox
        control={form.control}
        label={
          <Typography color='secondary' variant='caption'>
            {t(orderServiceMessages.details.referralOrder)}
          </Typography>
        }
        name={formInputName}
        rules={validationRules}
        tiny
      />
    </div>
  );
};

export default ReferralOrder;
