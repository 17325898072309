import parseDynamicTranslatableName from 'services/parseDynamicTranslatableName/parseDynamicTranslatableName';

import type { PatientPolicy, PatientPolicyDTO } from '../patientPolicies.types';

export const parsePatientPolicyForFE = (patientPolicyDTO: PatientPolicyDTO[]): PatientPolicy[] =>
  patientPolicyDTO.map(entity => ({
    validFrom: new Date(entity.valid_from),
    validUntil: new Date(entity.valid_until),
    policyNumber: entity.policy_number,
    payerId: entity.payer_id,
    payerImg: { logoRight: entity.payer_img.logo_right, logoLeft: entity.payer_img.logo_left },
    generalConditions: entity?.general_conditions || null,
    name: parseDynamicTranslatableName(entity.name),
    type: entity.type,
    policyId: entity.policy,
  }));
