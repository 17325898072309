import { FC, useMemo } from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import { ECareAlarm } from 'api/eCareAlarms/eCareAlarms.types';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import DefinitionList from 'components/UI/molecules/DefinitionList/DefinitionList';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputText from 'components/UI/organisms/_formInputs/FormInputText/FormInputText';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import useDropdownList from './_hooks/useDropdownlist/useDropdownlist';
import useStyles from './InterventionData.styles';

type FormInput = {
  description: string;
};

type Props = {
  alarmId: string;
  eCareAlarmsData: ECareAlarm;
  isValid: boolean;
};

const InterventionData: FC<Props> = ({ alarmId, eCareAlarmsData, isValid }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showConfirmationDialog } = useConfirmationDialogContext();

  const isClosed = useMemo(() => eCareAlarmsData.sosStatus === 'CLOSED', [eCareAlarmsData.sosStatus]);

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ALARMS, alarmId], [alarmId]);

  const statusMutation = useMutation(eCareAlarms.patchECareAlarm(alarmId), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });

  const { control, getValues, reset } = useForm<FormInput>();

  const addNewNote = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.interventionAlarmDescription),
      body: <FormInputText control={control} label='' multiline name='description' rows={6} />,
      buttonLabels: { onTrue: t(generalMessages.save), onFalse: t(generalMessages.cancel) },
    });
    if (confirmed) {
      await statusMutation.mutateAsync({
        description: getValues('description'),
      });
    }

    // Reset form
    reset();
  };

  /* This is dialog with alarm description */
  const showNotesDialog = async () => {
    await showConfirmationDialog({
      title: t(proECareAlarmMessages.alarmHistoryTitle),
      body: eCareAlarmsData?.description,
      infoOnly: true,
      buttonLabels: { onTrue: t(generalMessages.save) },
    });
  };

  const dropdownList = useDropdownList({ alarmId, eCareAlarmsData, isValid, isClosed });

  const tooltipText = t(proECareAlarmMessages.tooltip.addAlarmMessage);
  return (
    <CardContentContainer className={classes.cardContainer}>
      <SectionWithTitle
        className={classes.section}
        title={t(proECareAlarmMessages.interventionDate)}
        titleIcon={
          <>
            <Button disabled={isClosed} variant='contained' onClick={addNewNote}>
              {t(proECareAlarmMessages.alarmCard.buttons.alarmDescription)}
            </Button>
            <InfoIconWithTooltip content={tooltipText} />
          </>
        }
        titleRightSideComponent={
          <Button variant='outlined' onClick={showNotesDialog}>
            {t(proECareAlarmMessages.alarmDescriptionButton)}
          </Button>
        }
      >
        <DefinitionList
          columns={4}
          customClasses={{
            root: classes.definitionListRoot,
            listItemClass: classes.noPadding,
          }}
          definitions={dropdownList}
        />
      </SectionWithTitle>
    </CardContentContainer>
  );
};

export default InterventionData;
