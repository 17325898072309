import type { Basket } from 'constants/_types/Basket';
import type { SelectedServiceItemMetaData } from 'constants/_types/SelectedServiceItemMetaData';

import { removeEmptyKindSection } from '../removeEmptyKindSection/removeEmptyKindSection';

export const removeItemFromBasket = (basket: Basket | null, metaData: SelectedServiceItemMetaData) => {
  if (!basket) return null;

  const prevSelectedKindItems = basket[metaData.coreServiceKind]?.items || [];
  const updatedSelectedKindItems = prevSelectedKindItems.filter(item => `${item.id}` !== metaData.id);
  const updatedBasket = {
    ...basket,
    [metaData.coreServiceKind]: {
      ...basket[metaData.coreServiceKind],
      items: updatedSelectedKindItems,
    },
  };

  const modifiedBasket = removeEmptyKindSection({
    kind: metaData.coreServiceKind,
    kindItems: updatedSelectedKindItems,
    basket: updatedBasket,
  });

  const shouldSetInitialState = !Object.entries(modifiedBasket).length;

  return !shouldSetInitialState ? modifiedBasket : null;
};
