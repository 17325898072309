import type { FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LinkWithQuery from 'components/UI/atoms/LinkWithQuery/LinkWithQuery';
import SimpleFormWrapper from 'components/UI/molecules/SimpleFormWrapper/SimpleFormWrapper';
import PATHS from 'constants/router/PATHS';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import generalMessages from 'translations/common/general.mjs';
import resetPasswordMessages from 'translations/specific/resetPassword.mjs';

const ResetPasswordFormSuccessPage: FC = () => {
  usePageConfig();

  const { t } = useTranslation();
  return (
    <SimpleFormWrapper>
      <Typography align='center'>{t(resetPasswordMessages.reset_form_successful)}</Typography>
      <Button component={LinkWithQuery} to={`/${PATHS.AUTH_LOGIN}`} variant='contained'>
        {t(generalMessages.ok)}
      </Button>
    </SimpleFormWrapper>
  );
};

export default ResetPasswordFormSuccessPage;
