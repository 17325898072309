export type ImageReducerState = {
  src: string | null;
  hasSavedImage: boolean;
  imageUploaded: boolean;
};

type ImageReducerAction =
  | { type: 'updateSrc'; src: string }
  | { type: 'updateSrcByUser'; src: string }
  | { type: 'clearSrc' }
  | { type: 'toggleHasSavedImage' };

export const initialImageState = {
  src: null,
  hasSavedImage: false,
  imageUploaded: false,
};

export const imageReducer = (state: ImageReducerState, action: ImageReducerAction) => {
  switch (action.type) {
    case 'updateSrc':
      return { ...state, src: action.src };
    case 'updateSrcByUser':
      return { ...state, src: action.src, imageUploaded: true };
    case 'clearSrc':
      return { ...state, src: null, imageUploaded: false };
    case 'toggleHasSavedImage':
      return { ...state, hasSavedImage: true };
    default:
      throw new Error('Missing action type');
  }
};
