import { FC, useMemo } from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import eCareDevices from 'api/eCareDevices/eCareDevices';
import { ECareDevice } from 'api/eCareDevices/eCareDevices.types';
import DialogWrapper from 'components/UI/molecules/DialogWrapper/DialogWrapper';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import useTableData from 'hooks/useTableData/useTableData';
import generalMessages from 'translations/common/general.mjs';
import proECareTechnicalIssuesMessages from 'translations/specific/pro_ecare_technical_issues.mjs';
import proPatientMessages from 'translations/specific/pro_patient.mjs';

import PatientDevicesTypeTable from '../PatientDevicesTypeTable/PatientDevicesTypeTable';

export type Props = {
  close: () => void;
  isOpen: boolean;
  patientId: number | string;
  orderId?: number | string;
};

const PatientDevicesDialog: FC<Props> = ({ close, isOpen, patientId, orderId }) => {
  const { t } = useTranslation();
  const config = {
    pageSize: 5,
    initialFilters: [
      { key: 'patient', value: patientId },
      { key: 'order', value: orderId },
    ],
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<ECareDevice>();

    return [
      helper.accessor('name', {
        id: 'name',
        header: t(proECareTechnicalIssuesMessages.deviceElementLabel.name) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('dateFrom', {
        id: 'dateFrom',
        header: t(proECareTechnicalIssuesMessages.deviceElementLabel.dateFrom) as string,
        cell: info => (info.getValue() ? unknownDateToString(info.getValue(), DATE_FORMATS.DISPLAY) : t(generalMessages.noData)),
      }),
      helper.accessor('dateTo', {
        id: 'dateTo',
        header: t(proECareTechnicalIssuesMessages.deviceElementLabel.dateTo) as string,
        cell: info => (info.getValue() ? unknownDateToString(info.getValue(), DATE_FORMATS.DISPLAY) : t(generalMessages.noData)),
      }),
      helper.accessor('description', {
        id: 'description',
        header: t(proECareTechnicalIssuesMessages.deviceElementLabel.description) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('serialNumber', {
        id: 'serialNumber',
        header: t(proECareTechnicalIssuesMessages.deviceElementLabel.serialNumber) as string,
        cell: info => info.getValue(),
      }),
      helper.accessor('simNumber', {
        id: 'simNumber',
        header: t(proECareTechnicalIssuesMessages.deviceElementLabel.simNumber) as string,
        cell: info => info.getValue(),
      }),
    ];
  }, [t]);

  const { query, sort, onSort, page, onExactPage, pagesCount, isFetchingNewPage } = useTableData<keyof ECareDevice>({
    queryFunction: eCareDevices.getAllDevices(),
    queryKeyBase: QUERY_KEYS.GET_ECARE_DEVICES,
    config,
    queryOptions: { enabled: isOpen },
  });

  return (
    <DialogWrapper
      actionsConfig={[{ label: t(generalMessages.close), onClick: close, variant: 'contained' }]}
      close={close}
      header={t(proPatientMessages.showPatientDevicesDialog.title)}
      isOpen={isOpen}
      noMarginTop
    >
      {/* Generic Component throws error, but idk why, it should work */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CustomTable<ECareDevice>
        columns={columns}
        config={config}
        data={query.data?.data}
        page={page}
        pagesCount={pagesCount}
        showLoaderCover={isFetchingNewPage}
        sort={sort}
        onExactPage={onExactPage}
        onSort={onSort}
      />

      {orderId && (
        <Accordion>
          <AccordionSummary aria-controls='panel1-content' expandIcon={<ExpandMoreIcon />} id='panel1-header'>
            {t(proPatientMessages.showPatientDevicesDialog.availableDevices)}
          </AccordionSummary>
          <AccordionDetails>
            <PatientDevicesTypeTable orderId={orderId} />
          </AccordionDetails>
        </Accordion>
      )}
    </DialogWrapper>
  );
};

export default PatientDevicesDialog;
