import { useQuery } from 'react-query';

import userGroups from 'api/userGroups/userGroups';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';

/**
 * @return null when loading and bool when has data
 */
const useIsProfessionalUser = (): boolean | null => {
  const { userInfo } = useAuthContext();
  const { data } = useQuery(QUERY_KEYS.USER_GROUPS, userGroups.getUserGroups(), {
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 10,
    refetchOnWindowFocus: true,
    enabled: !!userInfo?.isAuthorized,
  });

  if (!data) return null;

  return !!data.length;
};

export default useIsProfessionalUser;
