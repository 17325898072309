import { FC, useMemo, useState } from 'react';

import { Button, Card, List, ListItem, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams, Link, generatePath } from 'react-router-dom';

import eCareAlarms from 'api/eCareAlarms/eCareAlarms';
import { AlarmStatusT } from 'api/eCareAlarms/eCareAlarms.types';
import eCareSyncWithCus, { ECareSyncCusEndpoint } from 'api/eCareSyncWithCus/eCareSyncWithCus';
import { ReactComponent as ECareAlarmListIcon } from 'assets/icons/hub/active/bell.svg';
import { useConfirmationDialogContext } from 'components/context/ConfirmationDialogContext/ConfirmationDialogContext';
import ECareAlarmStatusDropdown from 'components/UI/atoms/ECareAlarmStatusDropdown/ECareAlarmStatusDropdown';
import ECareSyncWithCusButton from 'components/UI/molecules/ECareSyncWithCusButton/ECareSyncWithCusButton';
import LoaderOverlay from 'components/UI/molecules/LoaderOverlay/LoaderOverlay';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import Timer from 'components/UI/molecules/Timer/Timer';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import ProfessionalECareAlarmInfo from 'components/UI/organisms/ProfessionalECareAlarmInfo/ProfessionalECareAlarmInfo';
import ProfessionalECareAlarmPersonalData from 'components/UI/organisms/ProfessionalECareAlarmPersonalData/ProfessionalECareAlarmPersonalData';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';
import { useShowSnackbar } from 'hooks/useShowSnackbar/useShowSnackbar';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import generalMessages from 'translations/common/general.mjs';
import proECareAlarmMessages from 'translations/specific/pro_ecare_alarm.mjs';

import HeaderButtons from './_components/HeaderButtons/HeaderButtons';
import useStyles from './ProfessionalAlarmSinglePage.styles';

const ProfessionalAlarmSinglePage: FC = () => {
  usePageConfig();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { alarmId } = useParams();
  const { showConfirmationDialog } = useConfirmationDialogContext();
  const { showSnackbar } = useShowSnackbar();
  const [isValid, setIsValid] = useState(false);

  const endpointsToSync: ECareSyncCusEndpoint[] = ['sos'];

  const queryKey = useMemo(() => [QUERY_KEYS.GET_ECARE_ALARMS, alarmId], [alarmId]);
  const { data: alarmData, isLoading } = useQuery(queryKey, eCareAlarms.getECareAlarm(alarmId as string), {
    enabled: !!alarmId,
    refetchOnWindowFocus: false,
  });

  const subLabel = useMemo(() => {
    if (!alarmData) return <Skeleton width={120} />;

    const age = alarmData?.patient ? getAgeFromDOB(alarmData.patient.personalData.dateOfBirth) : '';
    return alarmData?.patient ? (
      <>
        <Link
          className={classes.titleLink}
          to={`/${generatePath(PATHS.PROFESSIONAL_PATIENT_SINGLE, { patientId: alarmData.patient.id.toString() })}`}
        >
          {alarmData.patient.personalData.firstName} {alarmData.patient.personalData.lastName}
        </Link>
        , {age} {t(generalMessages.person.age)}
      </>
    ) : (
      t(generalMessages.noData)
    );
  }, [alarmData]);

  const colorResolver = (status: AlarmStatusT) => {
    switch (status) {
      case 'RECEIVED':
        return classes.received;
      case 'MISSED':
        return classes.missed;
      case 'COMPLETE_DOCUMENTATION':
        return classes.completeDocumentation;
      case 'CLOSED':
        return classes.closed;
      default:
        return exhaustiveGuard(status);
    }
  };

  const timerCountingHandler = (status: AlarmStatusT): boolean => ['RECEIVED', 'MISSED'].includes(status);
  const pageTitle = {
    headerLabel: t(proECareAlarmMessages.alarmDetails.title),
    subLabel,
    extraContentComponent: (alarmData?.patient && <HeaderButtons patientId={alarmData.patient.id} />) || <div />,
    icon: <ECareAlarmListIcon />,
    extraAfterIconComponent: (
      <>
        {!alarmData && <Skeleton height={48} width={200} />}
        {alarmData && (
          <Timer
            date={alarmData.createdAt}
            isInterval={timerCountingHandler(alarmData.sosStatus)}
            labelElement={<ECareAlarmStatusDropdown alarmId={alarmData.id} queryKey={queryKey} value={alarmData.sosStatus} />}
            statusColorClass={colorResolver(alarmData.sosStatus)}
            stopDate={alarmData.updatedAt}
          />
        )}
      </>
    ),
  };

  const queryClient = useQueryClient();
  const statusMutation = useMutation(eCareAlarms.patchECareAlarm(alarmId as string), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
    },
  });
  const statusSyncMutation = useMutation(eCareSyncWithCus.syncWithCus(alarmId as string, ['sos']), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async data => {
      if (!data?.success) {
        const { errorMessage } = data?.error as { errorMessage: string };
        showSnackbar({
          variant: 'error',
          translationArray: [errorMessage],
        });
      } else {
        statusMutation.mutate({
          sos_status: 'CLOSED',
        });
      }
    },
  });

  const isValidToCloseIntervention = useMemo(
    () => !!(alarmData && alarmData.sosType && alarmData.sosReason && alarmData.interventionType && alarmData.interventionEffect),
    [alarmData],
  );

  const closeIntervention = async () => {
    const confirmed = await showConfirmationDialog({
      title: t(proECareAlarmMessages.alarmCard.buttons.endIntervention),
      body: (
        <>
          <p>{t(proECareAlarmMessages.confirmationDialog.closeIntervention.closeInterventionDialogDescription)}</p>
          <p>{t(proECareAlarmMessages.confirmationDialog.closeIntervention.cusSync)}</p>
          {!isValidToCloseIntervention && (
            <List>
              Przed zakończeniem interwencji uzupełnij brakujące dane:
              {!alarmData?.sosType && <ListItem className={classes.listItem}> {t(proECareAlarmMessages.alarmDetails.sosType)}</ListItem>}
              {!alarmData?.sosReason && (
                <ListItem className={classes.listItem}> {t(proECareAlarmMessages.alarmDetails.sosReason)}</ListItem>
              )}
              {!alarmData?.interventionType && (
                <ListItem className={classes.listItem}> {t(proECareAlarmMessages.alarmDetails.interventionType)}</ListItem>
              )}
              {!alarmData?.interventionEffect && (
                <ListItem className={classes.listItem}> {t(proECareAlarmMessages.alarmDetails.interventionEffect)}</ListItem>
              )}
            </List>
          )}
        </>
      ),
      infoOnly: !isValidToCloseIntervention,
      buttonLabels: { onTrue: t(generalMessages.yes), onFalse: t(generalMessages.no) },
    });
    if (confirmed) {
      setIsValid(!isValidToCloseIntervention);
      if (isValidToCloseIntervention) statusSyncMutation.mutate();
    }
  };

  const buttonEndInterventionText = useMemo(
    () =>
      alarmData?.sosStatus === 'CLOSED'
        ? t(proECareAlarmMessages.alarmCard.buttons.endedIntervention)
        : t(proECareAlarmMessages.alarmCard.buttons.endIntervention),
    [alarmData?.sosStatus],
  );

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitleWithFiltersForTables pageTitle={pageTitle} />
      <Card>
        {isLoading && <LoaderOverlay inner minHeight='20px' />}
        {alarmData && alarmData.patient && (
          <ProfessionalECareAlarmPersonalData eCareAlarmsData={alarmData} patientId={alarmData.patient.id} />
        )}
        {alarmData && alarmId && <ProfessionalECareAlarmInfo alarmId={alarmId} eCareAlarmsData={alarmData} isValid={isValid} />}
        <CardContentContainer isLastSection>
          <div className={classes.footer}>
            {alarmData && (
              <>
                <ECareSyncWithCusButton
                  disabled={!isValidToCloseIntervention}
                  endpoints={endpointsToSync}
                  id={alarmData.id}
                  message={
                    alarmData.lastSync
                      ? `${t(proECareAlarmMessages.alarmCard.buttons.syncWithCusInfo)} ${unknownDateToString(
                          alarmData.lastSync,
                          DATE_FORMATS.DISPLAY_TIME,
                        )}`
                      : t(proECareAlarmMessages.noLastSyncData)
                  }
                />
                <Button disabled={['CLOSED', 'RECEIVED'].includes(alarmData.sosStatus)} variant='contained' onClick={closeIntervention}>
                  {buttonEndInterventionText}
                </Button>
              </>
            )}
          </div>
        </CardContentContainer>
      </Card>
    </MainContentWrapper>
  );
};

export default ProfessionalAlarmSinglePage;
