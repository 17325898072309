import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'fixed',
    bottom: '100px',
    right: theme.spacing(4),
    zIndex: theme.zIndex.drawer,
    borderRadius: '50%',
    minWidth: 'unset',
    height: '60px',
    width: '60px',
    boxShadow: theme.shadows[3],
  },
}));

export default useStyles;
