import axios from 'axios';

import type { DefaultApiResponse } from 'api/_types';

import { endpoint } from '../coreServiceTypes';
import type { CoreServiceTypeDetails, CoreServiceTypeDetailsDTO } from '../types';
import { parseServiceTypeDetailsForFE } from './_services/parseServiceTypeDetailsForFE/parseServiceTypeDetailsForFE';

interface Params {
  serviceTypeId: string;
}

type GetServiceTypeDetailsQuery = () => Promise<CoreServiceTypeDetails>;

export const getServiceTypeDetails =
  ({ serviceTypeId }: Params) =>
  async () => {
    const { data } = await axios.get<GetServiceTypeDetailsQuery, DefaultApiResponse<CoreServiceTypeDetailsDTO>>(
      `${endpoint}${serviceTypeId}`,
    );

    return parseServiceTypeDetailsForFE(data);
  };
