import { useCallback, useMemo, type FC } from 'react';

import { generatePath } from 'react-router-dom';

import { SERVICES_PATHS } from 'constants/router/PATHS';

import ServicesReviewTable, { type ServicesReviewTableDataInput } from '../ServicesReviewTable/ServicesReviewTable';

export interface Props {
  data: ServicesReviewTableDataInput;
  isHighlighted: boolean;
  isLast: boolean;
  title: string;
}

const ServicesReviewTableContainer: FC<Props> = ({ data, isHighlighted, isLast, title }) => {
  const { columnsNumber, isTableActive } = useMemo(
    () => ({
      columnsNumber: data?.headers.length,
      isTableActive: isLast && isHighlighted,
    }),
    [isHighlighted],
  );

  const handlePathToGenerator = useCallback(({ entityId }) => generatePath(SERVICES_PATHS.SERVICES_GROUP, { groupId: `${entityId}` }), []);

  return (
    <ServicesReviewTable
      columnsNumber={columnsNumber}
      data={data}
      fixedHeight={165}
      isLoading={false}
      isTableActive={isTableActive}
      pathToGenerator={handlePathToGenerator}
      title={title}
    />
  );
};

export default ServicesReviewTableContainer;
