import { useCallback, useMemo } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ORDER_SERVICE_ORIGINS } from 'constants/_types/OrderServiceOrigins';

interface UseFormStepParams {
  formStep: number;
}

export const useFormStep = ({ formStep }: UseFormStepParams) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paramOrigin = useMemo(() => searchParams.get('origin') as string, [searchParams]);

  const navigateBackToCatalogs = useCallback(() => {
    navigate(-1);
  }, []);

  const handlePreviousStep = useCallback(() => {
    if (paramOrigin === ORDER_SERVICE_ORIGINS.SERVICES) {
      navigateBackToCatalogs();
    } else {
      navigate({ pathname, search: `?origin=${paramOrigin}&step=${formStep - 1}` });
    }
  }, [formStep]);

  const handleNextStep = useCallback(() => {
    navigate({ pathname, search: `?origin=${paramOrigin}&step=${formStep + 1}` });
  }, [formStep]);

  return { handlePreviousStep, handleNextStep };
};
