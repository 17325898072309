import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { SERVICES_PREFIX } from 'constants/router/PATHS';

export const useShouldShowActionButtons = () => {
  const { pathname } = useLocation();

  const showButtons = useMemo(() => pathname.includes(`/${SERVICES_PREFIX}/`), [pathname]);

  return showButtons;
};
