import { getContactFormCategories } from './getContactFormCategories/getContactFormCategories';
import { submitContactForm } from './submitContactForm/submitContactForm';

export const endpoints = {
  getCategories: 'api/v1/cc-category/',
  postContactForm: 'api/v1/cc-topic/',
};

const contactForm = {
  getContactFormCategories,
  submitContactForm,
};

export default contactForm;
