import { FC } from 'react';

import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import PatientsList from 'components/UI/organisms/PatientsList/PatientsList';
import usePageConfig from 'hooks/usePageConfig/usePageConfig';

const ProfessionalPatientsListPage: FC = () => {
  usePageConfig();

  return (
    <MainContentWrapper extraContainer noPadding>
      <PatientsList />
    </MainContentWrapper>
  );
};

export default ProfessionalPatientsListPage;
