import type { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as PatientProfileIcon } from 'assets/icons/hub/active/user_plus.svg';
import PageTitle from 'components/UI/molecules/PageTitle/PageTitle';
import MainContentWrapper from 'components/UI/organisms/MainContentWrapper/MainContentWrapper';
import PatientCard from 'components/UI/organisms/PatientCard/PatientCard';
import patientMessages from 'translations/specific/patient.mjs';

const MyProfilePage: FC = () => {
  const { t } = useTranslation();

  return (
    <MainContentWrapper extraContainer noPadding>
      <PageTitle headerLabel={t(patientMessages.title)} icon={<PatientProfileIcon />} />
      <PatientCard />
    </MainContentWrapper>
  );
};

export default MyProfilePage;
