import { useState } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import type { FieldPath, FieldValues, PathValue, UnpackNestedValue, UseFormReturn } from 'react-hook-form';

import type { DropdownOption } from 'constants/_types/DropdownOptions';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect/useDidUpdateEffect';
import generateOptionsFormFlatList from 'services/generateOptionsFormFlatList/generateOptionsFormFlatList';
import usePatientEditorUIStorage from 'storages/patientEditorUIStorage';

type PostCodeResponse = {
  data: { miejscowosc: string; ulica: string }[];
};

type UseAddressDictionaries = <T extends FieldValues>(hookParams: {
  form: UseFormReturn<T>;
  postcodeInputKey: FieldPath<T>;
  cityInputKey: FieldPath<T>;
  streetInputKey: FieldPath<T>;
  isOutsidePatientEditor?: boolean;
  postalCodePattern: RegExp | undefined;
}) => { cityOptions: DropdownOption[]; streetOptions: DropdownOption[] };

const useAddressDictionaries: UseAddressDictionaries = ({
  form,
  postcodeInputKey,
  cityInputKey,
  streetInputKey,
  isOutsidePatientEditor = false,
  postalCodePattern,
}) => {
  const [cityOptions, setCityOptions] = useState<DropdownOption[]>([]);
  const [streetOptions, setStreetOptions] = useState<DropdownOption[]>([]);
  const { isPatientEditorOpen } = usePatientEditorUIStorage();

  const postcodeFieldValue = form.watch(postcodeInputKey) as string;

  const updateAddressDictionaries = async (postcode: string) => {
    const options: AxiosRequestConfig = {
      headers: {
        'X-TraceId': 'optional_abc123',
        Accept: 'application/json',
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST as string,
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY as string,
        Authorization: false,
      },
    };
    const url = `https://${process.env.REACT_APP_RAPID_API_HOST}/${postcode}`;

    try {
      const response: PostCodeResponse = await axios.get(url, options);

      const preparedCities = generateOptionsFormFlatList(response.data.map(({ miejscowosc }) => miejscowosc));
      const preparedStreets = generateOptionsFormFlatList(response.data.filter(({ ulica }) => !!ulica).map(({ ulica }) => ulica));

      if (!form.getValues(cityInputKey)) {
        if (preparedCities.length > 0 && preparedCities[0]?.value)
          form.setValue(cityInputKey, preparedCities[0].value as UnpackNestedValue<PathValue<string | number, FieldPath<FieldValues>>>);
        else form.setValue(cityInputKey, '' as UnpackNestedValue<PathValue<string, FieldPath<FieldValues>>>);
      }

      if (!form.getValues(streetInputKey)) {
        if (preparedStreets.length === 0)
          form.setValue(streetInputKey, '---' as UnpackNestedValue<PathValue<string, FieldPath<FieldValues>>>);
        else if (preparedStreets.length > 0 && preparedStreets[0]?.value)
          form.setValue(streetInputKey, preparedStreets[0].value as UnpackNestedValue<PathValue<string | number, FieldPath<FieldValues>>>);
        else form.setValue(streetInputKey, '' as UnpackNestedValue<PathValue<string, FieldPath<FieldValues>>>);
      }

      setCityOptions(preparedCities);
      setStreetOptions(preparedStreets);
    } catch (e) {
      // const { response } = e as AxiosError;
      // if (response?.status === 404) {
      //   form.setError('postcode', { message: t(validationMessages.invalid_postcode) });
      // }
    }
  };

  useDidUpdateEffect(() => {
    const isValid = postalCodePattern ? postalCodePattern.test(postcodeFieldValue) : true;
    const editorCondition = isOutsidePatientEditor || isPatientEditorOpen;
    if (isValid && editorCondition) {
      form.clearErrors(postcodeInputKey);
      updateAddressDictionaries(postcodeFieldValue);
    } else {
      setCityOptions([]);
      setStreetOptions([]);
    }
  }, [postcodeFieldValue, isPatientEditorOpen]);

  return { cityOptions, streetOptions };
};

export default useAddressDictionaries;
