import type { FC, ReactNode } from 'react';
import { createContext, useState, useContext, useRef, useMemo } from 'react';

import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import generalMessages from 'translations/common/general.mjs';

import useStyles from './ConfirmationDialogContext.styles';

export type ConfirmationOptions = {
  title?: string;
  body?: string | ReactNode;
  size?: 'sm' | 'md' | 'lg';
  infoOnly?: boolean;
  buttonLabels?: { onTrue?: string; onFalse?: string };
};
type ShowConfirmationDialog = (options: ConfirmationOptions) => Promise<boolean>;

type Props = {
  children: ReactNode;
};

type ConfirmationDialogContextType = {
  showConfirmationDialog: ShowConfirmationDialog;
};

const ConfirmationDialogContext = createContext({} as ConfirmationDialogContextType);

const { Provider } = ConfirmationDialogContext;

const ConfirmationDialogProvider: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState<ConfirmationOptions | null>();

  const awaitingConfirmation = useRef<any>(null);

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => setConfirmationOptions(null), 150);
  };

  const showConfirmationDialog: ShowConfirmationDialog = options => {
    setOpen(true);
    const defaultConfig = { infoOnly: false };
    setConfirmationOptions({ ...defaultConfig, ...options });
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    closeModal();
  };

  const onConfirm = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    closeModal();
  };

  const value = useMemo(() => ({ showConfirmationDialog }), [showConfirmationDialog]);

  return (
    <Provider value={value}>
      {children}
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        BackdropProps={{ style: { backgroundColor: alpha(theme.palette.secondary.light, 0.35) } }}
        fullWidth
        maxWidth={confirmationOptions?.size || 'sm'}
        open={open}
        onClose={onCancel}
      >
        {confirmationOptions?.title && (
          <DialogTitle className={cx(classes.dialogTitle, classes.dialogTitleText)} id='alert-dialog-title'>
            {confirmationOptions.title}
          </DialogTitle>
        )}
        {confirmationOptions?.body && (
          <DialogContent className={classes.dialogContent}>
            <DialogContentText id='alert-dialog-description'>{confirmationOptions.body}</DialogContentText>
          </DialogContent>
        )}
        {confirmationOptions?.infoOnly ? (
          <DialogActions className={classes.dialogActions}>
            <Button autoFocus variant='outlined' onClick={onConfirm}>
              {t(generalMessages.ok)}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions className={classes.dialogActions}>
            <Button variant='outlined' onClick={onCancel}>
              {confirmationOptions?.buttonLabels?.onFalse || t(generalMessages.no)}
            </Button>
            <Button autoFocus variant='contained' onClick={onConfirm}>
              {confirmationOptions?.buttonLabels?.onTrue || t(generalMessages.yes)}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Provider>
  );
};

export const useConfirmationDialogContext = () => useContext(ConfirmationDialogContext);

export default ConfirmationDialogProvider;
