import { useState } from 'react';

import { UseFormSetError } from 'react-hook-form';
import { useMutation } from 'react-query';

import auth from 'api/auth';
import { useAuthContext } from 'components/context/AuthContext/AuthContext';
import { GlobalDialogs, useGlobalDialogsContext } from 'components/context/GlobalDialogsContext/GlobalDialogsContext';
import { LoginFormInput } from 'components/UI/organisms/LoginForm/LoginForm';
import useMutationWithRecaptcha from 'hooks/useMutationWithRecaptcha/useMutationWithRecaptcha';
import useOnBackendFormError from 'hooks/useOnBackendFormError/useOnBackendFormError';

export const LOGIN_MUTATION_MUTATION_KEY = 'Login';

type UseLoginMutationArguments = {
  setError: UseFormSetError<LoginFormInput>;
};

const useLoginMutation = ({ setError }: UseLoginMutationArguments) => {
  const { addToGlobalDialogQueue } = useGlobalDialogsContext();
  const { login } = useAuthContext();
  const [generalErrors, setGeneralErrors] = useState<string[]>();
  const { mutateWithRecaptcha } = useMutationWithRecaptcha('Login');

  const onError = useOnBackendFormError<LoginFormInput, LoginFormInput>({
    setError,
    setGeneralErrors,
  });

  const { mutate, isLoading } = useMutation(LOGIN_MUTATION_MUTATION_KEY, auth.login(), {
    retry: 0,
    onSuccess: ({ data }) => {
      const { refresh, access, two_fa } = data;
      if (refresh && access) {
        login({ refresh, access });
      } else {
        addToGlobalDialogQueue({ type: GlobalDialogs.passTwoFA, props: { token: two_fa } });
      }
    },
    onError,
  });

  const loginMutate = (mutationData: LoginFormInput) => {
    mutateWithRecaptcha<LoginFormInput>(mutate, mutationData);
  };

  return { loginMutate, isLoginMutating: isLoading, loginMutationErrors: generalErrors };
};

export default useLoginMutation;
