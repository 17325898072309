import axios from 'axios';

import { DefaultApiResponse, PaginatedApiResponse } from 'api/_types';
import { TableDataBaseParamsForQuery } from 'constants/_types/TableDataBaseParamsForQuery';
import { TableDataQueryFunctionParams } from 'constants/_types/TableDataQueryFunction';
import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import parseFiltersForBE from '../_commonParsers/parseFiltersForBE';
import parseSortForBE from '../_commonParsers/parseSortForBE';
import { parseEcarePhoneCallForFE } from './eCarePhoneCall.parsers';
import { EcarePhoneCall, EcarePhoneCallDTO, EcarePhoneCallListFilters, ECarePhoneCallUnassigned } from './eCarePhoneCall.types';

const endpoint = '/api/v1/ecare-phone-call/';

type GetEcarePhoneCallQuery = () => Promise<EcarePhoneCall>;
export type UpdateEcarePhoneCallInput = ECarePhoneCallUnassigned;

type UpdateEcarePhoneCallQuery = (data: UpdateEcarePhoneCallInput) => Promise<DefaultApiResponse<EcarePhoneCall>>;

type GetEcarePhoneCallsListQuery = (
  params: TableDataQueryFunctionParams<keyof EcarePhoneCall>,
) => Promise<TableDataQueryResult<keyof EcarePhoneCall>>;

const eCarePhoneCall = {
  getEcarePhoneCallsList: (): GetEcarePhoneCallsListQuery => async params => {
    const paramsForBE: TableDataBaseParamsForQuery = {
      limit: params.limit,
      offset: params.offset,
      ordering: parseSortForBE(params.sort),
      ...parseFiltersForBE<EcarePhoneCallListFilters>(params.filters),
    };

    const { data } = await axios.get<GetEcarePhoneCallQuery, PaginatedApiResponse<EcarePhoneCallDTO>>(
      createUrlWithParams({ url: endpoint, params: paramsForBE }),
    );

    return {
      count: data.count,
      data: data.results.map(parseEcarePhoneCallForFE),
    };
  },
  getEcarePhoneCall:
    (id: string | number): GetEcarePhoneCallQuery =>
    async () => {
      const { data } = await axios.get<GetEcarePhoneCallQuery, DefaultApiResponse<EcarePhoneCallDTO>>(`${endpoint}${id}/`);
      return parseEcarePhoneCallForFE(data);
    },
  patchEcarePhoneCall:
    (id: string): UpdateEcarePhoneCallQuery =>
    data =>
      axios.patch<UpdateEcarePhoneCallQuery, DefaultApiResponse<EcarePhoneCall>>(`${endpoint}${id}/`, data),
};
export default eCarePhoneCall;
