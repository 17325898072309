import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

export const parseAddressData = (addressData: OrderServiceFormInput['address']) => ({
  closest: addressData.searchNearest,
  search_around_distance: addressData.searchAroundDistance,
  country: addressData.country,
  city: addressData.city,
  street_name: addressData.street,
  postcode: addressData.postcode,
  apartment_number: addressData.apartmentNumber,
  building_number: addressData.buildingNumber,
});
