import { useCallback, useMemo, type FC } from 'react';

import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { useOrderServiceFormContext } from 'components/context/OrderServiceFormContext/OrderServiceFormContext';
import CardWithSeparatedTitle from 'components/UI/molecules/CardWithSeparatedTitle/CardWithSeparatedTitle';
import FormInputAutocomplete from 'components/UI/organisms/_formInputs/FormInputAutocomplete/FormInputAutocomplete';
import FormInputDropdown from 'components/UI/organisms/_formInputs/FormInputDropdown/FormInputDropdown';
import ElementWithLoader from 'components/UI/organisms/ElementWithLoader/ElementWithLoader';
import type { OrderServiceOrigins } from 'constants/_types/OrderServiceOrigins';
import { FORM_CARD_MAX_WIDTH } from 'constants/styles/FORM_CARD_MAX_WIDTH';
import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import useUserPayersStorage from 'storages/payerStorage/userPayersStorage';
import { useServicesBasketStorageWrapper } from 'storageWrappers/useServicesBasketStorageWrapper/useServicesBasketStorageWrapper';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import { useOrderServiceFormDropdownsValues } from './_hooks/useOrderServiceFormDropdownsValues/useOrderServiceFormDropdownsValues';
import { usePreselectType } from './_hooks/usePreselectType/usePreselectType';
import { useRefetchingServicesForPreselection } from './_hooks/useRefetchingServicesForPreselection/useRefetchingServicesForPreselection';
import { useRefetchingSubtypesForPreselection } from './_hooks/useRefetchingSubtypesForPreselection/useRefetchingSubtypesForPreselection';
import { useServicesQuery } from './_hooks/useServicesQuery/useServicesQuery';
import { useUpdateSelectedSubtypeByPreselectedSubtype } from './_hooks/useUpdateSelectedSubtypeByPreselectedSubtype/useUpdateSelectedSubtypeByPreselectedSubtype';
import useStyles from './OrderServiceFormServiceTypes.styles';

export const testId = createTestIdObject('OrderServiceFormServiceTypes', {
  fallBackInfo: 'fallBackInfo',
});

const OrderServiceFormServiceTypes: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { form, handleManualResetPickServices } = useOrderServiceFormContext();
  const selectedPayersList = useUserPayersStorage(_state => _state.getSelectedPayersList());
  const { selectedItemMetaData, setSelectedItemMetaData, addToBasket } = useServicesBasketStorageWrapper();
  const { classes } = useStyles();

  const paramOrigin = useMemo(() => searchParams.get('origin') as OrderServiceOrigins, [searchParams]);

  const {
    serviceTypeValue,
    serviceSubtypeValues,
    servicesFromSubtypesValue,
    preselectedSubtypeId,
    preselectedServiceId,
    setFormSubtypePreselectedValue,
    setFormServicePreselectedValue,
  } = useOrderServiceFormDropdownsValues({ form, paramOrigin });

  const { isTypeSelected, isSubtypeSelected } = useMemo(
    () => ({
      isTypeSelected: !!serviceTypeValue,
      isSubtypeSelected: serviceSubtypeValues?.length > 0,
    }),
    [serviceTypeValue, serviceSubtypeValues],
  );

  const {
    serviceTypes,
    serviceTypesLoading,
    serviceSubtypes,
    servicesFromSubtypes,
    servicesFromSubtypesFetchedData,
    servicesFromSubtypesLoading,
    refetchSubtypes,
    refetchServices,
  } = useServicesQuery({
    selectedPayerIds: selectedPayersList,
    serviceTypeValue,
    serviceSubtypeValues,
    preselectedSubtypeId,
    setFormSubtypePreselectedValue,
    setFormServicePreselectedValue,
  });

  usePreselectType({ form, serviceTypes, selectedPayersList });

  useUpdateSelectedSubtypeByPreselectedSubtype({
    serviceSubtypes,
    serviceSubtypeValues,
    preselectedSubtypeId,
    setFormSubtypePreselectedValue,
  });

  useRefetchingSubtypesForPreselection({
    serviceTypes,
    serviceSubtypes,
    subtypeValues: serviceSubtypeValues,
    preselectedSubtypeId,
    refetchSubtypes,
  });

  useRefetchingServicesForPreselection({
    services: servicesFromSubtypes,
    serviceValue: servicesFromSubtypesValue,
    preselectedServiceId,
    refetchServices,
  });

  const handleAddToBasket = useCallback(() => {
    if ((!selectedItemMetaData && !preselectedServiceId) || !servicesFromSubtypesFetchedData?.length) return;

    const serviceIdClickedByUser = selectedItemMetaData?.id;
    const idToSearchFor = serviceIdClickedByUser || preselectedServiceId;
    const [addingItem] = servicesFromSubtypesFetchedData.filter(item => `${item.id}` === idToSearchFor);

    addToBasket(addingItem);
    handleManualResetPickServices();
  }, [selectedItemMetaData, servicesFromSubtypesFetchedData]);

  const isButtonDisabled = useMemo(
    () => [!serviceTypeValue, !serviceSubtypeValues?.length, !servicesFromSubtypesValue].some(condition => condition),
    [serviceTypeValue, serviceSubtypeValues, servicesFromSubtypesValue],
  );

  return (
    <CardWithSeparatedTitle maxWidth={FORM_CARD_MAX_WIDTH} title={t(orderServiceMessages[paramOrigin].cardTitle)}>
      <div className={classes.container}>
        <ElementWithLoader isLoading={serviceTypesLoading}>
          {serviceTypes?.length ? (
            <FormInputDropdown
              control={form.control}
              label={`${t(orderServiceMessages[paramOrigin].chooseTypeLabel)}...`}
              name={ORDER_SERVICE_FORM_INPUT_KEYS.serviceType}
              options={serviceTypes}
            />
          ) : (
            <Typography data-testid={testId.fallBackInfo}>{t(orderServiceMessages.warnings.noServicesWithChosenPayers)}</Typography>
          )}
        </ElementWithLoader>
        {isTypeSelected && (
          <FormInputDropdown
            control={form.control}
            label={`${t(orderServiceMessages[paramOrigin].chooseSubtypeLabel)}...`}
            multiple
            name={ORDER_SERVICE_FORM_INPUT_KEYS.serviceSubtype}
            options={serviceSubtypes}
          />
        )}
        {isSubtypeSelected && (
          <ElementWithLoader isLoading={servicesFromSubtypesLoading}>
            <FormInputAutocomplete
              control={form.control}
              label={`${t(orderServiceMessages[paramOrigin].chooseServiceFromSubtypeLabel)}...`}
              name={ORDER_SERVICE_FORM_INPUT_KEYS.serviceFromSubtype}
              options={servicesFromSubtypes}
              setSelectedItemMetaData={setSelectedItemMetaData}
            />
          </ElementWithLoader>
        )}
        <Button className={classes.confirmButton} disabled={isButtonDisabled} variant='contained' onClick={handleAddToBasket}>
          {t(orderServiceMessages.buttonLabels.confirmButton)}
        </Button>
      </div>
    </CardWithSeparatedTitle>
  );
};

export default OrderServiceFormServiceTypes;
