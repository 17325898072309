type PrepareFinalUrl = (url: string, urlParams: URLSearchParams, shouldReturnFullUrl: boolean) => string;

export const prepareFinalUrl: PrepareFinalUrl = (url, urlParams, shouldReturnFullUrl) => {
  const search = urlParams.toString();

  if (!shouldReturnFullUrl) return `${url}?${search}`;

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  if (!baseUrl) throw new Error('baseUrl not provided in "prepareFinalUrl"');

  const finalUrl = new URL(url, baseUrl);
  finalUrl.search = search;

  return finalUrl.toString();
};
