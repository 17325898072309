import axios from 'axios';

import { TableDataQueryResult } from 'constants/_types/TableDataQueryResult';
import createUrlWithParams from 'helpers/createUrlWithParams/createUrlWithParams';

import { DefaultApiResponse, PaginatedApiResponse } from '../_types';
import { parseECareCusCommentsListForFE } from './eCareCusComments.parsers';
import { CreateECareCusCommentsInput, ECareCusComments, ECareCusCommentsDTO, UpdateECareCusCommentsInput } from './eCareCusComments.types';

type GetECareAllCusCommentsQuery = () => Promise<TableDataQueryResult<keyof ECareCusComments>>;
type CreateNoteQuery = (data: CreateECareCusCommentsInput) => Promise<DefaultApiResponse<ECareCusComments>>;
type UpdateECareCusCommentsQuery = (data: UpdateECareCusCommentsInput) => Promise<DefaultApiResponse<ECareCusComments>>;

const endpoint = '/api/v1/ecare-comment/';

const eCareCusComments = {
  getAllComments:
    (orderId: string): GetECareAllCusCommentsQuery =>
    async () => {
      const { data } = await axios.get<GetECareAllCusCommentsQuery, PaginatedApiResponse<ECareCusCommentsDTO>>(
        createUrlWithParams({
          url: endpoint,
          params: { order: orderId || '' },
          removeEmptyParams: true,
        }),
      );
      return { data: parseECareCusCommentsListForFE(data.results), count: data.count };
    },

  createComment: (): CreateNoteQuery => data => axios.post<CreateNoteQuery, DefaultApiResponse<ECareCusComments>>(endpoint, data),

  patchCommentData:
    (id: string): UpdateECareCusCommentsQuery =>
    data =>
      axios.patch<UpdateECareCusCommentsQuery, DefaultApiResponse<ECareCusComments>>(`${endpoint}${id}/`, data),
};
export default eCareCusComments;
