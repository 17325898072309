import { makeStyles } from 'tss-react/mui';

import { FORM_CARD_MAX_WIDTH } from 'constants/styles/FORM_CARD_MAX_WIDTH';

import { MAIN_FORM_AREA } from '../../_constants/gridAreas';

const useStyles = makeStyles()(theme => ({
  mainForm: {
    gridArea: MAIN_FORM_AREA,
    maxWidth: FORM_CARD_MAX_WIDTH,
    width: '100%',
    display: 'flex',
    justifySelf: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(3.75),
  },
}));

export default useStyles;
