type SymptomCheckerMessageKeys = 'back' | 'interviewFinished' | 'generatePdf' | 'scheduleAnAppointment' | 'discussWithDoctor';
export type SymptomCheckerMessageValues =
  | 'mdt-back'
  | 'interview-finished'
  | 'generate-pdf'
  | 'schedule-an-appointment'
  | 'discuss-with-doctor';

export const SYMPTOM_CHECKER_MESSAGES: { [key in SymptomCheckerMessageKeys]: SymptomCheckerMessageValues } = {
  back: 'mdt-back',
  interviewFinished: 'interview-finished',
  generatePdf: 'generate-pdf',
  scheduleAnAppointment: 'schedule-an-appointment',
  discussWithDoctor: 'discuss-with-doctor',
};
