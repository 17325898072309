import { useCallback, useLayoutEffect, useState } from 'react';

import { throttle } from 'lodash';

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  const updateSize = useCallback(
    throttle(() => {
      setSize([window.innerWidth, window.innerHeight]);
    }, 300),
    [],
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { size };
};
