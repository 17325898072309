import { ECareDevice, ECareDeviceDTO } from './eCareDevices.types';

export const parseSingleECareDevicesForFE = (eCareDevicesDTO: ECareDeviceDTO): ECareDevice => ({
  id: eCareDevicesDTO.id,
  deviceType: eCareDevicesDTO.device_type,
  createdAt: new Date(eCareDevicesDTO.created_at),
  updatedAt: new Date(eCareDevicesDTO.updated_at),
  name: eCareDevicesDTO.name,
  serialNumber: eCareDevicesDTO.serial_number,
  simNumber: eCareDevicesDTO.sim_number,
  description: eCareDevicesDTO.description,
  dateFrom: new Date(eCareDevicesDTO.date_from),
  dateTo: eCareDevicesDTO.date_to ? new Date(eCareDevicesDTO.date_to) : null,
  objectId: eCareDevicesDTO.object_id,
  contentType: eCareDevicesDTO.content_type,
});

export const parseECareDevicesListForFE = (eCareDeviceDTO: ECareDeviceDTO[]): ECareDevice[] =>
  eCareDeviceDTO.map(parseSingleECareDevicesForFE);
