import { FC, useState } from 'react';

import { DateRangePicker as MuiDateRangePicker } from '@mui/lab';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import { Control, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import CalendarIcon from 'assets/icons/calendar.svg';
import InfoIconWithTooltip from 'components/UI/atoms/InfoIconWithTooltip/InfoIconWithTooltip';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';

export type Props = {
  text: {
    start: string;
    end: string;
  };
  name: string;
  control: Control<any>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  hasTooltip?: boolean;
  tooltipText?: string;
};

const DateRangePicker: FC<Props> = ({ name, register, text, setValue, hasTooltip, tooltipText }) => {
  const [dates, setDates] = useState<RangeInput<null>>([null, null]);

  const onChangeHandler = (newValue: RangeInput<null>) => {
    setDates(newValue);
    setValue(`${name}_after`, newValue[0] && format(newValue[0] as Date, DATE_FORMATS.API_DATE));
    setValue(`${name}_before`, newValue[1] && format(newValue[1] as Date, DATE_FORMATS.API_DATE));
  };

  return (
    <MuiDateRangePicker
      endText={text.end}
      renderInput={(startProps, endProps) => (
        <>
          <TextField
            {...startProps}
            {...register(`${name}_after`)}
            InputProps={{
              endAdornment: <CalendarIcon />,
            }}
          />
          <Box sx={{ mx: 1 }}> - </Box>
          <TextField
            {...endProps}
            {...register(`${name}_before`)}
            InputProps={{
              endAdornment: <CalendarIcon />,
            }}
          />
          {hasTooltip && (
            <Box sx={{ ml: 1 }}>
              <InfoIconWithTooltip content={tooltipText} />
            </Box>
          )}
        </>
      )}
      startText={text.start}
      value={dates}
      onChange={onChangeHandler}
    />
  );
};

export default DateRangePicker;
