import { makeStyles } from 'tss-react/mui';

import LAYOUT_SIZES from 'constants/styles/LAYOUT_SIZES';

interface StylesProps {
  isReducedPadding: boolean;
  noPadding: boolean;
  extraContainer: boolean;
  isFullHeight: boolean;
  buttonsBarHeight: number | null;
  // FOR v.0.4 ONLY
  shouldAdjustMobileViewportHeight: boolean;
  isIOS: boolean;
}

const useStyles = makeStyles<StylesProps>()(
  (theme, { isReducedPadding, noPadding, extraContainer, isFullHeight, buttonsBarHeight, shouldAdjustMobileViewportHeight, isIOS }) => {
    // @TODO: Make redundantValue dynamic using padding bottom and margin bottom from styles of specific containers
    const getMainMarginBottom = (redundantValue: number) => (buttonsBarHeight ? `${buttonsBarHeight - redundantValue}px` : 0);

    const main = noPadding
      ? {
          padding: 0,
          marginBottom: getMainMarginBottom(32),
        }
      : {
          padding: isReducedPadding ? theme.spacing(1, 4, 4) : theme.spacing(2, 2),
          marginBottom: getMainMarginBottom(32),
          [theme.breakpoints.down('xl')]: {
            padding: isReducedPadding ? theme.spacing(1, 4, 4) : theme.spacing(2, 2),
          },
          [theme.breakpoints.down('md')]: {
            padding: isReducedPadding ? theme.spacing(1, 2, 2) : theme.spacing(2),
            marginBottom: getMainMarginBottom(100),
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0, 0, 0),
            marginBottom: getMainMarginBottom(48),
          },
        };

    return {
      root: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: `minmax(calc(100vh - ${LAYOUT_SIZES.HEADER.xxl}px + 20px), auto) auto`,
        background: theme.palette.branding?.mainBackground || theme.palette.grey[300],

        [theme.breakpoints.down('md')]: {
          display: 'grid',
          gridTemplateRows: `minmax(calc(100vh - ${LAYOUT_SIZES.HEADER.sm}px), auto) auto`,
          paddingBottom: LAYOUT_SIZES.BOTTOM_MENU.closed,
        },
      },

      messages: {
        display: 'grid',
        gridGap: theme.spacing(2),
        paddingBottom: theme.spacing(5),
      },

      main,

      extraContainer: {
        height: isFullHeight ? '100%' : 'unset',
        paddingLeft: extraContainer ? theme.spacing(2) : 0,
        paddingRight: extraContainer ? theme.spacing(2) : 0,
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          height: shouldAdjustMobileViewportHeight ? 'calc(100vh - 205px)' : 'inherit', // FOR v.0.4 ONLY
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        },
      },

      colorContent: {
        marginLeft: theme.spacing(0.5),
      },
    };
  },
);

export default useStyles;
