import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';

import { Button } from '@mui/material';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePatientContext } from 'components/context/PatientContext/PatientContext';
import CancelButton from 'components/UI/molecules/BottomFixedContainer/_components/CancelButton/CancelButton';
import SubmitButton from 'components/UI/molecules/BottomFixedContainer/_components/SubmitButton/SubmitButton';
import EditableDataField from 'components/UI/molecules/EditableDataField/EditableDataField';
import SectionWithTitle from 'components/UI/molecules/SectionWithTitle/SectionWithTitle';
import FormInputCheckbox from 'components/UI/organisms/_formInputs/FormInputCheckbox/FormInputCheckbox';
import CardContentContainer from 'components/UI/organisms/CardContentContainer/CardContentContainer';
import EditableUserAvatar from 'components/UI/organisms/EditableUserAvatar/EditableUserAvatar';
import { CLAUSE_LOCATIONS } from 'constants/_types/ClauseLocations';
import PATHS from 'constants/router/PATHS';
import useClausesConsentsQuery from 'hooks/_clauses/useClausesConsentsQuery/useClausesConsentsQuery';
import useFormScrollToError from 'hooks/useFormScrollToError/useFormScrollToError';
import usePermissionCheck from 'hooks/usePermissionCheck/usePermissionCheck';
import useRedirectPrompt from 'hooks/useRedirectPrompt/useRedirectPrompt';
import getLabelForClause from 'services/_clauses/getLabelForClause/getLabelForClause';
import useBottomFixedContainerStorage from 'storages/bottomFixedContainerStorage';
import usePatientEditorUIStorage from 'storages/patientEditorUIStorage';
import generalMessages from 'translations/common/general.mjs';
import patientMessages from 'translations/specific/patient.mjs';

import { mobileGridTemplateAreas } from './_constants/styles';
import usePatientFormConfig from './_hooks/usePatientFormConfig/usePatientFormConfig';
import { usePatientInfoForm } from './_hooks/usePatientInfoForm/usePatientInfoForm';
import useStyles from './PatientPersonalInfo.styles';

type Props = {
  isPro: boolean;
  patientId?: number;
};

const PatientPersonalInfo: FC<Props> = ({ isPro, patientId }) => {
  const [canEditSomePartOfPatient, canEditAvatar] = usePermissionCheck(
    [{ comparisonMode: 'any', permissions: ['change_personaldata', 'change_addressdata'] }, 'change_avatar'],
    isPro,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientInfoFetched } = usePatientContext();
  const clausesRef = useRef<HTMLDivElement>(null);

  const { isPatientEditorOpen, closePatientEditor, openPatientEditor } = usePatientEditorUIStorage();
  const { openGlobalFormAndDisableOuterElements, closeGlobalForm, startGlobalFormLoading, endGlobalFormLoading } =
    useBottomFixedContainerStorage();

  const { form, onSubmit, isLoading, isSending, parsedUserData, refreshData, restoreInitialData } = usePatientInfoForm({
    patientId: patientId ? Number(patientId) : null,
    isPro,
  });

  const { personal, contact, address } = usePatientFormConfig(form, isPro);

  const { clausesWithConsents } = useClausesConsentsQuery([CLAUSE_LOCATIONS.personalData], {
    clausesQueryOptions: { staleTime: 60 * 60 * 1000 },
    shouldGetConsents: false,
    disabled: !isPro,
  });

  const isPristine = useMemo(() => {
    if (patientInfoFetched) return !patientId;
    return null;
  }, [patientId, patientInfoFetched]);

  const onSave = async () => {
    await onSubmit();
    if (form.formState.errors?.clauses && clausesRef.current) {
      clausesRef.current.scrollIntoView({ block: 'center' });
    }
  };

  useRedirectPrompt(
    { title: t(generalMessages.unsavedChanges.title), body: t(generalMessages.unsavedChanges.content) },
    { onYes: closeGlobalForm },
    isPatientEditorOpen,
  );

  useEffect(() => {
    if (isPatientEditorOpen) {
      openGlobalFormAndDisableOuterElements(
        [
          {
            buttonProps: {
              id: 0,
              action: () => {
                const hasPatient = patientInfoFetched && patientId;
                if (hasPatient) {
                  restoreInitialData();
                  closePatientEditor();
                } else {
                  navigate(PATHS.ROOT, { state: { force: true } });
                  closeGlobalForm();
                }
              },
            },
            buttonComponent: CancelButton,
          },
          {
            buttonProps: {
              id: 1,
              label: t(generalMessages.saveChanges),
              action: onSave,
            },
            buttonComponent: SubmitButton,
          },
        ],
        mobileGridTemplateAreas,
      );
    } else {
      closeGlobalForm();
    }
  }, [isPatientEditorOpen]);

  useEffect(() => {
    // Force open form when no patient added
    if (isPristine) openPatientEditor();
  }, [isPristine]);

  useEffect(() => {
    // cleanup
    if (isSending) startGlobalFormLoading();
    else endGlobalFormLoading();
    return closePatientEditor;
  }, [isSending]);

  useFormScrollToError(form.formState);

  const userAvatarEditDisabled = useMemo(() => {
    if (isNil(isPristine)) return { value: !!isPristine, reason: t(patientMessages.changePictureDisabled) };
    if (isPro) return { value: !canEditAvatar, reason: t(generalMessages.missingPermission) };
    return undefined;
  }, []);

  const { classes } = useStyles({ isEditMode: isPatientEditorOpen });

  return (
    <CardContentContainer isLoading={isLoading || !patientInfoFetched}>
      <div className={classes.wrapper}>
        <div className={classes.avatarContainer}>
          <div>
            <EditableUserAvatar
              disabled={userAvatarEditDisabled}
              gender={parsedUserData?.gender}
              imageUrl={parsedUserData?.imageUrl}
              onAvatarChange={refreshData}
            />

            {!isPatientEditorOpen && (
              <Button
                className={classes.editButton}
                disabled={isPro && !canEditSomePartOfPatient}
                size='small'
                variant='outlined'
                onClick={openPatientEditor}
              >
                {t(patientMessages.buttons.editProfile)}
              </Button>
            )}
          </div>
        </div>
        <div className={classes.formContainer}>
          <SectionWithTitle className={classes.personalSection} title={t(patientMessages.patientDataSectionTitles.personal)}>
            <div className={classes.standardColumnWrapper}>
              {personal.map(props => (
                <EditableDataField editMode={isPatientEditorOpen} form={form} key={props.label} {...props} />
              ))}
            </div>
          </SectionWithTitle>
          <SectionWithTitle className={classes.contactSection} title={t(patientMessages.patientDataSectionTitles.contact)}>
            <div className={classes.standardColumnWrapper}>
              {contact.map(props => (
                <EditableDataField editMode={isPatientEditorOpen} form={form} key={props.label} {...props} />
              ))}
            </div>
          </SectionWithTitle>
          <SectionWithTitle className={classes.addressSection} title={t(patientMessages.patientDataSectionTitles.address)}>
            <div className={classes.fourColumnsContainer}>
              {address.map(props => (
                <EditableDataField editMode={isPatientEditorOpen} form={form} key={props.label} {...props} />
              ))}
            </div>
          </SectionWithTitle>
          {!isPro && isPatientEditorOpen && clausesWithConsents && (
            <div className={classes.clausesContainer} ref={clausesRef}>
              {clausesWithConsents.map(({ clauseId, text, file, isObligatory }) => (
                <FormInputCheckbox
                  control={form.control}
                  hideAsterisk
                  key={clauseId}
                  label={getLabelForClause(text, {
                    url: file,
                    t,
                    isObligatory,
                  })}
                  name={`clauses.${clauseId}`}
                  required={isObligatory}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </CardContentContainer>
  );
};

export default PatientPersonalInfo;
