import { useTranslation } from 'react-i18next';

import { EcarePhoneCall } from 'api/eCarePhoneCall/eCarePhoneCall.types';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

const usePhoneCalls = (data: EcarePhoneCall | undefined) => {
  const { t } = useTranslation();

  return [
    {
      label: t(ecarePhoneCallMessages.detail.callReceivedDate),
      value: data?.callDate ? unknownDateToString(data.callDate, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.detail.callEndDate),
      value: data?.callEndDate ? unknownDateToString(data.callEndDate, DATE_FORMATS.DISPLAY_TIME) : t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.detail.caller),
      value: data ? t(ecarePhoneCallMessages.caller[data.caller]) : t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.detail.phoneNumber),
      value: data?.serviceRecipientPhoneNumber || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.detail.program),
      value: 'CUS' || t(generalMessages.noData),
    },
    {
      label: t(ecarePhoneCallMessages.detail.payer),
      value: data?.payer || t(generalMessages.noData),
    },
  ];
};

export default usePhoneCalls;
