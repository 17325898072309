const units = ['B', 'KB', 'MB'] as const;
export type Units = typeof units[number];

type SizeUnitsCalcReturn = Record<Units, number>;

const sizeUnitsCalc = (value: number, providerUnit: Units): SizeUnitsCalcReturn => {
  switch (providerUnit) {
    case 'B': {
      return {
        B: value,
        KB: +(value * 0.001).toFixed(3),
        MB: +(value * 0.000001).toFixed(6),
      };
    }
    case 'KB': {
      return {
        B: value * 1000,
        KB: value,
        MB: +(value * 0.001).toFixed(3),
      };
    }
    case 'MB': {
      return {
        B: value * 1000000,
        KB: value * 1000,
        MB: value,
      };
    }
    default: {
      throw Error('Incorrect unit value provided');
    }
  }
};

export default sizeUnitsCalc;
