import type { UseFormReturn } from 'react-hook-form';

import type { OrderServiceFormInput } from 'components/context/OrderServiceFormContext/_constants/inputKeys';
import { ORDER_SERVICE_FORM_INPUT_KEYS } from 'components/context/OrderServiceFormContext/_constants/inputKeys';

interface Parameters {
  form: UseFormReturn<OrderServiceFormInput, any>;
  currentValues: OrderServiceFormInput['address'];
}

export const clearSearchAroundDistance = ({ form, currentValues }: Parameters) => {
  form.setValue('address', {
    ...currentValues,
    [ORDER_SERVICE_FORM_INPUT_KEYS.searchAroundDistance]: '',
  });
};
