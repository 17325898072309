import type { FC } from 'react';

import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import createTestIdObject from 'helpers/createTestIdObject/createTestIdObject';
import orderServiceMessages from 'translations/specific/orderService.mjs';

import useStyles from './AddReferralButton.styles';

export const testId = createTestIdObject('AddReferralButton', {
  addFile: 'addFile',
});

export interface Props {
  handleAdd?: () => void;
  isLoading?: boolean;
}

const AddReferralButton: FC<Props> = ({ handleAdd, isLoading }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Button className={classes.root} data-testid={testId.addFile} size='small' variant='outlined' onClick={handleAdd}>
      {isLoading ? <CircularProgress size={16} /> : t(orderServiceMessages.details.referralAttachment.addReferral)}
    </Button>
  );
};

export default AddReferralButton;
