import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    height: '1.75rem',
    width: '1.75rem',
    margin: theme.spacing(0, 1),
  },
}));

export default useStyles;
