import { makeStyles } from 'tss-react/mui';

import AVATAR_SIZE from 'constants/styles/AVATAR_SIZE';

const useStyles = makeStyles()(theme => ({
  editorWrapper: {
    display: 'grid',
    maxWidth: `${64 + AVATAR_SIZE.width * 2}px`,
    margin: '0 auto',
  },
  previewWrapper: {
    display: 'grid',
    maxWidth: `${AVATAR_SIZE.width * 2}px`,
    margin: `${theme.spacing(4)} auto`,
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
}));

export default useStyles;
