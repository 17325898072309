import axios from 'axios';

import type { SupportedLanguages } from 'constants/translations/_types';

import { DefaultApiResponse } from './_types';

const endpoints = {
  me: 'api/v1/users/me/',
  passwordResetRequest: 'api/v1/users/reset-password/',
  passwordReset: 'api/v1/users/reset-password-confirm/',
  passwordChange: 'api/v1/users/set-password/',
  register: 'api/v1/users/',
  phoneUpdate: 'api/v1/users/update-phone-number/',
  phoneUpdateRevert: 'api/v1/users/revert-phone-number/',
  phoneVerify: 'api/v1/users/verify-phone-number/',
  phoneResend: 'api/v1/users/resend-verify-phone-number/',
};

export type MeResponse = {
  id: number;
  email: string;
  phone_number: string;
  has_phone_verified: boolean;
  has_full_account: boolean;
  login_identifier: string;
  has_obligatory_consents: {
    result: boolean;
    missing: number[];
  } | null;
  origin_brand: string;
  uses_2fa: boolean;
  language: SupportedLanguages;
};
type MeQuery = () => Promise<DefaultApiResponse<MeResponse>>;

type PasswordResetRequestQuery = (data: {
  login_identifier: string;
  recaptcha: string;
}) => Promise<DefaultApiResponse<{ login_identifier: string }>>;

type PasswordResetResponse = {
  uid: string;
  token: string;
  new_password: string;
  re_new_password: string;
};

export type PasswortResetData = {
  token: string;
  uid: string;
  new_password: string;
  re_new_password: string;
  recaptcha: string;
};

type PasswordResetQuery = (data: PasswortResetData) => Promise<DefaultApiResponse<PasswordResetResponse>>;

type PasswordChangeResponse = {
  new_password: string;
  re_new_password: string;
  current_password: string;
};

type PasswordChangeQuery = (data: {
  new_password: string;
  re_new_password: string;
  current_password: string;
}) => Promise<DefaultApiResponse<PasswordChangeResponse>>;

type RegisterResponse = {
  email: string;
  id: number;
  re_password: string;
};

export type RegisterQueryInput = {
  email: string;
  password: string;
  re_password: string;
  recaptcha: string;
  redirect_url?: string;
  consents: {
    clause: number;
    ordering: number | null;
    location: number;
    declined: boolean;
  }[];
};

type RegisterQuery = (data: RegisterQueryInput) => Promise<DefaultApiResponse<RegisterResponse>>;

export type PhoneUpdateInput = { phone_number: string };

type PhoneUpdateQuery = (data: PhoneUpdateInput) => Promise<DefaultApiResponse<unknown>>;

type PhoneVerifyResponse = {
  non_field_errors?: 'invalid_data' | 'exceeded_retries' | 'invalid_code';
};

export type PhoneVerifyInput = { code: string; recaptcha: string };

type PhoneVerifyQuery = (data: PhoneVerifyInput) => Promise<DefaultApiResponse<PhoneVerifyResponse>>;

type PhoneResendQuery = (data: { recaptcha: string }) => Promise<DefaultApiResponse<unknown>>;

type PhoneUpdateRevertQuery = () => Promise<DefaultApiResponse<unknown>>;

// @deprecated: This module should be prepared as the new 'users' module (in api/users directory)
const users = {
  me: (): MeQuery => () => axios.get<MeQuery, DefaultApiResponse<MeResponse>>(endpoints.me),
  passwordResetRequest: (): PasswordResetRequestQuery => data =>
    axios.post<PasswordResetRequestQuery, DefaultApiResponse<{ login_identifier: string }>>(endpoints.passwordResetRequest, data),
  passwordReset: (): PasswordResetQuery => data =>
    axios.post<PasswordResetQuery, DefaultApiResponse<PasswordResetResponse>>(endpoints.passwordReset, data),
  passwordChange: (): PasswordChangeQuery => data =>
    axios.post<PasswordChangeQuery, DefaultApiResponse<PasswordChangeResponse>>(endpoints.passwordChange, data),
  register: (): RegisterQuery => data => axios.post<RegisterQuery, DefaultApiResponse<RegisterResponse>>(endpoints.register, data),
  phoneUpdate: (): PhoneUpdateQuery => data => axios.post<PhoneUpdateQuery, DefaultApiResponse<unknown>>(endpoints.phoneUpdate, data),
  phoneUpdateRevert: () => () => axios.post<PhoneUpdateRevertQuery, DefaultApiResponse<unknown>>(endpoints.phoneUpdateRevert),
  phoneVerify: (): PhoneVerifyQuery => data =>
    axios.post<PhoneVerifyQuery, DefaultApiResponse<PhoneVerifyResponse>>(endpoints.phoneVerify, data),
  phoneResend: (): PhoneResendQuery => data => axios.post<PhoneResendQuery, DefaultApiResponse<unknown>>(endpoints.phoneResend, data),
};

export default users;
