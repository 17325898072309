import { ECareDictionary, ECareDictionaryDTO } from './eCareDictionary.types';

export const parseSingleECareDictionaryForFE = (eCareDictionaryDTO: ECareDictionaryDTO): ECareDictionary => ({
  id: eCareDictionaryDTO.id,
  createdAt: new Date(eCareDictionaryDTO.created_at),
  updatedAt: new Date(eCareDictionaryDTO.updated_at),
  entryType: eCareDictionaryDTO.entry_type,
  code: eCareDictionaryDTO.code,
  name: eCareDictionaryDTO.name,
  description: eCareDictionaryDTO.description || null,
  dateFrom: new Date(eCareDictionaryDTO.date_from),
  dateTo: eCareDictionaryDTO.date_to ? new Date(eCareDictionaryDTO.date_to) : null,
});

export const parseECareDictionaryForFE = (eCareDictionaryDTO: ECareDictionaryDTO[]): ECareDictionary[] =>
  eCareDictionaryDTO.map(parseSingleECareDictionaryForFE);
