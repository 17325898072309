import { useCallback } from 'react';

import type { OptionsObject } from 'notistack';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import SnackbarContent from 'components/UI/molecules/SnackbarContent/SnackbarContent';
import { SnackbarVariant } from 'constants/_types/SnackbarVariant';
import snackbarMessages from 'translations/common/snackbar.mjs';

type UseShowSnackbar = () => {
  showSnackbar: (config: {
    variant: SnackbarVariant;
    translationArray?: string[];
    translationParams?: Record<string, any>;
    accentColorText?: string;
    hasRefreshButton?: boolean;
    otherNotistackOptions?: Omit<OptionsObject, 'variant'>;
  }) => void;
};

export const useShowSnackbar: UseShowSnackbar = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getTranslationFallback = useCallback((variant: SnackbarVariant) => {
    switch (variant) {
      case 'success':
        return snackbarMessages.success;
      case 'error':
        return snackbarMessages.failure;
      default:
        return '';
    }
  }, []);

  const showSnackbar = useCallback(
    config => {
      const properTranslationArray = config?.translationArray ?? getTranslationFallback(config.variant);

      const props = {
        iconVariant: config.variant,
        text: config?.translationParams ? t(properTranslationArray, { ...config.translationParams }) : t(properTranslationArray),
        accentColorText: config?.accentColorText ?? '',
        hasRefreshButton: config?.hasRefreshButton ?? false,
      };

      enqueueSnackbar(<SnackbarContent {...props} />, {
        variant: config.variant,
        ...config?.otherNotistackOptions,
      });
    },
    [enqueueSnackbar],
  );

  return { showSnackbar };
};
