import { useTranslation } from 'react-i18next';

import { ECareOrder } from 'api/eCareOrder/eCareOrder.types';
import unknownDateToString from 'helpers/unknownDateToString/unknownDateToString';
import generalMessages from 'translations/common/general.mjs';
import proECareOrderMessages from 'translations/specific/pro_ecare_order.mjs';

const useTrainingData = (data: ECareOrder) => {
  const { t } = useTranslation();

  return [
    {
      label: t(proECareOrderMessages.devices.trainingDate),
      value: data?.trainingDate ? unknownDateToString(data.trainingDate) : t(generalMessages.noData),
    },
    {
      label: t(proECareOrderMessages.devices.trainingInfo),
      value: data?.trainingInfo || t(generalMessages.noData),
    },
  ];
};

export default useTrainingData;
