import type { FC } from 'react';

import DetermineLocation from 'components/UI/molecules/DetermineLocation/DetermineLocation';
import OrderDetails from 'components/UI/molecules/OrderDetails/OrderDetails';

import useStyles from './SummaryStep.styles';

const SummaryStep: FC = () => {
  const { classes } = useStyles();

  return (
    <section className={classes.mainForm}>
      <DetermineLocation />
      <OrderDetails />
    </section>
  );
};

export default SummaryStep;
