const passwordChangeReminder = {
  alert: {
    content: [
      'passwordChangeReminder>>dialog>>content',
      'Od ostatniej zmiany hasła minęło już 90 dni - ze względów bezpieczeństwa warto',
    ],
    strongContent: ['passwordChangeReminder>>dialog>>strongContent', 'zmienić je na nowe.'],
    buttonAcceptLabel: ['passwordChangeReminder>>dialog>>buttonAcceptLabel', 'Zmień hasło'],
    buttonRejectLabel: ['passwordChangeReminder>>dialog>>buttonRejectLabel', 'Pomiń'],
  },
};

export default passwordChangeReminder;
